import React, { Component } from 'react'
import { Input, message, Table, Button } from 'antd';
import { connect } from 'react-redux'
import { reportList } from '../../actions/account'
import './query.css'
import cookie from 'react-cookies';


import hospitial from '../../assets/hospitial.jpg'
const { Search } = Input;
const { Column } = Table
class query extends Component {
    constructor(props) {
        super(props);
        this.state = {
            idcard: undefined,
            queryArr: []
        }

    }
    componentDidMount() {
    }

    inputOnChange = e => {
        this.setState({
            idcard: e.target.value
        })
    }

    queryBycard = (val) => {
        console.log(val)
        this.setState({
            queryArr: []
        })
        const regex = /^[1-9]\d{5}(18|19|20)\d{2}(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])\d{3}[\dxX]$/;
        if (!regex.test(val)) {
            message.error('证件格式错误')
            return
        }
        this.props.reportList({ idCard: val }).then((msg) => {
            console.log(msg)
            if (msg.data.code == 200) {
                if (msg.data.data.rows.length == 0) {
                    message.error('当前身份证号码暂无数据')
                } else {
                    this.setState({
                        queryArr: msg.data.data.rows
                    })
                }
            }
        })
        // this.props.history.push('/appointQueryList?idCard=' + idcard + '&&type=' + 1)
    }
    readPic = (arr, ind) => {
        console.log(arr)
        window.open(arr.reportPath, "_blank");
    }
    render() {
        const {
            queryArr,

        } = this.state
        return (
            <div className="appointquery-bg">
                <div style={{
                    background: '#419ced', textAlign: 'center', height: '50px', lineHeight: '50px', color: ' #fff',
                    fontWeight: 'bold', fontSize: '20px',
                }}>
                    报告查询
                </div>
                <div className="appointquery-ipt" style={{ marginBottom: '20px' }}>
                    <Search placeholder="请输入身份证号码"
                        onChange={this.inputOnChange} enterButton="搜索" onSearch={value => this.queryBycard(value)} />
                </div>
                <div>
                    <Table dataSource={queryArr} size="middle" bordered>
                        <Column
                            title="姓名"
                            dataIndex="username"
                            key="username"
                            align="center"
                        />
                        <Column
                            title="日期"
                            dataIndex="createdAt"
                            key="createdAt"
                            align="center"
                        />
                        <Column
                            title="操作"
                            dataIndex="reportPath"
                            key="reportPath"
                            align="center"
                            render={(text, record, index) => (
                                <Button type="primary" onClick={(e) => this.readPic(record, index)}>查看</Button>
                            )}
                        />
                    </Table>
                </div>
            </div >
        )
    }
}
export default connect(null, {
    reportList
})(query)