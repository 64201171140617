import React, { Component } from 'react'
import { Modal, Button, Table, Input, message, Upload ,Divider} from 'antd';

// import _ from 'lodash'

import '../medicalRecord/RegistrationPage.css'
import { doctorAll, doctorSave, doctorUpdate, doctorDelete, imageUpload } from '../../../actions/account'
// export const 
// import _ from 'lodash'
import { connect } from 'react-redux'
const { Column } = Table;
const { confirm } = Modal;

class DoctorManage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editDoctorId:null,
            doctors: [],
            visible: false,
            doctorname: '',
            loading: false,
            imageUrlAll: undefined,
            imageUrl: undefined

        };
    }

    componentDidMount() {

        this.loadDatas();
    }

    loadDatas = () => {
        var that = this;
        that.props.doctorAll().then(msg => {
            if (msg.data.code === 200) {

                that.setState({ doctors: msg.data.data })
            }

        })
    }


    showModal = () => {
        this.setState({
            curRecord: undefined,
            visible: true
        })
    }

    handleCancel = () => {
        this.setState({
            visible: false
        })
    }

    machineOnChange = e => {
        this.setState({
            machineCode: e.target.value
        })
    }

    handleOk = () => {

    }
    editItem = (record) => {
        console.log(record.sign.replace("http://minio.yeekcloud.com/",""))
        this.setState({
            imageUrlAll:record.sign, doctorname:record.name,editDoctorId:record.id,visible:true,
            imageUrl:record.sign.replace("http://minio.yeekcloud.com/","")
        })
    }

    nameOnChnage = (e) => {
        this.setState({ doctorname: e.target.value })

    }

    handleChange = info => {

        if (info.file.status === 'uploading') {
            this.setState({ loading: true });
            // return;
        }
    };

    customRequest = (option) => {
        const formData = new FormData();
        const that = this;
        formData.append('file', option.file);

        that.props.imageUpload(formData).then(function (res) {
            if (res.data.code === 200) {
                that.setState({
                    imageUrlAll: res.data.complete,
                    imageUrl: res.data.data
                })
            }
            else {
                message.error(res.data.message);
            }
            that.setState({

                loading: false
            })
        })

    }

    handleBindOk = () => {
        var { imageUrl, doctorname,editDoctorId } = this.state;
        if (imageUrl === undefined || doctorname === '') {
            message.error("参数不全，不能提交！");return;
        }
        var that = this;
        console.log(editDoctorId)
        if(editDoctorId){
            that.props.doctorUpdate({ name: doctorname, sign: imageUrl,id:editDoctorId }).then(res => {
                if (res.data.code === 200) {
                    message.success("修改成功");
                    that.setState({ visible: false, imageUrl: undefined, imageUrlAll: undefined, doctorname: '',editDoctorId:null })
                    that.loadDatas();
                }
            })
        }
        else{
            that.props.doctorSave({ name: doctorname, sign: imageUrl }).then(res => {
                if (res.data.code === 200) {
                    message.success("新增成功");
                    that.setState({ visible: false, imageUrl: undefined, imageUrlAll: undefined, doctorname: '',editDoctorId:null  })
                    that.loadDatas();
                }
            })
        }
        
    }

    handleBindCancel = () => {
        this.setState({ visible: false, imageUrl: undefined, imageUrlAll: undefined, doctorname: '',editDoctorId:null, })

    }

    onRelieve = (id) => {
        var that = this;
        confirm({
            content: "确认删除？",
            onOk() {
                that.props.doctorDelete({ ids: id }).then(res => {
                    if (res.data.code === 200) {
                        message.success("删除成功！");
                    }
                })

            }
        });

    }

    render() {
        const { doctors, visible, doctorname, imageUrlAll } = this.state;
        return (
            <div>
                <div className="StatisticsPage-head pageHeader"><h3>医师管理</h3></div>
                <div className="StatisticsPage-box">
                    <div className="StatisticsPage-search">
                        <div className="StatisticsPage-btn">
                            <Button className="btn" type="primary" onClick={this.showModal}>添加医师</Button>

                            <Modal width="500px" title="医师信息" visible={visible} onOk={this.handleBindOk} onCancel={this.handleBindCancel} okText="保存" cancelText="取消">
                                <div className="form-group">
                                    <label className="form-label">医师姓名：</label>
                                    <div className="form-ipt" style={{ marginTop: '5px' }}>
                                        <Input value={doctorname} onChange={this.nameOnChnage}></Input>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="form-label">电子签名：</label>
                                    <div className="form-ipt" style={{ marginTop: '5px' }}>
                                        {imageUrlAll && <img style={{ width: '120px', height: '150px' }} alt="" src={imageUrlAll} />}
                                        <Upload
                                            accept="image/png, image/jpeg"
                                            showUploadList={false}
                                            customRequest={this.customRequest}
                                            onChange={this.handleChange}
                                        >
                                            <Button type="primary" loading={this.state.loading} >上传照片</Button>
                                        </Upload>
                                    </div>
                                </div>
                            </Modal>


                        </div>
                    </div>
                    <div>

                        <Table size="middle" dataSource={doctors} bordered rowKey="id" pagination={false}  >
                            <Column title="编号ID" dataIndex="id" key="id" align="center" />
                            <Column title="姓名" dataIndex="name" key="name" align="center" />
                            <Column width={150} title="电子签名" key="picture" align="center"
                                render={(record) => {
                                    return (<div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                                        <img alt="" style={{ width: 100, height: 100 }} src={record.sign} key={record.sign} />
                                    </div>)
                                }
                                } />
                            <Column title="操作" align="center" key="action"
                                render={(text, record) => (
                                    <span>
                                        <span style={{ cursor: 'pointer' }} onClick={() => this.onRelieve(record.id)} >删除</span>
                                        <Divider type="vertical" />
                                        <span style={{ cursor: 'pointer' }} onClick={() => this.editItem(record)} >修改</span>
                                    </span>
                                )} />
                        </Table>

                    </div>
                </div>
            </div>
        )
    }
}
export default connect(null, { doctorAll, doctorSave, doctorUpdate, doctorDelete, imageUpload })(DoctorManage)