import React, { Component } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import ToastWrap from "./ToastWrap";

class ToastBox extends Component {
  constructor() {
    super();
    this.transitionTime = 6000;
    this.state = { notices: [], open: true };
    this.removeNotice = this.removeNotice.bind(this);
  }
  handleClose = () => {
    this.setState({
      open: false
    });
  };

  getNoticeKey() {
    const { notices } = this.state;
    return `notice-${new Date().getTime()}-${notices.length}`;
  }

  addNotice(notice) {
    const { notices } = this.state;
    notice.key = this.getNoticeKey();

    // notices.push(notice);//展示所有的提示
    notices[0] = notice; //仅展示最后一个提示

    this.setState({ notices: notices, open: true });
    if (notice.duration > 0) {
      setTimeout(() => {
        this.removeNotice(notice.key);
      }, notice.duration);
    }
    return () => {
      this.removeNotice(notice.key);
    };
  }

  removeNotice(key) {
    const { notices } = this.state;
    this.setState({
      notices: notices.filter(notice => {
        if (notice.key === key) {
          if (notice.onClose) setTimeout(notice.onClose, this.transitionTime);
          return false;
        }
        return true;
      })
    });
  }

  render() {
    const { notices, open } = this.state;
    return (
      <div className="toast">
        {notices.map(notice => (
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center"
            }}
            open={open}
            autoHideDuration={6000}
            key={notice.key}
          >
            <ToastWrap
              onClose={this.handleClose}
              variant={notice.type}
              message={notice.content}
            />
          </Snackbar>
        ))}
      </div>
    );
  }
}

export default ToastBox;