import React, { Component } from 'react'
import {
  Button,
  Table,
  Input,
  DatePicker,
  message,
  Modal,
  Spin,
  Upload,
  Tooltip,
  Icon,
} from 'antd'
import { connect } from 'react-redux'
import {
  userBindStack,
  userStackDel,
  userStackExport,
  userImportStack,
  sendApplySampleInfo,
  jointGongWei,
  cancelApplyForm,
} from '../../../../actions/account'
import moment from 'moment'
import _ from 'lodash'
import XLSX from 'xlsx'
const { Column } = Table
const dateFormat = 'YYYY-MM-DD hh:mm:ss'
const { RangePicker } = DatePicker
const { confirm } = Modal

class UserStackManage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dataList: [],
      stackId: undefined,
      visible: false,
      total: 0,
      page: 1,
      pageSize: 10,
      projectList: [],
      masterIds: [],
      stackName: '',
      stackCode: '',
      barcodeNum: '',
      barcode: '',
      uname: '',
      idCard: '',
      startTime: undefined,
      endTime: undefined,
      choosedDates: [], //时间默认值
      visiblezutao: false,
      importSpinState: false,
      fileName: '',
      file: undefined,
      selectedRowKeys: [],
      selectedRowKeysbarcode: [],
      loadingSpinState: false,
      selectedRowKeyscode: [],
    }
  }
  componentDidMount() {
    this.loadDatas()
  }
  loadDatas = () => {
    var { page, pageSize, barcode, uname, idCard, startTime, endTime } =
      this.state
    const that = this
    var obj = { page: page - 1, size: pageSize }
    if (barcode !== '') {
      obj.barcode = barcode
    }
    if (uname !== '') obj.username = uname
    if (idCard !== '') obj.idCard = idCard
    if (endTime !== '' && endTime !== undefined) {
      obj.endTime = endTime
    }
    if (startTime !== '' && startTime !== undefined) {
      obj.startTime = startTime
    }
    this.props.userBindStack(obj).then((res) => {
      that.setState({
        dataList: res.data.data.rows,
        total: res.data.data.total,
      })
    })
  }

  barcodeOnChange = (e) => {
    this.setState({ barcode: e.target.value })
  }
  nameOnChange = (e) => {
    this.setState({ uname: e.target.value })
  }
  idCardOnChange = (e) => {
    this.setState({ idCard: e.target.value })
  }
  changePage = (page) => {
    var that = this
    this.setState(
      {
        page: page,
        selectedRowKeysbarcode: [],
        selectedRowKeyscode: [],
        selectedRowKeys: [],
      },
      () => {
        that.loadDatas()
      }
    )
  }
  pageSizeOnChange = (current, size) => {
    var that = this
    this.setState(
      {
        pageSize: size,
        selectedRowKeysbarcode: [],
        selectedRowKeyscode: [],
        selectedRowKeys: [],
      },
      () => {
        that.loadDatas()
      }
    )
  }
  datetimeChange = (date, dateString) => {
    // var choseDate =[];
    // choseDate = _.concat(choseDate,moment(dateString[0]+" 00:00:00","YYYY-MM-DD hh:mm:ss"));
    // choseDate = _.concat(choseDate,moment(dateString[1]+" 23:59:59","YYYY-MM-DD hh:mm:ss"));
    this.setState({
      startTime: dateString[0] === '' ? undefined : dateString[0] + ' 00:00:00',
      endTime: dateString[1] === '' ? undefined : dateString[1] + ' 23:59:59',
      choosedDates: date,
    })
  }
  export = () => {
    var { startTime, endTime } = this.state
    // var that = this;
    console.log(startTime, endTime)
    if (!startTime || !endTime) {
      message.warn('请选择起始时间!')
      return
    }
    var obj = { startTime, endTime }
    this.props.userStackExport(obj).then((res) => {
      const fileName = 'export' + moment().format('YYYYMMDDHHmmss')
      if ('download' in document.createElement('a')) {
        // 非IE下载
        const url = window.URL.createObjectURL(
          new Blob(
            [res.data],
            // 设置该文件的mime类型，这里对应的mime类型对应为.xlsx格式
            { type: 'application/vnd.ms-excel' }
          )
        )
        const link = document.createElement('a')
        link.href = url
        // 从header中获取服务端命名的文件名
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
      } else {
        // IE10+下载
        navigator.msSaveBlob(res.data, fileName)
      }
    })
  }
  groupImport = () => {
    this.setState({
      visiblezutao: true,
    })
  }
  onSelectChange = (selectedRowKeys) => {
    var { dataList } = this.state
    const commonData = dataList.filter((item) =>
      selectedRowKeys.includes(item.id)
    )
    let arr = []
    let arr1 = []
    commonData.map((res) => {
      arr.push(res.stackCode)
      arr1.push(res.barcode)
    })
    this.setState({
      selectedRowKeys: selectedRowKeys,
      selectedRowKeysbarcode: arr,
      selectedRowKeyscode: arr1,
    })
  }

  sendApplySampleInfo = () => {
    var { selectedRowKeys } = this.state
    if (selectedRowKeys.length == 0) {
      message.error('请先勾选数据')
      return
    }
    let that = this
    that.setState({ loadingSpinState: true }, () => {
      that.props
        .sendApplySampleInfo({ ids: selectedRowKeys })
        .then((res) => {
          console.log(res)
          if (res.data.code === 0) {
            message.success('请求成功！')
            that.setState({
              selectedRowKeysbarcode: [],
              selectedRowKeyscode: [],
              selectedRowKeys: [],
            })
          }
          message.success(res.data.msg)
          that.setState({
            loadingSpinState: false,
          })
        })
        .catch((error) => {
          that.setState({
            loadingSpinState: false,
          })
        })
    })
  }
  jointGongWeiInfo = () => {
    var { selectedRowKeysbarcode } = this.state
    if (selectedRowKeysbarcode.length == 0) {
      message.error('请先勾选数据')
      return
    }
    let that = this
    that.setState({ loadingSpinState: true }, () => {
      that.props
        .jointGongWei({ barcodeList: selectedRowKeysbarcode })
        .then((res) => {
          console.log(res)
          if (res.data.code === 0) {
            message.success('请求成功！')
            that.setState({
              selectedRowKeysbarcode: [],
              selectedRowKeyscode: [],
              selectedRowKeys: [],
            })
          }
          message.error(res.data.message)
          that.setState({
            loadingSpinState: false,
          })
        })
        .catch((error) => {
          that.setState({
            loadingSpinState: false,
          })
        })
    })
  }
  cancelApplyFormInfo = () => {
    var { selectedRowKeys, selectedRowKeyscode, selectedRowKeysbarcode } =
      this.state
    if (selectedRowKeyscode.length == 0) {
      message.error('请先勾选数据')
      return
    }

    this.postcancelApplyForm(selectedRowKeyscode, selectedRowKeysbarcode, 0, 0)
  }
  postcancelApplyForm = (arrid, arrcode, ind, indx) => {
    let that = this
    message.info('正在处理第' + (indx + 1) + '/' + arrid.length + '个')
    that.props
      .cancelApplyForm({ formCode: arrid[ind], barcode: arrcode[ind] })

      .then((res) => {
        ind++
        if (ind < arrid.length) {
          this.postcancelApplyForm(arrid, arrcode, ind, ind)
        } else {
          that.setState({
            selectedRowKeysbarcode: [],
            selectedRowKeyscode: [],
            selectedRowKeys: [],
          })
        }
        message.success(res.data.msg)
      })
  }
  importzutaoOk = () => {
    var { file } = this.state
    var formdata = new FormData()
    formdata.append('file', file)
    var that = this
    that.setState({ importSpinState: true }, () => {
      that.props.userImportStack(formdata).then((res) => {
        if (res.data.code === 200) {
          message.success('上传成功！')
          that.loadDatas()
          that.setState({
            fileName: '',
            file: undefined,
            visiblezutao: false,
            importSpinState: false,
          })
        } else {
          that.setState({


            importSpinState: false,
          })
          message.error(res.data.message)
        }
      })
    })
  }
  imporzutaoCancel = () => {
    this.setState({
      visiblezutao: false,
      fileName: '',
      file: undefined,
      importSpinState: false,
    })
  }
  s2ab(s) {
    const buf = new ArrayBuffer(s.length)
    const view = new Uint8Array(buf)
    for (let i = 0; i < s.length; i++) {
      view[i] = s.charCodeAt(i) & 0xff
    }
    return buf
  }
  downExcelMu = () => {
    const workbook = XLSX.utils.book_new()

    // 创建一个工作表
    const worksheet = XLSX.utils.aoa_to_sheet([
      ['组套条码', '体检条码', '姓名', '年龄', '性别', '身份证', '创建时间'],
    ])

    // 将工作表添加到工作簿
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')

    // 将工作簿转换为二进制数据
    const excelData = XLSX.write(workbook, { type: 'binary' })

    // 将二进制数据转换为Blob对象
    const blob = new Blob([this.s2ab(excelData)], {
      type: 'application/octet-stream',
    })

    // 创建一个下载链接
    const url = URL.createObjectURL(blob)

    // 创建一个新的<a>元素
    const link = document.createElement('a')
    link.href = url
    link.download = '导入组套模板.xlsx'

    // 模拟点击下载链接
    link.click()

    // 释放URL对象
    URL.revokeObjectURL(url)
  }
  customRequest = (option) => {
    this.setState({
      fileName: option.file.name,
      file: option.file,
    })
  }
  delItem = (id) => {
    var that = this

    confirm({
      content: '确认删除？',
      onOk() {
        that.props.userStackDel({ ids: id }).then((msg) => {
          if (msg.data.code === 200) {
            message.success('删除成功！')
            that.loadDatas()
          }
        })
      },
    })
  }

  render() {
    var {
      dataList,
      barcode,
      uname,
      idCard,
      choosedDates,
      visiblezutao,
      fileNamezutao,
      selectedRowKeys,
    } = this.state
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    }
    return (
      <div>
        <div className="StatisticsPage-head pageHeader">
          <h3>人员组套</h3>
        </div>
        <div className="StatisticsPage-box">
          <div className="StatisticsPage-search">
            <div className="StatisticsPage-btn">
              <RangePicker
                dateFormat={dateFormat}
                placeholder={['开始时间', '结束时间']}
                className="date"
                onChange={this.datetimeChange}
                value={choosedDates}
              />
              <Input
                className="ipt"
                placeholder="请输入组套条码"
                value={barcode}
                onChange={this.barcodeOnChange}
              />
              <Input
                className="ipt"
                placeholder="请输入姓名"
                value={uname}
                onChange={this.nameOnChange}
              />
              <Input
                className="ipt"
                placeholder="请输入身份证"
                value={idCard}
                onChange={this.idCardOnChange}
              />
              <Button className="btn" type="primary" onClick={this.loadDatas}>
                搜索
              </Button>
              <Button className="btn" type="primary" onClick={this.export}>
                导出
              </Button>
              <Button className="btn" type="primary" onClick={this.groupImport}>
                组套导入
              </Button>
              <Button
                className="btn"
                type="primary"
                onClick={this.sendApplySampleInfo}
              >
                兰卫外送样本信息
              </Button>
              <Button
                className="btn"
                type="primary"
                onClick={this.jointGongWeiInfo}
              >
                对接公卫
              </Button>
              <Button
                className="btn"
                type="primary"
                onClick={this.cancelApplyFormInfo}
              >
                取消外送
              </Button>
            </div>
          </div>
          <Spin tip="加载中..." spinning={this.state.loadingSpinState}>
            <div>
              <Table
                rowKey="id"
                size="middle"
                dataSource={dataList}
                rowSelection={rowSelection}
                pagination={{
                  pageSize: this.state.pageSize,
                  showTotal: (total) => {
                    return `共 ${total} 条`
                  },
                  onChange: this.changePage,
                  pageSizeOptions: ['10', '20', '50', '100', '500'],
                  total: this.state.total,
                  showSizeChanger: true,
                  onShowSizeChange: this.pageSizeOnChange,
                }}
                bordered
              >
                <Column
                  title="姓名"
                  dataIndex="username"
                  key="username"
                  align="center"
                />
                <Column title="性别" dataIndex="sex" key="sex" align="center" />
                <Column title="年龄" dataIndex="age" key="age" align="center" />
                <Column
                  title="体检条码"
                  dataIndex="barcode"
                  key="barcode"
                  align="center"
                />
                <Column
                  title="证件号"
                  dataIndex="idCard"
                  key="idCard"
                  align="center"
                />
                <Column
                  title="组套条码"
                  dataIndex="stackCode"
                  key="stackCode"
                  align="center"
                />
                <Column
                  title="项目名称"
                  dataIndex="projectNames"
                  key="projectNames"
                  align="center"
                />
                <Column
                  title="创建时间"
                  dataIndex="createdAt"
                  key="createdAt"
                  align="center"
                />
                <Column
                  width={50}
                  title="操作"
                  align="center"
                  key="action"
                  render={(text, record) => (
                    <span>
                      <span
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.delItem(record.id)}
                      >
                        删除
                      </span>
                    </span>
                  )}
                />
              </Table>
            </div>
          </Spin>
          <Modal
            width={600}
            title="组套导入"
            visible={visiblezutao}
            okText="导入"
            onOk={this.importzutaoOk}
            onCancel={this.imporzutaoCancel}
          >
            <Spin tip="导入中..." spinning={this.state.importSpinState}>
              <div className="form-group">
                <label className="form-label">文件：</label>
                <div className="form-ipt">
                  <Upload
                    showUploadList={false}
                    customRequest={this.customRequest}
                  >
                    <Tooltip placement="right">
                      <Button>
                        <Icon />
                        上传文件
                      </Button>
                    </Tooltip>
                  </Upload>
                  <span>{this.state.fileName}</span>
                </div>
              </div>
              <div className="form-group" style={{ paddingLeft: '17%' }}>
                <Button
                  type="link"
                  style={{ textAlign: 'left' }}
                  block
                  onClick={() => this.downExcelMu()}
                >
                  模板下载
                </Button>
              </div>
            </Spin>
          </Modal>
        </div>
      </div>
    )
  }
}
export default connect(null, {
  userBindStack,
  userStackDel,
  userStackExport,
  userImportStack,
  sendApplySampleInfo,
  jointGongWei,
  cancelApplyForm,
})(UserStackManage)
