import React, { Component } from 'react'
import { Modal, Button, Table, Input, message,  Select,Spin } from 'antd';

// import _ from 'lodash'

import { SearchOutlined} from '@ant-design/icons';

import '../medicalRecord/RegistrationPage.css'
import { BindMachineRemove,getallBindList, getMachine, addMachine, setOrganizationBindMachine, organizationPhysicalTypeSelect, getUsableMachine, getAlreadyMachineList, updateBindPhysicalType } from '../../../actions/account'

// import _ from 'lodash'
import { connect } from 'react-redux'
const { Column } = Table;
const { confirm } = Modal;
const { Option } = Select;

class MachineManage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputState:'2',
            keyword:'',
            spinning: false,
            physicalTypeId: undefined,
            physicalVisble: false,
            size: 10,
            allDataList: [],
            alreadyDataList: [],
            usableDataList: [],
            organList: [],
            physicalTypeList: [],
            curRecord: undefined,
            visible: false,
            page: 1,
            total: 0,
            machineCode: '',
            isRequesting: false,
            bindVisble: false,
            organCode: undefined,
            machineId: '',
            physicalType: '',
            recordId: '',
            bindId: '',
            bindList: [],
            dataList:[],
            isAdmin: JSON.parse(localStorage.getItem('data')).isAdmin === 1 ? true : false

        };
    }

    componentDidMount() {
        var that = this;
        this.props.organizationPhysicalTypeSelect().then(msg => {
            if (msg.data.code === 200) {
                that.setState({
                    bindList: msg.data.data
                })
            }
        })
        this.loadDatas();
    }

    loadDatas = () => {
        var { page, size,keyword } = this.state;
        var obj = { page: page - 1, size: size };
        var that = this;
        if(keyword!=='' && keyword!==undefined){
            obj.machineId=keyword;
        }
        this.setState({spinning:true})
        that.props.getMachine(obj).then(msg => {
            console.log(msg)
            if (msg.data.code === 200) {
                if (msg.data.data.rows.length === 0 && msg.data.data.total !== 0) {
                    that.setState({ page: page - 1 }, () => {
                        that.loadDatas()
                    })
                }
                else {
                    that.setState({
                        allDataList: msg.data.data.rows,
                        total: msg.data.data.total,
                    },()=>{
                        that.loadDatas2()
                    })
                }
            }
        })
    }
    loadDatas2 = () => {
        var that = this;
        if (this.state.isAdmin) {
            this.props.getallBindList().then(function (res) {
                console.log(res)
                if (res.data.code === 200) {
                    res.data.data.forEach(item => {
                        that.state.allDataList.forEach((it)=>{
                            if(it.machineId===item.machineId){
                                it.organizationName=item.organizationName;
                                it.physicalTypeName=item.physicalTypeName;
                                it.alreadyBindId=item.id;
                            }
                        })                     
                    });
                    that.setState({ dataList:that.state.allDataList,spinning:false });
                }
                else {
                    that.setState({ dataList:that.state.allDataList,spinning:false  });
                    message.error(res.data.message)
                }
            })
        }
        else {
            this.props.getAlreadyMachineList().then(function (res) {
                console.log(res)
                if (res.data.code === 200) {
                    res.data.data.forEach(item => {
                        that.state.allDataList.forEach((it)=>{
                            if(it.machineId===item.machineId){
                                it.organizationName=item.organizationName;
                                it.physicalTypeName=item.physicalTypeName;
                                it.alreadyBindId=item.id;
                            }
                        })                         

                     });
                     that.setState({ dataList:that.state.allDataList,spinning:false  });
                }
                else {
                    that.setState({ dataList:that.state.allDataList ,spinning:false });
                    message.error(res.data.message)
                }
            })
        }

    }

    showModal = () => {
        this.setState({
            curRecord: undefined,
            visible: true
        })
    }

    handleCancel = () => {
        this.setState({
            visible: false
        })
    }

    machineOnChange = e => {
        this.setState({
            machineCode: e.target.value
        })
    }

    handleOk = () => {
        var { machineCode, isRequesting } = this.state;
        if (isRequesting) {
            return
        }
        var that = this;
        this.setState({ isRequesting: true }, () => {
            that.props.addMachine({ machineId: machineCode }).then(msg => {
                console.log(msg)
                if (msg.data.code === 200) {
                    that.setState({ visible: false, machineCode: '' });
                    that.loadDatas();
                }
                else {
                    message.error(msg.data.message)
                }
                that.setState({ isRequesting: false })
            })
        })
    }
    editItem = (record) => {
        console.log(record)
        this.setState({
            curRecord: record,
            bindVisble: true,
            machineId: record.machineId,
            recordId: record.id
        })
    }

    organizationOnChange = (value) => {
        this.setState({ machineId: value })
    }
    bindIdOnChange = (value) => {
        this.setState({ bindId: value })
    }

    onRelieve= (id) => {
        var that = this;
        console.log(id)
        confirm({
            content: "确认解除绑定吗？",
            onOk() {
                that.props.BindMachineRemove({id:id.toString()}).then(msg=>{
                    if(msg.data.code=== 200){
                        message.success("解除成功！");
                        that.loadDatas();
                    }
                })
            }
        });

    }

    
    updateItem = (machineId, physicalTypeId) => {
        console.log(machineId, physicalTypeId)
        this.setState({ physicalVisble: true, machineId: machineId, physicalTypeId: physicalTypeId })
    }
    updateCancel = () => {
        this.setState({ physicalVisble: false, machineId: undefined, physicalTypeId: undefined });
    }
    updateOk = () => {
        var { physicalTypeId, machineId, isRequesting } = this.state;
        if (isRequesting) {
            return
        }
        var that = this;
        this.setState({ isRequesting: true }, () => {
            that.props.updateBindPhysicalType({ id: machineId, physicalType: physicalTypeId }).then(msg => {
                console.log(msg)
                if (msg.data.code === 200) {
                    that.updateCancel();
                    that.loadDatas2();
                }
                else {
                    message.error(msg.data.message)
                }
                that.setState({ isRequesting: false })
            })
        })
    }
    onphysicalTypeChange = e => {
        this.setState({
            physicalTypeId: e.target.value
        })
    };
    bandMachine = () => {
        var that = this;
        this.props.getUsableMachine().then(msg => {
            if (msg.data.code === 200) {
                that.setState({
                    usableDataList: msg.data.data,
                    bindVisble: true

                })
            }
            else {
                message.error(msg.data.message)
            }
        })
    }
    handleBindOk = () => {
        var { isRequesting, bindId, machineId } = this.state;
        if (isRequesting) {
            return
        }
        if((!bindId && bindId.length===0) || (!machineId && machineId.length===0)){
            return;
        }
        var that = this;
        this.setState({ isRequesting: true }, () => {
            that.props.setOrganizationBindMachine({
                bindId: bindId.toString(),
                machineId: machineId.toString()
            }).then(msg => {
                if (msg.data.code === 200) {
                    message.success("绑定成功！");
                    that.loadDatas()
                    that.handleBindCancel()
                }
                else {
                    message.error(msg.data.message)
                }
                that.setState({ isRequesting: false })
            })
        })

    }

    handleBindCancel = () => {
        this.setState({
            bindVisble: false,
            machineId: undefined,
            bindId: undefined,
        })
    }
    changePage = page => {
        var that = this;
        this.setState({ page }, () => {
            that.loadDatas()
        })
    }
    keywordChange=(e)=>{
        this.setState({ keyword: e.target.value })
    }
    btnSearch=()=>{
       this.loadDatas();
    }
    inputStateOnChange = (value) => {
        this.setState({ inputState: value })
    }
    render() {
        const { inputState,spinning,bindList, keyword,bindId, machineId, bindVisble, dataList, machineCode,  usableDataList} = this.state;
        return (
            <div>
                <div className="StatisticsPage-head pageHeader"><h3>机器管理</h3></div>
                <div className="StatisticsPage-box">
                    <div className="StatisticsPage-search">
                        <div className="StatisticsPage-btn">
                        <Select value={inputState} style={{ width: 120 }} onChange={this.inputStateOnChange} >
                            <Option key="1">机器编号</Option>
                            <Option key="2">绑定机构</Option>
                        </Select>
                        <Input className="ipt-search" value={keyword} placeholder="请根据所选字段输入关键字" onChange={e => this.keywordChange(e)} onPressEnter={this.btnSearch} />
                        <Button type="primary" icon={<SearchOutlined />} onClick={this.btnSearch} style={{ width: '60px' }}></Button>


                            <Button className="btn" type="primary" onClick={this.showModal}>添加机器</Button>
                            <Button className="btn" type="primary" onClick={this.bandMachine}>绑定机构</Button>
                            <Modal width="800px" title="机构-机器" visible={bindVisble} onOk={this.handleBindOk} onCancel={this.handleBindCancel} okText="保存" cancelText="取消">
                                <div className="form-group">
                                    <label className="form-label">机构/体检类型：</label>
                                    <div className="form-ipt" style={{ marginTop: '5px' }}>
                                        <Select style={{ width: '100%' }} value={bindId} onChange={this.bindIdOnChange}
                                            showSearch
                                            allowClear={true}
                                            filterOption={(input, option) =>
                                                // console.log(option)
                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {bindList.map(item => {
                                                return (<Option key={item.id}>{item.organizationName + '/' + item.physicalName}</Option>)
                                            })}
                                        </Select>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="form-label">机器：</label>
                                    <div className="form-ipt" style={{ marginTop: '5px' }}>
                                        <Select style={{ width: '60%' }} value={machineId} onChange={this.organizationOnChange}>
                                            {usableDataList.map(item => {
                                                return (<Option key={item.machineId}>{item.machineId}</Option>)
                                            })}
                                        </Select>
                                    </div>
                                </div>
                            </Modal>

                            
                            <Modal title="添加机器" visible={this.state.visible} onOk={this.handleOk} onCancel={this.handleCancel} okText="保存" cancelText="取消">
                                <div className="form-group">
                                    <label className="form-label">当前机器编号：</label>
                                    <div className="form-ipt">
                                        <Input value={machineCode} placeholder="请输入机器编号：" onChange={e => this.machineOnChange(e)} />
                                    </div>
                                </div>
                            </Modal>
                        </div>
                    </div>
                    <div>
                    <Spin tip="Loading..." spinning={spinning}>

                        <Table size="middle" dataSource={dataList} bordered rowKey="machineId" pagination={{
                            pageSize: this.state.size, showTotal: ((total) => {
                                return `共 ${total} 条`;
                            }),
                            onChange: this.changePage, total: this.state.total, showSizeChanger: false
                        }}  >
                            <Column title="机器编号" dataIndex="machineId" key="machineCode" align="center" />
                            <Column title="绑定机构" dataIndex="organizationName" key="organizationName" align="center" />
                            <Column title="绑定体检类型" dataIndex="physicalTypeName" key="physicalTypeName" align="center" />
                            <Column title="操作" align="center" key="action"
                                render={(text, record) => record.alreadyBindId?(
                                    <span>
                                        <span style={{ cursor: 'pointer' }} onClick={() => this.onRelieve(record.alreadyBindId)} >解除绑定</span>
                                    </span>
                                ):''} />
                        </Table>
                     </Spin>

                    </div>
                </div>
            </div>
        )
    }
}
export default connect(null, {BindMachineRemove, getallBindList, updateBindPhysicalType, getMachine, addMachine, setOrganizationBindMachine, getUsableMachine, organizationPhysicalTypeSelect, getAlreadyMachineList })(MachineManage)