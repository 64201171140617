import React, { Component } from 'react'
import './appointmentDate.css'
import {
  Modal,
  Button,
  Input,
  Table,
  Empty,
  Spin,
  DatePicker,
  Select,
  message,
  Divider,
  TimePicker, Checkbox
} from 'antd'
import { connect } from 'react-redux'
import {
  delDate,
  getAppointmentDateList,
  organizationPhysicalType,
  addAppointmentDate,
  updateAppointmentDate, appointmentParamList,
  addAppointmentDateDetail, mealList, appointmentParamRangeList
} from '../../../actions/account'
import moment from 'moment'
import {
  SearchOutlined,
  QrcodeOutlined,
  PlusCircleOutlined,
  MinusCircleOutlined,
  ClockCircleOutlined,
  EditOutlined,
} from '@ant-design/icons'
// import { ButtonBase } from '@material-ui/core';
const QRCode = require('qrcode.react')
const { RangePicker } = DatePicker
const dateFormat = 'YYYY-MM-DD'
const { Column } = Table
const { Option } = Select
const { confirm } = Modal
const { TextArea } = Input
class AppointmentList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      weekList: ['周一', '周二', '周三', '周四', '周五', '周六', '周日'],
      week: null,
      erweimaPageName: '',
      erweimaOrg: '',
      erweimaPhy: '',
      erweima: false,
      qrcodeURL: '',
      baseUrl: 'http://sample.yeekcloud.com',
      startDateDetail: '',
      endDateDetail: '',
      initNumber: '',
      dateDetailVisible: false,
      editAppointRecord: null,
      choosedDates2: [],
      startTime2: undefined,
      endTime2: undefined,
      newPhysicalTypeId: '',
      curRecord: undefined,
      resultVisble: false,
      phyId: '',
      physicalTypeList: [],
      guahaoList: [],
      organizationList: [],
      page: 1,
      total: 0,
      dataList: [],
      organList: [],
      unitname: '',
      barcode: '',
      uname: '',
      startTime: undefined,
      endTime: undefined,
      choosedDates: [], //时间默认值
      idcard: '',
      pageSize: 10,
      selectedRowKeys: [],
      selectedRowInfoKeys: [],
      organId: undefined,
      spinning: false,
      visible: false,
      submitSpinState: false,
      file: undefined,
      fileName: '',
      mealListArr: [],
      mealId: '',

      timedateArr: [],
      gettimearr: [],
      getDatetimeArr: [],
      timearr1: [],
      timearr: [{
        label: '周一',
        id: '1',

      }, {
        label: '周二',
        id: '2',

      }, {
        label: '周三',
        id: '3',

      }, {
        label: '周四',
        id: '4',

      }, {
        label: '周五',
        id: '5',

      }, {
        label: '周六',
        id: '6',

      }, {
        label: '周日',
        id: '7',
      },],
    }
  }
  componentDidMount() {
    var that = this
    this.loadDatas()
    this.timeWeek()
    this.getmealList()

    this.props.organizationPhysicalType().then((msg) => {
      if (msg.data.code === 200) {
        that.setState({
          physicalTypeList: msg.data.data.rows,
        })
      }
    })
  }
  timeWeek = () => {
    var { timearr } = this.state
    this.props.appointmentParamList({}).then((msg) => {
      console.log(msg)
      if (msg.data.code === 200) {
        if (msg.data.data.rows.length > 0) {
          let arr = []
          timearr.map(res => {
            msg.data.data.rows[0].week.split(',').map(res1 => {
              if (res.id == res1) {
                arr.push(res)
              }
            })
          })
          console.log(arr)
          this.setState({
            // weekList: msg.data.data.rows[0].week.split(','),
            timearr1: arr
          })
        }
      }
    })
  }
  getmealList = () => {
    var obj = { page: 0, size: 999, organizationId: JSON.parse(localStorage.getItem('data')).organizationId }
    var that = this
    this.setState({ spinning: true })
    this.props.mealList(obj).then((msg) => {
      console.log(msg)
      if (msg.data.code === 200) {
        that.setState({
          mealListArr: msg.data.data.rows,
        })
      }
    })
  }

  onOrganSelectChange = (value) => {
    this.setState({
      organId: value,
    })
  }
  onphySelectChange = (value) => {
    this.setState({
      phyId: value,
    })
  }
  onphySelectChange2 = (value) => {
    this.setState({
      newPhysicalTypeId: value,
    })
  }
  mealListArrSelectChange2 = (value) => {
    console.log(value)
    this.setState({
      mealId: value,
    })
  }
  onweekSelectChange = (value) => {
    console.log(value)
    this.setState({
      week: Number(value),
      getDatetimeArr: []
    })
    this.gettimeArr(value)
  }
  gettimeArr = (val) => {
    this.props.appointmentParamRangeList({ week: val }).then(res => {
      this.setState({
        timedateArr: res.data.data.rows
      })
    })
  }
  reset = () => {
    this.setState(
      {
        choosedDates: [],
        startTime: undefined,
        endTime: undefined,
        barcode: undefined,
        uname: undefined,
        idCard: undefined,
        organId: undefined,
        phyId: undefined,
      },
      () => {
        this.loadDatas()
      }
    )
  }
  loadDatas = () => {
    this.setState({
      spinning: true,
    })
    var { page, startTime, endTime, pageSize, phyId } = this.state
    var that = this
    var obj = {
      page: page - 1,
      size: pageSize,
    }
    if (startTime !== '' && startTime !== undefined) {
      obj.startTime = startTime
      // console.log( new Date(startTime.replace(/-/g, "/")))
    }
    if (endTime !== '' && endTime !== undefined) {
      obj.endTime = endTime

      // obj.endTime = new Date(endTime.replace(/-/g, "/"));
      // console.log( new Date(endTime.replace(/-/g, "/")) instanceof Date )
    }
    if (phyId !== '' && phyId !== undefined) {
      obj.physicalTypeId = phyId
    }
    this.props.getAppointmentDateList(obj).then((msg) => {
      that.setState({
        spinning: false,
      })
      if (msg.data.code === 200) {
        if (msg.data.data.rows.length === 0 && msg.data.data.total !== 0) {
          that.setState({ page: page - 1 }, () => {
            that.loadDatas()
          })
        } else {
          that.setState({
            dataList: msg.data.data.rows,
            total: msg.data.data.total,
          })
        }
      }
    })
  }

  unitnameOnChange = (e) => {
    this.setState({
      unitname: e.target.value,
    })
  }

  barcodeOnChange = (e) => {
    this.setState({
      barcode: e.target.value,
    })
  }

  nameOnChange = (e) => {
    this.setState({
      uname: e.target.value,
    })
  }

  datetimeChange = (date, dateString) => {
    // var choseDate =[];
    // choseDate = _.concat(choseDate,moment(dateString[0]+" 00:00:00","YYYY-MM-DD hh:mm:ss"));
    // choseDate = _.concat(choseDate,moment(dateString[1]+" 23:59:59","YYYY-MM-DD hh:mm:ss"));
    this.setState({
      startTime: dateString[0] === '' ? undefined : dateString[0] + ' 00:00:00',
      endTime: dateString[1] === '' ? undefined : dateString[1] + ' 23:59:59',
      choosedDates: date,
    })
  }
  datetimeChange2 = (date, dateString) => {
    this.setState({
      startTime2:
        dateString[0] === '' ? undefined : dateString[0] + ' 00:00:00',
      endTime2: dateString[1] === '' ? undefined : dateString[1] + ' 23:59:59',
      choosedDates2: date,
    })
  }
  onCalendarChange = (date, dateString) => {
    this.setState({
      startTime2:
        dateString[0] === '' ? undefined : dateString[0] + ' 00:00:00',
      endTime2: dateString[1] === '' ? undefined : dateString[1] + ' 23:59:59',
      choosedDates2: date,
    })
  }

  idCardOnChange = (e) => {
    this.setState({
      idCard: e.target.value,
    })
  }
  initNumberChange = (e) => {
    if (e.target.value < 0 || e.target.value > 1000) {
      message.warn('名额范围在1~1001以内！')
      this.setState({
        initNumber: '',
      })
      return
    }
    this.setState({
      initNumber: e.target.value,
    })
  }

  changePage = (page) => {
    var that = this
    this.setState(
      { page: page, selectedRowKeys: [], selectedRowInfoKeys: [] },
      () => {
        that.loadDatas()
      }
    )
  }

  pageSizeOnChange = (current, size) => {
    var that = this
    this.setState({ pageSize: size, selectedRowKeys: [] }, () => {
      that.loadDatas()
    })
  }

  importData = () => {
    this.setState({
      visible: true,
    })
  }

  customRequest = (option) => {
    console.log(option.file)
    this.setState({
      fileName: option.file.name,
      file: option.file,
    })
  }
  disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < moment().endOf('day')
  }
  addAppintCancel = () => {
    this.setState({
      editAppointRecord: null,
      visible: false,
      startTime2: undefined,
      endTime2: undefined,
      physicalSite: '',
      choosedDates2: [],
      newPhysicalTypeId: '',
      mealId: '',
    })
  }
  addDateDetailOk = () => {
    var that = this
    var {
      editAppointRecord,
      startDateDetail,
      endDateDetail,
      initNumber,
      week, getDatetimeArr, timedateArr

    } = this.state
    // console.log(editAppointRecord, startDateDetail, endDateDetail)
    console.log(getDatetimeArr, timedateArr)
    var date1 = editAppointRecord.startTime.substring(0, 10)
    var date2 = editAppointRecord.endTime.substring(0, 10)
    if (
      getDatetimeArr.length === 0
    ) {
      message.warn('请勾选时间段范围！')
      return
    }
    if (isNaN(initNumber)) {
      message.warn('名额为数字！')
      return
    }
    let arr = []

    timedateArr.map(res => {
      getDatetimeArr.map(res1 => {
        if (res.id == res1) {

          arr.push({
            week: week,
            appointmentId: editAppointRecord.id,
            initNumber: Number(initNumber),
            startTime: `${date1} ${res.startTime}`,
            endTime: `${date2} ${res.endTime}`,
          })
        }
      })
    })
    this.appointDate(arr, 0)

  }
  appointDate = (arr, ind) => {
    let that = this
    that.props.addAppointmentDateDetail(arr[ind]).then((res) => {

      if (res.data.code === 200) {
        message.success('设置成功！')
        if (arr.length - 1 == ind) {
          this.adddateDetailCancel()
          that.loadDatas()
        } else {
          ind++
          this.appointDate(arr, ind)
        }

      } else {
        message.warn(res.data.message)
      }
    })
  }
  adddateDetailCancel = () => {
    this.setState({
      editAppointRecord: null,
      dateDetailVisible: false,
      startDateDetail: '',
      endDateDetail: '',
      initNumber: '',
      week: null,
      gettimearr: [],
      timedateArr: [],
      getDatetimeArr: []
    })
  }
  editAppoint = (record) => {
    this.setState({
      editAppointRecord: record,
      startTime2: record.startTime,
      endTime2: record.endTime,
      newPhysicalTypeId: record.physicalTypeId,
      mealId: !!record.mealId ? record.mealId.split(',') : '',
      physicalSite: record.physicalSite,
      visible: true,
      choosedDates2: [moment(record.startTime), moment(record.endTime)],
    })
  }
  onSetDateDetail = (record) => {
    console.log(record)
    this.setState({
      dateDetailVisible: true,
      editAppointRecord: record,
    })
  }
  datedetailJump = (itm) => {
    this.props.history.push(
      {
        pathname: '/admin/AppointmentDateManage/AppointmentDateDetail',
        state: {
          appointmentId: itm.id
        }
      }
    )
  }
  addAppintOk = () => {
    var that = this
    var {
      newPhysicalTypeId,
      startTime2,
      endTime2,
      editAppointRecord,
      physicalSite, mealId
    } = this.state
    if (
      newPhysicalTypeId.length === 0 ||
      startTime2.length === 0 ||
      endTime2.length === 0 ||
      physicalSite.length === 0
    ) {
      message.warn('请填写完整！')
      return
    }
    console.log(editAppointRecord)
    var obj = {}
    if (editAppointRecord) {
      obj.id = editAppointRecord.id
      if (newPhysicalTypeId !== editAppointRecord.physicalTypeId)
        obj.physicalTypeId = newPhysicalTypeId
      if (startTime2 !== editAppointRecord.startTime) { obj.startTime = startTime2 }

      if (endTime2 !== editAppointRecord.endTime) { obj.endTime = endTime2 }
      if (physicalSite !== editAppointRecord.physicalSite) { obj.physicalSite = physicalSite }
      let editmealArr = editAppointRecord.mealId
      if (mealId !== editmealArr && !!mealId) { obj.mealId = !!mealId ? mealId.join(',') : '' }
      console.log(obj)
      that.setState({ submitSpinState: true }, () => {
        that.props.updateAppointmentDate(obj).then((res) => {
          that.loadDatas()
          if (res.data.code === 200) {
            message.success('修改成功！')
            that.setState({
              editAppointRecord: null,
              visible: false,
              startTime2: '',
              endTime2: '',
              physicalSite: '',
              choosedDates2: [],
              newPhysicalTypeId: '',
              mealId: '',
              submitSpinState: false,
            })
          } else {
            that.setState({
              submitSpinState: false,
            })
            message.warn(res.data.message)
          }
        })
      })
    } else {
      console.log(newPhysicalTypeId)
      obj = {
        physicalTypeId: newPhysicalTypeId,
        startTime: startTime2,
        endTime: endTime2,
        physicalSite: physicalSite,
        mealId: !!mealId ? mealId.join(',') : ''
      }
      that.setState({ submitSpinState: true }, () => {
        that.props.addAppointmentDate(obj).then((res) => {
          that.loadDatas()
          if (res.data.code === 200) {
            message.success('添加成功！')
            that.setState({
              visible: false,
              editAppointRecord: null,
              startTime2: '',
              endTime2: '',
              choosedDates2: [],
              newPhysicalTypeId: '',
              mealId: '',
              submitSpinState: false,
            })
          } else {
            that.setState({
              submitSpinState: false,
            })
            message.warn(res.data.message)
          }
        })
      })
    }
  }
  erweimaOk = () => {
    this.setState({
      qrcodeURL: '',
      erweima: false,
      erweimaOrg: '',
      erweimaPhy: '',
    })
  }
  addAppoint = () => {
    this.setState({
      visible: true,
    })
  }
  ondateDetailChange1 = (time, timeString) => {
    console.log(timeString)
    this.setState({
      startDateDetail: timeString,
    })
  }
  ondateDetailChange2 = (time, timeString) => {
    this.setState({
      endDateDetail: timeString,
    })
  }

  getStudentAppointmentCode = () => {
    var { phyId, physicalTypeList, baseUrl } = this.state
    if (physicalTypeList.length === 0) {
      message.warn('机构尚未绑定体检类型')
      return
    }
    if (phyId === undefined || phyId.length === 0) {
      message.warn('请选择体检类型')
      return
    }
    var tempPhy = ''
    var tempName = ''
    physicalTypeList.forEach((item) => {
      if (item.id === phyId) {
        tempPhy = item.physicalType
        tempName = item.organizationName + item.physicalName
      }
    })
    this.setState(
      {
        erweimaOrg: JSON.parse(localStorage.getItem('data')).organizationId,
        erweimaPhy: tempPhy,
        erweimaPageName: tempName,
        erweima: true,
      },
      () => {
        this.setState({
          qrcodeURL:
            baseUrl +
            '/StudentBooking?erweimaOrg=' +
            JSON.parse(localStorage.getItem('data')).organizationId +
            '&erweimaPhy=' +
            tempPhy +
            '&erweimaName=' +
            tempName,
        })
        //如需跳转取消注释
        this.props.history.push(
          '/StudentBooking?erweimaOrg=' +
          JSON.parse(localStorage.getItem('data')).organizationId +
          '&erweimaPhy=' +
          tempPhy +
          '&erweimaName=' +
          tempName
        )
      }
    )
  }
  getHealthphycode = () => {
    var { phyId, physicalTypeList, baseUrl } = this.state
    if (physicalTypeList.length === 0) {
      message.warn('机构尚未绑定体检类型')
      return
    }
    if (phyId === undefined || phyId.length === 0) {
      message.warn('请选择体检类型')
      return
    }
    var tempPhy = ''
    var tempName = ''
    physicalTypeList.forEach((item) => {
      if (item.id === phyId) {
        tempPhy = item.physicalType
        tempName = item.organizationName + item.physicalName
      }
    })
    this.setState(
      {
        erweimaOrg: JSON.parse(localStorage.getItem('data')).organizationId,
        erweimaPhy: tempPhy,
        erweimaPageName: tempName,
        erweima: true,
      },
      () => {
        this.setState({
          qrcodeURL:
            baseUrl +
            '/students_meal?erweimaOrg=' +
            JSON.parse(localStorage.getItem('data')).organizationId +
            '&erweimaPhy=' +
            tempPhy +
            '&erweimaName=' +
            tempName + '&erweimaOrgName=' +
            JSON.parse(localStorage.getItem('data')).organizationName
        })
        //如需跳转取消注释
        this.props.history.push(
          '/students_meal?erweimaOrg=' +
          JSON.parse(localStorage.getItem('data')).organizationId +
          '&erweimaPhy=' +
          tempPhy +
          '&erweimaName=' +
          tempName +
          '&erweimaOrgName=' +
          JSON.parse(localStorage.getItem('data')).organizationName
        )
      }
    )
  }
  getHealthCardCode2 = () => {
    var { phyId, physicalTypeList, baseUrl } = this.state
    if (physicalTypeList.length === 0) {
      message.warn('机构尚未绑定体检类型')
      return
    }
    if (phyId === undefined || phyId.length === 0) {
      message.warn('请选择体检类型')
      return
    }
    var tempPhy = ''
    var tempName = ''
    physicalTypeList.forEach((item) => {
      if (item.id === phyId) {
        tempPhy = item.physicalType
        tempName = item.organizationName + item.physicalName
      }
    })
    this.setState(
      {
        erweimaOrg: JSON.parse(localStorage.getItem('data')).organizationId,
        erweimaPhy: tempPhy,
        erweimaPageName: tempName,
        erweima: true,
      },
      () => {
        this.setState({
          qrcodeURL:
            baseUrl +
            '/HealthCertificateWithArchiveBooking?erweimaOrg=' +
            JSON.parse(localStorage.getItem('data')).organizationId +
            '&erweimaPhy=' +
            tempPhy +
            '&erweimaName=' +
            tempName,
        })
        //如需跳转取消注释
        this.props.history.push(
          '/HealthCertificateWithArchiveBooking?erweimaOrg=' +
          JSON.parse(localStorage.getItem('data')).organizationId +
          '&erweimaPhy=' +
          tempPhy +
          '&erweimaName=' +
          tempName
        )
      }
    )
  }
  getHealthCardCode = () => {
    var { phyId, physicalTypeList, baseUrl } = this.state
    if (physicalTypeList.length === 0) {
      message.warn('机构尚未绑定体检类型')
      return
    }
    if (phyId === undefined || phyId.length === 0) {
      message.warn('请选择体检类型')
      return
    }
    var tempPhy = ''
    var tempName = ''
    physicalTypeList.forEach((item) => {
      if (item.id === phyId) {
        tempPhy = item.physicalType
        tempName = item.organizationName + item.physicalName
      }
    })
    this.setState(
      {
        erweimaOrg: JSON.parse(localStorage.getItem('data')).organizationId,
        erweimaPhy: tempPhy,
        erweimaPageName: tempName,
        erweima: true,
      },
      () => {
        this.setState({
          qrcodeURL:
            baseUrl +
            '/HealthCertificateBooking?erweimaOrg=' +
            JSON.parse(localStorage.getItem('data')).organizationId +
            '&erweimaPhy=' +
            tempPhy +
            '&erweimaName=' +
            tempName,
        })
        //如需跳转取消注释
        this.props.history.push(
          '/HealthCertificateBooking?erweimaOrg=' +
          JSON.parse(localStorage.getItem('data')).organizationId +
          '&erweimaPhy=' +
          tempPhy +
          '&erweimaName=' +
          tempName
        )
      }
    )
  }
  getTwoCancerCode = () => {
    var { phyId, physicalTypeList, baseUrl } = this.state
    if (physicalTypeList.length === 0) {
      message.warn('机构尚未绑定体检类型')
      return
    }
    if (phyId === undefined || phyId.length === 0) {
      message.warn('请选择体检类型')
      return
    }
    var tempPhy = ''
    var tempName = ''
    physicalTypeList.forEach((item) => {
      if (item.id === phyId) {
        tempPhy = item.physicalType
        tempName = item.organizationName + item.physicalName
      }
    })
    this.setState(
      {
        erweimaOrg: JSON.parse(localStorage.getItem('data')).organizationId,
        erweimaPhy: tempPhy,
        erweimaPageName: tempName,
        erweima: true,
      },
      () => {
        //如需跳转取消注释
        if (tempName.includes('慢性')) {
          this.setState({
            qrcodeURL:
              baseUrl +
              '/YuyueMXB?erweimaOrg=' +
              JSON.parse(localStorage.getItem('data')).organizationId +
              '&erweimaPhy=' +
              tempPhy +
              '&erweimaName=' +
              tempName,
          })
          // this.props.history.push('/YuyueMXB?erweimaOrg=' + JSON.parse(localStorage.getItem('data')).organizationId + '&erweimaPhy=' + tempPhy + '&erweimaName=' + tempName)
        } else {
          this.setState({
            qrcodeURL:
              baseUrl +
              '/TwoCancerBooking?erweimaOrg=' +
              JSON.parse(localStorage.getItem('data')).organizationId +
              '&erweimaPhy=' +
              tempPhy +
              '&erweimaName=' +
              tempName +
              '&erweimaOrgName=' +
              JSON.parse(localStorage.getItem('data')).organizationName +
              '&sampleAccount=' +
              JSON.parse(localStorage.getItem('user')).account,
          })
          this.props.history.push(
            '/TwoCancerBooking?erweimaOrg=' +
            JSON.parse(localStorage.getItem('data')).organizationId +
            '&erweimaPhy=' +
            tempPhy +
            '&erweimaName=' +
            tempName +
            '&erweimaOrgName=' +
            JSON.parse(localStorage.getItem('data')).organizationName +
            '&sampleAccount=' +
            JSON.parse(localStorage.getItem('user')).account
          )
        }
      }
    )
  }
  getLaonainCode = () => {
    var tempPhy = ''
    var tempName = ''
    this.props.history.push(
      '/eldBooking?erweimaOrg=' +
      JSON.parse(localStorage.getItem('data')).organizationId +
      '&erweimaPhy=' +
      tempPhy +
      '&erweimaName=' +
      tempName +
      '&erweimaOrgName=' +
      JSON.parse(localStorage.getItem('data')).organizationName
    )
  }
  onSelectChange = (selectedRowKeys) => {
    console.log(selectedRowKeys)
    this.setState({ selectedRowKeys })
  }
  delItem = () => {
    var { selectedRowKeys } = this.state
    if (selectedRowKeys.length === 0) {
      message.warn('请选择删除项!')
      return
    }
    var that = this
    confirm({
      content: '确认删除？',
      onOk() {
        that.props.delDate({ ids: selectedRowKeys.join(',') }).then((msg) => {
          if (msg.data.code === 200) {
            message.info('删除成功')
            that.setState(
              {
                selectedRowKeys: [],
              },
              () => {
                that.loadDatas()
              }
            )
          } else {
            message.info(msg.data.message)
          }
        })
      },
    })
  }
  physicalSiteChange = (e) => {
    this.setState({ physicalSite: e.target.value.trim() })
  }
  CheckBoxChange = (e) => {
    this.setState({
      getDatetimeArr: e
    })
  }
  getmealArr = (mealId) => {
    console.log(mealId)
    if (!mealId) {
      return []
    }
    return mealId
  }
  render() {
    const {
      selectedRowKeys, getDatetimeArr,
      weekList,
      week,
      erweima,
      initNumber,
      phyId,
      dataList,
      spinning,
      choosedDates,
      newPhysicalTypeId,
      choosedDates2,
      dateDetailVisible,
      visible,
      physicalTypeList,
      startDateDetail, timearr1,
      endDateDetail, mealListArr, mealId, timedateArr
    } = this.state
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    }
    const disabledDate = (current) => {
      // console.log(current)
      // console.log(choosedDates2)
      if (!choosedDates2 || choosedDates2.length === 0) {
        return false
      }
      const tooLate =
        choosedDates2[0] && current.diff(choosedDates2[0], 'days') > 100
      const tooEarly =
        choosedDates2[1] && choosedDates2[1].diff(current, 'days') > 100
      return tooEarly || tooLate
    }
    return (
      <div>
        <div className="StatisticsPage-head pageHeader">
          <h3>体检预约日期设置</h3>
        </div>
        <div className="StatisticsPage-box has-select-table">
          <div className="StatisticsPage-search">
            <div className="StatisticsPage-btn">
              日期：
              <RangePicker
                dateFormat={dateFormat}
                placeholder={['开始时间', '结束时间']}
                className="date"
                onChange={this.datetimeChange}
                value={choosedDates}
              />
              体检类型：
              <Select
                className="ipt"
                allowClear={true}
                placeholder="请选择所属类型"
                value={phyId}
                onChange={this.onphySelectChange}
              >
                {physicalTypeList.map((phy) => {
                  return (
                    <Option key={phy.id}>
                      {phy.organizationName}--{phy.physicalName}
                    </Option>
                  )
                })}
              </Select>
              <Button
                className="btn"
                type="primary"
                onClick={this.loadDatas}
                icon={<SearchOutlined />}
              >
                搜索
              </Button>
              <Button className="btn" type="primary" onClick={this.reset}>
                重置
              </Button>
              <Button
                className="btn"
                type="primary"
                onClick={this.delItem}
                icon={<MinusCircleOutlined />}
              >
                删除
              </Button>
              <Button
                className="btn"
                type="primary"
                onClick={this.addAppoint}
                icon={<PlusCircleOutlined />}
              >
                新增体检预约
              </Button>
              <Button
                className="btn"
                type="primary"
                icon={<QrcodeOutlined />}
                onClick={this.getTwoCancerCode}
              >
                两癌预约二维码
              </Button>
              <Button
                className="btn"
                type="primary"
                icon={<QrcodeOutlined />}
                onClick={this.getLaonainCode}
              >
                公卫预约二维码
              </Button>
              <Button
                className="btn"
                type="primary"
                icon={<QrcodeOutlined />}
                onClick={this.getStudentAppointmentCode}
              >
                学生预约二维码
              </Button>
              <Button
                className="btn"
                type="primary"
                icon={<QrcodeOutlined />}
                onClick={this.getHealthCardCode}
              >
                健康证预约二维码
              </Button>
              <Button
                className="btn"
                type="primary"
                icon={<QrcodeOutlined />}
                onClick={this.getHealthCardCode2}
              >
                健康证预约二维码2
              </Button>
              <Button
                className="btn"
                type="primary"
                icon={<QrcodeOutlined />}
                onClick={this.getHealthphycode}
              >
                健康体检预约
              </Button>
              {/* <Button className="btn" type="primary" onClick={this.getQuery}>生成预约查询码</Button> */}
            </div>
          </div>
          <Spin tip="Loading..." spinning={spinning}>
            <div>
              {dataList.length > 0 ? (
                <Table
                  rowKey="id"
                  dataSource={dataList}
                  pagination={{
                    pageSize: this.state.pageSize,
                    showTotal: (total) => {
                      return `共 ${total} 条`
                    },
                    onChange: this.changePage,
                    total: this.state.total,
                    showSizeChanger: true,
                    onShowSizeChange: this.pageSizeOnChange,
                  }}
                  bordered
                  rowSelection={rowSelection}
                  onRow={(record, index) => {
                    return {
                      onClick: () => {
                        let temp = this.state.selectedRowKeys
                        let index = temp.indexOf(record.id)
                        if (index === -1) {
                          temp.push(record.id)
                        } else {
                          temp.splice(index, 1)
                        }
                        this.setState({ selectedRowKeys: temp })
                      },
                    }
                  }}
                >
                  <Column
                    title="开始日期"
                    dataIndex="startTime"
                    key="startTime"
                    align="center"
                  />
                  <Column
                    title="结束日期"
                    dataIndex="endTime"
                    key="endTime"
                    align="center"
                  />
                  <Column
                    title="体检类型"
                    dataIndex="physicalType"
                    key="physicalType"
                    align="center"
                  />
                  <Column
                    title="体检地点"
                    dataIndex="physicalSite"
                    key="physicalSite"
                    align="center"
                  />
                  <Column
                    title="套餐名称"
                    dataIndex="mealName"
                    key="mealName"
                    align="center"
                  />
                  <Column
                    title="创建时间"
                    dataIndex="createdAt"
                    key="createdAt"
                    align="center"
                  />
                  <Column
                    title="操作"
                    key="operate"
                    align="center"
                    render={(text, record) => (
                      <span>
                        <Button
                          size="small"
                          onClick={() => {
                            this.onSetDateDetail(record)
                          }}
                          icon={<ClockCircleOutlined />}
                        >
                          添加时间段
                        </Button>

                        <Divider type="vertical" />
                        <Button
                          size="small"
                          type="default"
                          onClick={() => {
                            this.editAppoint(record)
                          }}
                          icon={<EditOutlined />}
                        >
                          修改
                        </Button>

                        <Divider type="vertical" />
                        <Button
                          size="small"
                          type="default"
                          onClick={() => {
                            this.datedetailJump(record)
                          }}
                        >
                          时间段查询
                        </Button>
                      </span>
                    )}
                  />
                </Table>
              ) : (
                <Empty />
              )}
            </div>
          </Spin>
        </div>

        <Modal
          width={800}
          maskClosable={false}
          title="预约日期设置"
          visible={visible}
          okText="提交"
          onOk={this.addAppintOk}
          onCancel={this.addAppintCancel}
        >
          <Spin tip="提交中..." spinning={this.state.submitSpinState}>
            <div className="form-group">
              <label className="form-label ">套餐：</label>
              <div className="form-ipt">
                <Select
                  mode="multiple"
                  className="ipt"
                  allowClear={true}
                  placeholder="请选择所属类型"
                  value={this.getmealArr(mealId)}
                  onChange={this.mealListArrSelectChange2}
                >
                  {mealListArr.map((phy) => {
                    return (
                      <Option key={phy.id}>
                        {phy.mealName}
                      </Option>
                    )
                  })}
                </Select>
              </div>
            </div>
            <div className="form-group">
              <label className="form-label require">预约事件：</label>
              <div className="form-ipt">
                <Select
                  className="ipt"
                  allowClear={true}
                  placeholder="请选择所属类型"
                  value={newPhysicalTypeId}
                  onChange={this.onphySelectChange2}
                >
                  {physicalTypeList.map((phy) => {
                    return (
                      <Option key={phy.id}>
                        {phy.organizationName}--{phy.physicalName}
                      </Option>
                    )
                  })}
                </Select>
              </div>
            </div>
            <div className="form-group">
              <label className="form-label require">日期设置：</label>
              <div className="form-ipt">
                {/* <RangePicker disabledDate={this.disabledDate} dateFormat={dateFormat} placeholder={['开始时间', '结束时间']} className="date"
                                    onChange={this.datetimeChange2} value={choosedDates2} /> */}
                <RangePicker
                  dateFormat={dateFormat}
                  placeholder={['开始时间', '结束时间']}
                  className="date"
                  disabledDate={disabledDate}
                  onChange={this.datetimeChange2}
                  onCalendarChange={this.onCalendarChange}
                  value={choosedDates2}
                />
              </div>
            </div>
            <div className="form-group">
              <label className="form-label require">体检地点：</label>
              <div className="form-ipt">
                <TextArea
                  value={this.state.physicalSite}
                  placeholder="请输入体检地点"
                  onChange={this.physicalSiteChange}
                />
              </div>
            </div>
          </Spin>
        </Modal>
        <Modal
          width={800}
          maskClosable={false}
          title="二维码链接"
          visible={erweima}
          okText="确定"
          onOk={this.erweimaOk}
          onCancel={this.erweimaOk}
        >
          <div className="form-group">
            {/* <label className="form-label require">二维码</label> */}
            <div className="form-ipt">
              <QRCode
                id="qrcode"
                //二维码内容
                value={this.state.qrcodeURL}
                size={100}
                fgColor="#515151"
                style={{ cursor: 'pointer' }}
                imageSettings={{
                  // 二维码中间的图片
                  src: '../../../assets/login-img/logo.jpg', //图片路径
                  // 图片大小 不能太大要根据二维码的宽高进行适当调整，避免二维码被遮挡过大，导致不能正常臊面
                  height: 10,
                  width: 10,
                  excavate: true, // 中间图片所在的位置是否镂空
                }}
              />
            </div>
          </div>
        </Modal>
        <Modal
          width={800}
          maskClosable={false}
          title="时间段设置"
          visible={dateDetailVisible}
          okText="提交"
          onOk={this.addDateDetailOk}
          onCancel={this.adddateDetailCancel}
        >
          <Spin tip="提交中..." spinning={this.state.submitSpinState}>
            <div className="form-group">
              <label className="form-label require">名称：</label>
              <div className="form-ipt">
                {this.state.editAppointRecord && (
                  <p>{this.state.editAppointRecord.physicalType}</p>
                )}
              </div>
            </div>
            <div className="form-group">
              <label className="form-label require">日期：</label>
              <div className="form-ipt">
                {this.state.editAppointRecord && (
                  <p>
                    {this.state.editAppointRecord.startTime}
                    {'   至   '}
                    {this.state.editAppointRecord.endTime}
                  </p>
                )}
              </div>
            </div>
            {/* <div className="form-group">
              <label className="form-label require">时间段范围：</label>
              <div className="form-ipt">
                <TimePicker
                  onChange={this.ondateDetailChange1}
                  value={
                    startDateDetail && startDateDetail.length > 0
                      ? moment(startDateDetail, 'HH:mm:ss')
                      : null
                  }
                />
                <span style={{ padding: '0 20px' }}>--</span>
                <TimePicker
                  onChange={this.ondateDetailChange2}
                  value={
                    endDateDetail && endDateDetail.length > 0
                      ? moment(endDateDetail, 'HH:mm:ss')
                      : null
                  }
                />
              </div>
            </div> */}
            <div className="form-group">
              <label className="form-label require">名额：</label>
              <div className="form-ipt">
                <Input
                  placeholder="名额"
                  value={initNumber}
                  onChange={(e) => this.initNumberChange(e)}
                />
              </div>
            </div>
            <div className="form-group">
              <label className="form-label require">周几：</label>
              <div className="form-ipt">
                <Select
                  className="ipt"
                  allowClear={true}
                  placeholder="请选择所属类型"
                  value={week}
                  onChange={this.onweekSelectChange}
                >
                  {timearr1.map((item, index) => {
                    return (
                      <Option key={item.id} value={item.id}>
                        {item.label}
                      </Option>
                    )
                  })}
                </Select>
              </div>
            </div>
            <div className="form-group">
              <label className="form-label require">时间段范围：</label>
              <div className="form-ipt">
                {/* { timedateArr} */}
                <Checkbox.Group
                  value={getDatetimeArr}
                  onChange={e => this.CheckBoxChange(e)}
                >
                  {timedateArr.map(res => (
                    <Checkbox value={res.id}>{res.startTime}-{res.endTime}</Checkbox>
                  ))}

                </Checkbox.Group>
              </div>
            </div>
          </Spin>
        </Modal>
      </div>
    )
  }
}
export default connect(null, {
  getAppointmentDateList,
  organizationPhysicalType,
  addAppointmentDate,
  updateAppointmentDate,
  addAppointmentDateDetail, appointmentParamList,
  delDate, mealList, appointmentParamRangeList
})(AppointmentList)
