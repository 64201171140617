import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Select, Input, Checkbox, DatePicker } from 'antd';
import CheckBox from 'rc-checkbox';
import _ from 'lodash'
const { Option } = Select;


class EstablishAchives extends Component {
   constructor(props){
       super(props);
       this.state={
           jbsValue:"1",
           jbHistories:[],
           operations:[],
           traumas:[]

       }
   }

   onJbsChange=(value,index)=>{
       var {jbHistories} = this.state;
       var history = _.find(jbHistories,['index',index]);
       history.value= value;
       this.setState({jbHistories})

   }

   addIllHistory=()=>{
       var maxIndex=0
       var {jbHistories} = this.state;
       if(jbHistories.length!==0){
        maxIndex = jbHistories[jbHistories.length-1].index
       }
       maxIndex++;
       jbHistories= _.concat(jbHistories,{index:maxIndex})
       this.setState({
           jbHistories
       })
   }

   addoperHistory=()=>{
    var maxIndex=0
    var {operations} = this.state;
    if(operations.length!==0){
     maxIndex = operations[operations.length-1].index
    }
    maxIndex++;
    operations= _.concat(operations,{index:maxIndex})
    this.setState({
        operations
    })
   }

   addtraumasHistory=()=>{
    var maxIndex=0
    var {traumas} = this.state;
    if(traumas.length!==0){
     maxIndex = traumas[traumas.length-1].index
    }
    maxIndex++;
    traumas= _.concat(traumas,{index:maxIndex})
    this.setState({
        traumas
    })
   }
    render() {
        var {jbHistories,operations,traumas} = this.state;
        return (
            <div>
                <div className="StatisticsPage-head pageHeader"><h3>建立居民档案</h3></div>
                    <div className="form-group">
                        <label className="form-label">常住类型：</label>
                        <div className="form-ipt">
                            <Select style={{ width: 200 }}>
                                <Option value="未录入">户籍</Option>
                                <Option value="正常">非户籍</Option>
                            </Select>
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="form-label">文化程度：</label>
                        <div className="form-ipt">
                            <Select style={{ width: 200 }}>
                                <Option value="未录入">文盲及半文盲</Option>
                                <Option value="正常">小学</Option>
                                <Option value="正常">初中</Option>
                                <Option value="正常">高中/技校/中专</Option>
                                <Option value="正常">大学专科及以上</Option>
                                <Option value="正常">不详</Option>
                            </Select>
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="form-label">职业：</label>
                        <div className="form-ipt">
                            <Select style={{ width: 200 }}>
                                <Option value="未录入">国家机关、党群组织、企业、事业单位负责人</Option>
                                <Option value="正常">专业技术人员</Option>
                                <Option value="正常">办事人员和有关人员</Option>
                                <Option value="正常">商业、服务业人员</Option>
                                <Option value="正常">农、林、牧、渔、水利业生产人员</Option>
                                <Option value="正常">生产、运输设备操作人员及有关人员</Option>
                                <Option value="正常">军人</Option>
                                <Option value="正常">不便分类的其他从业人员</Option>
                            </Select>
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="form-label">婚姻状况：</label>
                        <div className="form-ipt">
                            <Select style={{ width: 200 }}>
                                <Option value="未录入">未婚</Option>
                                <Option value="正常">已婚</Option>
                                <Option value="正常">丧偶</Option>
                                <Option value="正常">离婚</Option>
                                <Option value="正常">未说明的婚姻状况</Option>
                            </Select>
                        </div>
                    </div>
                    <div className="form-group-compact">
                        <label className="form-label">医疗方式 ：</label>
                        <div className="form-ipt">
                            <Select style={{ width: 200 }}>
                                <Option value="1">城镇职工基本医疗保险</Option>
                                <Option value="2">城镇居民基本医疗保险</Option>
                                <Option value="3">新型农村合作医疗</Option>
                                <Option value="4">贫困救助</Option>
                                <Option value="5">商业医疗保险</Option>
                                <Option value="6">全公费</Option>
                                <Option value="7">全自费</Option>
                                <Option value="8">其他</Option>
                            </Select>
                        </div>
                    </div> 
                    <div className="form-group-compact">
                        <label className="form-label"></label>
                        <div className="form-ipt">
                            <Input  style={{ width: 200 }}></Input>
                            
                        </div>
                    </div>
                   
                    <div className="form-group-compact">
                        <label className="form-label">药物过敏史 ：</label>
                        <div className="form-ipt">
                            <Select style={{ width: 200 }}>
                                <Option value="1">无</Option>
                                <Option value="2">有</Option>
                            
                            </Select>
                        </div>
                    </div> 
                    <div className="form-group-compact">
                    <label className="form-label"></label>
                       <CheckBox/>青霉素
                       <CheckBox/>磺胺
                       <CheckBox/>链霉素
                       <CheckBox/>其他
                       <Input  style={{ width: 100 }}></Input>
                    </div>
                    <div className="form-group-compact">
                        <label className="form-label">暴露史 ：</label>
                        <div className="form-ipt">
                            <Select style={{ width: 200 }}>
                                <Option value="1">无</Option>
                                <Option value="2">有</Option>
                            
                            </Select>
                        </div>
                    </div> 
                    <div className="form-group-compact">
                    <label className="form-label"></label>
                        <Checkbox/>化学品
                        <Checkbox/>毒物
                        <Checkbox/>射线
                    </div>
                    <div className="form-group-compact">
                    <label className="form-label">疾病史</label>
                    <div style={{cursor:'pointer'}} onClick={this.addIllHistory}>+增加疾病史</div>
                    </div>
                    {jbHistories.map((history,index)=>{
                        return  <div className="form-group-compact" key={"history"+index}>
                            <Select style={{ width: 130 }} onChange={(value)=>this.onJbsChange(value,history.index)}>
                            <Option value={2}>高血压</Option>
                            <Option value={3}>糖尿病</Option>
                            <Option value={4}>冠心病</Option>
                            <Option value={5}>慢性阻塞性肺疾病</Option>
                            <Option value={6}>恶性肿瘤</Option>
                            <Option value={7}>脑卒中</Option>
                            <Option value={8} >严重精神障碍</Option>
                            <Option value={9}>结核病</Option>
                            <Option value={10}>肝炎</Option>
                            <Option value={11}>其他法定传染病</Option>
                            <Option value={12}>职业病</Option>
                            <Option value={13}>其他</Option>
                            </Select> 
                        {history.value===6||history.value===12||history.value===13?
                            <Input style={{width:100}}></Input>:null
                        }
                            <DatePicker style={{width:100}}></DatePicker>
                            <span>删除</span>
                        </div>
                    })}
                     <div className="form-group-compact">
                    <label className="form-label">手术史</label>
                    <div style={{cursor:'pointer'}} onClick={this.addoperHistory}>+增加疾病史</div>
                    </div>
                    {operations.map(operation=>{
                        return <div className="form-group-compact" key={"history"+operation.index}>
                             <Input style={{width:100}}></Input>
                            <DatePicker style={{width:100}}></DatePicker>
                            <span>删除</span>
                        </div>
                    })}
                       <div className="form-group-compact">
                    <label className="form-label">外伤史</label>
                    <div style={{cursor:'pointer'}} onClick={this.addtraumasHistory}>+增加疾病史</div>
                    </div>
                    {traumas.map(trauma=>{
                        return <div className="form-group-compact" key={"history"+trauma.index}>
                        <Input style={{width:100}}></Input>
                       <DatePicker style={{width:100}}></DatePicker>
                       <span>删除</span>
                   </div>
                    })}
                    
            </div>
        )
    }
}
export default connect(null, {  })(EstablishAchives) 