import React, { Component } from 'react'
import { Modal, Button, Table, message, Select, Spin, Divider } from 'antd'
import '../organizationBS/index.css'
import {
  updatePhyProjectList,
  removePhyProjectList,
  getPhysicalTypeList,
  projectSave,
  getParentList,
  savePhyProjectList,
  getPhyProjectList,
} from '../../../../actions/account'

// import _ from 'lodash'
import { connect } from 'react-redux'
const { Option } = Select
const { Column } = Table
const { confirm } = Modal

class MachineManage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      spinning: false,
      projectName: '',
      projectCode: '',
      rank: '',
      projectId: '',
      selectedRowKeys: [],
      phyList: [],

      alreadyDataList: [],
      newPhysicalTypeName: '',
      size: 10,
      dataList: [],
      curRecord: undefined,
      visible: false,
      page: 1,
      total: 0,
      isRequesting: false,
      bindVisble: false,
      bindphyVisble: false,
      orgList: [],
      organizationId: '',
      physicalType: '',
    }
  }

  componentDidMount() {
    this.loadDatas()
    var that = this
    this.props.getPhysicalTypeList().then((msg) => {
      console.log(msg)
      if (msg.data.code === 200) {
        that.setState({
          phyList: msg.data.data,
        })
      }
    })
    this.props.getParentList().then((msg) => {
      console.log(msg)
      if (msg.data.code === 200) {
        that.setState({
          dataList: msg.data.data,
        })
      }
    })
  }

  loadDatas = () => {
    var { page, size } = this.state
    var obj = { page: page - 1, size: size }
    var that = this
    this.setState({ spinning: true })
    this.props.getPhyProjectList(obj).then((msg) => {
      console.log(msg)
      if (msg.data.code === 200) {
        if (msg.data.data.rows.length === 0 && msg.data.data.total !== 0) {
          that.setState({ page: page - 1 }, () => {
            that.loadDatas()
          })
        } else {
          that.setState({
            dataList2: msg.data.data.rows,
            total: msg.data.data.total,
            spinning: false,
          })
        }
      }
    })
  }

  showModal = () => {
    this.setState({
      curRecord: undefined,
      visible: true,
    })
  }

  handleCancel = () => {
    this.setState({
      visible: false,
      projectName: '',
      projectCode: '',
      rank: '',
      parentId: '',
    })
  }
  handleBindCancel = () => {
    this.setState({
      bindVisble: false,
      newPhysicalTypeName: '',
      curRecord: undefined,
    })
  }
  projectNameChange = (e) => {
    this.setState({
      projectName: e.target.value,
    })
  }
  projectCodeChange = (e) => {
    this.setState({
      projectCode: e.target.value,
    })
  }
  rankChange = (e) => {
    this.setState({
      rank: e.target.value,
    })
  }

  handleOk = () => {
    var { projectName, projectCode, isRequesting, rank } = this.state
    if (isRequesting) {
      return
    }
    var that = this
    this.setState({ isRequesting: true }, () => {
      that.props
        .projectSave({
          projectName: projectName,
          projectCode: projectCode,
          rank: Number(rank),
        })
        .then((msg) => {
          console.log(msg)
          if (msg.data.code === 200) {
            message.success('添加成功')
            that.handleCancel()
            that.loadDatas()
          } else {
            message.error(msg.data.message)
          }
          that.setState({ isRequesting: false })
        })
    })
  }
  editItem = (record) => {
    console.log(record)
    this.setState({
      curRecord: record,
      physicalType: record.typeId.toString(),
      projectId: record.projectId.toString(),
      updatephyVisble: true,
    })
  }
  editAlreadyRecord = (record) => {
    console.log(record)
    this.setState({
      curRecord: record,
      organizationId: record.organizationId,
      physicalType: record.physicalType,
      updatephyVisble: true,
    })
  }
  projectOnChange = (value) => {
    this.setState({ projectId: value })
  }
  phyOnChange = (value) => {
    this.setState({ physicalType: value })
  }

  bandphy = () => {
    var that = this
    that.setState({
      bindphyVisble: true,
    })
  }
  handleBindphyOk = () => {
    var { isRequesting, projectId, physicalType } = this.state
    if (isRequesting) {
      return
    }
    var that = this
    this.setState({ isRequesting: false }, () => {
      that.props
        .savePhyProjectList({
          typeId: Number(physicalType),
          projectId: Number(projectId),
        })
        .then((msg) => {
          if (msg.data.code === 200) {
            message.success('绑定成功！')
            that.loadDatas()
            that.handleBindphyCancel()
          } else {
            message.error(msg.data.message)
          }
          that.setState({ isRequesting: false })
        })
    })
  }
  UpdateBindphyOk = () => {
    var { isRequesting, physicalType, curRecord, projectId } = this.state
    if (isRequesting) {
      return
    }
    var that = this
    this.setState({ isRequesting: true }, () => {
      that.props
        .updatePhyProjectList({
          id: Number(curRecord.id),
          typeId: Number(physicalType),
          projectId: Number(projectId),
        })
        .then((msg) => {
          if (msg.data.code === 200) {
            message.success('修改成功！')
            that.loadDatas()
            that.UpdateBindphyCancel()
          } else {
            message.error(msg.data.message)
          }
          that.setState({ isRequesting: false })
        })
    })
  }

  UpdateBindphyCancel = () => {
    this.setState({
      updatephyVisble: false,
      curRecord: undefined,
      physicalType: '',
      projectId: '',
    })
  }
  handleBindphyCancel = () => {
    this.setState({
      bindphyVisble: false,
      physicalType: '',
      projectId: '',
    })
  }
  changePage = (page) => {
    var that = this
    this.setState({ page }, () => {
      that.loadDatas2()
    })
  }
  // 选中改变
  onSelectChange = (selectedRowKeys) => {
    console.log(selectedRowKeys)
    this.setState({ selectedRowKeys })
  }
  delItem = (recorrd) => {
    var { selectedRowKeys } = this.state
    if (selectedRowKeys.length === 0 && recorrd === 'all') {
      message.error('请选择删除的记录')
      return
    }
    if (recorrd !== 'all') {
      this.setState({
        selectedRowKeys: [recorrd.id],
      })
    }
    var that = this
    confirm({
      content: '确认删除？',
      onOk() {
        var That = that
        that.props
          .removePhyProjectList({ ids: selectedRowKeys.join(',') })
          .then((msg) => {
            if (msg.data.code === 200) {
              message.success('删除成功！')
              That.setState({ selectedRowKeys: [] })
              That.loadDatas()
            }
          })
      },
    })
  }
  changePage = (page) => {
    var that = this
    this.setState({ page }, () => {
      that.loadDatas()
    })
  }
  render() {
    const {
      dataList2,
      projectId,
      selectedRowKeys,
      phyList,
      spinning,
      updatephyVisble,
      physicalType,
      dataList,
      bindphyVisble,
    } = this.state
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    }
    return (
      <div>
        <div className="StatisticsPage-head pageHeader">
          <h3>体检类型-项目关联</h3>
        </div>
        <div className="StatisticsPage-box">
          <div className="StatisticsPage-search">
            <div className="StatisticsPage-btn">
              <Button
                className="btn"
                type="primary"
                onClick={() => this.delItem('all')}
              >
                删除
              </Button>

              <Button className="btn" type="primary" onClick={this.bandphy}>
                添加绑定
              </Button>

              <Modal
                title="机构-体检类型"
                visible={bindphyVisble}
                onOk={this.handleBindphyOk}
                onCancel={this.handleBindphyCancel}
                okText="保存"
                cancelText="取消"
              >
                <div className="form-group">
                  <label className="form-label">项目名称：</label>
                  <div className="form-ipt" style={{ marginTop: '5px' }}>
                    <Select
                      style={{ width: '90%' }}
                      value={projectId}
                      onChange={this.projectOnChange}
                    >
                      {dataList.map((item) => {
                        return <Option key={item.id}>{item.projectName}</Option>
                      })}
                    </Select>
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-label">体检类型：</label>
                  <div className="form-ipt" style={{ marginTop: '5px' }}>
                    <Select
                      style={{ width: '60%' }}
                      value={physicalType}
                      onChange={this.phyOnChange}
                    >
                      {phyList.map((item) => {
                        return (
                          <Option key={item.id}>{item.physicalType}</Option>
                        )
                      })}
                    </Select>
                  </div>
                </div>
              </Modal>
            </div>
          </div>
          <div>
            <Spin tip="Loading..." spinning={spinning}>
              <Table
                size="middle"
                dataSource={dataList2}
                bordered
                rowKey="id"
                pagination={{
                  pageSize: this.state.size,
                  showTotal: (total) => {
                    return `共 ${total} 条`
                  },
                  onChange: this.changePage,
                  total: this.state.total,
                  showSizeChanger: false,
                }}
                rowSelection={rowSelection}
                onRow={(record, index) => {
                  return {
                    onClick: () => {
                      let temp = this.state.selectedRowKeys
                      let index = temp.indexOf(record.id)
                      if (index === -1) {
                        temp.push(record.id)
                      } else {
                        temp.splice(index, 1)
                      }
                      this.setState({ selectedRowKeys: temp })
                    },
                  }
                }}
              >
                <Column
                  title="体检类型"
                  dataIndex="typeName"
                  key="typeName"
                  align="center"
                />
                <Column
                  title="大项名称"
                  dataIndex="projectName"
                  key="projectName"
                  align="center"
                />
                {/* <Column title="小项"  dataIndex="slaveProject" key="slaveProject" align="center"  render={(text, record) => {
                                           
                                          return( <span>{record.slaveProject.map((item)=><span key={item.id}>{item.projectName+'  |  '}</span>)}</span>)
                                        }} /> */}
                <Column
                  title="操作"
                  align="center"
                  key="action"
                  render={(text, record) => (
                    <span>
                      <span
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.editItem(record)}
                      >
                        修改绑定
                      </span>
                      <Divider type="vertical" />
                      <span
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.delItem(record)}
                      >
                        删除
                      </span>
                    </span>
                  )}
                />
              </Table>
            </Spin>
            <Modal
              title="机构-体检类型-修改"
              visible={updatephyVisble}
              onOk={this.UpdateBindphyOk}
              onCancel={this.UpdateBindphyCancel}
              okText="保存"
              cancelText="取消"
            >
              <div className="form-group">
                <label className="form-label">大项：</label>
                <div className="form-ipt" style={{ marginTop: '5px' }}>
                  <Select
                    style={{ width: '90%' }}
                    value={projectId}
                    onChange={this.projectOnChange}
                  >
                    {dataList.map((item) => {
                      return <Option key={item.id}>{item.projectName}</Option>
                    })}
                  </Select>
                </div>
              </div>
              <div className="form-group">
                <label className="form-label">体检类型：</label>
                <div className="form-ipt" style={{ marginTop: '5px' }}>
                  <Select
                    style={{ width: '60%' }}
                    value={physicalType}
                    onChange={this.phyOnChange}
                  >
                    {phyList.map((item) => {
                      return <Option key={item.id}>{item.physicalType}</Option>
                    })}
                  </Select>
                </div>
              </div>
            </Modal>
          </div>
        </div>
      </div>
    )
  }
}
export default connect(null, {
  updatePhyProjectList,
  getPhyProjectList,
  removePhyProjectList,
  getPhysicalTypeList,
  projectSave,
  getParentList,
  savePhyProjectList,
})(MachineManage)
