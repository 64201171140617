import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  Button,
  Input,
  Icon,
  message,
  Select,
  DatePicker,
  Descriptions,
  Spin,
} from 'antd'

import moment from 'moment'

import { libangEcgupdate, libangEcgList, dcmSuggestList } from '../../../actions/account'
const { Option } = Select
const dateFormat = 'YYYY-MM-DDHHmmss'

const { RangePicker } = DatePicker
const zhanspdf = require('../../../assets/20240222-231047-1.pdf');
const { TextArea } = Input
const ButtonGroup = Button.Group
class lbpissRead extends Component {
  constructor(props) {
    super(props)
    this.canvasRef = React.createRef()
    this.arr = []
    this.state = {
      page: 0,
      size: 10,
      record: undefined,
      index: null,
      picUrl: '',
      age: '',
      barcode: '',
      diagnosticInfo: '',
      otherDiagnosticInfo: '',
      hr: '',
      idCard: '',
      p: '',
      pqrsT: '',
      pr: '',
      qrs: '',
      qtQtc: '',
      rv5Sv1: '',
      sex: '',
      username: '',
      id: '',
      no: '',
      ecgList: [],
      total: 0,
      loading: false,
      sizeNew: 0,
      usernameList: '',
      barcodeList: '',
      idCardList: '',
      isReadList: "",
      startTimeList: undefined,
      endTimeList: undefined,
      machineNoList: '',
      isRead: '',
      type: "",
      typeArr: [],
      searchObj: {},

    }
  }

  componentDidMount() {


    var record = JSON.parse(this.props.location.state.record)
    console.log(this.props.location.state)
    this.setState({
      index: this.props.location.state.index,
      page: this.props.location.state.page - 1,
      size: this.props.location.state.size,
      usernameList: this.props.location.state.username,
      barcodeList: this.props.location.state.barcode,
      idCardList: this.props.location.state.idCard,
      isReadList: this.props.location.state.isRead,
      startTimeList: this.props.location.state.startTime,
      endTimeList: this.props.location.state.endTime,
      machineNoList: this.props.location.state.machineNo,
      searchObj: {
        username: this.props.location.state.username,
        barcode: this.props.location.state.barcode,
        idCard: this.props.location.state.idCard,
        isRead: this.props.location.state.isRead,
        startTime: this.props.location.state.startTime,
        endTime: this.props.location.state.endTime,
        machineNo: this.props.location.state.machineNo,
      }

    })
    this.DcmSuggestlIST()
    this.getShuju(record)

    setTimeout(() => {
      this.loadDatas(
        this.props.location.state.page - 1,
        this.props.location.state.size,
      )
    }, 500)
  }
  getShuju = (record) => {
    this.setState({
      record: record,
      picUrl: !!record.pdfPath ? record.pdfPath : '',
      age: !!record.age ? record.age : '',
      barcode: !!record.barcode ? record.barcode : '',
      diagnosticInfo: !!record.diagnosticInfo ? record.diagnosticInfo : '',
      otherDiagnosticInfo: !!record.otherDiagnosticInfo ? record.otherDiagnosticInfo : '',
      hr: !!record.hr ? record.hr.trim() : '',
      idCard: !!record.idCard ? record.idCard : '',
      p: !!record.p ? record.p.trim() : '',
      pqrsT: !!record.pqrsT ? record.pqrsT.trim() : '',
      pr: !!record.pr ? record.pr.trim() : '',
      qrs: !!record.qrs ? record.qrs.trim() : '',
      rv5Sv1: !!record.rv5Sv1 ? record.rv5Sv1.trim() : '',
      qtQtc: !!record.qtQtc ? record.qtQtc.trim() : '',
      sex: !!record.sex ? (record.sex == '1' ? '男' : '女') : '',
      username: !!record.username ? record.username : '',
      id: record.id,
      no: record.no,
      isRead: record.isRead,
      type: ''
    })


    this.setState({ loading: false })
  }
  loadDatas = (page, size) => {
    var that = this
    var { usernameList, barcodeList, idCardList, isReadList, startTimeList, endTimeList, machineNoList } = this.state

    var obj = {
      page: page,
      size: size,
    }
    if (!!usernameList) {
      obj.username = usernameList
    }
    if (!!barcodeList) {
      obj.barcode = barcodeList
    }
    if (!!idCardList) {
      obj.idCard = idCardList
    }
    if (!!isReadList) {
      obj.isRead = isReadList
    }
    if (!!machineNoList) {
      obj.machineNo = machineNoList
    }
    if (startTimeList !== '' && startTimeList !== undefined) {
      obj.startTime = startTimeList;
    }
    if (endTimeList !== '' && endTimeList !== undefined) {
      obj.endTime = endTimeList;
    }
    this.props.libangEcgList(obj).then((res) => {
      if (res.data.code === 200) {
        that.setState({
          ecgList: res.data.data.rows,
          total: res.data.data.total,
          sizeNew: res.data.data.size,
        })
      }
    })
  }
  leftPage = () => {
    var { page, size, index, ecgList, usernameList, barcodeList, idCardList, isReadList, startTimeList, endTimeList, machineNoList } = this.state
    var that = this
    // 页数为0，下标为0  没有上一条
    if (page == '0' && index == '0') {
      message.error('没有上一条数据！')
      return
    }

    this.setState({ loading: true })
    // 页数不为0，下标为0，请求数据 分页上一页 拿去最后一条
    if (index == '0') {
      page = page - 1
      this.setState({
        page: page,
      })
      var obj = {
        page: page,
        size: size,
      }
      if (!!usernameList) {
        obj.username = usernameList
      }
      if (!!barcodeList) {
        obj.barcode = barcodeList
      }
      if (!!idCardList) {
        obj.idCard = idCardList
      }
      if (!!isReadList) {
        obj.machineNo = isReadList
      }
      if (!!machineNoList) {
        obj.machineNo = machineNoList
      }
      if (startTimeList !== '' && startTimeList !== undefined) {
        obj.startTime = startTimeList;
      }
      if (endTimeList !== '' && endTimeList !== undefined) {
        obj.endTime = endTimeList;
      }
      this.props.libangEcgList(obj).then((res) => {
        if (res.data.code === 200) {
          that.setState({
            ecgList: res.data.data.rows,
          })
          index = size - 1
          this.setState({
            index: index,
          })
          this.getShuju(res.data.data.rows[index])
        }
      })
    } else {
      // 下标不为0
      index = index - 1
      this.setState({
        index: index,
      })

      this.getShuju(ecgList[index])
    }
  }
  rightPage = () => {
    var { page, size, index, ecgList, total, sizeNew, usernameList, barcodeList, idCardList, isReadList, startTimeList, endTimeList, machineNoList } = this.state
    var that = this
    let current = Math.ceil(total / size)
    console.log(current)
    if (page == current - 1 && index == sizeNew - 1) {
      message.error('没有下一条数据！')
      return
    }
    this.setState({ loading: true })
    if (index != size - 1) {
      index = index + 1
      this.setState({
        index: index,
      })

      this.getShuju(ecgList[index])
    } else {
      // 下标不为0 下一页
      page = page + 1
      this.setState({
        page: page,
      })
      var obj = {
        page: page,
        size: size,
      }
      if (!!usernameList) {
        obj.username = usernameList
      }
      if (!!barcodeList) {
        obj.barcode = barcodeList
      }
      if (!!idCardList) {
        obj.idCard = idCardList
      }
      if (!!isReadList) {
        obj.machineNo = isReadList
      }
      if (!!machineNoList) {
        obj.machineNo = machineNoList
      }
      if (startTimeList !== '' && startTimeList !== undefined) {
        obj.startTime = startTimeList;
      }
      if (endTimeList !== '' && endTimeList !== undefined) {
        obj.endTime = endTimeList;
      }
      this.props.libangEcgList(obj).then((res) => {
        if (res.data.code === 200) {
          that.setState({
            ecgList: res.data.data.rows,
            sizeNew: res.data.data.size,
          })
          index = 0
          this.setState({
            index: index,
          })
          this.getShuju(res.data.data.rows[index])
        }
      })
    }
  }
  OnChangeInp = (e, type) => {
    if (type == 'diagnosticInfo') {
      this.setState({
        diagnosticInfo: e.target.value,
      })
    } else if (type == 'otherDiagnosticInfo') {
      this.setState({
        otherDiagnosticInfo: e.target.value,
      })
    }
  }
  DcmSuggestlIST = () => {
    var { typeArr } = this.state
    var that = this
    that.props.dcmSuggestList({
      page: 0,
      size: 9999,
      suggestType: 2
    }).then((msg) => {
      if (msg.data.code === 200) {
        console.log(msg)
        if (msg.data.data.rows.length > 0) {
          typeArr = msg.data.data.rows
          this.setState({ typeArr })
        }
      }
    })

  }
  typeChange = (e) => {
    var { type, diagnosticInfo } = this.state
    type = e
    let isContained = diagnosticInfo.includes(e);
    if (isContained) {
      message.error('诊断建议已当前选择建议')
    } else {
      let endsWithNewline = diagnosticInfo.substring(diagnosticInfo.length - 1) === '\n';
      if (endsWithNewline) {
        diagnosticInfo = !!diagnosticInfo ? diagnosticInfo + e : e
      } else {
        diagnosticInfo = !!diagnosticInfo ? diagnosticInfo + '\n' + e : e
      }

    }
    this.setState({ type, diagnosticInfo })
  }
  tongbuInfo = () => {
    var { diagnosticInfo, otherDiagnosticInfo } = this.state
    if (!otherDiagnosticInfo) {
      message.error('诊断建议为空，不可同步')
      return
    }
    let isContained = diagnosticInfo.includes(otherDiagnosticInfo);
    if (isContained) {
      message.error('诊断建议已包含原始单诊断建议')
    } else {
      let endsWithNewline = diagnosticInfo.substring(diagnosticInfo.length - 1) === '\n';
      if (endsWithNewline) {
        diagnosticInfo = !!diagnosticInfo ? diagnosticInfo + otherDiagnosticInfo : otherDiagnosticInfo
      } else {
        diagnosticInfo = !!diagnosticInfo ? diagnosticInfo + '\n' + otherDiagnosticInfo : otherDiagnosticInfo
      }
    }
    this.setState({ diagnosticInfo, otherDiagnosticInfo })
  }
  sumbit = () => {
    const {
      id,
      no,
      diagnosticInfo,
      hr,
      idCard,
      p,
      pqrsT,
      pr,
      qrs,
      qtQtc,
      rv5Sv1,
      page,
      size,
      otherDiagnosticInfo
    } = this.state
    let obj = {
      id: id,
      no: no,
      diagnosticInfo: diagnosticInfo,
      otherDiagnosticInfo: otherDiagnosticInfo,
      hr: hr,
      p: p,
      pqrsT: pqrsT,
      pr: pr,
      qrs: qrs,
      qtQtc: qtQtc,
      rv5Sv1: rv5Sv1,
      isRead: '1',
    }
    this.setState({ loading: true })
    this.props.libangEcgupdate(obj).then((res) => {
      if (res.data.code === 200) {
        message.success('修改成功！')
        this.loadDatas(page, size)
        this.setState({ loading: false })
      }
    })
  }
  goBack = () => {
    var { searchObj } = this.state
    this.props.history.push({ pathname: '/admin/lbpissResultPage', state: { searchObj } })
  }

  render() {
    const {
      record,
      index,
      picUrl,
      age,
      barcode,
      diagnosticInfo, otherDiagnosticInfo,
      hr,
      idCard,
      p,
      pqrsT,
      pr,
      qrs,
      qtQtc,
      rv5Sv1,
      sex,
      username, isRead, type, typeArr
    } = this.state

    return (
      <div>
        <div className="StatisticsPage-head pageHeader">
          {' '}
          <span
            onClick={this.goBack}
            style={{
              paddingRight: '20px',
              color: '#278B7B',
              cursor: 'pointer',
            }}
          >
            {'〈  返回列表'}
          </span>
        </div>

        <Spin tip="Loading..." spinning={this.state.loading}>

          <div>
            <div style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}>
              <div style={{ padding: '10px' }}>姓名：{username}</div>
              <div style={{ padding: '10px' }}>条码号：{barcode}</div>
              <div style={{ padding: '10px' }}>身份证号：{idCard}</div>
              <div style={{ padding: '10px' }}>年龄：{age}</div>
              <div style={{ padding: '10px' }}>性别：{sex}</div>
              <div style={{ padding: '10px', color: 'red' }}>是否阅读：{isRead == '1' ? '已读' : '未读'}</div>
              <div style={{ padding: '10px' }}>操作：<ButtonGroup>
                <Button type="primary" onClick={this.leftPage}>
                  <Icon type="left" />
                  上一个
                </Button>
                <Button type="primary" onClick={this.sumbit}>
                  提交
                </Button>
                <Button type="primary" onClick={this.rightPage}>
                  <Icon type="right" />
                  下一个
                </Button>
              </ButtonGroup></div>
            </div>
            <div style={{ display: "flex", justifyContent: 'space-between', alignItems: 'center', width: '100%', padding: '10px' }}>
              <div style={{ width: '45%' }}>
                原始单诊断建议：
                <TextArea
                  rows={5}
                  style={{ minWidth: '100%', height: '125px' }}
                  value={otherDiagnosticInfo}
                  placeholder="请输入诊断建议"
                  disabled
                  onChange={(e) => this.OnChangeInp(e, 'otherDiagnosticInfo')}
                />
              </div>
              <Button type="primary" onClick={this.tongbuInfo}>
                同步
              </Button>
              <div style={{ width: '45%' }}>
                <div style={{ display: 'flex', marginBottom: '8px' }}>
                  <div>诊断建议：</div>
                  <Select
                    showSearch
                    placeholder="心电图建议"
                    value={type}
                    onChange={this.typeChange}
                    style={{ width: '80%', alignItems: 'center' }}
                  >

                    {typeArr.map((item) => (
                      <Option value={item.title} key={item.id}>
                        {item.title}
                      </Option>
                    ))}
                  </Select>
                </div>
                <TextArea
                  rows={5}
                  style={{ minWidth: '100%', height: '105px' }}
                  value={diagnosticInfo}
                  placeholder="请输入其他诊断建议"
                  onChange={(e) => this.OnChangeInp(e, 'diagnosticInfo')}
                />
              </div>
            </div>
            {!!picUrl ? (
              <embed id="pdfEmbed" src={picUrl} type="application/pdf" width='100%' height='600' zoom='90%' />

            ) : (
              <div style={{ width: "100%", height: '600px', textAlign: 'center', lineHeight: "600px", fontSize: '40px' }}>暂无心电图数据</div>
            )
            }

          </div>

        </Spin >
      </div >
    )
  }
}
export default connect(null, {
  libangEcgupdate,
  libangEcgList, dcmSuggestList
})(lbpissRead)
