import React, { Component } from 'react'
import { Modal, Button, Table, Input, message,  Spin, DatePicker,Divider } from 'antd';
import moment from 'moment';
import {reagentUpdate, reagentRemove, getPhysicalTypeList, reagentSave, getreagentList, savePhyProjectList } from '../../../actions/account'

// import _ from 'lodash'
import { connect } from 'react-redux'
// const { Option } = Select;
const { Column } = Table;
const { confirm } = Modal;

class MachineManage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            validity: moment(),
            spinning: false,
            reagentName: '',
            number: '',
            rank: '',
            projectId: '',
            selectedRowKeys: [],
            phyList: [],

            alreadyDataList: [],
            newPhysicalTypeName: '',
            size: 10,
            dataList: [],
            curRecord: undefined,
            visible: false,
            page: 1,
            total: 0,
            isRequesting: false,
            bindVisble: false,
            bindphyVisble: false,
            orgList: [],
            organizationId: '',
            physicalType: ''
        };
    }

    componentDidMount() {
        this.loadDatas();
    }

    loadDatas = () => {
        var that = this;
        var { page, size } = this.state;
        var obj = { page: page - 1, size: size };
        this.setState({ spinning: true })
        this.props.getreagentList(obj).then(msg => {
            console.log(msg)
            if (msg.data.code === 200) {
                if (msg.data.data.rows.length === 0 && msg.data.data.total !== 0) {
                    that.setState({ page: page - 1 }, () => {
                        that.loadDatas()
                    })
                }
                else {
                    that.setState({
                        dataList: msg.data.data.rows,
                        total: msg.data.data.total,
                        spinning: false
                    })
                }
            }
        })
    }
    changePage = page => {
        var that = this;
        this.setState({ page }, () => {
            that.loadDatas()
        })
    }
    showModal = () => {
        this.setState({
            curRecord: undefined,
            visible: true
        })
    }

    handleCancel = () => {
        this.setState({ visible: false, reagentName: '', number: '' });

    }
    handleBindCancel = () => {
        this.setState({ bindVisble: false, reagentName: '', curRecord: undefined ,number:'',validity:moment()});

    }
    reagentNameChange = e => {
        this.setState({
            reagentName: e.target.value
        })
    }
    numberChange = e => {
        this.setState({
            number: e.target.value
        })
    }
    handleOk = () => {
        var { reagentName, number, isRequesting, validity } = this.state;
        if (isRequesting) {
            return
        }
        validity = moment(validity,'YYYY-MM-DD HH:mm:ss').format('YYYYMMDD')
        var that = this;
        console.log()
        this.setState({ isRequesting: true }, () => {
            that.props.reagentSave({ reagentName, number, validity }).then(msg => {
                console.log(msg)
                if (msg.data.code === 200) {
                    message.success('添加成功')
                    that.handleCancel();
                    that.loadDatas();
                }
                else {
                    message.error(msg.data.message)
                }
                that.setState({ isRequesting: false })
            })
        })
    }
    editItem = (record) => {
        console.log(record)
        this.setState({
            curRecord: record,
            reagentName:record.reagentName,
            number:record.number,
            validity:moment(record.validity,"YYYYMMDD").format("YYYY-MM-DD"),
            bindVisble: true,
        })
    }
    
    projectOnChange = (value) => {
        this.setState({ projectId: value })
    }
    phyOnChange = (value) => {
        this.setState({ physicalType: value })
    }

    handleBindphyOk = () => {
        var { isRequesting, projectId, physicalType } = this.state;
        if (isRequesting) {
            return
        }
        var that = this;
        this.setState({ isRequesting: false }, () => {
            that.props.savePhyProjectList({
                typeId: Number(physicalType),
                projectId: Number(projectId)
            }).then(msg => {
                if (msg.data.code === 200) {
                    message.success("绑定成功！");
                    that.loadDatas()
                    that.handleBindphyCancel()
                }
                else {
                    message.error(msg.data.message)
                }
                that.setState({ isRequesting: false })
            })
        })

    }
    UpdateBindphyOk = () => {
        // var { isRequesting, organizationId, physicalType, curRecord } = this.state;
        // if (isRequesting) {
        //     return
        // }
        // var that = this;
        // this.setState({ isRequesting: false }, () => {
        //     that.props.getPhysicalTypeList({
        //         id: curRecord.id,
        //         physicalType: Number(physicalType),
        //         organizationId: organizationId.toString()
        //     }).then(msg => {
        //         if (msg.data.code === 200) {
        //             message.success("修改成功！");
        //             that.loadDatas2();
        //             that.UpdateBindphyCancel()
        //         }
        //         else {
        //             message.error(msg.data.message)
        //         }
        //         that.setState({ isRequesting: false })
        //     })
        // })

    }

    UpdateBindphyCancel = () => {
        this.setState({
            updatephyVisble: false,
            curRecord: undefined,
            physicalType: '',
            organizationId: '',
        })
    }
    handleBindphyCancel = () => {
        this.setState({
            bindphyVisble: false,
            physicalType: '',
            projectId: ''
        })
    }
    changePage = page => {
        var that = this;
        this.setState({ page }, () => {
            that.loadDatas2()
        })
    }
    // 选中改变
    onSelectChange = selectedRowKeys => {
        console.log(selectedRowKeys)
        this.setState({ selectedRowKeys });
    };
    onChange = (date, dateString) => {
        // console.log(date, dateString, dateString.split('-'), dateString.split('-').join(''));
        this.setState({
            validity: dateString.split('-').join('')
        })
    }
    delItem = (recorrd) => {
        var { selectedRowKeys } = this.state;
        if (selectedRowKeys.length === 0 && recorrd === 'all') {
            message.error("请选择删除的记录")
            return;
        }
        if (recorrd !== 'all') {
            this.setState({
                selectedRowKeys: [recorrd.id]
            })
        }
        var that = this;
        confirm({
            content: "确认删除？",
            onOk() {
                var That = that;
                that.props.reagentRemove({ ids: selectedRowKeys.join(",") }).then(msg => {
                    if (msg.data.code === 200) {
                        message.success("删除成功！");
                        That.setState({ selectedRowKeys: [] })
                        That.loadDatas();
                    }

                })
            }
        });

    }
    handleBindOk = () => {
        var { isRequesting, reagentName, number,validity,curRecord } = this.state;
        if (isRequesting) {
            return
        }
        if((!reagentName && reagentName.length===0) || (!number && number.length===0)|| (!validity && validity.length===0)){
            return;
        }
        var that = this;
        this.setState({ isRequesting: true }, () => {
            that.props.reagentUpdate({
                id:curRecord.id,
                reagentName,
                number,
                validity,
            }).then(msg => {
                if (msg.data.code === 200) {
                    message.success("修改成功！");
                    that.loadDatas()
                    that.handleBindCancel()
                }
                else {
                    message.error(msg.data.message)
                }
                that.setState({ isRequesting: false })
            })
        })

    }
    render() {

        const {  selectedRowKeys,  spinning,  dataList, reagentName,  number, bindVisble,  } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        return (
            <div>
                <div className="StatisticsPage-head pageHeader"><h3>试剂管理</h3></div>
                <div className="StatisticsPage-box">
                    <div className="StatisticsPage-search">
                        <div className="StatisticsPage-btn">

                            <Button className="btn" type="primary" onClick={this.showModal}>添加试剂</Button>
                            <Button className="btn" type="primary" onClick={() => this.delItem('all')}>删除</Button>
                            <Modal title="添加试剂" visible={this.state.visible} onOk={this.handleOk} onCancel={this.handleCancel} okText="保存" cancelText="取消">
                                <div className="form-group">
                                    <label className="form-label">试剂名称：</label>
                                    <div className="form-ipt">
                                        <Input value={reagentName} placeholder="请输入试剂名称" onChange={e => this.reagentNameChange(e)} />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="form-label">批号：</label>
                                    <div className="form-ipt">
                                        <Input value={number} placeholder="请输入批号" onChange={e => this.numberChange(e)} />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="form-label">有效期：</label>
                                    <div className="form-ipt">
                                    <DatePicker onChange={this.onChange} defaultValue={moment(this.state.validity,'YYYY-MM-DD')} format={'YYYY-MM-DD'} />
                                    </div>
                                </div>
                            </Modal>
                        </div>
                    </div>
                    <div>
                        <Spin tip="Loading..." spinning={spinning}>

                            <Table size="middle" dataSource={dataList} bordered rowKey="id" 
                                rowSelection={rowSelection}
                                pagination={{
                                    pageSize: this.state.size, showTotal: ((total) => {
                                        return `共 ${total} 条`;
                                    }),
                                    onChange: this.changePage, total: this.state.total, showSizeChanger: false
                                }}
                                onRow={(record, index) => {
                                    return {
                                        onClick: () => {
                                            let temp = this.state.selectedRowKeys
                                            let index = temp.indexOf(record.id)
                                            if (index === -1) { temp.push(record.id) }
                                            else { temp.splice(index, 1) }
                                            this.setState({ selectedRowKeys: temp });
                                        }
                                    }
                                }}
                            >
                                <Column title="试剂名称" dataIndex="reagentName" key="reagentName" align="center" />
                                <Column title="批号" dataIndex="number" key="number" align="center" />
                                <Column title="有效期" dataIndex="validity" key="validity" align="center" />
                                <Column title="操作" align="center" key="action"
                                    render={(text, record) => (
                                        <span>
                                            <span style={{ cursor: 'pointer' }} onClick={() => this.editItem(record)}>修改</span>
                                            <Divider type="vertical" />
                                            <span style={{ cursor: 'pointer' }} onClick={() => this.delItem(record)} >删除</span>
                                        </span>
                                    )} />
                            </Table>
                        </Spin>
                        <Modal title="修改" visible={bindVisble} onOk={this.handleBindOk} onCancel={this.handleBindCancel} okText="保存" cancelText="取消">
                            <div className="form-group">
                                <label className="form-label">试剂名称：</label>
                                <div className="form-ipt">
                                    <Input value={reagentName} placeholder="请输入试剂名称" onChange={e => this.reagentNameChange(e)} />
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="form-label">批号：</label>
                                <div className="form-ipt">
                                    <Input value={number} placeholder="请输入批号" onChange={e => this.numberChange(e)} />
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="form-label">有效期：</label>
                                <div className="form-ipt">
                                    <DatePicker onChange={this.onChange} defaultValue={moment(this.state.validity,'YYYY-MM-DD')} format={'YYYY-MM-DD'} />
                                </div>
                            </div>
                        </Modal>
                    </div>
                </div>
            </div>
        )
    }
}
export default connect(null, { reagentUpdate,reagentRemove, getPhysicalTypeList, reagentSave, getreagentList, savePhyProjectList })(MachineManage)