import React, { Component } from 'react'
import 'antd/dist/antd.less';
import './Login.css'
import MD5 from 'crypto-js/md5'

// import login_logo from '../../assets/login-img/login_ybcj_bg.png'
import login_bg from '../../assets/login-img/container.png'
import login_left from '../../assets/login-img/login_left.png'
import login_logo from '../../assets/login-img/logo.jpg'
import { Input, Button, message } from 'antd';
import { connect } from 'react-redux'
import { login } from '../../actions/account'

class Login extends Component {
    constructor() {
        super()
        this.state = {
            userName: "",
            passWord: "",
            loading: false
        }
    }
    //  改变用户名
    changeName = e => {
        this.setState({ userName: e.target.value })
    }
    changepassWord = e => {
        this.setState({ passWord: e.target.value })
    }
    clickLogin = () => {
        // window.location ="/admin"; 

        //  localStorage.setItem('cookie',JSON.stringify({token:"329130223"}))
        //  window.location ="/admin"; 
        const { userName, passWord } = this.state;
        if (userName.trim() === "") {
            message.info("请输入账号");
            return;
        }
        if (passWord.trim() === "") {
            message.info("请输入密码");
            return;
        }
        var that = this;
        this.setState({
            loading: true
        }, () => {
            that.props.login({ account: userName, password: MD5(passWord).toString() }).then(function (res) {
                console.log(res)
                if (res.data.code === 200) {

                    localStorage.setItem('cookie', JSON.stringify({ token: res.data.data.token }))
                    var obj = {
                        account: userName
                    }
                    localStorage.setItem('user', JSON.stringify(obj))
                    localStorage.setItem('data', JSON.stringify(res.data.data))

                    window.location = "/admin";
                }
                else {
                    that.setState({ loading: false })
                    message.error(res.data.message)
                }

            })
        })

    }

    render() {
        const { userName, passWord } = this.state;
        return (
            <div className="login">

                <div className="loginContainer">
                    <div>
                        <div className='login_title'><img src={login_logo} alt="" style={{ height: '50px', marginRight: '20px' }} /> 样本采集信息管理系统</div>
                        <img alt="" src={login_left} style={{ width: '460px', height: '530px' }} />
                    </div>

                    <div className="loginFormtab">
                        <div className='loginFormtab_p'>
                            欢迎登录系统
                        </div>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center'
                        }}>
                            <div className='loginFormtab_heng'></div>
                        </div>
                        <Input placeholder="请输入登录账号" value={userName} onChange={e => this.changeName(e)} onPressEnter={this.clickLogin} className="loginFormtab_inp" />
                        <Input.Password placeholder="请输入登录密码" type="password" value={passWord} onChange={e => this.changepassWord(e)} onPressEnter={this.clickLogin} className="loginFormtab_inp" />
                        <Button type="primary" className="loginBtn" onClick={this.clickLogin} loading={this.state.loading}>登  录</Button>
                    </div>
                </div>
                <div style={{
                    position: 'absolute',
                    bottom: '0',
                    textAlign: 'center',
                    width: '100%'
                }}>
                    <div>备案号:<a href='https://beian.miit.gov.cn/' target="_blank">苏ICP备20047457-1号</a></div>

                </div>
            </div >
        )
    }
}
export default connect(null, { login })(Login)