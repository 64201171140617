import React, { Component } from 'react'
import { Button, Input, Select, message, Checkbox,  Upload, Icon } from 'antd';
// import { ImageUploader } from 'antd-mobile';
import { connect } from 'react-redux'
import { appointmentPageDetail2, appointmentSave, uploadFile } from '../../actions/account'
import './yuyue.css'
import _, { random } from 'lodash'
import { PlusOutlined, UploadOutlined } from '@ant-design/icons';
import moment from 'moment';
const { Option } = Select
class main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            physicalSite:"请先选择日期",
            isRequresting: false,
            loading: false,loading1:false,loading2:false,
            fileList: [],
            fileList2: [],
            org: null,
            phy: null,
            selectedDate: moment().format("YYYY-MM-DD"),
            schoolName: "",
            classGrade: "",
            sex: "",
            weekName: "",
            weeks: [[], [], [], [], [], [], []],
            btnState: false,
            time: "",
            Datas: [],
            syme: null,
            username: "", idCard: "", workUnit: "", phone: "", town: "", county: "", village: "", team: "", height: "", weight: "", occupation: "", education: "",
            pageTitle: "",
            occupation1: "",
            occupation2: "",
            educationList: [
                {
                    value: "10",
                    text: '研究生教育'
                }, {
                    value: "20",
                    text: '大学本科教育'
                }, {
                    value: "30",
                    text: '大学专科教育'
                }, {
                    value: "40",
                    text: '中等职业教育'
                }, {
                    value: "60",
                    text: '普通高级中学教育'
                }, {
                    value: "70",
                    text: '初级中学教育'
                }, {
                    value: "80",
                    text: '小学教育'
                }, {
                    value: "90",
                    text: '其他'
                }
            ],
            occupationList: [
                {
                    value: "100",
                    text: '党的机关、国家机关、群众团体和社会组织、企事业单位负责人',
                    children: [
                        {
                            value: "101",
                            text: '中国共产党机关负责人'
                        }, {
                            value: "102",
                            text: '国家机关负责人'
                        }, {
                            value: "103",
                            text: '民族党派和工商联负责人'
                        }, {
                            value: "104",
                            text: '人民团体和群众团体、社会组织及其他成员组织负责人'
                        }, {
                            value: "105",
                            text: '基层群众自治组织负责人'
                        }, {
                            value: "106",
                            text: '企事业单位负责人'
                        }, {
                            value: "199",
                            text: '其他党政机关、群众团体和社会组织、企事业单位负责人'
                        }
                    ]
                },
                {
                    value: "200",
                    text: '专业技术人员',
                    children: [
                        {
                            value: "201",
                            text: '科学研究人员'
                        }, {
                            value: "202",
                            text: '工程技术人员'
                        }, {
                            value: "203",
                            text: '农业技术人员'
                        }, {
                            value: "204",
                            text: '飞机和船舶技术人员'
                        }, {
                            value: "205",
                            text: '卫生专业技术人员'
                        }, {
                            value: "206",
                            text: '经济和金融专业人员'
                        }, {
                            value: "207",
                            text: '法律、社会和宗教专业人员'
                        },
                        {
                            value: "208",
                            text: '教学人员'
                        }, {
                            value: "209",
                            text: '文学艺术、体育专业人员'
                        }, {
                            value: "210",
                            text: '新闻出版、文化专业人员'
                        }, {
                            value: "299",
                            text: '其他专业技术人员'
                        },
                    ]
                },
                {
                    value: "300",
                    text: '办事人员和有关人员',
                    children: [
                        {
                            value: "301",
                            text: '办事人员'
                        }, {
                            value: "302",
                            text: '安全和消防人员'
                        }, {
                            value: "399",
                            text: '其他办事人员和有关人员'
                        }
                    ]
                },
                {
                    value: "400",
                    text: '社会生产服务和生活服务人员',
                    children: [
                        {
                            value: "401",
                            text: '批发与零售服务人员'
                        }, {
                            value: "402",
                            text: '交通运输、仓储和邮政业服务人员'
                        }, {
                            value: "403",
                            text: '住宿和餐饮服务人员'
                        }, {
                            value: "404",
                            text: '信息运输、软件和信息技术服务人员'
                        }, {
                            value: "405",
                            text: '金融服务人员'
                        }, {
                            value: "406",
                            text: '房地产服务人员'
                        }, {
                            value: "407",
                            text: '租赁和商务服务人员'
                        }, {
                            value: "408",
                            text: '技术辅助服务人员'
                        }, {
                            value: "409",
                            text: '水利、环境和公共设施管理服务人员'
                        }, {
                            value: "410",
                            text: '居民服务人员'
                        }, {
                            value: "411",
                            text: '电力、燃气及水供应服务人员'
                        }, {
                            value: "412",
                            text: '修理及制作服务人员'
                        }, {
                            value: "413",
                            text: '文化、体育及娱乐服务人员'
                        }, {
                            value: "414",
                            text: '健康服务人员'
                        }, {
                            value: "499",
                            text: '其他社会生产和生活服务人员'
                        }
                    ]
                },
                {
                    value: "500",
                    text: '农、林、牧、渔业生产及辅助人员',
                    children: [
                        {
                            value: "501",
                            text: '农业生产人员'
                        }, {
                            value: "502",
                            text: '林业生产人员'
                        }, {
                            value: "503",
                            text: '畜牧业生产人员'
                        }, {
                            value: "504",
                            text: '渔业生产人员'
                        }, {
                            value: "505",
                            text: '农林牧渔生产辅助人员'
                        }, {
                            value: "599",
                            text: '其他农、林、牧、渔、水利业生产人员'
                        },
                    ]
                },
                {
                    value: "600",
                    text: '生产制造及有关人员',
                    children: [
                        {
                            value: "601",
                            text: '农副产品加工人员'
                        }, {
                            value: "602",
                            text: '食品、饮料生产加工人员'
                        }, {
                            value: "603",
                            text: '烟草及其制品加工人员'
                        }, {
                            value: "604",
                            text: '纺织、针织、印染人员'
                        }, {
                            value: "605",
                            text: '纺织品、服装和皮革、毛皮制品加工制作人员'
                        }, {
                            value: "606",
                            text: '木材加工、家具与木制品制作人员'
                        }, {
                            value: "607",
                            text: '纸及纸制品生产加工人员'
                        }, {
                            value: "608",
                            text: '印刷和记录媒介复制人员'
                        }, {
                            value: "609",
                            text: '文教、工美、体育和娱乐用品制作人员'
                        }, {
                            value: "610",
                            text: '石油加工和炼焦、煤化工制作人员'
                        }, {
                            value: "611",
                            text: '化学原料和化学制品制造人员'
                        }, {
                            value: "612",
                            text: '医药制造人员'
                        }, {
                            value: "613",
                            text: '化学纤维制造人员'
                        }, {
                            value: "614",
                            text: '橡胶和塑料制品制造人员'
                        }, {
                            value: "615",
                            text: '非金属矿物制品制造人员'
                        }, {
                            value: "616",
                            text: '采矿人员'
                        }, {
                            value: "617",
                            text: '金属冶炼和压延加工人员'
                        }, {
                            value: "618",
                            text: '机械制造基础加工人员'
                        }, {
                            value: "619",
                            text: '金属制品制造人员'
                        }, {
                            value: "620",
                            text: '通用设备制造人员'
                        }, {
                            value: "621",
                            text: '专用设备制造人员'
                        }, {
                            value: "622",
                            text: '汽车制造人员'
                        }, {
                            value: "623",
                            text: '铁路、船舶、航空设备制造人员'
                        }, {
                            value: "624",
                            text: '电气机械和器材制造人员'
                        }, {
                            value: "625",
                            text: '计算机、通信和其他电子设备制造人员'
                        }, {
                            value: "626",
                            text: '仪器仪表制造人员'
                        }, {
                            value: "627",
                            text: '废弃资源综合利用人员'
                        }, {
                            value: "628",
                            text: '电力、热力、气体、水生产和输配人员'
                        }, {
                            value: "629",
                            text: '建筑施工人员'
                        }, {
                            value: "630",
                            text: '运输设备和通用工程机械操作人员及有关人员'
                        }, {
                            value: "631",
                            text: '生产辅助人员'
                        }, {
                            value: "699",
                            text: '其他生产制造及有关人员'
                        },
                    ]
                },
                {
                    value: "700",
                    text: '军人',
                    children: []
                },
                {
                    value: "800",
                    text: '不便分类的其他从业人员', children: []
                },
                {
                    value: "999",
                    text: '不详', children: []
                },
            ],
            occupationList1: [],
            occupationList2: [],
            dateList: [],
            timeList: [],
            date: "",
            detailId: "",
            erweimaOrg: undefined,
            erweimaPhy: undefined,
            birday: '--',
            isCheckedUnit: false,
            isCheckedCard: false
        }
    }
    componentDidMount() {
        document.title="健康证体检预约";
        var temp = []
        console.log(this.props.location.search.substring(1))
        var params = this.props.location.search.substring(1).split("&")
        var val = []
        var query = {}
        console.log(params)
        for (var i = 0; i < params.length; i++) {
            val = params[i].split("=");
            if (val[0] === 'erweimaName') { query[val[0]] = decodeURI(val[1]) }
            else query[val[0]] = val[1]

        }
        console.log(query)
        this.setState({ org: query.erweimaOrg, phy: query.erweimaPhy }, () => {
            this.loadDatas(query.erweimaOrg, query.erweimaPhy, 1)
        })
        this.state.occupationList.forEach(function (occ) {
            temp.push({ value: occ.value, text: occ.text })
        });
        var locationData = query
        this.setState({
            pageTitle: locationData.erweimaName,
            occupationList1: temp,
            erweimaOrg: query.erweimaOrg,
            erweimaPhy: query.erweimaPhy,
        })

    }
    onyyDateChange(date, dateString) {
        console.log(date, dateString);
    }
    loadDatas = (org, phy, mark) => {
        var that = this;
        var obj = { organizationId: org, typeId: Number(phy), currentDate: moment().format("YYYY-MM-DD HH:mm:ss"), selectedDate: this.state.selectedDate };
        // var obj = { organizationId: org, physicalTypeId: Number(phy), currentDate: moment().format("YYYY-MM-DD HH:mm:ss") };
        message.loading("加载数据中....", 0)
        this.setState({ weeks: [[], [], [], [], [], [], []], dateList: [], timeList: [] }, () => {
            var { dateList, weeks } = that.state;
            that.props.appointmentPageDetail2(obj).then(msg => {
                message.destroy();
                if (msg.data.code === 200) {
                    if (!msg.data.data || msg.data.data.length === 0) {
                        message.warn('最近没有预约事务！')
                        that.setState({ btnState: true })
                        return;
                    }
                    var festival = ["01-01", "05-01", "10-01"];//法定节假日
                    var objs = msg.data.data;
                    var countDay = 0;
                    objs.forEach(obj => {
                        obj.appointmentDateDetailDTOS.forEach(item => {
                            weeks[item.week - 1].push(item)
                        })
                        // alert(obj.startTime+","+d.getTime()+","+endDate.getTime())
                        //  var startDate = new Date(obj.startTime);
                        //  var endDate = new Date(obj.endTime);
                        var startDate = moment(obj.startTime);
                        var endDate = moment(obj.endTime);

                        var d = moment();
                        if (d.unix() > startDate.unix()) {
                            d = moment();
                        } 
                        else {
                            d = startDate;
                        }
                        // alert(startDate.getTime()+","+d.getTime()+","+endDate.getTime())
                        for (
                            d; d.unix() <= endDate.unix() && countDay < 7;
                            d = d.add(1, "days")
                        ) {
                            // console.log(moment(d).format("YYYY-MM-DD"))
                            if (festival.indexOf(d.format("MM-DD")) === -1) {
                                //从今天开始往后的   日期范围内的    
                                var temp = d.format("YYYY-MM-DD")
                                var now = moment().format("YYYY-MM-DD")
                                var day = parseInt(d.day());
                                //  ;
                                console.log(temp)
                                if ((moment(now).isBefore(temp) || temp === now) &&
                                    ((day !== 0 ) ||
                                    // ((day !== 0 && weeks[day - 1].length > 0) ||
                                        (day === 0 && weeks[6].length > 0))) {
                                    if (day !== 0) dateList.push({ date: d.format("YYYY-MM-DD"), week: day ,physicalSite:obj.physicalSite})
                                    else dateList.push({ date: d.format("YYYY-MM-DD"), week: 7 ,physicalSite:obj.physicalSite})
                                }
                                countDay++;

                            }
                        }
                    })

                    // alert(dateList.length)
                    that.setState({ dateList, weeks, btnState: false }, () => {
                        if (mark === 2) {
                            var temp = "", weekName = "",physicalSite="";
                            console.log(that.state.dateList, that.state.weeks)
                            that.state.dateList.forEach(item => {
                                if (item.date === that.state.selectedDate) {
                                    temp = item.week;
                                    if (item.week === 1) weekName = "周一";
                                    if (item.week === 2) weekName = "周二";
                                    if (item.week === 3) weekName = "周三";
                                    if (item.week === 4) weekName = "周四";
                                    if (item.week === 5) weekName = "周五";
                                    if (item.week === 6) weekName = "周六";
                                    if (item.week === 7) weekName = "周日";
                                    physicalSite=item.physicalSite;
                                    console.log('是', weekName)
                                    console.log('地点', physicalSite)
                                }
                            })
                            that.setState({ timeList: that.state.weeks[temp - 1], weekName,physicalSite })
                        }
                    })

                }
            })
        })

    }
    oneducationChange = (value) => {
        this.setState({ education: value })
    }
    onDateChange = (value) => {
        console.log(value)
        this.setState({ date: value, timeList: [], detailId: "", time: "", syme: null, selectedDate: value }, () => {
            this.loadDatas(this.state.org, this.state.phy, 2)
        })
    }
    ondetailIdChange = (value) => {
        var { timeList } = this.state;
        this.setState({ detailId: value })
        timeList.forEach(item => {
            if (item.id === Number(value)) {
                this.setState({
                    syme: Number(item.residueNumber),
                    time: `${item.startTime.substring(11, 19)}-${item.endTime.substring(11, 19)}`
                })
                return;
            }
        })
    }
    onOccupation1Change = (value) => {
        this.setState({ occupation1: value, occupation2: "" })
        var { occupationList } = this.state;
        occupationList.forEach(item => {
            if (item.value === value) {
                this.setState({ occupationList2: item.children })
            }
        })
    }
    onOccupation2Change = (value) => {
        this.setState({ occupation2: value })
    }
    submit = () => {
        var that = this;
        var { isCheckedCard, isCheckedUnit, date, time, detailId, username, idCard, phone, birday, workUnit,
            town, county, village, team, height, weight,fileList,fileList2
            //  occupation,  occupation1, occupation2 
        } = this.state
        console.log(date, time)
        if (!isCheckedCard || !isCheckedUnit) {
            message.error("请勾选体检时携带材料！")
            return
        }
        if (workUnit === undefined || workUnit === "" || workUnit === null) {
            message.warn('单位未填写完整！')
            // alert("")
            return;
        }
        // if (occupation2 && occupation2.length > 0) occupation = occupation2
        // else {
        //     if (occupation1 && occupation1.length > 0) occupation = occupation1
        // }
        if (detailId.length === 0 || date.length === 0 || time.length === 0 || username.length === 0 ||
            idCard.length === 0 || phone.length === 0) {
            message.warn('必填项未填写完整！')
            // alert("")
            return;
        }
        var obj = {
            appointmentDetailId: Number(detailId),
            username,
            phone,
            appointmentDate: `${date} ${time}`,
            birthday: birday
        }
        if (idCard !== "") {
            obj.idCard = idCard.toUpperCase();
        }

        if (town.length > 0) obj.town = town;
        if (workUnit.length > 0) obj.workUnit = workUnit;
        if (county.length > 0) obj.county = county;
        if (village.length > 0) obj.village = village;
        if (team.length > 0) obj.team = team;
        if (height.length > 0) obj.height = height;
        if (weight.length > 0) obj.weight = weight;
        if(fileList.length===0 || fileList2.length===0){
            message.error("请上传单位证件！")
            return;
        }
        if(fileList.length>0){
            console.log(fileList);
            var temp=[];
            fileList.forEach(item=>{temp.push(item.literationURL)})
            obj.materials=temp.join(',');
        }
        if(fileList2.length>0){
            var temp=[];
            fileList2.forEach(item=>{temp.push(item.literationURL)})
            obj.idCardImg=temp.join(',');
        }
        console.log(obj)
        this.props.appointmentSave(obj).then(msg => {
            if (msg.data.code === 200) {
                message.info('预约成功！')
                that.props.history.push('/appointQueryList?idCard=' + idCard)
                
            }
            else {
                // alert(msg.data);
                message.warn(msg.data.message)
            }
        })
    }
    usernameChange = (e) => {
        this.setState({
            username: e.target.value
        })
    }
    idCardChange = (e) => {
        var reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;

        if (e.target.value.length === 18) {
            if (reg.test(e.target.value) === false)
                message.warn("身份证号格式错误");
            else {
                if (parseInt(e.target.value.slice(-2, -1)) % 2 === 1) {
                    this.setState({
                        sex: "男"
                    })
                }
                else {
                    this.setState({
                        sex: "女"
                    })
                }
                var year = e.target.value.substring(6, 10);
                var month = e.target.value.substring(10, 12);
                var day = e.target.value.substring(12, 14);
                this.setState({ birday: year + "-" + month + "-" + day })
            }
        }
        this.setState({
            idCard: e.target.value
        })
    }

    sexOnChange = value => {
        this.setState({ sex: value })
    }

    workUnitChange = (e) => {
        this.setState({
            workUnit: e.target.value
        })
    }
    phoneChange = (e) => {
        this.setState({
            phone: e.target.value
        })
    }
    townChange = (e) => {
        this.setState({
            town: e.target.value
        })
    }
    countyChange = (e) => {
        this.setState({
            county: e.target.value
        })
    }
    villageChange = (e) => {
        this.setState({
            village: e.target.value
        })
    }
    teamChange = (e) => {
        this.setState({
            team: e.target.value
        })
    }
    heightChange = (e) => {
        this.setState({
            height: e.target.value
        })
    }
    weightChange = (e) => {
        this.setState({
            weight: e.target.value
        })
    }
    schoolNameChange = (e) => {
        this.setState({
            schoolName: e.target.value
        })
    }
    classGradeChange = (e) => {
        this.setState({
            classGrade: e.target.value
        })
    }

    birdayOnChange = (e, mark) => {
        var { birday } = this.state;
        var strs = birday.split("-");
        var year = "";
        if (strs.length >= 1) {
            year = strs[0];
        }
        var month = "";
        if (strs.length >= 2) {
            month = strs[1];
        }
        var day = ""
        if (strs.length >= 3) {
            day = strs[2];
        }
        switch (mark) {
            case "y":
                year = e.target.value;
                break;
            case "m":
                month = e.target.value;
                break;
            case "d":
                day = e.target.value;
                break;
            default:
                break;
        }
        birday = year + "-" + month + "-" + day;
        this.setState({ birday });
    }

    isCheckedUnitOnChange = e => {
        this.setState({
            isCheckedUnit: e.target.checked
        })
    }

    isCheckedCardOnChange = e => {
        this.setState({
            isCheckedCard: e.target.checked
        })
    }
    onChange = (e) => {
        e.preventDefault();
        const file = e.target.files[0];
        const formData = new FormData();
        // 这里的 image 是字段，根据具体需求更改
        formData.append('image', file);

    };

    customRequestIdCard = (option) => {
        this.imagePress(option.file,2*1024*1024,2)
    }

    customRequestContinueIdCard = (option) => {
        var { fileList2 } = this.state; 
        var that = this;
     
        let reader = new FileReader()
        reader.readAsDataURL(option)
        reader.onload = function () {
            var uid = fileList2.length;
            fileList2.push({ url: reader.result, thumbUrl: reader.result, name: '', uid: uid, status: 'uploading' });
            that.setState({ fileList2 }, () => {
                that.uploadFileIdCard(option, uid);
            })
        }
    }

    
    /**
     * 上传图片时，压缩图片
     * @param {*} file 目标文件
     * @param {*} size 压缩后目标大小
     */
    imagePress = (file, size, mark) => {
        var that= this;
       // const imagePress2 = this.imagePress;
        const isLt2M = file.size < size;       
        if (isLt2M) {
            console.log('没有压缩')
            if(mark===1){
                that.customRequestContinueMaterials(file);
            }else{
                that.customRequestContinueIdCard(file);
            }
         
        }
        else {
            console.log('压缩了')
            let rate = 0.9;
            let reader = new FileReader();
            reader.readAsDataURL(file);
            let img = new Image();
            reader.onload = function (e) {
                img.src = e.target.result;
            };
            img.onload = function () {
                let canvas = document.createElement("canvas");
                let context = canvas.getContext("2d");
                // 文件大小KB
                const fileSizeKB = Math.floor(file.size / 1024);
                // console.log('file size：', fileSizeKB, 'kb');
                // 配置rate和maxSize的关系
                if (fileSizeKB * rate > 3027) {
                    rate = Math.floor((3027 / fileSizeKB) * 10) / 30;
                }
                // 缩放比例，默认0.5
                let targetW = (canvas.width = this.width * rate);
                let targetH = (canvas.height = this.height * rate);
                context.clearRect(0, 0, targetW, targetH);
                context.drawImage(img, 0, 0, targetW, targetH);
                canvas.toBlob((blob) => {
                    const newImage = new File([blob], file.name, {
                        type: file.type,
                    });
                    // console.log(newImage.size / 1024, "kb");
                    if(newImage.size<size){
                       // 图片上传接口
                    if(mark===1){
                        that.customRequestContinueMaterials(newImage);
                        }else{
                            that.customRequestContinueIdCard(newImage);
                        }  
                    }else{
                        that.imagePress(newImage,1024*1024,mark)
                    }
                   
                 
                });
            };
        }
    }

    uploadFileIdCard = (file, uid) => {
        var { fileList2 } = this.state;
        var that = this;
        const formData = new FormData();
        // 这里的 image 是字段，根据具体需求更改
        formData.append('file', file);
        that.props.uploadFile(formData).then(msg => {
            if (msg.data.code === 200) {
                var file = _.find(fileList2, ['uid', uid]);
                file.status = 'success';
                file.name = '待提交...';
                file.url = msg.data.complete;
                file.literationURL = msg.data.data;
                that.setState({ fileList2 })
            }
            else {
                message.error(msg.data.message);
                var file = _.find(fileList2, ['uid', uid]);
                file.status = 'error';
                file.name = '上传失败';
                that.setState({ fileList2 })
            }
        })
    }
    handleOnRemoveIdCard = (file) => {
        var { fileList2 } = this.state;
        fileList2 = _.filter(fileList2, function (o) { return file.uid !== o.uid })
        this.setState({ fileList2 }, () => { console.log(fileList2) })
    }

    customRequestMaterials = (option) => {
        this.imagePress(option.file,2*1024*1024,1)
      
    }
    customRequestContinueMaterials=(file)=>{
        //console.log(option.file) 
        var { fileList } = this.state;
        var that = this;
        let reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = function () {
            var uid = fileList.length;
            fileList.push({ url: reader.result, thumbUrl: reader.result, name: '', uid: uid, status: 'uploading' });
            that.setState({ fileList }, () => {
                that.uploadFile(file, uid);
            })
        }
    }
    uploadFile = (file, uid) => {
        var { fileList } = this.state;
        var that = this;
        const formData = new FormData();
        // 这里的 image 是字段，根据具体需求更改
        formData.append('file', file);
        that.props.uploadFile(formData).then(msg => {
            if (msg.data.code === 200) {
                var file = _.find(fileList, ['uid', uid]);
                file.status = 'success';
                file.name = '待提交...';
                file.url = msg.data.complete;
                file.literationURL = msg.data.data;
                that.setState({ fileList })
            }
            else {
                message.error(msg.data.message);
                var file = _.find(fileList, ['uid', uid]);
                file.status = 'error';
                file.name = '上传失败';
                that.setState({ fileList })
            }
        })
    }
    handleOnRemoveMaterials = (file) => {
        var { fileList } = this.state;
        fileList = _.filter(fileList, function (o) { return file.uid !== o.uid })
        this.setState({ fileList }, () => { console.log(fileList) })
    }
    onPreview = () => { return; }

    imageUpLoadOnChange=(info,mark)=>{
        if(mark===1){
            if (info.file.status === 'uploading') {
                this.setState({ loading1: true });
            }
            else {
                this.setState({ loading1: false });
            }   
        }else if(mark===2){
            if (info.file.status === 'uploading') {
                this.setState({ loading2: true });
            }
            else {
                this.setState({ loading2: false });
            }   
        }
       
    }
    render() {
        const uploadButton = (
            <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>Upload</div>
            </div>
        );
        const { isCheckedCard, isCheckedUnit, weekName, syme, timeList, detailId, date, dateList, workUnit,loading1,loading2,
            birday, pageTitle, username, idCard, phone, fileList, fileList2 } = this.state;
        var strs = birday.split("-");
        var year = "";

        if (strs.length >= 1) {
            year = strs[0];
        }
        var month = "";
        if (strs.length >= 2) {
            month = strs[1];
        }
        var day = ""
        if (strs.length >= 3) {
            day = strs[2];
        }
        return (
            <div>
                <div className="StatisticsPage-head1 pageHeader1"><h4>{pageTitle}预约</h4></div>
                <div className="StatisticsPage-box1 has-select-table">
                    <div className="StatisticsPage-search">
                        <div className="form-group">
                            <label className="form-label1  require">日期：</label>
                            <div className="form-ipt">
                                <Select className="ipt1" allowClear={false} value={date} onChange={this.onDateChange} >
                                    {dateList.map((item, index) => {
                                        return <Option key={item.date}>{item.date}</Option>
                                    })}
                                </Select>
                                {weekName.length > 0 && <span>({weekName})</span>}
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="form-label1  require">时间点：</label>
                            <div className="form-ipt">
                                <Select className="ipt1" allowClear={false} value={detailId} onChange={this.ondetailIdChange} >
                                    {timeList && timeList.map((date, index) => {
                                        return <Option key={date.id}>{date.startTime.substring(11, 16)}~{date.endTime.substring(11, 16)}</Option>
                                    })}
                                </Select>
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="form-label1">体检地点：</label>
                            <div className="form-ipt">
                                <span className={date.length>0?'':'tip_color'}>{this.state.physicalSite}</span>
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="form-label1">剩余名额：</label>
                            <div className="form-ipt">
                                {syme == null || syme > 0 ? <span style={{ color: 'green' }}>{syme}</span> : <span style={{ color: 'red' }}>暂无名额</span>}
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="form-label1 require">携带材料：</label>
                            <div className="form-ipt">
                                <Checkbox checked={isCheckedUnit} onChange={this.isCheckedUnitOnChange}>单位证件的复印件{this.state.erweimaOrg === "62f4a124a69c4c3f8f6a822ff51255b7" && "(归属地为浦口区)"}
                                    {this.state.erweimaOrg === "706a25384d7843a7bc5233f65d14ad51" && "(归属地江北新区)"}</Checkbox>
                                <Checkbox checked={isCheckedCard} onChange={this.isCheckedCardOnChange}>身份证原件及复印件</Checkbox>
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="form-label1 require">上传材料：</label>
                            <div className="form-ipt upload_appoint_file">
                                <Upload accept="image/png, image/jpeg" customRequest={this.customRequestMaterials}
                                    listType="picture" onRemove={this.handleOnRemoveMaterials}
                                    onPreview={this.onPreview} onChange={(info)=>this.imageUpLoadOnChange(info,1)}
                                    fileList={fileList}>
                                   {loading1 ? <Icon type='loading' /> : <Button icon={<UploadOutlined />}>上传</Button>}
                                </Upload>
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="form-label1 require">上传身份证：</label>
                            <div className="form-ipt upload_appoint_file">
                                <Upload accept="image/png, image/jpeg" customRequest={this.customRequestIdCard}
                                    listType="picture" onRemove={this.handleOnRemoveIdCard}
                                    onPreview={this.onPreview}  onChange={(info)=>this.imageUpLoadOnChange(info,2)}
                                    fileList={fileList2}>
                                    <Button icon={<UploadOutlined />}>上传</Button>
                                </Upload>
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="form-label1 require">姓名：</label>
                            <div className="form-ipt">
                                <Input placeholder="姓名" value={username} onChange={e => this.usernameChange(e)} />
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="form-label1 require">身份证号：</label>
                            <div className="form-ipt">
                                <Input placeholder="身份证号" value={idCard} onChange={e => this.idCardChange(e)} />
                            </div>
                        </div>

                        <div className="form-group">
                            <label className="form-label1 require">性别：</label>
                            <div className="form-ipt">
                                {/* <Input  value={this.state.sex} disabled /> */}
                                <Select style={{ width: "80%" }} value={this.state.sex} onChange={this.sexOnChange}>
                                    <Option key="男">男</Option>
                                    <Option key="女">女</Option>
                                </Select>
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="form-label1 require">出生日期：</label>
                            <div className="form-ipt">
                                <Input style={{ width: '25%' }} value={year} onChange={(e) => this.birdayOnChange(e, "y")} />年
                                <Input style={{ width: '25%' }} value={month} onChange={(e) => this.birdayOnChange(e, "m")} />月
                                <Input style={{ width: '25%' }} value={day} onChange={(e) => this.birdayOnChange(e, "d")} />日

                            </div>
                        </div>
                        {/* <div className="form-group">
                            <label className="form-label1 require">学校名称：</label>
                            <div className="form-ipt">
                                <Input placeholder="学校名称" value={schoolName} onChange={e => this.schoolNameChange(e)} />

                            </div>
                        </div>
                        <div className="form-group">
                            <label className="form-label1 require">班级：</label>
                            <div className="form-ipt">
                                <Input placeholder="班级" value={classGrade} onChange={e => this.classGradeChange(e)} />

                            </div>
                        </div> */}

                        <div className="form-group">
                            <label className="form-label1 require">联系电话：</label>
                            <div className="form-ipt">
                                <Input placeholder="联系电话" value={phone} onChange={e => this.phoneChange(e)} />

                            </div>
                        </div>
                        <div className="form-group">
                            <label className="form-label1 require">单位名称：</label>
                            <div className="form-ipt">
                                <Input placeholder="单位" value={workUnit} onChange={e => this.workUnitChange(e)} />

                            </div>
                        </div>
                       
                        <Button type="primary" style={{ width: "100%" }} disabled={this.state.btnState} onClick={this.submit}>确定提交</Button>
                        <div className="form-group">
                            {/* <label className="form-label1">体重：</label> */}

                            {this.state.erweimaOrg === "62f4a124a69c4c3f8f6a822ff51255b7" &&
                                <div>地址：南京开元大酒店23楼知康办证服务点（周一至周五）<p></p>咨询电话:17368120001</div>}

                             {this.state.erweimaOrg === "320101020000" &&
                                <div>地址：南京开元大酒店23楼知康办证服务点（周一至周五）<p></p>咨询电话:17368120001</div>}


                            {this.state.erweimaOrg === "706a25384d7843a7bc5233f65d14ad51" &&
                                <div>地址：江北新区明滨路明发外滩广场F4(泰山街道体检中心）<p></p>咨询电话:025-68989587</div>}
                            <p></p>
                            备注：1、预约单位名称需要与材料的单位名称一致；<p></p>
                            2、单位证件含食品生产许可证、经营许可证、化妆品生产许可证、南京市地摊贩公示卡、食品小作坊登记证、小餐饮备案信息公示卡、生活饮用水卫生许可证、
                            涉及饮用水卫生安全产品卫生许可、消毒产品生产企业卫生许可证、公共场所卫生许可证、从事食品卫生服务相关行业的单位营业执照等复印件。

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default connect(null, {
    appointmentPageDetail2, appointmentSave, uploadFile
})(main)