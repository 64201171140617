import React, { Component } from 'react'
import { Modal, Button, Table,  Input, message,  Select, Spin } from 'antd';
import './index.css'
import {projectRemove, getPhysicalTypeList,  projectSave,getSlaveList,savePhyProjectList,getParentList } from '../../../../actions/account'

// import _ from 'lodash'
import { connect } from 'react-redux'
const { Option } = Select;
const { Column } = Table;
const { confirm } = Modal;

class MachineManage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            parentList:[],
            spinning: false,
            projectName: '',
            projectCode:'',
            rank:99,
            projectId:'',
            selectedRowKeys: [],
            phyList:[],
            parentId:'',
            dataList: [],
            visible: false,
            isRequesting: false,
            refVal:'',
            defVal:'',
            refScope:'',
            physicalType: '',
            method:''
        };
    }

    componentDidMount() {
        this.loadDatas();
    }

    loadDatas = () => {
        var that = this;
        this.setState({ spinning: true })
        this.props.getSlaveList().then(msg => {
            console.log(msg)
            if (msg.data.code === 200) {
                that.setState({
                    dataList: msg.data.data,
                    spinning: false
                })
            }
        })        
        this.props.getParentList().then(msg => {
            console.log(msg)
            if (msg.data.code === 200) {
                that.setState({
                    parentList: msg.data.data
                })
            }
        })  
        this.props.getPhysicalTypeList().then(msg => {
            console.log(msg)
            if (msg.data.code === 200) {
                that.setState({
                    phyList: msg.data.data
                })
            }
        })
    }
   
    showModal = () => {
        this.setState({
            visible: true
        })
    }

    handleCancel = () => {
        this.setState({ visible: false, projectName: '',projectCode:'',rank:'',parentId:'',
    refScope:'',refVal:'',defVal:'',method:'' });

    }
    
    projectNameChange = e => {
        this.setState({
            projectName: e.target.value
        })
    }
    projectCodeChange = e => {
        this.setState({
            projectCode: e.target.value
        })
    }
    rankChange= e => {
        this.setState({
            rank: e.target.value
        })
    }
      
    handleOk = () => {
        var { projectName, projectCode,isRequesting,rank,parentId ,refScope,refVal,defVal,method} = this.state;
        if (isRequesting) {
            return
        }
        var that = this;
        this.setState({ isRequesting: true }, () => {
            that.props.projectSave({ projectName: projectName,projectCode:projectCode,method:method,
                rank:Number(rank),parentId:Number(parentId),defVal:defVal,refScope:refScope,refVal:refVal
            }).then(msg => {
                console.log(msg)
                if (msg.data.code === 200) {
                    message.success('添加成功')
                    that.handleCancel();
                    that.loadDatas();
                }
                else {
                    message.error(msg.data.message)
                }
                that.setState({ isRequesting: false })
            })
        })
    }
    
    parentOnChange = (value) => {
        this.setState({ parentId: value })
    }   
    changePage = page => {
        var that = this;
        this.setState({ page }, () => {
            that.loadDatas2()
        })
    }
     // 选中改变
     onSelectChange = selectedRowKeys => {
        console.log(selectedRowKeys)
        this.setState({ selectedRowKeys });
    };
    delItem = (recorrd) => {
        var { selectedRowKeys } = this.state;
        if (selectedRowKeys.length === 0 && recorrd==='all') {
            message.error("请选择删除的记录")
            return;
        }
        if(recorrd!=='all'){
            this.setState({
                selectedRowKeys:[recorrd.id]
            })
        }
        var that = this;
        confirm({
            content: "确认删除？",
            onOk() {
                var That = that;
                that.props.projectRemove({ ids: selectedRowKeys.join(",")}).then(msg => {
                    if (msg.data.code === 200) {
                        message.success("删除成功！");
                        That.setState({selectedRowKeys:[]})

                        That.loadDatas();
                    }

                })
            }
        });

    }

    refValChange=e=>{
        this.setState({
            refVal:e.target.value
        })
    }

    defValChange=e=>{
        this.setState({defVal:e.target.value})
    }

    refScopeChange=e=>{
        this.setState({refScope:e.target.value})
    }

    methodChange=e=>{
        this.setState({method:e.target.value})
    }
    render() {
        
        const {parentList,parentId,selectedRowKeys, spinning,   projectName, rank,projectCode, dataList,method,
        defVal,refScope,refVal} = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        return (
            <div>
                <div className="StatisticsPage-head pageHeader"><h3>小项管理</h3></div>
                <div className="StatisticsPage-box">
                    <div className="StatisticsPage-search">
                        <div className="StatisticsPage-btn">

                            <Button className="btn" type="primary" onClick={this.showModal}>添加小项</Button>
                            <Button className="btn" type="primary" onClick={()=>this.delItem('all')}>删除</Button>


                            
                            <Modal title="添加项目" visible={this.state.visible} onOk={this.handleOk} onCancel={this.handleCancel} okText="保存" cancelText="取消">
                            <div className="form-group">
                                    <label className="form-label">所属大项：</label>
                                    <div className="form-ipt">
                                    <Select style={{ width: '60%' }} value={parentId} onChange={this.parentOnChange}>
                                            {parentList.map(item => {
                                                return (<Option key={item.id}>{item.projectName}</Option>)
                                            })}
                                        </Select>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="form-label">项目名称：</label>
                                    <div className="form-ipt">
                                        <Input value={projectName} placeholder="请输入项目名称" onChange={e => this.projectNameChange(e)} />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="form-label">项目代码：</label>
                                    <div className="form-ipt">
                                        <Input value={projectCode} placeholder="请输入项目代码" onChange={e => this.projectCodeChange(e)} />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="form-label">参考值：</label>
                                    <div className="form-ipt">
                                        <Input value={refVal} placeholder="请输入参考值" onChange={e => this.refValChange(e)} />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="form-label">默认值：</label>
                                    <div className="form-ipt">
                                        <Input value={defVal} placeholder="请输入默认值" onChange={e => this.defValChange(e)} />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="form-label">参考范围：</label>
                                    <div className="form-ipt">
                                        <Input value={refScope} placeholder="请输入参考范围" onChange={e => this.refScopeChange(e)} />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="form-label">方法：</label>
                                    <div className="form-ipt">
                                        <Input value={method} placeholder="请输入方法" onChange={e => this.methodChange(e)} />
                                    </div>
                                </div>
                                
                                <div className="form-group">
                                    <label className="form-label">权重：</label>
                                    <div className="form-ipt">
                                        <Input value={rank} placeholder="请输入权重" onChange={e => this.rankChange(e)} />
                                    </div>
                                </div>
                            </Modal>
                            
                        </div>
                    </div>
                    <div>
                    <Spin tip="Loading..." spinning={spinning}>

                                    <Table size="middle" dataSource={dataList} bordered rowKey="id" pagination={false}
                                    rowSelection={rowSelection}
                                    onRow={(record, index) => {
                                        return {
                                            onClick: () => {
                                                let temp = this.state.selectedRowKeys
                                                let index = temp.indexOf(record.id)
                                                if (index === -1) { temp.push(record.id) }
                                                else { temp.splice(index, 1) }
                                                this.setState({ selectedRowKeys: temp });
                                            }
                                        }
                                    }}
                                    >
                                        <Column title="小项名称" dataIndex="projectName" key="projectName" align="center" />
                                        <Column title="项目代码" dataIndex="projectCode" key="projectCode" align="center" />
                                        <Column title="权重" dataIndex="rank" key="rank" align="center" />
                                        <Column title="操作" align="center" key="action"
                                            render={(text, record) => (
                                                <span>
                                                    {/* <span style={{ cursor: 'pointer' }} onClick={() => this.editItem(record)}>修改</span> */}
                                                    {/* <Divider type="vertical" /> */}
                                                    <span style={{ cursor: 'pointer' }} onClick={() => this.delItem(record)} >删除</span>
                                                </span>
                                            )} />
                                    </Table>
                        </Spin>
                       
                    </div>
                </div>
            </div>
        )
    }
}
export default connect(null, { projectRemove,getPhysicalTypeList,projectSave, getSlaveList,savePhyProjectList,getParentList})(MachineManage)