import React, { Component } from 'react'
import './Index.css'
import Header from './layout/Header'
// import Footer from './layout/Footer';
import { Switch, Route } from 'react-router-dom'
import { Menu, Icon } from 'antd'
import RegistrationPage from './medicalRecord/RegistrationPage'
import StatisticsPage from './medicalRecord/StatisticsPage'
import Wupin from './wupin/WupinPage'
import machineManage from './systemManage/MachineManage'
import organizationManage from './systemManage/OrganizationManage'
import accountManage from './systemManage/AccountManage'
import physicalTypeManage from './systemManage/PhysicalTypeManage'
import organizationMaster from './systemManage/organizationBS/organizationMaster'
import organizationSlave from './systemManage/organizationBS/organizationSlave'
import phyBindProject from './systemManage/organizationBS/phyBindProject'
import StackManage from './systemManage/organizationBS/StackManage'
import ReagentManage from './systemManage/ReagentManage'
import EnvironmentManage from './systemManage/EnvironmentManage'
import PersonManage from './systemManage/PersonManage' //人员管理
import { withRouter } from 'react-router-dom'
import SystemDocking from './systemManage/SystemDocking'
import ResultEntering from './systemManage/ResultEntering'
import NoEntering from './systemManage/NoEntering'
import PeopleCategory from './systemManage/PeopleCategory'
import ReportPrint from './systemManage/ReportPrint'
import DoctorManage from './systemManage/DoctorManage'
import DetectionMachineManage from './systemManage/DetectionMachineManage'
import ScaleStatisticsList from './medicalRecord/ScaleStatisticsList'
import AppointmentList from './medicalRecord/AppointmentList'
import SystemRecord from './systemManage/SystemRecord'
import AppointmentDate from './appointManage/appointmentDate'
import AppointStatisics from './appointManage/AppointStatisics'
import appointmentParam from './appointManage/appointmentParam'
import AppointmentDateDetail from './appointManage/appointmentDateDetail'
import OrganizationStatistics from './statistics/OrganizationStatistics'
import picRead from '../admin/pic_Read/pic_Read'
import DCM from '../admin/pic_Read/DCM'
import ShortLink from './shortLink/ShortLink'
import Tupian from './tupian/tupian'
import DCMDoctor from './pic_Read/DCMDoctor'
import DCMSuggest from './pic_Read/DCMSuggest'
import UserStackManage from './systemManage/organizationBS/UserStackManage'
import BmodelPage from './pic_Read/BmodelPage'
import electrocardiogramPage from './pic_Read/electrocardiogramPage'
import pissResultPage from './pic_Read/pissResultPage'
import lbpissResultPage from './pic_Read/lbpissResultPage'
import lbpissRead from './pic_Read/lbpissRead'
import mealList from './meal/mealList'
import mealClassify from './meal/mealClassify'
const { SubMenu } = Menu
class Index extends Component {
  rootSubmenuKeys = ['sub1', 'sub2', 'sub7', 'sub8', 'sub4', 'sub5', 'sub6']
  state = {
    openKeys: ['sub1'],
    isAdmin: JSON.parse(localStorage.getItem('data')).isAdmin,
  }
  onOpenChange = (openKeys) => {
    const latestOpenKey = openKeys.find(
      (key) => this.state.openKeys.indexOf(key) === -1
    )
    if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      this.setState({ openKeys })
    } else {
      this.setState({
        openKeys: latestOpenKey ? [latestOpenKey] : [],
      })
    }
  }
  handleClick = (e) => {
    switch (e.key) {
      case '1':
        this.props.history.push('/admin/medicalRecord')
        break
      case '2':
        this.props.history.push('/admin')
        break
      case '211':
        this.props.history.push('/admin/stackInfo')
        break

      case '3':
        this.props.history.push('/admin/wupin')
        break
      case '20':
        this.props.history.push('/admin/systemManage/machineManage')
        break
      case '21':
        this.props.history.push('/admin/systemManage/organizationManage')
        break
      case '22':
        this.props.history.push('/admin/systemManage/physicalTypeManage')
        break
      case '23':
        this.props.history.push('/admin/systemManage/systemDocking')
        break
      case '24':
        this.props.history.push('/admin/organizationBS/organizationMaster')
        break
      case '25':
        this.props.history.push('/admin/organizationBS/organizationSlave')
        break
      case '26':
        this.props.history.push('/admin/organizationBS/phyBindProject')
        break
      case '261':
        this.props.history.push('/admin/organizationBS/StackManage')
        break

      case '27':
        this.props.history.push('/admin/reagentManage')
        break
      case '28':
        this.props.history.push('/admin/environment')
        break
      case '29':
        this.props.history.push('/admin/systemManage/ResultEntering')
        break
      case '31':
        this.props.history.push('/admin/systemManage/ReportPrint')
        break
      case '32':
        this.props.history.push('/admin/systemManage/DoctorManage')
        break
      case '33':
        this.props.history.push('/admin/systemManage/DetectionMachine')
        break
      case '4':
        this.props.history.push('/admin/systemManage/scaleStatisticsList')
        break

      case '34':
        this.props.history.push('/admin/systemManage/accountManage')
        break
      case '35':
        this.props.history.push('/admin/systemManage/PeopleCategory')
        break
      case '61':
        this.props.history.push('/admin/medicalRecord/appointList')
        break
      case '63':
        this.props.history.push('/admin/AppointmentDateManage/AppointmentDate')
        break
      case '62-1':
        this.props.history.push('/admin/AppointmentDateManage/appointmentParam')
        break
      case '64':
        this.props.history.push(
          '/admin/AppointmentDateManage/AppointmentDateDetail'
        )
        break
      case '65':
        this.props.history.push('/admin/AppointmentDateManage/statistics')
        break

      case '36':
        this.props.history.push('/admin/systemManage/SystemRecord')
        break
      case '37':
        this.props.history.push('/admin/NoEntering')
        break
      case '51':
        this.props.history.push('/admin/organiztion/statisics')
        break
      case '81':
        this.props.history.push('/admin/DCM')
        break
      case '82':
        this.props.history.push('/admin/DCMSuggest')
        break
      case '83':
        this.props.history.push('/admin/DCMDoctor')
        break
      case '84':
        this.props.history.push('/admin/BmodelPage')
        break
      case '85':
        this.props.history.push('/admin/electrocardiogramPage')
        break
      case '86':
        this.props.history.push('/admin/pissResultPage')
        break
      case '87':
        this.props.history.push('/admin/lbpissResultPage')
        break
      case '231':
        this.props.history.push('/admin/shortLink')
        break
      case '99':
        this.props.history.push('/admin/tupian')
        break
      case '66':
        this.props.history.push('/admin/mealList')
        break
      case '67':
        this.props.history.push('/admin/mealClassify')
        break
      default:
        break
    }
  }
  render() {
    var user = JSON.parse(localStorage.getItem('user'))
    user = user.account
    return (
      <div className="page_outer_box">
        <Header name="Header" className="page_header_box" />
        <div className="ContainerMain">
          <div className="containerMenu">
            <Menu
              theme="light"
              mode="inline"
              onClick={this.handleClick}
              openKeys={this.state.openKeys}
              onOpenChange={this.onOpenChange}
              style={{ backgroundColor: '#e9edf2' }}
            >
              <SubMenu
                key="sub1"
                title={
                  <span>
                    {' '}
                    <Icon type="file-protect" /> <span>挂号</span>{' '}
                  </span>
                }
              >
                <Menu.Item key="1">上传挂号信息</Menu.Item>
                <Menu.Item key="2">挂号信息列表</Menu.Item>
                <Menu.Item key="211">人员组套列表</Menu.Item>
              </SubMenu>
              {/* {user!=="ceshi"&& <SubMenu
                key="sub2"
                title={
                  <span>
                    {' '}
                    <Icon type="file-protect" /> <span>其他挂号列表</span>{' '}
                  </span>
                }
              >
                <Menu.Item key="3">物品环境</Menu.Item>
                <Menu.Item key="4">大规模采集</Menu.Item>
              </SubMenu> } */}

              <SubMenu
                key="sub6"
                title={
                  <span>
                    {' '}
                    <Icon type="file-protect" /> <span>预约管理</span>{' '}
                  </span>
                }
              >
                {/* <Menu.Item key="61">老年预约列表</Menu.Item> */}
                <SubMenu
                  key="62"
                  title={
                    <span>
                      <span>门诊预约</span>{' '}
                    </span>
                  }
                >
                  <Menu.Item key="62-1">周期管理</Menu.Item>
                  <Menu.Item key="63">预约日期管理</Menu.Item>
                  {/* <Menu.Item key="64">预约时间段管理</Menu.Item> */}
                  <Menu.Item key="65">预约统计</Menu.Item>
                </SubMenu>
                <SubMenu
                  key="66-1"
                  title={
                    <span>
                      <span>套餐管理</span>{' '}
                    </span>
                  }
                >
                  <Menu.Item key="67">套餐分类管理</Menu.Item>
                  <Menu.Item key="66">套餐列表管理</Menu.Item>


                </SubMenu>
              </SubMenu>

              <SubMenu
                key="sub5"
                title={
                  <span>
                    {' '}
                    <Icon type="file-protect" /> <span>检验管理</span>{' '}
                  </span>
                }
              >
                <SubMenu
                  key="sub4-1"
                  title={
                    <span>
                      <span>项目管理</span>{' '}
                    </span>
                  }
                >
                  {/* {user !== 'ceshi' && <Menu.Item key="25">小项</Menu.Item>} */}
                  <Menu.Item key="24">组套项目</Menu.Item>

                  <Menu.Item key="26">项目-体检类型绑定</Menu.Item>

                  <Menu.Item key="261">组套管理</Menu.Item>
                </SubMenu>
                {/* {user!=="ceshi"&&    <Menu.Item key="32">医生管理</Menu.Item>}
             {user!=="ceshi"&&    <Menu.Item key="27">试剂管理</Menu.Item>}
              {user!=="ceshi"&&   <Menu.Item key="33">设备管理</Menu.Item>}
               {user!=="ceshi"&&  <Menu.Item key="28">环境管理</Menu.Item>}
              {user!=="ceshi"&&   <Menu.Item key="29">结果录入</Menu.Item>}
              {user!=="ceshi"&&   <Menu.Item key="37">检验编号设置</Menu.Item>}
             {user!=="ceshi"&&    <Menu.Item key="35">人群分类</Menu.Item>}
              {user!=="ceshi"&&   <Menu.Item key="31">报告打印</Menu.Item>} */}
              </SubMenu>
              <SubMenu
                key="sub4"
                title={
                  <span>
                    {' '}
                    <Icon type="cluster" /> <span>系统管理</span>{' '}
                  </span>
                }
              >
                <Menu.Item key="34">账号管理</Menu.Item>

                <Menu.Item key="231">短链接管理</Menu.Item>

                {this.state.isAdmin === 1 && (
                  <Menu.Item key="20">机器管理</Menu.Item>
                )}
                <Menu.Item key="21">机构管理</Menu.Item>
                <Menu.Item key="22">体检类型管理</Menu.Item>
                <Menu.Item key="23">系统对接</Menu.Item>
                <Menu.Item key="99">图片剪切</Menu.Item>
                {this.state.isAdmin === 1 && (
                  <Menu.Item key="36">系统日志</Menu.Item>
                )}
              </SubMenu>
              {/* {user!=="ceshi"&&  <SubMenu
                key="sub7"
                title={
                  <span>
                    {' '}
                    <Icon type="cluster" /> <span>机构统计</span>{' '}
                  </span>
                }
              >
                <Menu.Item key="51">统计报表</Menu.Item>
              </SubMenu>} */}
              
                <SubMenu
                  key="sub8"
                  title={
                    <span>
                      {' '}
                      <Icon type="cluster" /> <span>功能性报告</span>{' '}
                    </span>
                  }
                >
                  <Menu.Item key="81">胸片阅读</Menu.Item>
                  <Menu.Item key="82">词条管理</Menu.Item>
                  <Menu.Item key="83">医生配置</Menu.Item>
                  <Menu.Item key="84">B超列表</Menu.Item>
                  <Menu.Item key="85">中旗心电图列表</Menu.Item>
                  <Menu.Item key="87">理邦心电图列表</Menu.Item>
                  <Menu.Item key="86">尿机数据列表</Menu.Item>
                </SubMenu>
              
            </Menu>
          </div>
          <div className="containerShow">
            <Switch>
              <Route
                path="/admin/medicalRecord"
                exact
                component={RegistrationPage}
              />
              <Route path="/admin" exact component={StatisticsPage} />
              <Route
                path="/admin/stackInfo"
                exact
                component={UserStackManage}
              />
              <Route
                path="/admin/systemManage/machineManage"
                exact
                component={machineManage}
              />
              <Route
                path="/admin/systemManage/organizationManage"
                exact
                component={organizationManage}
              />
              <Route
                path="/admin/systemManage/personManage"
                exact
                component={PersonManage}
              />
              <Route
                path="/admin/systemManage/physicalTypeManage"
                exact
                component={physicalTypeManage}
              />
              <Route
                path="/admin/systemManage/systemDocking"
                exact
                component={SystemDocking}
              />
              <Route path="/admin/wupin" exact component={Wupin} />
              <Route
                path="/admin/organizationBS/organizationMaster"
                exact
                component={organizationMaster}
              />
              <Route
                path="/admin/organizationBS/organizationSlave"
                exact
                component={organizationSlave}
              />
              <Route
                path="/admin/organizationBS/phyBindProject"
                exact
                component={phyBindProject}
              />
              <Route
                path="/admin/organizationBS/StackManage"
                exact
                component={StackManage}
              />
              <Route
                path="/admin/reagentManage"
                exact
                component={ReagentManage}
              />
              <Route
                path="/admin/environment"
                exact
                component={EnvironmentManage}
              />
              <Route
                path="/admin/systemManage/ResultEntering"
                exact
                component={ResultEntering}
              />
              <Route
                path="/admin/systemManage/ReportPrint"
                exact
                component={ReportPrint}
              />
              <Route
                path="/admin/systemManage/DoctorManage"
                exact
                component={DoctorManage}
              />
              <Route
                path="/admin/systemManage/DetectionMachine"
                exact
                component={DetectionMachineManage}
              />
              <Route
                path="/admin/systemManage/scaleStatisticsList"
                exact
                component={ScaleStatisticsList}
              />
              <Route
                path="/admin/systemManage/accountManage"
                exact
                component={accountManage}
              />
              <Route
                path="/admin/systemManage/PeopleCategory"
                exact
                component={PeopleCategory}
              />
              <Route
                path="/admin/medicalRecord/appointList"
                exact
                component={AppointmentList}
              />
              <Route
                path="/admin/systemManage/SystemRecord"
                exact
                component={SystemRecord}
              />
              <Route
                path="/admin/AppointmentDateManage/AppointmentDate"
                exact
                component={AppointmentDate}
              />
              <Route
                path="/admin/AppointmentDateManage/AppointmentDateDetail"
                exact
                component={AppointmentDateDetail}
              />
              <Route
                path="/admin/AppointmentDateManage/statistics"
                exact
                component={AppointStatisics}
              />
              <Route
                path="/admin/AppointmentDateManage/appointmentParam"
                exact
                component={appointmentParam}
              />
              <Route path="/admin/NoEntering" exact component={NoEntering} />
              <Route
                path="/admin/organiztion/statisics"
                exact
                component={OrganizationStatistics}
              />
              <Route path="/admin/picRead" exact component={picRead} />

              <Route path="/admin/DCM" exact component={DCM} />
              <Route path="/admin/DCMSuggest" exact component={DCMSuggest} />
              <Route path="/admin/DCMDoctor" exact component={DCMDoctor} />
              {/* <Route path="/admin/DCMPre" */}
              <Route path="/admin/shortLink" exact component={ShortLink} />
              <Route path="/admin/tupian" exact component={Tupian} />
              <Route path="/admin/BmodelPage" exact component={BmodelPage} />
              <Route
                path="/admin/electrocardiogramPage"
                exact
                component={electrocardiogramPage}
              />
              <Route
                path="/admin/pissResultPage"
                exact
                component={pissResultPage}
              />
              <Route
                path="/admin/lbpissResultPage"
                exact
                component={lbpissResultPage}
              />
              <Route path="/admin/lbpissRead" exact component={lbpissRead} />
              <Route path="/admin/mealList" exact component={mealList} />
              <Route path="/admin/mealClassify" exact component={mealClassify} />
              {/* organization_Statistics_PATH */}
            </Switch>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(Index)
