import React, { Component } from 'react'
import { Button } from 'antd';
import { connect } from 'react-redux'
import { mealList } from '../../actions/account'
import './query.css'
import cookie from 'react-cookies';




class students_detail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            detail: {},
            erweimaName: '',
            erweimaOrg: '',
            erweimaOrgName: '',
            erweimaPhy: '',
            detailImageArr: [],
            mealClassify: ''
        }

    }
    componentDidMount() {
        console.log(this.props.location.state)
        this.setState({
            detail: this.props.location.state.detail,
            erweimaName: this.props.location.state.erweimaName,
            erweimaOrg: this.props.location.state.erweimaOrg,
            erweimaOrgName: this.props.location.state.erweimaOrgName,
            erweimaPhy: this.props.location.state.erweimaPhy,
            mealClassify: this.props.location.state.mealClassify,
            detailImageArr: !!this.props.location.state.detail.detailImage ? this.props.location.state.detail.detailImage.split(",") : []

        })
    }
    goback = () => {
        this.props.history.go(-1)
    }
    jumpyuyue = () => {
        var { erweimaOrg, erweimaPhy, erweimaName, erweimaOrgName, detail, mealClassify } = this.state
        this.props.history.push(
            '/students_yuyue?erweimaOrg=' +
            erweimaOrg +
            '&erweimaPhy=' +
            erweimaPhy +
            '&erweimaName=' +
            erweimaName +
            '&erweimaOrgName=' +
            erweimaOrgName +
            '&mealClassify=' + mealClassify +
            '&id=' + detail.id +
            '&type=' + '1' +
            '&mealName=' + detail.mealName

        )
    }

    render() {
        const {

            detail, detailImageArr

        } = this.state
        return (
            <div className="appointquery-bg">
                <div className="StatisticsPage-head1 pageHeader1">
                    <h3>套餐详情</h3>
                </div>
                <div style={{ position: 'relative', paddingBottom: '18px' }}>
                    <div>
                        <img src={detail.image} alt="" srcset="" style={{ width: '100%' }} />
                    </div>
                    <div style={{ fontSize: "20px", padding: '20px', background: '#fff', margin: '10px 0' }}>
                        <div style={{ color: 'red', fontWeight: 'bold', }}>¥{detail.mealPrice}</div>
                        <div style={{ margin: "10px 0" }}>{detail.mealName}</div>
                        <div style={{ fontSize: "17px", color: '#999' }}>{detail.checkProject}</div>
                    </div>
                    <div style={{ marginBottom: '40px' }}>
                        {detailImageArr.length > 0 && (
                            <div style={{
                                width: '100%',
                            }}>
                                {
                                    detailImageArr.map((res, i) => (
                                        <img src={res} alt="" srcset="" style={{ width: '100%' }} />
                                    ))
                                }
                            </div>
                        )}

                    </div>
                    <div style={{ padding: '10px', position: 'fixed', bottom: '0', width: '100%' }}>
                        <Button style={{ width: '45%', marginRight: "10%" }} size='large' onClick={() => this.goback()}>返回</Button>
                        <Button type="primary" style={{ width: '45%' }} size='large' onClick={() => this.jumpyuyue()}>购买</Button>
                    </div>
                </div>


            </div >
        )
    }
}
export default connect(null, {
    mealList
})(students_detail)