import React, { Component } from 'react'
import { Modal, Button, Table, Input, message,  Select } from 'antd';

// import _ from 'lodash'

import '../medicalRecord/RegistrationPage.css'
import {detectionMachineSave,detectionMachineUpdate,detectionMachineDelete ,detectionMachineList } from '../../../actions/account'
// export const 
// import _ from 'lodash'
import { connect } from 'react-redux'
const { Column } = Table;
const { confirm } = Modal;
const { Option } = Select;

class DetectionMachineManage extends Component {
    constructor(props) {
        super(props);
        this.state = {
           doctors:[],
           visible:false,
           loading:false,
            dname:undefined,
            dcode:undefined,
            dtype:undefined

        };
    }

    componentDidMount() {
      
        this.loadDatas();
    }

    loadDatas = () => {
       var that = this;
        that.props.detectionMachineList().then(msg => {
            if(msg.data.code===200){

                that.setState({doctors:msg.data.data})
            }
            
        })
    }
    

    showModal = () => {
        this.setState({
            curRecord: undefined,
            visible: true
        })
    }

    handleCancel = () => {
        this.setState({
            visible: false
        })
    }

    machineOnChange = e => {
        this.setState({
            machineCode: e.target.value
        })
    }

    handleOk = () => {
       
    }
    editItem = (record) => {
      
    }

    nameOnChnage=(e)=>{
        this.setState({dname:e.target.value})

    }

    dcodeOnChnage=e=>{
        this.setState({dcode:e.target.value});
    }

    dtypeOnChange=value=>{
        this.setState({dtype:value})
    }
   

    handleBindOk=()=>{
        var {dtype,dcode,dname}= this.state;
        if( dtype===undefined || dname===undefined){
            message.error("参数不全，不能提交！")
        }
        var that= this;
        that.props.detectionMachineSave({detectionMachine:dname,machineCode:dcode,machineType:dtype}).then(res=>{
            if(res.data.code===200){
                message.success("新增成功");
                that.setState({visible:false,dname:undefined,dcode:undefined,dtype:undefined})
                that.loadDatas();
            }
        })
    }

    handleBindCancel=()=>{
        this.setState({visible:false,dname:undefined,dcode:undefined,dtype:undefined})

    }

    onRelieve=id=>{
        var that = this;
        confirm({
            content: "确认删除？",
            onOk() {
                that.props.detectionMachineDelete({'ids':id}).then(res=>{
                    message.success("删除成功！")
                    that.loadDatas();
                })
            }
        })
    }
   
    render() {
        const { doctors,visible,dname,dcode,dtype} = this.state;
        return (
            <div>
                <div className="StatisticsPage-head pageHeader"><h3>仪器管理</h3></div>
                <div className="StatisticsPage-box">
                    <div className="StatisticsPage-search">
                        <div className="StatisticsPage-btn">
                            <Button className="btn" type="primary" onClick={this.showModal}>添加仪器</Button>
                          
                            <Modal width="500px" title="仪器信息" visible={visible} onOk={this.handleBindOk} onCancel={this.handleBindCancel} okText="保存" cancelText="取消">
                                <div className="form-group">
                                    <label className="form-label">仪器名称：</label>
                                    <div className="form-ipt" style={{ marginTop: '5px' }}>
                                       <Input value={dname} onChange={this.nameOnChnage}></Input>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="form-label">仪器编号：</label>
                                    <div className="form-ipt" style={{ marginTop: '5px' }}>
                                       <Input value={dcode} onChange={this.dcodeOnChnage}></Input>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="form-label">仪器类型：</label>
                                    <div className="form-ipt" style={{ marginTop: '5px' }}>
                                       <Select style={{width:'80%'}} value={dtype} onChange={this.dtypeOnChange}>
                                           <Option value={1}>提取仪</Option>
                                           <Option value={2}>检测仪</Option>
                                       </Select>
                                    </div>
                                </div>
                                
                            </Modal>

                            
                        </div>
                    </div>
                    <div>
                    
                        <Table size="middle" dataSource={doctors} bordered rowKey="id" pagination={false}  >
                        <Column title="仪器名称" dataIndex="detectionMachine" key="detectionMachine" align="center" />
                            <Column title="仪器编号" dataIndex="machineCode" key="machineCode" align="center" />
                            <Column title="电子签名" key="picture" align="center"
                                render={(record) => {
                                    return record.machineType===1?'提取仪器':'检测仪器'
                                }
                                } />
                            <Column title="操作" align="center" key="action"
                                render={(text, record) => (
                                    <span>
                                        <span style={{ cursor: 'pointer' }} onClick={() => this.onRelieve(record.id)} >删除</span>
                                    </span>
                                )} />
                        </Table>
                   
                    </div>
                </div>
            </div>
        )
    }
}
export default connect(null, { detectionMachineSave,detectionMachineUpdate,detectionMachineDelete ,detectionMachineList})(DetectionMachineManage)