import { GET_RESOURCE } from "../actions/types";
const initialState = { list: [] ,loadType:0 };
export default function(state = initialState, action) {
    switch (action.type) {
      case GET_RESOURCE:
        return {
          ...state,
          ...action.payload
        }; 
      default:
        return state;
    }
  }