import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Tabs, Input, Button, message, DatePicker, Modal, Select, Spin, Checkbox } from 'antd';
import { updateItemCheckNum,getPhysicalRecord, getOrganizationGuahaoList, updateInspectionResult, batchSetResult, updateSampleNumber, batchSetSampleWay,getItemInfo } from '../../../actions/account'
import _ from 'lodash'
import './NoEntering.css'
import { SearchOutlined} from '@ant-design/icons';

const { RangePicker } = DatePicker;
const { Option } = Select;
const dateFormat = 'YYYY-MM-DD hh:mm:ss';
const { TabPane } = Tabs;
class ResultEntering extends Component {
    constructor(props) {
        super(props);
        this.state = {
            resultWP:null,
            checkNumberWP:"",
            barcodeWP:"",
            isquickWP:true,
            otherSample: '',
            inputState: '1',
            barcode: '',
            result: undefined,
            choosedDates: [],//时间默认值
            startTime: undefined,
            endTime: undefined,
            dateTimeVisble: false,
            setMarK: 1,
            allList: [],
            gatherMode: undefined,
            loading: false,
            sampleNumber: "",
            sampleWay: 1,
            otherWay: "",
            isquick: true
        }
    }

    componentDidMount() {

    }
    handleChange = (value) => {
        console.log(`selected ${value}`);
    }
    barcodeChange = e => {
        this.setState({ barcode: e.target.value })
    }
    barcodeWPChange= e => {
        var that = this;
        this.setState({ barcodeWP: e.target.value },()=>{
            if(that.state.barcodeWP.length>=10){
                that.btnSearchWP()
            }
        })
    }
    btnSearch = () => {
        var { barcode, isquick } = this.state;
        var obj = {
            page: 0,
            size: 999
        }
        obj.barcode = barcode
        var that = this;
        message.loading('搜索中...', 0)
        this.props.getOrganizationGuahaoList(obj).then(res => {
            message.destroy()
            if (res.data.code === 200) {
                if (res.data.data.rows.length === 0) {
                    message.error("查无结果！")
                }
                else {
                    var tempSampleNumber = that.state.sampleNumber;
                    var tempSampleeWay = that.state.sampleWay;
                    var tempOtherWay = that.state.otherSample;
                    if (res.data.data.rows[0].checkSampleNumber && res.data.data.rows[0].checkSampleNumber.length > 0) {
                        tempSampleNumber = res.data.data.rows[0].checkSampleNumber
                    }
                    if (res.data.data.rows[0].sampleWay !== null) {
                        tempSampleeWay = res.data.data.rows[0].sampleWay
                    }
                    if (res.data.data.rows[0].otherWay !== null) {
                        tempOtherWay = res.data.data.rows[0].otherWay
                    }
                    that.setState({
                        result: res.data.data.rows[0],
                        sampleNumber: tempSampleNumber,
                        sampleWay: tempSampleeWay,
                        otherWay: tempOtherWay,
                    })

                }
                if (isquick) {
                    this.btnClick();
                }

            }
            else {
                message.warn(res.data.message)
            }
        })

    }
    btnSearchWP = () => {
        var { barcodeWP, isquickWP } = this.state;
        var obj = {}
        obj.barcode = barcodeWP
        var that = this;
        message.loading('搜索中...', 0)
        this.props.getItemInfo(obj).then(res => {
            message.destroy()
            if (res.data.code === 200) {
                if (!res.data.data) {
                    message.error("查无结果！")
                }
                else {
                    var tempcheckNumberWP = that.state.checkNumberWP;
                    if (res.data.data.checkSampleNumber && res.data.data.checkSampleNumber.length > 0) {
                        tempcheckNumberWP = res.data.data.checkSampleNumber
                    }  
                    that.setState({
                        resultWP: res.data.data,
                        checkNumberWP: tempcheckNumberWP
                    })

                }
                if (isquickWP) {
                    that.btnClickWP();
                }

            }
            else {
                message.warn(res.data.message)
            }
        })

    }
    checkValOnChange = (e, bigId, smallId) => {
        var { result } = this.state;
        var bigProject = _.find(result.inspectionResult.details, ['projectId', bigId]);
        var smallProject = _.find(bigProject.slaveProject, ['id', smallId]);
        smallProject.result = e.target.value;
        this.setState({ result });
    }
    otherWayOnChange = (e) => {
        var { otherWay } = this.state;
        otherWay = e.target.value.trim();
        this.setState({ otherWay })
    }
    checkSampleNumberOnChange = (e) => {
        var { sampleNumber } = this.state;
        sampleNumber = e.target.value.trim();
        this.setState({ sampleNumber: sampleNumber })
    }
    WPcheckSampleNumberOnChange= (e) => {
        var { checkNumberWP } = this.state;
        checkNumberWP = e.target.value.trim();
        this.setState({ checkNumberWP: checkNumberWP })
    }
    promptOnChange = (e, bigId, smallId) => {
        var { result } = this.state;
        var bigProject = _.find(result.inspectionResult.details, ['projectId', bigId]);
        var smallProject = _.find(bigProject.slaveProject, ['id', smallId]);
        smallProject.prompt = e.target.value;
        this.setState({ result });
    }
    otherSampleChange = (e) => {
        this.setState({ otherSample: e.target.value });

    }
    btnClick = () => {
        var { result, sampleNumber, otherWay, sampleWay } = this.state;
        var that = this;
        if (sampleWay === null) {
            message.warn("请选择采样方式！");
            return;
        }
        var obj = {
            barcode: result.barcode,
            sampleWay: sampleWay,
            otherWay: otherWay,
            checkSampleNumber: sampleNumber,
        }
        var temp = result.barcode;
        message.loading("提交中...", 0)
        that.props.updateSampleNumber(obj).then(res => {
            if (res.data.code === 200) {
                const input = this.refs.inputBarcode
                input.focus();
                that.setState({
                    sampleNumber: Number(sampleNumber) + 1,
                    sampleWay: 2,
                    otherWay: "",
                    barcode: "",
                    result: null,
                }, () => {
                    that.getInspectionResult(temp);
                })

            }
            else {
                message.destroy();
                message.error(res.data.message)
            }
        })
    }
    btnClickWP= () => {
        var { resultWP, checkNumberWP,} = this.state;
        var that = this;
        if (checkNumberWP.length===0) {
            message.warn("请输入检验编号");
            return;
        }
        var obj = {
            id: resultWP.id,
            checkNumber: checkNumberWP,
        }
        message.loading("提交中...", 0)
        var temp = resultWP.barcode;
        that.props.updateItemCheckNum(obj).then(res => {
            if (res.data.code === 200) {
                message.destroy();
                const input = this.refs.inputBarcode2
                input.focus();
                that.setState({
                    checkNumberWP: Number(checkNumberWP) + 1,
                    barcodeWP: "",
                    resultWP: null,
                }, () => {
                    that.getInspectionResult(temp);
                })

            }
            else {
                message.destroy();
                message.error(res.data.message)
            }
        })
    }
    getInspectionResult = (barcode) => {
        var that = this;
        this.props.getPhysicalRecord({ barcode: barcode }).then(res => {
            if (res.data.code === 200) {
                if (res.data.data === null) {
                    message.destroy();
                    message.error("检验结果不存在！")
                }
                else {
                    var result = res.data.data;
                    result.inspectionResult.details.forEach(item => {
                        if (item.projectName.includes('核酸')) {
                            item.slaveProject.forEach(slave => {
                                if (slave.projectName.includes('核酸')) {
                                    slave.result = "阴性";
                                    slave.prompt = "-";
                                }
                            });

                        }
                    });
                    that.props.updateInspectionResult(result).then(res => {
                        message.destroy();
                        if (res.data.code === 200) {
                            message.success("录入成功！")
                        }
                        else {
                            message.error('保存检验结果错误：' + res.data.message);
                        }
                    })
                }
            }
            else {
                message.destroy();
                message.error('检索检验结果错误：' + res.data.message);

            }
        })
    }
    inputStateOnChange = (value) => {
        this.setState({ inputState: value })
    }

    datetimeChange = (date, dateString) => {
        // var choseDate =[];
        // choseDate = _.concat(choseDate,moment(dateString[0]+" 00:00:00","YYYY-MM-DD hh:mm:ss"));
        // choseDate = _.concat(choseDate,moment(dateString[1]+" 23:59:59","YYYY-MM-DD hh:mm:ss"));
        this.setState({
            startTime: dateString[0] === "" ? undefined : dateString[0] + " 00:00:00",
            endTime: dateString[1] === "" ? undefined : dateString[1] + " 23:59:59",
            choosedDates: date,
        })
    }

    batchSetOnClick = (mark) => {
        this.setState({
            dateTimeVisble: true,
            startTime: undefined,
            endTime: undefined,
            choosedDates: [],
            setMarK: mark
        })
    }

    onDateOK = () => {
        //  setmark
        var { startTime, endTime, setMarK, gatherMode, otherSample } = this.state;
        var that = this;
        if (setMarK === 1) {
            var formdata = new FormData();
            formdata.append("startTime", startTime);
            formdata.append("endTime", endTime);
            that.props.batchSetResult(formdata).then(res => {
                console.log(res)
                if (res.data.code === 200) {
                    message.success("批量结果设置成功！");
                    that.setState({
                        gatherMode: undefined,
                        dateTimeVisble: false,
                        startTime: undefined,
                        endTime: undefined,
                        choosedDates: []
                    })
                }
            })
        }
        else {
            console.log(startTime, endTime, gatherMode, otherSample)
            var obj = {
                startTime: startTime,
                endTime: endTime,
                sampleWay: gatherMode
            }
            if (gatherMode === 0) {
                obj.other = otherSample
            }
            that.props.batchSetSampleWay(obj).then(res => {
                console.log(res)
                if (res.data.code === 200) {
                    message.success("批量结果设置成功！");

                    that.setState({
                        gatherMode: undefined,
                        otherSample: '',
                        dateTimeVisble: false,
                        startTime: undefined,
                        endTime: undefined,
                        choosedDates: []
                    })
                }
            })
            // batchSetSampleWay
            // //批量设置鼻试纸
            // that.setState({allList:[]},()=>{
            //     that.getGuaHaoList(0,[]);
            // })

        }
    }

    updateInspection = (list, i) => {
        var that = this;
        if (i >= list.length) {
            message.success("批量设置采集方式成功！")
            that.setState({
                dateTimeVisble: false,
                loading: false,

            })
            return;
        }
        var barcode = list[i].barcode;
        var obj = {
            barcode: barcode,
            sampleWay: this.state.gatherMode
        }
        // var formdata =new FormData();
        // formdata.append('barcode',barcode);
        // formdata.append('sampleWay',1);

        that.props.updateSampleNumber(obj).then(res => {
            if (res.data.code === 200) {
                i++;
                that.updateInspection(list, i);
            }
        })

    }

    getGuaHaoList = (page, list) => {
        var { startTime, endTime, allList } = this.state
        var that = this;
        this.setState({
            loading: true
        })
        if (list.length === 0 && page !== 0) {
            that.updateInspection(allList, 0)
            return;
        }
        else {

            var obj = {
                page: page,
                size: 10,
                startTime: startTime,
                endTime: endTime
            }
            obj.organizationId = JSON.parse(localStorage.getItem('data')).organizationId;
            // organizationId
            that.props.getOrganizationGuahaoList(obj).then(res => {
                if (res.data.code === 200) {
                    page++;
                    that.setState({
                        allList: _.concat(allList, res.data.data.rows)
                    }, () => {
                        that.getGuaHaoList(page, res.data.data.rows)

                    })
                    // list = _.concat(list,)
                }
            })
        }

    }
    // 

    onDateCancel = () => {
        this.setState({
            gatherMode: undefined,
            dateTimeVisble: false,
            startTime: undefined,
            endTime: undefined,
            choosedDates: []
        })
    }

    gatherModeOnChnage = value => {
        console.log(value)
        this.setState({ gatherMode: value })

    }
    sampleWayStateOnChange = value => {
        var { sampleWay, otherWay } = this.state
        sampleWay = value;
        if (value !== 0) {
            otherWay = ""
        }
        this.setState({ sampleWay, otherWay })

    }

    isquickOnChange = e => {
        this.setState({
            isquick: e.target.checked
        })
    }
    isquickWPOnChange= e => {
        this.setState({
            isquickWP: e.target.checked
        })
    }
    render() {
        const { checkNumberWP,resultWP,isquickWP,barcodeWP,otherWay, sampleWay, barcode, result, choosedDates, isquick, sampleNumber, gatherMode, setMarK, otherSample } = this.state
        return (
            <div>
                <div className="StatisticsPage-head pageHeader"><h3>检验编号设置</h3></div>
                <div className="StatisticsPage-box">
                    <Tabs defaultActiveKey="1">
                        <TabPane tab="人员编号" key="1">
                            <div className="StatisticsPage-search">
                                <Input ref="inputBarcode" className="ipt" style={{ height: "40px" }} value={barcode} placeholder="请输入条码号" onChange={e => this.barcodeChange(e)} onPressEnter={this.btnSearch} />
                                <Button type="primary" style={{ height: "40px" }}  icon={<SearchOutlined />} onClick={this.btnSearch} >搜索条码号</Button>
                                <Checkbox checked={isquick} onChange={this.isquickOnChange}>快速入库</Checkbox>
                            </div>
                            {result && <div className="StatisticsPage-search">
                                <span>姓名：{result.username + "   "}</span>
                                <span>证件号：{result.idCard + "   "}</span>
                                <span>条码号：{result.barcode + "   "}</span>
                            </div>}
                            <div className="tab">
                                <div className="tab-title" >
                                    采集方式
                                    <Select value={sampleWay} style={{ width: 120, margin: "0  20px " }} onChange={this.sampleWayStateOnChange} >
                                        <Option key={1} value={1}>鼻</Option>
                                        <Option key={2} value={2}>咽</Option>
                                        <Option key={3} value={3}>血</Option>
                                        <Option key={0} value={0}>其他</Option>
                                    </Select>
                                    {sampleWay === 0 && <Input placeholder="其他采集方式" style={{ width: "200px", marginRight: "50px" }} value={otherWay} onChange={(e) => this.otherWayOnChange(e)} />}
                                    检验编号：<Input style={{ width: "200px", marginRight: "50px" }} value={sampleNumber} onChange={(e) => this.checkSampleNumberOnChange(e)} />


                                </div>

                            </div>
                            {result !== undefined && result !== null &&
                                <div className="tab-org">
                                    <Button type="primary" style={{ height: "50px", width: "400px" }} onClick={this.btnClick}>保存</Button>
                                </div>
                            }
                        </TabPane>
                        <TabPane tab="物品编号" key="2">
                            <div className="StatisticsPage-search">
                                <Input ref="inputBarcode2" className="ipt" style={{ height: "40px" }} value={barcodeWP} placeholder="请输入条码号" onChange={e => this.barcodeWPChange(e)} onPressEnter={this.btnSearchWP} />
                                <Button type="primary" style={{ height: "40px" }}  icon={<SearchOutlined />} onClick={this.btnSearchWP} >搜索条码号</Button>
                                <Checkbox checked={isquickWP} onChange={this.isquickWPOnChange}>快速入库</Checkbox>
                               
                            </div>
                            {resultWP && <div className="StatisticsPage-search">
                                <span>单位：{resultWP.unit + "   "}</span>
                                <span>物品：{resultWP.itemsName + "   "}</span>
                                <span>条码号：{resultWP.barcode + "   "}</span>
                            </div>}
                            <div className="tab">
                                <div className="tab-title" > 
                                 检验编号：
                                 <Input style={{ width: "200px", marginRight: "50px" }} value={checkNumberWP} onChange={(e) => this.WPcheckSampleNumberOnChange(e)} />


                                </div>

                            </div>
                            {resultWP !== undefined && resultWP !== null &&
                                <div className="tab-org">
                                    <Button type="primary" style={{ height: "50px", width: "400px" }} onClick={this.btnClickWP}>保存</Button>
                                </div>
                            }
                        </TabPane>

                    </Tabs>
                </div>


                <Modal title="批量设置" visible={this.state.dateTimeVisble}
                    onOk={this.onDateOK}
                    onCancel={this.onDateCancel}>
                    <Spin spinning={this.state.loading} tip="批量设置中，请耐心等待...">
                        <div className="form-group">
                            <label className="form-label">选择日期：</label>
                            <div className="form-ipt">
                                <RangePicker dateFormat={dateFormat} placeholder={['开始时间', '结束时间']} className="date"
                                    onChange={this.datetimeChange} value={choosedDates} />
                            </div>
                        </div>
                        {setMarK === 2 && <div className="form-group">
                            <label className="form-label">选择采样方式：</label>
                            <div className="form-ipt">
                                <Select style={{ width: '300px' }} value={gatherMode} onChange={this.gatherModeOnChnage}>
                                    <Option value={1}>鼻</Option>
                                    <Option value={2}>咽</Option>
                                    <Option value={3}>血</Option>
                                    <Option value={4}>肛</Option>
                                    <Option value={0}>其他</Option>
                                </Select>
                            </div>
                        </div>}
                        {gatherMode === 0 && <div className="form-group">
                            <label className="form-label">其他方式：</label>
                            <div className="form-ipt">
                                <Input value={otherSample} placeholder="请输入其他采样方式" onChange={(e) => this.otherSampleChange(e)} />

                            </div>
                        </div>}
                    </Spin>

                </Modal>
            </div>
        )
    }
}
export default connect(null, { updateItemCheckNum,getItemInfo,updateInspectionResult, getPhysicalRecord, batchSetSampleWay, batchSetResult, getOrganizationGuahaoList, updateSampleNumber })(ResultEntering)