import React, { Component } from 'react'
import { Modal, Button, Input, Select, message, Radio, DatePicker, Checkbox, InputNumber, Alert } from 'antd';
import { connect } from 'react-redux'
import { appointmentPageDetail2, appointmentSave, getTwoCancerToken, getTwoCancerRecord ,getAppointmentInfoList} from '../../actions/account'
import './yuyue.css'

import _ from 'lodash'
import moment from 'moment';
// import { red } from '@material-ui/core/colors';
const { Option } = Select
const { TextArea } = Input;
class main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasTwoCancerRecord:false,
            twoCancerToken: "A25ED7EC1C574D23BF9E531C5376F5A4",
            idCardError: "请输入身份证号",
            orgName: "",
            twoToken: "",
            org: null,
            phy: null,
            selectedDate: moment().format("YYYY-MM-DD"),
            sex: "",
            weekName: "",
            weeks: [[], [], [], [], []],
            btnState: false, provinces: [], cities: [], counties: [], towns: [],
            time: "",
            Datas: [],
            syme: null,
            username: "", idCard: "", workUnit: "", phone: "", town: undefined, county: undefined, village: "", team: "", height: "", weight: "", occupation: "", education: "",
            pageTitle: "",
            occupation1: "800",
            occupation2: "",
            educationList: [
                {
                    value: "10",
                    text: '研究生教育'
                }, {
                    value: "20",
                    text: '大学本科教育'
                }, {
                    value: "30",
                    text: '大学专科教育'
                }, {
                    value: "40",
                    text: '中等职业教育'
                }, {
                    value: "60",
                    text: '普通高级中学教育'
                }, {
                    value: "70",
                    text: '初级中学教育'
                }, {
                    value: "80",
                    text: '小学教育'
                }, {
                    value: "90",
                    text: '其他'
                }
            ],
            occupationList: [
                {
                    value: "100",
                    text: '党的机关、国家机关、群众团体和社会组织、企事业单位负责人',
                    children: [
                        {
                            value: "101",
                            text: '中国共产党机关负责人'
                        }, {
                            value: "102",
                            text: '国家机关负责人'
                        }, {
                            value: "103",
                            text: '民族党派和工商联负责人'
                        }, {
                            value: "104",
                            text: '人民团体和群众团体、社会组织及其他成员组织负责人'
                        }, {
                            value: "105",
                            text: '基层群众自治组织负责人'
                        }, {
                            value: "106",
                            text: '企事业单位负责人'
                        }, {
                            value: "199",
                            text: '其他党政机关、群众团体和社会组织、企事业单位负责人'
                        }
                    ]
                },
                {
                    value: "200",
                    text: '专业技术人员',
                    children: [
                        {
                            value: "201",
                            text: '科学研究人员'
                        }, {
                            value: "202",
                            text: '工程技术人员'
                        }, {
                            value: "203",
                            text: '农业技术人员'
                        }, {
                            value: "204",
                            text: '飞机和船舶技术人员'
                        }, {
                            value: "205",
                            text: '卫生专业技术人员'
                        }, {
                            value: "206",
                            text: '经济和金融专业人员'
                        }, {
                            value: "207",
                            text: '法律、社会和宗教专业人员'
                        },
                        {
                            value: "208",
                            text: '教学人员'
                        }, {
                            value: "209",
                            text: '文学艺术、体育专业人员'
                        }, {
                            value: "210",
                            text: '新闻出版、文化专业人员'
                        }, {
                            value: "299",
                            text: '其他专业技术人员'
                        },
                    ]
                },
                {
                    value: "300",
                    text: '办事人员和有关人员',
                    children: [
                        {
                            value: "301",
                            text: '办事人员'
                        }, {
                            value: "302",
                            text: '安全和消防人员'
                        }, {
                            value: "399",
                            text: '其他办事人员和有关人员'
                        }
                    ]
                },
                {
                    value: "400",
                    text: '社会生产服务和生活服务人员',
                    children: [
                        {
                            value: "401",
                            text: '批发与零售服务人员'
                        }, {
                            value: "402",
                            text: '交通运输、仓储和邮政业服务人员'
                        }, {
                            value: "403",
                            text: '住宿和餐饮服务人员'
                        }, {
                            value: "404",
                            text: '信息运输、软件和信息技术服务人员'
                        }, {
                            value: "405",
                            text: '金融服务人员'
                        }, {
                            value: "406",
                            text: '房地产服务人员'
                        }, {
                            value: "407",
                            text: '租赁和商务服务人员'
                        }, {
                            value: "408",
                            text: '技术辅助服务人员'
                        }, {
                            value: "409",
                            text: '水利、环境和公共设施管理服务人员'
                        }, {
                            value: "410",
                            text: '居民服务人员'
                        }, {
                            value: "411",
                            text: '电力、燃气及水供应服务人员'
                        }, {
                            value: "412",
                            text: '修理及制作服务人员'
                        }, {
                            value: "413",
                            text: '文化、体育及娱乐服务人员'
                        }, {
                            value: "414",
                            text: '健康服务人员'
                        }, {
                            value: "499",
                            text: '其他社会生产和生活服务人员'
                        }
                    ]
                },
                {
                    value: "500",
                    text: '农、林、牧、渔业生产及辅助人员',
                    children: [
                        {
                            value: "501",
                            text: '农业生产人员'
                        }, {
                            value: "502",
                            text: '林业生产人员'
                        }, {
                            value: "503",
                            text: '畜牧业生产人员'
                        }, {
                            value: "504",
                            text: '渔业生产人员'
                        }, {
                            value: "505",
                            text: '农林牧渔生产辅助人员'
                        }, {
                            value: "599",
                            text: '其他农、林、牧、渔、水利业生产人员'
                        },
                    ]
                },
                {
                    value: "600",
                    text: '生产制造及有关人员',
                    children: [
                        {
                            value: "601",
                            text: '农副产品加工人员'
                        }, {
                            value: "602",
                            text: '食品、饮料生产加工人员'
                        }, {
                            value: "603",
                            text: '烟草及其制品加工人员'
                        }, {
                            value: "604",
                            text: '纺织、针织、印染人员'
                        }, {
                            value: "605",
                            text: '纺织品、服装和皮革、毛皮制品加工制作人员'
                        }, {
                            value: "606",
                            text: '木材加工、家具与木制品制作人员'
                        }, {
                            value: "607",
                            text: '纸及纸制品生产加工人员'
                        }, {
                            value: "608",
                            text: '印刷和记录媒介复制人员'
                        }, {
                            value: "609",
                            text: '文教、工美、体育和娱乐用品制作人员'
                        }, {
                            value: "610",
                            text: '石油加工和炼焦、煤化工制作人员'
                        }, {
                            value: "611",
                            text: '化学原料和化学制品制造人员'
                        }, {
                            value: "612",
                            text: '医药制造人员'
                        }, {
                            value: "613",
                            text: '化学纤维制造人员'
                        }, {
                            value: "614",
                            text: '橡胶和塑料制品制造人员'
                        }, {
                            value: "615",
                            text: '非金属矿物制品制造人员'
                        }, {
                            value: "616",
                            text: '采矿人员'
                        }, {
                            value: "617",
                            text: '金属冶炼和压延加工人员'
                        }, {
                            value: "618",
                            text: '机械制造基础加工人员'
                        }, {
                            value: "619",
                            text: '金属制品制造人员'
                        }, {
                            value: "620",
                            text: '通用设备制造人员'
                        }, {
                            value: "621",
                            text: '专用设备制造人员'
                        }, {
                            value: "622",
                            text: '汽车制造人员'
                        }, {
                            value: "623",
                            text: '铁路、船舶、航空设备制造人员'
                        }, {
                            value: "624",
                            text: '电气机械和器材制造人员'
                        }, {
                            value: "625",
                            text: '计算机、通信和其他电子设备制造人员'
                        }, {
                            value: "626",
                            text: '仪器仪表制造人员'
                        }, {
                            value: "627",
                            text: '废弃资源综合利用人员'
                        }, {
                            value: "628",
                            text: '电力、热力、气体、水生产和输配人员'
                        }, {
                            value: "629",
                            text: '建筑施工人员'
                        }, {
                            value: "630",
                            text: '运输设备和通用工程机械操作人员及有关人员'
                        }, {
                            value: "631",
                            text: '生产辅助人员'
                        }, {
                            value: "699",
                            text: '其他生产制造及有关人员'
                        },
                    ]
                },
                {
                    value: "700",
                    text: '军人',
                    children: []
                },
                {
                    value: "800",
                    text: '不便分类的其他从业人员', children: []
                },
                {
                    value: "999",
                    text: '不详', children: []
                },
            ],
            occupationList1: [],
            occupationList2: [],
            dateList: [],
            timeList: [],
            date: "",
            detailId: "",
            erweimaOrg: undefined,
            erweimaPhy: undefined,


            lowinsuranceNo: "", poor: "", freeCheck: "", cardType: "01", nation: "01", nationality: "CN", hosType: "02", idcardHos: "", domicileMode: "1",
            residentialAddress: "", domicileType: "1", domicileAddr: "", prov: undefined, city: undefined, teamNo: "", postCode: "", workTel: "", tctNo: "", hpvNo: "",
            bmi: "", lmpStr: "", menophaniaAge: null, menstrual: "", cyc: null, isMenopause: "1", menopauseAge: null, primiparityAge: null, isProduction: "0", isLactation: "0",
            lactationTime: null, larcControl: "1", larcCondom: "", larcAcyeterion: "", larcAcyeYear: null, larcOther: "", larcOtherCont: "", larciud: "", larciudYear: null,
            gravidityTimes: null, deliverTimes: null, optName: "", hospitalName: "", sampleDate: moment().format("YYYY-MM-DD")
        }
    }
    componentDidMount() {
        document.title = "两癌检查预约";
        var temp = [], that = this;
        console.log(this.props.location.search.substring(1))
        var params = this.props.location.search.substring(1).split("&")
        var val = []
        var query = {}
        console.log(params)
        for (var i = 0; i < params.length; i++) {
            val = params[i].split("=");
            if (val[0] === 'erweimaName' || val[0] === 'erweimaOrgName') { query[val[0]] = decodeURI(val[1]) }
            else query[val[0]] = val[1]
        }
        console.log(query)
        this.setState({ org: query.erweimaOrg, phy: query.erweimaPhy, orgName: query.erweimaOrgName.replace("永林","永宁") }, () => {
            this.loadDatas(query.erweimaOrg, query.erweimaPhy, 1)
        })
        this.state.occupationList.forEach(occ => {
            temp.push({ value: occ.value, text: occ.text })
        });
        var locationData = query
        this.setState({
            pageTitle: locationData.erweimaName.replace("永林","永宁"),
            occupationList1: temp,
            erweimaOrg: query.erweimaOrg.replace("永林","永宁"),
            erweimaPhy: query.erweimaPhy.replace("永林","永宁"),
        })
        if(query.sampleAccount===undefined){
            if(query.erweimaOrg==="62f4a124a69c4c3f8f6a822ff51255b7"){
                query.sampleAccount="3040001"
                this.props.history.push("/TwoCancerBooking2?erweimaOrg=62f4a124a69c4c3f8f6a822ff51255b7&erweimaPhy=13&erweimaName=江浦街道社区卫生服务中心两癌筛查&erweimaOrgName=江浦街道社区卫生服务中心&sampleAccount=3040001")
            return;
            }
            if(query.erweimaOrg==="706a25384d7843a7bc5233f65d14ad51"){
                query.sampleAccount="3040001"
                this.props.history.push("/TwoCancerBooking2?erweimaOrg=706a25384d7843a7bc5233f65d14ad51&erweimaPhy=13&erweimaName=泰山街道社区卫生服务中心两癌筛查&erweimaOrgName=泰山街道社区卫生服务中心&sampleAccount=3040001")
            return;
            }
            

        }
      /*  this.getTwoCancerTokenEvent(query.sampleAccount);

        this.props.getRootAreas().then(res => {
            if (res.data.code === 200) {
                that.setState({ provinces: res.data.data })
            }
        })*/

    }
    getTwoCancerTokenEvent(sampleAccount) {
        console.log(sampleAccount)
        this.props.getTwoCancerToken({ sampleAccount: sampleAccount }).then(msg => {
            if (msg.data.code === 200) {
                this.setState({ twoCancerToken: msg.data.message })
            }
            else {
                message.error('获取两癌token:' + msg.data.message)
            }
        })
    }
    onyyDateChange(date, dateString) {
        console.log(date, dateString);
    }
    loadDatas = (org, phy, mark) => {
        var that = this;
        var obj = { organizationId: org, typeId: Number(phy), currentDate: moment('2021-08-28 17:04:41').format("YYYY-MM-DD HH:mm:ss"), selectedDate: this.state.selectedDate };
        // var obj = { organizationId: org, physicalTypeId: Number(phy), currentDate: moment('2021-08-28 17:04:41').format("YYYY-MM-DD HH:mm:ss") };
        // message.loading("加载数据中....", 0)
        this.setState({ weeks: [[], [], [], [], [], [], []], dateList: [], timeList: [] }, () => {
            var { dateList, weeks } = that.state;
            message.destroy();
            console.log(obj)
            that.props.appointmentPageDetail2(obj).then(msg => {
                if (msg.data.code === 200) {
                    if (!msg.data.data || msg.data.data.length === 0) {
                        message.warn('最近没有预约事务！')
                        that.setState({ btnState: false })
                        return;
                    }


                    var festival = ["01-01", "05-01", "10-01"];//法定节假日
                    var objs = msg.data.data;
                    var countDay = 0;
                    objs.forEach(obj => {
                        obj.appointmentDateDetailDTOS.forEach(item => {
                            weeks[item.week - 1].push(item)
                        })
                        obj.startTime = moment(obj.startTime).format("YYYY-MM-DD") + " 00:00:00";
                        obj.endTime = moment(obj.endTime).format("YYYY-MM-DD") + " 23:59:59";
                        var startDate = moment(obj.startTime);
                        var endDate = moment(obj.endTime);

                        var d = moment();
                        if (d.unix() > startDate.unix()) {
                            d = moment();
                        }
                        else {
                            d = startDate;
                        }
                        // alert(startDate.getTime()+","+d.getTime()+","+endDate.getTime())
                        for (
                            d; d.unix() <= endDate.unix() && countDay < 8;
                            d = d.add(1, "days")
                        ) {
                            console.log(d.format("YYYY-MM-DD") + '-->' + countDay)
                            // console.log(moment(d).format("YYYY-MM-DD"))
                            if (festival.indexOf(d.format("MM-DD")) === -1) {
                                //从今天开始往后的   日期范围内的    
                                var temp = d.format("YYYY-MM-DD")
                                var now = moment().format("YYYY-MM-DD")
                                var day = parseInt(d.day());
                                //  ;
                                // console.log(moment(now).isBefore(temp)+'-->'+now+'  '+temp)

                                if ((moment(now).isBefore(temp) || temp === now) &&
                                    ((day !== 0) ||
                                        // ((day !== 0 && weeks[day - 1].length > 0) ||
                                        (day === 0 && weeks[6].length > 0))) {
                                    if (day !== 0) dateList.push({ date: d.format("YYYY-MM-DD"), week: day })
                                    else dateList.push({ date: d.format("YYYY-MM-DD"), week: 7 })
                                }
                                countDay++;


                            }
                        }
                    })

                    // alert(dateList.length)
                    that.setState({ dateList, weeks, btnState: false }, () => {
                        if (mark === 2) {
                            var temp = "", weekName = ""
                            console.log(that.state.dateList, that.state.weeks)
                            that.state.dateList.forEach(item => {
                                if (item.date === that.state.selectedDate) {
                                    temp = item.week;
                                    if (item.week === 1) weekName = "周一";
                                    if (item.week === 2) weekName = "周二";
                                    if (item.week === 3) weekName = "周三";
                                    if (item.week === 4) weekName = "周四";
                                    if (item.week === 5) weekName = "周五";
                                    if (item.week === 6) weekName = "周六";
                                    if (item.week === 7) weekName = "周日";
                                    console.log('是', weekName)
                                }
                            })
                            that.setState({ timeList: that.state.weeks[temp - 1], weekName })
                        }
                    })

                }
            })

        })

    }
    oneducationChange = (value) => {
        this.setState({ education: value })
    }
    onDateChange = (value) => {
        console.log(value)
        this.setState({ date: value, timeList: [], detailId: "", time: "", syme: null, selectedDate: value }, () => {

            this.loadDatas(this.state.org, this.state.phy, 2)
        })

        // , () => {
        //     Datas.forEach(item => {
        //         if (item.id !== null && item.id === Number(value)) {
        //             times = item.appointmentDateDetailDTOS;
        //             return;
        //         }
        //     })
        //     that.setState({ timeList: times })
        // }

    }
    ondetailIdChange = (value) => {
        var { timeList } = this.state;
        this.setState({ detailId: value })
        timeList.forEach(item => {
            if (item.id === Number(value)) {
                this.setState({
                    syme: Number(item.residueNumber),
                    time: `${item.startTime.substring(11, 19)}-${item.endTime.substring(11, 19)}`
                })
                return;
            }
        })
    }
    onOccupation1Change = (value) => {
        this.setState({ occupation1: value, occupation2: "" })
        var { occupationList } = this.state;
        occupationList.forEach(item => {
            if (item.value === value) {
                this.setState({ occupationList2: item.children })
            }
        })
    }
    onOccupation2Change = (value) => {
        this.setState({ occupation2: value })
    }
    GetAge = (strBirthday, date) => {
        var returnAge,
            strBirthdayArr = strBirthday.split("-"),
            birthYear = strBirthdayArr[0],
            birthMonth = strBirthdayArr[1],
            birthDay = strBirthdayArr[2],
            d = moment(date, "YYYY-MM-DD"),
            nowYear = d.format("YYYY"),
            nowMonth = d.format("MM"),
            nowDay = d.format("DD");
        if (nowYear === birthYear) {
            returnAge = 0;//同年 则为0周岁
        }
        else {
            var returnAge = nowYear - birthYear; //年之差
            // if (ageDiff > 0) {
            //     if (nowMonth === birthMonth) {
            //         var dayDiff = nowDay - birthDay;//日之差
            //         if (dayDiff < 0) {
            //             returnAge = ageDiff - 1;
            //         } else {
            //             returnAge = ageDiff;
            //         }
            //     } else {
            //         var monthDiff = nowMonth - birthMonth;//月之差
            //         if (monthDiff < 0) {
            //             returnAge = ageDiff - 1;
            //         }
            //         else {
            //             returnAge = ageDiff;
            //         }
            //     }
            // } else {
            //     returnAge = -1;//返回-1 表示出生日期输入错误 晚于今天
            // }
        }
        return returnAge;//返回周岁年龄
    }
    submit = () => {
        var that = this;
        var { date, time, detailId, username, idCard, phone, workUnit, hosType,
            prov, city, county, town, village, teamNo, team,syme,twoCancerToken,
            height, weight, occupation, education, occupation1, occupation2 } = this.state
        // console.log(date, time)
        if(syme<=0){
            message.warn("无剩余名额")
            return;
        }
        if (idCard.length !== 18) {
            message.warn("身份证格式错误")
            return;
        }
        if (parseInt(idCard.substr(16, 1)) % 2 === 1) {
            //男
            message.warn("性别不符合要求！")
            return;
        }

        if (occupation2 && occupation2.length > 0) occupation = occupation2
        else {
            if (occupation1 && occupation1.length > 0) occupation = occupation1
        }
        if (detailId.length === 0 || date.length === 0 || time.length === 0) {
            message.warn('请选择预约时间及地点！')
            return;
        }
        if (username.length === 0) {
            message.warn('姓名不得为空！')
            return;
        }
        if (idCard.length === 0) {
            message.warn('身份证号不得为空！')
            return;
        }
        if (phone.length === 0) {
            message.warn('联系电话不得为空！')
            return;
        }
        if (height.length === 0 || weight.length === 0) {
            message.warn('身高、体重不得为空！')
            return;
        }
        if (hosType.length === 0) {
            message.warn('请选择医保类型！')
            return;
        }
        // if (prov === undefined || city === undefined || county === undefined || town === undefined) {
        //     message.warn('请填写居住地必填项！')
        //     return;
        // }
        if(teamNo==='' || team===''){
            message.warn('请填写小区信息必填项！')
            return;
        }
        if(education.length===0){
            message.warn('请填写文化程度！')
            return;
        }
        if(this.state.domicileAddr.length===0){
            message.warn('请填写户籍地址！')
            return;
        }
        if(this.state.menophaniaAge===null){
            message.warn('请填写初潮年龄！')
            return;
        }
        if(     this.state.gravidityTimes===null){
            message.warn('请填写孕次！')
            return;
        }
        if( this.state.deliverTimes===null){
            message.warn('请填写分娩次数！')
            return;
        }
        idCard = idCard.trim();
        var birday = idCard.substring(6, 10) + "-" + idCard.substring(10, 12) + "-" + idCard.substring(12, 14);
        var age = this.GetAge(birday, date);
        if (age < 35 || age >= 65) {
            message.warn("您尚未达到两癌筛查年龄要求！")
            return;
        }
        var obj = {
            appointmentDetailId: Number(detailId),
            username,
            idCard: idCard.toUpperCase(),
            phone,
            appointmentDate: `${date} ${time}`,twoCancerToken
        }
        if (workUnit.length > 0) obj.workUnit = workUnit;
        // if (town.length > 0) obj.town = town;
        // if (county.length > 0) obj.county = county;
        // if (village.length > 0) obj.village = village;
        // if (team.length > 0) obj.team = team;
        if (height.length > 0) obj.height = height;
        if (weight.length > 0) obj.weight = weight;
        if (education.length > 0) obj.education = education;
        if (occupation.length > 0) obj.occupation = occupation;
        var keys = ["lowinsuranceNo", "poor", "freeCheck", "cardType", "nation", "nationality", "hosType", "idcardHos", "domicileMode",
            "residentialAddress", "domicileType", "domicileAddr", "prov", "city", "county", "town", "village", "teamNo", "team", "postCode", "workTel", "tctNo", "hpvNo",
            "bmi", "lmpStr", "menophaniaAge", "menstrual", "cyc", "isMenopause", "menopauseAge", "primiparityAge", "isProduction", "isLactation",
            "lactationTime", "larcControl", "larcCondom", "larcAcyeterion", "larcAcyeYear", "larcOther", "larcOtherCont", "larciud", "larciudYear",
            "gravidityTimes", "deliverTimes", "optName", "hospitalName", "sampleDate"];
        for (var i in keys) {
            obj[keys[i]] = this.state[keys[i]]
        }
        console.log(obj)
        this.props.appointmentSave(obj).then(msg => {
            if (msg.data.code === 200) {
                message.info('预约成功！')
                that.props.history.push('/appointQueryList?idCard=' + idCard);
            }
            else {
                message.warn(msg.data.message)
            }
        })
    }
    usernameChange = (e) => {
        this.setState({
            username: e.target.value
        })
    }

    idCardChange = (e) => {
        var { date } = this.state;
        if (date === undefined || date === '' || date === null) {
            message.warn("请先选择预约日期！")
            return;
        }
        var reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
        var idCardError = null;
        var hasTwoCancerRecord=false;
        if (e.target.value.length === 18) {
            if (reg.test(e.target.value) === false) {
                message.warn("身份证号格式错误");
                idCardError = "身份证号格式错误";
            }
            else {
                if (parseInt(e.target.value.slice(-2, -1)) % 2 === 1) {
                    this.setState({
                        sex: "男"
                    })
                    message.warn("您的性别未达到两癌筛查年龄要求！")
                    idCardError = "您的性别未达到两癌筛查年龄要求！";
                    // return;
                }
                else {
                    this.setState({
                        sex: "女"
                    })
                    var birday = e.target.value.substring(6, 10) + "-" + e.target.value.substring(10, 12) + "-" + e.target.value.substring(12, 14);
                    var age = this.GetAge(birday, date);
                    if (age < 35 || age >= 65) {
                        message.warn("您尚未达到两癌筛查年龄要求！")
                        idCardError = "您尚未达到两癌筛查年龄要求！";
                        // return;
                    }
                }

            }
        }
        else {
            idCardError = "身份证号格式错误";
        }
        if (!idCardError) {
            // 获取往年体检记录
            this.props.getTwoCancerRecord({
                json: "{\"data\":{\"cardNo\":\"" + e.target.value.trim() + "\",\"limit\":20,\"offset\":1},\"source\":\"source\",\"token\":\"token\",\"time\":\"" + moment().format('YYYY-MM-DD') + "\"}"
            }, this.state.twoCancerToken).then(msg => {
                if (msg.data.result === "fail") {
                      this.setState({hasTwoCancerRecord:false})
                }
                if (msg.data.result === "success") {
                    if(msg.data.data.tbs!==undefined){
                        if(msg.data.data.tbs!=="01"){
                            this.setState({hasTwoCancerRecord:false})  
                            Modal.error({
                                title: '提示',
                                content:"您已于"+msg.data.data.lastYear+"年参加过两癌筛查，不符合本次筛查条件",
                              });
                            return;
                        }
                    }
                    if(msg.data.data.checkResult==='0'){
                        if(msg.data.data.tbs!==undefined){
                            if(msg.data.data.tbs!=="01"){
                                this.setState({hasTwoCancerRecord:false})  
                                return;
                            }
                        }
                        Modal.error({
                            title: '提示',
                            content:"您已于"+msg.data.data.lastYear+"年参加过两癌筛查，不符合本次筛查条件",
                          });
                          this.setState({hasTwoCancerRecord:true})
                    }
                    else{
                        this.setState({hasTwoCancerRecord:false})  
                    }
                }
            })
        }
        if (!idCardError) {
            //查询是否存在了已预约记录
            // this.props.getAppointmentInfoList({idCard:e.target.value}).then(res => {
            //     if(res.data.code===200){
            //         var datas = res.data.data;
            //         for(var i=0;i<datas.length;i++){
            //             var data= datas[i];
            //             if(data.)
            //         }

            //     }
            // })
            
        }
        this.setState({
            idCard: e.target.value, idCardError
        })
    }
    workUnitChange = (e) => {
        this.setState({
            workUnit: e.target.value
        })
    }
    phoneChange = (e) => {
        this.setState({
            phone: e.target.value
        })

    }
    phoneBlur = () => {
        var { phone } = this.state;
        let reg = /^1[3|4|5|7|8][0-9]\d{8}$/;
        if (!reg.test(phone)) {
            message.error("联系电话格式有误！")
        }
    }

    townChange = (e) => {
        this.setState({
            town: e
        })
    }
    countyChange = (e) => {
        var { counties } = this.state;
        this.setState({ county: e });
        var province = _.find(counties, ['name', e]);
        if (province !== undefined) {
            var that = this;
            this.props.getChildAreas({ parentCode: province.areaCode }).then(res => {
                if (res.data.code === 200) {
                    that.setState({ towns: res.data.data, town: '' })
                }
            })
        }
    }
    villageChange = (e) => {
        this.setState({
            village: e.target.value
        })
    }
    teamChange = (e) => {
        this.setState({
            team: e.target.value
        })
    }
    heightChange = (e) => {
        this.setState({ height: e.target.value }, () => {
            this.autoCompleteBMI();
        });
    }
    weightChange = (e) => {
        this.setState({ weight: e.target.value }, () => {
            this.autoCompleteBMI();
        });
    }
    autoCompleteBMI = () => {
        var { height, weight } = this.state;
        if (height.length > 0 && weight.length > 0) {
            this.setState({
                bmi: (weight / ((height / 100) * (height / 100))).toFixed(2)
            })
        }
        else {
            this.setState({
                bmi: ""
            })
        }
    }
    isLastCheckChange = value => {
        this.setState({
            btnState: value
        })
    }
    lowinsuranceNoChange = (e) => { this.setState({ lowinsuranceNo: e.target.value.trim() }) }
    poorOnChange = (e) => { this.setState({ poor: e.target.value }) }
    freeCheckOnChange = (value) => { this.setState({ freeCheck: value }) }
    onCardTypeChange = (value) => { this.setState({ cardType: value }) }
    nationChange = (value) => { this.setState({ nation: value }) }
    nationalityChange = (value) => { this.setState({ nationality: value }) }
    hosTypeChange = (value) => { this.setState({ hosType: value }) }
    idcardHosChange = (e) => { this.setState({ idcardHos: e.target.value.trim() }) }
    domicileModeChange = (value) => { this.setState({ domicileMode: value }) }
    residentialAddressChange = (e) => { this.setState({ residentialAddress: e.target.value.trim() }) }
    domicileTypeChange = (value) => { this.setState({ domicileType: value }) }
    domicileAddrChange = (e) => { this.setState({ domicileAddr: e.target.value.trim() }) }
    provChange = (e) => {
        var { provinces } = this.state;
        this.setState({ prov: e });
        var province = _.find(provinces, ['name', e]);
        if (province !== undefined) {
            var that = this;
            this.props.getChildAreas({ parentCode: province.areaCode }).then(res => {
                if (res.data.code === 200) {
                    that.setState({ cities: res.data.data, towns: [], counties: [], city: undefined, county: undefined, town: undefined })
                }
            })
        }
    }
    cityChange = (e) => {
        var { cities } = this.state;
        this.setState({ city: e });
        var province = _.find(cities, ['name', e]);
        if (province !== undefined) {
            var that = this;
            this.props.getChildAreas({ parentCode: province.areaCode }).then(res => {
                if (res.data.code === 200) {
                    that.setState({ counties: res.data.data, towns: [], county: undefined, town: undefined })
                }
            })
        }
    }
    teamNoChange = (e) => {
        this.setState({ teamNo: e.target.value.trim() })
    }
    postCodeChange = (e) => { this.setState({ postCode: e.target.value.trim() }) }
    workTelChange = (e) => { this.setState({ workTel: e.target.value.trim() }) }
    tctNoChange = (e) => { this.setState({ tctNo: e.target.value.trim() }) }
    hpvNoChange = (e) => { this.setState({ hpvNo: e.target.value.trim() }) }
    bmiChange = (e) => { this.setState({ bmi: e.target.value.trim() }) }
    menophaniaAgeChange = (e) => { this.setState({ menophaniaAge: e }) }
    isMenopauseChange = (value) => { this.setState({ isMenopause: value, menopauseAge: "", lmpStr: "", menstrual: "", cyc: null }) }
    menopauseAgeChange = (e) => { this.setState({ menopauseAge: e }) }
    lmpStrOnChange = (value, str) => { console.log(value, str); this.setState({ lmpStr: str }) }
    menstrualChange = (value) => { this.setState({ menstrual: value }) }
    cycChange = (e) => { this.setState({ cyc: e }) }
    isProductionOnChange = (e) => { this.setState({ isProduction: e.target.value, primiparityAge: "" }) }
    primiparityAgeChange = (e) => { this.setState({ primiparityAge: e }) }
    isLactationOnChange = (e) => { this.setState({ isLactation: e.target.value, lactationTime: "" }) }
    lactationTimeChange = (e) => { this.setState({ lactationTime: e }) }
    larcControlChange = (value) => {
        this.setState({
            larcControl: value,
            larcCondom: "",
            larcAcyeterion: "",
            larcAcyeYear: "",
            larcOther: "",
            larcOtherCont: "",
            larciud: "",
            larciudYear: "",
        })
    }
    larcCondomChange = (e) => { this.setState({ larcCondom: e.target.checked ? 1 : 0 }) }
    larcAcyeterionChange = (e) => { this.setState({ larcAcyeterion: e.target.checked ? 1 : 0, larcAcyeYear: "" }) }
    larcOtherChange = (e) => { this.setState({ larcOther: e.target.checked ? 1 : 0, larcOtherCont: "" }) }
    larcAcyeYearChange = (e) => { this.setState({ larcAcyeYear: e }) }
    larcOtherContChange = (e) => { this.setState({ larcOtherCont: e.target.value.trim() }) }
    larciudChange = (e) => { this.setState({ larciud: e.target.checked ? 1 : 0, larciudYear: "" }) }
    larciudYearChange = (e) => { this.setState({ larciudYear: e }) }
    gravidityTimesChange = (e) => { this.setState({ gravidityTimes: e }) }
    deliverTimesChange = (e) => { this.setState({ deliverTimes: e }) }
    render() {
        const { weekName, syme, timeList, detailId, date, dateList, educationList, occupationList1, occupationList2,
            pageTitle, username, idCard, workUnit, phone, town, county, village, team, height, weight, occupation1,
            occupation2, education, btnState } = this.state;
        const hjbz = [
            {
                value: "1",
                text: '户籍常住'
            },
            {
                value: "2",
                text: '非户籍常住'
            },
            {
                value: "3",
                text: '港澳台常住'
            },
            {
                value: "4",
                text: '外籍常住'
            },
            {
                value: "9",
                text: '非常住居民'
            },
        ];
        const jq = [
            {
                value: "1",
                text: '3-7天'
            },
            {
                value: "2",
                text: '>8天'
            },
            {
                value: "3",
                text: '<3天'
            }, {
                value: "4",
                text: '无规律'
            }
        ];
        return (
            <div>
                <div className="StatisticsPage-head1 pageHeader1"><h3>{pageTitle}预约</h3></div>
                <div className="StatisticsPage-box1 has-select-table">
                    <div className="appoint_tips">
                        <p >
                            注意事项：两癌筛查项目为国家免费检查项目，针对人群为我区街道的35周岁~64周岁的女性。若之前参与过两癌检查的人员，TCT、HPV皆为阴性的人员，三年内无需参加检查。
前来参检的人员，请在非月经期前往{this.state.orgName}受检，<span>受检当请携带本人身份证和医保卡。另子宫切除者不参加本次检查</span>
                            </p>
                    </div>
                    <div className="StatisticsPage-search two_cancer_appoint">
                        
                        <div className="form-group">
                            <label className="form-label1  require">日期：</label>
                            <div className="form-ipt">
                                <Select className={date && date.length > 0 ? 'ipt1' : ' ipt1 require_input'} allowClear={false} value={date} onChange={this.onDateChange} >
                                    {dateList.map((item, index) => {
                                        return <Option key={item.date}>{item.date}</Option>
                                    })}
                                </Select>
                                {weekName.length > 0 && <span>({weekName})</span>}
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="form-label1  require">时间点：</label>
                            <div className="form-ipt">
                                <Select className={detailId && detailId.length > 0 ? 'ipt1' : ' ipt1 require_input'} allowClear={false} value={detailId} onChange={this.ondetailIdChange} >
                                    {timeList.map((date, index) => {
                                        return <Option key={date.id}>{date.startTime.substring(11, 16)}~{date.endTime.substring(11, 16)}</Option>
                                    })}
                                </Select>
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="form-label1">剩余名额：</label>
                            <div className="form-ipt">
                                {syme == null || syme > 0 ? <span style={{ color: 'green' }}>{syme}</span> : <span style={{ color: 'red' }}>暂无名额</span>}
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="form-label1 require">姓名：</label>
                            <div className="form-ipt">
                                <Input placeholder="姓名" className={username && username.length > 0 ? '' : 'require_input'} value={username} onChange={e => this.usernameChange(e)} />
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="form-label1">证件类型：</label>
                            <div className="form-ipt">
                                <Select className="ipt1" allowClear={false} placeholder="请选择" value={this.state.cardType} onChange={this.onCardTypeChange} >
                                    <Option key={'01'}>居民身份证</Option>
                                    <Option key={'02'}>居民户口簿</Option>
                                    <Option key={'03'}>护照</Option>
                                    <Option key={'04'}>军官证</Option>
                                    <Option key={'05'}>驾驶证</Option>
                                    <Option key={'06'}>港澳居民来往内地通行证</Option>
                                    <Option key={'07'}>台湾居民来往内地通行证</Option>
                                    <Option key={'99'}>其他法定有效证件</Option>
                                </Select>
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="form-label1 require">身份证号：</label>
                            <div className="form-ipt">
                                <Input placeholder="身份证号" className={idCard && idCard.length > 0 ? 'query_seconds_Input' : 'require_input query_seconds_Input'} value={idCard}
                                    onChange={e => this.idCardChange(e)} />
                            </div>
                        </div>
                        {/* <div className="form-group">
                            <label className="form-label1  require">上一年度是否参加两癌体检：</label>
                            <div className="form-ipt">
                                <Select className='ipt1' value={btnState} onChange={this.isLastCheckChange} disabled>
                                    <Option value={false}>否</Option>
                                    <Option value={true}>是</Option>
                                </Select>
                            </div>
                        </div> */}
                        {this.state.sex && this.state.sex.length !== 0 &&
                            <div className="form-group">
                                <label className="form-label1">性别：</label>
                                <div className="form-ipt">
                                    <Input placeholder="身份证号自动识别" value={this.state.sex} disabled />
                                </div>
                            </div>}
                        <div className="form-group">
                            <label className="form-label1 require">联系电话：</label>
                            <div className="form-ipt">
                                <Input placeholder="联系电话" className={phone && phone.length > 0 ? '' : 'require_input'} value={phone} onChange={e => this.phoneChange(e)} onBlur={this.phoneBlur} />

                            </div>
                        </div>
                        <div className="form-group">
                            <label className="form-label1 require">居住地：</label>
                            <div className="form-ipt">
                                {/* <Input placeholder="省" className={this.state.prov && this.state.prov.length>0?'':'require_input'} value={this.state.prov} onChange={e => this.provChange(e)} style={{ marginBottom: "5px" }} /> */}
                                {/* <Select className={this.state.prov && this.state.prov.length > 0 ? 'address_select' : 'address_select require_input'} value={this.state.prov} onChange={this.provChange} style={{ marginBottom: "5px" }} placeholder="省">
                                    {this.state.provinces.map(province => { return <Option value={province.name} >{province.name}</Option> })}
                                </Select>
                                <Select className={this.state.city && this.state.city.length > 0 ? 'address_select' : 'address_select require_input'} value={this.state.city} onChange={this.cityChange} style={{ marginBottom: "5px" }} placeholder="市">
                                    {this.state.cities.map(province => { return <Option value={province.name} >{province.name}</Option> })}
                                </Select>
                                <Select className={this.state.county && this.state.county.length > 0 ? 'address_select' : 'address_select require_input'} value={this.state.county} onChange={this.countyChange} style={{ marginBottom: "5px" }} placeholder="区县">
                                    {this.state.counties.map(province => { return <Option value={province.name} >{province.name}</Option> })}
                                </Select>
                                <Select className={this.state.county && this.state.county.length > 0 ? 'address_select' : 'address_select require_input'} placeholder="乡镇(街道)" onChange={this.townChange} style={{ marginBottom: "5px" }} value={this.state.town}>
                                    {this.state.towns.map(province => { return <Option value={province.name} >{province.name}</Option> })}
                                </Select> */}
                                {/* <Input placeholder="市" className={this.state.city && this.state.city.length>0?'':'require_input'}value={this.state.city} onChange={e => this.cityChange(e)} style={{ marginBottom: "5px" }} /> */}
                                {/* <Input placeholder="县区"className={this.state.county && this.state.county.length>0?'':'require_input'} value={this.state.county} onChange={e => this.countyChange(e)} style={{ marginBottom: "5px" }} /> */}
                                {/* <Input placeholder="乡镇(街道)"className={this.state.town && this.state.town.length>0?'':'require_input'} value={this.state.town} onChange={e => this.townChange(e)} style={{ marginBottom: "5px" }} /> */}
                                <Input placeholder="村" value={this.state.village} onChange={e => this.villageChange(e)} style={{ marginBottom: "5px" }} />
                                <Input className={this.state.teamNo.length>0?'':'require_input'} placeholder="小区/组号" value={this.state.teamNo} onChange={e => this.teamNoChange(e)} style={{ marginBottom: "5px" }} />
                                <Input className={this.state.team.length>0?'':'require_input'} placeholder="小区门牌号" value={this.state.team} onChange={e => this.teamChange(e)} style={{ marginBottom: "5px" }} />
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="form-label1">职业：</label>
                            <div className="form-ipt">
                                <Select className="ipt1" allowClear={false} placeholder="请选择" value={occupation1} onChange={this.onOccupation1Change} >
                                    {occupationList1.map(occ => {
                                        return <Option key={occ.value}>{occ.text}</Option>
                                    })}
                                </Select>
                                {occupation1 && occupation1.length > 0 && occupationList2 && occupationList2.length > 0 &&
                                    <Select className="ipt1" allowClear={false} placeholder="请选择" value={occupation2} onChange={this.onOccupation2Change} >
                                        {occupationList2.map(occ => {
                                            return <Option key={occ.value}>{occ.text}</Option>
                                        })}
                                    </Select>
                                }
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="form-label1  require">所在单位：</label>
                            <div className="form-ipt">
                                <Input className={workUnit.length>0?'':'require_input'} placeholder="所在单位" value={workUnit} onChange={e => this.workUnitChange(e)} />

                            </div>
                        </div>
                        {/* <div className="form-group">
                            <label className="form-label1">联系电话：</label>
                            <div className="form-ipt">
                                <Input placeholder="请输入工作单位联系电话" value={this.state.workTel} onChange={e => this.workTelChange(e)} />
                            </div>
                        </div> */}
                        {/* <div className="form-group">
                                <label className="form-label1">现住址：</label>
                                <div className="form-ipt">
                                    <Input placeholder="街道" value={town} onChange={e => this.townChange(e)} style={{ marginBottom: "5px" }} />
                                    <Input placeholder="社区" value={county} onChange={e => this.countyChange(e)} style={{ marginBottom: "5px" }} />
                                    <Input placeholder="村" value={village} onChange={e => this.villageChange(e)} style={{ marginBottom: "5px" }} />
                                    <Input placeholder="门牌" value={team} onChange={e => this.teamChange(e)} style={{ marginBottom: "5px" }} />
                                </div>
                            </div> */}
                        <div className="form-group">
                            <label className="form-label1  require">文化程度：</label>
                            <div className="form-ipt ">
                                <Select className={education.length>0?"ipt1":"ipt1 require_input"} allowClear={false} placeholder="请选择" value={education} onChange={this.oneducationChange} >
                                    {educationList.map(edu => {
                                        return <Option key={edu.value}>{edu.text}</Option>
                                    })}
                                </Select>
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="form-label1 require">身高：</label>
                            <div className="form-ipt">
                                <Input placeholder="身高cm" className={height && height.length > 0 ? '' : 'require_input'} value={height} onChange={e => this.heightChange(e)} />

                            </div>
                        </div>
                        <div className="form-group">
                            <label className="form-label1 require">体重：</label>
                            <div className="form-ipt">
                                <Input placeholder="体重kg" className={weight && weight.length > 0 ? '' : 'require_input'} value={weight} onChange={e => this.weightChange(e)} />

                            </div>
                        </div>
                        <div>
                            {/* <div className="form-group">
                                <label className="form-label1">BMI：</label>
                                <div className="form-ipt flex-row">
                                    <Input placeholder="自动计算" value={this.state.bmi} onChange={e => this.bmiChange(e)} disabled />
                                    <Button type="primary" ghost onClick={this.autoCompleteBMI}> 自动计算</Button>
                                </div>
                            </div> */}
                            <div className="form-group">
                                <label className="form-label1">BMI：</label>
                                <div className="form-ipt">
                                    <Input placeholder="自动计算" value={this.state.bmi} onChange={e => this.bmiChange(e)} disabled />
                                    {/* <Button type="primary" ghost onClick={this.autoCompleteBMI}> 自动计算</Button> */}
                                </div>
                            </div>
                            {/* <div className="form-group">
                                <label className="form-label1">低保证号：</label>
                                <div className="form-ipt">
                                    <Input placeholder="请输入低保证号" value={this.state.lowinsuranceNo} onChange={e => this.lowinsuranceNoChange(e)} />
                                </div>
                            </div> */}
                            {/* <div className="form-group">
                                <label className="form-label1">特困妇女：</label>
                                <div className="form-ipt">
                                    <Radio.Group onChange={this.poorOnChange} value={this.state.poor}>
                                        <Radio value={0}>否</Radio>
                                        <Radio value={1}>是</Radio>
                                    </Radio.Group>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="form-label1">免费检查：</label>
                                <div className="form-ipt">
                                    <Select className="ipt1" allowClear={false} placeholder="请选择" onChange={this.freeCheckOnChange} value={this.state.freeCheck} >
                                        <Option key={0}>收费</Option>
                                        <Option key={1}>常规免费</Option>
                                        <Option key={2}>复检免费</Option>
                                    </Select>
                                </div>
                            </div> */}

                            <div className="form-group">
                                <label className="form-label1">民族：</label>
                                <div className="form-ipt ">
                                    <Select className="ipt1"  allowClear={false} placeholder="请选择" value={this.state.nation} onChange={e => this.nationChange(e)}  >
                                        <Option key={'01'} value={'01'}>汉族</Option>
                                        <Option key={'08'} value={'08'}>壮族</Option>
                                        <Option key={'11'} value={'11'}>满族</Option>
                                        <Option key={'06'} value={'06'}>苗族</Option>
                                        <Option key={'03'} value={'03'}>回族</Option>
                                        <Option key={'05'} value={'05'}>维吾尔族</Option>
                                        <Option key={'07'} value={'07'}>彝族</Option>
                                        <Option key={'15'} value={'15'}>土家族</Option>
                                        <Option key={'04'} value={'04'}>藏族</Option>
                                        <Option key={'02'} value={'02'}>蒙古族</Option>
                                        <Option key={'09'} value={'09'}>布依族</Option>
                                        <Option key={'10'} value={'10'}>朝鲜族</Option>
                                        <Option key={'12'} value={'12'}>侗族</Option>
                                        <Option key={'13'} value={'13'}>瑶族</Option>
                                        <Option key={'14'} value={'14'}>白族</Option>
                                        <Option key={'16'} value={'16'}>哈尼族</Option>
                                        <Option key={'17'} value={'17'}>哈萨克族</Option>
                                        <Option key={'18'} value={'18'}>傣族</Option>
                                        <Option key={'19'} value={'19'}>黎族</Option>
                                        <Option key={'20'} value={'20'}>傈傈族</Option>
                                        <Option key={'21'} value={'21'}>佤族</Option>
                                        <Option key={'22'} value={'22'}>畲族</Option>
                                        <Option key={'23'} value={'23'}>高山族</Option>
                                        <Option key={'24'} value={'24'}>拉祜族</Option>
                                        <Option key={'25'} value={'25'}>水族</Option>
                                        <Option key={'26'} value={'26'}>东乡族</Option>
                                        <Option key={'27'} value={'27'}>纳西族</Option>
                                        <Option key={'28'} value={'28'}>景颇族</Option>
                                        <Option key={'29'} value={'29'}>柯尔克孜族</Option>
                                        <Option key={'30'} value={'30'}>土族</Option>
                                        <Option key={'31'} value={'31'}>达翰尔族</Option>
                                        <Option key={'32'}  value={'32'}>仫佬族</Option>
                                        <Option key={'33'} value={'33'}>羌族</Option>
                                        <Option key={'34'} value={'34'}>布朗族</Option>
                                        <Option key={'35'} value={'35'}>撒拉族</Option>
                                        <Option key={'36'} value={'36'}>毛难族</Option>
                                        <Option key={'37'} value={'37'}>仡佬族</Option>
                                        <Option key={'38'} value={'38'}>锡伯族</Option>
                                        <Option key={'39'} value={'39'}>阿昌族</Option>
                                        <Option key={'40'} value={'40'}>普米族</Option>
                                        <Option key={'41'} value={'41'}>塔吉克族</Option>
                                        <Option key={'42'} value={'42'}>怒族</Option>
                                        <Option key={'43'} value={'43'}>乌孜别克族</Option>
                                        <Option key={'44'} value={'44'}>俄罗斯族</Option>
                                        <Option key={'45'} value={'45'}>鄂温克族</Option>
                                        <Option key={'46'} value={'46'}>崩龙族</Option>
                                        <Option key={'47'} value={'47'}>保安族</Option>
                                        <Option key={'48'} value={'48'}>裕固族</Option>
                                        <Option key={'49'} value={'49'}>京族</Option>
                                        <Option key={'50'} value={'50'}>塔塔尔族</Option>
                                        <Option key={'51'} value={'51'}>独龙族</Option>
                                        <Option key={'52'} value={'52'}>鄂伦春族</Option>
                                        <Option key={'53'} value={'53'}>赫哲族</Option>
                                        <Option key={'54'} value={'54'}>门巴族</Option>
                                        <Option key={'55'} value={'55'}>珞巴族</Option>
                                        <Option key={'56'} value={'56'}>基诺族</Option>
                                        <Option key={'97'} value={'97'}>其他</Option>
                                        <Option key={'98'} value={'98'}>外国血统</Option>
                                    </Select>
                                </div>
                            </div>
                            {/* <div className="form-group">
                                <label className="form-label1">国籍：</label>
                                <div className="form-ipt">
                                    <Select className="ipt1" allowClear={false} placeholder="请选择" value={this.state.nationality} onChange={e => this.nationalityChange(e)}  >
                                        <Option key={'1'}>中国</Option>
                                    </Select>
                                </div>
                            </div> */}
                            <div className="form-group">
                                <label className="form-label1 require">医保类型：</label>
                                <div className="form-ipt">
                                    <Select className={this.state.hosType && this.state.hosType.length > 0 ? 'ipt1' : ' ipt1 require_input'} allowClear={false} placeholder="请选择" value={this.state.hosType} onChange={this.hosTypeChange} >
                                        <Option key={'01'}>城镇职工医疗保险</Option>
                                        <Option key={'02'}>城镇居民医疗保险</Option>
                                        <Option key={'03'}>新型农村合作医疗</Option>
                                        <Option key={'04'}>贫困救助</Option>
                                        <Option key={'05'}>商业医疗保险</Option>
                                        <Option key={'06'}>全公费</Option>
                                        <Option key={'07'}>全自费</Option>
                                        <Option key={'08'}>其他社会保险</Option>
                                        <Option key={'09'}>免费</Option>
                                        <Option key={'99'}>其他</Option>
                                    </Select>
                                </div>
                            </div>
                            {/* <div className="form-group">
                                <label className="form-label1">医保卡号：</label>
                                <div className="form-ipt">
                                    <Input placeholder="请输入医保卡号" value={this.state.idcardHos} onChange={e => this.idcardHosChange(e)} />
                                </div>
                            </div> */}
                            <div className="form-group">
                                <label className="form-label1">户口类别：</label>
                                <div className="form-ipt">
                                    <Select className="ipt1" allowClear={false} placeholder="请选择" value={this.state.domicileMode} onChange={this.domicileModeChange} >
                                        <Option key={'1'} value={'1'}>本区县</Option>
                                        <Option key={'2'} value={'2'}>本市外区</Option>
                                        <Option key={'3'} value={'3'}>本省外市</Option>
                                        <Option key={'4'} value={'4'}>外省</Option>
                                        <Option key={'5'} value={'5'}>港澳台</Option>
                                        <Option key={'6'} value={'6'}>外籍</Option>
                                    </Select>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="form-label1">户籍类别：</label>
                                <div className="form-ipt">
                                    <Select className="ipt1" allowClear={false} placeholder="请选择" value={this.state.domicileType} onChange={this.domicileTypeChange} >
                                        {hjbz.map(item => (
                                            <Option key={item.value} value={item.value}>{item.text}</Option>
                                        ))}
                                    </Select>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="form-label1">户籍地址：</label>
                                <div className="form-ipt">
                                    <TextArea className={this.state.domicileAddr.length>0?'':'require_input'} placeholder="请输入户籍地址" value={this.state.domicileAddr} onChange={e => this.domicileAddrChange(e)} />
                                </div>
                            </div>
                            {/* <div className="form-group">
                                <label className="form-label1">居住地址：</label>
                                <div className="form-ipt">
                                    <TextArea placeholder="请输入居住地址" value={this.state.residentialAddress} onChange={e => this.residentialAddressChange(e)} />
                                </div>
                            </div> */}
                            {/* <div className="form-group">
                                <label className="form-label1 require">居住地：</label>
                                <div className="form-ipt">
                                    <Input placeholder="省" className={this.state.prov && this.state.prov.length>0?'':'require_input'} value={this.state.prov} onChange={e => this.provChange(e)} style={{ marginBottom: "5px" }} />
                                    <Input placeholder="市" className={this.state.city && this.state.city.length>0?'':'require_input'}value={this.state.city} onChange={e => this.cityChange(e)} style={{ marginBottom: "5px" }} />
                                    <Input placeholder="县区"className={this.state.county && this.state.county.length>0?'':'require_input'} value={this.state.county} onChange={e => this.countyChange(e)} style={{ marginBottom: "5px" }} />
                                    <Input placeholder="乡镇(街道)"className={this.state.town && this.state.town.length>0?'':'require_input'} value={this.state.town} onChange={e => this.townChange(e)} style={{ marginBottom: "5px" }} />
                                    <Input placeholder="村" value={this.state.village} onChange={e => this.villageChange(e)} style={{ marginBottom: "5px" }} />
                                    <Input placeholder="小区/组号" value={this.state.teamNo} onChange={e => this.teamNoChange(e)} style={{ marginBottom: "5px" }} />
                                    <Input placeholder="小区门牌号" value={this.state.team} onChange={e => this.teamChange(e)} style={{ marginBottom: "5px" }} />
                                </div>
                            </div> */}
                            <div className="form-group">
                                <label className="form-label1">邮编：</label>
                                <div className="form-ipt">
                                    <Input placeholder="请输入邮编" value={this.state.postCode} onChange={e => this.postCodeChange(e)} />
                                </div>
                            </div>
                            {/* <div className="form-group">
                                <label className="form-label1">TCT编号：</label>
                                <div className="form-ipt">
                                    <Input placeholder="请输入TCT编号" value={this.state.tctNo} onChange={e => this.tctNoChange(e)} />
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="form-label1">HPV编号：</label>
                                <div className="form-ipt">
                                    <Input placeholder="请输入HPV编号" value={this.state.hpvNo} onChange={e => this.hpvNoChange(e)} />
                                </div>
                            </div> */}
                            <div className="form-group">
                                <label className="form-label1">初潮年龄(岁)：</label>
                                <div className="form-ipt require_input">
                                    <InputNumber style={{ width: "100%" }} placeholder="请输入初潮年龄(岁)" value={this.state.menophaniaAge} onChange={e => this.menophaniaAgeChange(e)} />
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="form-label1">是否绝经：</label>
                                <div className="form-ipt">
                                    <Select className="ipt1" allowClear={false} placeholder="请选择" value={this.state.isMenopause} onChange={this.isMenopauseChange} >
                                        <Option key={'0'}>否</Option>
                                        <Option key={'1'}>是</Option>
                                    </Select>
                                </div>
                            </div>
                            {this.state.isMenopause === '1' &&
                                <div className="form-group">
                                    <label className="form-label1">绝经年龄(岁)：</label>
                                    <div className="form-ipt">
                                        <InputNumber style={{ width: "100%" }} placeholder="请输入绝经年龄(岁)" value={this.state.menopauseAge} onChange={e => this.menopauseAgeChange(e)} />
                                    </div>
                                </div>}
                            {this.state.isMenopause !== '1' && <div className="form-group">
                                <label className="form-label1">末次月经：</label>
                                <div className="form-ipt">
                                    <DatePicker format={'YYYY-MM-DD'} style={{ width: "100%" }} value={this.state.lmpStr ? moment(this.state.lmpStr, 'YYYY-MM-DD') : null}
                                        onChange={this.lmpStrOnChange} />
                                </div>
                            </div>}
                            {this.state.isMenopause !== '1' && <div className="form-group">
                                <label className="form-label1">经期：</label>
                                <div className="form-ipt">
                                    <Select className="ipt1" allowClear={false} placeholder="请选择" value={this.state.menstrual} onChange={this.menstrualChange} >
                                        {jq.map(item => (
                                            <Option key={item.value} value={item.value}>{item.text}</Option>
                                        ))}
                                    </Select>
                                </div>
                            </div>}
                            {this.state.isMenopause !== '1' && <div className="form-group">
                                <label className="form-label1">月经周期(天)：</label>
                                <div className="form-ipt">
                                    <InputNumber style={{ width: "100%" }} placeholder="请输入月经周期(天)" value={this.state.cyc} onChange={e => this.cycChange(e)} />
                                </div>
                            </div>}



                            <div className="form-group">
                                <label className="form-label1">是否生产过：</label>
                                <div className="form-ipt">
                                    <Radio.Group onChange={this.isProductionOnChange} value={this.state.isProduction}>
                                        <Radio value={'0'}>否</Radio>
                                        <Radio value={'1'}>是</Radio>
                                    </Radio.Group>
                                </div>
                            </div>
                            {this.state.isProduction === '1' &&
                                <div className="form-group">
                                    <label className="form-label1">初产年龄(岁)：</label>
                                    <div className="form-ipt">
                                        <InputNumber style={{ width: "100%" }} placeholder="请输入初产年龄(岁)" value={this.state.primiparityAge} onChange={e => this.primiparityAgeChange(e)} />
                                    </div>
                                </div>}
                            <div className="form-group">
                                <label className="form-label1">是否哺乳：</label>
                                <div className="form-ipt">
                                    <Radio.Group onChange={this.isLactationOnChange} value={this.state.isLactation}>
                                        <Radio value={'0'}>否</Radio>
                                        <Radio value={'1'}>是</Radio>
                                    </Radio.Group>
                                </div>
                            </div>
                            {this.state.isLactation === '1' &&
                                <div className="form-group">
                                    <label className="form-label1">母乳喂养持续时间(月)：</label>
                                    <div className="form-ipt">
                                        <InputNumber style={{ width: "100%" }} placeholder="请输入母乳喂养持续时间(月)" value={this.state.lactationTime} onChange={e => this.lactationTimeChange(e)} />
                                    </div>
                                </div>}
                            <div className="form-group">
                                <label className="form-label1">避孕情况：</label>
                                <div className="form-ipt">
                                    <Select className="ipt1" allowClear={false} placeholder="请选择" value={this.state.larcControl} onChange={this.larcControlChange} >
                                        <Option key={'1'}>未避孕</Option>
                                        <Option key={'2'}>避孕</Option>
                                        <Option key={'3'}>不详</Option>
                                    </Select>
                                    {this.state.larcControl === '2' && <div>
                                        <Checkbox style={{ width: '100%', margin: '10px 0px' }} onChange={this.larcCondomChange} checked={this.state.larcCondom === 1}>避孕套</Checkbox>
                                        <Checkbox style={{ width: '100%', margin: '10px 0px' }} onChange={this.larcAcyeterionChange} checked={this.state.larcAcyeterion === 1}>避孕药</Checkbox>
                                        {this.state.larcAcyeterion === 1 && <InputNumber style={{ width: "100%" }} placeholder="避孕药复用年数(月)" value={this.state.larcAcyeYear} onChange={e => this.larcAcyeYearChange(e)} />}


                                        <Checkbox style={{ width: '100%', margin: '10px 0px' }} onChange={this.larciudChange} checked={this.state.larciud === 1}>宫内节育器</Checkbox>
                                        {this.state.larciud === 1 && <InputNumber style={{ width: "100%" }} placeholder="宫内节育器年数(年)" value={this.state.larciudYear} onChange={e => this.larciudYearChange(e)} />}


                                        <Checkbox style={{ width: '100%', margin: '10px 0px' }} onChange={this.larcOtherChange} checked={this.state.larcOther === 1}>其他避孕方式</Checkbox>
                                        {this.state.larcOther === 1 && <Input placeholder="其他避孕方法" value={this.state.larcOtherCont} onChange={e => this.larcOtherContChange(e)} />}

                                    </div>}


                                </div>
                            </div>
                            <div className="form-group">
                                <label className="form-label1">孕次：</label>
                                <div className="form-ipt">
                                    <InputNumber className={this.state.gravidityTimes===null?"require_input":""} style={{ width: "100%" }} placeholder="请输入孕次(次)" value={this.state.gravidityTimes} onChange={e => this.gravidityTimesChange(e)} />
                                </div>
                            </div>
                       
                            <div className="form-group">
                                <label className="form-label1">分娩次数：</label>
                                <div className="form-ipt">
                                    <InputNumber style={{ width: "100%" }}  className={this.state.deliverTimes===null?"require_input":""} placeholder="请输入分娩(次)" value={this.state.deliverTimes} onChange={e => this.deliverTimesChange(e)} />
                                </div>
                            </div>
                        </div>
                        <Button type="primary" style={{ width: "100%" }} disabled={this.state.idCardError || syme<=0|| this.state.hasTwoCancerRecord} onClick={this.submit}>确定提交</Button>
                        {this.state.idCardError && <Alert message={this.state.idCardError} type="error" />}
                    </div>
                </div>
            </div>
        )
    }
}
export default connect(null, {
    appointmentPageDetail2, appointmentSave, getTwoCancerToken, getTwoCancerRecord,getAppointmentInfoList
})(main)