import React, { Component } from 'react'
import { connect } from 'react-redux'
import '../medicalRecord/RegistrationPage.css'
import {
    getBarcode, addGuahao, oldSystemData, getPhysicalTypeList, inportBackUP, getDetail, guahaoUpdate, getAllorganization,fubaosuogetData,
    transferPhysicalData, excelGeneratePdf, itemRegistration, exportParseExcel, exportHsExcel,getTwoCancerToken,getTwoCancerRecord,getDcmInfolIST,dcmUpdate
} from '../../../actions/account'
import {fubaosuo_getdata_PATH} from '../../../actions/urls'
import _ from 'lodash'
import { Select, Tooltip, Icon, Upload, Button, message, DatePicker } from 'antd';
import moment from 'moment';
import './SystemDocking.css'

import XLSX from 'xlsx';
import Axios from 'axios'
const { Option } = Select;
const dateFormat = 'YYYY-MM-DD hh:mm:ss';
const { RangePicker } = DatePicker;

class SystemDocking extends Component {
    constructor(props) {
        super(props);
        this.state = {
            choosedDates: [],//时间默认值
            startTime: undefined,
            endTime: undefined,

            selectValue: undefined,
            ptypes: [],
            physicalTypeValue: undefined,
            physicalTypeValue2: undefined,
            physicalTypeValue3:undefined,
            contents: [],
            contents2: [],
            // getAllorganization
            organiations: [],twoCancerToken:"A25ED7EC1C574D23BF9E531C5376F5A4",
            organizationId: undefined

        }
    }
    componentDidMount() {
        var that = this;

        that.props.getPhysicalTypeList().then(res => {
            if (res.data.code === 200) {
                that.setState({
                    ptypes: res.data.data
                })
            }
        })
        that.props.getAllorganization().then(res => {
            if (res.data.code === 200) {
                that.setState({
                    organiations: res.data.data
                })
            }
        })
    }

    selectOnChange = value => {
        this.setState({
            selectValue: value
        })
    }

    physicalTypeOnChange = (value) => {
        this.setState({
            physicalTypeValue: value
        })
    }
    physicalTypeOnChange2 = (value) => {
        this.setState({
            physicalTypeValue2: value
        })
    }
    physicalTypeOnChange3 = (value) => {
        this.setState({
            physicalTypeValue3: value
        })
    }
    registrationData = (e, mark) => {
        var files = e.target.files;
        var that = this;
        var fileReader = new FileReader();
        fileReader.onload = function (ev) {
            try {
                var data = ev.target.result,
                    workbook = XLSX.read(data, { type: 'binary' }),
                    persons = []; // 存储获取到的数据
            } catch (e) {
                console.log('文件类型不正确');
                return;
            }

            // 遍历每张表读取
            for (var sheet in workbook.Sheets) {
                if (workbook.Sheets.hasOwnProperty(sheet)) {
                    persons = persons.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]));
                    // break; // 如果只取第一张表，就取消注释这行
                }
            }
            if (mark === 1) {

                that.askrequest2(persons, 0)
            }
            else if (mark === 2) {
                that.askrequest3(persons, 0)
            }
            else if (mark === 3) {
                that.askrequest4(persons, 0)
            }
            else if (mark === 4) {
                that.askrequest5(persons, 0)
            }
            else if (mark === 5) {
                that.askrequest6(persons, 0)
            }
            else if(mark === 6){
                that.askrequest7(persons,0)
            }
            else if(mark ===7){
                that.askrequest8(persons,0)
            }
        };
        // 以二进制方式打开文件
        fileReader.readAsBinaryString(files[0]);
    }

    askrequest8= async (persons,i)=>{
        for(var i=0 ; i< persons.length;i++){
            var person = persons[i];
            message.info("正在处理第"+i+"/"+persons.length+"个")
            var barcode =  person.体检编号;
            if(barcode===undefined || barcode ===null){
                barcode= person.体检编码;
            }
            if(barcode===undefined || barcode ===null){
            message.error("缺少体检编号列")
            }
            var obj = { page:0, size: 10, barcode:  barcode,auditStatus:0};
            var physical =undefined;
            await  this.props.getDcmInfolIST(obj).then(msg => {
                if(msg.data.code === 200){
                    if(msg.data.data.rows.length!==0){
                        physical =msg.data.data.rows[0];
                    }
                }
            });
            if(physical!==undefined){
                var objj= {id:physical.id};
                objj.age= person.年龄;
                objj.username = person.姓名;
                objj.idCard= person.证件号;
                objj.auditStatus=0;
                if(objj.idCard===undefined || objj.idCard ===null){
                    objj.idCard= person.身份证号;
                }
            
            if(person.性别 ==="男"){
                objj.sex = 1;
              }else{
                objj.sex=2;
              }
            objj.checkDoctor = "顾卫凯"; 
            objj.auditDoctor = "袁冬兰";
            await this.props.dcmUpdate(objj).then(msg=>{
                message.info("第"+i+"个，共"+persons.length+"个，"+msg.data.message);
              })
            }
        }
      


    }

    exportExcel=(headers, data, fileName = '请假记录表.xlsx')=> {
        const _headers = headers
            .map((item, i) => Object.assign({}, { key: item.key, title: item.title, position: String.fromCharCode(65 + i) + 1 }))
            .reduce((prev, next) => Object.assign({}, prev, { [next.position]: { key: next.key, v: next.title } }), {});
    
        const _data = data
            .map((item, i) => headers.map((key, j) => Object.assign({}, { content: item[key.key], position: String.fromCharCode(65 + j) + (i + 2) })))
            // 对刚才的结果进行降维处理（二维数组变成一维数组）
            .reduce((prev, next) => prev.concat(next))
            // 转换成 worksheet 需要的结构
            .reduce((prev, next) => Object.assign({}, prev, { [next.position]: { v: next.content } }), {});
    
        // 合并 headers 和 data
        const output = Object.assign({}, _headers, _data);
        // 获取所有单元格的位置
        const outputPos = Object.keys(output);
        // 计算出范围 ,["A1",..., "H2"]
        const ref = `${outputPos[0]}:${outputPos[outputPos.length - 1]}`;
    
        // 构建 workbook 对象
        const wb = {
            SheetNames: ['mySheet'],
            Sheets: {
                mySheet: Object.assign(
                    {},
                    output,
                    {
                        '!ref': ref,
                        '!cols': [{ wpx: 45 }, { wpx: 100 }, { wpx: 200 }, { wpx: 80 }, { wpx: 150 }, { wpx: 100 }, { wpx: 300 }, { wpx: 300 }],
                    },
                ),
            },
        };
    
        // 导出 Excel
        XLSX.writeFile(wb, fileName);
    }


    askrequest7=async(persons,i)=>{
        message.info("第"+i+"个，共"+persons.length+"个")
        if (i >= persons.length) {
            return;
        }
        var {twoCancerToken} = this.state;
        var that = this
       
        if(twoCancerToken===undefined){
            // await this.props.getTwoCancerToken({sampleAccount:"3040001"}).then(res=>{
            //     twoCancerToken=res.data.message;
            //     that.setState({twoCancerToken: res.data.message })
                
            // })
            twoCancerToken="A25ED7EC1C574D23BF9E531C5376F5A4"

        }
        
       
        var person = persons[i];
        var hasTwoCancerRecord=true;
        this.props.getTwoCancerRecord({
            json: "{\"data\":{\"cardNo\":\"" + person.身份证号 + "\",\"limit\":20,\"offset\":1},\"source\":\"source\",\"token\":\"token\",\"time\":\"" + moment().format('YYYY-MM-DD') + "\"}"
        }, twoCancerToken).then(msg => {
            if (msg.data.result === "fail") {
                    hasTwoCancerRecord=false
            }
            if (msg.data.result === "success") {
                if(msg.data.data.checkResult==='0'){
                    if(msg.data.data.tbs!==undefined){
                        if(msg.data.data.tbs!=="01"){
                                hasTwoCancerRecord=false
                        }
                    }
                }
            }
       
            var cont =person.姓名+"&"+person.身份证号+ "&"+person.年龄+ "&"+ person.联系电话+ "&"+ person.企业名称+ "&"+ person.登记日期

            if(!hasTwoCancerRecord){
                this.setState({
                    contents2: _.concat(this.state.contents2, cont)
                })
            }
            i++
            setTimeout(() => {
                that.askrequest7(persons, i)
            }, 2000);
        })
       

    }


    /**
     * 生成核酸报告
     * @param {} persons 
     * @param {*} i 
     */
    askrequest5 = (persons, i) => {
        var arr = [];
        for (i = 0; i < persons.length; i++) {
            var obj = {};
            var barcode = persons[i].条码
            if (barcode === undefined) {
                barcode = persons[i].条码号
            }
            obj.barcode = barcode === undefined ? '' : barcode;
            if (persons[i].身份证号 === undefined) {
                message.error("身份证号列为空")
                return;
            }
            var idcard = persons[i].身份证号;
            obj.idCard = idcard;
            obj.sex = this.getInfoByIdCard(idcard, 2);
            if (obj.sex === 1) {
                obj.sex = "男"
            }
            else {
                obj.sex = "女"
            }
            obj.age = this.getInfoByIdCard(idcard, 3);
            if (persons[i].姓名 === undefined) {
                message.error("姓名列为空")
                return;
            }
            obj.username = persons[i].姓名;
            obj.collect = persons[i].采样地点;
            obj.environment = persons[i].环境条件;
            obj.machine = persons[i].检测仪器;
            obj.checkType = persons[i].采样类型;
            var reagents = [];
            var resgent = {}
            if (persons[i].试剂一名称 !== undefined && persons[i].试剂一名称 !== "" && persons[i].试剂一名称 !== null && persons[i].试剂一名称 !== "无") {
                resgent.name = persons[i].试剂一名称;
                resgent.number = persons[i].试剂一批号;
                resgent.validity = persons[i].试剂一有效期;
                reagents = _.concat(reagents, resgent)
            }
            if (persons[i].试剂二名称 !== undefined && persons[i].试剂二名称 !== "" && persons[i].试剂二名称 !== null && persons[i].试剂二名称 !== "无") {
                resgent.name = persons[i].试剂二名称;
                resgent.number = persons[i].试剂二批号;
                resgent.validity = persons[i].试剂二有效期;
                reagents = _.concat(reagents, resgent)
            }
            obj.reagents = reagents;
            var projects = [];
            var project = {};
            project.name = "新型冠状病毒核酸检测";
            project.subName = "2019-nCoV";
            project.result = persons[i].结果;
            project.hint = persons[i].提示;
            project.ref = persons[i].参考值
            project.method = persons[i].方法学;
            projects = _.concat(projects, project)
            obj.projects = projects
            obj.checkUser = persons[i].检验者;
            obj.verificationUser = persons[i].核对者;
            obj.signUser = persons[i].签发者;
            obj.sampleDate = persons[i].采样日期;
            obj.checkDate = persons[i].报告日期;
            arr = _.concat(arr, obj);

        }
        // var that = this;
        this.props.excelGeneratePdf(arr).then(res => {
            // if(res.data.code===200){
            if (res.data.code === 200) {
                window.open(res.data.data.complete, "_blank");
            }
            else {
                message.error(res.data.message)
            }
            // }
        })
    }
    getInfoByIdCard = (UUserCard, num) => {
        if (num === 1) {
            //获取出生日期
            return UUserCard.substring(6, 10) + "-" + UUserCard.substring(10, 12) + "-" + UUserCard.substring(12, 14);
        }
        if (num === 2) {
            //获取性别
            if (parseInt(UUserCard.substr(16, 1)) % 2 === 1) {
                //男
                return 1;
            } else {
                //女
                return 2;
            }
        }
        if (num === 3) {
            //获取年龄
            var myDate = new Date();
            var month = myDate.getMonth() + 1;
            var day = myDate.getDate();
            var age = myDate.getFullYear() - UUserCard.substring(6, 10) - 1;
            if (UUserCard.substring(10, 12) < month || (UUserCard.substring(10, 12) === month && UUserCard.substring(12, 14) <= day)) {
                age++;
            }
            return age;
        }
    }
    /**
     * 数据迁移
     * @param {} persons 
     * @param {*} i 
     */
    askrequest4 = (persons, i) => {
        if (i >= persons.length) {
            return;
        }
        var that = this;
        var idcard = persons[i].身份证号;
        if (idcard === undefined) {
            idcard = persons[i].身份证;
        }
        if (idcard === undefined) {
            idcard = persons[i].证件号;
        }
        if (idcard === undefined) {
            idcard = persons[i].证件号码;
        }
        if (idcard === undefined) {
            message.error("身份证号列为空")
        }
        var barcode = persons[i].条码号;
        if (barcode === undefined) {
            barcode = persons[i].条码;
        }
        if (barcode === undefined) {
            message.error("身份证号列为空")
        }
        var formdata = new FormData();
        formdata.append("barcode", barcode)
        formdata.append("idCard", idcard)
        formdata.append("newOrganizationId", this.state.organizationId)
        var cont = ""
        this.props.transferPhysicalData(formdata).then(res => {
            cont = "";
            if (res.data.code === 200) {
                message.success("更新成功");
                cont = idcard + "样本系统更新成功\n";

            }
            else {
                message.error(res.data.message);
                cont = idcard + "样本系统更新失败\n";
            }
            that.setState({
                contents2: _.concat(that.state.contents2, cont)
            }, () => {
                i++;
                that.askrequest4(persons, i);
            })
        })
    }
    /**
     * 更新手机号
     * @param {*} persons 
     * @param {*} i 
     * @returns 
     */
    askrequest3 = (persons, i) => {
        if (i >= persons.length) {
            return;
        }
        var that = this;
        var idcard = persons[i].身份证号;
        var phone = persons[i].联系方式;
        if (idcard === undefined) {
            idcard = persons[i].证件号码;
        }
        if (idcard === undefined) {
            idcard = persons[i].证件号;
        }
        if (phone === undefined) {
            phone = persons[i].电话;
        }
        if (phone === undefined) {
            phone = persons[i].手机号;
        }
        if (phone === undefined) {
            phone = persons[i].手机号码;
        }
        if (phone === undefined) {
            phone = persons[i].电话号码;
        }
        if (phone === undefined) {
            phone = persons[i].联系电话;
        }
        if (phone === undefined) {
            phone = persons[i].号码;
        }
        if (phone === undefined) {
            phone = "";
        }
        var workUnit = persons[i].单位;
        if (workUnit === undefined) {
            workUnit = persons[i].工作单位;
        }
        if (workUnit === undefined) {
            workUnit = "";
        }
        var cont = ""
        this.props.getDetail({
            idCard: idcard
        }).then(res => {
            cont = "";
            if (res.data.code === 200) {
                if (res.data.data) {
                    // 更新电话
                    var updateobj = {
                        id: res.data.data.id,
                        recordId: res.data.data.recordId,
                        phone: phone,
                        workUnit: workUnit
                    }
                    that.props.guahaoUpdate(updateobj).then(res => {
                        console.log(res)
                        if (res.data.code === 200) {
                            message.success("更新成功");
                            cont = idcard + "样本系统更新成功\n";

                        }
                        else {
                            message.error(res.data.message);
                            cont = idcard + "样本系统更新失败\n";
                        }
                    })

                }
                else {
                    cont = idcard + "样本系统无数据\n";
                }
            }
            else {
                cont = idcard + "操作失败\n";
            }
            that.setState({
                contents2: _.concat(that.state.contents2, cont)
            }, () => {
                i++;
                that.askrequest3(persons, i);
            })

        })
    }
    getNewBarcode = (idcard) => {
        var { physicalTypeValue2 } = this.state;
        var that = this;
        return async (resolve) => {
            await that.props.getBarcode({ physicalType: physicalTypeValue2, idCard: idcard }).then(res => {
                if (res.data.code === 200) {
                    resolve(res.data.message)
                }
                else {
                    resolve("")
                }
            })
        }

    }
    askrequest6 = (persons, i) => {
        var cont = ""
        if (i >= persons.length) {
            return;
        }
        var person = persons[i];
        var that = this
        var obj = {}
        obj.itemsName = person.物品名称;
        obj.unit = person.采样单位;
        obj.locations = person.采样地点;
        obj.contactName = person.负责人;
        obj.contactPhone = person.负责人电话;
        obj.latLng = "无"
        obj.physicalType = "12";
        var createdAt = person.体检日期;
        if (createdAt === undefined) {
            createdAt = person.采样日期
        }
        if (createdAt !== undefined) {
            createdAt = createdAt + " 00:00:00";
            obj.createdAt = createdAt;
        }

        obj.barcode = person.条码号;
        if (obj.barcode === undefined) {
            obj.barcode = moment().valueOf() / 1000;
        }
        that.props.itemRegistration(obj).then(msg => {
            cont = person.物品名称;
            cont = cont + msg.data.message + "\n"
            i++;
            that.setState({
                contents2: _.concat(that.state.contents2, cont)
            }, () => {

                setTimeout(() => {
                    that.askrequest6(persons, i)
                }, 1000);
            })
        })
        //         1628728548033
        // SystemDocking.js:393 1628728549034
        // SystemDocking.js:393 1628728550047
    }


    askrequest2 = (persons, i) => {
        var { physicalTypeValue2 } = this.state
        console.log(persons, i)
        if (i >= persons.length) {
            return;
        }

        var that = this;
        var person = persons[i]
      
        // let time = new Date((person. - 1) * 24 * 3600000 + 1)
        if (person.体检日期 === undefined && person.采样日期 === undefined&& person.登记时间 === undefined&& person.登记日期 === undefined) {
            message.error("缺少体检日期列");
            return;
        }

        var idcard = person.身份证号;
        if (idcard === undefined) {
            idcard = person.证件号;
        }
        if (idcard === undefined) {
            idcard = person.身份证;
        }
        idcard = idcard.replace("'", "").replace("‘", "");
        var barcode = person.体检编码;
        if (barcode === undefined) {
            barcode = person.个人体检编号;
        }
        if (barcode === undefined) {
            barcode = person.体检编号;
        }
        if (barcode === undefined) {
            barcode = person.体检号;
        }
        if (barcode === undefined) {
            barcode = person.条码号;
        }
        if (barcode === undefined) {
            barcode = person.条码;
        }
        var createdAt = person.体检日期;
        if (createdAt === undefined) {
            createdAt = person.采样日期;
        }
        if (createdAt === undefined) {
            createdAt = person.登记时间;
        }
        if (createdAt === undefined) {
            createdAt = person.登记日期;
        }
        var cont = ""

        if (barcode === undefined) {
            if (person.个人体检编号 === undefined) {
                that.props.getBarcode({ physicalType: physicalTypeValue2, idCard: idcard, time: createdAt }).then(res => {
                    if (res.data.code === 200) {
                        barcode = res.data.message


                        var timestamp = Date.parse(person.体检日期.replace(/-/g, '/'));
                        var address = person.户籍地址;
                        if (address === undefined) {
                            address = person.户籍住址;
                        }if (address === undefined) {
                            address = person.地址;
                        }
                        var uname = person.姓名;
                        if (uname === undefined) {
                            uname = person.患者姓名;
                        }  
                        if (uname === undefined) {
                            uname = person.用户名;
                        }
                        var way = 2;
                        var physicalType = physicalTypeValue2;
                        var phone = person.联系电话;
                        if (phone === undefined) {
                            phone = person.电话;
                        } if (phone === undefined) {
                            phone = person.号码;
                        }

                        var formdata = new FormData();
                        if (idcard === undefined) {
                            message.error("缺少身份证号列");
                            return;
                        }
                        formdata.append("idcard", idcard)
                        if (barcode === undefined) {
                            message.error("缺少体检编号列");
                            return;
                        }
                        formdata.append("barcode", barcode)
                        formdata.append("timestamp", timestamp)
                        if (address !== undefined)
                            formdata.append("address", address)

                        if (uname === undefined) {
                            message.error("缺少姓名列");
                            return;
                        }
                        formdata.append("uname", uname)
                        formdata.append("way", way)
                        formdata.append("physicalType", physicalType)
                        if (phone !== undefined)
                            formdata.append("phone", phone)
                        if (idcard.length !== 18) {
                            formdata.append('cardType', 0);
                            formdata.append('sex', 1)
                            formdata.append('age', "99")
                            formdata.append('birthday', "19900101")
                        }
                        else {
                            formdata.append('cardType', 1);
                        }

                        //    if (person.采集点 !== undefined)
                        //        formdata.append('collection', person.采集点);
                        if (person.机器套餐 !== undefined)
                            formdata.append('orgname', person.机器套餐);
                        var workUnit = "";
                        if (person.单位 !== undefined) {
                            workUnit = person.单位;
                        }
                        if (person.房间号 !== undefined) {
                            workUnit = workUnit + person.房间号;
                        }
                        //    formdata.append("workUnit",workUnit);
                        formdata.append('collection', workUnit);




                        that.props.addGuahao(formdata).then(res => {
                            console.log(res)

                            if (res.data.code === 200) {

                                cont = person.姓名 + "," + idcard;
                                cont = cont + "登记成功\n"
                                i++;
                                that.setState({
                                    contents2: _.concat(that.state.contents2, cont)
                                }, () => {
                                    that.askrequest2(persons, i);
                                })
                            }
                            else {
                                if (res.data.message === "证件格式错误") {
                                    formdata.set("cardType", 0);
                                    //    var sex = this.getInfoByIdCard(idcard, 2)
                                    formdata.append('sex', this.getInfoByIdCard(idcard, 2))
                                    formdata.append('age', this.getInfoByIdCard(idcard, 3))
                                    formdata.append('birthday', this.getInfoByIdCard(idcard, 1))
                                    that.props.addGuahao(formdata).then(res1 => {
                                        console.log(res1)
                                        if (res1.data.code === 200) {

                                            cont = person.姓名 + "," + idcard;
                                            cont = cont + "登记成功\n"
                                            i++;
                                            that.setState({
                                                contents2: _.concat(that.state.contents2, cont)
                                            }, () => {
                                                that.askrequest2(persons, i);
                                            })
                                        }
                                        else {

                                            cont = person.姓名 + "," + idcard;
                                            cont = cont + res.data.message + "\n"
                                            i++;
                                            that.setState({
                                                contents2: _.concat(that.state.contents2, cont)
                                            }, () => {
                                                that.askrequest2(persons, i);
                                            })
                                        }
                                    })
                                }
                                else {
                                    cont = person.姓名 + "," + idcard;
                                    cont = cont + res.data.message + "\n"
                                    i++;
                                    that.setState({
                                        contents2: _.concat(that.state.contents2, cont)
                                    }, () => {
                                        that.askrequest2(persons, i);
                                    })
                                }
                            }
                        })
                    }
                })

            }

        }
        else {
            var timestamp = Date.parse(createdAt.replace(/-/g, '/'));
            var address = person.户籍地址;
            if (address === undefined) {
                address = person.户籍住址;
            }if (address === undefined) {
                address = person.地址;
            }
            var uname = person.姓名;
            if (uname === undefined) {
                uname = person.患者姓名;
            }
            if (uname === undefined) {
                uname = person.用户名;
            }
            var way = 2;
            var physicalType = physicalTypeValue2;
            var phone = person.联系电话;
            if (phone === undefined) {
                phone = person.电话;
            }
            if (phone === undefined) {
                phone = person.号码;
            }
            var formdata = new FormData();
            if (idcard === undefined) {
                message.error("缺少身份证号列");
                return;
            }
            formdata.append("idcard", idcard)
            if (barcode === undefined) {
                message.error("缺少体检编号列");
                return;
            }
            formdata.append("barcode", barcode)
            formdata.append("timestamp", timestamp)
            if (address !== undefined)
                formdata.append("address", address)

            if (uname === undefined) {
                message.error("缺少姓名列");
                return;
            }
            formdata.append("uname", uname)
            formdata.append("way", way)
            formdata.append("physicalType", physicalType)
            if (phone !== undefined)
                formdata.append("phone", phone)
            if (idcard.length !== 18) {
                formdata.append('cardType', 0);
                formdata.append('sex', 1)
                formdata.append('age', "99")
                formdata.append('birthday', "19900101")
            }
            else {
                formdata.append('cardType', 1);
            }

            if (person.采集点 !== undefined)
                formdata.append('collection', person.采集点);
            if (person.机器套餐 !== undefined)
                formdata.append('orgname', person.机器套餐);
            var workUnit = "";
            if (person.单位 !== undefined) {
                workUnit = person.单位;
            }
            if (person.房间号 !== undefined) {
                workUnit = workUnit + person.房间号;
            }
            formdata.append("workUnit", workUnit);
            that.props.addGuahao(formdata).then(res => {
                console.log(res)
                if (res.data.code === 200) {

                    cont = uname + "," + idcard;
                    cont = cont + "登记成功\n"
                    i++;
                    that.setState({
                        contents2: _.concat(that.state.contents2, cont)
                    }, () => {
                        that.askrequest2(persons, i);
                    })
                }
                else {
                    if (res.data.message === "证件格式错误") {
                        formdata.set("cardType", 0);
                        // var sex = this.getInfoByIdCard(idcard, 2)
                        formdata.append('sex', this.getInfoByIdCard(idcard, 2))
                        formdata.append('age', this.getInfoByIdCard(idcard, 3))
                        formdata.append('birthday', this.getInfoByIdCard(idcard, 1))
                        that.props.addGuahao(formdata).then(res1 => {
                            console.log(res1)
                            if (res1.data.code === 200) {

                                cont = uname + "," + idcard;
                                cont = cont + "登记成功\n"
                                i++;
                                that.setState({
                                    contents2: _.concat(that.state.contents2, cont)
                                }, () => {
                                    that.askrequest2(persons, i);
                                })
                            }
                            else {

                                cont = uname + "," + idcard;
                                cont = cont + res.data.message + "\n"
                                i++;
                                that.setState({
                                    contents2: _.concat(that.state.contents2, cont)
                                }, () => {
                                    that.askrequest2(persons, i);
                                })
                            }
                        })
                    }
                    else {
                        cont = person.姓名 + "," + idcard;
                        cont = cont + res.data.message + "\n"
                        i++;
                        that.setState({
                            contents2: _.concat(that.state.contents2, cont)
                        }, () => {
                            that.askrequest2(persons, i);
                        })
                    }
                }
            })

        }

    }
    getInfoByIdCard = (UUserCard, num) => {
        if (num === 1) {
            //获取出生日期
            return UUserCard.substring(6, 10) + "-" + UUserCard.substring(10, 12) + "-" + UUserCard.substring(12, 14);
        }
        if (num === 2) {
            //获取性别
            if (parseInt(UUserCard.substr(16, 1)) % 2 === 1) {
                //男
                return 1;
            } else {
                //女
                return 2;
            }
        }
        if (num === 3) {
            //获取年龄
            var myDate = new Date();
            var month = myDate.getMonth() + 1;
            var day = myDate.getDate();
            var age = myDate.getFullYear() - UUserCard.substring(6, 10) - 1;
            if (UUserCard.substring(10, 12) < month || (UUserCard.substring(10, 12) === month && UUserCard.substring(12, 14) <= day)) {
                age++;
            }
            return age;
        }
    }
    importdata = (e) => {
        var files = e.target.files;
        var that = this;
        var fileReader = new FileReader();
        fileReader.onload = function (ev) {
            try {
                var data = ev.target.result,
                    workbook = XLSX.read(data, { type: 'binary' }),
                    persons = []; // 存储获取到的数据
            } catch (e) {
                console.log('文件类型不正确');
                return;
            }

            // 遍历每张表读取
            for (var sheet in workbook.Sheets) {
                if (workbook.Sheets.hasOwnProperty(sheet)) {
                    persons = persons.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]));
                    // break; // 如果只取第一张表，就取消注释这行
                }
            }
            that.askrequest(persons, 0)
        };
        // 以二进制方式打开文件
        fileReader.readAsBinaryString(files[0]);
    }

    askrequest = (persons, i) => {
        var cont = ""
        var { physicalTypeValue } = this.state
        if (i >= persons.length) {
            return;
        }
        var that = this;
        var person = persons[i]
        var idcard = person.身份证号;
        var barcode = person.条码;
        var createdAt = person.创建时间;

        that.props.oldSystemData({
            barcode: barcode, idcard: idcard,
            checkdate: moment(createdAt, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD")
        }).then(res => {
            if (res.data.code === "200") {
                var data = res.data;
                var createtime = moment(data.createtime, "YYYY-MM-DD HH:mm:ss").unix() * 1000;
                var formdata = new FormData();
                formdata.append("timestamp", createtime)
                formdata.append("idcard", data.idcard);
                formdata.append("barcode", data.barcode);
                if (data.uaddress !== undefined) {

                    formdata.append("address", data.uaddress);
                }
                if (data.photo !== "") {
                    formdata.append("pic", data.photo);
                }
                formdata.append("uname", data.name);
                formdata.append("physicalType", physicalTypeValue);
                formdata.append("workUnit", data.unitname);
                formdata.append("phone", data.phone);

                if (data.acquiringway === "0") {
                    formdata.append("way", 2);
                }
                else if (data.acquiringway === "1") {
                    formdata.append("way", 2);
                    formdata.append("group", data.group)
                    formdata.append("orgname", data.orgname)
                }

                this.props.addGuahao(formdata).then(res => {
                    i++;
                    cont = person.姓名 + "," + idcard;
                    cont = cont + "导入成功\n"

                    that.setState({
                        contents: _.concat(that.state.contents, cont)
                    }, () => {

                        that.askrequest(persons, i);
                    })
                })
            }
        })
    }

    customRequest = (option, mark) => {
        var that = this;

        const formData = new FormData();
        formData.append('file', option.file);
        if (mark === 1) {
            that.props.inportBackUP(formData)
                .then(function (res) {
                    if (res.data.code === 200) {
                        message.success("备份文件成功！")
                    }
                    else {
                        message.error(res.data.message)
                    }
                    that.setState({ loading: false })
                });
        }
        else {
            that.props.exportParseExcel(formData).then(res => {
                const fileName = "export" + moment().format("YYYYMMDDHHmmss");
                if ('download' in document.createElement('a')) { // 非IE下载
                    const url = window.URL.createObjectURL(new Blob([res.data],
                        // 设置该文件的mime类型，这里对应的mime类型对应为.xlsx格式                                                   
                        { type: 'application/vnd.ms-excel' }));
                    const link = document.createElement('a');
                    link.href = url;
                    // 从header中获取服务端命名的文件名
                    link.setAttribute('download', fileName);
                    document.body.appendChild(link);
                    link.click();
                }
                else {
                    // IE10+下载
                    navigator.msSaveBlob(res.data, fileName)
                }
            })
        }



    }
    fileUploadOnChange = (info) => {
        if (info.file.status === 'uploading') {
            this.setState({ loading: true });
        }
        else {
            this.setState({ loading: false });
        }
    }

    orgOnChange = value => {
        this.setState({ organizationId: value })
    }
    onExportHsExcel=()=>{
        var {startTime,endTime,physicalTypeValue3}=this.state;
        console.log(startTime,endTime,physicalTypeValue3)
        var obj={
            startTime:startTime,
            endTime:endTime,
            physicalType:physicalTypeValue3,
        }
        message.loading('导出中...',0)
        this.props.exportHsExcel(obj).then(res => {
            message.destroy()
            const fileName = "export" + moment().format("YYYYMMDDHHmmss");
            if ('download' in document.createElement('a')) { // 非IE下载
                const url = window.URL.createObjectURL(new Blob([res.data],
                    // 设置该文件的mime类型，这里对应的mime类型对应为.xlsx格式                                                   
                    { type: 'application/vnd.ms-excel' }));
                const link = document.createElement('a');
                link.href = url;
                // 从header中获取服务端命名的文件名
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
            }
            else {
                // IE10+下载
                navigator.msSaveBlob(res.data, fileName)
            }

        })
    }
    datetimeChange = (date, dateString) => {
        // var choseDate =[];
        // choseDate = _.concat(choseDate,moment(dateString[0]+" 00:00:00","YYYY-MM-DD hh:mm:ss"));
        // choseDate = _.concat(choseDate,moment(dateString[1]+" 23:59:59","YYYY-MM-DD hh:mm:ss"));
        this.setState({
            startTime: dateString[0] === "" ? undefined : dateString[0] + " 00:00:00",
            endTime: dateString[1] === "" ? undefined : dateString[1] + " 23:59:59",
            choosedDates: date,
        })
    }

 

    render() {
        const { ptypes, loading, contents2, organiations, organizationId ,choosedDates} = this.state;
        return (
            <div>
                <div className="StatisticsPage-head pageHeader"><h3>系统对接</h3></div>
                <div className="StatisticsPage-box">
                    <div>备份文件上传:
                        <Upload showUploadList={false} customRequest={(option) => this.customRequest(option, 1)}
                            onChange={this.fileUploadOnChange} >
                            <Tooltip placement="right" >
                                {loading ? <Icon type='loading' /> : <Button><Icon />上传文件</Button>}

                            </Tooltip>
                        </Upload>
                    </div>
                </div>
                {/* <div className="StatisticsPage-box">
                    <div style={{ display: "inline" }}>旧系统引入:</div>
                    <Select style={{ width: 200, }} onChange={this.physicalTypeOnChange}>
                        {ptypes.map(type => {
                            return <Option key={"type" + type.id} value={type.id}>{type.physicalType}</Option>
                        })}
                    </Select>
                    <div style={{ display: "inline" }}><span>选择文件：</span><input type="file" id="excel-file4" onChange={this.importdata}></input></div>
                    {contents.map((content, index) => {
                        return <div key={"content" + index}>{content}</div>
                    })}
                </div> */}
                <div className="StatisticsPage-box">
                    <div style={{ display: "inline" }}>批量挂号登记:</div>
                    <Select style={{ width: 200, }} onChange={this.physicalTypeOnChange2}>
                        {ptypes.map(type => {
                            return <Option key={"type2" + type.id} value={type.id}>{type.physicalType}</Option>
                        })}
                    </Select>
                    <div style={{ display: "inline" }}>
                        <span>选择文件：</span>
                        <input type="file" id="excel-file4" onChange={(e) => this.registrationData(e, 1)}></input>
                    </div>
                </div>
                <div className="StatisticsPage-box">
                    <div style={{ display: "inline" }}>更新电话及工作单位:</div>
                    <div style={{ display: "inline" }}>
                        <span>选择文件：</span>
                        <input type="file" id="excel-file4" onChange={(e) => this.registrationData(e, 2)}></input>
                    </div>
                </div>
                <div className="StatisticsPage-box">
                    <div style={{ display: "inline" }}>数据迁移:</div>
                    <Select value={organizationId} onSearch style={{ width: 200, }} onChange={this.orgOnChange}>
                        {organiations.map(type => {
                            return <Option key={"organ" + type.organizationId} value={type.organizationId}>{type.organizationName}</Option>
                        })}
                    </Select>
                    <div style={{ display: "inline" }}>
                        <span>选择文件：</span>
                        <input type="file" id="excel-file4" onChange={(e) => this.registrationData(e, 3)}></input>
                    </div>
                </div>
                <div className="StatisticsPage-box">
                    <div style={{ display: "inline" }}>物品采样登记:</div>

                    <div style={{ display: "inline" }}>
                        <span>选择文件：</span>
                        <input type="file" id="excel-file7" onChange={(e) => this.registrationData(e, 5)}></input>
                    </div>
                </div>
                <div className="StatisticsPage-box">
                    <div style={{ display: "inline" }}>EXCEL生成报告:</div>

                    <div style={{ display: "inline" }}>
                        <span>选择文件：</span>
                        <input type="file" id="excel-file4" onChange={(e) => this.registrationData(e, 4)}></input>
                    </div>
                </div>
                <div className="StatisticsPage-box">
                    <div>备份文件转Excel:
                        <Upload showUploadList={false} customRequest={(option) => this.customRequest(option, 2)}
                            onChange={this.fileUploadOnChange} >
                            <Tooltip placement="right" >
                                {loading ? <Icon type='loading' /> : <Button><Icon />上传文件</Button>}

                            </Tooltip>
                        </Upload>
                    </div>
                </div>
                <div className="StatisticsPage-box">
                    <div>导出华晟Excel:
                        <Select style={{ width: 200, }} onChange={this.physicalTypeOnChange3} value={this.state.physicalTypeValue3}>
                            {ptypes.map(type => {
                                return <Option key={"type2" + type.id} value={type.id}>{type.physicalType}</Option>
                            })}
                        </Select>
                        <RangePicker dateFormat={dateFormat} placeholder={['开始时间', '结束时间']} className="date2"
                                onChange={this.datetimeChange} value={choosedDates} />
                         <Button onClick={this.onExportHsExcel}>导出</Button>
                    </div>
                </div>
                <div className="StatisticsPage-box">
                    <div style={{ display: "inline" }}>筛选是否符合两癌条件:</div>

                    <div style={{ display: "inline" }}>
                        <span>选择文件：</span>
                        <input type="file" id="excel-file4" onChange={(e) => this.registrationData(e, 6)}></input>
                    </div>
                </div>
             
                <div className="StatisticsPage-box">
                    <div style={{ display: "inline" }}>  导入顶山名单:</div>
                  
                    <div style={{ display: "inline" }}>
                        <span>选择文件：</span>
                        <input type="file" id="excel-file4" onChange={(e) => this.registrationData(e, 7)}></input>
                    </div>
                </div>

                {contents2.map((content, index) => {
                    return <div key={"contents2" + index}>{content}</div>
                })}
            </div>
        )
    }
}
export default connect(null, {
    addGuahao, oldSystemData, getPhysicalTypeList, inportBackUP, getDetail, guahaoUpdate, getAllorganization, getBarcode,fubaosuogetData,
    transferPhysicalData, excelGeneratePdf, itemRegistration, exportParseExcel, exportHsExcel,getTwoCancerToken,getTwoCancerRecord,getDcmInfolIST,dcmUpdate
})(SystemDocking)