import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  Modal,
  Button,
  Table,
  Input,
  message,
  Divider,
  Tabs,
  Select,
  DatePicker,
} from 'antd'
import './DCM.css'
import moment from 'moment'

import { ecgInfo, exportEcg, deleteEcg } from '../../../actions/account'
const dateFormat = 'YYYYMMDD'
const { confirm } = Modal
const { RangePicker } = DatePicker
const { TabPane } = Tabs
const { Column } = Table
const { TextArea } = Input
const { Option } = Select
class electrocardiogramPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      applyNo: '',

      size: 10,
      dataList: [],
      page: 1,
      total: 0,
      title: '',
      subTitle: '',
      currId: undefined,
      type: '',
      startTime: undefined,
      endTime: undefined,
      choosedDates: [],
      selectedRowKeys: [],
    }
  }

  componentDidMount() {
    this.loadDatas()
  }

  loadDatas = () => {
    var { page, size, applyNo, startTime, endTime } = this.state
    var that = this
    var obj = {
      page: page - 1,
      size: size,
      applyNo: applyNo,
    }
    if (startTime !== undefined) {
      obj.startTime = moment(startTime, 'YYYY-MM-DD HH:mm:ss').format(
        'YYYY-MM-DD'
      )
      obj.startTime = obj.startTime + ' 00:00:00'
    }
    if (endTime !== undefined) {
      obj.endTime = moment(endTime, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD')
      obj.endTime = obj.endTime + ' 23:59:59'
    }
    this.props.ecgInfo(obj).then((res) => {
      if (res.data.code === 200) {
        that.setState({
          dataList: res.data.data.rows,
          total: res.data.data.total,
        })
      }
    })
  }

  keyWordOnChange = (e) => {
    this.setState({ applyNo: e.target.value })
  }
  typeChange = (e) => {
    console.log(e)
    this.setState({ type: e })
  }

  changePage = (page) => {
    console.log(page)
    var that = this
    this.setState({ page }, () => {
      that.loadDatas()
    })
  }
  pageSizeOnChange = (current, size) => {
    console.log(size)
    var that = this
    this.setState({ size: size })
  }
  datetimeChange = (date, dateString) => {
    this.setState({
      startTime: dateString[0] === '' ? undefined : dateString[0] + ' 00:00:00',
      endTime: dateString[1] === '' ? undefined : dateString[1] + ' 23:59:59',
      choosedDates: date,
    })
  }
  onSelectChange = (selectedRowKeys) => {
    // var { dataList } = this.state;
    this.setState({ selectedRowKeys })
  }
  exportelectrocardiogramPage = () => {
    var { startTime, endTime } = this.state

    if (!!!startTime || !!!endTime) {
      message.error('请先选择时间')
      return
    }
    var obj = {}
    if (startTime !== undefined) {
      obj.startTime = moment(startTime, 'YYYY-MM-DD HH:mm:ss').format(
        'YYYY-MM-DD'
      )
      obj.startTime = obj.startTime + ' 00:00:00'
    }
    if (endTime !== undefined) {
      obj.endTime = moment(endTime, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD')
      obj.endTime = obj.endTime + ' 23:59:59'
    }

    var that = this
    that.props.exportEcg(obj).then((res) => {
      const fileName = 'export' + moment().format('YYYYMMDDHHmmss')
      if ('download' in document.createElement('a')) {
        // 非IE下载
        const url = window.URL.createObjectURL(
          new Blob(
            [res.data],
            // 设置该文件的mime类型，这里对应的mime类型对应为.xlsx格式
            { type: 'application/vnd.ms-excel' }
          )
        )
        const link = document.createElement('a')
        link.href = url
        // 从header中获取服务端命名的文件名
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
      } else {
        // IE10+下载
        navigator.msSaveBlob(res.data, fileName)
      }
    })
  }
  delItem = (id) => {
    var { dataList } = this.state
    var that = this
    // if (selectedRowInfoKeys.length === 0) {
    //     message.error("请选择删除的记录")
    //     return;
    // }
    // var that = this;
    // var barcode = ( _.find(dataList, ['id', id])).barcode;
    // console.log(barcode)
    confirm({
      content: '确认删除？',
      onOk() {
        that.props.deleteEcg({ ids: id }).then((msg) => {
          if (msg.data.code === 200) {
            message.success('删除成功！')
            that.loadDatas()
          }
        })
      },
    })
  }
  serchBtn = () => {
    this.setState({ page: 1 }, () => {
      this.loadDatas()
    })
  }
  render() {
    const { dataList, applyNo, choosedDates, selectedRowKeys } = this.state
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    }
    return (
      <div>
        <div className="StatisticsPage-head pageHeader">
          <h3>心电图列表</h3>
        </div>
        <div className="StatisticsPage-box">
          <div className="StatisticsPage-search">
            <div className="StatisticsPage-btn">
              <RangePicker
                dateFormat={dateFormat}
                placeholder={['开始时间', '结束时间']}
                className="date"
                onChange={this.datetimeChange}
                value={choosedDates}
              />
              <Input
                className="ipt"
                placeholder="请输入条码号"
                value={applyNo}
                style={{ width: 100 }}
                onChange={this.keyWordOnChange}
              />

              <Button className="btn" type="primary" onClick={this.serchBtn}>
                搜索
              </Button>
              <Button
                className="btn"
                type="primary"
                onClick={this.exportelectrocardiogramPage}
              >
                导出
              </Button>
            </div>
          </div>
          <div>
            <Table
              size="middle"
              dataSource={dataList}
              bordered
              rowKey="id"
              pagination={{
                pageSize: this.state.size,
                showTotal: (total) => {
                  return `共 ${total} 条`
                },
                onChange: this.changePage,
                total: this.state.total,
                showSizeChanger: true,
                onShowSizeChange: this.pageSizeOnChange,
              }}
              rowSelection={rowSelection}
            >
              <Column
                title="条码号"
                dataIndex="applyNo"
                key="applyNo"
                align="center"
              />
              <Column
                title="患者姓名"
                dataIndex="name"
                key="name"
                align="center"
              />
              <Column
                title="身份证号"
                dataIndex="idCard"
                key="idCard"
                align="center"
              />
              <Column
                title="病历诊断时间"
                dataIndex="diagnoseTime"
                key="diagnoseTime"
                align="center"
              />
              <Column
                title="结论"
                dataIndex="advice"
                key="advice"
                align="center"
              />

              <Column
                title="操作"
                align="center"
                key="action"
                render={(text, record) => (
                  <span>
                    <span
                      style={{ cursor: 'pointer' }}
                      onClick={() => this.delItem(record.id)}
                    >
                      删除
                    </span>
                  </span>
                )}
              />
            </Table>
          </div>
        </div>
      </div>
    )
  }
}
export default connect(null, {
  ecgInfo,
  exportEcg,
  deleteEcg,
})(electrocardiogramPage)
