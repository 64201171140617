import React, { Component } from 'react'
import MD5 from 'crypto-js/md5'
import {
  Modal,
  Button,
  Table,
  Input,
  message,
  Radio,
  Spin,
  Divider,
  Select,
} from 'antd'
import '../medicalRecord/RegistrationPage.css'
import {
  addOrganization,
  getAllorganization,
  getUsableMachine,
  resetPwd,
  organizationDelete,
} from '../../../actions/account'

// import _ from 'lodash'
import { connect } from 'react-redux'
const { Column } = Table
const { confirm } = Modal
const { Option } = Select

class MachineManage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      spinning: false,
      isAdmin: 0,
      organizationName: '',
      account: '',
      password: '',
      size: 10,
      dataList: [],
      organizationList: [],
      curRecord: null,
      visible: false,
      page: 1,
      total: 0,
      isRequesting: false,
      bindVisble: false,
      barcode: '0000',
      password2: '',
      password3: '',
      organizationId: '',
      parentId: undefined,
    }
  }

  componentDidMount() {
    this.loadDatas()
    var that = this
    this.props.getAllorganization().then((msg) => {
      if (msg.data.code === 200) {
        that.setState(
          {
            organizationList: msg.data.data,
          },
          () => {
            that.loadDatas()
          }
        )
      }
    })
  }

  loadDatas = () => {
    this.setState({ spinning: true })

    var { page, size } = this.state
    var obj = { page: page - 1, size: size }
    var that = this
    that.props.getAllorganization(obj).then((msg) => {
      console.log(msg)
      if (msg.data.code === 200) {
        that.setState({
          dataList: msg.data.data,
          spinning: false,
        })
        // if (msg.data.data.rows.length === 0 && msg.data.data.total !== 0) {
        //     that.setState({ page: page - 1 }, () => {
        //         that.loadDatas()
        //     })
        // }
        // else {
        //     that.setState({
        //         dataList: msg.data.data.rows,
        //         total: msg.data.data.total,
        //         spinning:false
        //     })
        // }
      }
    })
  }

  showModal = () => {
    this.setState({
      curRecord: undefined,
      visible: true,
    })
  }

  handleCancel = () => {
    this.setState({
      visible: false,
      organizationName: '',
      account: '',
      password: '',
      barcode: '0000',
    })
  }

  organizationNameChange = (e) => {
    this.setState({
      organizationName: e.target.value,
    })
  }
  accountChange = (e) => {
    this.setState({
      account: e.target.value,
    })
  }
  passwordChange = (e) => {
    this.setState({
      password: e.target.value,
    })
  }
  password2Change = (e) => {
    this.setState({
      password2: e.target.value,
    })
  }
  password3Change = (e) => {
    this.setState({
      password3: e.target.value,
    })
  }
  barcodeChange = (e) => {
    this.setState({
      barcode: e.target.value,
    })
  }
  organizationIdChange = (e) => {
    this.setState({
      organizationId: e.target.value,
    })
  }
  handleOk = () => {
    var {
      organizationName,
      account,
      password,
      isRequesting,
      barcode,
      isAdmin,
      organizationId,
      parentId,
    } = this.state
    if (isRequesting) {
      return
    }
    var obj = {
      organizationName,
      account,
      password: MD5(password).toString(),
      code: barcode,
      isAdmin: isAdmin,
    }
    if (
      organizationId !== '' &&
      organizationId !== null &&
      organizationId !== undefined
    ) {
      obj.organizationId = organizationId
    }
    if (parentId !== '' && parentId !== null && parentId !== undefined) {
      obj.parentId = parentId
    }
    var that = this
    this.setState({ isRequesting: true }, () => {
      that.props.addOrganization(obj).then((msg) => {
        console.log(msg)
        if (msg.data.code === 200) {
          that.setState({
            visible: false,
            organizationName: '',
            account: '',
            password: '',
          })
          that.loadDatas()
        } else {
          message.error(msg.data.message)
        }
        that.setState({ isRequesting: false })
      })
    })
  }
  editItem = (record) => {
    console.log(record)
    this.setState({
      curRecord: record,
      bindVisble: true,
    })
  }

  delItem = (id) => {
    var that = this
    confirm({
      content: '确认删除？',
      onOk() {
        that.props.organizationDelete({ organizationId: id }).then((msg) => {
          if (msg.data.code === 200) {
            message.success('删除成功！')
            that.loadDatas()
          }
        })
      },
    })
  }
  bandMachine = (record) => {
    console.log(record)
    this.setState({
      curRecord: record,
      bindVisble: true,
    })
  }
  handleBindOk = () => {
    var { curRecord, password2, password3 } = this.state

    if (password2 !== password3) {
      message.error('两次密码输入不一致！')
      return
    }
    if (!!!password2 || !!!password3) {
      message.error('请输入密码或新密码！')
      return
    }
    var that = this
    that.props
      .resetPwd({
        account: curRecord.account,
        newPassword: MD5(password2).toString(),
      })
      .then((res) => {
        if (res.data.code === 200) {
          message.success('修改密码成功！')
          that.setState({
            bindVisble: false,
            curRecord: undefined,
          })
        }
      })
  }

  handleBindCancel = () => {
    this.setState({
      bindVisble: false,
      password3: '',
      password2: '',
      machineId: undefined,
      curRecord: undefined,
    })
  }

  onisAdminChange = (e) => {
    this.setState({
      isAdmin: e.target.value,
    })
  }
  changePage = (page) => {
    var that = this
    this.setState({ page }, () => {
      that.loadDatas()
    })
  }

  onOrganSelectChange = (value) => {
    this.setState({
      parentId: value,
    })
  }
  render() {
    const {
      spinning,
      parentId,
      isAdmin,
      barcode,
      dataList,
      organizationName,
      account,
      password,
      password2,
      password3,
      bindVisble,
      organizationList,
      organizationId,
    } = this.state
    return (
      <div>
        <div className="StatisticsPage-head pageHeader">
          <h3>机构管理</h3>
        </div>
        <div className="StatisticsPage-box">
          <div className="StatisticsPage-search">
            <div className="StatisticsPage-btn">
              <Button className="btn" type="primary" onClick={this.showModal}>
                添加
              </Button>
              <Modal
                title="添加机构"
                visible={this.state.visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                okText="保存"
                cancelText="取消"
              >
                <div className="form-group">
                  <label className="form-label">机构名称：</label>
                  <div className="form-ipt">
                    <Input
                      value={organizationName}
                      placeholder="请输入机构名称"
                      onChange={(e) => this.organizationNameChange(e)}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-label">账号：</label>
                  <div className="form-ipt">
                    <Input
                      value={account}
                      placeholder="请输入账号"
                      onChange={(e) => this.accountChange(e)}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-label">密码：</label>
                  <div className="form-ipt">
                    <Input.Password
                      value={password}
                      placeholder="请输入密码"
                      onChange={(e) => this.passwordChange(e)}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-label">Barcode前4位：</label>
                  <div className="form-ipt">
                    <Input
                      value={barcode}
                      placeholder="Barcode前4位"
                      onChange={(e) => this.barcodeChange(e)}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-label">机构编号：</label>
                  <div className="form-ipt">
                    <Input
                      value={organizationId}
                      placeholder="机构编号和父级ID填一项，12位"
                      onChange={(e) => this.organizationIdChange(e)}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-label">上级机构：</label>
                  <div className="form-ipt">
                    <Select
                      style={{ width: 300 }}
                      allowClear={true}
                      placeholder="请选择所属机构"
                      value={parentId}
                      onChange={this.onOrganSelectChange}
                    >
                      {organizationList.map((organ) => {
                        return (
                          <Option key={organ.organizationId}>
                            {organ.organizationName}
                          </Option>
                        )
                      })}
                    </Select>
                  </div>
                </div>
                {((bool) => {
                  if (bool) {
                    return (
                      <div className="form-group">
                        <label className="form-label">管理员：</label>
                        <div className="form-ipt">
                          <Radio.Group
                            onChange={this.onisAdminChange}
                            value={isAdmin}
                          >
                            <Radio value={1} key={1}>
                              是
                            </Radio>
                            <Radio value={0} key={0}>
                              否
                            </Radio>
                          </Radio.Group>
                        </div>
                      </div>
                    )
                  } else {
                    return
                  }
                })(JSON.parse(localStorage.getItem('data')).isAdmin)}
              </Modal>
            </div>
          </div>
          <div>
            <Spin tip="Loading..." spinning={spinning}>
              <Table
                size="middle"
                dataSource={dataList}
                bordered
                rowKey="organizationId"
                pagination={false}
                // pagination={{
                //                 pageSize: this.state.size, showTotal: ((total) => {
                //                     return `共 ${total} 条`;
                //                 }),
                //                 onChange: this.changePage, total: this.state.total, showSizeChanger: false
                //             }}
              >
                <Column
                  title="机构名称"
                  dataIndex="organizationName"
                  key="organizationName"
                  align="center"
                />
                <Column
                  title="账号"
                  dataIndex="account"
                  key="account"
                  align="center"
                />
                <Column
                  title="条码号标识"
                  dataIndex="code"
                  key="code"
                  align="center"
                />
                <Column
                  title="操作"
                  align="center"
                  key="action"
                  render={(text, record) => (
                    <span>
                      {/* <span style={{ cursor: 'pointer' }} onClick={() => this.bandMachine(record)}>绑定机器</span> */}
                      {/* <Divider type="vertical" /> */}
                      {/* <span style={{ cursor: 'pointer' }} onClick={() => this.bandMachine(record)}>绑定体检类型</span> */}

                      {/* <Divider type="vertical" />
                       */}
                      <span
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.editItem(record)}
                      >
                        修改密码
                      </span>
                      <Divider type="vertical" />
                      <span
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.delItem(record.organizationId)}
                      >
                        删除
                      </span>
                    </span>
                  )}
                />
              </Table>
            </Spin>
            <Modal
              title="修改机构密码"
              visible={bindVisble}
              onOk={this.handleBindOk}
              onCancel={this.handleBindCancel}
              okText="保存"
              cancelText="取消"
            >
              <div className="form-group">
                <label className="form-label">密码：</label>
                <div className="form-ipt">
                  <Input.Password
                    value={password2}
                    placeholder="请输入密码"
                    onChange={(e) => this.password2Change(e)}
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="form-label">再次输入密码：</label>
                <div className="form-ipt">
                  <Input.Password
                    value={password3}
                    placeholder="请输入密码"
                    onChange={(e) => this.password3Change(e)}
                  />
                </div>
              </div>
            </Modal>
          </div>
        </div>
      </div>
    )
  }
}
export default connect(null, {
  addOrganization,
  getAllorganization,
  getUsableMachine,
  resetPwd,
  organizationDelete,
})(MachineManage)
