import React, { Component } from 'react'
import { Modal, Button, Table, message, Spin, TimePicker, Divider, Select, Input, Radio, Checkbox } from 'antd'

import {
    appointmentParamRangeList, appointmentParamRangeSave, appointmentParamRangeUpdate, appointmentParamRangeRemove, appointmentParamList, appointmentParamSave
} from '../../../actions/account'
import _ from 'lodash'
// import _ from 'lodash'
import { connect } from 'react-redux'
import '../medicalRecord/RegistrationPage.css'
import moment from 'moment'
const { Column } = Table
const { confirm } = Modal
const { Option } = Select
class appointmentParam extends Component {
    constructor(props) {
        super(props)
        this.state = {
            spinning: false,
            week: '',
            startTime: '',
            endTime: '',
            id: '',
            bindphyVisble: false,
            bindphyVisble1: false,
            // 
            dataList2: [],
            page: 1,
            total: 0,
            size: 10,
            timeOrder: [null, null],
            curend: {},
            weekList: [],
            timearr: [{
                label: '周一',
                id: '1',

            }, {
                label: '周二',
                id: '2',

            }, {
                label: '周三',
                id: '3',

            }, {
                label: '周四',
                id: '4',

            }, {
                label: '周五',
                id: '5',

            }, {
                label: '周六',
                id: '6',

            }, {
                label: '周日',
                id: '7',
            },],
            timearr1: [],

        }
    }

    componentDidMount() {
        this.loadDatas()
        this.timeWeek()
        var that = this

    }
    onsexChange = (e) => {
        this.setState({
            status: e.target.value,
        })
    }
    timeWeek = () => {
        var { timearr, weekList, timearr1 } = this.state
        this.props.appointmentParamList({}).then((msg) => {
            console.log(msg)
            if (msg.data.code === 200) {
                if (msg.data.data.rows.length > 0) {
                    let arr = []
                    timearr.map(res => {
                        msg.data.data.rows[0].week.split(',').map(res1 => {
                            if (res.id == res1) {
                                arr.push(res)
                            }
                        })
                    })
                    this.setState({
                        weekList: msg.data.data.rows[0].week.split(','),
                        timearr1: arr
                    })
                }
            }
        })
    }
    loadDatas = () => {
        var { page, size } = this.state
        var obj = { page: page - 1, size: size }

        var that = this
        this.setState({ spinning: true })
        this.props.appointmentParamRangeList(obj).then((msg) => {
            console.log(msg)
            if (msg.data.code === 200) {
                that.setState({
                    dataList2: msg.data.data.rows,
                    total: msg.data.data.total,
                    spinning: false,
                })
            }
        })
    }

    editItem = (itm) => {
        var { weekList, timeOrder, curend, id, endTime, startTime } = this.state

        var that = this
        that.setState({
            // curend: JSON.parse(JSON.stringify(itm)),
            startTime: itm.startTime,
            endTime: itm.endTime,
            bindphyVisble1: true,
            id: itm.id,
            timeOrder: [moment(itm.startTime, 'HH:mm:ss'), moment(itm.endTime, 'HH:mm:ss')],
            week: itm.week
        })

    }

    bandphy = () => {
        var that = this
        that.setState({
            bindphyVisble: true,
        })
    }
    bandphy1 = () => {
        var that = this
        that.setState({
            bindphyVisble1: true,
            startTime: '',
            endTime: '',
            id: '',
            week: null,
            timeOrder: [null, null]
        })
    }
    handleBindphyOk = () => {
        var { weekList } = this.state
        var that = this
        that.props
            .appointmentParamSave({ week: weekList.join(',') })
            .then((msg) => {
                if (msg.data.code === 200) {
                    message.success('操作成功！')
                    that.timeWeek()
                    that.setState({
                        bindphyVisble: false,
                    })
                } else {
                    message.error(msg.data.message)
                }

            })

    }
    handleBindphyCancel = () => {
        this.setState({
            bindphyVisble: false
        })
    }

    handleBindphyOk1 = () => {
        var { week, timeOrder, curend, id, endTime, startTime } = this.state
        var that = this
        if (!timeOrder[0]) {
            message.error('请先选择时间段！')
        }
        console.log(timeOrder)
        let obj = {
            week: week,
            startTime: startTime,
            endTime: endTime,
        }
        if (!!id) {
            obj.id = id
            that.props
                .appointmentParamRangeUpdate(obj)
                .then((msg) => {
                    if (msg.data.code === 200) {
                        message.success('操作成功！')
                        that.loadDatas()
                        that.setState({
                            bindphyVisble1: false,
                        })
                    } else {
                        message.error(msg.data.message)
                    }

                })
        } else {
            that.props
                .appointmentParamRangeSave(obj)
                .then((msg) => {
                    if (msg.data.code === 200) {
                        message.success('操作成功！')
                        that.loadDatas()
                        that.setState({
                            bindphyVisble1: false,
                        })
                    } else {
                        message.error(msg.data.message)
                    }

                })
        }

    }


    handleBindphyCancel1 = () => {
        this.setState({
            bindphyVisble1: false,
            startTime: '',
            endTime: '',
            id: '',
            week: null,
            timeOrder: [null, null]
        })
    }


    delItem = (recorrd) => {
        var that = this
        confirm({
            content: '确认删除？',
            onOk() {
                var That = that
                that.props
                    .appointmentParamRangeRemove({ id: recorrd.id })
                    .then((msg) => {
                        if (msg.data.code === 200) {
                            message.success('删除成功！')

                            That.loadDatas()
                        }
                    })
            },
        })
    }
    changePage = (page) => {
        var that = this
        this.setState({ page }, () => {
            that.loadDatas()
        })
    }
    CheckBoxChange = (e) => {
        console.log(e)
        var { weekList } = this.state
        this.setState({
            weekList: e
        })
    }
    ondateDetail = (e, timeString) => {
        console.log(timeString)
        this.setState({
            startTime: timeString[0],
            endTime: timeString[1],
            timeOrder: [moment(timeString[0], 'HH:mm:ss'), moment(timeString[1], 'HH:mm:ss')]
        })

    }
    ondetailIdChange = (e) => {
        this.setState({
            week: e,

        })
    }
    render() {
        const {
            dataList2,

            selectedRowKeys,
            classifyName,
            spinning,
            endTime, week, startTime, timearr, timeOrder, timearr1,
            bindphyVisble,
            weekList, bindphyVisble1
        } = this.state
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        }

        return (
            <div>
                <div className="StatisticsPage-head pageHeader">
                    <h3>周期列表</h3>
                </div>

                <div className="StatisticsPage-box">
                    <div className="StatisticsPage-search">
                        <div className="StatisticsPage-btn">
                            <Button className="btn" type="primary" onClick={this.bandphy}>
                                添加周期
                            </Button>
                            <Button className="btn" type="primary" onClick={this.bandphy1}>
                                添加时间段
                            </Button>

                            <Modal
                                title="周期"
                                visible={bindphyVisble}
                                onOk={this.handleBindphyOk}
                                onCancel={this.handleBindphyCancel}
                                okText="保存"
                                cancelText="取消"
                            >
                                <div className="form-group">
                                    <label className="form-label">周期：</label>
                                    <div className="form-ipt" style={{ marginTop: '5px' }}>
                                        <Checkbox.Group
                                            value={weekList}
                                            onChange={e => this.CheckBoxChange(e)}
                                        >
                                            {timearr.map(res => (
                                                <Checkbox value={res.id}>{res.label}</Checkbox>
                                            ))}

                                        </Checkbox.Group>
                                    </div>
                                </div>
                            </Modal>
                            <Modal
                                title="时间段"
                                visible={bindphyVisble1}
                                onOk={this.handleBindphyOk1}
                                onCancel={this.handleBindphyCancel1}
                                okText="保存"
                                cancelText="取消"
                            >
                                <div className="form-group">
                                    <label className="form-label">周期：</label>
                                    <div className="form-ipt" style={{ marginTop: '5px' }}>
                                        <Select
                                            style={{ width: '100%' }}
                                            value={week}
                                            onChange={this.ondetailIdChange}
                                        >
                                            {timearr1.map((date, index) => {
                                                return (
                                                    <Option key={date.id} value={date.id}>
                                                        {date.label}
                                                    </Option>
                                                )
                                            })}
                                        </Select>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="form-label">时间段：</label>
                                    <div className="form-ipt" style={{ marginTop: '5px' }}>
                                        <TimePicker.RangePicker
                                            style={{ width: '100%' }}
                                            onChange={this.ondateDetail}
                                            value={
                                                timeOrder
                                            }
                                        />
                                    </div>
                                </div>
                            </Modal>
                        </div>
                    </div>
                    <div>
                        <Spin tip="Loading..." spinning={spinning}>
                            <Table
                                size="middle"
                                dataSource={dataList2}
                                bordered
                                rowKey="id"
                                pagination={{
                                    pageSize: this.state.size,
                                    showTotal: (total) => {
                                        return `共 ${total} 条`
                                    },
                                    onChange: this.changePage,
                                    total: this.state.total,
                                    showSizeChanger: false,
                                }}
                                rowSelection={rowSelection}

                            >
                                <Column
                                    title="周期"
                                    dataIndex="week"
                                    key="week"
                                    align="center"
                                />
                                <Column
                                    title="开始时间"
                                    dataIndex="startTime"
                                    key="startTime"
                                    align="center"
                                />
                                <Column
                                    title="结束时间"
                                    dataIndex="endTime"
                                    key="endTime"
                                    align="center"
                                />
                                <Column
                                    title="操作"
                                    align="center"
                                    key="action"
                                    render={(text, record) => (
                                        <span>
                                            <span
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => this.editItem(record)}
                                            >
                                                修改
                                            </span>
                                            <Divider type="vertical" />
                                            <span
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => this.delItem(record)}
                                            >
                                                删除
                                            </span>
                                        </span>
                                    )}
                                />
                            </Table>
                        </Spin>

                    </div>
                </div>
            </div>
        )
    }
}
export default connect(null, {
    appointmentParamRangeList, appointmentParamRangeSave, appointmentParamRangeUpdate, appointmentParamRangeRemove, appointmentParamList, appointmentParamSave
})(appointmentParam)
