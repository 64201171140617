import React, { Component } from 'react'
import { Modal, Button, Table, message, Select, Spin, Divider, InputNumber, Input, Upload, Icon, } from 'antd'

import {

    mealRemove, mealSave, mealList, newUploadFilePath, mealClassifyList
} from '../../../actions/account'
import _ from 'lodash'
// import _ from 'lodash'
import { connect } from 'react-redux'
import '../medicalRecord/RegistrationPage.css'
const { Column } = Table
const { confirm } = Modal
const { TextArea } = Input
const { Option } = Select
class mealListPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            spinning: false,
            mealName: '',
            mealPrice: '',
            id: '',
            originalPrice: '',
            remark: '',
            checkProject: '',
            bindphyVisble: false,
            // 
            dataList2: [],
            page: 1,
            total: 0,
            size: 10,
            name: '',
            fileList: [],
            loading: false,
            classifyList: [],
            classifyId: null,
            detailImageArr: []
        }
    }

    componentDidMount() {
        this.loadDatas()
        this.getmealClassifyList()
        var that = this

    }

    loadDatas = () => {
        var { page, size, name } = this.state
        var obj = { page: page - 1, size: size, organizationId: JSON.parse(localStorage.getItem('data')).organizationId }
        if (!!name) {
            obj.mealName = name
        }
        var that = this
        this.setState({ spinning: true })
        this.props.mealList(obj).then((msg) => {
            console.log(msg)
            if (msg.data.code === 200) {
                that.setState({
                    dataList2: msg.data.data.rows,
                    total: msg.data.data.total,
                    spinning: false,
                })
            }
        })
    }
    getmealClassifyList = () => {
        var that = this
        this.props.mealClassifyList({
            page: 0, size: 999, status: 1
        }).then((msg) => {
            console.log(msg)
            if (msg.data.code === 200) {
                that.setState({
                    classifyList: msg.data.data.rows,
                })
            }
        })
    }
    ondetailIdChange = (value) => {
        this.setState({
            classifyId: value
        })
    }
    editItem = (itm) => {
        var that = this
        console.log(itm.image)
        that.setState({
            bindphyVisble: true,
            mealName: itm.mealName,
            mealPrice: itm.mealPrice,
            id: itm.id,
            originalPrice: itm.originalPrice,
            classifyId: itm.classifyId,
            remark: itm.remark,
            checkProject: itm.checkProject,
            fileList: !!itm.image ? [itm.image] : [],
            detailImageArr: !!itm.detailImage ? itm.detailImage.split(",") : []
        })

    }
    beforeUpload = (file) => {
        console.log(file)
        const formData = new FormData();
        const that = this;
        formData.append('file', file);
        that.props.newUploadFilePath(formData).then(function (res) {
            if (res.data.code === 200) {
                that.setState({
                    fileList: [res.data.fullPath],
                })
            }
            else {
                message.error(res.data.message);
            }
            that.setState({

                loading: false
            })
        });
    }
    beforeUpload1 = (file) => {
        console.log(file)
        var { detailImageArr } = this.state
        const formData = new FormData();
        const that = this;
        formData.append('file', file);
        that.props.newUploadFilePath(formData).then(function (res) {
            if (res.data.code === 200) {
                that.setState({
                    detailImageArr: [...[res.data.fullPath], ...detailImageArr],
                })
            }
            else {
                message.error(res.data.message);
            }
        });
    }
    bandphy = () => {
        var that = this
        that.setState({
            bindphyVisble: true,
            mealName: '',
            mealPrice: '',
            id: '',
            originalPrice: '',
            classifyId: null,
            remark: '',
            checkProject: '',
            fileList: [],
            detailImageArr: []
        })
    }
    handleBindphyOk = () => {
        var { mealName, mealPrice, originalPrice, detailImageArr, remark, checkProject, id, fileList, classifyId } = this.state

        var that = this
        if (!mealName) {
            message.error('请输入套餐名称')
            return
        }
        if (!mealPrice) {
            message.error('请输入套餐价格')
            return
        }
        if (!classifyId) {
            message.error('请选择分类')
            return
        }
        that.props
            .mealSave({
                mealName: mealName,
                mealPrice: mealPrice,
                originalPrice: originalPrice,
                classifyId: classifyId,
                remark: remark,
                checkProject: checkProject,
                id: id,
                image: fileList[0],
                detailImage: detailImageArr.join(',')
            })
            .then((msg) => {
                if (msg.data.code === 200) {
                    message.success('操作成功！')
                    that.loadDatas()
                    that.handleBindphyCancel()
                } else {
                    message.error(msg.data.message)
                }

            })

    }


    handleBindphyCancel = () => {
        this.setState({
            bindphyVisble: false,
            mealName: '',
            mealPrice: '',
            id: '',
            originalPrice: '',
            classifyId: null,
            remark: '',
            checkProject: '',
            detailImageArr: []
        })
    }

    // 选中改变
    onSelectChange = (selectedRowKeys) => {
        console.log(selectedRowKeys)
        this.setState({ selectedRowKeys })
    }
    delItem = (recorrd) => {
        var that = this
        confirm({
            content: '确认删除？',
            onOk() {
                var That = that
                that.props
                    .mealRemove({ id: recorrd.id })
                    .then((msg) => {
                        if (msg.data.code === 200) {
                            message.success('删除成功！')

                            That.loadDatas()
                        }
                    })
            },
        })
    }
    changePage = (page) => {
        var that = this
        this.setState({ page }, () => {
            that.loadDatas()
        })
    }
    mealNameChange = (e, type) => {

        this.setState({
            [type]: e.target.value,
        })
    }
    priceChange = (e, type) => {

        this.setState({
            [type]: e,
        })
    }
    nameOnChange = (e) => {

        this.setState({
            name: e.target.value,
        })
    }
    delImage = (ind) => {
        var { detailImageArr } = this.state
        detailImageArr.splice(ind, 1)
        this.setState({
            detailImageArr
        })
    }
    render() {
        const {
            dataList2,

            selectedRowKeys,

            spinning, detailImageArr,

            bindphyVisble, mealName, mealPrice, originalPrice, remark, checkProject, name, fileList, classifyList, classifyId
        } = this.state
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        }
        const uploadButton = (
            <div>
                <Icon type={this.state.loading ? 'loading' : 'plus'} />
                <div className="ant-upload-text">图片上传</div>
            </div>
        )
        return (
            <div>
                <div className="StatisticsPage-head pageHeader">
                    <h3>套餐列表</h3>
                </div>

                <div className="StatisticsPage-box">
                    <div className="StatisticsPage-search">
                        <div className="StatisticsPage-btn">
                            <Input
                                className="ipt"
                                placeholder="请输入套餐名称"
                                value={name}
                                onChange={this.nameOnChange}
                            />

                            <Button className="btn" type="primary" onClick={this.loadDatas}>
                                搜索
                            </Button>
                            <Button className="btn" type="primary" onClick={this.bandphy}>
                                添加套餐
                            </Button>

                            <Modal
                                title="套餐"
                                visible={bindphyVisble}
                                onOk={this.handleBindphyOk}
                                onCancel={this.handleBindphyCancel}
                                okText="保存"
                                cancelText="取消"
                            >
                                <div className="form-group">
                                    <label className="form-label">套餐名称：</label>
                                    <div className="form-ipt" style={{ marginTop: '5px' }}>
                                        <Input
                                            placeholder="套餐名称"
                                            value={mealName}
                                            onChange={(e) => this.mealNameChange(e, 'mealName')}
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="form-label">套餐分类：</label>
                                    <div className="form-ipt" style={{ marginTop: '5px' }}>
                                        <Select
                                            style={{ width: '100%' }}
                                            value={classifyId}
                                            onChange={this.ondetailIdChange}
                                        >
                                            {classifyList.map((date, index) => {
                                                return (
                                                    <Option key={date.id} value={date.id}>
                                                        {date.classifyName}
                                                    </Option>
                                                )
                                            })}
                                        </Select>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="form-label">原价：</label>
                                    <div className="form-ipt" style={{ marginTop: '5px' }}>
                                        <InputNumber
                                            value={originalPrice}
                                            min={0}
                                            placeholder="请输入原价"
                                            style={{ width: '100%' }}
                                            onChange={(e) => this.priceChange(e, 'originalPrice')}
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="form-label">套餐价格：</label>
                                    <div className="form-ipt" style={{ marginTop: '5px' }}>
                                        <InputNumber
                                            value={mealPrice}
                                            min={0}
                                            style={{ width: '100%' }}
                                            placeholder="请输入套餐价格"
                                            onChange={(e) => this.priceChange(e, 'mealPrice')}
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="form-label">检查项目：</label>
                                    <div className="form-ipt" style={{ marginTop: '5px' }}>
                                        <TextArea
                                            style={{ width: '100%' }}
                                            placeholder="检查项目"
                                            value={checkProject}
                                            onChange={(e) =>
                                                this.mealNameChange(
                                                    e,
                                                    'checkProject',
                                                )
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="form-label">检查用途：</label>
                                    <div className="form-ipt" style={{ marginTop: '5px' }}>
                                        <TextArea
                                            style={{ width: '100%' }}
                                            placeholder="检查用途"
                                            value={remark}
                                            onChange={(e) =>
                                                this.mealNameChange(
                                                    e,
                                                    'remark',
                                                )
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="form-label">套餐图片：</label>
                                    <div className="form-ipt" style={{ marginLeft: '30px' }}>

                                        <Upload
                                            accept="image/png, image/jpeg"
                                            name="avatar"
                                            listType="picture-card"
                                            className="avatar-uploader"
                                            showUploadList={false}
                                            beforeUpload={this.beforeUpload}
                                        // onChange={this.handleChange}
                                        >
                                            <Button type="primary" loading={this.state.loading}>
                                                {this.state.file || (fileList && fileList.length > 0)
                                                    ? '重新上传'
                                                    : '上传照片'}
                                            </Button>
                                        </Upload>
                                    </div>
                                </div>
                                <div style={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'center'
                                }}>
                                    {fileList.length > 0 && (
                                        <div style={{ width: '30%' }}>
                                            <img src={fileList[0]} alt="" srcset="" style={{ width: '100%' }} />
                                        </div>
                                    )}
                                </div>

                                <div className="form-group">
                                    <label className="form-label">详情图片：</label>
                                    <div className="form-ipt" style={{ marginLeft: '30px' }}>

                                        <Upload
                                            accept="image/png, image/jpeg"
                                            name="avatar"
                                            listType="picture-card"
                                            className="avatar-uploader"
                                            showUploadList={false}
                                            beforeUpload={this.beforeUpload1}
                                        >
                                            <Button type="primary" >
                                                上传
                                            </Button>
                                        </Upload>
                                    </div>
                                </div>
                                {detailImageArr.length > 0 && (
                                    <div style={{
                                        width: '100%',
                                    }}>
                                        {
                                            detailImageArr.map((res, i) => (
                                                <div style={{
                                                    display: 'flex',
                                                    alignItems: 'center'
                                                }}>
                                                    <div style={{ width: '40%' }}>
                                                        <img src={res} alt="" srcset="" style={{ width: '80%' }} />

                                                    </div>
                                                    <Button type="primary" danger onClick={() => this.delImage(i)}>
                                                        删除
                                                    </Button>
                                                </div>

                                            ))
                                        }
                                    </div>


                                )}
                            </Modal>
                        </div>
                    </div>
                    <div>
                        <Spin tip="Loading..." spinning={spinning}>
                            <Table
                                size="middle"
                                dataSource={dataList2}
                                bordered
                                rowKey="id"
                                pagination={{
                                    pageSize: this.state.size,
                                    showTotal: (total) => {
                                        return `共 ${total} 条`
                                    },
                                    onChange: this.changePage,
                                    total: this.state.total,
                                    showSizeChanger: false,
                                }}
                                rowSelection={rowSelection}

                            >
                                <Column
                                    title="套餐名称"
                                    dataIndex="mealName"
                                    key="mealName"
                                    align="center"
                                />
                                <Column
                                    title="分类"
                                    dataIndex="classifyId"
                                    key="classifyId"
                                    align="center"
                                    render={(text, record) => (
                                        classifyList.map(res => {
                                            if (record.classifyId == res.id) {
                                                return res.classifyName
                                            }
                                        })

                                    )}
                                />
                                <Column
                                    title="原价"
                                    dataIndex="originalPrice"
                                    key="originalPrice"
                                    align="center"
                                />
                                <Column
                                    title="套餐价格"
                                    dataIndex="mealPrice"
                                    key="mealPrice"
                                    align="center"
                                />
                                <Column
                                    title="检查用途"
                                    dataIndex="remark"
                                    key="remark"
                                    align="center"
                                />
                                <Column
                                    title="检查项目"
                                    dataIndex="checkProject"
                                    key="checkProject"
                                    align="center"

                                />

                                <Column
                                    title="操作"
                                    align="center"
                                    key="action"
                                    render={(text, record) => (
                                        <span>
                                            <span
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => this.editItem(record)}
                                            >
                                                修改
                                            </span>
                                            <Divider type="vertical" />
                                            <span
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => this.delItem(record)}
                                            >
                                                删除
                                            </span>
                                        </span>
                                    )}
                                />
                            </Table>
                        </Spin>

                    </div>
                </div >
            </div >
        )
    }
}
export default connect(null, {
    mealRemove, mealSave, mealList, newUploadFilePath, mealClassifyList
})(mealListPage)
