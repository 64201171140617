import React, { Component } from 'react'
import {
  Modal,
  Button,
  Table,
  Upload,
  Spin,
  Input,
  Tooltip,
  Icon,
  Progress,
  notification,
  DatePicker,
  Select,
  message,
  Divider,
  Radio,
} from 'antd'
import './DCM.css'

import {
  getDcmInfolIST,
  uploadDCM,
  dcmDoctorList,
  batchUpdateDoctor,
  generateDcmReport,
  dcmResultImport,
  dcmDelete,
  dcmUpdate,
  dcmExport,
  updateDCM, settingReportPath
} from '../../../actions/account'
import moment from 'moment'
import { fubaosuo_getdata_PATH } from '../../../actions/urls'
import Axios from 'axios'

// import _ from 'lodash'
import { connect } from 'react-redux'
import _ from 'lodash'

const { RangePicker } = DatePicker
const { Column } = Table
const { Option } = Select
const { confirm } = Modal

const dateFormat = 'YYYYMMDD'
var count = 0
class MachineManage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedRowKeys: [],
      barcodeVisble: false,
      currRecord: undefined,
      size: 10,
      dataList: [],
      page: 1,
      total: 0,
      fileList: [],
      spinning: false,
      visible: false,
      doctorvisible: false,
      submitState: false,
      loading: false,
      percent: 0,
      uname: '',
      barcode: '',
      choosedDates: [],
      startTime: undefined,
      isRead: undefined,
      isError: undefined,
      endTime: undefined,
      doctors: [],
      zdDoctor: null,
      shDoctor: '',
      isUploading: false,
      auditStatus: '',
      isauditStatus: 0,
      editInfoVisble: false,
    }
  }

  componentDidMount() {
    var { startTime, endTime, choosedDates, barcode, uname, isError, isRead } =
      this.state,
      that = this
    if (this.props.location.state) {
      var searchObj = this.props.location.state.searchObj
      if (searchObj.startTime) {
        startTime =
          moment(searchObj.startTime, 'YYYYMMDD').format('YYYY-MM-DD') +
          ' 00:00:00'
        choosedDates = _.concat(
          choosedDates,
          moment(startTime, 'YYYY-MM-DD HH:mm:ss')
        )
      }
      if (searchObj.endTime) {
        endTime =
          moment(searchObj.endTime, 'YYYYMMDD').format('YYYY-MM-DD') +
          ' 00:00:00'
        choosedDates = _.concat(
          choosedDates,
          moment(endTime, 'YYYY-MM-DD HH:mm:ss')
        )
      }
      if (searchObj.barcode) {
        barcode = searchObj.barcode
      }
      if (searchObj.username) {
        uname = searchObj.username
      }
      if (searchObj.isError !== undefined) {
        isError = searchObj.isError
      }
      if (searchObj.isRead !== undefined) {
        isRead = searchObj.isRead
      }

      this.setState(
        {
          startTime,
          endTime,
          choosedDates,
          barcode,
          uname,
          isError,
          isRead,
        },
        () => {
          that.loadDatas()
        }
      )
    } else {
      this.loadDatas()
    }
    var that = this
    this.props.dcmDoctorList({ page: 0, size: 1000 }).then((res) => {
      if (res.data.code === 200) {
        that.setState({
          doctors: res.data.data.rows,
        })
      }
    })
  }

  showModal = () => {
    this.setState({
      visible: true,
    })
  }
  loadDatas = () => {
    var { uname, startTime, endTime, barcode, isError, isRead, isauditStatus } =
      this.state

    this.setState({ spinning: true })
    var { page, size } = this.state
    var obj = { page: page - 1, size: size }
    if (uname !== '') {
      obj.username = uname
    }
    if (barcode !== '') {
      obj.barcode = barcode
    }
    if (startTime !== undefined) {
      obj.startTime = moment(startTime, 'YYYY-MM-DD HH:mm:ss').format(
        'YYYYMMDD'
      )
    }
    if (endTime !== undefined) {
      obj.endTime = moment(endTime, 'YYYY-MM-DD HH:mm:ss').format('YYYYMMDD')
    }
    if (isError !== undefined) {
      obj.isError = isError
    }
    if (isRead !== undefined) {
      obj.isRead = isRead
    }
    if (isauditStatus !== undefined) {
      obj.auditStatus = isauditStatus
    }
    var that = this
    that.props.getDcmInfolIST(obj).then((msg) => {
      if (msg.data.code === 200) {
        if (msg.data.data.rows.length === 0 && msg.data.data.total !== 0) {
          that.setState({ page: page - 1 }, () => {
            that.loadDatas()
          })
        } else {
          that.setState({
            dataList: msg.data.data.rows,
            total: msg.data.data.total,
            spinning: false,
          })
        }
      }
    })
  }

  export = () => {
    var { uname, startTime, endTime, barcode, isError, isRead } = this.state

    this.setState({ spinning: true })
    var { page, size } = this.state
    var obj = { page: page - 1, size: size }
    if (uname !== '') {
      obj.username = uname
    }
    if (barcode !== '') {
      obj.barcode = barcode
    }
    if (startTime !== undefined) {
      obj.startTime = moment(startTime, 'YYYY-MM-DD HH:mm:ss').format(
        'YYYYMMDD'
      )
    }
    if (endTime !== undefined) {
      obj.endTime = moment(endTime, 'YYYY-MM-DD HH:mm:ss').format('YYYYMMDD')
    }
    if (isError !== undefined) {
      obj.isError = isError
    }
    if (isRead !== undefined) {
      obj.isRead = isRead
    }
    var that = this
    that.props.dcmExport(obj).then((res) => {
      that.setState({ spinning: false })
      const fileName = 'export' + moment().format('YYYYMMDDHHmmss')
      if ('download' in document.createElement('a')) {
        // 非IE下载
        const url = window.URL.createObjectURL(
          new Blob(
            [res.data],
            // 设置该文件的mime类型，这里对应的mime类型对应为.xlsx格式
            { type: 'application/vnd.ms-excel' }
          )
        )
        const link = document.createElement('a')
        link.href = url
        // 从header中获取服务端命名的文件名
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
      } else {
        // IE10+下载
        navigator.msSaveBlob(res.data, fileName)
      }
    })
  }
  handleOk = () => {
    var { fileList, isUploading } = this.state,
      that = this
    if (isUploading) {
      message.error('正在上传中...')
      return
    }

    this.setState({ isUploading: true }, () => {
      fileList = _.filter(fileList, function (o) {
        return o.name.indexOf('dcm') !== -1
      })

      that.setState({ submitState: true }, () => {
        that.askrequest(fileList)
      })
    })
  }

  askrequest = async (fileList) => {
    for (var i = 0; i < fileList.length; i = i + 2) {
      const formData = new FormData()
      var fileName = ''
      for (var j = 0; j < 2; j++) {
        if (i + j < fileList.length) {
          formData.append('files', fileList[i + j])
          fileName = fileName + fileList[i + j].name + ','
        } else {
          break
        }
      }
      var that = this
      await that.props.uploadDCM(formData).then((msg) => {
        if (msg.data.code === 200) {
          console.log(parseInt(((i + 2) / fileList.length) * 100))
          that.setState({
            percent: parseInt(((i + 2) / fileList.length) * 100),
          })
          if (i + 2 >= fileList.length) {
            notification.success({
              message: '导入成功',
              description: '胸片列表已更新',
            })
            that.handleCancel()
            that.loadDatas()
          }
        } else {
          notification.error({
            message: `导入失败`,
            description: fileName + msg.data.message + ',请单独上传',
            duration: null,
          })
          that.setState({
            percent: parseInt((i + 2) / fileList.length),
          })
        }
      })
    }
    this.setState({ isUploading: false })
  }
  // askrequest = (fileList, i) => {

  //   if (i >= fileList.length) {
  //     this.setState({ percent: 100,submitState: false })
  //     notification.success({
  //       message: "导入成功",
  //       description: "胸片列表已更新",
  //     })
  //     this.handleCancel();
  //     return;
  //   }
  //   var file1 = fileList[i];
  //   // var file2 = fileList[i + 1];
  //   console.log(file1)
  //   const formData = new FormData();
  //   formData.append('files', file1);
  //   count++;
  //   // if (file2) {formData.append('files', file2);count++;}
  //   var that = this;
  //   that.props.uploadDCM(formData).then(msg => {
  //     if (msg.data.code === 200) {
  //       console.log(parseInt((count/fileList.length)*100))
  //       that.setState({
  //         percent:parseInt((count/fileList.length)*100)
  //       },()=>{
  //         that.askrequest(fileList, i ++);
  //       })

  //     }
  //     else {
  //       notification.error({
  //         message:`${file1.name}:导入失败`,
  //         description: msg.data.message, duration: null
  //       });
  //       that.setState({
  //         percent:parseInt(count/fileList.length)
  //       },()=>{
  //         that.askrequest(fileList, i + 2);
  //       })
  //     }
  //   })
  // }
  handleCancel = () => {
    this.setState({
      fileList: [],
      visible: false,
      doctorvisible: false,
    })
  }
  barcodeOk = () => {
    var { currRecord } = this.state,
      that = this
    var obj = { id: currRecord.id, barcode: currRecord.barcode }
    // this.setState
    this.props.updateDCM(obj).then((res) => {
      message.info(res.data.message)
      that.setState({ barcodeVisble: false, currRecord: undefined })
      that.loadDatas()
    })
  }
  barcodehandleCancel = () => {
    this.setState({ barcodeVisble: false, currRecord: undefined })
  }
  showBarcodeModal = (record, index) => {
    this.setState({ barcodeVisble: true, currRecord: record })
  }
  editInfo = (record, index) => {
    this.setState({ editInfoVisble: true, currRecord: record })
  }
  editInfoCancel = (record, index) => {
    this.setState({ editInfoVisble: false, currRecord: undefined })
  }
  editInfoOk = () => {
    var { currRecord } = this.state,
      that = this
    var obj = {
      id: currRecord.id,
      username: currRecord.username,
      idCard: currRecord.idCard,
      age: currRecord.age,
    }

    this.props.updateDCM(obj).then((res) => {
      message.info(res.data.message)
      that.setState({ editInfoVisble: false, currRecord: undefined })
      that.loadDatas()
    })
  }
  onsexChange = (e) => {
    var { currRecord } = this.state
    currRecord.sex = e.target.value
    this.setState({
      currRecord,
    })
  }
  recordusernameOnChange = (e) => {
    var { currRecord } = this.state
    currRecord.username = e.target.value
    this.setState({ currRecord })
  }
  recordidCardOnChange = (e) => {
    var { currRecord } = this.state
    currRecord.idCard = e.target.value

    var regex = /^\d{6}(\d{4})(\d{2})(\d{2})\d{3}[\dxX]$/ // 身份证号格式为18位或者15位数字加最后一位校验码（x）

    if (regex.test(e.target.value)) {
      var year = e.target.value.substr(6, 4) // 前四位为年份
      var month = e.target.value.substr(10, 2) // 接下来两位为月份
      var day = e.target.value.substr(12, 2) // 再接下来两位为日期

      let birthday = year + '-' + month + '-' + day
      var genderCode = parseInt(e.target.value[16])
      currRecord.sex = genderCode % 2 !== 0 ? 1 : 2
      // this.calculateAge1(this.card.birthday)
      const birthDate = new Date(birthday)
      // 当前日期
      const currentDate = new Date()

      // 计算年龄
      let age = currentDate.getFullYear() - birthDate.getFullYear()

      // 如果当前月份小于出生月份，或者当前月份等于出生月份但当前日期小于出生日期，则年龄减1
      if (
        currentDate.getMonth() < birthDate.getMonth() ||
        (currentDate.getMonth() === birthDate.getMonth() &&
          currentDate.getDate() < birthDate.getDate())
      ) {
        age--
      }
      currRecord.age = age
      console.log(currRecord)
    }
    this.setState({ currRecord })
  }
  recordageOnChange = (e) => {
    var { currRecord } = this.state
    currRecord.age = e.target.value
    this.setState({ currRecord })
  }
  readPic = (record, index) => {
    var { uname, startTime, endTime, barcode, isError, isRead, isauditStatus } =
      this.state
    console.log(index)
    var obj = {}
    if (uname !== '') {
      obj.username = uname
    }
    if (barcode !== '') {
      obj.barcode = barcode
    }
    if (startTime !== undefined) {
      obj.startTime = moment(startTime, 'YYYY-MM-DD HH:mm:ss').format(
        'YYYYMMDD'
      )
    }
    if (endTime !== undefined) {
      obj.endTime = moment(endTime, 'YYYY-MM-DD HH:mm:ss').format('YYYYMMDD')
    }
    if (isError !== undefined) {
      obj.isError = isError
    }
    if (isRead !== undefined) {
      obj.isRead = isRead
    }
    if (isauditStatus !== undefined) {
      obj.isauditStatus = isauditStatus
    }
    // this.props.history.push('/admin/picRead?url=' + record.filePath)
    this.props.history.push({
      pathname: '/admin/picRead',
      state: {
        record: JSON.stringify(record),
        page: this.state.page,
        index: index,
        searchObj: obj,
      },
    })
  }
  changePage = (page) => {
    var that = this
    this.setState({ page }, () => {
      that.loadDatas()
    })
  }
  barcodeOnChange = (e) => {
    this.setState({ barcode: e.target.value })
  }
  recordBarcodeOnChange = (e) => {
    var { currRecord } = this.state
    currRecord.barcode = e.target.value
    this.setState({ currRecord })
  }
  nameOnChange = (e) => {
    this.setState({ uname: e.target.value })
  }
  datetimeChange = (date, dateString) => {
    this.setState({
      startTime: dateString[0] === '' ? undefined : dateString[0] + ' 00:00:00',
      endTime: dateString[1] === '' ? undefined : dateString[1] + ' 23:59:59',
      choosedDates: date,
    })
  }
  isErrorONChange = (value) => {
    this.setState({ isError: value })
  }
  isReadOnChange = (value) => {
    this.setState({ isRead: value })
  }
  isauditStatus = (value) => {
    this.setState({ isauditStatus: value })
  }
  onSelectChange = (selectedRowKeys) => {
    // var { dataList } = this.state;
    this.setState({ selectedRowKeys })
  }
  showDoctorModal = () => {
    var { selectedRowKeys } = this.state
    if (selectedRowKeys.length === 0) {
      message.error('请选择数据')
      return
    }
    this.setState({ doctorvisible: true })
  }
  print = () => {
    var { selectedRowKeys } = this.state
    if (selectedRowKeys.length === 0) {
      message.error('请选择数据')
      return
    }
    var formdata = new FormData()
    formdata.append('dcmInfoIds', selectedRowKeys)
    var that = this
    this.setState({ spinning: true }, () => {
      that.props.generateDcmReport(formdata).then((res) => {
        console.log(res)
        if (res.data.code === 200) {
          window.open(res.data.fullPath, '_blank')
        } else {
          message.error(res.data.message)
        }
        that.setState({
          spinning: false,
        })
      })
    })
  }
  settingReportPath = async () => {
    var { selectedRowKeys, dataList } = this.state
    if (selectedRowKeys.length === 0) {
      message.error('请选择数据')
      return
    }
    for (var i = 0; i < selectedRowKeys.length; i++) {
      var obj = _.find(dataList, ['id', selectedRowKeys[i]])
      console.log(obj)
      if (!!obj.filePath) {
        var formdata = new FormData()
        formdata.append('dcmInfoIds', selectedRowKeys[i])
        var pdfPath = "";
        await this.props.generateDcmReport(formdata).then((res) => {
          console.log(res)
          if (res.data.code === 200) {
            pdfPath = res.data.fullPath

          } else {
            message.error(res.data.message)
          }

        })
        if (pdfPath !== "") {
          await this.props.settingReportPath({ idCard: obj.idCard, username: obj.username, pdfPath: pdfPath }).then((res) => {
            console.log(res)
            message.info(
              '第' +
              i +
              '个，共' +
              selectedRowKeys.length +
              '个，' +
              res.data.message
            )
          })
        } else {
          message.info(
            '第' +
            i +
            '个，共' +
            selectedRowKeys.length +
            '个，' +
            '暂无pdf'
          )
        }
      }


    }
  }
  maternity = async () => {
    var { selectedRowKeys, dataList } = this.state
    for (var i = 0; i < selectedRowKeys.length; i++) {
      var obj = _.find(dataList, ['id', selectedRowKeys[i]])
      var datas = []
      await Axios.post(
        fubaosuo_getdata_PATH +
        '?clientname=南京知康门诊部&token=2a7b98bb-2e00-4623-8135-f0e7d8fef2e7&patientno=' +
        obj.barcode
      ).then((res) => {
        console.log(res)
        if (res.data.errorcode === 0) {
          datas = res.data.obj
        }
      })
      if (datas.length !== 0) {
        var data = datas[0]
        var objj = { id: selectedRowKeys[i] }
        objj.age = data.patientage
        objj.username = data.patientname
        objj.idCard = obj.barcode
        if (data.patientgender === '男') {
          objj.sex = 1
        } else {
          objj.sex = 2
        }

        await this.props.dcmUpdate(objj).then((msg) => {
          message.info(
            '第' +
            i +
            '个，共' +
            selectedRowKeys.length +
            '个，' +
            msg.data.message
          )
        })
      }
    }
  }

  delete = () => {
    var { selectedRowKeys } = this.state
    var that = this
    confirm({
      content: '确认删除？',
      onOk() {
        that.props.dcmDelete({ ids: selectedRowKeys.join(',') }).then((res) => {
          if (res.data.code === 200) {
            that.loadDatas()
          }
        })
      },
    })
  }
  selectOnchange = (value, mark) => {
    if (mark === 1) {
      this.setState({ zdDoctor: value })
    } else if (mark === 2) {
      this.setState({ shDoctor: value })
    } else if (mark === 3) {
      this.setState({ auditStatus: value })
    }
  }
  handleDoctorOk = () => {
    var { selectedRowKeys, shDoctor, zdDoctor, auditStatus } = this.state
    console.log(zdDoctor)
    if (zdDoctor === '' || zdDoctor === undefined || zdDoctor === null) {
      message.error('诊断医师不能为空')
      return
    }
    var obj = { ids: selectedRowKeys, checkDoctor: zdDoctor }
    if (shDoctor !== '' && shDoctor !== undefined) {
      obj.auditDoctor = shDoctor
    }
    if (
      auditStatus !== '' &&
      auditStatus !== undefined &&
      auditStatus !== null
    ) {
      obj.auditStatus = auditStatus
    }
    var that = this
    this.props.batchUpdateDoctor(obj).then((res) => {
      if (res.data.code === 200) {
        message.success('设置成功')
        that.setState({ doctorvisible: false })
        that.loadDatas()
      }
    })
  }

  fileUploadOnChange = (info) => {
    if (info.file.status === 'uploading') {
      this.setState({ loading: true })
    } else {
      this.setState({ loading: false })
    }
  }

  customRequest = (option, record) => {
    var that = this

    const formData = new FormData()
    formData.append('file', option.file)
    // formData.append('appointmentDetailId', record.detailId);
    // 2021-04-19 14:30:00-15:30:00
    // var time =record.startTime;
    // var endTime= moment(record.endTime,"YYYY-MM-DD HH:mm:ss").format("HH:mm:ss");
    // time = time+"-"+endTime;
    // formData.append('appointmentDate',time)
    this.props.dcmResultImport(formData).then((res) => {
      if (res.data.code === 200) {
        message.success('上传成功')
        that.setState({ loading: false })
      } else {
        // message.error("")
        message.error(res.data.message)
        that.setState({ loading: false })
      }
    })
  }
  pageSizeOnChange = (current, size) => {
    var that = this
    this.setState({ size: size, selectedRowKeys: [] }, () => {
      that.loadDatas()
    })
  }
  render() {
    const {
      dataList,
      selectedRowKeys,
      spinning,
      fileList,
      uname,
      barcode,
      choosedDates,
      doctors,
      loading,
      currRecord,
    } = this.state
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    }
    const props = {
      directory: true,
      progress: {
        strokeColor: {
          '0%': '#108ee9',
          '100%': '#87d068',
        },
        strokeWidth: 3,
        format: (percent) => `${parseFloat(percent.toFixed(2))}%`,
      },
      onRemove: (file) => {
        this.setState((state) => {
          const index = state.fileList.indexOf(file)
          const newFileList = state.fileList.slice()
          newFileList.splice(index, 1)
          return {
            fileList: newFileList,
          }
        })
      },
      beforeUpload: (file) => {
        if (
          file.name.indexOf('dcm') !== -1 &&
          file.name.indexOf('org') === -1
        ) {
          this.setState((state) => ({
            fileList: [...state.fileList, file],
          }))
        }

        return false
      },
      fileList,
    }

    return (
      <div>
        <div className="StatisticsPage-head pageHeader">
          <h3>胸片管理</h3>
        </div>
        <Spin spinning={this.state.spinning}>
          <div className="StatisticsPage-box">
            <div className="StatisticsPage-search">
              <div className="StatisticsPage-btn">
                <RangePicker
                  dateFormat={dateFormat}
                  placeholder={['开始时间', '结束时间']}
                  className="date"
                  onChange={this.datetimeChange}
                  value={choosedDates}
                />
                <Input
                  className="ipt"
                  placeholder="请输入条码"
                  value={barcode}
                  style={{ width: 100 }}
                  onChange={this.barcodeOnChange}
                />
                <Input
                  className="ipt"
                  placeholder="请输入姓名"
                  value={uname}
                  style={{ width: 100 }}
                  onChange={this.nameOnChange}
                />
                <Select
                  style={{ width: 100 }}
                  allowClear={true}
                  placeholder="是否异常"
                  onChange={this.isErrorONChange}
                  value={this.state.isError}
                >
                  <Option value={0}>异常</Option>
                  <Option value={1}>正常</Option>
                </Select>{' '}
                <Select
                  style={{ width: 100 }}
                  allowClear={true}
                  placeholder="是否阅片"
                  onChange={this.isReadOnChange}
                  value={this.state.isRead}
                >
                  <Option value={0}>否</Option>
                  <Option value={1}>是</Option>
                </Select>{' '}
                <Select
                  style={{ width: 100 }}
                  allowClear={true}
                  placeholder="审核状态"
                  onChange={this.isauditStatus}
                  value={this.state.isauditStatus}
                >
                  <Option value={0}>未审核</Option>
                  <Option value={1}>已审核</Option>
                </Select>
                <Button className="btn" type="primary" onClick={this.loadDatas}>
                  搜索
                </Button>
                <Button className="btn" type="primary" onClick={this.export}>
                  导出
                </Button>
                <Button className="btn" type="primary" onClick={this.showModal}>
                  文件导入
                </Button>
                <Upload
                  showUploadList={false}
                  customRequest={this.customRequest}
                  onChange={this.fileUploadOnChange}
                >
                  <Tooltip placement="right">
                    {loading ? (
                      <Icon type="loading" />
                    ) : (
                      <Button className="btn" type="primary">
                        导入结果
                      </Button>
                    )}
                  </Tooltip>
                </Upload>
                <Button
                  className="btn"
                  type="primary"
                  onClick={this.showDoctorModal}
                >
                  胸片审核
                </Button>
                <Button className="btn" type="primary" onClick={this.print}>
                  报告打印
                </Button>
                <Button className="btn" type="primary" onClick={this.settingReportPath}>
                  推送报告查询
                </Button>
                <Button className="btn" type="primary" onClick={this.maternity}>
                  妇保同步信息
                </Button>
                <Button className="btn" type="primary" onClick={this.delete}>
                  删除
                </Button>
              </div>
            </div>
            <div>
              <Modal
                title="胸片文件导入(超过100巨卡)"
                maskClosable={false}
                visible={this.state.visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                okText="保存"
                cancelText="取消"
              >
                <div className="form-group">
                  <label className="form-label">选择文件：</label>
                  <div className="form-ipt">
                    <Upload {...props}>
                      <Button>选择文件</Button>
                      {this.state.fileList.length > 0 && (
                        <span>共{this.state.fileList.length}个文件</span>
                      )}
                    </Upload>
                  </div>
                </div>
                {this.state.submitState && (
                  <div className="form-group">
                    <label className="form-label">上传进度：</label>
                    <div className="form-ipt">
                      <Progress percent={this.state.percent} status="active" />
                    </div>
                  </div>
                )}
              </Modal>
              <Modal
                title="胸片审核设置"
                maskClosable={false}
                visible={this.state.doctorvisible}
                onOk={this.handleDoctorOk}
                onCancel={this.handleCancel}
                okText="保存"
                cancelText="取消"
              >
                <div className="form-group">
                  <label className="form-label">诊断医师：</label>
                  <div className="form-ipt">
                    <Select
                      style={{ width: 300 }}
                      onChange={(value) => this.selectOnchange(value, 1)}
                    >
                      {doctors.map((doctor) => {
                        return (
                          <Option
                            key={'zd' + doctor.id}
                            value={doctor.doctorName}
                          >
                            {doctor.doctorName}
                          </Option>
                        )
                      })}
                    </Select>
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-label">审核医师：</label>
                  <div className="form-ipt">
                    <Select
                      style={{ width: 300 }}
                      onChange={(value) => this.selectOnchange(value, 2)}
                    >
                      {doctors.map((doctor) => {
                        return (
                          <Option
                            key={'sh' + doctor.id}
                            value={doctor.doctorName}
                          >
                            {doctor.doctorName}
                          </Option>
                        )
                      })}
                    </Select>
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-label">审核状态：</label>
                  <div className="form-ipt">
                    <Select
                      style={{ width: 300 }}
                      onChange={(value) => this.selectOnchange(value, 3)}
                    >
                      <Option value={0}>未审核</Option>
                      <Option value={1}>已审核</Option>
                    </Select>
                  </div>
                </div>
              </Modal>
              <Modal
                title="修改条码号"
                maskClosable={false}
                visible={this.state.barcodeVisble}
                onOk={this.barcodeOk}
                onCancel={this.barcodehandleCancel}
                okText="保存"
                cancelText="取消"
              >
                <div className="form-group">
                  <label className="form-label">条码号：</label>
                  <div className="form-ipt">
                    {currRecord && (
                      <Input
                        value={currRecord.barcode}
                        onChange={this.recordBarcodeOnChange}
                      />
                    )}
                  </div>
                </div>
              </Modal>
              <Modal
                title="修改基本信息"
                maskClosable={false}
                visible={this.state.editInfoVisble}
                onOk={this.editInfoOk}
                onCancel={this.editInfoCancel}
                okText="保存"
                cancelText="取消"
              >
                <div className="form-group">
                  <label className="form-label">姓名：</label>
                  <div className="form-ipt">
                    {currRecord && (
                      <Input
                        value={currRecord.username}
                        onChange={this.recordusernameOnChange}
                      />
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-label">身份证：</label>
                  <div className="form-ipt">
                    {currRecord && (
                      <Input
                        value={currRecord.idCard}
                        onChange={this.recordidCardOnChange}
                      />
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-label">年龄：</label>
                  <div className="form-ipt">
                    {currRecord && (
                      <Input
                        value={currRecord.age}
                        onChange={this.recordageOnChange}
                      />
                    )}
                  </div>
                </div>

                <div className="form-group">
                  <label className="form-label">性别：</label>
                  <div className="form-ipt">
                    {currRecord && (
                      <Radio.Group
                        onChange={this.onsexChange}
                        value={currRecord.sex}
                      >
                        <Radio value={1}>男</Radio>
                        <Radio value={2}>女</Radio>
                      </Radio.Group>
                    )}
                  </div>
                </div>
              </Modal>

              <Spin tip="Loading..." spinning={spinning}>
                <Table
                  size="middle"
                  dataSource={dataList}
                  bordered
                  rowKey="id"
                  pagination={{
                    pageSize: this.state.size,
                    showTotal: (total) => {
                      return `共 ${total} 条`
                    },
                    onChange: this.changePage,
                    total: this.state.total,
                    pageSizeOptions: ['10', '20', '50', '100', '500', '5000'],
                    showSizeChanger: true,
                    onShowSizeChange: this.pageSizeOnChange,
                  }}
                  rowSelection={rowSelection}
                // onRow={(record, index) => {
                //   return {
                //     onClick: () => {
                //       let temp = this.state.selectedRowKeys
                //       let index = temp.indexOf(record.id)
                //       if (index === -1) { temp.push(record.id) }
                //       else { temp.splice(index, 1) }
                //       this.setState({ selectedRowKeys: temp });
                //     }
                //   }
                // }}
                >
                  <Column
                    title="条码号"
                    dataIndex="barcode"
                    key="barcode"
                    align="center"
                  />
                  <Column
                    title="姓名"
                    dataIndex="username"
                    key="username"
                    align="center"
                  />
                  <Column
                    title="证件号"
                    dataIndex="idCard"
                    key="idCard"
                    align="center"
                  />
                  <Column
                    width={45}
                    title="性别"
                    dataIndex="sex"
                    key="sex"
                    align="center"
                    render={(text, record) => (
                      <div
                        style={{
                          wordWrap: 'break-word',
                          wordBreak: 'break-all',
                        }}
                      >
                        {text && text === 1 ? '男' : '女'}
                      </div>
                    )}
                  />
                  <Column
                    title="年龄"
                    dataIndex="age"
                    key="age"
                    align="center"
                  />
                  <Column
                    title="拍片日期"
                    dataIndex="checkDate"
                    key="checkDate"
                    align="center"
                  />
                  <Column
                    width={200}
                    title="诊断结果"
                    dataIndex="diagnoseResult"
                    ellipsis={true}
                    key="diagnoseResult"
                    align="center"
                  />
                  <Column
                    title="检查医生"
                    dataIndex="checkDoctor"
                    ellipsis={true}
                    key="checkDoctor"
                    align="center"
                  />
                  <Column
                    title="审核状态"
                    dataIndex="auditStatus"
                    key="auditStatus"
                    align="center"
                    render={(text, record) => (
                      <div
                        style={{
                          wordWrap: 'break-word',
                          wordBreak: 'break-all',
                        }}
                      >
                        {text === 0 ? '未审核' : text === 1 ? '已审核' : ''}
                      </div>
                    )}
                  />
                  {/* <Column title="检查所见" dataIndex="examinationInfo"ellipsis={true} key="examinationInfo" align="center" /> */}
                  {/* <Column title="结果录入时间" dataIndex="updatedAt" key="updatedAt" align="center" /> */}
                  <Column
                    title="操作"
                    align="center"
                    key="action"
                    render={(text, record, index) => (
                      <span>
                        <span
                          style={{ cursor: 'pointer' }}
                          onClick={(e) => this.readPic(record, index)}
                        >
                          胸片阅读
                        </span>
                        <Divider type="vertical" />
                        <span
                          style={{ cursor: 'pointer' }}
                          onClick={(e) => this.showBarcodeModal(record, index)}
                        >
                          修改条码号
                        </span>
                        <Divider type="vertical" />
                        <span
                          style={{ cursor: 'pointer' }}
                          onClick={(e) => this.editInfo(record, index)}
                        >
                          修改基本信息
                        </span>
                      </span>
                    )}
                  />
                </Table>
              </Spin>
            </div>
          </div>
        </Spin>
      </div>
    )
  }
}
export default connect(null, {
  getDcmInfolIST,
  updateDCM,
  uploadDCM,
  dcmDoctorList,
  batchUpdateDoctor,
  generateDcmReport,
  dcmResultImport,
  dcmDelete,
  dcmUpdate,
  dcmExport, settingReportPath
})(MachineManage)
