import {
  Post,
  Put,
  Get,
  Delete,
  PostForm,
  PostDown,
  PostDown2,
  PostTwoCancer,
  Upload,
} from './axios'
import {
  LOGIN_PATH,
  Organization_Logout_PATH,
  userGroupNumber_PATH,
  exportNcov_Path,
  UsableMachine_PATH,
  GuahaoList_PATH,
  MachineList_PATH,
  MachineAdd_PATH,
  OrganizationBindMachine_PATH,
  GuahaoAdd_PATH,
  alreadyMachineList_PATH,
  OrganizationAdd_PATH,
  OrganizationList_PATH,
  physicalTypeList_PATH,
  physicalSave_PATH,
  physicalUpdate_PATH,
  physicalDel_PATH,
  organizationSelect_PATH,
  Guahao_Organization_PATH,
  Barcode_PATH,
  BindPhysicalTypeUpdate_PATH,
  organizationPhysicalType_select_PATH,
  organizationPhysicalType_PATH,
  organizationPhysicalTypeSave_PATH,
  organizationPhysicalTypeRemove_PATH,
  organizationPhysicalTypeUpdate_PATH,
  allBindList_PATH,
  Guahao_getDetail_PATH,
  Organization_Delete_PATH,
  Old_System_Data_PATH,
  Guahao_remove_Path,
  Guahao_update_Path,
  import_registerInfo_PATH,
  BindMachine_remove_PATH,
  organization_not_slect_Path,
  itemRegistration_List_Path,
  itemRegistration_export_Path,
  uploadFile_Path,
  inportBackUP_Path,
  project_save_Path,
  project_remove_Path,
  project_slaveList_Path,
  project_parentList_Path,
  physicalRecord_Path,
  organizationTypeProject_save_Path,
  organizationTypeProject_remove_Path,
  organizationTypeProject_update_Path,
  organizationTypeProject_list_Path,
  exportNcovUser_Path,
  exportHsExcel_Path,
  reagent_save_Path,
  reagent_remove_Path,
  reagent_update_Path,
  reagent_list_Path,
  reagent_list_noPage_Path,
  environment_save_Path,
  environment_remove_Path,
  environment_update_Path,
  environment_list_Path,
  environment_list_noPage_Path,
  setReport_env_Path,
  updateInspectionResult_Path,
  batchSetResult_PATH,
  generateReport_Path,
  doctorAll_PATH,
  doctorSave_PATH,
  doctorUpdate_PATH,
  doctorDelete_PATH,
  imageUpload_PATH,
  detectionMachine_Save_PATH,
  detectionMachine_Update_PATH,
  detectionMachine_Delete_PATH,
  detectionMachine_List_PATH,
  setMachine_PATH,
  scale_List_PATH,
  updateSampleNumber_PATH,
  childOrganization_list_Path,
  childOrganization_add_Path,
  childOrganization_remove_Path,
  childOrganization_update_Path,
  items_export_PATH,
  setPersonsCategory_Path,
  batchSetSampleWay_Path,
  readExcel_Path,
  resetPwd_PATH,
  modules_PATH,
  businessTypes_PATH,
  logList_PATH,
  AppointmentDate_LIST_Path,
  AppointmentDate_UPDATE_Path,
  AppointmentDate_ADD_Path,
  AppointmentDateDetail_LIST_Path,
  AppointmentDateDetail_UPDATE_Path,
  AppointmentDateDetail_ADD_Path,
  appointmentList_LIST_Path,
  appointmentPageDetail_Path,
  appointmentSave_Path,
  appointmentPageDetail_Path2,
  appointmentDateDetail_Remove_Path,
  AppointmentInfo_PATH,
  appointmentCancel_PATH,
  barcodeRuleSet_PATH,
  AppointmentList_PATH,
  AppointmentExport_Path,
  getBarcodeRule_PATH,
  org_PhycicalTypes_PATH,
  valid_PhysicalRecord_PATH,
  transferPhysicalData_PATH,
  organization_Statistics_PATH,
  excelGeneratePdf_PATH,
  itemRegistration_PATH,
  updateItemCheckNum_PATH,
  getItemInfo_PATH,
  setReportEnv_item_Path,
  setMachine_item_Path,
  generateItemPdf_Path,
  dcmInfo_LIST_Path,
  uploadDCM_Path,
  updateDCM_Path,
  batchSetItemResult_Path,
  export_AppointGroup_PATH,
  export_parseExcel_PATH,
  appointmentDate_Remove_Path,
  two_cancer_login_PATH,
  two_cancer_checklist_PATH,
  itemRegistration_delete_PATH,
  wx_official_pdf_PATH,
  wx_sendCode_PATH,
  getToken_cancer_PATH,
  getRecord_TWOCANCER_PATH,
  impot_appointmentUser_PATH,
  // get_root_area_PATH,
  // get_child_area_PATH,
  appointmentRegister_Save_Path,
  appointmentRegister_Detail_Path,
  shortLink_save_PATH,
  shortLink_list_PATH,
  dcm_doctor_list_PATH,
  batch_update_doctor_PATH,
  dcm_suggest_save_PATH,
  dcm_suggest_update_PATH,
  dcm_suggest_list_PATH,
  dcm_suggest_del_PATH,
  dcm_doctor_save_PATH,
  dcm_doctor_update_PATH,
  dcm_doctor_del_PATH,
  dcm_upload_file_PATH,
  generate_dcm_report_PATH,
  healthDeclare_Entry_PATH,
  healthDeclare_Detail_PATH,
  healthDeclare_Delete_PATH,
  dcm_result_import_PATH,
  fubaosuo_getdata_PATH,
  dcm_delete_PATH,
  dcm_update_PATH,
  dcm_export_PATH,
  reportList_PATH,
  reportDownLoad_PATH,
  twoCancer_IsHave_PATH,
  stack_save_PATH,
  stack_list_PATH,
  stack_del_PATH,
  stack_update_PATH,
  userBindStack_PATH,
  UserStackDel_PATH,
  userStackExport_PATH,
  userImportStack_PATH,
  bmodelInfo_PATH,
  ecgInfo_PATH,
  exportBmodel_PATH,
  exportEcg_PATH,
  deleteEcg_PATH,
  deleteB_PATH,
  dcmSuggestimportExcel_PATH,
  pissResult_PATH,
  pissResultimportExcel_PATH,
  pissResultremove_PATH,
  pissResultupdate_PATH,
  sendApplySampleInfo_PATH,
  jointGongWei_PATH,
  cancelApplyForm_PATH,
  physicalRecord_remove_Path,
  appointmentUser_PATH,
  libangEcgList_PATH,
  libangEcgsave_PATH,
  libangEcgupdate_PATH,
  libangEcgremove_PATH, forPay_PATH,
  libangEcgexportEcg_PATH,
  settingReportPath_PATH,
  sampleInfoinsert_PATH,
  mealList_PATH, appointmentParamRangeList_PATH, appointmentParamRangeSave_PATH, appointmentParamRangeRemove_PATH, appointmentParamRangeUpdate_PATH,
  mealSave_PATH, jscode2session_PATH, appointmentParamList_PATH, appointmentParamSave_PATH,
  mealRemove_PATH, newUploadFilePath_PATH, generateSign_PATH, mealClassifyList_PATH, mealClassifySave_PATH, mealClassifyRemove_PATH
} from './urls'
//预约
export const appointmentListList = (data) => (dispatch) =>
  Get(appointmentList_LIST_Path, data).then((res) => res)
export const appointmentPageDetail = (data) => (dispatch) =>
  Get(appointmentPageDetail_Path, data).then((res) => res)
export const appointmentPageDetail2 = (data) => (dispatch) =>
  Get(appointmentPageDetail_Path2, data).then((res) => res)

export const appointmentSave = (data) => (dispatch) =>
  Post(appointmentSave_Path, data).then((res) => res)
export const appointmentRegisterSave = (data) => (dispatch) =>
  Post(appointmentRegister_Save_Path, data).then((res) => res)
export const appointmentRegisterDetail = (data) => (dispatch) =>
  Get(appointmentRegister_Detail_Path, data).then((res) => res)
export const delDateDetail = (data) => (dispatch) =>
  Delete(appointmentDateDetail_Remove_Path, data).then((res) => res)
export const delDate = (data) => (dispatch) =>
  Delete(appointmentDate_Remove_Path, data).then((res) => res)

export const getAppointmentDateList = (data) => (dispatch) =>
  Get(AppointmentDate_LIST_Path, data).then((res) => res)
export const addAppointmentDate = (data) => (dispatch) =>
  Post(AppointmentDate_ADD_Path, data).then((res) => res)
export const updateAppointmentDate = (data) => (dispatch) =>
  Put(AppointmentDate_UPDATE_Path, data).then((res) => res)

export const getAppointmentDateDetailList = (data) => (dispatch) =>
  Get(AppointmentDateDetail_LIST_Path, data).then((res) => res)
export const addAppointmentDateDetail = (data) => (dispatch) =>
  Post(AppointmentDateDetail_ADD_Path, data).then((res) => res)
export const updateAppointmentDateDetail = (data) => (dispatch) =>
  Put(AppointmentDateDetail_UPDATE_Path, data).then((res) => res)

//批量设置采集方式
export const batchSetSampleWay = (data) => (dispatch) =>
  Post(batchSetSampleWay_Path, data).then((res) => res)

//人群分类管理
export const setPersonsCategory = (data) => (dispatch) =>
  Put(setPersonsCategory_Path, data).then((res) => res)
// 账号管理
export const addChildOrganization = (data) => (dispatch) =>
  Post(childOrganization_add_Path, data).then((res) => res)
export const removeChildOrganization = (data) => (dispatch) =>
  Delete(childOrganization_remove_Path, data).then((res) => res)
export const getChildOrganizationList = (data) => (dispatch) =>
  Get(childOrganization_list_Path, data).then((res) => res)
export const updateChildOrganization = (data) => (dispatch) =>
  Put(childOrganization_update_Path, data).then((res) => res)
//环境
export const EnvironmentSave = (data) => (dispatch) =>
  Post(environment_save_Path, data).then((res) => res)
export const EnvironmentRemove = (data) => (dispatch) =>
  Delete(environment_remove_Path, data).then((res) => res)
export const EnvironmentUpdate = (data) => (dispatch) =>
  Put(environment_update_Path, data).then((res) => res)
export const getEnvironmentList = (data) => (dispatch) =>
  Get(environment_list_Path, data).then((res) => res)
export const getEnvironmentNoPageList = (data) => (dispatch) =>
  Get(environment_list_noPage_Path, data).then((res) => res)
export const setReport = (data) => (dispatch) =>
  PostForm(setReport_env_Path, data).then((res) => res)

//试剂
export const reagentSave = (data) => (dispatch) =>
  Post(reagent_save_Path, data).then((res) => res)
export const reagentRemove = (data) => (dispatch) =>
  Delete(reagent_remove_Path, data).then((res) => res)
export const reagentUpdate = (data) => (dispatch) =>
  Put(reagent_update_Path, data).then((res) => res)
export const getreagentList = (data) => (dispatch) =>
  Get(reagent_list_Path, data).then((res) => res)
export const getreagentNoPageList = (data) => (dispatch) =>
  Get(reagent_list_noPage_Path, data).then((res) => res)
//  项目管理
export const projectSave = (data) => (dispatch) =>
  Post(project_save_Path, data).then((res) => res)
export const projectRemove = (data) => (dispatch) =>
  Delete(project_remove_Path, data).then((res) => res)
export const getSlaveList = (data) => (dispatch) =>
  Get(project_slaveList_Path, data).then((res) => res)
export const getParentList = (data) => (dispatch) =>
  Get(project_parentList_Path, data).then((res) => res)
//检验结果

export const getPhysicalRecord = (data) => (dispatch) =>
  Get(physicalRecord_Path, data).then((res) => res)
export const updateInspectionResult = (data) => (dispatch) =>
  Put(updateInspectionResult_Path, data).then((res) => res)
//  batchSetResult_PATH
export const batchSetResult = (data) => (dispatch) =>
  Post(batchSetResult_PATH, data).then((res) => res)
//体检类型-项目绑定
export const getPhyProjectList = (data) => (dispatch) =>
  Get(organizationTypeProject_list_Path, data).then((res) => res)
export const removePhyProjectList = (data) => (dispatch) =>
  Delete(organizationTypeProject_remove_Path, data).then((res) => res)
export const savePhyProjectList = (data) => (dispatch) =>
  Post(organizationTypeProject_save_Path, data).then((res) => res)
export const updatePhyProjectList = (data) => (dispatch) =>
  Put(organizationTypeProject_update_Path, data).then((res) => res)

export const uploadFile = (data) => (dispatch) =>
  PostForm(uploadFile_Path, data).then((res) => res)
export const inportBackUP = (data) => (dispatch) =>
  Post(inportBackUP_Path, data).then((res) => res)
export const getItemRegistrationList = (data) => (dispatch) =>
  Get(itemRegistration_List_Path, data).then((res) => res)
export const exportItemRegistrationList = (data) => (dispatch) =>
  PostDown(itemRegistration_export_Path, data).then((res) => res)

export const organizationPhysicalTypeSelect = (data) => (dispatch) =>
  Get(organizationPhysicalType_select_PATH, data).then((res) => res)
export const organizationPhysicalType = (data) => (dispatch) =>
  Get(organizationPhysicalType_PATH, data).then((res) => res)
export const organizationPhysicalTypeSave = (data) => (dispatch) =>
  Post(organizationPhysicalTypeSave_PATH, data).then((res) => res)
export const organizationPhysicalTypeRemove = (data) => (dispatch) =>
  Delete(organizationPhysicalTypeRemove_PATH, data).then((res) => res)
export const organizationPhysicalTypeUpdate = (data) => (dispatch) =>
  Put(organizationPhysicalTypeUpdate_PATH, data).then((res) => res)

export const importRegisterInfo = (data) => (dispatch) =>
  PostDown(import_registerInfo_PATH, data).then((res) => res)
export const BindMachineRemove = (data) => (dispatch) =>
  Delete(BindMachine_remove_PATH, data).then((res) => res)

export const login = (data) => (dispatch) =>
  Post(LOGIN_PATH, data).then((res) => res) //登录

export const OrganizationLogout = (data) => (dispatch) =>
  Post(Organization_Logout_PATH, data).then((res) => res)
// 挂号
export const getGuahaoList = (data) => (dispatch) =>
  Get(GuahaoList_PATH, data).then((res) => res)
export const getOrganizationGuahaoList = (data) => (dispatch) =>
  Get(Guahao_Organization_PATH, data).then((res) => res)
export const addGuahao = (data) => (dispatch) =>
  PostForm(GuahaoAdd_PATH, data).then((res) => res)
export const getDetail = (data) => (dispatch) =>
  Get(Guahao_getDetail_PATH, data).then((res) => res)
export const guahaoDel = (data) => (dispatch) =>
  Delete(Guahao_remove_Path, data).then((res) => res)
export const guahaoUpdate = (data) => (dispatch) =>
  Put(Guahao_update_Path, data).then((res) => res)

// 机器
export const getallBindList = (data) => (dispatch) =>
  Get(allBindList_PATH, data).then((res) => res)

export const getAlreadyMachineList = (data) => (dispatch) =>
  Get(alreadyMachineList_PATH, data).then((res) => res)
export const getUsableMachine = (data) => (dispatch) =>
  Get(UsableMachine_PATH, data).then((res) => res)
export const getMachine = (data) => (dispatch) =>
  Get(MachineList_PATH, data).then((res) => res)
export const addMachine = (data) => (dispatch) =>
  Post(MachineAdd_PATH, data).then((res) => res)
// Organization_Delete_PATH
export const organizationDelete = (data) => (dispatch) =>
  Delete(Organization_Delete_PATH, data).then((res) => res)

// 机构绑定机器
export const setOrganizationBindMachine = (data) => (dispatch) =>
  Post(OrganizationBindMachine_PATH, data).then((res) => res)
export const addOrganization = (data) => (dispatch) =>
  Post(OrganizationAdd_PATH, data).then((res) => res)
export const getOrganizationList = (data) => (dispatch) =>
  Get(OrganizationList_PATH, data).then((res) => res)
export const getAllorganization = (data) => (dispatch) =>
  Get(organizationSelect_PATH, data).then((res) => res)
export const getNotBindorganization = (data) => (dispatch) =>
  Get(organization_not_slect_Path, data).then((res) => res)
export const updateBindPhysicalType = (data) => (dispatch) =>
  Put(BindPhysicalTypeUpdate_PATH, data).then((res) => res)

//体检类型
export const getPhysicalTypeList = (data) => (dispatch) =>
  Get(physicalTypeList_PATH, data).then((res) => res)
export const addPhysicalType = (data) => (dispatch) =>
  Post(physicalSave_PATH, data).then((res) => res)
export const updatePhysicalType = (data) => (dispatch) =>
  Put(physicalUpdate_PATH, data).then((res) => res)
export const delPhysicalType = (data) => (dispatch) =>
  Delete(physicalDel_PATH, data).then((res) => res)
//条形码
export const getBarcode = (data) => (dispatch) =>
  Get(Barcode_PATH, data).then((res) => res)
// Old_System_Data_PATH

export const oldSystemData = (data) => (dispatch) =>
  Get(Old_System_Data_PATH, data).then((res) => res)
//  报告打印
export const generateReport = (data) => (dispatch) =>
  Post(generateReport_Path, data).then((res) => res)
// export const doctorAll_PATH = PREFIX_FANDIAN +"/health/doctor/all";
// export const doctorSave_PATH = PREFIX_FANDIAN +"/health/doctor/save";
// export const doctorUpdate_PATH = PREFIX_FANDIAN+"/health/doctor/batchRemove";
// export const doctorList_PATH = PREFIX_FANDIAN+"/health/doctor/list";
// export const doctorDelete_PATH = PREFIX_FANDIAN+"/health/doctor/batchRemove";
export const doctorAll = (data) => (dispatch) =>
  Get(doctorAll_PATH, data).then((res) => res)
export const doctorSave = (data) => (dispatch) =>
  Post(doctorSave_PATH, data).then((res) => res)
export const doctorUpdate = (data) => (dispatch) =>
  Put(doctorUpdate_PATH, data).then((res) => res)
export const doctorDelete = (data) => (dispatch) =>
  Delete(doctorDelete_PATH, data).then((res) => res)

export const imageUpload = (data) => (dispatch) =>
  Post(imageUpload_PATH, data).then((res) => res)

// export const detectionMachine_Save_PATH =PREFIX_FANDIAN+"/health/ncovDetectionMachine/save";
// export const detectionMachine_Update_PATH=PREFIX_FANDIAN +"/health/ncovDetectionMachine/update";
// export const detectionMachine_List_PATH = PREFIX_FANDIAN+"/health/ncovDetectionMachine/list";
// export const detectionMachine_Delete_PATH =PREFIX_FANDIAN+"/health/ncovDetectionMachine/batchRemove";

export const detectionMachineSave = (data) => (dispatch) =>
  Post(detectionMachine_Save_PATH, data).then((res) => res)

export const detectionMachineUpdate = (data) => (dispatch) =>
  Put(detectionMachine_Update_PATH, data).then((res) => res)

export const detectionMachineDelete = (data) => (dispatch) =>
  Delete(detectionMachine_Delete_PATH, data).then((res) => res)

export const detectionMachineList = (data) => (dispatch) =>
  Get(detectionMachine_List_PATH, data).then((res) => res)
export const setMachine = (data) => (dispatch) =>
  Post(setMachine_PATH, data).then((res) => res)
export const scaleList = (data) => (dispatch) =>
  Get(scale_List_PATH, data).then((res) => res)
export const itemsExport = (data) => (dispatch) =>
  PostDown(items_export_PATH, data).then((res) => res)
export const exportNcovUser = (data) => (dispatch) =>
  PostDown(exportNcovUser_Path, data).then((res) => res)
export const exportNcov = (data) => (dispatch) =>
  PostDown2(exportNcov_Path, data).then((res) => res)
// updateSampleNumber_PATH
export const updateSampleNumber = (data) => (dispatch) =>
  Put(updateSampleNumber_PATH, data).then((res) => res)
export const readExcel = (data) => (dispatch) =>
  Post(readExcel_Path, data).then((res) => res)
// export const resetPwd_PATH = PREFIX_FANDIAN +"/health/organization/resetPwd";//重置密码
// export const modules_PATH = PREFIX_FANDIAN +"/health/logs/modules";//操作模块
// export const businessTypes_PATH = PREFIX_FANDIAN +"/health/logs/businessType";//业务类型
// export const logList_PATH = PREFIX_FANDIAN +"/health/logs/list";//日志列表
export const resetPwd = (data) => (dispatch) =>
  Post(resetPwd_PATH, data).then((res) => res)
export const logmodules = (data) => (dispath) =>
  Get(modules_PATH, data).then((res) => res)
export const businessType = (data) => (dispatch) =>
  Get(businessTypes_PATH, data).then((res) => res)
export const loglist = (data) => (dispatch) =>
  Get(logList_PATH, data).then((res) => res)

export const getAppointmentInfoList = (data) => (dispatch) =>
  Get(AppointmentInfo_PATH, data).then((res) => res)
export const appointmentCancel = (data) => (dispatch) =>
  Delete(appointmentCancel_PATH, data).then((res) => res)
export const barcodeRuleSet = (data) => (dispatch) =>
  Put(barcodeRuleSet_PATH, data).then((res) => res)
// AppointmentList_PATH
export const orgAppointmentList = (data) => (dispatch) =>
  Get(AppointmentList_PATH, data).then((res) => res)
export const orgAppointmentExport = (data) => (dispatch) =>
  PostDown(AppointmentExport_Path, data).then((res) => res)
export const getBarcodeRule = (data) => (dispatch) =>
  Get(getBarcodeRule_PATH, data).then((res) => res)

// org_PhycicalTypes_PATH
export const orgPhycicalTypes = (data) => (dispatch) =>
  Get(org_PhycicalTypes_PATH, data).then((res) => res)
// valid_PhysicalRecord_PATH
export const validPhysicalRecord = (data) => (dispatch) =>
  Post(valid_PhysicalRecord_PATH, data).then((res) => res)
//慢性病预约
export const getUserGroupNumber = (data) => (dispatch) =>
  Get(userGroupNumber_PATH, data).then((res) => res)
// organization_Statistics_PATH
// 机构统计人数
export const organizationStatistics = (data) => (dispatch) =>
  Get(organization_Statistics_PATH, data).then((res) => res)
export const transferPhysicalData = (data) => (dispatch) =>
  Post(transferPhysicalData_PATH, data).then((res) => res)

export const excelGeneratePdf = (data) => (dispatch) =>
  Post(excelGeneratePdf_PATH, data).then((res) => res)

export const itemRegistration = (data) => (dispatch) =>
  Post(itemRegistration_PATH, data).then((res) => res)

export const updateItemCheckNum = (data) => (dispatch) =>
  Put(updateItemCheckNum_PATH, data).then((res) => res)
export const getItemInfo = (data) => (dispatch) =>
  Get(getItemInfo_PATH, data).then((res) => res)
export const setReportEnvItem = (data) => (dispatch) =>
  Post(setReportEnv_item_Path, data).then((res) => res)
export const setMachineItem = (data) => (dispatch) =>
  Post(setMachine_item_Path, data).then((res) => res)
export const generateItemPdf = (data) => (dispatch) =>
  Post(generateItemPdf_Path, data).then((res) => res)
export const getDcmInfolIST = (data) => (dispatch) =>
  Get(dcmInfo_LIST_Path, data).then((res) => res)
export const uploadDCM = (data) => (dispatch) =>
  PostForm(uploadDCM_Path, data).then((res) => res)
export const updateDCM = (data) => (dispatch) =>
  Put(updateDCM_Path, data).then((res) => res)
export const batchSetItemResult = (data) => (dispatch) =>
  PostForm(batchSetItemResult_Path, data).then((res) => res)
export const newUploadFilePath = (data) => (dispatch) =>
  PostForm(newUploadFilePath_PATH, data).then((res) => res)
export const exportAppointGroup = (data) => (dispatch) =>
  Post(export_AppointGroup_PATH, data).then((res) => res)
export const exportParseExcel = (data) => (dispatch) =>
  PostDown(export_parseExcel_PATH, data).then((res) => res)

export const exportHsExcel = (data) => (dispatch) =>
  PostDown(exportHsExcel_Path, data).then((res) => res)

export const itemRegistrationDelete = (data) => (dispatch) =>
  Delete(itemRegistration_delete_PATH, data).then((res) => res)
export const wxSendCode = (data) => (dispatch) =>
  PostForm(wx_sendCode_PATH, data).then((res) => res)
export const getTwoCancerToken = (data) => (dispatch) =>
  Get(getToken_cancer_PATH, data).then((res) => res)
export const getTwoCancerRecord = (data, twoToken) => (dispatch) =>
  PostTwoCancer(getRecord_TWOCANCER_PATH, data, twoToken).then((res) => res)

export const getTwoCancerIsHave = (data) => (dispatch) =>
  Get(twoCancer_IsHave_PATH, data).then((res) => res)

export const wxGetPDF = (data) => (dispatch) =>
  Get(wx_official_pdf_PATH, data).then((res) => res)
export const impotAppointmentUser = (data) => (dispatch) =>
  Post(impot_appointmentUser_PATH, data).then((res) => res)

// export const getRootAreas = (data) => (dispatch) =>
//   Get(get_root_area_PATH, data).then((res) => res)
// export const getChildAreas = (data) => (dispatch) =>
//   Get(get_child_area_PATH, data).then((res) => res)
export const shortLinkSave = (data) => (dispatch) =>
  Post(shortLink_save_PATH, data).then((res) => res)
export const shortLinkList = (data) => (dispatch) =>
  Get(shortLink_list_PATH, data).then((res) => res)
export const batchUpdateDoctor = (data) => (dispatch) =>
  Put(batch_update_doctor_PATH, data).then((res) => res)
// 胸片医生
// 胸片词条
// export const dcm_suggest_save_PATH = PREFIX_FANDIAN +"/health/dcmSuggest/save"
export const dcmSuggestSave = (data) => (dispatch) =>
  Post(dcm_suggest_save_PATH, data).then((res) => res)
export const dcmSuggestUpdate = (data) => (dispatch) =>
  Put(dcm_suggest_update_PATH, data).then((res) => res)
export const dcmSuggestList = (data) => (dispatch) =>
  Get(dcm_suggest_list_PATH, data).then((res) => res)
export const dcmSuggestDel = (data) => (dispatch) =>
  Delete(dcm_suggest_del_PATH, data).then((res) => res)
export const generateDcmReport = (data) => (dispatch) =>
  Post(generate_dcm_report_PATH, data).then((res) => res)

// export const dcm_doctor_update_PATH=PREFIX_FANDIAN+"/health/dcmDoctor/update";
// export const dcm_doctor_list_PATH=PREFIX_FANDIAN+"/health/dcmDoctor/list";
// export const dcm_doctor_del_PATH=PREFIX_FANDIAN+"/health/dcmDoctor/remove";
// export const dcm_upload_file_PATH = PREFIX_FANDIAN2+"/qiniu/uploadDcmFile";
export const dcmDoctorSave = (data) => (dispatch) =>
  Post(dcm_doctor_save_PATH, data).then((res) => res)
export const dcmDoctorList = (data) => (dispatch) =>
  Get(dcm_doctor_list_PATH, data).then((res) => res)
export const dcmDoctorUpdate = (data) => (dispatch) =>
  Put(dcm_doctor_update_PATH, data).then((res) => res)
export const dcmDoctorDelete = (data) => (dispatch) =>
  Delete(dcm_doctor_del_PATH, data).then((res) => res)
export const dcmUploadFile = (data) => (dispatch) =>
  Upload(dcm_upload_file_PATH, data).then((res) => res)
export const dcmResultImport = (data) => (dispatch) =>
  Post(dcm_result_import_PATH, data).then((res) => res) // dcm_result_import_PATH
// export const healthDeclare_Entry_PATH =  PREFIX_FANDIAN2+"/health/healthDeclare/entry";
// export const healthDeclare_Detail_PATH =  PREFIX_FANDIAN2+ "/health/healthDeclare/detail";
export const healthDeclareEntry = (data) => (dispatch) =>
  Post(healthDeclare_Entry_PATH, data).then((res) => res)
export const healthDeclareDetail = (data) => (dispatch) =>
  Get(healthDeclare_Detail_PATH, data).then((res) => res)
export const healthDeclareDelete = (data) => (dispatch) =>
  Delete(healthDeclare_Delete_PATH, data).then((res) => res)
export const fubaosuogetData = (data) => (dispatch) =>
  Post(fubaosuo_getdata_PATH, data).then((res) => res)
export const dcmDelete = (data) => (dispatch) =>
  Delete(dcm_delete_PATH, data).then((res) => res)
export const dcmUpdate = (data) => (dispatch) =>
  Put(dcm_update_PATH, data).then((res) => res)
export const pissResultupdate = (data) => (dispatch) =>
  Put(pissResultupdate_PATH, data).then((res) => res)
export const dcmExport = (data) => (dispatch) =>
  PostDown(dcm_export_PATH, data).then((res) => res)
export const reportList = (data) => (dispatch) =>
  Get(reportList_PATH, data).then((res) => res)
export const reportDownLoad = (data) => (dispatch) =>
  Get(reportDownLoad_PATH, data).then((res) => res)
/**

stackSave,stackList,stackUpdate,stackDelete
 */
export const stackSave = (data) => (dispatch) =>
  Post(stack_save_PATH, data).then((res) => res)
export const stackList = (data) => (dispatch) =>
  Get(stack_list_PATH, data).then((res) => res)
export const stackUpdate = (data) => (dispatch) =>
  Put(stack_update_PATH, data).then((res) => res)
export const stackDelete = (data) => (dispatch) =>
  Delete(stack_del_PATH, data).then((res) => res)

export const userBindStack = (data) => (dispatch) =>
  Get(userBindStack_PATH, data).then((res) => res)

export const userStackDel = (data) => (dispatch) =>
  Delete(UserStackDel_PATH, data).then((res) => res)
export const userStackExport = (data) => (dispatch) =>
  PostDown(userStackExport_PATH, data).then((res) => res)
export const userImportStack = (data) => (dispatch) =>
  Post(userImportStack_PATH, data).then((res) => res)

export const bmodelInfo = (data) => (dispatch) =>
  Get(bmodelInfo_PATH, data).then((res) => res)
export const appointmentUser = (data) => (dispatch) =>
  Get(appointmentUser_PATH, data).then((res) => res)
export const ecgInfo = (data) => (dispatch) =>
  Get(ecgInfo_PATH, data).then((res) => res)
export const pissResult = (data) => (dispatch) =>
  Get(pissResult_PATH, data).then((res) => res)
export const exportBmodel = (data) => (dispatch) =>
  PostDown(exportBmodel_PATH, data).then((res) => res)
export const exportEcg = (data) => (dispatch) =>
  PostDown(exportEcg_PATH, data).then((res) => res)
export const libangEcgexportEcg = (data) => (dispatch) =>
  PostDown(libangEcgexportEcg_PATH, data).then((res) => res)
export const pissResultimportExcel = (data) => (dispatch) =>
  PostDown(pissResultimportExcel_PATH, data).then((res) => res)

// export const deleteEcg_PATH = PREFIX_FANDIAN + '/health/ecgInfo/batchRemove'
// export const deleteB_PATH = PREFIX_FANDIAN + '/health/bmodelInfo/batchRemove'
export const deleteEcg = (data) => (dispatch) =>
  Delete(deleteEcg_PATH, data).then((res) => res)
export const deleteBchao = (data) => (dispatch) =>
  Delete(deleteB_PATH, data).then((res) => res)
export const physicalRecord_remove = (data) => (dispatch) =>
  Delete(physicalRecord_remove_Path, data).then((res) => res)
export const pissResultremove = (data) => (dispatch) =>
  Delete(pissResultremove_PATH, data).then((res) => res)
export const dcmSuggestimportExcel = (data) => (dispatch) =>
  Post(dcmSuggestimportExcel_PATH, data).then((res) => res)
export const sendApplySampleInfo = (data) => (dispatch) =>
  Post(sendApplySampleInfo_PATH, data).then((res) => res)
export const jointGongWei = (data) => (dispatch) =>
  Post(jointGongWei_PATH, data).then((res) => res)
export const cancelApplyForm = (data) => (dispatch) =>
  Post(cancelApplyForm_PATH, data).then((res) => res)
export const forPay = (data) => (dispatch) =>
  Post(forPay_PATH, data).then((res) => res)
//
export const libangEcgList = (data) => (dispatch) =>
  Get(libangEcgList_PATH, data).then((res) => res)
export const libangEcgsave = (data) => (dispatch) =>
  Post(libangEcgsave_PATH, data).then((res) => res)
export const settingReportPath = (data) => (dispatch) =>
  Post(settingReportPath_PATH, data).then((res) => res)
export const sampleInfoinsert = (data) => (dispatch) =>
  Post(sampleInfoinsert_PATH, data).then((res) => res)
export const libangEcgupdate = (data) => (dispatch) =>
  Put(libangEcgupdate_PATH, data).then((res) => res)
export const libangEcgremove = (data) => (dispatch) =>
  Delete(libangEcgremove_PATH, data).then((res) => res)
export const mealRemove = (data) => (dispatch) =>
  Delete(mealRemove_PATH, data).then((res) => res)
export const mealSave = (data) => (dispatch) =>
  Post(mealSave_PATH, data).then((res) => res)
export const mealList = (data) => (dispatch) =>
  Get(mealList_PATH, data).then((res) => res)
// ====
export const mealClassifyRemove = (data) => (dispatch) =>
  Delete(mealClassifyRemove_PATH, data).then((res) => res)
export const mealClassifySave = (data) => (dispatch) =>
  Post(mealClassifySave_PATH, data).then((res) => res)
export const mealClassifyList = (data) => (dispatch) =>
  Get(mealClassifyList_PATH, data).then((res) => res)
// 
export const generateSign = (data) => (dispatch) =>
  Get(generateSign_PATH, data).then((res) => res)
export const jscode2session = (data) => (dispatch) =>
  Get(jscode2session_PATH, data).then((res) => res)
export const appointmentParamList = (data) => (dispatch) =>
  Get(appointmentParamList_PATH, data).then((res) => res)
export const appointmentParamSave = (data) => (dispatch) =>
  Post(appointmentParamSave_PATH, data).then((res) => res)
export const appointmentParamRangeList = (data) => (dispatch) =>
  Get(appointmentParamRangeList_PATH, data).then((res) => res)
export const appointmentParamRangeSave = (data) => (dispatch) =>
  Post(appointmentParamRangeSave_PATH, data).then((res) => res)
export const appointmentParamRangeUpdate = (data) => (dispatch) =>
  Put(appointmentParamRangeUpdate_PATH, data).then((res) => res)
export const appointmentParamRangeRemove = (data) => (dispatch) =>
  Delete(appointmentParamRangeRemove_PATH, data).then((res) => res)