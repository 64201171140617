import React, { Component } from 'react'
import {
  Modal,
  Button,
  Table,
  Divider,
  Input,
  message,
  Radio,
  Select,
  Spin,
  InputNumber,
} from 'antd'
import '../medicalRecord/RegistrationPage.css'
import {
  organizationPhysicalTypeUpdate,
  organizationPhysicalTypeRemove,
  organizationPhysicalTypeSave,
  addPhysicalType,
  delPhysicalType,
  getPhysicalTypeList,
  updatePhysicalType,
  organizationPhysicalType,
  getAllorganization,
  barcodeRuleSet,
  getBarcodeRule,
} from '../../../actions/account'

// import _ from 'lodash'
import { connect } from 'react-redux'
const { Option } = Select
const { Column } = Table
const { confirm } = Modal

class MachineManage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isDate: 0,
      spinning: false,
      isAppointment: null,
      alreadyDataList: [],
      btnsState: 'all',
      newPhysicalTypeName: '',
      physicalTypeName: '',
      size: 10,
      dataList: [],
      curRecord: undefined,
      visible: false,
      page: 1,
      total: 0,
      isRequesting: false,
      bindVisble: false,
      bindphyVisble: false,
      orgList: [],
      organizationId: '',
      physicalType: '',
      barcoderuleVisble: false,
      barcodeNumber: 12,
      barcodePre: undefined,
      currbarcodeNum: 0,
      repeatMark: 0,
      repeatNum: 0,
      // =====
      curRecordStr: undefined,
      barcodeNumberStr: 12,
      barcodePreStr: undefined,
      currbarcodeNumStr: 0,
      isDateStr: 0,
      repeatMarkStr: 0,
      repeatNumStr: 0,
      organizationIdld: '',
      physicalTypeld: '',
    }
  }

  componentDidMount() {
    var that = this
    this.props.getAllorganization().then((msg) => {
      if (msg.data.code === 200) {
        that.setState({
          orgList: msg.data.data,
        })
      } else {
        message.error(msg.data.message)
      }
    })
    // var that = this;
    // that.props.organizationAllList().then(msg=>{
    //     if(msg.data.code=== 200){
    //         that.setState({
    //             organList:msg.data.data
    //         })
    //     }
    // })

    this.loadDatas()
  }

  loadDatas = () => {
    var that = this
    this.setState({ spinning: true })
    this.props.getPhysicalTypeList().then((msg) => {
      console.log(msg)
      if (msg.data.code === 200) {
        that.setState({
          dataList: msg.data.data,
          spinning: false,
        })
      }
    })
  }
  loadDatas2 = () => {
    var that = this
    var { page, size } = this.state
    this.setState({ spinning: true })

    var obj = { page: page - 1, size: size }
    this.props.organizationPhysicalType(obj).then(function (res) {
      console.log(res)
      if (res.data.code === 200) {
        if (res.data.data.rows.length === 0 && res.data.data.total !== 0) {
          that.setState({ page: page - 1 }, () => {
            that.loadDatas2()
          })
        } else {
          that.setState({
            alreadyDataList: res.data.data.rows,
            btnsState: 'already',
            total: res.data.data.total,
            spinning: false,
          })
        }
      } else {
        message.error(res.data.message)
        that.setState({ btnsState: 'already' })
      }
    })
  }
  showModal = () => {
    this.setState({
      curRecord: undefined,
      visible: true,
    })
  }

  handleCancel = () => {
    this.setState({ visible: false, physicalTypeName: '' })
  }
  handleBindCancel = () => {
    this.setState({
      bindVisble: false,
      newPhysicalTypeName: '',
      curRecord: undefined,
    })
  }
  physicalTypeChange = (e) => {
    this.setState({
      physicalTypeName: e.target.value,
    })
  }
  newphysicalTypeChange = (e) => {
    this.setState({
      newPhysicalTypeName: e.target.value,
    })
  }
  handleBindOk = () => {
    var { newPhysicalTypeName, isRequesting, curRecord } = this.state
    if (isRequesting) {
      return
    }
    var that = this
    this.setState({ isRequesting: true }, () => {
      that.props
        .updatePhysicalType({
          physicalType: newPhysicalTypeName,
          id: curRecord.id,
        })
        .then((msg) => {
          console.log(msg)
          if (msg.data.code === 200) {
            that.setState({
              bindVisble: false,
              newPhysicalTypeName: '',
              curRecord: undefined,
            })
            that.loadDatas()
          } else {
            message.error(msg.data.message)
          }
          that.setState({ isRequesting: false })
        })
    })
  }
  handleOk = () => {
    var { physicalTypeName, isRequesting } = this.state
    if (isRequesting) {
      return
    }
    var that = this
    this.setState({ isRequesting: true }, () => {
      that.props
        .addPhysicalType({ physicalType: physicalTypeName })
        .then((msg) => {
          console.log(msg)
          if (msg.data.code === 200) {
            that.setState({ visible: false, physicalTypeName: '' })
            that.loadDatas()
          } else {
            message.error(msg.data.message)
          }
          that.setState({ isRequesting: false })
        })
    })
  }
  editItem = (record) => {
    console.log(record)
    this.setState({
      curRecord: record,
      newPhysicalTypeName: record.physicalType,
      bindVisble: true,
    })
  }
  editAlreadyRecord = (record) => {
    console.log(record)
    this.setState({
      curRecord: record,
      organizationId: record.organizationId,
      physicalType: record.physicalType,
      organizationIdld: JSON.parse(JSON.stringify(record.organizationId)),
      physicalTypeld: JSON.parse(JSON.stringify(record.physicalType)),
      isAppointment:
        record.isAppointment != null ? record.isAppointment.toString() : '',
      updatephyVisble: true,
    })
  }
  organizationOnChange = (value) => {
    this.setState({ organizationId: value })
  }
  phyOnChange = (value) => {
    this.setState({ physicalType: value })
  }
  isAppointmentOnChange = (value) => {
    this.setState({ isAppointment: value })
  }
  delItem = (id) => {
    var that = this
    console.log([id])
    confirm({
      content: '确认删除？',
      onOk() {
        that.props.delPhysicalType({ ids: id }).then((msg) => {
          if (msg.data.code === 200) {
            message.success('删除成功！')
            that.loadDatas()
          }
        })
      },
    })
  }
  delAlreadyRecord = (id) => {
    var that = this
    console.log([id])
    confirm({
      content: '确认删除？',
      onOk() {
        that.props.organizationPhysicalTypeRemove({ id: id }).then((msg) => {
          if (msg.data.code === 200) {
            message.success('删除成功！')
            that.loadDatas2()
          }
        })
      },
    })
  }
  handleSizeChange = (e) => {
    // var { dataList, alreadyDataList } = this.state;
    if (e.target.value === 'all') {
      this.loadDatas()
      this.setState({ btnsState: 'all' })
    }
    if (e.target.value === 'already') {
      this.loadDatas2()
      this.setState({ btnsState: 'already' })
    }
  }
  bandphy = () => {
    var that = this
    that.setState({
      bindphyVisble: true,
    })
  }
  handleBindphyOk = () => {
    var { isRequesting, organizationId, physicalType } = this.state
    if (isRequesting) {
      return
    }
    var that = this
    this.setState({ isRequesting: false }, () => {
      that.props
        .organizationPhysicalTypeSave({
          physicalType: Number(physicalType),
          organizationId: organizationId.toString(),
        })
        .then((msg) => {
          if (msg.data.code === 200) {
            message.success('绑定成功！')
            that.handleBindphyCancel()
            that.loadDatas2()
          } else {
            message.error(msg.data.message)
          }
          that.setState({ isRequesting: false })
        })
    })
  }
  UpdateBindphyOk = () => {
    var {
      isRequesting,
      organizationId,
      physicalType,
      curRecord,
      isAppointment,
      organizationIdld,
      physicalTypeld,

    } = this.state
    if (isRequesting) {
      return
    }
    let obj = {
      id: curRecord.id,
      isAppointment: Number(isAppointment)
    }
    if (physicalTypeld != physicalType) {
      obj.physicalType = physicalType
    }
    if (organizationIdld != organizationId) {
      obj.organizationId = organizationId
    }
    var that = this
    this.setState({ isRequesting: false }, () => {
      that.props
        .organizationPhysicalTypeUpdate(obj)
        .then((msg) => {
          if (msg.data.code === 200) {
            message.success('修改成功！')
            that.loadDatas2()
            that.UpdateBindphyCancel()
          } else {
            message.error(msg.data.message)
          }
          that.setState({ isRequesting: false })
        })
    })
  }

  UpdateBindphyCancel = () => {
    this.setState({
      isAppointment: null,
      updatephyVisble: false,
      curRecord: undefined,
      physicalType: '',
      organizationId: '',
      organizationIdld: '',
      physicalTypeld: '',
    })
  }
  handleBindphyCancel = () => {
    this.setState({
      bindphyVisble: false,
      physicalType: '',
      organizationId: '',
      organizationIdld: '',
      physicalTypeld: '',
      machineId: undefined,
    })
  }
  changePage = (page) => {
    var that = this
    this.setState({ page }, () => {
      that.loadDatas2()
    })
  }

  barcodeSet = (record) => {
    // 请求接口，获取他的条码号规则

    this.setState({
      barcoderuleVisble: true,
      curRecord: record,
      curRecordStr: JSON.parse(JSON.stringify(record)),
    })
    var that = this
    that.props
      .getBarcodeRule({
        organizationId: record.organizationId,
        physicalType: record.physicalType,
      })
      .then((res) => {
        if (res.data.code === 200) {
          if (res.data.data.barcodeNumber !== null) {
            that.setState({
              barcodeNumber: res.data.data.barcodeNumber,
              barcodeNumberStr: JSON.parse(
                JSON.stringify(res.data.data.barcodeNumber)
              ),
            })
          }
          if (res.data.data.barcodePre !== null) {
            that.setState({
              barcodePre: res.data.data.barcodePre,
              barcodePreStr: JSON.parse(
                JSON.stringify(res.data.data.barcodePre)
              ),
            })
          }
          if (res.data.data.currentVal !== null) {
            that.setState({
              currbarcodeNum: res.data.data.currentVal,
              currbarcodeNumStr: JSON.parse(
                JSON.stringify(res.data.data.currentVal)
              ),
            })
          }
          that.setState({
            isDate: res.data.data.isDate,
            repeatMark: res.data.data.unit ? res.data.data.unit : 0,
            repeatNum: res.data.data.unit ? res.data.data.number : undefined,
            isDateStr: JSON.parse(JSON.stringify(res.data.data.isDate)),

            repeatMarkStr: JSON.parse(
              JSON.stringify(res.data.data.unit ? res.data.data.unit : 0)
            ),
            repeatNumStr: res.data.data.unit
              ? JSON.parse(
                JSON.stringify(
                  res.data.data.unit ? res.data.data.number : undefined
                )
              )
              : undefined,
          })
        } else {
          message.error(res.data.message)
        }
      })
  }
  barcodesetOnChange = (e, mark) => {
    if (mark === 1) {
      this.setState({
        barcodePre: e.target.value,
      })
    } else if (mark === 2) {
      this.setState({
        barcodeNumber: e,
      })
    } else if (mark === 3) {
      this.setState({
        currbarcodeNum: e,
      })
    } else if (mark === 4) {
      this.setState({
        repeatNum: e,
      })
    }
  }

  barcodeSetOk = () => {
    var {
      barcodePre,
      barcodeNumber,
      currbarcodeNum,
      curRecord,
      repeatMark,
      repeatNum,
      isDate,
      barcodePreStr,
      barcodeNumberStr,
      currbarcodeNumStr,
      curRecordStr,
      repeatMarkStr,
      repeatNumStr,
      isDateStr,
    } = this.state
    var that = this
    if (barcodePre === undefined || barcodePre === '') {
      message.error('请完成表单填写！')
      return
    }
    var obj = {
      id: curRecord.id,
    }
    if (curRecord.organizationId != curRecordStr.organizationId) {
      obj.organizationId = curRecord.organizationId
    }
    if (curRecord.physicalType != curRecordStr.physicalType) {
      obj.physicalType = curRecord.physicalType
    }
    if (barcodePre != barcodePreStr) {
      obj.barcodePre = barcodePre
    }
    // if (barcodeNumber != barcodeNumberStr) {
    //   obj.barcodeNumber = barcodeNumber
    // }
    obj.barcodeNumber = barcodeNumber
    if (currbarcodeNum != currbarcodeNumStr) {
      obj.currentVal = currbarcodeNum
    }
    if (isDate != isDateStr) {
      obj.isDate = isDate
    }

    if (repeatMark != repeatMarkStr) {
      obj.unit = repeatMark
    }
    if (repeatNum != repeatNumStr) {
      obj.number = repeatNum
    }

    that.props.barcodeRuleSet(obj).then((res) => {
      if (res.data.code === 200) {
        message.success('条码设置完成！')
        that.barcodeSetCancel()
      }
    })
  }

  barcodeSetCancel = () => {
    this.setState({
      curRecord: undefined,
      barcoderuleVisble: false,
      barcodePre: undefined,
      barcodeNumber: 12,
      currbarcodeNum: 0,
      repeatMark: 0,
      repeatNum: 0,
      isDate: 0,
      curRecordStr: undefined,
      barcodeNumberStr: 12,
      barcodePreStr: undefined,
      currbarcodeNumStr: 0,
      isDateStr: 0,
      repeatMarkStr: 0,
      repeatNumStr: 0,
    })
  }

  repeatOnChange = (value) => {
    this.setState({
      repeatMark: value,
    })
  }
  isDateOnChange = (e) => {
    console.log(e)
    if (e.target.value === 1) {
      this.setState({ currbarcodeNum: 0 })
    }
    this.setState({ isDate: e.target.value })
  }
  render() {
    const {
      barcoderuleVisble,
      barcodePre,
      barcodeNumber,
      currbarcodeNum,
      isAppointment,
      spinning,
      updatephyVisble,
      physicalType,
      orgList,
      btnsState,
      organizationId,
      dataList,
      physicalTypeName,
      bindVisble,
      repeatMark,
      repeatNum,
      bindphyVisble,
      newPhysicalTypeName,
      alreadyDataList,
    } = this.state
    return (
      <div>
        <div className="StatisticsPage-head pageHeader">
          <h3>体检类型管理</h3>
        </div>
        <div className="StatisticsPage-box">
          <div className="StatisticsPage-search">
            <div className="StatisticsPage-btn">
              <Button className="btn" type="primary" onClick={this.showModal}>
                添加
              </Button>
              <Button className="btn" type="primary" onClick={this.bandphy}>
                绑定机构
              </Button>

              <Radio.Group value={btnsState} onChange={this.handleSizeChange}>
                <Radio.Button value="all">全部</Radio.Button>
                <Radio.Button value="already">已绑定</Radio.Button>
              </Radio.Group>
              <Modal
                title="添加体检类型"
                visible={this.state.visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                okText="保存"
                cancelText="取消"
              >
                <div className="form-group">
                  <label className="form-label">名称：</label>
                  <div className="form-ipt">
                    <Input
                      value={physicalTypeName}
                      placeholder="请输入类型名称"
                      onChange={(e) => this.physicalTypeChange(e)}
                    />
                  </div>
                </div>
              </Modal>
              <Modal
                title="机构-体检类型"
                visible={bindphyVisble}
                onOk={this.handleBindphyOk}
                onCancel={this.handleBindphyCancel}
                okText="保存"
                cancelText="取消"
              >
                <div className="form-group">
                  <label className="form-label">机构：</label>
                  <div className="form-ipt" style={{ marginTop: '5px' }}>
                    <Select
                      style={{ width: '90%' }}
                      value={organizationId}
                      onChange={this.organizationOnChange}
                    >
                      {orgList.map((item) => {
                        return (
                          <Option key={item.organizationId}>
                            {item.organizationName}
                          </Option>
                        )
                      })}
                    </Select>
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-label">体检类型：</label>
                  <div className="form-ipt" style={{ marginTop: '5px' }}>
                    <Select
                      style={{ width: '60%' }}
                      value={physicalType}
                      onChange={this.phyOnChange}
                    >
                      {dataList.map((item) => {
                        return (
                          <Option key={item.id}>{item.physicalType}</Option>
                        )
                      })}
                    </Select>
                  </div>
                </div>
              </Modal>
            </div>
          </div>
          <div>
            {((bool) => {
              if (bool) {
                return (
                  <Table
                    size="middle"
                    dataSource={dataList}
                    bordered
                    rowKey="id"
                  >
                    <Column
                      title="类型名称"
                      dataIndex="physicalType"
                      key="physicalType"
                      align="center"
                    />
                    <Column
                      title="创建时间"
                      dataIndex="createdAt"
                      key="createdAt"
                      align="center"
                    />
                    <Column
                      title="操作"
                      align="center"
                      key="action"
                      render={(text, record) => (
                        <span>
                          <span
                            style={{ cursor: 'pointer' }}
                            onClick={() => this.editItem(record)}
                          >
                            修改
                          </span>
                          <Divider type="vertical" />
                          <span
                            style={{ cursor: 'pointer' }}
                            onClick={() => this.delItem(record.id)}
                          >
                            删除
                          </span>
                        </span>
                      )}
                    />
                  </Table>
                )
              } else {
                return (
                  <Spin tip="Loading..." spinning={spinning}>
                    <div>
                      <Table
                        size="middle"
                        dataSource={alreadyDataList}
                        rowKey="id"
                        pagination={{
                          pageSize: this.state.pageSize,
                          showTotal: (total) => {
                            return `共 ${total} 条`
                          },
                          onChange: this.changePage,
                          total: this.state.total,
                        }}
                      >
                        <Column
                          title="机构名"
                          dataIndex="organizationName"
                          key="organizationName"
                          align="center"
                        />
                        <Column
                          title="类型名称"
                          dataIndex="physicalName"
                          key="physicalName"
                          align="center"
                        />
                        <Column
                          title="能否预约"
                          dataIndex="isAppointment"
                          key="isAppointment"
                          align="center"
                          render={(text, record) => (
                            <span>
                              <span>
                                {record.isAppointment === 1
                                  ? '能'
                                  : record.isAppointment === 0
                                    ? '否'
                                    : '未设置'}
                              </span>
                            </span>
                          )}
                        />
                        <Column
                          title="操作"
                          align="center"
                          key="action"
                          render={(text, record) => (
                            <span>
                              <span
                                style={{ cursor: 'pointer' }}
                                onClick={() => this.barcodeSet(record)}
                              >
                                设置条码规则
                              </span>
                              <Divider type="vertical" />
                              <span
                                style={{ cursor: 'pointer' }}
                                onClick={() => this.editAlreadyRecord(record)}
                              >
                                修改绑定
                              </span>
                              <Divider type="vertical" />
                              <span
                                style={{ cursor: 'pointer' }}
                                onClick={() => this.delAlreadyRecord(record.id)}
                              >
                                删除绑定
                              </span>
                            </span>
                          )}
                        />
                      </Table>
                    </div>
                  </Spin>
                )
              }
            })(btnsState === 'all')}
            <Modal
              title="设置条码规则"
              visible={barcoderuleVisble}
              onOk={this.barcodeSetOk}
              onCancel={this.barcodeSetCancel}
              okText="保存"
              cancelText="取消"
            >
              <div className="form-group">
                <label className="form-label">条码前缀：</label>
                <div className="form-ipt">
                  <Input
                    value={barcodePre}
                    placeholder="请输入条码前缀"
                    onChange={(e) => this.barcodesetOnChange(e, 1)}
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="form-label">条码位数：</label>
                <div className="form-ipt">
                  <InputNumber
                    value={barcodeNumber}
                    placeholder="请输入条码位数"
                    onChange={(e) => this.barcodesetOnChange(e, 2)}
                  />
                </div>
              </div>
              {!this.state.isDate && (
                <div className="form-group">
                  <label className="form-label">条码起始号：</label>
                  <div className="form-ipt">
                    <InputNumber
                      value={currbarcodeNum}
                      placeholder="请输入条码起始号"
                      onChange={(e) => this.barcodesetOnChange(e, 3)}
                    />
                  </div>
                </div>
              )}

              <div className="form-group">
                <label className="form-label">设置重复参数：</label>
                <div className="form-ipt">
                  <Select
                    width={200}
                    value={repeatMark}
                    onChange={this.repeatOnChange}
                  >
                    <Option value={0}>不设置</Option>
                    <Option value={1}>年</Option>
                    <Option value={2}>月</Option>
                  </Select>
                </div>
              </div>
              {repeatMark !== 0 && (
                <div className="form-group">
                  <label className="form-label">
                    设置重复间隔({repeatMark === 1 ? '年' : '月'})：
                  </label>
                  <div className="form-ipt">
                    <InputNumber
                      width={200}
                      value={repeatNum}
                      onChange={(e) => this.barcodesetOnChange(e, 4)}
                    />
                  </div>
                </div>
              )}
              <div className="form-group">
                <label className="form-label">是否为日期格式：</label>
                <div className="form-ipt">
                  <Radio.Group
                    onChange={this.isDateOnChange}
                    value={this.state.isDate}
                  >
                    <Radio value={0}>否</Radio>
                    <Radio value={1}>是</Radio>
                  </Radio.Group>
                </div>
              </div>
            </Modal>
            <Modal
              title="修改类型名称"
              visible={bindVisble}
              onOk={this.handleBindOk}
              onCancel={this.handleBindCancel}
              okText="保存"
              cancelText="取消"
            >
              <div className="form-group">
                <label className="form-label">类型名称：</label>
                <div className="form-ipt">
                  <Input
                    value={newPhysicalTypeName}
                    placeholder="请输入类型名称"
                    onChange={(e) => this.newphysicalTypeChange(e)}
                  />
                </div>
              </div>
            </Modal>
            <Modal
              title="机构-体检类型-修改"
              visible={updatephyVisble}
              onOk={this.UpdateBindphyOk}
              onCancel={this.UpdateBindphyCancel}
              okText="保存"
              cancelText="取消"
            >
              <div className="form-group">
                <label className="form-label">机构：</label>
                <div className="form-ipt" style={{ marginTop: '5px' }}>
                  {orgList.length === 0 && this.state.curRecord && (
                    <p>{this.state.curRecord.organizationName}</p>
                  )}
                  {orgList.length > 0 && (
                    <Select
                      style={{ width: '60%' }}
                      value={organizationId}
                      onChange={this.organizationOnChange}
                    >
                      {orgList.map((item) => {
                        return (
                          <Option key={item.organizationId}>
                            {item.organizationName}
                          </Option>
                        )
                      })}
                    </Select>
                  )}
                </div>
              </div>
              <div className="form-group">
                <label className="form-label">体检类型：</label>
                <div className="form-ipt" style={{ marginTop: '5px' }}>
                  <Select
                    style={{ width: '60%' }}
                    value={physicalType.toString()}
                    onChange={this.phyOnChange}
                  >
                    {dataList.map((item) => {
                      return <Option key={item.id}>{item.physicalType}</Option>
                    })}
                  </Select>
                </div>
              </div>
              <div className="form-group">
                <label className="form-label">能否预约：</label>
                <div className="form-ipt" style={{ marginTop: '5px' }}>
                  <Select
                    style={{ width: '60%' }}
                    value={isAppointment}
                    onChange={this.isAppointmentOnChange}
                  >
                    <Option key={1}>能</Option>
                    <Option key={0}>否</Option>
                  </Select>
                </div>
              </div>
            </Modal>
          </div>
        </div>
      </div>
    )
  }
}
export default connect(null, {
  organizationPhysicalTypeUpdate,
  organizationPhysicalTypeRemove,
  organizationPhysicalTypeSave,
  addPhysicalType,
  delPhysicalType,
  getPhysicalTypeList,
  updatePhysicalType,
  organizationPhysicalType,
  getAllorganization,
  barcodeRuleSet,
  getBarcodeRule,
})(MachineManage)
