import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Input, Button, message, DatePicker, Modal, Select ,Spin} from 'antd';
import { getPhysicalRecord, updateInspectionResult, batchSetResult,getOrganizationGuahaoList,updateSampleNumber,batchSetSampleWay } from '../../../actions/account'
import _ from 'lodash'
import './ResultEntering.css'
import { SearchOutlined} from '@ant-design/icons';

const { RangePicker } = DatePicker;
const { Option } = Select;
const dateFormat = 'YYYY-MM-DD hh:mm:ss';
class ResultEntering extends Component {
    constructor(props) {
        super(props);
        this.state = {
            otherSample:'',
            inputState: '1',
            barcode: '',
            result: undefined,
            choosedDates: [],//时间默认值
            startTime: undefined,
            endTime: undefined,
            dateTimeVisble: false,
            setMarK:1,
            allList:[],
            gatherMode:undefined,
            loading:false
        }
    }

    componentDidMount() {

    }
    handleChange = (value) => {
        console.log(`selected ${value}`);
    }
    barcodeChange = e => {
        this.setState({ barcode: e.target.value })
    }

    btnSearch = () => {
        var { barcode ,inputState} = this.state;
        var obj={}
        if(inputState==='1'){
            obj.barcode=barcode
        }
        else{
            obj.sampleCheckNumber=barcode
        }
        var that = this;
        this.props.getPhysicalRecord(obj).then(res => {
            if (res.data.code === 200) {
                if (res.data.data === null) {
                    message.error("该体检类型没有配置体检项！")
                }
                that.setState({
                    result: res.data.data
                })
            }
        })

    }

    checkValOnChange = (e, bigId, smallId) => {
        var { result } = this.state;
        var bigProject = _.find(result.inspectionResult.details, ['projectId', bigId]);
        var smallProject = _.find(bigProject.slaveProject, ['id', smallId]);
        smallProject.result = e.target.value;
        this.setState({ result });
    }

    promptOnChange = (e, bigId, smallId) => {
        var { result } = this.state;
        var bigProject = _.find(result.inspectionResult.details, ['projectId', bigId]);
        var smallProject = _.find(bigProject.slaveProject, ['id', smallId]);
        smallProject.prompt = e.target.value;
        this.setState({ result });
    }
    otherSampleChange=(e)=>{
        this.setState({ otherSample: e.target.value });

    }
    btnClick = () => {
        var { result } = this.state;
        var that = this;
        console.log(result)
        that.props.updateInspectionResult(result).then(res => {
            if (res.data.code === 200) {
                message.success("录入成功！")
                that.setState({
                    barcode: '',
                    result: undefined
                })
            }
        })
    }

    inputStateOnChange = (value) => {
        this.setState({ inputState: value })
    }

    datetimeChange = (date, dateString) => {
        // var choseDate =[];
        // choseDate = _.concat(choseDate,moment(dateString[0]+" 00:00:00","YYYY-MM-DD hh:mm:ss"));
        // choseDate = _.concat(choseDate,moment(dateString[1]+" 23:59:59","YYYY-MM-DD hh:mm:ss"));
        this.setState({
            startTime: dateString[0] === "" ? undefined : dateString[0] + " 00:00:00",
            endTime: dateString[1] === "" ? undefined : dateString[1] + " 23:59:59",
            choosedDates: date,
        })
    }

    batchSetOnClick = (mark) => {
        this.setState({
            dateTimeVisble: true,
            startTime: undefined,
            endTime: undefined,
            choosedDates: [],
            setMarK:mark
        })
    }

    onDateOK = () => {
      //  setmark
        var { startTime, endTime,setMarK,gatherMode ,otherSample} = this.state;
        var that = this;
        if(setMarK===1){
            var formdata = new FormData();
            formdata.append("startTime", startTime);
            formdata.append("endTime", endTime);
            that.props.batchSetResult(formdata).then(res => {
                console.log(res)
                if (res.data.code === 200) {
                    message.success("批量结果设置成功！");
                    that.setState({
                        gatherMode:undefined,
                        dateTimeVisble: false,
                        startTime: undefined,
                        endTime: undefined,
                        choosedDates: []
                    })
                }
            })
        }
        else{
            console.log(startTime,endTime,gatherMode,otherSample)
            var obj={
                startTime:startTime,
                endTime:endTime,
                sampleWay:gatherMode
            }
            if(gatherMode===0){
                obj.other=otherSample
            }
            that.props.batchSetSampleWay(obj).then(res => {
                console.log(res)
                if (res.data.code === 200) {
                    message.success("批量结果设置成功！");
                    that.setState({
                        gatherMode:undefined,
                        otherSample:'',
                        dateTimeVisble: false,
                        startTime: undefined,
                        endTime: undefined,
                        choosedDates: []
                    })
                }
            })
            // batchSetSampleWay
            // //批量设置鼻试纸
            // that.setState({allList:[]},()=>{
            //     that.getGuaHaoList(0,[]);
            // })
          
        }
    }

    updateInspection=(list,i)=>{
        var that=this;
        if(i>=list.length){
            message.success("批量设置采集方式成功！")
            that.setState({
                dateTimeVisble:false,
            loading:false,

            })
            return;
        }
        var barcode = list[i].barcode;
        var obj={
            barcode:barcode,
            sampleWay:this.state.gatherMode
        }
        // var formdata =new FormData();
        // formdata.append('barcode',barcode);
        // formdata.append('sampleWay',1);

        that.props.updateSampleNumber(obj).then(res=>{
            if(res.data.code===200){
                i++;
                that.updateInspection(list,i);
            }
        })

    }

    getGuaHaoList=(page,list)=>{
        var {startTime,endTime,allList}= this.state
        var that = this;
        this.setState({
            loading:true
        })
        if(list.length===0 && page !== 0){
            that.updateInspection(allList,0)
            return;
        }
        else{

            var obj={
                page:page,
                size:10,
                startTime: startTime,
                endTime: endTime
            }
            obj.organizationId = JSON.parse(localStorage.getItem('data')).organizationId;
            // organizationId
            that.props.getOrganizationGuahaoList(obj).then(res=>{
                if(res.data.code===200){
                    page++;
                    that.setState({
                        allList:_.concat(allList,res.data.data.rows)
                    },()=>{
                        that.getGuaHaoList(page,res.data.data.rows)

                    })
                    // list = _.concat(list,)
                }
            })
        }

    }
    // 

    onDateCancel = () => {
        this.setState({
            gatherMode:undefined,
            dateTimeVisble: false,
            startTime: undefined,
            endTime: undefined,
            choosedDates: []
        })
    }

    gatherModeOnChnage=value=>{
        console.log(value)
        this.setState({gatherMode:value})

    }

    render() {
        const { barcode, result, choosedDates, inputState,gatherMode,setMarK,otherSample } = this.state
        return (
            <div>
                <div className="StatisticsPage-head pageHeader"><h3>检验结果录入</h3></div>
                <div className="StatisticsPage-box">
                    <div className="StatisticsPage-search">
                        <Select value={inputState} style={{ width: 120 }} onChange={this.inputStateOnChange} >
                            <Option key="1">条码号</Option>
                            <Option key="2">检验编号</Option>
                        </Select>

                        <Input className="ipt" value={barcode} placeholder="请输入体检码" onChange={e => this.barcodeChange(e)} onPressEnter={this.btnSearch} />
                        <Button type="primary"  icon={<SearchOutlined />} onClick={this.btnSearch} style={{ width: '60px' }}></Button>
                        {/* <Button type="primary" style={{ 'marginLeft': '10px' }} >上传血检结果</Button> */}
                        <Button type="primary" style={{ 'marginLeft': '10px' }} onClick={()=>this.batchSetOnClick(1)} >批量设置结果</Button>
                        <Button type="primary" style={{ 'marginLeft': '10px' }} onClick={()=>this.batchSetOnClick(2)}>批量设置采集方式</Button>
                    </div>
                    {/* {!result&&<div className="StatisticsPage-search">  */}
                    {/* <span>姓名：{result.userBasicInfo.username +"   "}</span>
                    <span>证件号：{result.userBasicInfo.certNumber +"   "}</span>
                    <span>电话：{result.userBasicInfo.phone +"   "}</span> */}
                    {/* </div>} */}
                    {result !== undefined && result !== null && <div>
                        <span>条码号：{barcode + "   "}</span>
                        <div className="tab">
                            {result.inspectionResult !== null && result.inspectionResult.details.map(project => {
                                return <div className="medicalResult-tabs-in" key={"master" + project.projectId}>
                                    <div className="tab-title" >
                                        {project.projectName + "   "}
                                    </div>
                                    {project.slaveProject.length !== 0 && project.slaveProject.map(item => {
                                        return (<div className="spbox result-spbox" key={item.id}>
                                            <div className="spbox-name" style={{ display: "inline-block" }}>{item.projectName}</div>
                                            <div className="spbox-value" style={{ display: "inline-block" }}>
                                                <span style={{ marginRight: "20px" }}>检查值</span>
                                                <Input style={{ width: "100px", marginRight: "50px" }} value={item.result} onChange={(e) => this.checkValOnChange(e, project.projectId, item.id)} />
                                                <span style={{ marginRight: "20px" }}>标志符</span>
                                                <Input style={{ width: "100px", marginRight: "50px" }} value={item.prompt} onChange={(e) => this.promptOnChange(e, project.projectId, item.id)} />

                                                <span style={{ marginRight: "20px" }}>{item.defVal !== null && "正常值：" + item.defVal}</span><span>{item.refVal !== null && "参考值：" + item.refVal}</span>
                                            </div>
                                            <div className="spbox-flag">

                                            </div>
                                            <div className="clear"></div>
                                        </div>)
                                    })}
                                </div>
                            })}
                        </div>
                    </div>}
                    {result !== undefined && result !== null &&
                        <div className="tab-org">
                            <Button type="primary" onClick={this.btnClick}>保存</Button>
                        </div>
                    }
                </div>

                <Modal title="批量设置" visible={this.state.dateTimeVisble}
                    onOk={this.onDateOK}
                    onCancel={this.onDateCancel}>
                         <Spin spinning={this.state.loading} tip="批量设置中，请耐心等待...">
                         <div className="form-group">
                            <label className="form-label">选择日期：</label>
                            <div className="form-ipt">
                            <RangePicker dateFormat={dateFormat} placeholder={['开始时间', '结束时间']} className="date"
                             onChange={this.datetimeChange} value={choosedDates} />
                            </div>
                        </div>
                        {setMarK===2 &&  <div className="form-group">
                            <label className="form-label">选择采样方式：</label>
                            <div className="form-ipt">
                                <Select style={{width:'300px'}} value={gatherMode} onChange={this.gatherModeOnChnage}>
                                    <Option value={1}>鼻</Option>
                                    <Option value={2}>咽</Option>
                                    <Option value={3}>血</Option>
                                    <Option value={4}>肛</Option>
                                    <Option value={0}>其他</Option>
                                </Select>
                            </div>
                        </div>}
                        {gatherMode===0 &&  <div className="form-group">
                            <label className="form-label">其他方式：</label>
                            <div className="form-ipt">
                            <Input  value={otherSample} placeholder="请输入其他采样方式" onChange={(e) => this.otherSampleChange(e)} />

                            </div>
                        </div>}
                   </Spin>
                  
                </Modal>
            </div>
        )
    }
}
export default connect(null, { batchSetSampleWay,getPhysicalRecord, updateInspectionResult, batchSetResult,getOrganizationGuahaoList,updateSampleNumber })(ResultEntering)               