import axios from "axios";
import Toast from "../component/toast/Index";
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.timeout = 30 * 1000 * 60;
const before = function before(config) {
  config.headers = {
    ...config.headers,
    //nonce: "562764451",
    Authorization:localStorage.getItem("cookie") && JSON.parse(localStorage.getItem("cookie")).token
  };
  return config;
};
const beforetwoCancer = function before(config) {
  config.headers = {
    ...config.headers,
    //nonce: "562764451",
    token:JSON.parse(localStorage.getItem("cookie")).liangatoken
  };
  return config;
}; 
const errHandler = function errHandler(error) {
  const tag = !!error;
  if (tag) {
    Toast.error(error.toString());
  }
  return Promise.reject(error);
};

// Add a request interceptor
axios.interceptors.request.use(before, errHandler);

axios.interceptors.response.use(
  response => {
    if (response && response.data.code !== 200) {
      Toast.error(response.data.msg);
    }
    if (response && response.data.code === 606) {
      localStorage.removeItem("cookie");
      window.location = "/account";
    }
    return response;
  },
  error => {
    console.log(error)
    if (error.response) {
      Toast.error(error.response.data.msg);
    } else {
      Toast.error(error.toString());
    }
    return Promise.reject(error);
  }
);

export function Get(url, data = {}) {
  const instance = axios.create();
  instance.interceptors.request.use(before, errHandler);
  instance.interceptors.response.use(response => {
    if (response && response.data.code !== 200&& response.data.code !== "200") {
      console.log("responseErr Data=>", response.data.data)
      // Toast.error(response.data.message);
    }
    if (response && response.data.code === 606) {
      localStorage.removeItem("cookie");
      // window.location = "/";
    }
    if (response && response.data.code === 702) {
      localStorage.removeItem("cookie");
      // window.location = "/";
    }
    return response;
  },
    error => {
      console.log("Err Data=>", error.response)
      if (error.response && error.response.data.code === 702) {
        localStorage.removeItem("cookie");
        Toast.error("登录已失效");
        // window.location = "/";
        return Promise.reject(error);
      }
      if (error.response && error.response.data.code === 702) {
        localStorage.removeItem("cookie");
        Toast.error("登录已失效");
        // window.location = "/";
        return Promise.reject(error);
      }
      if (error.response) {
        Toast.error(error.response.data.message);
      } else {
        Toast.error(error.toString());
      }
      return Promise.reject(error);
    });
  return instance.get(url, { params: data });
}

export function PostTwoCancer(url, data = {},twoToken) {
  const before2 = function before2(config) {
    config.headers = {
      ...config.headers,
      //nonce: "562764451",
      token:twoToken
    };
    return config;
  };
  const instance = axios.create();
  instance.interceptors.request.use(before2, errHandler);
  instance.interceptors.response.use(response => {
    //  if (response && response.data.code !== 200) {
    //    console.log("responseErr Data=>",response.data.data)
    //    Toast.error(response.data.message);
    //  }    
    if (response && response.data.code === 702) {
      localStorage.removeItem("cookie");
      //  window.location = "/";
    }
    return response;
  },
    error => {
      console.log("Err Data=>", error.response)
      if (error.response && error.response.data.code === 702) {
        localStorage.removeItem("cookie");
        Toast.error("登录已失效");
        //  window.location = "/";
        return Promise.reject(error);
      }
      if (error.response) {
        Toast.error(error.response.data.message);
      } else {
        Toast.error(error.toString());
      }
      return Promise.reject(error);
    });
  return instance.post(url, data);
}
export function Post(url, data = {}) {
  const instance = axios.create();
  instance.interceptors.request.use(before, errHandler);
  instance.interceptors.response.use(response => {
    //  if (response && response.data.code !== 200) {
    //    console.log("responseErr Data=>",response.data.data)
    //    Toast.error(response.data.message);
    //  }    
    if (response && response.data.code === 702) {
      localStorage.removeItem("cookie");
      //  window.location = "/";
    }
    return response;
  },
    error => {
      console.log("Err Data=>", error.response)
      if (error.response && error.response.data.code === 702) {
        localStorage.removeItem("cookie");
        Toast.error("登录已失效");
        //  window.location = "/";
        return Promise.reject(error);
      }
      if (error.response) {
        Toast.error(error.response.data.message);
      } else {
        Toast.error(error.toString());
      }
      return Promise.reject(error);
    });
  return instance.post(url, data);
}

export function PostLa(url, data = {}) {
  const instance = axios.create();
  instance.interceptors.request.use(beforetwoCancer, errHandler);
  instance.interceptors.response.use(response => {
    //  if (response && response.data.code !== 200) {
    //    console.log("responseErr Data=>",response.data.data)
    //    Toast.error(response.data.message);
    //  }    
    if (response && response.data.code === 702) {
      localStorage.removeItem("cookie");
      //  window.location = "/";
    }
    return response;
  },
    error => {
      console.log("Err Data=>", error.response)
      if (error.response && error.response.data.code === 702) {
        localStorage.removeItem("cookie");
        Toast.error("登录已失效");
        //  window.location = "/";
        return Promise.reject(error);
      }
      if (error.response) {
        Toast.error(error.response.data.message);
      } else {
        Toast.error(error.toString());
      }
      return Promise.reject(error);
    });
  return instance.post(url, data);
}

export function PostForm(url, data = {}) {
  const instance = axios.create();
  instance.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded";
  console.log(instance.defaults.headers.post)
  instance.interceptors.request.use(before, errHandler);

  instance.interceptors.response.use(response => {
    //  if (response && response.data.code !== 200) {
    //    console.log("responseErr Data=>",response.data.data)
    //    Toast.error(response.data.message);
    //  }    
    if (response && response.data.code === 702) {
      localStorage.removeItem("cookie");
      //  window.location = "/";
    }
    return response;
  },
    error => {
      console.log("Err Data=>", error.response)
      if (error.response && error.response.data.code === 702) {
        localStorage.removeItem("cookie");
        Toast.error("登录已失效");
        //  window.location = "/";
        return Promise.reject(error);
      }
      if (error.response) {
        Toast.error(error.response.data.message);
      } else {
        Toast.error(error.toString());
      }
      return Promise.reject(error);
    });
  return instance.post(url, data);
}
export function PostVerity(url, vtoken, data = {}) {
  //verityToken: vtoken
  const instance = axios.create({ headers: { verityToken: vtoken } });
  instance.interceptors.request.use(before, errHandler);
  instance.interceptors.response.use(response => {
    if (response && response.data.code !== 200) {
      console.log("responseErr Data=>", response.data.data)
      Toast.error(response.data.message);
    }
    if (response && response.data.code === 606) {
      localStorage.removeItem("cookie");
      // window.location = "/";
    }
    return response;
  },
    error => {
      console.log("Err Data=>", error.response)
      if (error.response && error.response.data.code === 702) {
        localStorage.removeItem("cookie");
        Toast.error("登录已失效");
        // window.location = "/";
        return Promise.reject(error);
      }
      if (error.response) {
        Toast.error(error.response.data.message);
      } else {
        Toast.error(error.toString());
      }
      return Promise.reject(error);
    });
  return instance.post(url, data);
}


export function Delete(url, data = {}) {
  const instance = axios.create();
  instance.interceptors.request.use(before, errHandler);
  instance.interceptors.response.use(response => {
    if (response && response.data.code !== 200) {
      console.log("responseErr Data=>",response.data.data)
      Toast.error(response.data.message);
    }  
    if (response && response.data.code === 606) {
      localStorage.removeItem("cookie");
      window.location = "/";
    }
    return response;
  },
  error => {
    console.log("Err Data=>",error.response)
    if (error.response &&error.response.data.code === 702) {
      localStorage.removeItem("cookie");
      Toast.error("登录已失效"); 
      window.location = "/";
      return Promise.reject(error);
    }
    if (error.response) {
      Toast.error(error.response.data.message);
    } else {
      Toast.error(error.toString());
    }
    return Promise.reject(error);
  });
  return instance.delete(url, { params: data});
}

export function Put(url, data = {}) {
  const instance = axios.create();
  instance.interceptors.request.use(before, errHandler);
  instance.interceptors.response.use(response => {
    if (response && response.data.code !== 200) {
      console.log("responseErr Data=>", response.data.data)
      Toast.error(response.data.message);
    }
    if (response && response.data.code === 606) {
      localStorage.removeItem("cookie");
      // window.location = "/";
    }
    return response;
  },
    error => {
      console.log("Err Data=>", error.response)
      if (error.response && error.response.data.code === 702) {
        localStorage.removeItem("cookie");
        Toast.error("登录已失效");
        // window.location = "/";
        return Promise.reject(error);
      }
      if (error.response) {
        Toast.error(error.response.data.message);
      } else {
        Toast.error(error.toString());
      }
      return Promise.reject(error);
    });
  return instance.put(url, data);
}
export function PutVerity(url, vtoken, data = {}) {
  const instance = axios.create({ headers: { verityToken: vtoken } });
  instance.interceptors.request.use(before, errHandler);

  instance.interceptors.response.use(response => {
    if (response && response.data.code !== 200) {
      console.log("responseErr Data=>", response.data.data)
      Toast.error(response.data.message);
    }
    if (response && response.data.code === 606) {
      localStorage.removeItem("cookie");
      // window.location = "/";
    }
    return response;
  },
    error => {
      console.log("Err Data=>", error.response)
      if (error.response && error.response.data.code === 702) {
        localStorage.removeItem("cookie");
        Toast.error("登录已失效");
        // window.location = "/";
        return Promise.reject(error);
      }
      if (error.response) {
        Toast.error(error.response.data.message);
      } else {
        Toast.error(error.toString());
      }
      return Promise.reject(error);
    });
  return instance.put(url, data);
}

export function Upload(url, data, progress) {
  return axios.post(
    url,
    data,
    // {
    //   onUploadProgress: ProgressEvent => {
    //     progress((ProgressEvent.loaded / ProgressEvent.total) * 100);
    //   }
    // });
  );
}


export function UploadVerity(url, vtoken, data, progress) {
  // const instance = axios.create();
  const instance = axios.create({ headers: { verityToken: vtoken } });
  instance.interceptors.request.use(before, errHandler);
  instance.interceptors.response.use(response => {
    if (response && response.data.code !== 200) {
      console.log("responseErr Data=>", response.data.data)
      Toast.error(response.data.message);
    }
    if (response && response.data.code === 606) {
      localStorage.removeItem("cookie");
      // window.location = "/";
    }
    return response;
  },
    error => {
      console.log("Err Data=>", error.response)
      if (error.response && error.response.data.code === 702) {
        localStorage.removeItem("cookie");
        Toast.error("登录已失效");
        // window.location = "/";
        return Promise.reject(error);
      }
      if (error.response) {
        Toast.error(error.response.data.message);
      } else {
        Toast.error(error.toString());
      }
      return Promise.reject(error);
    });
  return instance.post(
    url,
    data,
    {
      onUploadProgress: ProgressEvent => {
        progress((ProgressEvent.loaded / ProgressEvent.total) * 100);
      }
    });
}

export function PostDown(url, data = {}) {
  const instance = axios.create();
  instance.interceptors.request.use(before, errHandler);
  instance.interceptors.response.use(response => {
    console.log(response) 
    

    return response;
  },
    error => {
      console.log("Err Data=>", error.response)

    
      if (error.response && error.response.data.code === 600) {
        localStorage.removeItem("cookie");
        localStorage.removeItem("jwesUserInfo");
        Toast.error("登录已失效");
        // window.location = "/account";
        return Promise.reject(error);
      }
      console.log(error.response)
      if (error.response) {
        Toast.error(error.response.data.message);
      } else {
        Toast.error(error.toString());
      }
      return Promise.reject(error);
    });
  return instance.post(url, data, { responseType: 'blob' });
}
export function PostDown2(url, data = {}) {
  const instance = axios.create();
  instance.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded";
  instance.interceptors.request.use(before, errHandler);
  instance.interceptors.response.use(response => {
    return response;
  },
    error => {
      console.log("Err Data=>", error.response)

    
      if (error.response && error.response.data.code === 600) {
        localStorage.removeItem("cookie");
        localStorage.removeItem("jwesUserInfo");
        Toast.error("登录已失效");
        // window.location = "/account";
        return Promise.reject(error);
      }
      console.log(error.response)
      if (error.response) {
        Toast.error(error.response.data.message);
      } else {
        Toast.error(error.toString());
      }
      return Promise.reject(error);
    });
  return instance.post(url, data, { responseType: 'blob' });
}

