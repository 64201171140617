import React, { Component } from 'react'
import {connect} from 'react-redux'
import './PrintCheckInfo.css'
import moment from 'moment';
import util from '../../actions/util';

class PrintCheckInfo extends Component {

    constructor(props){
        super(props);
        // username="+record.+"&sex="+record.+"&birthDate="+
        // record.+"&barcode="+record.+"&infoCreatedAt="
        // +record.
        let search = this.props.history.location.search;
        var username = util.urlParam("username", search);
        var sex = util.urlParam("sex", search);
        var birthDate = util.urlParam("birthDate", search);
        var barcode = util.urlParam("barcode", search);
        var infoCreatedAt = util.urlParam("infoCreatedAt", search);
        var phone = util.urlParam("phone", search);
        var workUnit = util.urlParam("workUnit", search);
        this.state={
            username:username?username:undefined,
            sex:sex?sex:undefined,
            birthDate:birthDate?birthDate:undefined,
            barcode:barcode?barcode:undefined,
            infoCreatedAt:infoCreatedAt?infoCreatedAt:undefined,
            phone:phone?phone:undefined,
            workUnit:workUnit?workUnit:undefined
        }
    }

    componentDidMount(){
        window.print();
    }


    render() {
        const {username,sex,birthDate,barcode,infoCreatedAt,phone,workUnit} = this.state;
       var sexstr="";
       var age="";
       var nowTime = moment();
       var curYear = nowTime.format("YYYY");
       var curMonth = nowTime.format("MM");
       var curDay = nowTime.format("DD");

      
           if(sex){
               if(sex==="1"){
                   sexstr="男"
               }
               else{
                   sexstr="女"
               }
           }
           if(birthDate){
               var year = parseInt(birthDate.substring(0,4));
               var now_year = parseInt(curYear);
               age = now_year-year;
           }
     
       if(infoCreatedAt){
           var infoCreatedAtnew= moment(infoCreatedAt,"YYYY-MM-DD HH:mm:ss");
           curYear = infoCreatedAtnew.format("YYYY");
           curMonth = infoCreatedAtnew.format("MM");
           curDay = infoCreatedAtnew.format("DD");
       }
       return (
        <div className="table">
        <h3 className="table-h3">江苏省从业人员预防性健康检查用表</h3>
        <div className="table-box">
            <div className="table-date">
                <label className="table-label">体检日期：</label>
                <span className="table-span"><b>{curYear}</b>年<b>{curMonth}</b>月<b>{curDay}</b>日</span>
            </div>
            <div className="table-date">
                <label className="table-label">编号：</label>
                <span className="table-span">
                    <span className="bod">
                        {barcode&&barcode}
                    </span>
                </span>
            </div>
        </div>
        <div className="tabel-div">
            <table className="tabel-tab">
                <tbody>
                    <tr>
                        <td colSpan={10}>
                            <table style={{width:"100%"}}>
                                <tbody>
                                    <tr>
                                        <td width="90px" className="table-td">姓名:</td>
                                        <td className="table-td"><font className="tabel-font">{username&&username}</font></td>
                                        <td width="50px" className="table-td">性别:</td>
                                        <td className="table-td"><font className="tabel-font">{sexstr}</font></td>
                                        <td width="65px" className="table-td">年龄:</td>
                                        <td className="table-td"><font className="tabel-font">{age}</font></td>
                                        <td width="90px" className="table-td">文化程度:</td>
                                        <td className="table-td"><font className="tabel-font">{" "}</font></td>
                                    </tr>
                                    <tr>
                                        <td width="90px"  className="table-tb">联系电话:</td>
                                        <td className="table-tb" colSpan={3}><font className="font1">{phone!==undefined&&phone!=='null'&&phone}</font></td>
                                        <td className="table-tb">单位:</td>
                                        <td className="table-tb" colSpan={3}><font className="font2">{workUnit!==undefined&&workUnit!=='null'&&workUnit}</font></td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan={2} className="table-op" align="center" width="50"><br />即往<br /><br />病史<br /><br /></td>
                        <td className="table-op" align="center" colSpan={2} width="134">病名</td>
                        <td className="table-op" align="center" width="134">肝炎</td>
                        <td className="table-op" align="center" width="134">痢疾</td>
                        <td className="table-op" align="center" colSpan={2} width="134">伤寒</td>
                        <td className="table-op" align="center" width="134">肺结核</td>
                        <td className="table-op" align="center" width="134">皮肤病</td>
                        <td className="table-op" align="center" width="134">其他</td>
                    </tr>
                    <tr>
                        <td className="table-op" align="center" colSpan={2}>病患时间</td>
                        <td className="table-op">&nbsp;</td>
                        <td className="table-op">&nbsp;</td>
                        <td className="table-op" colSpan={2}>&nbsp;</td>
                        <td className="table-op">&nbsp;</td>
                        <td className="table-op">&nbsp;</td>
                        <td className="table-op">&nbsp;</td>
                    </tr>
                    <tr>
                        <td className="table-op" rowSpan={4} align="center">
                                    体<br/><br/>征
                        </td>
                        <td className="table-op" width="67" height="40" align="center">心</td>
                        <td colSpan={4} className="table-op" width="402">&nbsp;</td>
                        <td className="table-op" width="67" align="center">肝</td>
                        <td className="table-op">&nbsp;</td>
                        <td className="table-op">&nbsp;</td>
                        <td className="table-op">&nbsp;</td>
                    </tr>
                    <tr>
                        <td className="table-op" width="67" height="40" align="center">脾</td>
                        <td colSpan={4} className="table-op" width="402">&nbsp;</td>
                        <td className="table-op" width="67" align="center">肺</td>
                        <td className="table-op">&nbsp;</td>
                        <td className="table-op">&nbsp;</td>
                        <td className="table-op">&nbsp;</td>
                    </tr>
                    <tr>
                        <td className="table-op" width="67" align="center">皮肤</td>
                        <td colSpan={8} className="table-op">
                        手癣  指甲癣  手部湿疹  银屑(或鳞屑)病<br/>
                        渗出性皮肤病  化脓皮肤病<br/>&nbsp;
                        
                        </td>
                    </tr>
                    <tr>
                        <td className="table-op" width="67" align="center">其<br/>他</td>
                        <td colSpan={4} className="table-op">
                                &nbsp;<br/><br/><br/><br/><br/>
                        
                        </td>
                        <td colSpan={4} className="table-op">
                            医生签名<br /><br/><br/>
                        </td>
                    </tr>
                    <tr>
                        <td className="table-op" height="40" colSpan={2}>
                            X线胸透或胸部拍片
                        </td>
                        <td colSpan={8}  className="table-op">
                            <font className="font3">                                  医师签名:</font>
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan={7} className="table-op">
                        实验室检查（化验单附后）
                        </td>
                        <td colSpan={4} className="table-op">检查项目<br/>&nbsp;</td>
                        <td colSpan={3} className="table-op">检查结果<br/>&nbsp;</td>
                        <td colSpan={2} className="table-op">检验师签名<br/>&nbsp;</td>
                    </tr>
                    <tr>
                        <td rowSpan={2} colSpan={2} className="table-op">大便<br/>培养
                        </td>
                        <td colSpan={2} className="table-op">痢疾杆菌</td>
                        <td colSpan={3} className="table-op">&nbsp;</td>
                        <td colSpan={2} className="table-op">&nbsp;</td>
                    </tr>
                    <tr>
                        <td colSpan={2} className="table-op">伤寒或副伤寒</td>
                        <td colSpan={3} className="table-op">&nbsp;</td>
                        <td colSpan={2} className="table-op">&nbsp;</td>
                    </tr>
                    <tr>
                        <td colSpan={2} className="table-op">肝功能</td>
                        <td colSpan={2} className="table-op">谷丙转氨酶</td>
                        <td colSpan={3} className="table-op">&nbsp;</td>
                        <td colSpan={2} className="table-op">&nbsp;</td>
                    </tr>
                    <tr>
                        <td colSpan={4} className="table-op">甲肝抗体（IgM）</td>
                        <td colSpan={3} className="table-op">&nbsp;</td>
                        <td colSpan={2} className="table-op">&nbsp;</td>
                    </tr>
                    <tr>
                        <td colSpan={4} className="table-op">戊肝抗体（IgM）</td>
                        <td colSpan={3} className="table-op">&nbsp;</td>
                        <td colSpan={2} className="table-op">&nbsp;</td>
                    </tr>
                    <tr>
                        <td colSpan={9} className="table-op">其它</td>
                    </tr>
                    <tr>
                        <td colSpan={10} className="table-op">
                            <table width="100%">
                                <tbody>
                                    <tr>
                                    <td>检查结论:</td>
                                    </tr>
                                    <tr>
                                        <td align="right">主检医师签名:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（公章）&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                        </td>
                                    </tr>
                                    <tr>
                                        <td align="right">年 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;月 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;日 </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div className="table-list">
            <div className="table-wet">
                <p>备注：</p>
                <p>1、发现谷丙转氨酶异常者的，应加做甲肝抗体和戊肝抗体检查。</p>
                <p>2、关于大便培养的痢疾杆菌、伤寒或副伤寒检查项目，应统一规范检验报告格式，并按规定的检验标准进行检测，如未检出，报告格式为：“痢疾杆菌、伤寒、副伤寒等沙门氏菌未检出”，如检出，应明确检出的病原菌名。</p>
            </div>
        </div>
    </div>   
        )
    }
}
export default connect(null,{})(PrintCheckInfo)