// 
const Community= [{code:"江浦街道社区卫生服务中心",list:[
    {
        key: '同心社区',
        title: '同心社区'
    },{
        key: '巩固社区',
        title: '巩固社区'
    },{
        key: '八里社区',
        title: '八里社区'
    },{
        key: '团结社区',
        title: '团结社区'
    },{
        key: '老虎桥社区',
        title: '老虎桥社区'
    },{
        key: '高旺社区',
        title: '高旺社区'
    },{
        key: '西江口社区',
        title: '西江口社区'
    },{
        key: '华光社区',
        title: '华光社区'
    },{
        key: '白马社区',
        title: '白马社区'
    },{
        key: '人民桥社区',
        title: '人民桥社区'
    },{
        key: '新合社区',
        title: '新合社区'
    },{
        key: '烈士塔社区',
        title: '烈士塔社区'
    },{
        key: '新河社区',
        title: '新河社区'
    },{
        key: '珠江社区',
        title: '珠江社区'
    },{
        key: '求雨山社区',
        title: '求雨山社区'
    }
    ]}]
    export default Community;


