
export default {
  /**
   * 截取URL参数
   * @param {string} name 截取的key
   * @param {string} [url] 被截取的url
   * @returns {string} 截取的val
   */
  urlParam: (name, url) => {
    let reg = new RegExp(".*[&?]" + name + "=([^&]*)(&|$)");
    let r;
    if (!url) {
      r = window.location.search.match(reg);
    } else {
      r = url.match(reg);
    }
    if (r) return decodeURIComponent(r[1]);
    return "";
  },
  /**
   * 判断是否是手机号
   * @param {string} val 传进来的字符串
   */ isMobile: val => {
    let reg = /^1[3|4|5|7|8][0-9]\d{8}$/;
    return reg.test(val);
  },


  getUUid: () => {
    var s = [];
    var hexDigits = "0123456789abcdef";
    for (var i = 0; i < 36; i++) {
      s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
    }
    s[14] = "4";  // bits 12-15 of the time_hi_and_version field to 0010
    s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);  // bits 6-7 of the clock_seq_hi_and_reserved to 01
    s[8] = s[13] = s[18] = s[23] = "-";

    var uuid = s.join("");
    return uuid;
  }
};
