import React, { Component } from 'react'
import { Input, Table, Button, Modal } from 'antd'
import { connect } from 'react-redux'
import { shortLinkSave, shortLinkList } from '../../../actions/account'

const { TextArea } = Input

const { Column } = Table
class ShortLink extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dataList: [],
      visble: false,
      baseUrl: '',
      suffixUrl: '',
      fullUrl: '',
      shotName: '',
    }
  }

  componentDidMount() {
    this.loadDatas()
  }

  loadDatas = () => {
    var that = this
    this.props.shortLinkList({ page: 0, size: 1000 }).then((res) => {
      if (res.data.code === 200) {
        that.setState({
          dataList: res.data.data.rows,
        })
      }
    })
  }

  linkAddOnClick = () => {
    this.setState({ visible: true })
  }

  baseUrlOnChange = (e) => {
    this.setState({ baseUrl: e.target.value })
  }
  suffixUrlOnChange = (e) => {
    this.setState({ suffixUrl: e.target.value })
  }
  fullUrlOnChange = (e) => {
    this.setState({ fullUrl: e.target.value })
  }
  shotNameOnChange = (e) => {
    this.setState({ shotName: e.target.value })
  }

  linkAddonOk = () => {
    var { baseUrl, suffixUrl, fullUrl, shotName } = this.state
    var that = this
    this.props
      .shortLinkSave({ shotName, baseUrl, suffixUrl, fullUrl })
      .then((res) => {
        if (res.data.code === 200) {
          that.setState({
            visible: false,
            baseUrl: '',
            suffixUrl: '',
            fullUrl: '',
            shotName: '',
          })
        }
      })
  }

  linkAddonCancel = () => {
    this.setState({
      visible: false,
      baseUrl: '',
      suffixUrl: '',
      fullUrl: '',
      shotName: '',
    })
  }

  render() {
    const { shotName, dataList, baseUrl, suffixUrl, fullUrl, visible } =
      this.state

    return (
      <div>
        <div className="StatisticsPage-head pageHeader">
          <h3>短链接管理</h3>
        </div>
        <div className="StatisticsPage-box has-select-table">
          <div className="StatisticsPage-search">
            <div className="StatisticsPage-btn">
              <Button
                className="btn"
                type="primary"
                onClick={this.linkAddOnClick}
              >
                新增
              </Button>
            </div>
          </div>
          <Table dataSource={dataList} size="middle" bordered>
            <Column
              title="短链接名称"
              dataIndex="shotName"
              key="shotName"
              align="center"
            />
            <Column
              title="短链接前缀"
              dataIndex="baseUrl"
              key="baseUrl"
              align="center"
            />
            <Column
              title="短连接编码"
              dataIndex="shotCode"
              key="shotCode"
              align="center"
            />
            {/* <Column title="子机构体检人数" dataIndex="childPhysicalNumber" key="childPhysicalNumber" align="center" /> */}
          </Table>
        </div>
        <Modal
          width={600}
          title="短链接编辑"
          visible={visible}
          onOk={this.linkAddonOk}
          onCancel={this.linkAddonCancel}
        >
          <div className="form-group">
            <label className="form-label">名称：</label>
            <div className="form-ipt">
              <Input value={shotName} onChange={this.shotNameOnChange} />
            </div>
          </div>
          <div className="form-group">
            <label className="form-label">域名：</label>
            <div className="form-ipt">
              <Input value={baseUrl} onChange={this.baseUrlOnChange} />
            </div>
          </div>
          <div className="form-group">
            <label className="form-label">域名外的后缀：</label>
            <div className="form-ipt">
              <TextArea value={suffixUrl} onChange={this.suffixUrlOnChange} />
            </div>
          </div>
          <div className="form-group">
            <label className="form-label">完整链接:</label>
            <div className="form-ipt">
              <TextArea value={fullUrl} onChange={this.fullUrlOnChange} />
            </div>
          </div>
        </Modal>
      </div>
    )
  }
}
export default connect(null, {
  shortLinkSave,
  shortLinkList,
})(ShortLink)
