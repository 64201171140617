import React, { Component } from 'react'
import './RegistrationPage.css'
// import login_logo from '../../../assets/img_defalt.png'
import JsBarcode from 'jsbarcode'
import Cropper from 'react-cropper'
import 'cropperjs/dist/cropper.css'
import { ScissorOutlined } from '@ant-design/icons'

import { connect } from 'react-redux'
import {
  guahaoUpdate,
  getDetail,
  getUsableMachine,
  addGuahao,
  getAlreadyMachineList,
  orgPhycicalTypes,
  getOrganizationGuahaoList,
  getBarcode,
  getPhyProjectList,
} from '../../../actions/account'
// import RNFetchBlob from "react-native-fetch-blob";
import { Input, Button, message, Table, Modal, Select, DatePicker } from 'antd'
import { Upload, Radio } from 'antd'
// import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';

import moment from 'moment'

const { Column } = Table
const { confirm } = Modal
const { Option } = Select

class RegistrationPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      file: null,
      cropformData: undefined,
      isShowPicCut: false,
      cutPicUrl: '',
      masterList: [],
      masterNames: [],
      masterProjectsCode: [],
      cardType: 1,
      deptName: '',
      collection: '',
      updateTempObj: {},
      id: '',
      recordId: '',
      updateState: 0,
      entryTime: moment().locale('zh-cn').format('YYYY-MM-DD HH:mm:ss'),
      way: 2,
      physicalType: '',
      timeStamp: '',
      imageUrl: '',
      imageUrlUpdate: 1,
      machineList: [],
      physicalTypeList: [],
      barcodeShow: false,
      partyName: '',
      certAddress: '',
      phone: '',
      certNumber: '',
      identityPic: undefined,
      nation: '',
      birday: undefined,
      loading: false,
      isRequresting: false,
      picUrl: undefined,
      base64Url: undefined,
      fileList: [],
      machineType: undefined,
      workUnit: '',
      barcode: '',
      industry: '1001',
      source: '2',
      currecord: undefined,
      dataList: [],
      page: 1,
      total: 0,
      pageSize: 10,
      barcodeAuto: '',
      recordId: ''
    }
  }

  componentDidMount() {
    var that = this
    this.props.getAlreadyMachineList().then(function (res) {
      console.log(res)
      if (res.data.code === 200) {
        that.setState({ machineList: res.data.data })
      } else {
        message.error(res.data.message)
      }
    })
    this.props.orgPhycicalTypes().then((msg) => {
      if (msg.data.code === 200) {
        that.setState({
          physicalTypeList: msg.data.data,
        })
      }
    })
    this.loadDataList()

    if (this.props.location.state && this.props.location.state.idCard) {
      var idCardtemp = this.props.location.state.idCard
      let curRecordObj = JSON.parse(this.props.location.state.curRecord)
      console.log(curRecordObj)
      this.setState({ updateState: 1 })
      if (!!curRecordObj) {
        that.setState(
          {
            updateTempObj: curRecordObj,
            id: curRecordObj.infoId,
            recordId: curRecordObj.id,
            certNumber: curRecordObj.idCard,
            certAddress: curRecordObj.address,
            phone: curRecordObj.phone,
            barcode: curRecordObj.barcode,
            partyName: curRecordObj.username,
            workUnit: curRecordObj.workUnit,
            entryTime: !!curRecordObj.entryTime ? curRecordObj.entryTime : moment().locale('zh-cn').format('YYYY-MM-DD HH:mm:ss'),
            physicalType: curRecordObj.physicalType,
            imageUrl: curRecordObj.pic,
            deptName: curRecordObj.deptName,
            collection: curRecordObj.collection,
            masterNames: curRecordObj.masterNames
              ? curRecordObj.masterNames.split(',')
              : [],
            masterProjectsCode: curRecordObj.masterProjectsCode
              ? curRecordObj.masterProjectsCode.split(',')
              : [],
            cardType: curRecordObj.cardType,
            birday: curRecordObj.birthday,
            nation: curRecordObj.nation,
            sex: curRecordObj.sex,
          },
          () => {
            that.getMasterProject(curRecordObj.physicalType)
          }
        )
      } else {
        this.props
          .getDetail({
            idCard: idCardtemp,
          })
          .then((res) => {
            if (res.data.code === 200) {
              if (res.data.data) {
                that.setState(
                  {
                    updateTempObj: res.data.data,
                    id: res.data.data.id,
                    recordId: res.data.data.recordId,
                    certNumber: res.data.data.idCard,
                    certAddress: res.data.data.address,
                    phone: res.data.data.phone,
                    barcode: res.data.data.barcode,
                    partyName: res.data.data.username,
                    workUnit: res.data.data.workUnit,
                    entryTime: !!res.data.data.entryTime ? res.data.data.entryTime : moment().locale('zh-cn').format('YYYY-MM-DD HH:mm:ss'),
                    physicalType: res.data.data.physicalType,
                    imageUrl: res.data.data.pic,
                    deptName: res.data.data.deptName,
                    collection: res.data.data.collection,
                    masterNames: res.data.data.masterNames
                      ? res.data.data.masterNames.split(',')
                      : [],
                    masterProjectsCode: res.data.data.masterProjectsCode
                      ? res.data.data.masterProjectsCode.split(',')
                      : [],
                    cardType: res.data.data.cardType,
                    birday: res.data.data.birthday,
                    nation: res.data.data.nation,
                    sex: res.data.data.sex,
                  },
                  () => {
                    that.getMasterProject(res.data.data.physicalType)
                  }
                )
              } else {
                return
              }
            }
          })
      }


    }
  }

  /**
   * 图片转base64
   * @param {*} img
   * @param {*} callback
   */
  getBase64(img, callback) {
    const reader = new FileReader()
    reader.addEventListener('load', () => callback(reader.result))
    reader.readAsDataURL(img)
  }

  beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!')
    }
    if (file.status === 'uploading') {
      this.setState({ loading: true })
      // return;
    }
    this.getBase64(file, (imageUrl) => {
      console.log(file)
      this.setState({
        imageUrl: imageUrl,
        loading: false,
        file: file,
      })
    })
    // this.imagePress(file, 1024 * 30)
  }
  handleChange = (info) => {
    // Get this url from response in real world.
  }

  /**
   * 上传图片时，压缩图片
   * @param {*} file 目标文件
   * @param {*} size 压缩后目标大小
   */
  imagePress = (file, size) => {
    const imagePress2 = this.imagePress
    const isLt2M = file.size < size
    if (isLt2M) {
      console.log('没有压缩')
      this.getBase64(file, (imageUrl) => {
        this.setState(
          {
            imageUrl: imageUrl,
            loading: false,
          },
          () => {
            this.continueSave()
          }
        )
      })
    } else {
      console.log('压缩了')
      let rate = 0.9
      let reader = new FileReader()
      reader.readAsDataURL(file)
      let img = new Image()
      reader.onload = function (e) {
        img.src = e.target.result
      }
      img.onload = function () {
        let canvas = document.createElement('canvas')
        let context = canvas.getContext('2d')
        // 文件大小KB
        const fileSizeKB = Math.floor(file.size / 1024)
        // console.log('file size：', fileSizeKB, 'kb');
        // 配置rate和maxSize的关系
        if (fileSizeKB * rate > 3027) {
          rate = Math.floor((3027 / fileSizeKB) * 10) / 30
        }
        // 缩放比例，默认0.5
        let targetW = (canvas.width = this.width * rate)
        let targetH = (canvas.height = this.height * rate)
        context.clearRect(0, 0, targetW, targetH)
        context.drawImage(img, 0, 0, targetW, targetH)
        canvas.toBlob((blob) => {
          const newImage = new File([blob], file.name, {
            type: file.type,
          })
          // console.log(newImage.size / 1024, "kb");
          // 图片上传接口
          imagePress2(newImage, size)
        })
      }
    }
  }
  /**
   * 身份证识别后返回参数
   * @param {} options
   */
  ajax(options) {
    if (options.type == null) {
      options.type = 'POST'
    }
    if (options.url == null) {
      options.url = ''
    }
    if (options.timeout == null) {
      options.timeout = 5000
    }
    if (options.onComplate == null) {
      options.onComplate = function () { }
    }
    if (options.onError == null) {
      options.onError = function () { }
    }
    if (options.onSuccess == null) {
      options.onSuccess = function () { }
    }
    if (options.data) {
      options.data = ''
    }
    var xml
    xml = new XMLHttpRequest()
    xml.open(options.type, options.url, true)
    var timeoutLength = options.timeout
    var requestDone = false
    setTimeout(function () {
      requestDone = true
    }, timeoutLength)
    xml.onreadystatechange = function () {
      if (xml.readyState === 4 && !requestDone) {
        if (httpSuccess(xml)) {
          options.onSuccess(httpData(xml))
        } else {
          options.onError()
        }
        options.onComplate()
        xml = null
      }
    }
    xml.send()
    function httpSuccess(r) {
      if (r.status === 200) {
        return true
      } else {
        return false
      }
    }
    function httpData(r) {
      return r.responseText
    }
  }
  /**
   * 连接身份证识别器
   */
  connect = () => {
    var options = {}
    options.type = 'GET'
    options.url = 'http://127.0.0.1:19196/OpenDevice'
    options.timeout = 5000
    options.onSuccess = this.onSuccess
    this.ajax(options)
  }
  onSuccess = (data) => {
    data = JSON.parse(data)

    message.info(data.errorMsg)
  }
  /**
   * 读取身份证
   */
  read = () => {
    var options = {}
    options.type = 'GET'
    options.url = 'http://127.0.0.1:19196/readcard'
    options.timeout = 5000
    options.onSuccess = this.onReadSuccess
    this.ajax(options)
  }

  /**
   * 读取身份证成功函数
   * @param {*} data
   */
  onReadSuccess = (data) => {
    data = JSON.parse(data)
    message.info(data.errorMsg)
    this.setState({
      partyName: data.partyName,
      certAddress: data.certAddress,
      certNumber: data.certNumber,
      way: 1,
      imageUrl: 'data:image/png;base64,' + data.identityPic,
    })
  }
  getBirthday = (idcard) => {
    if (idcard.length !== 18) return
    var birday = ''
    if (idcard.length === 18) {
      birday =
        idcard.substr(6, 4) +
        '-' +
        idcard.substr(10, 2) +
        '-' +
        idcard.substr(12, 2)
    }
    this.setState({ birday: birday })
  }

  // handleChange = (info) => {
  //     if (info.file.status === 'uploading') {
  //         this.setState({ loading: true });
  //     }
  //     else {
  //         this.setState({ loading: false });
  //     }
  // }

  customRequest = (option) => {
    var { isRequresting } = this.state
    const formData = new FormData()

    formData.append('file', option.file)
    if (isRequresting) {
      return
    }
    this.setState({ isRequresting: true }, () => {
      // that.props.organizationUpload(formData).then(function (res) {
      //     if (res.data.code === 200) {
      //         that.setState({
      //             identityPic: res.data.data.completePath,
      //             picUrl: res.data.data.path,
      //             base64Url: undefined,
      //             fileList: [{ status: "done", uid: res.data.data.path, url: res.data.data.completePath }],
      //         })
      //     }
      //     else {
      //         message.error(res.data.message);
      //     }
      //     that.setState({
      //         isRequresting: false,
      //         loading: false
      //     })
      // })
    })
  }

  nameChange = (e) => {
    this.setState({
      partyName: e.target.value,
    })
  }

  birdayOnChange = (date, dateString) => {
    console.log(date, dateString)
    this.setState({
      birday: dateString,
    })
  }
  dateOnChange = (date, dateString) => {

    this.setState({
      entryTime: dateString,
    })
  }

  nationChange = (e) => {
    console.log(e)
    this.setState({ nation: e })
  }

  machineTypeOnChange = (value) => {
    this.setState({ machineType: value.toString() })
    console.log(value)
  }

  certNumberOnChange = (e) => {
    this.setState({ certNumber: e.target.value })
  }

  barcodeOnChange = (e) => {
    this.setState({
      barcode: e.target.value,
    })
  }

  certAddressOnChange = (e) => {
    this.setState({ certAddress: e.target.value })
  }
  phoneOnChange = (e) => {
    this.setState({ phone: e.target.value })
  }
  workUnitOnChange = (e) => {
    this.setState({ workUnit: e.target.value })
  }
  deptNameOnChange = (e) => {
    this.setState({ deptName: e.target.value })
  }
  collectionOnChange = (e) => {
    this.setState({ collection: e.target.value })
  }
  barcodeOnChange = (e) => {
    this.setState({
      barcode: e.target.value,
      barcodeAuto: '',
    })
  }
  getInfoByIdCard = (UUserCard, num) => {
    if (num === 1) {
      //获取出生日期
      return (
        UUserCard.substring(6, 10) +
        '-' +
        UUserCard.substring(10, 12) +
        '-' +
        UUserCard.substring(12, 14)
      )
    }
    if (num === 2) {
      //获取性别
      if (parseInt(UUserCard.substr(16, 1)) % 2 === 1) {
        //男
        return 1
      } else {
        //女
        return 2
      }
    }
    if (num === 3) {
      //获取年龄
      var myDate = new Date()
      var month = myDate.getMonth() + 1
      var day = myDate.getDate()
      var age = myDate.getFullYear() - UUserCard.substring(6, 10) - 1
      if (
        UUserCard.substring(10, 12) < month ||
        (UUserCard.substring(10, 12) === month &&
          UUserCard.substring(12, 14) <= day)
      ) {
        age++
      }
      return age
    }
  }
  /**
   * 保存
   */
  save = () => {
    var { file } = this.state
    if (file && file.size > 1024 * 100) {
      this.imagePress(file, 1024 * 100)
    } else {
      this.continueSave()
    }
  }
  continueSave = () => {
    var {
      partyName,
      certNumber,
      machineType,
      masterNames,
      masterProjectsCode,
      imageUrlUpdate,
      barcode,
      imageUrl,
      certAddress,
      way,
      physicalType,
      phone,
      workUnit, entryTime,
      id,
      recordId,
      updateState,
      updateTempObj,
      cardType,
      deptName,
      collection,
      birday,
      sex,
      nation
    } = this.state
    if (updateState) {
      //更新信息
      var obj = {
        id: id,
        recordId: recordId,
      }
      if (certNumber !== updateTempObj.idCard) {
        obj.idCard = certNumber
      }
      if (partyName !== updateTempObj.username) {
        obj.username = partyName
      }
      if (certAddress !== updateTempObj.address) {
        obj.address = certAddress
      }
      if (imageUrl !== updateTempObj.pic && imageUrlUpdate === 1) {
        obj.pic = imageUrl
      }
      if (barcode !== updateTempObj.barcode) {
        obj.barcode = barcode
      }
      if (physicalType !== updateTempObj.physicalType) {
        obj.physicalType = physicalType
      }
      if (workUnit !== updateTempObj.workUnit) {
        obj.workUnit = workUnit
      }
      if (entryTime !== updateTempObj.entryTime) {
        obj.entryTime = entryTime
      }
      if (phone !== updateTempObj.phone) {
        obj.phone = phone
      }
      if (deptName !== updateTempObj.deptName) {
        obj.deptName = deptName
      }
      if (collection !== updateTempObj.collection) {
        obj.collection = collection
      }
      if (
        masterNames.join(',') !== updateTempObj.masterNames &&
        masterNames.join(',').length > 0
      ) {
        obj.masterNames = masterNames.join(',')
      }
      if (
        masterProjectsCode.join(',') !== updateTempObj.masterProjectsCode &&
        masterProjectsCode.join(',').length > 0
      ) {
        obj.masterProjectsCode = masterProjectsCode.join(',')
      }
      if (sex !== updateTempObj.sex) {
        obj.sex = sex
      }
      if (birday !== updateTempObj.birthday) {
        obj.birthday = birday
      }
      if (nation !== updateTempObj.nation) {
        obj.nation = nation
      }
      if (cardType == 1) {

        obj.sex = this.getInfoByIdCard(certNumber, 2)
        obj.age = this.getInfoByIdCard(certNumber, 3)
        obj.birthday = this.getInfoByIdCard(certNumber, 1)
      } else {
        console.log(birday)
        const regex = /^\d{4}-\d{2}-\d{2}$/;
        if (!regex.test(birday)) {

          message.error('请选择正确的生日')

        } else {
          const birthDate = new Date(birday);
          const currentDate = new Date();

          let age = currentDate.getFullYear() - birthDate.getFullYear();
          if (currentDate.getMonth() < birthDate.getMonth() ||
            (currentDate.getMonth() === birthDate.getMonth() && currentDate.getDate() < birthDate.getDate())) {
            age--;
          }
          obj.age = age
          obj.birthday = birday
          obj.sex = sex
        }
      }

      console.log(obj)

      //向后台发请求
      this.props.guahaoUpdate(obj).then((res) => {
        console.log(res)
        if (res.data.code === 200) {
          message.success('修改成功')
          this.props.history.go(-1)
        } else message.error(res.data.message)
      })
    } else {
      //新增保存信息
      let newformData = new FormData()
      newformData.append('uname', partyName.toString())
      newformData.append('timestamp', Date.parse(new Date()))
      newformData.append('idcard', certNumber.toString())
      newformData.append('way', way)
      newformData.append('cardType', cardType)
      newformData.append('barcode', barcode.toString())
      if (masterNames.length > 0) {
        newformData.append('masterNames', masterNames.join(','))
      }
      if (masterProjectsCode.length > 0) {
        newformData.append('masterProjectsCode', masterProjectsCode.join(','))
      }
      if (cardType == 1) {
        newformData.append('sex', this.getInfoByIdCard(certNumber, 2))
        newformData.append('age', this.getInfoByIdCard(certNumber, 3))
        newformData.append('birthday', this.getInfoByIdCard(certNumber, 1))
      } else {

        const regex = /^\d{4}-\d{2}-\d{2}$/;
        if (!regex.test(birday)) {

          message.error('请选择正确的生日')

        } else {
          const birthDate = new Date(birday);
          const currentDate = new Date();

          let age = currentDate.getFullYear() - birthDate.getFullYear();
          if (currentDate.getMonth() < birthDate.getMonth() ||
            (currentDate.getMonth() === birthDate.getMonth() && currentDate.getDate() < birthDate.getDate())) {
            age--;
          }
          newformData.append('sex', sex)
          newformData.append('age', age)
          newformData.append('birthday', birday)
        }
      }
      if (way === 1) {
        newformData.append('orgnum', machineType.toString())
      }
      if (way === 2) {
        newformData.append('physicalType', physicalType)
      }
      if (certAddress !== null) {
        if (certAddress.length > 0) {
          newformData.append('address', certAddress.toString())
        }
      }
      if (phone !== null && phone !== undefined && phone.length > 0)
        newformData.append('phone', phone)
      if (workUnit !== null && workUnit !== undefined && workUnit.length > 0)
        newformData.append('workUnit', workUnit)
      if (entryTime !== null && entryTime !== undefined && entryTime.length > 0)
        newformData.append('entryTime', entryTime)
      if (deptName !== null && deptName !== undefined && deptName.length > 0)
        newformData.append('deptName', deptName)
      if (
        collection !== null &&
        collection !== undefined &&
        collection.length > 0
      )
        newformData.append('collection', collection)
      if (
        imageUrl !== null &&
        imageUrl !== undefined &&
        imageUrl.toString().length > 0 &&
        imageUrlUpdate === 1
      ) {
        var temp = imageUrl
        newformData.append(
          'pic',
          temp
            .toString()
            .replace('data:image/png;base64,', '')
            .replace('data:image/jpeg;base64,', '')
        )
      }
      if (!!!physicalType || !!!barcode || !!!certNumber) {
        message.error('请输入必填信息')
        return
      }
      if (!!!sex) {
        message.error('请选择性别')
        return
      }
      //向后台发请求
      this.props.addGuahao(newformData).then((res) => {
        console.log(res)
        if (res.data.code === 200) {
          message.success('录入成功')
          this.setState({
            partyName: '',
            certNumber: '',
            machineType: '',
            barcode: '',
            imageUrl: '',
            certAddress: '',
            physicalType: '',
            orgnum: '',
            masterList: [],
            masterProjectsCode: [],
            masterNames: [],
            nation: '',
            phone: '',
            workUnit: '',
            entryTime: moment().locale('zh-cn').format('YYYY-MM-DD HH:mm:ss'),
            birday: undefined,
            sex: '',
            collection: '',
            deptName: '',
            file: null,
            cutPicUrl: ''
          })
          this.loadDataList()
        } else message.error(res.data.message)
      })
    }
  }
  industryOnChange = (value) => {
    this.setState({
      industry: value,
    })
  }

  loadDataList = () => {
    var { page, pageSize } = this.state

    var time = moment().format('YYYY-MM-DD')
    var obj = {
      page: page - 1,
      size: pageSize,
      startTime: time + ' 00:00:00',
      endTime: time + ' 23:59:59',
      organizationId: JSON.parse(localStorage.getItem('data')).organizationId,
    }
    var that = this
    that.props.getOrganizationGuahaoList(obj).then((msg) => {
      console.log(msg)
      if (msg.data.code === 200) {
        if (msg.data.data.rows.length === 0 && msg.data.data.total !== 0) {
          that.setState({ page: page - 1 }, () => {
            that.loadDatas()
          })
        } else {
          that.setState({
            dataList: msg.data.data.rows,
            total: msg.data.data.total,
          })
        }
      }
    })
  }

  changePage = (page) => {
    var that = this
    this.setState({ page }, () => {
      that.loadDataList()
    })
  }

  editItems = (record) => {
    this.setState({
      currecord: record,
      source: record.source,
      partyName: record.username,
      certNumber: record.certNumber,
      machineType: record.machineType + '',
      birday: record.birthDate,
      phone: record.phone,
      identityPic: record.picture,
      barcode: record.barcode,
      workUnit: record.workUnit,
      entryTime: !!record.entryTime ? record.entryTime : moment().locale('zh-cn').format('YYYY-MM-DD HH:mm:ss'),
      certAddress: record.address,
    })
  }

  delItem = (id) => {
    // var that = this;
    confirm({
      content: '确认删除？',
      onOk() {
        // that.props.organizationCheckDelete({ ids: id }).then(msg => {
        //     if (msg.data.code === 200) {
        //         message.success("删除成功！");
        //         that.loadDataList();
        //     }
        // })
      },
    })
  }
  getBarcode = () => {
    var that = this
    var { partyName, certNumber, physicalType } = this.state
    if (partyName === '' || certNumber === '') {
      message.error('请先填写姓名身份证！')
      return
    }
    that.props
      .getBarcode({ physicalType: physicalType, idCard: certNumber })
      .then((res) => {
        if (res.data.code === 200) {
          that.setState(
            {
              barcode: res.data.message,
            },
            () => {
              // that.setState({ barcodeShow: true })
              // that.preview();
            }
          )
        }
        message.info(res.data.message)
      })
  }
  barcodePrint = () => {
    // this.setState({ barcodeShow: true })
    // this.preview();
  }
  /**
   * 打印条码
   */
  preview = () => {
    var { barcode } = this.state
    JsBarcode(this.barcode, barcode, {
      text: barcode,
      format: 'CODE39',
      displayValue: true,
      width: 0.6,
      height: 50,
      fontSize: 10,
      margin: 0,
      textAlign: 'left',
      textMargin: 0,
    })
    JsBarcode(this.barcode1, barcode, {
      // text: partyName+" "+barcode,
      text: barcode,
      format: 'CODE39',
      displayValue: true,
      width: 0.6,
      height: 50,
      fontSize: 10,
      margin: 0,
      textAlign: 'left',
      textMargin: 0, //设置条形码和文本之间的间距
    })
    JsBarcode(this.barcode2, barcode, {
      // text: partyName+" "+barcode,
      text: barcode,
      format: 'CODE39',
      displayValue: true,
      width: 0.6,
      height: 50,
      fontSize: 10,
      margin: 0,
      textAlign: 'left',
      textMargin: 0, //设置条形码和文本之间的间距
    })
    JsBarcode(this.barcode3, barcode, {
      // text: partyName+" "+barcode,
      text: barcode,
      format: 'CODE39',
      displayValue: true,
      width: 0.6,
      height: 50,
      fontSize: 10,
      margin: 0,
      textAlign: 'left',
      textMargin: 0, //设置条形码和文本之间的间距
    })
    const printDiv = document.getElementById('printDiv')
    const iframe = document.createElement('IFRAME')
    let doc = null
    iframe.setAttribute(
      'style',
      'position:absolute;width:0px;height:0px;left:0px;top:0px;'
    )
    document.body.appendChild(iframe)

    doc = iframe.contentWindow.document
    // 打印时去掉页眉页脚
    doc.write('<style media="print">@page {size: auto;  margin: 0mm; }</style>')

    doc.write(printDiv.innerHTML)
    doc.close()
    // 获取iframe的焦点，从iframe开始打印
    iframe.contentWindow.focus()
    iframe.contentWindow.print()
    if (navigator.userAgent.indexOf('MSIE') > 0) {
      //打印完删除iframe
      document.body.removeChild(iframe)
    }
  }
  range1 = (start, end) => {
    const result = []
    for (let i = start; i < end; i++) {
      result.push(i)
    }
    return result
  }
  disabledDate = (current) => {
    console.log(current)
    // Can not select days before today and today
    return current && current < moment().endOf('day')
  }

  disabledDateTime = () => {
    return {
      disabledHours: () => this.range1(0, 24).splice(4, 20),
      disabledMinutes: () => this.range1(30, 60),
      disabledSeconds: () => [55, 56],
    }
  }

  onphysicalTypeChange = (e) => {
    var that = this
    this.setState(
      { masterList: [], masterNames: [], masterProjectsCode: [] },
      () => {
        that.getMasterProject(e.target.value)
        that.setState({
          physicalType: e.target.value,
        })
      }
    )
  }
  getMasterProject = (phyId) => {
    var that = this
    var { masterNames, masterProjectsCode } = this.state
    masterNames = []
    masterProjectsCode = []
    that.props
      .getPhyProjectList({ page: 0, size: 999, typeId: phyId })
      .then((res) => {
        if (res.data.code === 200) {
          if (res.data.data) {
            res.data.data.rows.forEach((project) => {
              masterNames.push(project.projectName)
              masterProjectsCode.push(project.projectId)
            })
            that.setState({
              masterList: res.data.data.rows,
              masterProjectsCode,
              masterNames,
            })
          }
        } else {
          message.warn(res.data.message)
        }
      })
  }
  certNumberOnBlur = () => {
    var that = this
    console.log(this.state.certNumber)
    this.props
      .getDetail({
        idCard: this.state.certNumber,
      })
      .then((res) => {
        console.log('****************')
        if (res.data.code === 200) {
          if (res.data.data) {
            that.setState(
              {
                certAddress: res.data.data.address,
                phone: res.data.data.phone,
                // barcode: res.data.data.barcode,
                partyName: res.data.data.username,
                workUnit: res.data.data.workUnit,
                entryTime: !!res.data.data.entryTime ? res.data.data.entryTime : moment().locale('zh-cn').format('YYYY-MM-DD HH:mm:ss'),
                physicalType: res.data.data.physicalType,
                imageUrl: res.data.data.pic,
                imageUrlUpdate: res.data.data.pic !== null ? 0 : 1,
              },
              () => {
                that.getMasterProject(res.data.data.physicalType)
              }
            )
            // if (res.data.data.pic !== null && res.data.data.pic !== undefined) {
            //     console.log(res.data.data.pic)
            //     that.urlToBase64(res.data.data.pic).then(msg => {
            //         console.log(msg)
            //         that.setState({ imageUrl: msg });
            //     })
            // }
          } else {
            return
          }
        }
      })
  }

  /**
   * URL转base64
   * @param {*} url
   * @returns
   */
  urlToBase64 = (url) => {
    return new Promise((resolve, reject) => {
      let image = new Image()
      image.onload = function () {
        let canvas = document.createElement('canvas')
        canvas.width = this.naturalWidth
        canvas.height = this.naturalHeight
        // 将图片插入画布并开始绘制
        canvas.getContext('2d').translate(0.5, 0.5).drawImage(image, 0, 0)
        // result
        let result = canvas.toDataURL('image/png')
        resolve(result)
      }
      // CORS 策略，会存在跨域问题https://stackoverflow.com/questions/20424279/canvas-todataurl-securityerror
      image.setAttribute('crossOrigin', 'Anonymous')
      image.src = url
      // 图片加载失败的错误处理
      image.onerror = () => {
        message.warn('图片加载失败')
      }
    })
  }

  cardTypeHandleChange = (value) => {
    this.setState({ cardType: value })
  }
  masterProjectChange = (value) => {
    console.log(value)
    var { masterList } = this.state
    var masterNames = []
    masterList.forEach((master) => {
      if (value.includes(master.projectId)) {
        masterNames.push(master.projectName)
      }
    })
    this.setState({ masterProjectsCode: value, masterNames })
  }
  onCutPic = (imageUrl) => {
    this.setState({ cutPicUrl: imageUrl, isShowPicCut: true })
  }
  PicCutOk = () => {
    this.setState({ isShowPicCut: false })
    const croppedCanvas = this.cropper.getCroppedCanvas({
      minwidth: 300,
      minHeight: 200,
      maxWidth: 4096,
      maxHeight: 4096,
      imageSmoothingEnabled: true,
      imageSmoothingQuality: 'high',
    })
    console.log(croppedCanvas.toDataURL())
    if (typeof croppedCanvas === 'undefined') {
      return
    }
    this.setState({
      imageUrl: croppedCanvas.toDataURL(),
      file: this.dataURLtoFile(croppedCanvas.toDataURL()),
    })
  }
  rotateClick = () => {
    // this.cropper.cropper.style.transform='rotate(90deg)'
    // this.cropper.cropper.__proto__.rotate(90)
  }
  // base64->file对象
  dataURLtoFile = (dataurl, filename = 'file') => {
    let arr = dataurl.split(',')
    let mime = arr[0].match(/:(.*?);/)[1]
    let suffix = mime.split('/')[1]
    let bstr = atob(arr[1])
    let n = bstr.length
    let u8arr = new Uint8Array(n)
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }
    return new File([u8arr], `${filename}.${suffix}`, { type: mime })
  }

  PicCutCancel = () => {
    this.setState({ isShowPicCut: false })
  }
  onCropperInit = (cropper) => {
    this.cropper = cropper
  }
  onsexChange = (e) => {
    this.setState({
      sex: e.target.value,
    })
  }
  pageSizeOnChange = (current, size) => {
    var that = this
    this.setState(
      {
        pageSize: size,
      },
      () => {
        that.loadDataList()
      }
    )
  }
  render() {
    const { imageUrl } = this.state
    const {
      isShowPicCut,
      cardType,
      deptName,
      collection,
      workUnit,
      barcode,
      partyName,
      phone,
      certAddress,
      dataList,
      physicalType,
      physicalTypeList,
      certNumber, entryTime,
      birday,
    } = this.state
    return (
      <div className="registrationPageMain">
        <div className="registrationPageMain-head pageHeader">
          <h3>挂号登记</h3>
        </div>
        <div className="registrationPageMain-content">
          <div className="repageUserInfo">
            <div className="repageUserInfo-left">
              <div className="repageUserInfo-leftcontent">
                <div className="form-group">
                  <label className="form-label required">姓名：</label>
                  <div className="form-ipt">
                    <Input
                      placeholder="姓名(必填)"
                      value={partyName}
                      onChange={(e) => this.nameChange(e)}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-label required">证件类型：</label>
                  <div className="form-ipt">
                    <Select
                      value={cardType}
                      style={{ width: '100%' }}
                      onChange={(e) => this.cardTypeHandleChange(e)}
                    >
                      <Option key={1} value={1}>
                        居民身份证
                      </Option>
                      <Option key={2} value={2}>
                        居民户口簿
                      </Option>
                      <Option key={3} value={3}>
                        护照
                      </Option>
                      <Option key={4} value={4}>
                        军官证
                      </Option>
                      <Option key={5} value={5}>
                        驾驶证
                      </Option>
                      <Option key={6} value={6}>
                        港澳居民来往内地通行证
                      </Option>
                      <Option key={7} value={7}>
                        台湾居民来往内地通行证
                      </Option>
                      <Option key={99} value={99}>
                        其他法定有效证件
                      </Option>
                    </Select>
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-label  required">证件号：</label>
                  <div className="form-ipt">
                    <Input
                      placeholder="证件号：(必填)"
                      value={certNumber}
                      onChange={(e) => this.certNumberOnChange(e)}
                      onBlur={this.certNumberOnBlur}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-label required">条码：</label>
                  <div className="form-ipt">
                    <Input
                      style={{ width: '50%' }}
                      value={barcode}
                      onChange={this.barcodeOnChange}
                    />
                    <Button
                      size="small"
                      style={{ margin: '0 5px' }}
                      onClick={this.getBarcode}
                    >
                      获取/打印条码
                    </Button>
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-label required">体检类型：</label>
                  <div className="form-ipt">
                    <Radio.Group
                      onChange={this.onphysicalTypeChange}
                      value={physicalType}
                    >
                      {physicalTypeList.map((item) => {
                        return (
                          <Radio
                            value={item.physicalType}
                            key={item.physicalType}
                          >
                            {item.physicalName}
                          </Radio>
                        )
                      })}
                    </Radio.Group>
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-label">项目：</label>
                  <div className="form-ipt">
                    <Select
                      mode="multiple"
                      allowClear
                      style={{ width: '100%' }}
                      value={this.state.masterProjectsCode}
                      placeholder="体检类型所含项目"
                      onChange={this.masterProjectChange}
                    >
                      {this.state.masterList.map((item) => (
                        <Option value={item.projectId} key={item.projectId}>
                          {item.projectName}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </div>
                <div
                  className="form-group"
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <label className="form-label">民族：</label>
                  <div className="form-ipt">
                    <Select
                      className="ipt1"
                      allowClear={false}
                      placeholder="请选择"
                      value={this.state.nation}
                      onChange={(e) => this.nationChange(e)}
                    >
                      <Option key={'1'}>汉族</Option>
                      <Option key={'8'}>壮族</Option>
                      <Option key={'11'}>满族</Option>
                      <Option key={'6'}>苗族</Option>
                      <Option key={'3'}>回族</Option>
                      <Option key={'5'}>维吾尔族</Option>
                      <Option key={'7'}>彝族</Option>
                      <Option key={'15'}>土家族</Option>
                      <Option key={'4'}>藏族</Option>
                      <Option key={'2'}>蒙古族</Option>
                      <Option key={'9'}>布依族</Option>
                      <Option key={'10'}>朝鲜族</Option>
                      <Option key={'12'}>侗族</Option>
                      <Option key={'13'}>瑶族</Option>
                      <Option key={'14'}>白族</Option>
                      <Option key={'16'}>哈尼族</Option>
                      <Option key={'17'}>哈萨克族</Option>
                      <Option key={'18'}>傣族</Option>
                      <Option key={'19'}>黎族</Option>
                      <Option key={'20'}>傈傈族</Option>
                      <Option key={'21'}>佤族</Option>
                      <Option key={'22'}>畲族</Option>
                      <Option key={'23'}>高山族</Option>
                      <Option key={'24'}>拉祜族</Option>
                      <Option key={'25'}>水族</Option>
                      <Option key={'26'}>东乡族</Option>
                      <Option key={'27'}>纳西族</Option>
                      <Option key={'28'}>景颇族</Option>
                      <Option key={'29'}>柯尔克孜族</Option>
                      <Option key={'30'}>土族</Option>
                      <Option key={'31'}>达翰尔族</Option>
                      <Option key={'32'}>仫佬族</Option>
                      <Option key={'33'}>羌族</Option>
                      <Option key={'34'}>布朗族</Option>
                      <Option key={'35'}>撒拉族</Option>
                      <Option key={'36'}>毛难族</Option>
                      <Option key={'37'}>仡佬族</Option>
                      <Option key={'38'}>锡伯族</Option>
                      <Option key={'39'}>阿昌族</Option>
                      <Option key={'40'}>普米族</Option>
                      <Option key={'41'}>塔吉克族</Option>
                      <Option key={'42'}>怒族</Option>
                      <Option key={'43'}>乌孜别克族</Option>
                      <Option key={'44'}>俄罗斯族</Option>
                      <Option key={'45'}>鄂温克族</Option>
                      <Option key={'46'}>崩龙族</Option>
                      <Option key={'47'}>保安族</Option>
                      <Option key={'48'}>裕固族</Option>
                      <Option key={'49'}>京族</Option>
                      <Option key={'50'}>塔塔尔族</Option>
                      <Option key={'51'}>独龙族</Option>
                      <Option key={'52'}>鄂伦春族</Option>
                      <Option key={'53'}>赫哲族</Option>
                      <Option key={'54'}>门巴族</Option>
                      <Option key={'55'}>珞巴族</Option>
                      <Option key={'56'}>基诺族</Option>
                      <Option key={'97'}>其他</Option>
                      <Option key={'98'}>外国血统</Option>
                    </Select>
                  </div>
                </div>
              </div>
              <div className="repageUserInfo-rightcontent">
                <div className="form-group">
                  <label className="form-label">地址：</label>
                  <div className="form-ipt">
                    <Input
                      value={certAddress}
                      onChange={(e) => this.certAddressOnChange(e)}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-label">电话：</label>
                  <div className="form-ipt">
                    <Input
                      value={phone}
                      onChange={(e) => this.phoneOnChange(e)}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-label">工作单位：</label>
                  <div className="form-ipt">
                    <Input
                      value={workUnit}
                      onChange={(e) => this.workUnitOnChange(e)}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-label required">日期：</label>
                  <div className="form-ipt">
                    {/* <Input value={this.state.date} /> */}
                    <DatePicker
                      format={'YYYY-MM-DD HH:mm:ss'}
                      showTime
                      value={!!entryTime ? moment(entryTime, 'YYYY-MM-DD HH:mm:ss') : ''}
                      defaultValue={
                        !!entryTime ? moment(entryTime, 'YYYY-MM-DD HH:mm:ss') : ''
                      }
                      onChange={this.dateOnChange}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-label required">生日：</label>
                  <div className="form-ipt">
                    <DatePicker
                      format={'YYYY-MM-DD'}
                      value={!!birday ? moment(birday, 'YYYY-MM-DD') : ''}
                      defaultValue={
                        !!birday ? moment(birday, 'YYYY-MM-DD') : ''
                      }
                      onChange={this.birdayOnChange}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-label">部门名称：</label>
                  <div className="form-ipt">
                    <Input
                      value={deptName}
                      onChange={(e) => this.deptNameOnChange(e)}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-label">采集点：</label>
                  <div className="form-ipt">
                    <Input
                      value={collection}
                      onChange={(e) => this.collectionOnChange(e)}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-label required">性别：</label>
                  <div className="form-ipt">
                    <Radio.Group
                      onChange={this.onsexChange}
                      value={this.state.sex}
                    >
                      <Radio value={1}>男</Radio>
                      <Radio value={2}>女</Radio>
                    </Radio.Group>
                  </div>
                </div>
              </div>
            </div>
            <div className="repageUserInfo-right">
              <img
                style={{
                  maxWidth: '300px',
                  maxHeight: '200px',
                  display: 'inlineBlock',
                }}
                alt=""
                src={imageUrl}
              />
              {this.state.file && (
                <Button
                  type="default"
                  style={{ margin: '10px' }}
                  icon={<ScissorOutlined />}
                  onClick={(e) => this.onCutPic(imageUrl)}
                >
                  裁剪
                </Button>
              )}

              <div>
                <Upload
                  name="avatar"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  beforeUpload={this.beforeUpload}
                // onChange={this.handleChange}
                >
                  <Button type="primary" loading={this.state.loading}>
                    {this.state.file || (imageUrl && imageUrl.length > 0)
                      ? '重新上传'
                      : '上传照片'}
                  </Button>
                </Upload>
                <p className="file_size_tips">
                  {this.state.file
                    ? '当前图片大小：' +
                    Math.ceil(this.state.file.size / 1024) +
                    'KB'
                    : '图片大小不得超过100KB'}
                </p>
                <p className="tips">若图片超过100KB上传时将为您自动压缩</p>
              </div>
            </div>
          </div>
        </div>
        <div className="registrationPageMain-btn">
          <div>
            <Button type="primary" onClick={this.connect}>
              连接读卡器
            </Button>
            <Button type="primary" onClick={this.read}>
              读取身份证
            </Button>
            <Button type="primary" onClick={this.save}>
              提交
            </Button>
          </div>
        </div>
        {/* 底部人员 */}
        <div style={{ margin: '20px 0' }}>
          <div className="registrationPageMain-head pageHeader">
            <h3>当日挂号人员列表</h3>
          </div>
          <Table
            rowKey="id"
            dataSource={dataList}
            pagination={{
              pageSize: this.state.pageSize,

              showTotal: (total) => {
                return `共 ${total} 条`
              },
              onChange: this.changePage,
              onShowSizeChange: this.pageSizeOnChange,
              total: this.state.total,
            }}
            bordered
          >
            <Column
              width={120}
              title="条码号"
              dataIndex="barcode"
              key="barcode"
              align="center"
            />
            <Column
              width={100}
              title="是否预约"
              dataIndex="isAppointment"
              key="isAppointment"
              align="center"
              render={(text, record) => (
                <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                  {text === 1 ? '是' : '否'}
                </div>
              )}
            />
            <Column
              width={100}
              title="姓名"
              dataIndex="username"
              key="username"
              align="center"
            />

            <Column
              width={180}
              title="身份证号"
              dataIndex="idCard"
              key="idCard"
              align="center"
            />
            <Column
              width={100}
              title="地址"
              dataIndex="address"
              key="address"
              align="center"
            />
            {/* <Column width={100} title="操作" align="center" key="action"
                            render={(text, record) => (
                                <span>
                                    <span style={{ cursor: 'pointer' }} onClick={() => this.editItems(record)}>编辑x</span>
                                    <Divider type="vertical" />
                                    <span style={{ cursor: 'pointer' }} onClick={() => this.delItem(record.recordId)}>删除x</span>
                                </span>
                            )}
                        /> */}
          </Table>
        </div>
        <Modal
          title="Basic Modal"
          visible={isShowPicCut}
          onOk={this.PicCutOk}
          onCancel={this.PicCutCancel}
        >
          {/* {this.state.cutPicUrl} */}
          <div className="form-group">
            <label className="form-label required">照片：</label>
            <div className="form-ipt">
              <Cropper
                src={this.state.cutPicUrl}
                className="company-logo-cropper"
                onInitialized={this.onCropperInit.bind(this)}
                zoomable={false}
                guides={false}
                viewMode={1}
                aspectRatio={102 / 126}
                rotatable={true}
                preview=".cropper-preview"
              />
            </div>
          </div>
          {/* <div className="form-group">
                        <label className="form-label"></label>
                        <div className="form-ipt">
                            <div className="cropper-preview"></div>

                        </div>
                    </div> */}
        </Modal>
      </div>
    )
  }
}
export default connect(null, {
  guahaoUpdate,
  getDetail,
  getUsableMachine,
  addGuahao,
  getAlreadyMachineList,
  orgPhycicalTypes,
  getOrganizationGuahaoList,
  getBarcode,
  getPhyProjectList,
})(RegistrationPage)
