import React, { Component } from 'react'
import './Header.css'
import { connect } from 'react-redux'
// import headerlogo from '../../../assets/login-img/img_logo.png'
import { Button } from 'antd';
import { OrganizationLogout } from '../../../actions/account'
import login_logo from '../../../assets/login-img/logo.jpg'
class Header extends Component {

    logout = () => {
        var that = this;
        that.props.OrganizationLogout().then(msg => {
            if (msg.data.code === 200) {
                localStorage.removeItem("cookie")
                localStorage.removeItem("user")
                localStorage.removeItem("data")
                window.location = '/';
            }
        })
    }
    render() {
        var data = localStorage.getItem("data");
        var obj = JSON.parse(data);
        var username = JSON.parse(localStorage.getItem("user")).account;

        return (
            <div className="headerContent">
                <div className="headerlogoimg"><img src={login_logo} alt="" style={{ height: '40px', marginRight: '20px' }} /> <span>
                    {obj.organizationName !== undefined ? obj.organizationName + "样本信息采集管理系统" : "样本信息采集管理系统"}</span></div>
                <div className="headerName"><font className="headerUser">
                    <Button className="headerGoBlack" onClick={this.logout} style={{ background: '#a7b6d7' }}>退出</Button> {"欢迎" + username + "用户"}</font></div>
            </div>
        )
    }
}
export default connect(null, { OrganizationLogout })(Header)