import React, { Component } from 'react'
import { Button, Input, message, Empty, Table, Modal, Divider } from 'antd'
import { connect } from 'react-redux'
import {
  appointmentPageDetail2,
  appointmentSave,
  uploadFile,
  wxGetPDF,
  wxSendCode,
  reportList,
} from '../../../actions/account'
import './QueryPdf.css'
import { reportDownLoad_PATH } from '../../../actions/urls'
import moment from 'moment'
// import { GroupContext } from 'antd/lib/checkbox/Group';
// import { saveAs } from 'file-saver';
const { Column } = Table
// const { Option } = Select
class main extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showCode: false,
      username: '',
      idCard: '',
      sex: '',
      resultList: [],
      phone: '',
      secondsTip: '获取验证码',
      code: '',
    }
  }
  componentDidMount() {
    document.title = '报告查询'
    var that = this
    console.log(this.props.location.search.substring(1))
    var params = this.props.location.search.substring(1).split('&')
    var val = []
    console.log(params)
    var idCard = undefined,
      barcode = undefined
    for (var i = 0; i < params.length; i++) {
      val = params[i].split('=')
      if (val[0] === 'idCard' || val[0] === 'idcard') {
        idCard = decodeURI(val[1])
      } else if (val[0] === 'barcode') {
        barcode = decodeURI(val[1])
      }
    }
    if (barcode !== undefined && idCard !== undefined) {
      this.props
        .reportList({ idCard: idCard, reportPath: barcode })
        .then((res) => {
          if (res.data.code === 200) {
            if (res.data.data.rows.length === 0) {
              //  message.warn("您的报告暂未出具，请耐心等待！")
              Modal.error({
                title: '提示',
                content: '您的报告暂未出具，请耐心等待！',
              })
              return
            } else {
              var data = res.data.data.rows[0]
              var searchObj = { reportId: data.id, url: data.reportPath }
              window.open(reportDownLoad_PATH+"?reportId="+data.id, "_blank");
              //   that.props.history.push('/MyPdfViewer?reportId=' + data.id+'&url='+data.reportPath);
              // var obj ={reportId:data.id,url:data.reportPath}
              // this.props.history.push({
              //   pathname: '/MyPdfViewer',
              //   state: { reportId: data.id, reporturl: data.reportPath },
              // })
            }
          }
        })
    }

    if (localStorage.getItem('QUERY_PDF_CODE')) {
      var data = JSON.parse(localStorage.getItem('QUERY_PDF_CODE'))
      console.log('现在的时间戳', moment().format('YYYY-MM-DD HH:mm:ss'))
      console.log('缓存的时间戳', data.time)
      // 验证码缓存半小时
      if (moment().diff(moment(data.time), 'second') >= 1800) {
        localStorage.removeItem('QUERY_PDF_CODE')
        this.setState({ showCode: true })
      }
      console.log(moment().diff(moment(data.time), 'second'))
    } else {
      this.setState({ showCode: true })
    }
  }
  queryResult = () => {
    var { phone } = this.state
    if (!phone || phone.length !== 11) {
      message.error('手机号格式错误！')
      return
    }
    if (localStorage.getItem('QUERY_PDF_CODE')) {
      var data = JSON.parse(localStorage.getItem('QUERY_PDF_CODE'))
      if (phone === data.phone) {
        this.setState({ code: data.code, showCode: false })
      } else {
        localStorage.removeItem('QUERY_PDF_CODE')
        this.setState({ code: '', showCode: true })
        message.info('请输入验证码！')
        return
      }
    }
    this.setState({ resultList: [] }, () => {
      this.goNext()
    })
  }
  goNext = () => {
    var { phone, code } = this.state
    if (code.length === 0 || phone.length === 0) {
      message.error('请填写完整！')
      return
    }
    var obj = { page: 0, size: 20, phone: phone, code: code }
    message.loading('查询中...', 0)
    this.props.wxGetPDF(obj).then((msg) => {
      message.destroy()
      if (msg.data.code === 200) {
        this.setState({ resultList: msg.data.data.rows })
        if (!localStorage.getItem('QUERY_PDF_CODE')) {
          // localStorage.setItem('QUERY_PDF_CODE', JSON.stringify({ phone: phone, code: code, time: moment().format('YYYY-MM-DD HH:mm:ss') }))
        }
      } else {
        message.error(msg.data.message)
      }
    })
  }
  usernameChange = (e) => {
    this.setState({
      username: e.target.value,
    })
  }
  sexOnChange = (value) => {
    this.setState({ sex: value })
  }
  phoneChange = (e) => {
    this.setState({ phone: e.target.value.trim() })
  }
  codeChange = (e) => {
    this.setState({ code: e.target.value.trim() })
  }
  idCardChange = (e) => {
    var reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/

    if (e.target.value.length === 18) {
      if (reg.test(e.target.value) === false) message.warn('身份证号格式错误')
      else {
        if (parseInt(e.target.value.slice(-2, -1)) % 2 === 1) {
          this.setState({
            sex: '男',
          })
        } else {
          this.setState({
            sex: '女',
          })
        }
        var year = e.target.value.substring(6, 10)
        var month = e.target.value.substring(10, 12)
        var day = e.target.value.substring(12, 14)
        this.setState({ birday: year + '-' + month + '-' + day })
      }
    }
    this.setState({
      idCard: e.target.value,
    })
  }
  downLoad = (record) => {
    window.open(reportDownLoad_PATH + '?reportId=' + record.id, '_blank')

    // this.props.history.push('/MyPdfViewer?reportId=' + record.id+'&url='+record.reportPath);
    // var obj ={reportId:record.id,url:record.reportPath}
    // this.props.history.push({ pathname: "/MyPdfViewer" ,state:{reportId:record.id,url:record.reportPath}});
    // console.log(record);
    // window.open(reportDownLoad_PATH+"?reportId="+record.id, "_blank");
    // let url = "http://pdf.yeekcloud.com/2022-02-11/2022021108525560640775606139.pdf"// 绝对地址
    // let a = document.createElement('a');
    // a.download = 'test.pdf';
    // a.href = url;
    // a.target = '_blank';
    // a.click();
    // if (!record.reportPath) {
    //     message.error('报告不存在！');
    //     return;
    // }
    // var FileSaver = require('file-saver');
    // // var blob = new Blob(["Hello, world!"], { type: "text/plain;charset=utf-8" });
    // FileSaver.saveAs(record.reportPath, "test.pdf");
  }
  sendCode = () => {
    var { phone } = this.state
    var that = this
    if (phone.length !== 11) {
      message.error('手机号格式错误！')
      return
    }

    var formdata = new FormData()
    formdata.append('phone', phone)

    that.props.wxSendCode(formdata).then((msg) => {
      if (msg.data.code === 200) {
        //         that.setState({ secondsTip: "已发送" })
        this.setState({ secondsTip: 60 }, () => {
          var myInterval = setInterval(() => {
            that.setState({ secondsTip: that.state.secondsTip - 1 }, () => {
              if (that.state.secondsTip <= 0) {
                that.setState({ secondsTip: '获取验证码' })
                clearInterval(myInterval)
              }
            })
          }, 1000)
        })
      } else {
        message.error(msg.data.message)
      }
    })
  }
  getSecretIdCard = (idCard) => {
    console.log(idCard)
    // certNumber.replace(/(?<=\d{3})\d{11}(?=\d{2})/, "************");
    var str = ''
    str = idCard.substring(0, 3) + '***' + idCard.substring(14, idCard.length)
    return str
  }
  lookDCM = (record) => {
    if (record.idCard === '320125199107261525') {
      window.open(
        'http://qiniu.yeekcloud.com/sample/dr/2023-08-17/ae6fe12f92384c32bf6c9baf289bf5cb.jpg',
        '_blank'
      )
    }
  }
  render() {
    var { resultList, phone, code } = this.state
    return (
      <div className="queryPdf_outer">
        <div>
          <div className="queryPdf_outer_header">
            <p>查询</p>
          </div>
          <div className="queryPdf_outer_content">
            <div className="queryPdf_outer_query_box">
              {/* <div className="form-group">
                                <label className="form-label1 require">姓名：</label>
                                <div className="form-ipt">
                                    <Input placeholder="姓名" value={username} onChange={e => this.usernameChange(e)} />
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="form-label1 require">身份证号：</label>
                                <div className="form-ipt">
                                    <Input placeholder="身份证号" value={idCard} onChange={e => this.idCardChange(e)} />
                                </div>
                            </div> */}
              <div className="form-group">
                <label className="form-label1 require">手机号：</label>
                <div className="form-ipt">
                  <Input
                    placeholder="输入手机号"
                    value={phone}
                    onChange={(e) => this.phoneChange(e)}
                  />
                </div>
              </div>
              {this.state.showCode && (
                <div className="form-group">
                  <label className="form-label1 require">验证码：</label>
                  <div className="form-ipt flex-row">
                    <Input
                      placeholder="输入验证码"
                      className="query_seconds_Input"
                      value={code}
                      onChange={(e) => this.codeChange(e)}
                    />
                    <Button
                      type="primary"
                      ghost
                      className="query_seconds_btn"
                      onClick={this.sendCode}
                      disabled={this.state.secondsTip !== '获取验证码'}
                    >
                      {isNaN(this.state.secondsTip)
                        ? this.state.secondsTip
                        : this.state.secondsTip + 'S'}
                    </Button>
                  </div>
                </div>
              )}
              {/* <div className="form-group">
                                <label className="form-label1">性别：</label>
                                <div className="form-ipt">
                                    <Select style={{ width: "80%" }} value={this.state.sex} onChange={this.sexOnChange}>
                                        <Option key="男">男</Option>
                                        <Option key="女">女</Option>
                                    </Select>
                                </div>
                            </div> */}
              <Button
                type="default"
                className="query_pdf_btn"
                onClick={this.queryResult}
              >
                查询结果
              </Button>
            </div>
            <div className="queryPdf_outer_result_box">
              {resultList.length === 0 && <Empty />}
              {resultList.length > 0 && (
                <Table
                  dataSource={resultList}
                  bordered
                  rowKey="id"
                  pagination={false}
                >
                  <Column
                    title="姓名"
                    dataIndex="username"
                    key="username"
                    align="center"
                  />
                  {/* <Column title="身份证号" dataIndex="idCard" key="idCard" align="center" render={(text, record) => (
                                        <span>
                                            <span >{this.getSecretIdCard(text)}</span>
                                        </span>
                                    )} /> */}

                  <Column
                    title="类型"
                    dataIndex="physicalType"
                    key="physicalType"
                    align="center"
                    render={(text, record) => (
                      <span>
                        {
                          ['', '大学生', '公卫', '体检', '健康证', '样本'][
                            record.physicalType
                          ]
                        }
                      </span>
                    )}
                  />
                  <Column
                    title="时间"
                    dataIndex="createdAt"
                    key="createdAt"
                    align="center"
                    render={(text, record) => (
                      <span>
                        <span>{text.substring(0, 10)}</span>
                      </span>
                    )}
                  />
                  <Column
                    title="操作"
                    align="center"
                    key="action"
                    render={(text, record) => (
                      <span>
                        <a
                          href={reportDownLoad_PATH + '?reportId=' + record.id} // 替换为你的PDF文件路径
                          download={record.username + '报告'} // 下载后的文件名
                          target="_blank"
                        >
                          下载报告
                        </a>
                        {/* <span onClick={() => this.downLoad(record)}>下载报告</span> */}
                        {record.idCard === '320125199107261525' && (
                          <Divider type="vertical" />
                        )}
                        {record.idCard === '320125199107261525' && (
                          <span onClick={() => this.lookDCM(record)}>
                            查看胸片
                          </span>
                        )}
                      </span>
                    )}
                  />
                </Table>
              )}
            </div>
          </div>
        </div>

        {/* <div className='queryPdf_inner'>

                </div> */}
      </div>
    )
  }
}
export default connect(null, {
  appointmentPageDetail2,
  appointmentSave,
  uploadFile,
  wxGetPDF,
  wxSendCode,
  reportList,
})(main)
