import React, { Component } from 'react'
import QRCode from 'qrcode.react'
import liulanqi from '../../assets/lliulanqi.png'
import cookie from 'react-cookies'

import {
  Modal,
  Button,
  Input,
  Select,
  message,
  Radio,
  DatePicker,
  Checkbox,
  InputNumber,
  Alert,
  Spin, Badge, Calendar, Icon,
} from 'antd'
import { connect } from 'react-redux'
import {
  appointmentPageDetail2,
  appointmentSave,
  getTwoCancerToken,
  // getRootAreas,
  // getChildAreas,
  getTwoCancerIsHave,
  getAppointmentInfoList,
  appointmentUser, mealList, jscode2session, generateSign,
  appointmentCancel, forPay
} from '../../actions/account'
import './yuyue.css'

import Community from './Community'

import _ from 'lodash'
import moment from 'moment'
// import { red } from '@material-ui/core/colors';
const { Option } = Select
const { TextArea } = Input
const { confirm } = Modal
class students_yuyue extends Component {
  constructor(props) {
    super(props)

    this.state = {
      khd: '',
      hasTwoCancerRecord: false,
      // twoCancerToken: "A25ED7EC1C574D23BF9E531C5376F5A4",
      idCardError: '',
      orgName: '',
      twoToken: '',
      isSaving: false,
      org: null,
      phy: null,
      selectedDate: moment().format('YYYY-MM-DD'),
      sex: '',
      weekName: '',
      classGrade: '',
      weeks: [[], [], [], [], []],
      btnState: false,
      provinces: [],
      cities: [],
      counties: [],
      towns: [],
      time: '',
      Datas: [],
      syme: null,
      username: '',
      idCard: '',
      workUnit: '',
      phone: '',
      town: undefined,
      county: undefined,
      height: '',
      weight: '',
      occupation: '',
      education: '',
      pageTitle: '',
      communityList: [],

      dateList: [],
      timeList: [],
      date: '',
      detailId: '',
      erweimaOrg: undefined,
      erweimaPhy: undefined,

      lowinsuranceNo: '',
      poor: '',
      freeCheck: '',
      cardType: '01',
      domicileMode: '1',
      residentialAddress: '',
      domicileType: '1',
      domicileAddr: '',
      prov: undefined,
      city: undefined,
      postCode: '',
      workTel: '',
      tctNo: '',
      hpvNo: '',
      bmi: '',
      lmpStr: '',
      menophaniaAge: null,
      menstrual: '',
      cyc: null,
      isMenopause: '1',
      menopauseAge: null,
      primiparityAge: null,
      isProduction: '0',
      isLactation: '0',
      lactationTime: null,
      larcControl: '1',
      larcCondom: '',
      larcAcyeterion: '',
      larcAcyeYear: null,
      larcOther: '',
      larcOtherCont: '',
      larciud: '',
      larciudYear: null,
      gravidityTimes: null,
      deliverTimes: null,
      optName: '',
      hospitalName: '',
      sampleDate: moment().format('YYYY-MM-DD'),
      wxPayQrcode: '',
      orderId: '',

      spinloading: false,
      mealArr: [],
      mealId: '',
      mealRemark: '',
      price: '',
      erweimaName: '',
      pageType: '1',
      currentDate: '',
      timeArr: [],
      timeInd: null,
      address: '',
      mealClassify: '',
      code: '',
      pathname: '',
      pathname1: '',
      loading: false,
      appoints: [],
      mealName: '',
      newDateArr: [],
      origin: ''
    }
  }
  componentWillUnmount() {


  }
  componentDidMount() {
    this.setState({
      loading: true
    })
    const pathname = window.location.origin + window.location.pathname;
    const origin = window.location.origin

    document.title = '预约'
    var temp = [],
      that = this
    console.log(this.props.location.search.substring(1))
    var params = this.props.location.search.substring(1).split('&')
    var val = []
    var query = {}
    for (var i = 0; i < params.length; i++) {
      val = params[i].split('=')
      if (val[0] === 'erweimaName' || val[0] === 'erweimaOrgName' || val[0] === 'mealClassify' || val[0] === 'mealName') {
        query[val[0]] = decodeURI(val[1])
      } else query[val[0]] = val[1]
    }

    let pathname1 = ''
    // type=1  详情页面 3搜索页面
    if (query.type == '3') {
      this.yuyuedetail()
      return
    } else {
      pathname1 = `${pathname}?erweimaOrg=${query.erweimaOrg}&erweimaPhy=${query.erweimaPhy}&erweimaName=${query.erweimaName}&mealClassify=${query.mealClassify}&erweimaOrgName=${query.erweimaOrgName}&type=1&id=${query.id}&mealName=${query.mealName}`
      if (!query.id) {
        window.location.href = `${window.location.origin}/students_meal?erweimaOrg=${query.erweimaOrg}&erweimaPhy=${query.erweimaPhy}&erweimaName=${query.erweimaName}&erweimaOrgName=${query.erweimaOrgName}`; // 
      } else {
        if (!query.code) {
          let url = encodeURIComponent(pathname1)
          let url1 = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxfac0221bce684e03&redirect_uri=${url}&response_type=code&scope=snsapi_userinfo&state=${Math.floor(Math.random() * 9000000000) + 1000000000}#wechat_redirect`
          window.location.href = url1
        }

      }
    }

    var currentDate = moment().format('YYYY-MM-DD');

    console.log(query); // 输出类似 "2023-10-23" 这样的日期字符串
    this.setState(
      {
        org: query.erweimaOrg,
        phy: query.erweimaPhy,
        orgName: query.erweimaOrgName.replace('永林', '永宁'),
        mealId: query.id,
        erweimaName: query.erweimaOrgName,
        currentDate: currentDate,
        mealClassify: query.mealClassify,
        code: query.code,
        type: query.type,
        pageType: query.type,
        openId: '',
        pathname: pathname,
        pathname1: pathname1,
        mealName: query.mealName,
        origin: origin
      },
      () => {
        this.loadDatas(query.erweimaOrg, query.erweimaPhy, 1)
        if (!!query.code) {
          this.getJsonopenid(query.code, pathname1)
        }
      }
    )

    var locationData = query
    this.setState({
      pageTitle: locationData.erweimaName.replace('永林', '永宁'),
      occupationList1: temp,
      erweimaOrg: query.erweimaOrg.replace('永林', '永宁'),
      erweimaPhy: query.erweimaPhy.replace('永林', '永宁'),
    })

    //查找对应社区列表
    var commobj = _.find(Community, ['code', query.erweimaOrgName])
    if (commobj !== undefined) {
      this.setState({ communityList: commobj.list })
    }
    // this.props.getRootAreas().then((res) => {
    //   if (res.data.code === 200) {
    //     that.setState({ provinces: res.data.data })
    //   }
    // })
    // ========
    // var ua = window.navigator.userAgent.toLowerCase();
    // if (ua.match(/MicroMessenger/i) == 'micromessenger') {
    //   this.setState({
    //     khd: 'wx',
    //   })
    // } else {
    //   this.setState({
    //     khd: 'qt',
    //   })
    // }
    // 获取套餐
    // this.getMeal(query.erweimaOrg)
    if (window.wx) {
      // 微信JSSDK可用
      window.wx.ready(function () {
        // 在这里调用微信JSSDK的方法
      });
    } else {
      console.error('微信JSSDK未加载完成或不可用');
    }
  }
  getJsonopenid = (id, pathname1) => {
    this.props.jscode2session({
      code: id
    }).then(res => {
      if (res.data.code == '200') {
        this.setState({
          openId: res.data.openId
        })
      } else {
        let url = encodeURIComponent(pathname1)
        let url1 = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxfac0221bce684e03&redirect_uri=${url}&response_type=code&scope=snsapi_userinfo&state=123123#wechat_redirect`
        window.location.href = url1
      }

    })
  }
  getMeal = (erweimaOrg) => {
    this.props.mealList({
      page: 0,
      size: 999,
      organizationId: erweimaOrg
    }).then((msg) => {
      console.log(msg)
      if (msg.data.code === 200) {
        this.setState({
          mealArr: msg.data.data.rows,

        })
      }
    })
  }
  onyyDateChange(date, dateString) {
    console.log(date, dateString)
  }
  loadDatas = (org, phy, mark) => {
    var that = this
    var obj = {
      organizationId: org,
      typeId: Number(phy),
      currentDate: moment('2021-08-28 17:04:41').format('YYYY-MM-DD HH:mm:ss'),
      selectedDate: this.state.selectedDate,
      mealId: this.state.mealId
    }
    // var obj = { organizationId: org, physicalTypeId: Number(phy), currentDate: moment('2021-08-28 17:04:41').format("YYYY-MM-DD HH:mm:ss") };
    // message.loading("加载数据中....", 0)
    this.setState(
      { dateList: [], timeList: [], newDateArr: [] },
      () => {
        var { dateList, weeks, newDateArr } = that.state
        message.destroy()
        console.log(obj)
        that.props.appointmentPageDetail2(obj).then((msg) => {
          this.setState({
            loading: false
          })
          if (msg.data.code === 200) {
            if (!msg.data.data || msg.data.data.length === 0) {
              message.warn('当前套餐最近没有预约事务！')
              that.setState({ btnState: false })
              return
            }
            var objs = msg.data.data
            console.log(objs)
            var countDay = 0
            objs.forEach((obj) => {

              obj.startTime =
                moment(obj.startTime).format('YYYY-MM-DD') + ' 00:00:00'
              obj.endTime =
                moment(obj.endTime).format('YYYY-MM-DD') + ' 23:59:59'
              var startDate = moment(obj.startTime)
              var endDate = moment(obj.endTime)

              var d = moment()
              if (d.unix() > startDate.unix()) {
                d = moment()
              } else {
                d = startDate
              }
              for (
                d;

                d.unix() <= endDate.unix();
                d = d.add(1, 'days')
              ) {


                //从今天开始往后的   日期范围内的
                var temp = d.format('YYYY-MM-DD')
                var now = moment().format('YYYY-MM-DD')
                var day = parseInt(d.day())
                //  ;

                if (moment(now).isBefore(temp) || temp === now) {
                  dateList.push({
                    date: d.format('YYYY-MM-DD'),
                    address: obj.physicalSite,
                    id: obj.id,
                    appointmentDateDetailDTOS: obj.appointmentDateDetailDTOS
                  })

                }
                countDay++

              }
              let arr = []
              msg.data.date.map(res1 => {
                if (!!res1) {
                  if (obj.id == res1.physical) {

                    arr.details = res1.details
                    arr.id = res1.physical
                  }
                }
              })
              console.log(dateList)
              console.log(arr)
              dateList.map(res => {
                arr.details.map(res1 => {
                  if (res1.date == res.date) {
                    let currentDate1 = new Date(res1.date);
                    var dayOfWeek = currentDate1.getDay();
                    let ind = dayOfWeek == 0 ? 7 : dayOfWeek
                    res1.address = res.address
                    res1.id = res.id
                    res1.times.map(res2 => {
                      res.appointmentDateDetailDTOS.map(res3 => {
                        if (res2.times == `${res3.startTime.substring(11, 19)}-${res3.endTime.substring(
                          11,
                          19
                        )}` && ind == res3.week) {
                          res2.obj = res3
                        }
                      })
                    })
                    newDateArr.push(res1)
                  }
                })
              })
            })

            this.setState({
              newDateArr, dateList
            })
            this.getTimeArr(this.state.currentDate)

          }
        })
      }
    )
  }

  onDateChange = (value) => {
    console.log(value)
    this.setState(
      {
        date: value,
        timeList: [],
        detailId: '',
        time: '',
        syme: null,
        selectedDate: value,
      },
      () => {
        this.loadDatas(this.state.org, this.state.phy, 2)
      }
    )
  }
  ondetailIdChange = (value) => {
    var { timeList } = this.state
    this.setState({ detailId: value })
    timeList.forEach((item) => {
      if (item.id === Number(value)) {
        this.setState({
          syme: Number(item.residueNumber),
          time: `${item.startTime.substring(11, 19)}-${item.endTime.substring(
            11,
            19
          )}`,
        })
        return
      }
    })
  }

  GetAge = (strBirthday, date) => {
    var returnAge,
      strBirthdayArr = strBirthday.split('-'),
      birthYear = strBirthdayArr[0],
      birthMonth = strBirthdayArr[1],
      birthDay = strBirthdayArr[2],
      d = moment(date, 'YYYY-MM-DD'),
      nowYear = d.format('YYYY'),
      nowMonth = d.format('MM'),
      nowDay = d.format('DD')
    if (nowYear === birthYear) {
      returnAge = 0 //同年 则为0周岁
    } else {
      var ageDiff = nowYear - birthYear //年之差
      if (ageDiff > 0) {
        if (nowMonth === birthMonth) {
          /**      var dayDiff = nowDay - birthDay;//日之差
                    if (dayDiff < 0) {
                        returnAge = ageDiff - 1;
                    } else {
                        returnAge = ageDiff;
                    }*/
        } else {
          var monthDiff = nowMonth - birthMonth //月之差
          if (returnAge === 65) {
            monthDiff = monthDiff - 1
          }
          if (monthDiff < 0) {
            returnAge = ageDiff - 1
          } else {
            returnAge = ageDiff
          }
        }
      } else {
        returnAge = -1 //返回-1 表示出生日期输入错误 晚于今天
      }
    }
    return returnAge //返回周岁年龄
  }
  submit = () => {
    var that = this
    var {
      date,
      time,
      detailId,
      username,
      idCard,
      phone,
      workUnit,
      isSaving,
      classGrade,
      county,
      town,
      syme,
      height,
      weight,
      occupation,
      education,
      openId
    } = this.state
    console.log(date, time)
    if (phone.length !== 11) {
      message.error('请正确填写手机号！')
      return
    }

    if (syme <= 0) {
      message.warn('无剩余名额')

      return
    }

    if (detailId.length === 0 || date.length === 0 || time.length === 0) {
      message.warn('请选择预约时间及地点！')

      return
    }
    if (username.length === 0) {
      message.warn('姓名不得为空！')
      that.setState({ isSaving: false })
      return
    }
    if (idCard.length === 0) {
      message.warn('身份证号不得为空！')
      that.setState({ isSaving: false })
      return
    }
    if (phone.length === 0) {
      message.warn('联系电话不得为空！')
      that.setState({ isSaving: false })
      return
    }

    idCard = idCard.trim()

    var obj = {
      appointmentDetailId: Number(detailId),
      username,
      // classGrade,
      idCard: idCard.toUpperCase(),
      phone,
      appointmentDate: `${date} ${time}`,
    }

    var keys = [
      'lowinsuranceNo',
      'poor',
      'freeCheck',
      'cardType',
      'domicileMode',
      'residentialAddress',
      'domicileType',
      'domicileAddr',
      'prov',
      'city',
      'county',
      'town',
      'postCode',
      'workTel',
      'tctNo',
      'hpvNo',
      'bmi',
      'lmpStr',
      'menophaniaAge',
      'menstrual',
      'cyc',
      'isMenopause',
      'menopauseAge',
      'primiparityAge',
      'isProduction',
      'isLactation',
      'lactationTime',
      'larcControl',
      'larcCondom',
      'larcAcyeterion',
      'larcAcyeYear',
      'larcOther',
      'larcOtherCont',
      'larciud',
      'larciudYear',
      'gravidityTimes',
      'deliverTimes',
      'optName',
      'hospitalName',
      'sampleDate', 'mealId',
      'openId'
    ]
    for (var i in keys) {
      obj[keys[i]] = this.state[keys[i]]
    }

    console.log(obj)
    this.setState({
      loading: true
    })
    that.props.appointmentSave(obj).then((msg) => {
      console.log(msg)
      if (msg.data.code === 200) {
        message.info('正在调取支付页面进行支付！')
        that.someMethod(msg.data.prepayId)
      } else {
        this.setState({
          loading: false
        })
        message.warn(msg.data.message)
      }

    })
  }

  // 假设你从props或state中获取配置信息，并在某个方法中调用configWechatJsSdk
  someMethod = (prepayId) => {
    var { erweimaOrg, erweimaPhy, pageTitle, pathname, erweimaName, mealId, mealClassify, mealName } = this.state
    const pathname1 = `${pathname}?erweimaOrg=${erweimaOrg}&erweimaPhy=${erweimaPhy}&erweimaName=${pageTitle}&erweimaOrgName=${erweimaName}&mealClassify=${mealClassify}&type=2&id=${mealId}&mealName=${mealName}`
    this.props.generateSign({ url: pathname1, prepayId: prepayId }).then(res => {
      const configFromBackend = {
        beta: true, // 必须这么写，否则wx.invoke无法调用
        debug: false, // 开启调试模式
        appId: res.data.appId,
        timestamp: res.data.timestamp, // 使用后端返回的时间戳
        nonceStr: res.data.nonceStr,
        signature: res.data.signature,
        jsApiList: ['getBrandWCPayRequest'] // 需要调用的JS接口列表
      };
      this.setState({
        loading: false
      })
      this.configWechatJsSdk(configFromBackend, res.data.paySign, prepayId);
    })
  }
  configWechatJsSdk = (config, paySign, prepayId) => {
    let that = this
    // 配置JS-SDK
    window.wx.config(config);
    // 配置失败后的回调
    window.wx.error((res) => {
      // alert('初始化失败' + JSON.stringify(res))
    });
    // 配置成功后的回调
    window.wx.ready(() => {
      // 调用微信JS-SDK提供的方法
      // ...
      let obj = {
        "appId": config.appId,     //公众号ID，由商户传入     
        "timeStamp": config.timestamp,     //时间戳，自1970年以来的秒数     
        "nonceStr": config.nonceStr,      //随机串     
        "package": "prepay_id=" + prepayId,
        "signType": "RSA",     //微信签名方式：     
        "paySign": paySign //微信签名,

      }

      window.wx.invoke('getBrandWCPayRequest', obj,
        function (res) {

          if (res.err_msg == "get_brand_wcpay_request:ok") {
            that.yuyuedetail()
            //跳详情页
            // 使用以上方式判断前端返回,微信团队郑重提示：
            //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
          }
        });
    });


  }

  usernameChange = (e) => {
    this.setState({
      username: e.target.value,
    })
  }
  idCardonBlur = (e) => {
    console.log(e.target.value)
    var reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/
    var idCardError = null
    var hasTwoCancerRecord = false
    if (e.target.value.length === 18) {
      if (reg.test(e.target.value) === false) {
        message.warn('身份证号格式错误')
        idCardError = '身份证号格式错误'
      } else {
        idCardError = ''
      }
    } else {
      if (!!e.target.value) {
        idCardError = '身份证号格式错误'
      } else {
        idCardError = ''
      }

    }
    this.setState({

      idCardError,
    })

  }
  idCardChange = (e) => {
    var { date } = this.state
    if (date === undefined || date === '' || date === null) {
      message.warn('请先选择预约日期！')
      return
    }
    this.setState({
      idCard: e.target.value,

    })
  }
  workUnitChange = (e) => {
    this.setState({
      workUnit: e.target.value,
    })
  }
  classGradeChange = (e) => {
    this.setState({ classGrade: e.target.value })
  }
  phoneChange = (e) => {
    this.setState({
      phone: e.target.value,
    })
  }
  phoneBlur = () => {
    var { phone } = this.state
    let reg = /^1[3|4|5|7|8][0-9]\d{8}$/
    if (!reg.test(phone)) {
      message.error('联系电话格式有误！')
    }
  }

  townChange = (e) => {
    this.setState({
      town: e,
    })
  }
  countyChange = (e) => {
    var { counties } = this.state
    this.setState({ county: e })
    var province = _.find(counties, ['name', e])
    if (province !== undefined) {
      var that = this
      this.props
        .getChildAreas({ parentCode: province.areaCode })
        .then((res) => {
          if (res.data.code === 200) {
            that.setState({ towns: res.data.data, town: '' })
          }
        })
    }
  }

  teamChange = (e) => {
    this.setState({
      team: e.target.value,
    })
  }
  heightChange = (e) => {
    this.setState({ height: e.target.value }, () => {
      this.autoCompleteBMI()
    })
  }
  weightChange = (e) => {
    this.setState({ weight: e.target.value }, () => {
      this.autoCompleteBMI()
    })
  }
  autoCompleteBMI = () => {
    var { height, weight } = this.state
    if (height.length > 0 && weight.length > 0) {
      this.setState({
        bmi: (weight / ((height / 100) * (height / 100))).toFixed(2),
      })
    } else {
      this.setState({
        bmi: '',
      })
    }
  }
  isLastCheckChange = (value) => {
    this.setState({
      btnState: value,
    })
  }
  lowinsuranceNoChange = (e) => {
    this.setState({ lowinsuranceNo: e.target.value.trim() })
  }
  poorOnChange = (e) => {
    this.setState({ poor: e.target.value })
  }
  freeCheckOnChange = (value) => {
    this.setState({ freeCheck: value })
  }
  onCardTypeChange = (value) => {
    this.setState({ cardType: value })
  }

  idcardHosChange = (e) => {
    this.setState({ idcardHos: e.target.value.trim() })
  }
  domicileModeChange = (value) => {
    this.setState({ domicileMode: value })
  }
  residentialAddressChange = (e) => {
    this.setState({ residentialAddress: e.target.value.trim() })
  }
  domicileTypeChange = (value) => {
    this.setState({ domicileType: value })
  }
  domicileAddrChange = (e) => {
    this.setState({ domicileAddr: e.target.value.trim() })
  }
  provChange = (e) => {
    var { provinces } = this.state
    this.setState({ prov: e })
    var province = _.find(provinces, ['name', e])
    if (province !== undefined) {
      var that = this
      this.props
        .getChildAreas({ parentCode: province.areaCode })
        .then((res) => {
          if (res.data.code === 200) {
            that.setState({
              cities: res.data.data,
              towns: [],
              counties: [],
              city: undefined,
              county: undefined,
              town: undefined,
            })
          }
        })
    }
  }
  cityChange = (e) => {
    var { cities } = this.state
    this.setState({ city: e })
    var province = _.find(cities, ['name', e])
    if (province !== undefined) {
      var that = this
      this.props
        .getChildAreas({ parentCode: province.areaCode })
        .then((res) => {
          if (res.data.code === 200) {
            that.setState({
              counties: res.data.data,
              towns: [],
              county: undefined,
              town: undefined,
            })
          }
        })
    }
  }

  postCodeChange = (e) => {
    this.setState({ postCode: e.target.value.trim() })
  }
  workTelChange = (e) => {
    this.setState({ workTel: e.target.value.trim() })
  }
  tctNoChange = (e) => {
    this.setState({ tctNo: e.target.value.trim() })
  }
  hpvNoChange = (e) => {
    this.setState({ hpvNo: e.target.value.trim() })
  }
  bmiChange = (e) => {
    this.setState({ bmi: e.target.value.trim() })
  }
  menophaniaAgeChange = (e) => {
    this.setState({ menophaniaAge: e })
  }
  isMenopauseChange = (value) => {
    this.setState({
      isMenopause: value,
      menopauseAge: '',
      lmpStr: '',
      menstrual: '',
      cyc: null,
    })
  }
  menopauseAgeChange = (e) => {
    this.setState({ menopauseAge: e })
  }
  lmpStrOnChange = (value, str) => {
    console.log(value, str)
    this.setState({ lmpStr: str })
  }
  menstrualChange = (value) => {
    this.setState({ menstrual: value })
  }
  cycChange = (e) => {
    this.setState({ cyc: e })
  }
  isProductionOnChange = (e) => {
    this.setState({ isProduction: e.target.value, primiparityAge: '' })
  }
  primiparityAgeChange = (e) => {
    this.setState({ primiparityAge: e })
  }
  isLactationOnChange = (e) => {
    this.setState({ isLactation: e.target.value, lactationTime: '' })
  }
  lactationTimeChange = (e) => {
    this.setState({ lactationTime: e })
  }
  larcControlChange = (value) => {
    this.setState({
      larcControl: value,
      larcCondom: '',
      larcAcyeterion: '',
      larcAcyeYear: '',
      larcOther: '',
      larcOtherCont: '',
      larciud: '',
      larciudYear: '',
    })
  }
  larcCondomChange = (e) => {
    this.setState({ larcCondom: e.target.checked ? 1 : 0 })
  }
  larcAcyeterionChange = (e) => {
    this.setState({
      larcAcyeterion: e.target.checked ? 1 : 0,
      larcAcyeYear: '',
    })
  }
  larcOtherChange = (e) => {
    this.setState({ larcOther: e.target.checked ? 1 : 0, larcOtherCont: '' })
  }
  larcAcyeYearChange = (e) => {
    this.setState({ larcAcyeYear: e })
  }
  larcOtherContChange = (e) => {
    this.setState({ larcOtherCont: e.target.value.trim() })
  }
  larciudChange = (e) => {
    this.setState({ larciud: e.target.checked ? 1 : 0, larciudYear: '' })
  }
  larciudYearChange = (e) => {
    this.setState({ larciudYear: e })
  }
  gravidityTimesChange = (e) => {
    this.setState({ gravidityTimes: e })
  }
  deliverTimesChange = (e) => {
    this.setState({ deliverTimes: e })
  }
  jumpQuery = () => {
    this.props.history.push('/appointQuery')
  }
  goback = () => {
    // this.props.history.go(-1)
    var { origin, org, phy, erweimaName, pageTitle } = this.state
    window.location.href = `${origin}/students_meal?erweimaOrg=${org}&erweimaPhy=${phy}&erweimaName=${pageTitle}&erweimaOrgName=${erweimaName}`; // 
  }
  // 日历
  onPanelChange = (value, mode) => {
    console.log(value.format('YYYY-MM-DD'), mode);
  };
  onSelect = (newValue) => {
    console.log(newValue.format('YYYY-MM-DD'))
    this.getTimeArr(newValue.format('YYYY-MM-DD'))
  }
  dateCellRender = (value) => {
    var { newDateArr } = this.state
    var day = value.format('YYYY-MM-DD');
    if (newDateArr.length > 0) {

      for (let i = 0; i < newDateArr.length; i++) {
        if (newDateArr[i].date == day) {
          let num = null
          newDateArr[i].times.map(res => {
            num += Number(res.number)
          })
          return !!num ? (<div style={{ color: '#4158d0' }}>剩{num}</div>) : (<div style={{ color: 'red' }}>剩0</div>)
        }
      }
    }
  }
  arrDistinctByProp(arr, prop) {
    //只返回第一次出现的，重复出现的过滤掉
    return arr.filter(function (item, index, self) {
      return self.findIndex(el => el[prop] == item[prop]) === index
    })
  }

  getTimeArr = (currentDate) => {
    var { dateList, timeArr, address, newDateArr } = this.state
    let weekDay = ''
    // 创建一个表示当前日期和时间的Date对象
    console.log(newDateArr)
    console.log(dateList)

    timeArr = []
    address = ''
    let newdatearrNew = this.arrDistinctByProp(newDateArr, 'date')
    console.log(newdatearrNew)
    newdatearrNew.map(res => {
      if (res.date == currentDate) {
        timeArr = res.times
        address = res.address
      }
    })

    this.setState({
      timeArr,
      timeInd: null,
      date: currentDate,
      detailId: '',
      syme: null,
      time: '',
      address: address,
      weekName: ''
    })
  }

  topPage = (val) => {
    var { pageType } = this.state
    this.setState({
      pageType: val
    })
    if (val == '1') {
      this.reloadPage()
    }
  }
  gettimeInd = (i) => {
    var { timeArr } = this.state
    console.log(timeArr)
    if (timeArr[i].obj && timeArr[i].number != 0) {
      this.setState({
        timeInd: i,
        detailId: timeArr[i].obj.id,
        weekName: timeArr[i].obj.week,
        syme: Number(timeArr[i].number),
        time: timeArr[i].times,
      })
    } else {
      message.error('当前时间段暂无预约名额！')
    }

  }
  getweekName = (week) => {
    if (week === 1) return '周一'
    if (week === 2) return '周二'
    if (week === 3) return '周三'
    if (week === 4) return '周四'
    if (week === 5) return '周五'
    if (week === 6) return '周六'
    if (week === 7) return '周日'
  }
  reloadPage = () => {
    var { pathname1, org, phy, mealId } = this.state

    if (!org || !phy || !mealId) {
      window.location.href = `${window.location.origin}/students_meal?erweimaOrg=6f06839c79c7472d85f3e66271f0e9c5&erweimaPhy=14&erweimaName=健康体检&erweimaOrgName=知康门诊`; //
    }
    this.setState({
      pageType: '1',
      idCard: ''
    })
    this.loadDatas(org, phy, 1)
  }
  yuyuedetail = () => {
    var { idCard } = this.state
    this.setState({
      loading: true,
      pageType: '3'
    })
    if (!!idCard) {
      this.props.getAppointmentInfoList({ idCard: idCard }).then(res => {
        if (res.data.code === 200) {
          var appoints = !!res.data.data ? res.data.data : []
          appoints.map(res1 => {
            res1.istrue = false
          })
          console.log(appoints)
          this.setState({
            appoints: appoints,
          })
        }
        this.setState({
          loading: false
        })
      })
    } else {
      message.error('请输入身份证！')
      this.setState({
        loading: false,
        appoints: [],
      })
    }
  }
  serchTextChange = (e) => {
    this.setState({
      idCard: e.target.value,
    })
  }
  appointCancel = (id) => {
    var that = this
    confirm({
      content: '是否确认取消预约并退款？',
      onOk() {
        that.props.appointmentCancel({ id: id }).then((res) => {
          if (res.data.code === 200) {
            message.success('取消预约并退款成功！')
            that.yuyuedetail()
          }
        })
      },
    })
  }
  getAppoint = (orderId) => {
    let formData = new FormData()
    formData.append('orderId', orderId)
    this.props.forPay(formData).then(res1 => {
      if (res1.data.code == '200') {
        this.someMethod(res1.data.prepayId)
      }
    })
  }
  clickIstrue = (ind) => {
    var { appoints } = this.state
    console.log(appoints[ind])
    appoints[ind].istrue = !appoints[ind].istrue
    this.setState({
      appoints
    })
  }
  render() {
    const {
      weekName,
      syme,
      timeList,
      detailId,
      date,
      dateList,
      erweimaOrg,
      pageTitle,
      username,
      idCard,
      workUnit,
      phone,
      classGrade,
      county,
      village,
      height,
      weight,
      btnState,
      khd, address,
      wxPayQrcode, mealArr, timeArr, timeInd, time,
      mealRemark, price, pageType, currentDate,
      mealClassify, erweimaName, loading, appoints, mealName
    } = this.state

    return (
      <div>
        <div className="StatisticsPage-head1 pageHeader1">
          <h3>{pageTitle}预约</h3>
        </div>
        {khd == 'wx' ? (
          <div id="container" >
            <div style={{ position: 'relative', paddingTop: '100px', color: '#333', fontSize: '22px', textAlign: 'center' }}>
              <i style={{ position: 'absolute', top: '10px', right: '10px', fontSize: '100px' }}></i>
              <p style={{ fontWeight: '600' }}>请点击屏幕右上角 [ ··· ] </p>
              <p>在 <img src={liulanqi} alt="" srcset="" style={{ width: '25px' }} /> 浏览器打开</p></div>
          </div>
        ) : (<div>
          {/* <div style={{ display: 'flex', fontWeight: 'bold' }}>如您已预约，请点击：<Button
            type="danger"
            size="small"
            style={{ width: '40%', }}
            onClick={() => this.jumpQuery()}
          >
            查询已预约
          </Button></div> */}
          <Spin spinning={loading}>
            <div style={{
              width: "100%", height: '40px', lineHeight: '40px', textAlign: 'center', background: "#fff", display: 'flex',
              justifyContent: 'space-around'
            }}>
              <div style={{
                width: "100px", borderBottom: pageType != '3' ? '1px solid red' : '',
                textAlign: 'center'
              }} onClick={this.reloadPage}>预    约</div>
              <div style={{ width: "100px", borderBottom: pageType == '3' ? '1px solid red' : '' }} onClick={this.yuyuedetail}>预约记录</div>
            </div>
            <div style={{ textAlign: 'center', padding: '10px 0' }}>南京知康门诊(南京知康体检中心)</div>
            {pageType == '1' ? (
              //日历
              <div>
                <div style={{ background: '#fff' }}>
                  <Calendar dateCellRender={this.dateCellRender} onPanelChange={(value, mode) => this.onPanelChange(value, mode)} onSelect={(e) => this.onSelect(e)} style={{ margin: '0px 20px 0px 6px' }} />
                </div>

                <div style={{ background: '#fff', padding: '10px 20px', marginBottom: '30px', marginTop: '10px' }}>
                  预约日期
                  {timeArr.length > 0 ? (
                    <div style={{ display: 'flex', flexWrap: "wrap", justifyContent: 'space-around' }}>
                      {timeArr.map((res, i) => (
                        <div key={i} onClick={() => this.gettimeInd(i)} style={{ background: i == timeInd ? 'rgba(65, 88, 208, 0.3)' : '', padding: '8px 10px ', border: '1px dashed  #4158d0', marginRight: "10px", marginTop: '10px' }}>{res.times}</div>
                      )
                      )}
                    </div>
                  ) : (<div style={{
                    fontSize: '20px',
                    fontWeight: 'bold',
                    textAlign: 'center',
                    color: 'red'
                  }}>暂无信息</div>)}
                </div>
                <div style={{
                  padding: '10px', position: 'sticky',
                  width: '100%',
                  bottom: '10px'
                }}>
                  <Button style={{ width: '45%', marginRight: "10%" }} size='large' onClick={() => this.goback()}>返回</Button>
                  <Button disabled={
                    timeInd == null
                  } type="primary" style={{ width: '45%' }} size='large' onClick={() => this.topPage('2')}>下一页</Button>
                </div>
              </div>

            ) : pageType == '2' ? (
              // 表单
              <div className="StatisticsPage-search two_cancer_appoint" style={{ position: 'relative', }}>
                <div style={{ background: '#fff' }}>
                  <div className="form-heng">
                    <label className="heng_label  require">套餐分类：</label>
                    <div className="heng_con">
                      {mealClassify}
                    </div>
                  </div>
                  <div className="form-heng">
                    <label className="heng_label  require">套餐名称：</label>
                    <div className="heng_con">
                      {mealName}
                    </div>
                  </div>
                  <div className="form-heng" >
                    <label className="heng_label require">预约日期：</label>
                    <div className="heng_con">
                      {/* <Select
                    className={
                      date && date.length > 0 ? 'ipt1' : ' ipt1 require_input'
                    }
                    allowClear={false}
                    value={date}
                    onChange={this.onDateChange}
                  >
                    {dateList.map((item, index) => {
                      return <Option key={item.date}>{item.date}</Option>
                    })}
                  </Select>
                  {weekName.length > 0 && <span>({weekName})</span>} */}
                      {date} ({this.getweekName(weekName)})
                    </div>
                  </div>
                  <div className="form-heng">
                    <label className="heng_label  require">时间点：</label>
                    <div className="heng_con">
                      {time}
                      {/* <Select
                    className={
                      detailId && detailId.length > 0
                        ? 'ipt1'
                        : ' ipt1 require_input'
                    }
                    allowClear={false}
                    value={detailId}
                    onChange={this.ondetailIdChange}
                  >
                    {timeList.map((date, index) => {
                      return (
                        <Option key={date.id}>
                          {date.startTime.substring(11, 16)}~
                          {date.endTime.substring(11, 16)}
                        </Option>
                      )
                    })}
                  </Select> */}
                    </div>
                  </div>
                  <div className="form-heng">
                    <label className="heng_label  require">地址：</label>
                    <div className="heng_con">
                      {address}
                      {/* {date &&
                    _.find(dateList, ['date', date]) &&
                    _.find(dateList, ['date', date]).address} */}
                    </div>
                  </div>
                  <div className="form-heng">
                    <label className="heng_label">剩余名额：</label>
                    <div className="heng_con">
                      {syme == null || syme > 0 ? (
                        <span style={{ color: 'green' }}>{syme}</span>
                      ) : (
                        <span style={{ color: 'red' }}>暂无名额</span>
                      )}
                    </div>
                  </div>


                  <div className="form-heng">
                    <label className="heng_label require">姓名：</label>
                    <div className="heng_con">
                      <Input
                        placeholder="请输入姓名"
                        value={username} bordered={false}
                        onChange={(e) => this.usernameChange(e)}
                      />
                    </div>
                  </div>
                  <div className="form-heng">
                    <label className="heng_label">证件类型：</label>
                    <div className="heng_con">
                      <Select
                        className="ipt1"
                        allowClear={false} bordered={false}
                        placeholder="请选择证件类型"
                        value={this.state.cardType}
                        onChange={this.onCardTypeChange}
                        disabled
                      >
                        <Option key={'01'}>居民身份证</Option>
                        <Option key={'02'}>居民户口簿</Option>
                        <Option key={'03'}>护照</Option>
                        <Option key={'04'}>军官证</Option>
                        <Option key={'05'}>驾驶证</Option>
                        <Option key={'06'}>港澳居民来往内地通行证</Option>
                        <Option key={'07'}>台湾居民来往内地通行证</Option>
                        <Option key={'99'}>其他法定有效证件</Option>
                      </Select>
                    </div>
                  </div>
                  <div className="form-heng">
                    <label className="heng_label require">身份证号：</label>
                    <div className="heng_con">
                      <Input
                        placeholder="身份证号"
                        value={idCard}
                        bordered={false}
                        onChange={(e) => this.idCardChange(e)}
                        onBlur={(e) => this.idCardonBlur(e)}
                      />
                    </div>
                  </div>
                  {this.state.sex && this.state.sex.length !== 0 && (
                    <div className="form-group">
                      <label className="form-label1">性别：</label>
                      <div className="form-ipt">
                        <Input
                          placeholder="身份证号自动识别"
                          value={this.state.sex}
                          disabled
                        />
                      </div>
                    </div>
                  )}
                  <div className="form-heng">
                    <label className="heng_label require">联系电话：</label>
                    <div className="heng_con">
                      <Input
                        placeholder="联系电话"
                        bordered={false}
                        value={phone}
                        onChange={(e) => this.phoneChange(e)}
                        onBlur={this.phoneBlur}
                      />
                    </div>
                  </div>
                </div>
                <div style={{ color: 'red', margin: '8px 20px 70px 20px' }}>注：请务必输入体检人正确的信息</div>

                {
                  !!wxPayQrcode ? (
                    <a href={wxPayQrcode} style={{ display: 'block', width: '100%', height: "40px", lineHeight: '40px', textAlign: 'center', background: '#2979ff', color: '#fff' }}>请点击支付</a>
                  ) : (
                    <div style={{
                      padding: '10px', position: 'fixed',
                      width: '100%',
                      bottom: '10px'
                    }}>
                      <Button style={{ width: '45%', marginRight: "10%" }} size='large' onClick={() => this.topPage('1')}>上一页</Button>
                      <Button
                        type="primary"
                        size='large'
                        style={{ width: '45%' }}
                        disabled={
                          this.state.idCardError ||
                          syme <= 0 ||
                          this.state.hasTwoCancerRecord
                        }
                        onClick={this.submit}
                      >
                        确定提交
                      </Button>
                    </div>
                  )
                }


                {this.state.idCardError && (
                  <Alert message={this.state.idCardError} type="error" />
                )}

              </div>
            ) : (<div>
              <div style={{ textAlign: 'center', paddingBottom: '10px', background: '#fff', paddingTop: '10px', marginBottom: '10px' }}>
                <Input.Search
                  allowClear
                  style={{
                    width: '92%',
                  }}
                  placeholder='请输入证件号'
                  value={idCard}
                  onChange={(e) => this.serchTextChange(e)}
                  onSearch={() => this.yuyuedetail()}
                />
              </div>
              <div style={{ background: '#fff', padding: '10px 20px' }}>
                {appoints.length === 0 ? (
                  <div style={{
                    fontSize: '20px',
                    fontWeight: 'bold',
                    textAlign: 'center',
                    padding: '50px 0',
                    marginTop: '10px',
                  }}> <div>暂无预约，请重新扫码预约</div>
                    <div>如有取消预约，预约时费用退款已原路返回</div></div>

                ) : (
                  appoints.map((item, index) => (
                    <div style={{ marginTop: '10px' }}>
                      <div style={{ fontSize: '17px', fontWeight: 'bold', paddingBottom: '8px' }}>{item.appointmentDate}</div>
                      <div style={{ fontSize: '15px', color: '#999', paddingBottom: '8px' }}>{item.idCard}</div>
                      <div style={{
                        fontSize: '15px', color: '#999', paddingBottom: '8px', display: 'flex',
                        justifyContent: ' space-between'
                      }}>
                        <div>{item.physicalSite}</div>
                        <div onClick={() => this.clickIstrue(index)} style={{ width: '110px', textAlign: 'center', color: '#409EFF' }}>{item.istrue ? '▲收起' : '▼展开'}</div>
                      </div>
                      {
                        item.istrue && (
                          <div>
                            <div style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center'
                            }}>
                              <div>
                                <div style={{ fontSize: '15px', paddingBottom: '8px' }}>套餐名称:{item.mealName}</div>
                                <div style={{ fontSize: '15px', paddingBottom: '8px' }}>体检类型:{item.physicalTypeName}</div>
                                <div style={{ fontSize: '15px', paddingBottom: '8px' }}>订单状态: {item.orderStatus == '0'
                                  ? '无需支付'
                                  : item.orderStatus == '1'
                                    ? '待付款'
                                    : item.orderStatus == '2'
                                      ? '待使用'
                                      : item.orderStatus == '3'
                                        ? '已完成'
                                        : ''}</div>
                              </div>
                              <div>
                                {item.orderStatus == '3' ? (<span></span>) : (
                                  <div>
                                    <Button
                                      danger
                                      type="primary"
                                      size="middle"
                                      onClick={() => this.appointCancel(item.id)}
                                    >
                                      取消预约并退款
                                    </Button>
                                  </div>
                                )}
                              </div>
                            </div>
                            {item.orderStatus == '2' && (
                              <div style={{ width: '100%', textAlign: 'center', fontWeight: 'bold', fontSize: "17px" }}>
                                <QRCode value={item.orderId} />
                                <div>请出示二维码进行核销</div>
                              </div>
                            )}
                            {item.orderStatus == '1' && (
                              <div style={{ textAlign: 'center' }}>
                                <Button style={{ width: '50%' }} size='middle' type="primary" onClick={() => this.getAppoint(item.orderId)}>支付</Button>
                              </div>
                            )}

                          </div>
                        )
                      }

                    </div>
                  ))
                )
                }
              </div>

            </div>)
            }
          </Spin>
        </div >)
        }




      </div>
    )
  }
}
export default connect(null, {
  appointmentPageDetail2,
  appointmentSave,
  getTwoCancerToken,
  // getRootAreas,
  // getChildAreas,
  getTwoCancerIsHave,
  getAppointmentInfoList, forPay,
  appointmentUser, mealList, jscode2session, generateSign, appointmentCancel
})(students_yuyue)
