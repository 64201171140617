import React, { Component } from 'react'
import { connect } from 'react-redux'
import './Appoint.css'
import { Input, Button } from 'antd';

class AppointmentOutside extends Component {
   constructor(props){
       super(props);
       this.state={

       }
   }


    render() {
        return (
            <div>
                <div className="StatisticsPage-head pageHeader"><h3>门诊外检预约</h3></div>
                    <div className="form-group">
                        <label className="form-label">选择体检地点：</label>
                        <div className="form-ipt">
                            <Input placeholder="姓名(必填)" onChange={e => this.nameChange(e)} />
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="form-label">预约日期：</label>
                        <div className="form-ipt">
                            <Input placeholder="姓名(必填)" onChange={e => this.nameChange(e)} />
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="form-label">预约时段：</label>
                        <div className="form-ipt">
                            <Input placeholder="姓名(必填)" onChange={e => this.nameChange(e)} />
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="form-label">剩余名额：</label>
                        <div className="form-ipt">
                            <Input placeholder="姓名(必填)" onChange={e => this.nameChange(e)} />
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="form-label">姓名：</label>
                        <div className="form-ipt">
                            <Input placeholder="姓名(必填)" onChange={e => this.nameChange(e)} />
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="form-label">身份证号：</label>
                        <div className="form-ipt">
                            <Input placeholder="姓名(必填)" onChange={e => this.nameChange(e)} />
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="form-label">行业：</label>
                        <div className="form-ipt">
                            <Input placeholder="姓名(必填)" onChange={e => this.nameChange(e)} />
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="form-label">所在单位：</label>
                        <div className="form-ipt">
                            <Input placeholder="姓名(必填)" onChange={e => this.nameChange(e)} />
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="form-label">联系电话：</label>
                        <div className="form-ipt">
                            <Input placeholder="姓名(必填)" onChange={e => this.nameChange(e)} />
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="form-label">现住址：</label>
                        <div className="form-ipt">
                            <Input placeholder="姓名(必填)" onChange={e => this.nameChange(e)} />
                        </div>
                    </div>
                    
                    <div className="form-group">
                        <label className="form-label">身高：</label>
                        <div className="form-ipt">
                            <Input placeholder="姓名(必填)" onChange={e => this.nameChange(e)} />
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="form-label">体重：</label>
                        <div className="form-ipt">
                            <Input placeholder="姓名(必填)" onChange={e => this.nameChange(e)} />
                        </div>
                    </div>
                    <div className="form-group">
                        <Button type="primary" style={{width:'100%'}}>确定提交</Button>
                    </div>
                </div>
        )
    }
}
export default connect(null, {  })(AppointmentOutside) 