import React, { Component } from 'react'
import './RegistrationPage.css'
import {
  Modal,
  Button,
  Input,
  Table,
  Empty,
  message,
  Spin,
  DatePicker,
  Select,
  Checkbox,
} from 'antd'
import { connect } from 'react-redux'
import _ from 'lodash'
import moment from 'moment'
import JsBarcode from 'jsbarcode'
import XLSX from 'xlsx'

import {
  importRegisterInfo,
  getOrganizationGuahaoList,
  getPhysicalTypeList,
  getAllorganization,
  guahaoDel,
  physicalRecord_remove, getParentList, sampleInfoinsert
} from '../../../actions/account'

const { RangePicker } = DatePicker
const dateFormat = 'YYYY-MM-DD hh:mm:ss'
const { Column } = Table
const { Option } = Select
const { confirm } = Modal
const CheckboxGroup = Checkbox.Group
class StatisticsPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      printRecord: { username: '', idCard: '' },
      printRecordList: [],
      curRecord: undefined,
      resultVisble: false,
      phyId: '',
      physicalTypeList: [],
      guahaoList: [],
      organizationList: [],
      page: 1,
      total: 0,
      dataList: [],
      organList: [],
      unitname: '',
      workUnit: '',
      startAge: '',
      endAge: '',
      collection: '',
      barcode: '',
      uname: '',
      startTime: undefined,
      endTime: undefined,
      choosedDates: [], //时间默认值
      idcard: '',
      pageSize: 10,
      selectedRowKeys: [],
      selectedRowInfoKeys: [],
      organId: undefined,
      sex: null,
      spinning: false,
      cbValue: false,
      descrArr: [],
      visible: false,
      getdescrArr: []
    }
  }

  componentDidMount() {
    var that = this

    this.props.getPhysicalTypeList().then((msg) => {
      if (msg.data.code === 200) {
        that.setState({
          physicalTypeList: msg.data.data,
        })
      }
    })
    this.props.getAllorganization().then((msg) => {
      if (msg.data.code === 200) {
        that.setState(
          {
            organizationList: msg.data.data,
          },
          () => {
            that.loadDatas()
          }
        )
      }
    })
    this.props.getParentList().then((msg) => {
      console.log(msg)
      if (msg.data.code === 200) {
        this.setState({
          descrArr: msg.data.data,

        })
      }
    })
  }

  onsexSelectChange = (value) => {
    this.setState({
      sex: value,
    })
  }
  onOrganSelectChange = (value) => {
    this.setState({
      organId: value,
    })
  }
  onphySelectChange = (value) => {
    this.setState({
      phyId: value,
    })
  }

  exportExcel = () => {
    var { startTime, endTime, organId, phyId } = this.state
    // var that = this;
    console.log(startTime, endTime)
    if (!startTime || !endTime) {
      message.warn('请选择起始时间!')
      return
    }
    var obj = { startTime, endTime }
    if (organId && organId.length > 0) {
      obj.organizationId = organId
    }
    if (phyId !== undefined) {
      obj.physicalType = phyId
    }
    this.props.importRegisterInfo(obj).then((res) => {
      const fileName = 'export' + moment().format('YYYYMMDDHHmmss')
      if ('download' in document.createElement('a')) {
        // 非IE下载
        const url = window.URL.createObjectURL(
          new Blob(
            [res.data],
            // 设置该文件的mime类型，这里对应的mime类型对应为.xlsx格式
            { type: 'application/vnd.ms-excel' }
          )
        )
        const link = document.createElement('a')
        link.href = url
        // 从header中获取服务端命名的文件名
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
      } else {
        // IE10+下载
        navigator.msSaveBlob(res.data, fileName)
      }
    })
  }
  reset = () => {
    this.setState(
      {
        choosedDates: [],
        startTime: undefined,
        endTime: undefined,
        barcode: undefined,
        uname: undefined,
        idCard: undefined,
        organId: undefined,
        phyId: undefined,
        collection: undefined,
        workUnit: undefined,
        startAge: '',
        endAge: '',
        sex: ''
      },
      () => {
        this.loadDatas()
      }
    )
  }
  loadDatas = () => {
    this.setState({
      spinning: true,
      selectedRowInfoKeys: [],
      selectedRowKeys: [],
    })
    var {
      page,
      startTime,
      endTime,
      barcode,
      uname,
      pageSize,
      idCard,
      organId,
      sex,
      phyId,
      startAge,
      endAge,
      workUnit,
      collection,
    } = this.state
    var that = this
    var obj = {
      page: page - 1,
      size: pageSize,
    }
    if (collection !== '' && collection !== undefined) {
      obj.collection = collection
    }
    if (workUnit !== '' && workUnit !== undefined) {
      obj.workUnit = workUnit
    } if (startAge !== '' && startAge !== undefined) {
      obj.startAge = startAge
    }
    if (endAge !== '' && endAge !== undefined) {
      obj.endAge = endAge
    }

    if (startTime !== '' && startTime !== undefined) {
      obj.startTime = startTime
    }
    if (organId !== '' && organId !== undefined) {
      obj.organizationId = organId
    } else {
      if (JSON.parse(localStorage.getItem('data')).isAdmin !== 1) {
        obj.organizationId = JSON.parse(
          localStorage.getItem('data')
        ).organizationId
      }
    }
    if (idCard !== '' && idCard !== undefined) {
      obj.idCard = idCard
    }
    if (sex !== '' && sex !== undefined) {
      obj.sex = sex
    }
    if (endTime !== '' && endTime !== undefined) {
      obj.endTime = endTime
    }
    if (phyId !== '' && phyId !== undefined) {
      obj.physicalType = phyId
    }
    if (barcode !== '' && barcode !== undefined) {
      obj.barcode = barcode
    }
    if (uname !== '' && uname !== undefined) {
      obj.username = uname
    }
    this.props.getOrganizationGuahaoList(obj).then((msg) => {
      that.setState({
        spinning: false,
      })
      if (msg.data.code === 200) {
        if (msg.data.data.rows.length === 0 && msg.data.data.total !== 0) {
          that.setState({ page: page - 1 }, () => {
            that.loadDatas()
          })
        } else {
          that.setState({
            dataList: msg.data.data.rows,
            total: msg.data.data.total,
          })
        }
      }
    })
  }

  unitnameOnChange = (e) => {
    this.setState({
      unitname: e.target.value,
    })
  }

  barcodeOnChange = (e) => {
    this.setState({
      barcode: e.target.value,
    })
  }

  nameOnChange = (e) => {
    this.setState({
      uname: e.target.value,
    })
  }

  datetimeChange = (date, dateString) => {
    // var choseDate =[];
    // choseDate = _.concat(choseDate,moment(dateString[0]+" 00:00:00","YYYY-MM-DD hh:mm:ss"));
    // choseDate = _.concat(choseDate,moment(dateString[1]+" 23:59:59","YYYY-MM-DD hh:mm:ss"));
    this.setState({
      startTime: dateString[0] === '' ? undefined : dateString[0] + ' 00:00:00',
      endTime: dateString[1] === '' ? undefined : dateString[1] + ' 23:59:59',
      choosedDates: date,
    })
  }

  idCardOnChange = (e) => {
    this.setState({
      idCard: e.target.value,
    })
  }

  delItem = (id) => {
    var { dataList } = this.state
    // if (selectedRowInfoKeys.length === 0) {
    //     message.error("请选择删除的记录")
    //     return;
    // }
    var that = this
    // var barcode = ( _.find(dataList, ['id', id])).barcode;
    // console.log(barcode)
    confirm({
      content: '确认删除？',
      onOk() {
        that.props.physicalRecord_remove({ id: id }).then((msg) => {
          if (msg.data.code === 200) {
            message.success('删除成功！')
            that.loadDatas()
          }
        })
      },
    })
  }

  editItem = () => {
    var { selectedRowKeys, dataList } = this.state
    if (selectedRowKeys.length === 0) {
      message.error('请选择编辑记录')
      return
    }
    if (selectedRowKeys.length > 1) {
      message.error('选择记录过多，请选择单条记录')
      return
    }
    var curRecord = _.find(dataList, ['id', selectedRowKeys[0]])
    this.props.history.push({
      pathname: '/admin/medicalRecord',
      state: {
        idCard: curRecord.idCard,
        curRecord: JSON.stringify(curRecord)
      },
    })
  }

  changePage = (page) => {
    var that = this
    this.setState(
      { page: page, selectedRowKeys: [], selectedRowInfoKeys: [] },
      () => {
        that.loadDatas()
      }
    )
  }

  pageSizeOnChange = (current, size) => {
    var that = this
    this.setState({ pageSize: size, selectedRowKeys: [] }, () => {
      that.loadDatas()
    })
  }
  // infoId
  // 选中改变
  onSelectChange = (selectedRowKeys) => {
    var { dataList } = this.state
    this.setState({ selectedRowKeys })
    var barcodes = []
    selectedRowKeys.forEach((selectedRowKey) => {
      barcodes = _.concat(
        barcodes,
        _.find(dataList, ['id', selectedRowKey]).barcode
      )
    })
    this.setState({ selectedRowInfoKeys: selectedRowKeys })
  }

  tickexportExcel = () => {
    var { selectedRowInfoKeys, dataList } = this.state
    if (selectedRowInfoKeys.length === 0) {
      message.error('请选择记录')
      return
    }
    var tempList = []
    selectedRowInfoKeys.forEach((item, index) => {
      tempList.push(_.find(dataList, ['id', item]))
    })
    var initColumn = [
      {
        title: '条码',
        dataIndex: 'barcode',
        key: 'barcode',
        className: 'text-monospace',
      },
      {
        title: '姓名',
        dataIndex: 'username',
        key: 'username',
        className: 'text-monospace',
      },
      {
        title: '性别',
        dataIndex: 'sex',
        key: 'sex',
        className: 'text-monospace',
      },
      {
        title: '生日',
        dataIndex: 'birthday',
        key: 'birthday',
        className: 'text-monospace',
      },
      {
        title: '身份证号',
        dataIndex: 'idCard',
        key: 'idCard',
        className: 'text-monospace',
      },
      {
        title: '家庭住址',
        dataIndex: 'address',
        key: 'address',
        className: 'text-monospace',
      },
      {
        title: '工作单位',
        dataIndex: 'workUnit',
        key: 'workUnit',
        className: 'text-monospace',
      },
      {
        title: '手机号',
        dataIndex: 'phone',
        key: 'phone',
        className: 'text-monospace',
      },
      {
        title: '创建时间',
        dataIndex: 'entryTime',
        key: 'entryTime',
        className: 'text-monospace',
      },
      {
        title: '样本检测编号',
        dataIndex: 'checkSampleNumber',
        key: 'checkSampleNumber',
        className: 'text-monospace',
      },
    ]

    this.exportExcel3(initColumn, tempList, '采样人员名单.xlsx')
  }

  exportExcel3 = (headers, data, fileName = '请假记录表.xlsx') => {
    const _headers = headers
      .map((item, i) =>
        Object.assign(
          {},
          {
            key: item.key,
            title: item.title,
            position:
              (i < 26
                ? String.fromCharCode(65 + i)
                : String.fromCharCode(parseInt(i / 26) + 64) +
                String.fromCharCode(65 + (i % 26))) + 1,
          }
        )
      )
      .reduce(
        (prev, next) =>
          Object.assign({}, prev, {
            [next.position]: { key: next.key, v: next.title },
          }),
        {}
      )

    const _data = data
      .map((item, i) =>
        headers.map((key, j) =>
          Object.assign(
            {},
            {
              content: item[key.key],
              position:
                (j < 26
                  ? String.fromCharCode(65 + j)
                  : String.fromCharCode(parseInt(j / 26) + 64) +
                  String.fromCharCode(65 + (j % 26))) +
                (i + 2),
            }
          )
        )
      )
      // 对刚才的结果进行降维处理（二维数组变成一维数组）
      .reduce((prev, next) => prev.concat(next))
      // 转换成 worksheet 需要的结构
      .reduce(
        (prev, next) =>
          Object.assign({}, prev, { [next.position]: { v: next.content } }),
        {}
      )
    console.log(_data)
    console.log(_headers)
    // 合并 headers 和 data
    const output = Object.assign({}, _headers, _data)
    console.log(output)
    // 获取所有单元格的位置
    const outputPos = Object.keys(output)
    // 计算出范围 ,["A1",..., "H2"]
    const ref = `${outputPos[0]}:${outputPos[outputPos.length - 1]}`

    // 构建 workbook 对象
    const wb = {
      SheetNames: ['mySheet'],
      Sheets: {
        mySheet: Object.assign({}, output, {
          '!ref': ref,
          '!cols': [
            { wpx: 45 },
            { wpx: 100 },
            { wpx: 200 },
            { wpx: 80 },
            { wpx: 150 },
            { wpx: 100 },
            { wpx: 300 },
            { wpx: 300 },
          ],
        }),
      },
    }

    // 导出 Excel
    XLSX.writeFile(wb, fileName)
  }
  barcodePrint = () => {
    var { selectedRowInfoKeys, dataList } = this.state
    if (selectedRowInfoKeys.length === 0) {
      message.error('请选择记录')
      return
    }
    var that = this
    var barcodeTEMP = ''
    var tempList = []
    selectedRowInfoKeys.forEach((item, index) => {
      tempList.push(_.find(dataList, ['id', item]))
      that.setState({ printRecordList: tempList }, () => {
        barcodeTEMP = _.find(dataList, ['id', item]).barcode
        JsBarcode(this['BARCODE1_' + barcodeTEMP], barcodeTEMP, {
          text: barcodeTEMP,
          format: 'CODE39',
          displayValue: true,
          width: 0.7,
          height: 50,
          fontSize: 10,
          margin: 0,
          textAlign: 'left',
          textMargin: 0,
        })
        if (this.state.cbValue) {
          JsBarcode(this['BARCODE2_' + barcodeTEMP], barcodeTEMP, {
            text: barcodeTEMP,
            format: 'CODE39',
            displayValue: true,
            width: 0.7,
            height: 50,
            fontSize: 10,
            margin: 0,
            textAlign: 'left',
            textMargin: 0,
          })
        }
      })
    })
    setTimeout(() => {
      that.preview()
    }, 1000)
  }
  /**
   * 打印条码
   */
  preview = (barcode = null) => {
    // JsBarcode(this.barcode, barcode, {
    //     text: barcode,
    //     format: "CODE39",
    //     displayValue: true,
    //     width: 1,
    //     height: 50,
    //     fontSize: 10,
    //     margin: 0,
    //     textAlign: "left",
    //     textMargin: 0
    // });
    // JsBarcode(this.barcode1, barcode, {
    //     text: barcode,
    //     format: "CODE39",
    //     displayValue: true,
    //     width: 1,
    //     height: 50,
    //     fontSize: 10,
    //     margin: 0,
    //     textAlign: "left",
    //     textMargin: 0
    // });
    const printDiv = document.getElementById('printDiv')
    const iframe = document.createElement('IFRAME')
    let doc = null
    iframe.setAttribute(
      'style',
      'position:absolute;width:0px;height:0px;left:0px;top:0px;'
    )
    document.body.appendChild(iframe)
    doc = iframe.contentWindow.document
    // 打印时去掉页眉页脚
    doc.write('<style media="print">@page {size: auto;  margin: 0mm; }</style>')
    doc.write(printDiv.innerHTML)
    doc.close()
    // 获取iframe的焦点，从iframe开始打印
    iframe.contentWindow.focus()
    iframe.contentWindow.print()
    if (navigator.userAgent.indexOf('MSIE') > 0) {
      //打印完删除iframe
      document.body.removeChild(iframe)
    }
  }
  startNumOnChange = (e) => {
    this.setState({ startAge: e.target.value })
  }

  endNumOnChange = (e) => {
    this.setState({ endAge: e.target.value })
  }
  workUnitOnChange = (e) => {
    this.setState({ workUnit: e.target.value })
  }
  collectionOnChange = (e) => {
    this.setState({ collection: e.target.value })
  }
  cbonChange = (e) => {
    this.setState({
      cbValue: e.target.checked,
    })
  }
  addInsert = (e) => {
    var { selectedRowInfoKeys } = this.state
    if (selectedRowInfoKeys.length === 0) {
      message.error('请选择记录')
      return
    }
    this.setState({
      getdescrArr: [],
      visible: true
    })
  }
  handleBindCancel = () => {
    this.setState({
      visible: false,
      getdescrArr: [],
    })
  }
  handleBindOk = () => {
    var { selectedRowInfoKeys, dataList, getdescrArr, descrArr } = this.state
    if (getdescrArr.length === 0) {
      message.error('请勾选项目名称')
      return
    }
    let arr1 = []
    let arr2 = []
    let commonItems = descrArr.filter(item1 =>
      getdescrArr.some(item2 => item1.id === item2)
    );
    commonItems.map(res => {
      arr1.push(res.projectName)
      arr2.push(res.projectCode)
    })
    var itmObj = {}
    var itmObj1 = {}
    this.setState({
      visible: false,
    })
    for (var i = 0; i < selectedRowInfoKeys.length; i++) {
      message.info("正在处理第" + i + "/" + selectedRowInfoKeys.length + "个")
      itmObj = _.find(dataList, ['id', selectedRowInfoKeys[i]])
      itmObj1 = {
        descr: arr1.join(),
        desccode: arr2.join(),
        barcode: itmObj.barcode,
        pname: itmObj.username,
        psex: itmObj.sex == 1 ? '男' : itmObj.sex == 2 ? '女' : "3",
        psextype: !!itmObj.sex ? itmObj.sex : 3,
        page: itmObj.age,
        pagetype: '1',
        sampletime: itmObj.entryTime,
        stype: '',
        idcard: itmObj.idCard,
        tel: itmObj.phone,
      }
      console.log(itmObj1)
      this.props.sampleInfoinsert(itmObj1).then((msg) => {
        message.success(msg.data.message)
      })
    }
  }
  ongetdescrArrCheckBoxChange = (e) => {
    console.log(e)
    this.setState({
      getdescrArr: e
    })
  }
  render() {
    const {
      organId, sex,
      phyId,
      selectedRowKeys,
      dataList,
      barcode,
      uname,
      idCard,
      spinning,
      choosedDates,
      physicalTypeList,
      cbValue,
      organizationList,
      startAge,
      endAge,
      workUnit,
      collection, visible, descrArr,
      getdescrArr
    } = this.state
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    }
    return (
      <div>
        <div className="StatisticsPage-head pageHeader">
          <h3>挂号人员统计</h3>
        </div>
        <div className="StatisticsPage-box has-select-table">
          <div className="StatisticsPage-search">
            <div className="StatisticsPage-btn">
              <div id="printDiv" style={{ display: 'none' }}>
                {this.state.printRecordList.map((record, index) => (
                  <div
                    key={index}
                    style={{ display: 'block', overflow: 'hidden' }}
                  >
                    <div style={{ height: '110px' }}>
                      <p style={{ fontSize: 15, padding: 0, margin: 0 }}>
                        {record.username + '       '}{' '}
                        {record.workUnit !== null && record.workUnit}
                      </p>
                      {/* <p style={{ 'fontSize': 10,  padding: 0, margin: 0 }}>  {record.idCard}</p> */}
                      <div style={{ fontSize: 0 }}>
                        <svg
                          ref={(ref) => {
                            this['BARCODE1_' + record.barcode] = ref
                          }}
                        />
                      </div>
                      <p style={{ fontSize: 15, padding: 0, margin: 0 }}>
                        {' '}
                        {record.collection !== undefined &&
                          record.collection !== null &&
                          record.collection + '   '}
                      </p>
                    </div>
                    {cbValue && (
                      <div style={{ height: '110px' }}>
                        <p style={{ fontSize: 15, padding: 0, margin: 0 }}>
                          {record.username + '    '}{' '}
                          {record.workUnit !== null && record.workUnit}
                        </p>
                        {/* <p style={{ 'fontSize': 10,  padding: 0, margin: 0 }}>  {record.idCard}</p> */}
                        <div style={{ fontSize: 0 }}>
                          <svg
                            ref={(ref) => {
                              this['BARCODE2_' + record.barcode] = ref
                            }}
                          />
                        </div>
                        <p style={{ fontSize: 15, padding: 0, margin: 0 }}>
                          {' '}
                          {record.collection !== undefined &&
                            record.collection !== null &&
                            record.collection + '   '}
                        </p>
                      </div>
                    )}
                  </div>
                ))}
              </div>
              <RangePicker
                dateFormat={dateFormat}
                placeholder={['开始时间', '结束时间']}
                className="date"
                onChange={this.datetimeChange}
                value={choosedDates}
              />
              <Input
                className='date'

                placeholder="请输入条码"
                value={barcode}
                onChange={this.barcodeOnChange}
              />
              <Input
                className='date'

                placeholder="请输入姓名"
                value={uname}
                onChange={this.nameOnChange}
              />
              <Input

                className='date'
                placeholder="请输入身份证"
                value={idCard}
                onChange={this.idCardOnChange}
              />
              <Input
                className='date'
                placeholder="请输入单位"
                value={workUnit}
                onChange={this.workUnitOnChange}
              />
              {/* <Input
                className='date'
                placeholder="请输入采集点"
                value={collection}
                onChange={this.collectionOnChange}
              /> */}
              <Select
                className='date'
                allowClear={true}
                placeholder="请选择性别"
                value={sex}
                onChange={this.onsexSelectChange}
              >
                <Option key='1'>
                  男
                </Option>
                <Option key='2'>
                  女
                </Option>
              </Select>
              <Input
                className='date'
                placeholder="起始年龄"
                value={startAge}
                onChange={this.startNumOnChange}
              />
              <Input
                className='date'
                placeholder="终止年龄"
                value={endAge}
                onChange={this.endNumOnChange}
              />
              <Select
                className='date'
                allowClear={true}
                placeholder="请选择所属机构"
                value={organId}
                onChange={this.onOrganSelectChange}
              >
                {organizationList.map((organ) => {
                  return (
                    <Option key={organ.organizationId}>
                      {organ.organizationName}
                    </Option>
                  )
                })}
              </Select>
              <Select
                className='date'
                allowClear={true}
                placeholder="请选择所属类型"
                value={phyId}
                onChange={this.onphySelectChange}
              >
                {physicalTypeList.map((phy) => {
                  return <Option key={phy.id}>{phy.physicalType}</Option>
                })}
              </Select>
              <Button className="btn" type="primary" onClick={this.loadDatas}>
                搜索
              </Button>
              <Button className="btn" type="primary" onClick={this.reset}>
                重置
              </Button>
              <Button className="btn" type="primary" onClick={this.exportExcel}>
                导出
              </Button>
              <Button className="btn" type="primary" onClick={this.editItem}>
                编辑
              </Button>
              <Button className="btn" type="primary" onClick={this.addInsert}>
                新增华晟数据
              </Button>
              {/* <Button className="btn" type="primary" onClick={this.delItem}>删除</Button> */}
              <Button
                className="btn"
                type="primary"
                onClick={this.barcodePrint}
              >
                打印条码
              </Button>
              <Button
                className="btn"
                type="primary"
                onClick={this.tickexportExcel}
              >
                勾选导出
              </Button>
              <Checkbox onChange={this.cbonChange}>打印两张条码</Checkbox>
            </div>
          </div>
          <Spin tip="Loading..." spinning={spinning}>
            <div>
              {dataList.length > 0 ? (
                <Table
                  rowKey="id"
                  onRow={(record, index) => {
                    return {
                      onClick: () => {
                        let temp = this.state.selectedRowKeys
                        let tempinfo = this.state.selectedRowInfoKeys
                        let index = temp.indexOf(record.id)
                        let index1 = tempinfo.indexOf(record.id)
                        if (index === -1) {
                          temp.push(record.id)
                        } else {
                          temp.splice(index, 1)
                        }
                        this.setState({ selectedRowKeys: temp })
                        if (index1 === -1) {
                          tempinfo.push(record.id)
                        } else {
                          tempinfo.splice(index1, 1)
                        }
                        this.setState({ selectedRowInfoKeys: tempinfo })
                        console.log(tempinfo)
                        this.setState({ printRecordList: [] })
                      },
                    }
                  }}
                  rowSelection={rowSelection}
                  dataSource={dataList}
                  pagination={{
                    pageSize: this.state.pageSize,
                    showTotal: (total) => {
                      return `共 ${total} 条`
                    },
                    onChange: this.changePage,
                    pageSizeOptions: ['10', '20', '50', '100', '500'],
                    total: this.state.total,
                    showSizeChanger: true,
                    onShowSizeChange: this.pageSizeOnChange,
                  }}
                  bordered
                >
                  <Column
                    width={100}
                    title="条码号"
                    dataIndex="barcode"
                    key="barcode"
                    align="center"
                    sorter={(a, b) => {
                      return a.barcode > b.barcode ? 1 : -1
                    }}
                  />
                  <Column
                    width={50}
                    title="姓名"
                    dataIndex="username"
                    key="username"
                    align="center"
                  />
                  <Column
                    width={30}
                    title="性别"
                    dataIndex="sex"
                    key="sex"
                    align="center"
                    render={(text, record) => (
                      <div
                        style={{
                          wordWrap: 'break-word',
                          wordBreak: 'break-all',
                        }}
                      >
                        {text && text === 1 ? '男' : '女'}
                      </div>
                    )}
                  />

                  <Column
                    width={50}
                    title="照片"
                    key="picture"
                    align="center"
                    render={(record) => {
                      return (
                        <div
                          style={{
                            wordWrap: 'break-word',
                            wordBreak: 'break-all',
                          }}
                        >
                          <img
                            alt=""
                            style={{ width: 50, height: 50 }}
                            src={record.pic}
                            key={record.pic}
                          />
                        </div>
                      )
                    }}
                  />

                  <Column
                    width={100}
                    title="身份证号"
                    dataIndex="idCard"
                    key="idCard"
                    align="center"
                  />
                  <Column
                    width={90}
                    title="体检类型"
                    dataIndex="physicalTypeName"
                    key="physicalTypeName"
                    align="center"
                  />
                  <Column
                    width={50}
                    title="机器套餐"
                    key="orgname"
                    align="orgname"
                    render={(text, record) => (
                      <span>
                        <span>{record.orgname && record.orgname}</span>
                      </span>
                    )}
                  />
                  <Column
                    width={50}
                    title="录入方式"
                    key="way"
                    align="center"
                    render={(text, record) => (
                      <span>
                        <span>
                          {record.way === 1 ? '设备上传' : '手动录入'}
                        </span>
                      </span>
                    )}
                  />
                  <Column
                    width={50}
                    title="单位名称"
                    key="workUnit"
                    align="workUnit"
                    render={(text, record) => (
                      <span>
                        <span>{record.workUnit && record.workUnit}</span>
                      </span>
                    )}
                    sorter={(a, b) => {
                      return a.workUnit > b.workUnit ? 1 : -1
                    }}
                  />

                  <Column
                    width={50}
                    title="部门名称"
                    dataIndex="deptName"
                    key="deptName"
                    align="center"
                  />
                  <Column
                    width={90}
                    title="采集点"
                    key="collection"
                    align="collection"
                    sorter={(a, b) => {
                      return a.collection > b.collection ? 1 : -1
                    }}
                    render={(text, record) => (
                      <span>
                        <span>{record.collection && record.collection}</span>
                      </span>
                    )}
                  />

                  <Column
                    width={100}
                    title="联系电话"
                    dataIndex="phone"
                    key="phone"
                    align="center"
                  />
                  <Column
                    width={100}
                    title="检验编号"
                    dataIndex="checkSampleNumber"
                    key="checkSampleNumber"
                    align="center"
                    sorter={(a, b) => {
                      return a.checkSampleNumber > b.checkSampleNumber ? 1 : -1
                    }}
                  />
                  <Column
                    width={80}
                    title="采集日期"
                    dataIndex="entryTime"
                    key="entryTime"
                    align="center"
                    sorter={(a, b) => {
                      return a.entryTime > b.entryTime ? 1 : -1
                    }}
                  />
                  <Column
                    width={50}
                    title="操作"
                    align="center"
                    key="action"
                    render={(text, record) => (
                      <span>
                        <span
                          style={{ cursor: 'pointer' }}
                          onClick={() => this.delItem(record.id)}
                        >
                          删除
                        </span>
                      </span>
                    )}
                  />
                </Table>
              ) : (
                <Empty />
              )}
            </div>
            <Modal
              width="500px"
              title="选择项目名称"
              visible={visible}
              onOk={this.handleBindOk}
              onCancel={this.handleBindCancel}
              okText="保存"
              cancelText="取消"
            >
              <div className="form-group">
                <label className="form-label">项目名称：</label>
                <div className="form-ipt" style={{ marginTop: '5px' }}>
                  <CheckboxGroup
                    value={
                      getdescrArr
                    }
                    onChange={e => this.ongetdescrArrCheckBoxChange(e)}
                  >
                    {descrArr.map(
                      (item, index) => (
                        <Checkbox
                          value={item.id}
                          key={index}
                        >
                          {item.projectName}
                        </Checkbox>
                      )
                    )}
                  </CheckboxGroup>
                </div>
              </div>
            </Modal>
          </Spin>
        </div>
      </div>
    )
  }
}
export default connect(null, {
  getOrganizationGuahaoList,
  getPhysicalTypeList,
  getAllorganization,
  importRegisterInfo,
  guahaoDel,
  physicalRecord_remove, getParentList, sampleInfoinsert
})(StatisticsPage)
