import React, { Component } from 'react'
import PropTypes from "prop-types";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Admin from "./admin/Index";
import Login from "./account/Login";
import PrivateRoute from "./auth/Index";
import AppointmentInside from './appoint/AppointmentInside';
import AppointmentOutside from './appoint/AppointmentOutside';
import EstablishAchives from './appoint/EstablishAchives';
import PrintCheckInfo from './print/PrintCheckInfo';
import QrcodeScanning from './qrcode/QrcodeScanning';
import linagai_yuyue from './yuyue/linagai_yuyue';
import linagai_yuyue2 from './yuyue/linagai_yuyue2';
import student_yuyue from './yuyue/student_yuyue';
import jkz_yuyue from './yuyue/jkz_yuyue';
import jkz_yuyue_with_archive from './yuyue/jkz_yuyue_with_archive';
import YuyueMXB from './yuyue/chronicDisease';
import AppointQuery from './yuyue/query'
import AppointQueryList from './yuyue/QueryList'
import QueryPdf from './wx/QueryPdf/QueryPdf'
import Process from './process/Process';
import AppointmentRegisterPrint from './print/appointmentRegister'
// import FlowModulation from './yuyue/FlowModulation'
import laonian_yuyue from './yuyue/laonian_yuyue';
import tcm_yuyue from './yuyue/tcm_yuyue'
import students_yuyue from './yuyue/students_yuyue'
import studentsNew_yuyue from './yuyue/studentsNew_yuyue'
import students_meal from './yuyue/students_meal'
import students_detail from './yuyue/students_detail'
import erbao_yuyue from './yuyue/erbao_yuyue'
import browseDCM from './print/picBrowse';
import Test from './yuyue/Test';
import vacc_yuyue from './yuyue/vacc_yuyue';
import physical_yuyue from './yuyue/physical_yuyue';
import dr_yuyue from './yuyue/dr_yuyue';
import reportQuery from './yuyue/reportQuery';

const styles = theme => ({
  index: {}
});
class Container extends Component {
  componentDidMount() {
    if (localStorage.getItem("cookie")) {
      //   this.props.userInfo().then(function(res) {
      //     if (res.data.code === 200) {
      //       return;
      //     }
      //     Toast.error(res.data.msg);
      //   });
    }
  }
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.index}>
        <Router>
          <Switch>
            <Route path="/" exact component={Login} />
            <Route path="/appoint/inside" exact component={AppointmentInside} />
            <Route path="/appoint/outside" exact component={AppointmentOutside} />
            <Route path="/print/CheckInfo" exact component={PrintCheckInfo} />
            <Route path="/appoint/establish" exact component={EstablishAchives} />
            <Route path="/qrcode/scanning" exact component={QrcodeScanning} />

            <PrivateRoute path="/admin" component={Admin} />
            <Route path="/TwoCancerBooking" component={linagai_yuyue2} />
            <Route path="/erbao_yuyue" component={erbao_yuyue} />
            <Route path="/tcm_yuyue" component={tcm_yuyue} />
            <Route path="/students_meal" component={students_meal} />
            <Route path="/students_detail" component={students_detail} />
            <Route path="/students_yuyue" component={students_yuyue} />
            <Route path="/studentsNew_yuyue" component={studentsNew_yuyue} />
            <Route path="/vacc_yuyue" component={vacc_yuyue} />
            <Route path="/TwoCancerBooking2" component={linagai_yuyue2} />
            <Route path="/TwoCancerBooking3" component={linagai_yuyue} />
            <Route path="/eldBooking" component={laonian_yuyue} />
            <Route path="/physicalBooking" component={physical_yuyue} />
            <Route path="/StudentBooking" component={student_yuyue} />
            {/* 紫金学院结核筛查预约 */}
            <Route path="/DRBooking" component={dr_yuyue} />
            <Route path="/ReportQuery" component={reportQuery} />

            <Route path="/HealthCertificateBooking" component={jkz_yuyue} />
            <Route path="/yuyueStudent2" component={jkz_yuyue} />
            <Route path="/HealthCertificateWithArchiveBooking" component={jkz_yuyue_with_archive} />

            <Route path="/YuyueMXB" component={YuyueMXB} />
            <Route path="/Test" component={Test} />



            <Route path="/appointQuery" component={AppointQuery} />
            <Route path="/appointQueryList" component={AppointQueryList} />
            <Route path="/Process" component={Process} />
            <Route path="/QueryPdf" component={QueryPdf} />
            <Route path="/AppointmentRegisterPrint" component={AppointmentRegisterPrint} />

            {/* <Route path="/flowModulation" component={FlowModulation}/> */}
            <Route path="/browseDCM" component={browseDCM} />

          </Switch>
        </Router>
      </div>
    )
  }
}
Container.propTypes = {
  classes: PropTypes.object.isRequired
};
export default (withStyles(styles)(Container));
