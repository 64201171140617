import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Input, Button, message, DatePicker, Modal, Select ,Spin} from 'antd';
import {   setPersonsCategory, getPhysicalRecord, updateInspectionResult, batchSetResult,getOrganizationGuahaoList,updateSampleNumber } from '../../../actions/account'
import _ from 'lodash'
import './ResultEntering.css'
import { SearchOutlined} from '@ant-design/icons';

const { RangePicker } = DatePicker;
const { Option } = Select;
const dateFormat = 'YYYY-MM-DD hh:mm:ss';
class ResultEntering extends Component {
    constructor(props) {
        super(props);
        this.state = {
            personCategory:undefined,
            inputState: '1',
            barcode: '',
            result: undefined,
            choosedDates: [],//时间默认值
            startTime: undefined,
            endTime: undefined,
            dateTimeVisble: false,
            setMarK:2,
            allList:[],
            gatherMode:undefined,
            loading:false
        }
    }

    componentDidMount() {

    }
    handleChange = (value) => {
        console.log(`selected ${value}`);
    }
    barcodeChange = e => {
        this.setState({ barcode: e.target.value })
    }

    btnSearch = () => {
        var { barcode} = this.state;
        if(barcode.length===0){
            message.warn('请输入条码')
            return;
        }
        var obj={}
            obj.barcode=barcode
        var that = this;
        this.props.getOrganizationGuahaoList(obj).then(msg => {
            if (msg.data.code === 200) {
                    that.setState({
                        result: msg.data.data.rows[0],
                    })
            }
        })

    }

    checkValOnChange = (e, bigId, smallId) => {
        var { result } = this.state;
        var bigProject = _.find(result.inspectionResult.details, ['projectId', bigId]);
        var smallProject = _.find(bigProject.slaveProject, ['id', smallId]);
        smallProject.result = e.target.value;
        this.setState({ result });
    }

    promptOnChange = (e, bigId, smallId) => {
        var { result } = this.state;
        var bigProject = _.find(result.inspectionResult.details, ['projectId', bigId]);
        var smallProject = _.find(bigProject.slaveProject, ['id', smallId]);
        smallProject.prompt = e.target.value;
        this.setState({ result });
    }

    btnClick = () => {
        var { result ,gatherMode} = this.state;
        var that = this;
        console.log(result,gatherMode)
        that.props.setPersonsCategory({physicalRecordDTOS:[{id:result.id,crowd:gatherMode}]}).then(res => {
            if (res.data.code === 200) {
                message.success("设置成功!")
                that.setState({
                    result:undefined,
                    barcode:''
                })
            }
        })
    }

    inputStateOnChange = (value) => {
        this.setState({ inputState: value })
    }

    datetimeChange = (date, dateString) => {
        // var choseDate =[];
        // choseDate = _.concat(choseDate,moment(dateString[0]+" 00:00:00","YYYY-MM-DD hh:mm:ss"));
        // choseDate = _.concat(choseDate,moment(dateString[1]+" 23:59:59","YYYY-MM-DD hh:mm:ss"));
        this.setState({
            startTime: dateString[0] === "" ? undefined : dateString[0] + " 00:00:00",
            endTime: dateString[1] === "" ? undefined : dateString[1] + " 23:59:59",
            choosedDates: date,
        })
    }

    batchSetOnClick = (mark) => {
        this.setState({
            dateTimeVisble: true,
            startTime: undefined,
            endTime: undefined,
            choosedDates: [],
            setMarK:mark
        })
    }

    onDateOK = () => {
         var that = this;
            //批量设置鼻试纸
            that.setState({allList:[]},()=>{
                that.getGuaHaoList(0,[]);
            })
    }

    updateInspection=(list,i,gatherMode)=>{
        var that=this;
        console.log(gatherMode)
        console.log(list)        
        if(i>=list.length){
            message.success("批量设置人群分类成功！")
            that.setState({
                dateTimeVisble:false,
            loading:false,

            })
            return;
        }        
        var id = list[i].id;
        var obj={
            id:id,
            crowd:gatherMode
        }
        that.props.setPersonsCategory({physicalRecordDTOS:[obj]}).then(res=>{
            if(res.data.code===200){
                i++;
                that.updateInspection(list,i,gatherMode);
            }
        })

    }

    getGuaHaoList=(page,list)=>{
        var {startTime,endTime,allList,personCategory}= this.state
        var that = this;
        this.setState({
            loading:true
        })
        if(list.length===0 && page !== 0){
            that.updateInspection(allList,0,personCategory)
            return;
        }
        else{
            var obj={
                page:page,
                size:10,
                startTime: startTime,
                endTime: endTime
            }
            obj.organizationId = JSON.parse(localStorage.getItem('data')).organizationId;
            // organizationId
            that.props.getOrganizationGuahaoList(obj).then(res=>{
                if(res.data.code===200){
                    if(page===0 && res.data.data.rows.length===0){
                        message.warn('该期间无人员信息！');
                        that.setState({
                            loading:false
                        })
                        return;
                    }
                    page++;
                    that.setState({
                        allList:_.concat(allList,res.data.data.rows)
                    },()=>{
                        that.getGuaHaoList(page,res.data.data.rows)

                    })
                }
            })
        }

    }
    // 

    onDateCancel = () => {
        this.setState({
            gatherMode:undefined,
            dateTimeVisble: false,
            startTime: undefined,
            endTime: undefined,
            choosedDates: []
        })
    }

    gatherModeOnChnage=(value)=>{
        console.log(value)
        this.setState({gatherMode:value})

    }
    personCategoryOnChnage=value=>{
        this.setState({personCategory:value})

    }

    render() {
        const { barcode, result, choosedDates, gatherMode,setMarK ,personCategory} = this.state
        return (
            <div>
                <div className="StatisticsPage-head pageHeader"><h3>人群分类管理</h3></div>
                <div className="StatisticsPage-box">
                    <div className="StatisticsPage-search">
                        <Input className="ipt" value={barcode} placeholder="请输入条码号" onChange={e => this.barcodeChange(e)} onPressEnter={this.btnSearch} />
                        <Button type="primary"  icon={<SearchOutlined />} onClick={this.btnSearch} style={{ width: '60px' }}></Button>
                        {/* <Button type="primary" style={{ 'marginLeft': '10px' }} >上传血检结果</Button> */}
                        {/* <Button type="primary" style={{ 'marginLeft': '10px' }} onClick={()=>this.batchSetOnClick(1)} >批量设置结果</Button> */}
                        <Button type="primary" style={{ 'marginLeft': '10px' }} onClick={()=>this.batchSetOnClick(2)}>批量设置分类</Button>
                    </div>
                    {/* {!result&&<div className="StatisticsPage-search">  */}
                    {/* <span>姓名：{result.userBasicInfo.username +"   "}</span>
                    <span>证件号：{result.userBasicInfo.certNumber +"   "}</span>
                    <span>电话：{result.userBasicInfo.phone +"   "}</span> */}
                    {/* </div>} */}
                    {result !== undefined && result !== null && <div>
                        <p>姓名：{result.username}</p>
                        <p>条码号：{result.barcode }</p>
                        <p>身份证号：{result.idCard}</p>
                        <p>地址：{result.address}</p>
                        <p>体检类型：{result.physicalTypeName}</p>
                        <p>机构名称：{result.orgname}</p>
                        <p>联系方式：{result.phone?result.phone:'暂无'}</p>
                        <p>工作单位：{result.workUnit?result.workUnit:'暂无'}</p>
                        <div>
                            人群分类：
                            <Select style={{width:'300px'}} value={gatherMode} onChange={this.gatherModeOnChnage}>
                                    <Option value={1}>冷链食品从业人员</Option>
                                    <Option value={2}>进口货物直接接触人员</Option>
                                    <Option value={3}>邮政、快递、物流从业人员</Option>
                                    <Option value={4}>返乡回浦人员</Option>
                                    <Option value={5}>其他“应检尽检”人员</Option>
                            </Select>
                        </div>

                       
                    </div>}
                    {result !== undefined && result !== null &&
                        <div className="tab-org">
                            <Button type="primary" onClick={this.btnClick}>保存</Button>
                        </div>
                    }
                </div>

                <Modal title="批量设置结果" visible={this.state.dateTimeVisble}
                    onOk={this.onDateOK}
                    onCancel={this.onDateCancel}>
                         <Spin spinning={this.state.loading} tip="批量设置中，请耐心等待...">
                         <div className="form-group">
                            <label className="form-label">选择日期：</label>
                            <div className="form-ipt">
                            <RangePicker dateFormat={dateFormat} placeholder={['开始时间', '结束时间']} className="date"
                                onChange={this.datetimeChange} value={choosedDates} />
                            </div>
                        </div>
                        {setMarK===2 &&  <div className="form-group">
                            <label className="form-label">选择人群分类：</label>
                            <div className="form-ipt">
                                <Select style={{width:'300px'}} value={personCategory} onChange={this.personCategoryOnChnage}>
                                    <Option value={1}>冷链食品从业人员</Option>
                                    <Option value={2}>进口货物直接接触人员</Option>
                                    <Option value={3}>邮政、快递、物流从业人员</Option>
                                    <Option value={4}>返乡回浦人员</Option>
                                    <Option value={5}>其他“应检尽检”人员</Option>
                                </Select>
                            </div>
                        </div>}
                       
                   </Spin>
                  
                </Modal>
            </div>
        )
    }
}
export default connect(null, {  setPersonsCategory, getPhysicalRecord, updateInspectionResult, batchSetResult,getOrganizationGuahaoList,updateSampleNumber })(ResultEntering)               