import React, { Component } from 'react'
import { Input } from 'antd';
import { connect } from 'react-redux'
import { appointmentPageDetail2, appointmentSave } from '../../actions/account'
import './query.css'
import cookie from 'react-cookies';


import hospitial from '../../assets/hospitial.jpg'

class query extends Component {
    constructor(props) {
        super(props);
        this.state = {
            idcard: undefined
        }

    }
    componentDidMount() {
        // var data = cookie.load("idCard");

        // if(data!==undefined){
        //     this.props.history.push('/appointQueryList?idCard='+data)
        // }

    }

    inputOnChange = e => {
        this.setState({
            idcard: e.target.value
        })
    }

    queryBycard = () => {
        var { idcard } = this.state;

        this.props.history.push('/appointQueryList?idCard=' + idcard + '&&type=' + 1)
    }
    render() {

        return (
            <div className="appointquery-bg">
                <div className="apointquery-logo">
                    <img alt="" src={hospitial} width={200} />
                </div>
                <p className="appointquery-title">预约查询</p>
                {/* <div className="StatisticsPage-head1 pageHeader1"><h3>预约查询</h3></div>
                <div className="StatisticsPage-box1 has-select-table">
                </div> */}
                <div className="appointquery-ipt">
                    <Input placeholder="请输入身份证号码" className="appointquery-ipt-input"
                        onChange={this.inputOnChange} />
                </div>
                <div class="appointquery-btn" onClick={this.queryBycard}>查询</div>

            </div>
        )
    }
}
export default connect(null, {
    appointmentPageDetail2, appointmentSave
})(query)