import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  Modal,
  Button,
  Table,
  Input,
  message,
  Divider,
  Tabs,
  Select,
  DatePicker,
} from 'antd'
import './DCM.css'
import moment from 'moment'
import _ from 'lodash'
import {
  libangEcgList,
  libangEcgsave,
  libangEcgupdate,
  libangEcgremove,
  getOrganizationGuahaoList, libangEcgexportEcg
} from '../../../actions/account'
const dateFormat = 'YYYY-MM-DDHHmmss'
const { confirm } = Modal
const { RangePicker } = DatePicker
const { TabPane } = Tabs
const { Column } = Table
const { TextArea } = Input
const { Option } = Select
class lbpissResultPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      username: '',
      barcode: '',
      idCard: '',
      size: 10,
      dataList: [],
      page: 1,
      total: 0,
      title: '',
      barcodeNo: '',
      currentId: '',
      machineNo: '',
      no: '',
      selectedRowKeys: [],
      visible: false,
      isRead: "",
      startTime: undefined,
      endTime: undefined,
      choosedDates: [],
      // =======
      physicalStartTime: undefined,
      physicalEndTime: undefined,
      physicalchoosedDates: []
    }
  }

  componentDidMount() {
    var { startTime, endTime, username, barcode, idCard, isRead, machineNo, choosedDates, physicalStartTime, physicalEndTime, physicalchoosedDates } =
      this.state,
      that = this
    if (this.props.location.state) {
      var searchObj = this.props.location.state.searchObj
      if (searchObj.startTime) {
        startTime =
          moment(searchObj.startTime, 'YYYYMMDD').format('YYYY-MM-DD') +
          ' 00:00:00'
        choosedDates = _.concat(
          choosedDates,
          moment(startTime, 'YYYY-MM-DD HH:mm:ss')
        )
      }
      if (searchObj.endTime) {
        endTime =
          moment(searchObj.endTime, 'YYYYMMDD').format('YYYY-MM-DD') +
          ' 00:00:00'
        choosedDates = _.concat(
          choosedDates,
          moment(endTime, 'YYYY-MM-DD HH:mm:ss')
        )
      }
      if (searchObj.physicalStartTime) {
        physicalStartTime =
          moment(searchObj.physicalStartTime, 'YYYYMMDD').format('YYYY-MM-DD') +
          ' 00:00:00'
        physicalchoosedDates = _.concat(
          physicalchoosedDates,
          moment(physicalStartTime, 'YYYY-MM-DD HH:mm:ss')
        )
      }
      if (searchObj.physicalEndTime) {
        physicalEndTime =
          moment(searchObj.physicalEndTime, 'YYYYMMDD').format('YYYY-MM-DD') +
          ' 00:00:00'
        physicalchoosedDates = _.concat(
          physicalchoosedDates,
          moment(physicalEndTime, 'YYYY-MM-DD HH:mm:ss')
        )
      }
      if (searchObj.barcode) {
        barcode = searchObj.barcode
      }
      if (searchObj.username) {
        username = searchObj.username
      }
      if (searchObj.idCard !== undefined) {
        idCard = searchObj.idCard
      }
      if (searchObj.isRead !== undefined) {
        isRead = searchObj.isRead
      }
      if (searchObj.machineNo !== undefined) {
        machineNo = searchObj.machineNo
      }

      this.setState(
        {
          startTime,
          endTime,
          machineNo,
          barcode,
          username,
          idCard,
          isRead,
          choosedDates,
          physicalEndTime,
          physicalStartTime,
          physicalchoosedDates
        },
        () => {
          that.loadDatas()
        }
      )
    } else {
      this.loadDatas()
    }
  }

  loadDatas = () => {
    var { page, size, username, barcode, idCard, machineNo, startTime, endTime, isRead, physicalStartTime, physicalEndTime } = this.state
    var that = this
    var obj = {
      page: page - 1,
      size: size,
    }
    if (!!username) {
      obj.username = username
    }
    if (!!barcode) {
      obj.barcode = barcode
    }
    if (!!idCard) {
      obj.idCard = idCard
    }
    if (!!machineNo) {
      obj.machineNo = machineNo
    }
    if (!!isRead) {
      obj.isRead = isRead
    }
    if (startTime !== '' && startTime !== undefined) {
      obj.startTime = startTime;
    }
    if (endTime !== '' && endTime !== undefined) {
      obj.endTime = endTime;
    }
    if (physicalStartTime !== '' && physicalStartTime !== undefined) {
      obj.physicalStartTime = physicalStartTime;
    }
    if (physicalEndTime !== '' && physicalEndTime !== undefined) {
      obj.physicalEndTime = physicalEndTime;
    }
    this.props.libangEcgList(obj).then((res) => {
      if (res.data.code === 200) {
        that.setState({
          dataList: res.data.data.rows,
          total: res.data.data.total,
        })
      }
    })
  }
  datetimeChange = (date, dateString) => {
    this.setState({
      startTime: dateString[0] === "" ? undefined : dateString[0] + " 00:00:00",
      endTime: dateString[1] === "" ? undefined : dateString[1] + " 23:59:59",
      choosedDates: date,
    })
  }
  physicaltimeChange = (date, dateString) => {
    this.setState({
      physicalStartTime: dateString[0] === "" ? undefined : dateString[0] + " 00:00:00",
      physicalEndTime: dateString[1] === "" ? undefined : dateString[1] + " 23:59:59",
      physicalchoosedDates: date,
    })
  }
  barcodeOnChange = (e) => {
    this.setState({ barcode: e.target.value })
  }
  usernameOnChange = (e) => {
    this.setState({ username: e.target.value })
  }
  idCardOnChange = (e) => {
    this.setState({ idCard: e.target.value })
  }
  machineNoChange = (e) => {
    this.setState({ machineNo: e.target.value })
  }
  readPissResult = (record, index) => {
    this.props.history.push({
      pathname: '/admin/lbpissRead',
      state: {
        record: JSON.stringify(record),
        index: index,
        page: this.state.page,
        size: this.state.size,
        username: this.state.username,
        barcode: this.state.barcode,
        idCard: this.state.idCard,
        machineNo: this.state.machineNo,
        isRead: this.state.isRead,
        startTime: this.state.startTime,
        endTime: this.state.endTime,
        physicalStartTime: this.state.physicalStartTime,
        physicalEndTime: this.state.physicalEndTime,
      },
    })
  }
  handleBindOk = () => {
    var { barcodeNo, currentId, no } = this.state
    var that = this
    var obj = { barcode: barcodeNo, no: no }
    obj.id = currentId
    this.props
      .getOrganizationGuahaoList({
        page: 0,
        size: 10,
        barcode: barcodeNo,
        organizationId: JSON.parse(localStorage.getItem('data')).organizationId,
      })
      .then((res1) => {
        console.log(res1)
        if (res1.data.data.total != 0) {
          obj.idCard = res1.data.data.rows[0].idCard
          obj.sex = res1.data.data.rows[0].sex
          obj.age = res1.data.data.rows[0].age
          obj.username = res1.data.data.rows[0].username
        }
        //   ===============
        this.props.libangEcgupdate(obj).then((res) => {
          if (res.data.code === 200) {
            message.success('修改成功！')
            that.setState({
              visible: false,
              barcodeNo: '',
              no: '',
              currentId: undefined,
            })
            that.loadDatas()
          }
        })
      })
  }
  handleBindCancel = () => {
    this.setState({
      visible: false,
      barcodeNo: '',
      no: '',
      currentId: undefined,
    })
  }
  changePage = (page) => {
    var that = this
    this.setState({ page }, () => {
      that.loadDatas()
    })
  }
  pageSizeOnChange = (current, size) => {
    var that = this
    this.setState({ size: size }, () => {
      that.loadDatas()
    })
  }

  onSelectChange = (selectedRowKeys) => {
    // var { dataList } = this.state;
    this.setState({ selectedRowKeys })
  }
  delItem = (id) => {
    var that = this
    confirm({
      content: '确认删除？',
      onOk() {
        that.props.libangEcgremove({ id: id }).then((msg) => {
          if (msg.data.code === 200) {
            message.success('删除成功！')
            that.loadDatas()
          }
        })
      },
    })
  }

  serchBtn = () => {
    this.setState({ page: 1 }, () => {
      this.loadDatas()
    })
  }
  barcodeOnChnage = (e) => {
    this.setState({ barcode: e.target.value })
  }
  barcodeNoOnChnage = (e) => {
    this.setState({ barcodeNo: e.target.value })
  }
  onphySelectChange = value => {
    this.setState({
      isRead: value
    })
  }
  editItem = (record) => {
    this.setState({
      visible: true,
      barcodeNo: record.barcode,
      currentId: record.id,
      no: record.no,
    })
  }
  exportelectrocardiogramPage = () => {
    var { startTime, endTime } = this.state

    if (!!!startTime || !!!endTime) {
      message.error('请先选择时间')
      return
    }
    var obj = {}
    if (startTime !== undefined) {
      obj.startTime = moment(startTime, 'YYYY-MM-DD HH:mm:ss').format(
        'YYYY-MM-DD'
      )
      obj.startTime = obj.startTime + ' 00:00:00'
    }
    if (endTime !== undefined) {
      obj.endTime = moment(endTime, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD')
      obj.endTime = obj.endTime + ' 23:59:59'
    }

    var that = this
    that.props.libangEcgexportEcg(obj).then((res) => {
      const fileName = 'export' + moment().format('YYYYMMDDHHmmss')
      if ('download' in document.createElement('a')) {
        // 非IE下载
        const url = window.URL.createObjectURL(
          new Blob(
            [res.data],
            // 设置该文件的mime类型，这里对应的mime类型对应为.xlsx格式
            { type: 'application/vnd.ms-excel' }
          )
        )
        const link = document.createElement('a')
        link.href = url
        // 从header中获取服务端命名的文件名
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
      } else {
        // IE10+下载
        navigator.msSaveBlob(res.data, fileName)
      }
    })
  }
  render() {
    const {
      dataList,
      selectedRowKeys,
      username,
      barcode,
      idCard,
      visible,
      barcodeNo,
      machineNo, choosedDates, physicalchoosedDates,
      isRead
    } = this.state
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    }
    return (
      <div>
        <div className="StatisticsPage-head pageHeader">
          <h3>理邦心电图列表</h3>
        </div>
        <div className="StatisticsPage-box">
          <div className="StatisticsPage-search">
            <div className="StatisticsPage-btn">
              <Input
                className="ipt"
                placeholder="请输入条码号"
                value={barcode}
                style={{ maxWidth: '250px' }}
                onChange={this.barcodeOnChange}
              />
              <Input
                className="ipt"
                placeholder="请输入姓名"
                value={username}
                style={{ maxWidth: '250px' }}
                onChange={this.usernameOnChange}
              />
              <Input

                placeholder="请输入身份证"
                value={idCard}
                style={{ maxWidth: '250px' }}
                onChange={this.idCardOnChange}
              />
              <RangePicker dateFormat={dateFormat} placeholder={['体检开始时间', '体检结束时间']} className="ipt"
                onChange={this.physicaltimeChange} value={physicalchoosedDates} style={{ maxWidth: '400px' }} />
              <Input
                className="ipt"
                placeholder="机器编号"
                value={machineNo}
                style={{ maxWidth: '250px' }}
                onChange={this.machineNoChange}
              />
              <Select className="ipt" style={{ maxWidth: '250px' }} allowClear={true} placeholder="是否阅读" value={isRead} onChange={this.onphySelectChange} >
                <Option key='' disabled>是否阅读</Option>
                <Option key='0'>否</Option>
                <Option key='1'>是</Option>
              </Select>
              <RangePicker dateFormat={dateFormat} placeholder={['开始时间', '结束时间']} className="ipt"
                onChange={this.datetimeChange} value={choosedDates} style={{ maxWidth: '400px' }} />
              <Button className="btn" type="primary" onClick={this.serchBtn}>
                搜索
              </Button>
              <Button
                className="btn"
                type="primary"
                onClick={this.exportelectrocardiogramPage}
              >
                导出
              </Button>
            </div>
          </div>
          <Modal
            width="500px"
            title="修改条码号"
            visible={visible}
            onOk={this.handleBindOk}
            onCancel={this.handleBindCancel}
            okText="保存"
            cancelText="取消"
          >
            <div className="form-group">
              <label className="form-label">条码号：</label>
              <div className="form-ipt" style={{ marginTop: '5px' }}>
                <Input
                  value={barcodeNo}
                  onChange={this.barcodeNoOnChnage}
                ></Input>
              </div>
            </div>
          </Modal>
          <div>
            <Table
              size="middle"
              dataSource={dataList}
              bordered
              rowKey="id"
              pagination={{
                pageSize: this.state.size,
                showTotal: (total) => {
                  return `共 ${total} 条`
                },
                onChange: this.changePage,
                total: this.state.total,
                showSizeChanger: true,
                onShowSizeChange: this.pageSizeOnChange,
              }}
              rowSelection={rowSelection}
            >
              <Column title="编号" dataIndex="no" key="no" align="center" />
              <Column
                title="姓名"
                dataIndex="username"
                key="username"
                align="center"
              />
              <Column
                title="机器编号"
                dataIndex="machineNo"
                key="machineNo"
                align="center"
              />
              <Column
                title="条码号"
                dataIndex="barcode"
                key="barcode"
                align="center"
              />
              <Column
                title="身份证号"
                dataIndex="idCard"
                key="idCard"
                align="center"
              />
              <Column
                title="医生建议"
                dataIndex="diagnosticInfo"
                key="diagnosticInfo"
                align="center"
              />
              <Column
                title="是否阅读"
                dataIndex="isRead"
                key="isRead"
                align="center"
                render={(text, record) => (
                  <span>{record.isRead == '1' ? '是' : '否'}</span>
                )}
              />
              <Column
                title="体检时间"
                dataIndex="physicalTime"
                key="physicalTime"
                align="center"
              />
              <Column
                title="创建时间"
                dataIndex="createdAt"
                key="createdAt"
                align="center"
              />
              <Column
                title="操作"
                align="center"
                key="action"
                render={(text, record, index) => (
                  <span>
                    <span
                      style={{ cursor: 'pointer' }}
                      onClick={() => this.editItem(record)}
                    >
                      修改条码号
                    </span>
                    <Divider type="vertical" />
                    <span
                      style={{ cursor: 'pointer' }}
                      onClick={() => this.readPissResult(record, index)}
                    >
                      心电图阅读
                    </span>
                    <Divider type="vertical" />
                    <span
                      style={{ cursor: 'pointer' }}
                      onClick={() => this.delItem(record.id)}
                    >
                      删除
                    </span>
                  </span>
                )}
              />
            </Table>
          </div>
        </div>
      </div>
    )
  }
}
export default connect(null, {
  libangEcgList,
  libangEcgsave,
  libangEcgupdate,
  libangEcgremove,
  getOrganizationGuahaoList, libangEcgexportEcg
})(lbpissResultPage)
