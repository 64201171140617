import React from 'react'; 
import './App.css';
import CssBaseline from "@material-ui/core/CssBaseline";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { Provider } from "react-redux";
import store from "./reducers/store";
import Container from "./modules/Container"
import zh_CN from 'antd/lib/locale-provider/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';
moment.locale('zh-cn');
const theme = createMuiTheme({
  typography: {
    useNextVariants: true, //迁移到typography v2
    h6: { color: `inherit` },
    subtitle1: { color: `inherit` }
  },
  palette: {
    primary: {
      main: `#1aad19`,
      contrastText: `#fff`,
      background: { default: `#f8f9fb` },
      text: { primary: `#353535` }
    },
    secondary: { main: "#576b95" },
    divider: `#E1E1E6`
  },
  direction: "ltr"
});
function App() {
  return (
    <Provider store={store} locale={zh_CN}>
        <MuiThemeProvider theme={theme} >
          <div className="App">
            <CssBaseline />
            <Container />
          </div>
        </MuiThemeProvider>
      </Provider>
  );
}

export default App;
