import React, { Component } from 'react'
import { Modal, Button, Table, Divider, Input, Select,message, Form, Radio, Checkbox } from 'antd';
import '../medicalRecord/RegistrationPage.css'
import _ from 'lodash'
import MD5 from 'crypto-js/md5'
import {connect} from 'react-redux'
const { Option } = Select; 
const { Column } = Table; 
const { confirm } = Modal;

class PersonManage extends Component {
    state = {
        dataList:[],
        organList:[],
        roleList:[],
        page:1,
        total:0,
        isRequesting:false,
        curRecord:undefined,
        oldPassWord:"",
        npassword:"",
        againpassword:"",
        roleVisble:false,
        curRoles:[]
    };
    componentDidMount(){
        const that=this;
        this.props.organizationNoPageList().then(msg=>{
            if(msg.data.code === 200){
                that.setState({
                    organList:msg.data.data
                })
            }
        })
        this.props.organizationRoleList().then(msg=>{
            if(msg.data.code===200){
                that.setState({
                    roleList:msg.data.data
                })
            }
        })
        this.loadDatas();     
    }

    loadDatas=()=>{
        var {page} = this.state;
        var obj={page:page-1};
        var that= this;
        that.props.organizationUserList(obj).then(msg=>{
            if(msg.data.code === 200){
                if(msg.data.data.rows.length===0&& msg.data.data.total!==0){
                    that.setState({page:page-1},()=>{
                        that.loadDatas()
                    })
                }
                else{
                    that.setState({
                        dataList:msg.data.data.rows,
                        total:msg.data.data.total
                    })
                }
            }
        })
    }

    showModal = () => {
        this.setState({
            visible: true,curRecord:undefined
        });
        this.props.form.setFieldsValue({
            username:undefined,account:undefined,password:undefined,
            newpassword:undefined,organizationId:undefined,status:1,
            roleIds:undefined
        })
    };

    handleOk = e => { 
        var {isRequesting,curRecord} = this.state;
        var that = this;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                if(isRequesting){
                    return;
                }
                if(curRecord!==undefined){
                    var obj1={
                        account:values['account'],
                        username:values['username'],
                        userId:curRecord.userId,
                        roleIds:values['roleIds']
                    }
                    that.setState({isRequesting:true},()=>{
                        that.props.organizationUserUpdate(obj1).then(msg=>{
                            if(msg.data.code=== 200){
                                message.success("更新成功！")
                                that.setState({visible:false})
                                that.loadDatas();
                            }
                            else{
                                message.error(msg.data.message);
                            }
                            that.setState({isRequesting:false})
                            var user= JSON.parse(localStorage.getItem("user"))
                            if(user.account!==values['account']){
                                that.logout();
                            }
                        })
                    });

                }
                else{

                    var obj={
                        account:values['account'],
                    password:MD5(values['password']).toString(),
                    username:values['username'],
                    status:values['status'],
                    organizationId:values['organizationId'],
                    roleIds:values['roleIds']
                }
               
                that.setState({isRequesting:true},()=>{
                    that.props.organizationUserAdd(obj).then(msg=>{
                        if(msg.data.code=== 200){
                            message.success("创建成功")
                            that.setState({visible:false})
                            that.loadDatas();
                        }
                        else{
                            message.error(msg.data.message);
                        }
                        that.setState({isRequesting:false})
                    })
                })
            }
            }
        })
    };

    handleCancel = e => {
        console.log(e);
        this.setState({
            visible: false,
        });
    };
   
    editItem=record=>{
        this.setState({
            curRecord:record,
            visible:true
        })
        this.props.form.setFieldsValue({
            username:record.username,account:record.account,password:record.username,
            newpassword:record.username,organizationId:record.organizationId,status:1
        })
    }
    oldPassWordOnChange=e=>{
        this.setState({
            oldPassWord:e.target.value
        })
    }

    newPassWordOnChange=e=>{
        this.setState({
            npassword:e.target.value
        })
    }

    newPassWordAgainOnChange=e=>{
        this.setState({
            againpassword:e.target.value
        })
    }

    resetHandleCancel=()=>{
        this.setState({
            resetVisible:false
        })
    }

    resetPassword=record=>{
        this.setState({
            curRecord:record,
            resetVisible:true ,
            oldPassWord:"",
            npassword:"",
            againpassword:""
        })
    }

    resetHandleOk=()=>{
        var that = this;
        var {oldPassWord,npassword,againpassword,curRecord} = this.state;
        if(npassword!==againpassword){
            message.error("两次密码不一致!")
            return;
        }
        this.props.organizationUserResetPassword({
            userId:curRecord.userId,
            oldPassword:MD5(oldPassWord).toString(),
            password:
            MD5(npassword).toString()
        }).then(msg=>{
            if(msg.data.code === 200){
                message.success("修改成功");
                that.setState({resetVisible:false});
                that.logout();
            }
        })
    }

    logout=()=>{
        var that = this;
        that.props.OrganizationLogout().then(res=>{
            if(res.data.code === 200){
                localStorage.removeItem("cookie")
                localStorage.removeItem("user")
                window.location = '/';
            }
        })
        
    }

    delItem=(userId)=>{
        var that = this;
        confirm({
            content:"确认删除？",
            onOk(){
                that.props.organizationUserDelete({userId:userId}).then(msg=>{
                    if(msg.data.code=== 200){
                        message.success("删除成功！");
                        that.loadDatas();
                    }
                })
            }
        });

    }

    showUserModal=(record)=>{
        var that = this;
        that.props.organizationUserRoleList({userId:record.userId}).then(res=>{
            if(res.data.code === 200){
                var datas = [];
                // .forea
                res.data.data.forEach(elem => {
                    if(elem.roleSign==="true")
                    datas = _.concat(datas,elem.roleId)
                });
                that.setState({
                    curRoles:datas,
                    roleVisble:true,
                    curRecord:record
                })
            }
        })
    }

    roleHandleCancel=()=>{
        this.setState({
            roleVisble:false
        })
    }

    roleOnChange=(e,roleId)=>{
        var {curRoles} = this.state;
        if(e.target.checked){
            curRoles = _.concat(curRoles,roleId);
        }
        else{
            curRoles=_.filter(curRoles,function(o){
                return o !==roleId
            })
        }
        this.setState({curRoles})
    }

    roleHandleOK=()=>{
        var {curRecord,curRoles} = this.state
        var obj={
            roleIds:curRoles,
            userId:curRecord.userId
        }
        var that = this;
        that.props.organizationUserRoleUpdate(obj).then(msg=>{
            if(msg.data.code === 200){
                that.loadDatas();
                that.setState({
                    roleVisble:false,
                    curRecord:undefined
                })
                message.success("修改成功！")
            }
        })
    }

    
    changePage=page=>{
        var that = this
        this.setState({page},()=>{
            that.loadDatas()
        })
    }

    render() {
        const {roleList, organList,curRoles, dataList, curRecord, oldPassWord, npassword,againpassword} = this.state;
      
         const { getFieldDecorator } = this.props.form;
        var user= JSON.parse(localStorage.getItem("user"))
        const formItemLayout = {
            labelCol: {
              xs: { span: 24 },
              sm: { span: 8 },
            },
            wrapperCol: {
              xs: { span: 24 },
              sm: { span: 16 },
            },
          };

          const passwordValidator = (rule, value, callback) => {
            if (!value) {
                callback();
            }
             if (value.length<6) {
                callback('密码长度不少于六位！');
            }
            callback();
        } 
          const spasswordValidator=(rule,value,callback,markstr)=>{
            if (!value) {
              callback();
            }
            if(this.props.form.getFieldValue(markstr) !==value){
              callback('两次密码不一致！');
            }
            callback();
      
          }
        return (
            <div>
                <div className="StatisticsPage-head pageHeader"><h3>人员管理</h3></div>
                <div className="StatisticsPage-box">
                    <div className="StatisticsPage-search">
                        <div className="StatisticsPage-btn">
                            <Button className="btn" type="primary" onClick={this.showModal}>添加</Button>
                           <Modal title="修改用户角色" visible={this.state.roleVisble}
                            onOk={this.roleHandleOK} onCancel={this.roleHandleCancel}>
                               <div className="form-group">
                                   {roleList.map(role=>{
                                       return <Checkbox key={role.roleId} value={role.roleId} 
                                      checked={_.find(curRoles,function(o){return role.roleId===o})===undefined?false:true}
                                       onChange={(e)=>this.roleOnChange(e,role.roleId)}>{role.roleName}</Checkbox>
                                   })}
                                </div>  

                           </Modal>
                            <Modal title="修改密码" visible={this.state.resetVisible} onOk={this.resetHandleOk} onCancel={this.resetHandleCancel} okText="保存" cancelText="取消">
                                <div className="form-group">
                                    <label className="form-label">原密码：</label>
                                    <div className="form-ipt">
                                        <Input.Password value={oldPassWord} placeholder="请输入旧密码"  onChange={e => this.oldPassWordOnChange(e)} />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="form-label">新密码：</label>
                                    <div className="form-ipt">
                                        <Input.Password value={npassword} placeholder="请输入新密码"  onChange={e => this.newPassWordOnChange(e)} />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="form-label">确认密码：</label>
                                    <div className="form-ipt">
                                        <Input.Password value={againpassword} placeholder="请再次输入新密码"  onChange={e => this.newPassWordAgainOnChange(e)} />
                                    </div>
                                </div>
                            </Modal>
                            <Modal title="添加/编辑管理员" visible={this.state.visible} onOk={this.handleOk} onCancel={this.handleCancel} okText="保存" cancelText="取消">
                            <Form  {...formItemLayout } >
                                <Form.Item label="用户名">
                                {getFieldDecorator('username',{
                                    rules: [ { required: true, message: '请输入用户名' },],
                                })(<Input placeholder="请输入用户名"/>)}
                                </Form.Item>
                                <Form.Item label="账号名">
                                {getFieldDecorator('account',{
                                    rules: [ { required: true, message: '请输入账号名' },],
                                })(<Input placeholder="请输入账号名"/>)}
                                </Form.Item>
                               {curRecord===undefined&& <Form.Item label="密码">
                                    {getFieldDecorator('password', {
                                        rules: [{ required: true, message: '请输入密码'},
                                        {validator:passwordValidator}],
                                    })(<Input.Password autoComplete="new-password"  placeholder="请输入密码" /> )}
                               </Form.Item> }
                               {curRecord===undefined&&
                                <Form.Item label="确认密码">
                                    {getFieldDecorator('newpassword', {
                                        rules: [{ required: true, message: '请再次确认密码'},
                                        { validator: (rule,value,callback)=>spasswordValidator(rule,value,callback,'password')}],
                                    })(<Input.Password autoComplete="new-password"  placeholder="请再次确认密码"/> )}
                               </Form.Item> }
                               {curRecord===undefined&&
                               <Form.Item label="所属机构">
                                {getFieldDecorator('organizationId', {
                                        rules: [{ required: true, message: '请选择所属于机构'}],
                                    })(<Select showSearch 
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }>
                                        {organList.map(organ=>{
                                            return <Option key={organ.id} value={organ.id}>{organ.alias}</Option>
                                        })}
                                    </Select> )}
                                </Form.Item>}
                                {curRecord===undefined&&
                                <Form.Item label="状态">
                                {getFieldDecorator('status', {
                                        rules: [{ required: true, message: '请选择状态'}],
                                    })( <Radio.Group defaultValue={1}>
                                        <Radio value={1}>启用</Radio>
                                        <Radio value={0}>禁用</Radio>
                                    </Radio.Group> )}
                                </Form.Item>}
                                <Form.Item label="角色">
                                    {getFieldDecorator('roleIds', {
                                        rules: [{ required: true, message: '请选择角色'}],
                                    })(<Checkbox.Group>
                                        {roleList.map(role=>{
                                       return <Checkbox key={role.roleId} value={role.roleId} 
                                       >{role.roleName}</Checkbox>
                                   })}
                                    </Checkbox.Group>)}
                                </Form.Item>
                                {/* 
                                  return <Checkbox key={role.roleId} value={role.roleId} 
                                      checked={_.find(curRoles,function(o){return role.roleId===o})===undefined?false:true}
                                       onChange={(e)=>this.roleOnChange(e,role.roleId)}>{role.roleName}</Checkbox>
                               
                                </Form.Item>
                             */}
                            </Form>
                              
                             
                            </Modal>
                        </div> 
                    </div>    
                    <div>
                        <Table  size="middle" dataSource={dataList} bordered 
                          pagination={{ pageSize: 10, onChange: this.changePage ,total:this.state.total}} >
                        {/* <Column title="编号" dataIndex="id" key="id" align="center" /> */}
                        <Column title="用户名" dataIndex="username" key="username" align="center" />
                        <Column title="用户账号" dataIndex="account" key="account" align="center" />
                        <Column title="所属机构名称" dataIndex="organizationName" key="organizationName" align="center" 
                        width="200px" render={(text, record) => (
                            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                                {text}
                            </div>
                        )} />
                        <Column title="操作" align="center" key="action"
                                render={(text, record) => (
                                    <span>
                                        {user.account === record.account&&<span style={{ cursor: 'pointer' }} onClick={()=>this.editItem(record)}>编辑</span>}
                                        {user.account === record.account&&<Divider type="vertical" /> }
                                        <span style={{ cursor: 'pointer' }} onClick={()=>this.resetPassword(record)}>修改密码</span>
                                        {record.organizationId!==null&& <Divider type="vertical" />}
                                        {record.organizationId!==null&& <span style={{ cursor: 'pointer' }} onClick={()=>this.delItem(record.userId)} >删除</span>}
                                        <Divider type="vertical" />
                                        <span style={{cursor:'pointer'}} onClick={()=>this.showUserModal(record)}>修改用户角色</span>
                                    </span>
                                )} />
                            </Table>
                    </div>    
                </div>
                
            </div>
        )
    }
}
export default connect(null,{})(PersonManage)