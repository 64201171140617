import React, { Component } from 'react'
import { Button, Input, message } from 'antd';
import { connect } from 'react-redux'
import { mealList, mealClassifyList } from '../../actions/account'
import './query.css'
import cookie from 'react-cookies';
import liulanqi from '../../assets/lliulanqi.png'
import axios from "axios";


class students_meal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            org: '',
            phy: '',
            orgName: '',
            pageTitle: '',
            occupationList1: '',
            erweimaOrg: '',
            erweimaPhy: '',
            // =======
            mealArr: [],
            khd: '',
            leftArr: [],
            serchText: '',
            ind: 0,
            // =========
            signature: '',
            str: '',
            code: '',

        }

    }
    componentDidMount() {
        document.title = '预约'
        var temp = [],
            that = this
        console.log(this.props.location.search.substring(1))
        var params = this.props.location.search.substring(1).split('&')
        var val = []
        var query = {}
        console.log(params)
        for (var i = 0; i < params.length; i++) {
            val = params[i].split('=')
            if (val[0] === 'erweimaName' || val[0] === 'erweimaOrgName') {
                query[val[0]] = decodeURI(val[1])
            } else query[val[0]] = val[1]
        }
        console.log(query)
        this.setState(
            {
                org: query.erweimaOrg,
                phy: query.erweimaPhy,
                orgName: query.erweimaOrgName.replace('永林', '永宁'),
                code: query.code
            },
            () => {
                this.getmealList(query.erweimaOrg)
            }
        )

        var locationData = query
        this.setState({
            pageTitle: locationData.erweimaName.replace('永林', '永宁'),
            occupationList1: temp,
            erweimaOrg: query.erweimaOrg.replace('永林', '永宁'),
            erweimaPhy: query.erweimaPhy.replace('永林', '永宁'),
        })

        // ========
        var ua = window.navigator.userAgent.toLowerCase();
        if (ua.match(/MicroMessenger/i) == 'micromessenger') {
            // this.setState({
            //     khd: 'wx',
            // })

        } else {
            // this.setState({
            //     khd: 'qt',
            // })
        }


    }




    generateNonceStr = (length = 32) => {
        let chars =
            "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        let result = "";
        for (let i = 0; i < length; i++) {
            result += chars[Math.floor(Math.random() * chars.length)];
        }
        return result;
    }
    serchTextChange = (e) => {
        this.setState({
            serchText: e.target.value,
        })
    }
    sercgTetxonSearch = (e) => {
        var { serchText, leftArr } = this.state
        console.log(serchText)
        // this.someMethod()
        this.getgetmealListNew()

    }


    getmealList = () => {
        var { org } = this.state
        var that = this
        this.props.mealClassifyList({
            page: 0,
            size: 999,
            status: 1,
            organizationId: org
        }).then(msg => {

            that.setState({
                leftArr: !!msg.data.data.rows ? msg.data.data.rows : []
            }, () => {
                if (msg.data.data.rows.length > 0) {
                    this.getgetmealListNew()
                }
            })
        })
    }
    getgetmealListNew = () => {
        var that = this
        var { ind, org, leftArr, serchText } = this.state
        this.props.mealList({
            page: 0,
            size: 999,
            organizationId: org,
            classifyId: !!serchText ? '' : leftArr[ind].id,
            mealName: !!serchText ? serchText : ''
        }).then((msg) => {
            if (msg.data.code === 200) {
                that.setState({
                    mealArr: !!msg.data.data.rows ? msg.data.data.rows : [],
                }, () => {
                    if (!!serchText && msg.data.data.rows.length > 0) {
                        leftArr.map((res, i) => {
                            if (res.id == msg.data.data.rows[0].classifyId) {
                                that.setState({
                                    ind: i
                                })
                            }
                        })
                    }
                })
                if (msg.data.data.rows.length == 0) {
                    message.error('暂无数据')
                }
            }
        })
    }
    openDetail = (itm) => {
        console.log(itm)
        var {
            erweimaOrg, erweimaPhy, pageTitle, orgName, leftArr, ind
        } = this.state
        this.props.history.push({ pathname: '/students_detail', state: { detail: itm, erweimaOrg: erweimaOrg, erweimaPhy: erweimaPhy, erweimaName: pageTitle, erweimaOrgName: orgName, mealClassify: leftArr[ind].classifyName } })
    }
    openInd = (itm) => {
        console.log(itm)
        var {
            ind
        } = this.state

        this.setState({
            ind: itm,
            serchText: ''
        }, () => {
            this.getgetmealListNew()
        })
    }

    render() {
        const {

            pageTitle, mealArr, khd, liulanqi, leftArr, serchText, ind, str

        } = this.state
        return (
            <div className="appointquery-bg">
                {khd == 'wx' ? (
                    <div id="container" >
                        <div style={{ position: 'relative', paddingTop: '100px', color: '#333', fontSize: '22px', textAlign: 'center' }}>
                            <i style={{ position: 'absolute', top: '10px', right: '10px', fontSize: '100px' }}></i>
                            <p style={{ fontWeight: '600' }}>请点击屏幕右上角 [ ··· ] </p>
                            <p>在 <img src={liulanqi} alt="" srcset="" style={{ width: '25px' }} /> 浏览器打开</p></div>
                    </div>
                ) : (
                    <div style={{ padding: '0', height: '100vh' }}>
                        <div className="StatisticsPage-head1 pageHeader1" style={{ height: '40px' }}>
                            <h3>{pageTitle}预约
                            </h3>
                        </div>
                        {/* 搜索 */}
                        <div style={{ textAlign: 'center', paddingBottom: '10px', paddingTop: '10px' }}>
                            <Input.Search
                                allowClear
                                style={{
                                    width: '92%',
                                }}
                                placeholder='请输入套餐名称'
                                value={serchText}
                                onChange={(e) => this.serchTextChange(e)}
                                onSearch={() => this.sercgTetxonSearch()}
                            />
                        </div>

                        <div style={{ display: 'flex' }}>


                            <div style={{
                                width: '25%', position: 'absolute',
                                left: '0',
                                height: '89%',
                                overflow: 'scroll'
                            }}>
                                {leftArr.map((res, i) => (
                                    <div style={{ height: '50px', textAlign: 'center', lineHeight: '50px', background: ind == i ? "#fff" : '', borderLeft: ind == i ? '4px solid #E60012' : 'none' }} onClick={() => this.openInd(i)}>
                                        {res.classifyName}
                                    </div>
                                ))}
                            </div>
                            <div style={{
                                width: '75%', position: 'absolute',
                                right: '0',
                                height: '89%',
                                overflow: 'scroll',
                                background: '#fff',
                                paddingLeft: '20px'
                            }}>
                                <div style={{ width: '100%', padding: '10px 0', color: '#999', fontWeight: "bold" }}> {leftArr.length > 0 ? leftArr[ind].classifyName : ''}</div>
                                {mealArr.map(res => (
                                    <div style={{ height: '100px', overflow: 'hidden', marginBottom: '20px', paddingRight: "10px" }} onClick={() => this.openDetail(res)}>
                                        <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center' }}>
                                            <div style={{ width: '100px', marginRight: '10px', height: '100px', backgroundImage: `url(${res.image})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center center', backgroundSize: "cover" }}>
                                                {/* <img src={res.image} alt="" srcset="" style={{ width: '100%' }} /> */}
                                            </div>
                                            <div style={{ height: '100%', width: "55%" }}>
                                                <div style={{
                                                    fontWeight: 'bold', fontSize: '15px', display: '-webkit-box',
                                                    '-webkit-box-orient': 'vertical',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    '-webkit-line-clamp': '2', marginBottom: '5px',
                                                    'line-height': '1.2', /* 根据你的字体大小调整 */
                                                    height: '2.4em' /* 行高乘以行数 */
                                                }}>{res.mealName}</div>
                                                <div style={{
                                                    fontSize: '14px', display: '-webkit-box',
                                                    '-webkit-box-orient': 'vertical',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    '-webkit-line-clamp': '2',
                                                    'line-height': '1.2', /* 根据你的字体大小调整 */
                                                    height: '2.4em'/* 行高乘以行数 */
                                                }}>{res.checkProject}</div>
                                                <div style={{ fontSize: '15px', display: 'flex', marginTop: '5px' }}><div style={{ textDecorationLine: 'line-through', textDecorationColor: '#999', textDecorationStyle: 'solid', marginRight: '10px', color: '#999' }}>¥{res.originalPrice}</div><div style={{ color: 'red' }}>折后价：¥{res.mealPrice}</div></div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                )
                }
            </div >
        )
    }
}
export default connect(null, {
    mealList, mealClassifyList
})(students_meal)