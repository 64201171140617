import React, { Component } from 'react'
import { Button, Table, Input, DatePicker, Spin, message, Select, } from 'antd'
import {
  orgAppointmentList,
  orgAppointmentExport,
  exportAppointGroup,
  getTwoCancerIsHave,
  appointmentRegisterDetail, getPhysicalTypeList
} from './../../../actions/account'
import { connect } from 'react-redux'
import moment from 'moment'
import _ from 'lodash'
import XLSX from 'xlsx'
const { Option } = Select
const { Column } = Table
const { RangePicker } = DatePicker
const dateFormat = 'YYYY-MM-DD hh:mm:ss'

class AppointStatisics extends Component {
  constructor(props) {
    super(props)
    this.state = {
      iframeUrl: null,
      choosedDates: [moment(), moment().add('days', 90)],
      username: '',
      dataList: [],
      page: 1,
      pageSize: 10,
      total: 0,
      contents: [],
      idcard: '',
      selectedRowKeys: [],
      startTime: moment().format('YYYY-MM-DD') + ' 00:00:00',
      endTime: moment().add('days', 90).format('YYYY-MM-DD') + ' 23:59:59',
      spinstatus: false,
      dataTypeList: [],
      orderStatus: undefined
    }
  }

  componentDidMount() {
    this.loadDatas()
    this.loadDatasTypeList()
  }

  loadDatas = () => {
    var { startTime, endTime, page, username, idcard, pageSize, physicalTypeId, orderStatus } = this.state
    var obj = { page: page - 1, size: pageSize }
    if (startTime !== undefined) {
      obj.startDate = startTime
    }
    if (endTime !== undefined) {
      obj.endDate = endTime
    }
    if (username !== '') {
      obj.username = username
    }
    if (idcard !== '') {
      obj.idCard = idcard
    }
    if (physicalTypeId !== '') {
      obj.physicalTypeId = physicalTypeId
    }
    if (orderStatus !== '') {
      obj.orderStatus = orderStatus
    }
    var that = this
    that.props.orgAppointmentList(obj).then((res) => {
      if (res.data.code === 200) {
        console.log(res.data.data.total)
        that.setState({
          dataList: res.data.data.rows,
          total: res.data.data.total,
        })
      }
    })
  }
  loadDatasTypeList = () => {
    var that = this

    this.props.getPhysicalTypeList().then((msg) => {
      console.log(msg)
      if (msg.data.code === 200) {
        that.setState({
          dataTypeList: msg.data.data,

        })
      }
    })
  }
  usernameOnChange = (e) => {
    this.setState({
      username: e.target.value,
    })
  }

  idcardOnChange = (e) => {
    this.setState({
      idcard: e.target.value,
    })
  }

  datetimeChange = (date, dateString) => {
    console.log(date)
    this.setState({
      startTime: dateString[0] === '' ? undefined : dateString[0] + ' 00:00:00',
      endTime: dateString[1] === '' ? undefined : dateString[1] + ' 23:59:59',
      choosedDates: date,
    })
  }

  export = () => {
    var { startTime, endTime } = this.state
    var obj = {}
    if (startTime !== undefined) {
      obj.startDate = startTime
    }
    if (endTime !== undefined) {
      obj.endDate = endTime
    }
    var that = this
    this.setState(
      {
        spinstatus: true,
      },
      () => {
        that.props.orgAppointmentExport(obj).then((res) => {
          const fileName = 'export' + moment().format('YYYYMMDDHHmmss')
          if ('download' in document.createElement('a')) {
            // 非IE下载
            const url = window.URL.createObjectURL(
              new Blob(
                [res.data],
                // 设置该文件的mime类型，这里对应的mime类型对应为.xlsx格式
                { type: 'application/vnd.ms-excel' }
              )
            )
            const link = document.createElement('a')
            link.href = url
            // 从header中获取服务端命名的文件名
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
          } else {
            // IE10+下载
            navigator.msSaveBlob(res.data, fileName)
          }
          that.setState({ spinstatus: false })
        })
      }
    )
  }

  changePage = (page) => {
    var that = this
    this.setState({ page }, () => {
      that.loadDatas()
    })
  }
  onSelectChange = (selectedRowKeys) => {
    var { dataList } = this.state
    this.setState({ selectedRowKeys })
    var barcodes = []
    selectedRowKeys.forEach((selectedRowKey) => {
      barcodes = _.concat(
        barcodes,
        _.find(dataList, ['id', selectedRowKey]).idCard
      )
    })
    this.setState({ selectedRowInfoKeys: selectedRowKeys })
  }

  appointStatisics = () => {
    var { startTime, endTime } = this.state
    var formdata = new FormData()
    formdata.append('startTime', startTime.split(' ')[0])
    formdata.append('endTime', endTime.split(' ')[0])
    formdata.append(
      'organizationId',
      JSON.parse(localStorage.getItem('data')).organizationId
    )
    var datas = []
    var that = this
    this.props.exportAppointGroup(formdata).then((res) => {
      if (res.data.code === 200) {
        var rows = res.data.data
        for (var i = 0; i < rows.length; i++) {
          var row = rows[i]
          for (var j = 0; j < row.appointmentGroupDTOS.length; j++) {
            var appointmentGroupDTO = row.appointmentGroupDTOS[j]
            var obj = {
              date: appointmentGroupDTO.appointmentDate,
              ageRange: appointmentGroupDTO.ageRange,
              sex: appointmentGroupDTO.sex,
              count: appointmentGroupDTO.count,
            }
            datas = _.concat(datas, obj)
          }
        }
        var initColumn = [
          {
            title: '日期',
            dataIndex: 'date',
            key: 'date',
            className: 'text-monospace',
          },
          {
            title: '年龄段',
            dataIndex: 'ageRange',
            key: 'ageRange',
            className: 'text-monospace',
          },
          {
            title: '性别',
            dataIndex: 'sex',
            key: 'sex',
            className: 'text-monospace',
          },
          {
            title: '预约人数',
            dataIndex: 'count',
            key: 'count',
            className: 'text-monospace',
          },
        ]

        that.exportExcel(initColumn, datas, '预约人数分类统计.xlsx')
      }
    })
  }
  exportExcel = (headers, data, fileName = '请假记录表.xlsx') => {
    const _headers = headers
      .map((item, i) =>
        Object.assign(
          {},
          {
            key: item.key,
            title: item.title,
            position: String.fromCharCode(65 + i) + 1,
          }
        )
      )
      .reduce(
        (prev, next) =>
          Object.assign({}, prev, {
            [next.position]: { key: next.key, v: next.title },
          }),
        {}
      )

    const _data = data
      .map((item, i) =>
        headers.map((key, j) =>
          Object.assign(
            {},
            {
              content: item[key.key],
              position: String.fromCharCode(65 + j) + (i + 2),
            }
          )
        )
      )
      // 对刚才的结果进行降维处理（二维数组变成一维数组）
      .reduce((prev, next) => prev.concat(next))
      // 转换成 worksheet 需要的结构
      .reduce(
        (prev, next) =>
          Object.assign({}, prev, { [next.position]: { v: next.content } }),
        {}
      )

    // 合并 headers 和 data
    const output = Object.assign({}, _headers, _data)
    // 获取所有单元格的位置
    const outputPos = Object.keys(output)
    // 计算出范围 ,["A1",..., "H2"]
    const ref = `${outputPos[0]}:${outputPos[outputPos.length - 1]}`

    // 构建 workbook 对象
    const wb = {
      SheetNames: ['mySheet'],
      Sheets: {
        mySheet: Object.assign({}, output, {
          '!ref': ref,
          '!cols': [
            { wpx: 45 },
            { wpx: 100 },
            { wpx: 200 },
            { wpx: 80 },
            { wpx: 150 },
            { wpx: 100 },
            { wpx: 300 },
            { wpx: 300 },
          ],
        }),
      },
    }

    // 导出 Excel
    XLSX.writeFile(wb, fileName)
  }
  printRegister = (record) => {
    this.setState({ iframeUrl: null }, () => {
      message.loading({ content: '查询中...', duration: 2 })
      this.props
        .appointmentRegisterDetail({ idCard: record.idCard })
        .then((res) => {
          message.destroy()
          if (res.data.code === 200) {
            this.setState({
              iframeUrl: '/AppointmentRegisterPrint?curRecord=' + record.idCard,
            })
          } else {
            message.error(res.data.message)
          }
        })
    })
  }
  check = async () => {
    var { selectedRowKeys, dataList } = this.state
    var that = this
    for (var i = 0; i < selectedRowKeys.length; i++) {
      var idcard = _.find(dataList, ['id', selectedRowKeys[i]]).idCard
      await this.props.getTwoCancerIsHave({ idCard: idcard }).then((res) => {
        if (res.data.code === 200) {
          that.setState({
            contents: _.concat(that.state.contents, idcard + '臻鼎无数据！'),
          })
        } else {
          that.setState({
            contents: _.concat(
              that.state.contents,
              idcard + '-->' + res.data.message
            ),
          })
        }
      })
    }
  }
  pageSizeOnChange = (current, size) => {
    var that = this
    this.setState({ pageSize: size, selectedRowKeys: [] }, () => {
      that.loadDatas()
    })
  }
  onphySelectChange = (value) => {
    this.setState({
      physicalTypeId: value,
    })
  }
  orderStatusSelectChange = (value) => {
    this.setState({
      orderStatus: value,
    })
  }
  render() {
    const {
      choosedDates,
      username,
      idcard,
      dataList,
      spinstatus,
      selectedRowKeys,
      contents, dataTypeList,
      physicalTypeId, orderStatus
    } = this.state
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    }
    return (
      <div>
        {this.state.iframeUrl && (
          <div className="web_view_box">
            <iframe
              name="myiframe"
              src={this.state.iframeUrl}
              scrolling="auto"
              frameBorder="0"
              width="100%"
              style={{ display: 'none' }}
            ></iframe>
          </div>
        )}
        <div className="StatisticsPage-head pageHeader">
          <h3>预约统计</h3>
        </div>
        <div className="StatisticsPage-box">
          <div className="StatisticsPage-search">
            <div className="StatisticsPage-btn">
              <RangePicker
                dateFormat={dateFormat}
                placeholder={['开始时间', '结束时间']}
                className="date"
                onChange={this.datetimeChange}
                value={choosedDates}
              />
              <Input
                className="ipt"
                placeholder="请输入姓名"
                value={username}
                onChange={this.usernameOnChange}
              />
              <Select
                className="ipt"
                allowClear={true}
                placeholder="请选择体检类型"
                value={physicalTypeId}
                onChange={this.onphySelectChange}
              >
                {dataTypeList.map((phy) => {
                  return (
                    <Option key={phy.id}>
                      {phy.physicalType}
                    </Option>
                  )
                })}
              </Select>
              <Select
                className="ipt"
                allowClear={true}
                placeholder="请选择订单状态"
                value={orderStatus}
                onChange={this.orderStatusSelectChange}
              >
                <Option key='0'>
                  无需支付
                </Option>
                <Option key='1'>
                  待付款
                </Option>
                <Option key='2'>
                  待使用
                </Option>
                <Option key='3'>
                  已完成
                </Option>
                <Option key='4'>
                  已退款
                </Option>
              </Select>
              <Input
                className="ipt"
                placeholder="请输入身份证"
                value={idcard}
                onChange={this.idcardOnChange}
              />
              <Button className="btn" type="primary" onClick={this.loadDatas}>
                搜索
              </Button>
              <Button className="btn" type="primary" onClick={this.export}>
                导出
              </Button>
              <Button
                className="btn"
                type="primary"
                onClick={this.appointStatisics}
              >
                预约统计
              </Button>
              <Button className="btn" type="primary" onClick={this.check}>
                臻鼎查重
              </Button>
            </div>
          </div>
          <div>
            <Spin spinning={spinstatus}>
              <Table
                rowKey="id"
                size="middle"
                dataSource={dataList}
                bordered
                rowSelection={rowSelection}
                pagination={{
                  pageSize: this.state.pageSize,
                  showTotal: (total) => {
                    return `共 ${total} 条`
                  },
                  onChange: this.changePage,
                  pageSizeOptions: ['10', '20', '50', '100', '500'],
                  total: this.state.total,
                  showSizeChanger: true,
                  onShowSizeChange: this.pageSizeOnChange,
                }}
              >
                <Column
                  title="姓名"
                  dataIndex="username"
                  key="username"
                  align="center"
                />
                <Column
                  title="身份证号"
                  dataIndex="idCard"
                  key="idCard"
                  align="center"
                />
                <Column
                  title="预约时间段"
                  dataIndex="appointmentDate"
                  key="appointmentDate"
                  align="center"
                />
                <Column
                  title="联系电话"
                  dataIndex="phone"
                  key="phone"
                  align="center"
                />
                <Column
                  title="单位"
                  dataIndex="workUnit"
                  key="workUnit"
                  align="center"
                />
                <Column
                  title="套餐名称"
                  dataIndex="mealName"
                  key="mealName"
                  align="center"
                />
                <Column
                  title="预约类型"
                  dataIndex="physicalTypeName"
                  key="physicalTypeName"
                  align="center"
                />
                <Column
                  title="订单状态"
                  dataIndex="orderStatus"
                  key="orderStatus"
                  align="center"
                  render={(text, record) => (
                    <span

                    >
                      {record.orderStatus == '0' ? '无需支付' : record.orderStatus == '1' ? '待付款' : record.orderStatus == '2' ? '待使用' : record.orderStatus == '3' ? '已完成' : record.orderStatus == '4' ? '已退款' : ''}
                    </span>
                  )}
                />
                <Column
                  title="操作"
                  key="operate"
                  align="center"
                  render={(text, record) => (
                    <span
                      style={{ cursor: 'pointer', color: '#41A99B' }}
                      onClick={() => this.printRegister(record)}
                    >
                      打印档案登记表
                    </span>
                  )}
                />
              </Table>
            </Spin>
          </div>
        </div>
        {
          contents.map((content, index) => {
            return <div key={'contents2' + index}>{content}</div>
          })
        }
      </div >
    )
  }
}
export default connect(null, {
  orgAppointmentList,
  orgAppointmentExport,
  exportAppointGroup,
  getTwoCancerIsHave,
  appointmentRegisterDetail, getPhysicalTypeList
})(AppointStatisics)
