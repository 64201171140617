import React, { Component } from 'react'
import './appointmentDate.css'
import {
  Modal,
  Button,
  Input,
  Table,
  Empty,
  Spin,
  DatePicker,
  Select,
  message,
  TimePicker,
  Upload,
  Tooltip,
  Icon,
} from 'antd'
import { connect } from 'react-redux'
import {
  impotAppointmentUser,
  delDateDetail,
  appointmentListList,
  getAppointmentDateDetailList,
  organizationPhysicalType,
  addAppointmentDate, appointmentParamList,
  updateAppointmentDateDetail,
} from '../../../actions/account'
import moment from 'moment'
import {
  SearchOutlined,
  MinusCircleOutlined,
  EditOutlined,
} from '@ant-design/icons'

// import { ButtonBase } from '@material-ui/core';

const { RangePicker } = DatePicker
const dateFormat = 'YYYY-MM-DD'
const { Column } = Table
const { Option } = Select
const { confirm } = Modal

class AppointmentList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedRowKeys: [],
      week: null,
      weekList: ['周一', '周二', '周三', '周四', '周五', '周六', '周日'],
      initNumber: '',
      startDateDetail: '',
      endDateDetail: '',
      dateDetailVisible: false,
      editAppointRecord: null,
      choosedDates2: [],
      startTime2: undefined,
      endTime2: undefined,
      newPhysicalTypeId: '',
      curRecord: undefined,
      resultVisble: false,
      phyId: '',
      physicalTypeList: [],

      dataList: [],
      organList: [],

      startTime: undefined,
      endTime: undefined,
      choosedDates: [], //时间默认值
      spinning: false,
      loading: false,
      visible: false,
      submitSpinState: false,
      timearr1: [],
      timearr: [{
        label: '周一',
        id: 1,

      }, {
        label: '周二',
        id: 2,

      }, {
        label: '周三',
        id: 3,

      }, {
        label: '周四',
        id: 4,

      }, {
        label: '周五',
        id: 5,

      }, {
        label: '周六',
        id: 6,

      }, {
        label: '周日',
        id: 7,
      },],
      appointmentId: null
    }
  }
  componentDidMount() {
    var that = this
    that.setState({
      appointmentId: this.props.location.state.appointmentId,
    }, () => {
      this.loadDatas()
    })
    console.log(this.props.location)

    this.timeWeek()

    this.props.organizationPhysicalType().then((msg) => {
      if (msg.data.code === 200) {
        that.setState({
          physicalTypeList: msg.data.data.rows,
        })
      }
    })
  }
  goBack = () => {

    this.props.history.push({ pathname: '/admin/AppointmentDateManage/AppointmentDate' })
  }
  timeWeek = () => {
    var { timearr } = this.state
    this.props.appointmentParamList({}).then((msg) => {
      console.log(msg)
      if (msg.data.code === 200) {
        if (msg.data.data.rows.length > 0) {
          let arr = []
          timearr.map(res => {
            msg.data.data.rows[0].week.split(',').map(res1 => {
              if (res.id == res1) {
                arr.push(res)
              }
            })
          })
          console.log(arr)
          this.setState({
            timearr1: arr
          })
        }
      }
    })
  }
  onphySelectChange = (value) => {
    this.setState({
      phyId: value,
    })
  }
  onphySelectChange2 = (value) => {
    console.log(value)
    this.setState({
      newPhysicalTypeId: value,
    })
  }

  reset = () => {
    this.setState(
      {
        choosedDates: [],
        startTime: undefined,
        endTime: undefined,
        phyId: undefined,
      },
      () => {
        this.loadDatas()
      }
    )
  }
  loadDatas = () => {
    this.setState({
      spinning: true,
    })
    var { startTime, endTime, phyId, appointmentId } = this.state
    var that = this
    var obj = {
      appointmentId: appointmentId
    }
    if (phyId && phyId.length > 0) obj.physicalTypeId = phyId
    if (startTime && startTime.length > 0) obj.startTime = startTime
    if (endTime && endTime.length > 0) obj.endTime = endTime

    this.props.appointmentListList(obj).then((msg) => {
      that.setState({
        spinning: false,
      })
      if (msg.data.code === 200) {
        that.setState({
          dataList: msg.data.data,
        })
      }
    })
  }

  datetimeChange = (date, dateString) => {
    // var choseDate =[];
    // choseDate = _.concat(choseDate,moment(dateString[0]+" 00:00:00","YYYY-MM-DD hh:mm:ss"));
    // choseDate = _.concat(choseDate,moment(dateString[1]+" 23:59:59","YYYY-MM-DD hh:mm:ss"));
    this.setState({
      startTime: dateString[0] === '' ? undefined : dateString[0] + ' 00:00:00',
      endTime: dateString[1] === '' ? undefined : dateString[1] + ' 23:59:59',
      choosedDates: date,
    })
  }

  disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < moment().endOf('day')
  }
  addAppintCancel = () => {
    this.setState({
      week: null,
      editAppointRecord: null,
      visible: false,
      initNumber: '',
      startTime2: undefined,
      endTime2: undefined,
      choosedDates2: [],
      newPhysicalTypeId: '',
    })
  }
  addDateDetail = () => {
    console.log('123')
    this.setState({
      dateDetailVisible: true,
    })
  }
  addDateDetailOk = () => {
    this.setState({
      editAppointRecord: null,
      dateDetailVisible: false,
    })
  }
  adddateDetailCancel = () => {
    this.setState({
      editAppointRecord: null,
      dateDetailVisible: false,
    })
  }
  editAppoint = (record) => {
    console.log(record)
    this.setState({
      editAppointRecord: record,
      initNumber: record.initNumber,
      week: record.week.toString(),
      startDateDetail: record.startTime.substring(11, 19),
      endDateDetail: record.endTime.substring(11, 19),
      newPhysicalTypeId: record.physicalTypeId,
      visible: true,
      choosedDates2: [moment(record.startTime), moment(record.endTime)],
    })
  }
  onSetDateDetail = (record) => {
    this.setState({
      dateDetailVisible: true,
      editAppointRecord: record,
    })
  }
  addAppintOk = () => {
    var that = this
    var {
      startDateDetail,
      endDateDetail,
      initNumber,
      editAppointRecord,
      week,
    } = this.state
    console.log(startDateDetail, endDateDetail, initNumber, editAppointRecord)
    if (
      startDateDetail.length === 0 ||
      endDateDetail.length === 0 ||
      initNumber.length === 0 ||
      !!!week
    ) {
      message.warn('请填写完整！')
      return
    }
    if (isNaN(initNumber)) {
      message.warn('初始名额为数字！')
      return
    }
    var date1 = editAppointRecord.startTime.substring(0, 10)
    var date2 = editAppointRecord.endTime.substring(0, 10)
    var obj = {
      id: editAppointRecord.detailId,
    }
    // if (`${date1} ${startDateDetail}` !== editAppointRecord.startTime)
    obj.startTime = `${date1} ${startDateDetail}`
    // if (`${date2} ${endDateDetail}` !== editAppointRecord.endTime)
    obj.endTime = `${date2} ${endDateDetail}`
    if (Number(initNumber) !== editAppointRecord.initNumber)
      obj.initNumber = Number(initNumber)
    if (Number(week) !== editAppointRecord.week) obj.week = Number(week)

    that.setState({ submitSpinState: true }, () => {
      that.props.updateAppointmentDateDetail(obj).then((res) => {
        that.loadDatas()
        if (res.data.code === 200) {
          message.success('修改成功！')
          that.setState({
            editAppointRecord: null,
            visible: false,
            startDateDetail: '',
            endDateDetail: '',
            week: null,
            submitSpinState: false,
          })
        } else {
          that.setState({
            submitSpinState: false,
          })
          message.warn(res.data.message)
        }
      })
    })
  }
  addAppoint = () => {
    this.setState({
      visible: true,
    })
  }
  ondateDetailChange1 = (time, timeString) => {
    this.setState({
      startDateDetail: timeString,
    })
  }
  ondateDetailChange2 = (time, timeString) => {
    this.setState({
      endDateDetail: timeString,
    })
  }
  initNumberChange = (e) => {
    if (e.target.value < 0 || e.target.value > 1000) {
      message.warn('名额范围在1~1001以内！')
      this.setState({
        initNumber: '',
      })
      return
    }
    this.setState({
      initNumber: e.target.value,
    })
  }
  getTime = (str) => {
    console.log(str)
    // return str.substring(0,10)
  }
  onweekSelectChange = (value) => {
    console.log(value)
    this.setState({
      week: value,
    })
  }
  onSelectChange = (selectedRowKeys) => {
    console.log(selectedRowKeys)
    this.setState({ selectedRowKeys })
  }
  delItem = () => {
    var { selectedRowKeys } = this.state
    if (selectedRowKeys.length === 0) {
      message.warn('请选择删除项!')
      return
    }
    var that = this
    confirm({
      content: '确认删除？',
      onOk() {
        that.props
          .delDateDetail({ ids: selectedRowKeys.join(',') })
          .then((msg) => {
            if (msg.data.code === 200) {
              message.info('删除成功')
              that.setState(
                {
                  selectedRowKeys: [],
                },
                () => {
                  that.loadDatas()
                }
              )
            } else {
              message.info(msg.data.message)
            }
          })
      },
    })
  }

  fileUploadOnChange = (info) => {
    console.log(info)
    // if (info.file.status === 'uploading') {
    //   this.setState({ loading: true })
    // } else {
    //   this.setState({ loading: false })
    // }
  }

  customRequest = (option, record) => {
    // var that = this;

    const formData = new FormData()
    formData.append('file', option.file)
    formData.append('appointmentDetailId', record.detailId)
    // 2021-04-19 14:30:00-15:30:00
    var time = record.startTime
    var endTime = moment(record.endTime, 'YYYY-MM-DD HH:mm:ss').format(
      'HH:mm:ss'
    )
    time = time + '-' + endTime
    formData.append('appointmentDate', time)
    this.props.impotAppointmentUser(formData).then((res) => {
      if (res.data.code === 200) {
        message.success('上传成功')
      }
    })
  }

  render() {
    const {
      selectedRowKeys,
      weekList,
      week,
      initNumber,
      phyId,
      dataList,
      spinning,
      choosedDates,
      loading,
      visible,
      physicalTypeList, timearr1,
    } = this.state
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      getCheckboxProps: (record) => ({
        disabled: !record.detailId,
      }),
    }
    return (
      <div>
        <div className="StatisticsPage-head pageHeader">
          {' '}
          <span
            onClick={this.goBack}
            style={{
              paddingRight: '20px',
              color: '#278B7B',
              cursor: 'pointer',
            }}
          >
            {'〈  返回列表'}
          </span>
        </div>
        <div className="StatisticsPage-box has-select-table">
          <div className="StatisticsPage-search">
            <div className="StatisticsPage-btn">
              <RangePicker
                dateFormat={dateFormat}
                placeholder={['开始时间', '结束时间']}
                className="date"
                onChange={this.datetimeChange}
                value={choosedDates}
              />
              <Select
                className="ipt"
                allowClear={true}
                placeholder="请选择所属类型"
                value={phyId}
                onChange={this.onphySelectChange}
              >
                {physicalTypeList.map((phy) => {
                  return (
                    <Option key={phy.physicalType}>
                      {phy.organizationName}--{phy.physicalName}
                    </Option>
                  )
                })}
              </Select>
              <Button
                className="btn"
                type="primary"
                onClick={this.loadDatas}
                icon={<SearchOutlined />}
              >
                搜索
              </Button>
              <Button className="btn" type="primary" onClick={this.reset}>
                重置
              </Button>
              <Button
                className="btn"
                type="primary"
                onClick={this.delItem}
                icon={<MinusCircleOutlined />}
              >
                删除
              </Button>
              {/* <Button className="btn" type="primary" onClick={this.importData}>导入</Button> */}
            </div>
          </div>
          <Spin tip="Loading..." spinning={spinning}>
            <div>
              {dataList.length > 0 ? (
                <Table
                  rowKey={(record) =>
                    record.detailId ? record.detailId : record.id
                  }
                  dataSource={dataList}
                  pagination={false}
                  bordered
                  rowSelection={rowSelection}
                  onRow={(record, index) => {
                    return {
                      onClick: () => {
                        if (!record.detailId) {
                          return
                        }
                        let temp = this.state.selectedRowKeys
                        let index = temp.indexOf(record.detailId)
                        if (index === -1) {
                          temp.push(record.detailId)
                        } else {
                          temp.splice(index, 1)
                        }
                        this.setState({ selectedRowKeys: temp })
                      },
                    }
                  }}
                >
                  <Column
                    width={50}
                    title="开始日期"
                    key="startDate"
                    align="center"
                    render={(text, record) => (
                      <span>{record.startDate.substring(0, 10)}</span>
                    )}
                  />
                  <Column
                    width={50}
                    title="结束日期"
                    key="endDate"
                    align="center"
                    render={(text, record) => (
                      <span>{record.endDate.substring(0, 10)}</span>
                    )}
                  />
                  <Column
                    width={50}
                    title="周几"
                    key="week"
                    align="center"
                    render={(text, record) => (
                      <span>{this.state.weekList[record.week - 1]}</span>
                    )}
                  />
                  <Column
                    width={50}
                    title="开始时间"
                    key="startTime"
                    align="center"
                    render={(text, record) => (
                      <span>
                        {record.startTime
                          ? record.startTime.substring(11, 19)
                          : '未设置'}
                      </span>
                    )}
                  />
                  <Column
                    width={50}
                    title="结束时间"
                    key="endTime"
                    align="center"
                    render={(text, record) => (
                      <span>
                        {record.endTime
                          ? record.endTime.substring(11, 19)
                          : '未设置'}
                      </span>
                    )}
                  />
                  <Column
                    width={50}
                    title="体检类型"
                    dataIndex="physicalType"
                    key="physicalType"
                    align="center"
                  />
                  <Column
                    width={50}
                    title="初始名额"
                    dataIndex="initNumber"
                    key="initNumber"
                    align="center"
                  />
                  {/* <Column width={50} title="剩余名额" dataIndex="residueNumber" key="residueNumber" align="center" /> */}
                  <Column
                    width={50}
                    title="操作"
                    key="operate"
                    align="center"
                    render={(text, record) => (
                      <span>
                        {(record.endTime || record.startTime) && (
                          <Button
                            size="small"
                            type="default"
                            onClick={() => {
                              this.editAppoint(record)
                            }}
                            icon={<EditOutlined />}
                          >
                            修改
                          </Button>
                        )}

                        {'   '}
                        <Upload
                          showUploadList={false}
                          customRequest={(option) =>
                            this.customRequest(option, record)
                          }
                          onChange={this.fileUploadOnChange}
                        >
                          <Tooltip placement="right">
                            {loading ? (
                              <Icon type="loading" />
                            ) : (
                              <Button size="small">

                                <Icon />
                                上传文件
                              </Button>
                            )}
                          </Tooltip>
                        </Upload>
                      </span>
                    )}
                  />
                </Table>
              ) : (
                <Empty />
              )}
            </div>
          </Spin>
        </div>
        <Modal
          width={800}
          maskClosable={false}
          title="时间修改"
          visible={visible}
          okText="提交"
          onOk={this.addAppintOk}
          onCancel={this.addAppintCancel}
        >
          <Spin tip="提交中..." spinning={this.state.submitSpinState}>
            <div className="form-group">
              <label className="form-label require">名称：</label>
              <div className="form-ipt">
                {this.state.editAppointRecord && (
                  <p>{this.state.editAppointRecord.physicalType}</p>
                )}
              </div>
            </div>
            <div className="form-group">
              <label className="form-label require">日期：</label>
              <div className="form-ipt">
                {this.state.editAppointRecord && (
                  <p>
                    {this.state.editAppointRecord.startDate}
                    {'   至   '}
                    {this.state.editAppointRecord.endDate}
                  </p>
                )}
              </div>
            </div>
            <div className="form-group">
              <label className="form-label require">时间段范围：</label>
              <div className="form-ipt">
                <TimePicker
                  onChange={this.ondateDetailChange1}
                  value={
                    !!this.state.startDateDetail
                      ? moment(this.state.startDateDetail, 'HH:mm:ss')
                      : null
                  }
                />
                <span style={{ padding: '0 20px' }}>--</span>
                <TimePicker
                  onChange={this.ondateDetailChange2}
                  value={
                    !!this.state.endDateDetail
                      ? moment(this.state.endDateDetail, 'HH:mm:ss')
                      : null
                  }
                />
              </div>
            </div>
            <div className="form-group">
              <label className="form-label require">初始名额：</label>
              <div className="form-ipt">
                <Input
                  placeholder="名额"
                  value={initNumber}
                  onChange={(e) => this.initNumberChange(e)}
                />
              </div>
            </div>
            <div className="form-group">
              <label className="form-label require">周几：</label>
              <div className="form-ipt">
                <Select
                  className="ipt"
                  allowClear={true}
                  placeholder="请选择所属类型"
                  value={week}
                  onChange={this.onweekSelectChange}
                >
                  {timearr1.map((week, index) => {
                    return <Option key={week.id}>{week.label}</Option>
                  })}
                </Select>
              </div>
            </div>
          </Spin>
        </Modal>
      </div>
    )
  }
}
export default connect(null, {
  getAppointmentDateDetailList,
  organizationPhysicalType,
  addAppointmentDate,
  impotAppointmentUser,
  updateAppointmentDateDetail,
  appointmentListList,
  delDateDetail, appointmentParamList
})(AppointmentList)
