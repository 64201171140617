import React, { Component } from 'react'
import './RegistrationPage.css'
import { Modal, Button, Input, Table, Empty, Upload,Tooltip,Icon, Spin, DatePicker, Select, message } from 'antd';
import { connect } from 'react-redux'
import {getOrganizationGuahaoList, getPhysicalTypeList, getAllorganization, readExcel } from '../../../actions/account'

const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD hh:mm:ss';
const { Column } = Table;
const { Option } = Select;
class AppointmentList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            curRecord: undefined,
            resultVisble: false,
            phyId: '',
            physicalTypeList: [],
            guahaoList: [],
            organizationList: [],
            page: 1,
            total: 0,
            dataList: [],
            organList: [],
            unitname: '',
            barcode: '',
            uname: '',
            startTime: undefined,
            endTime: undefined,
            choosedDates: [],//时间默认值
            idcard: '',
            pageSize: 10,
            selectedRowKeys: [],
            selectedRowInfoKeys:[],
            organId: undefined,
            spinning: false,
            visible:false,
            importSpinState:false,
            file:undefined,
            fileName:''
        }
    }

    componentDidMount() {
        var that = this;
       
        this.props.getPhysicalTypeList().then(msg => {
            if (msg.data.code === 200) {
                that.setState({
                    physicalTypeList: msg.data.data
                })
            }
        })
        this.props.getAllorganization().then(msg => {
            if (msg.data.code === 200) {
                that.setState({
                    organizationList: msg.data.data
                },()=>{
                   that.loadDatas();

                })
            }
        })
    }

    onOrganSelectChange = value => {
        this.setState({
            organId: value
        })
    }
    onphySelectChange = value => {
        this.setState({
            phyId: value
        })
    }
   
  
    reset = () => {
        this.setState({
            choosedDates: [],
            startTime: undefined,
            endTime: undefined,
            barcode: undefined,
            uname: undefined,
            idCard: undefined,
            organId: undefined,
            phyId: undefined,
        }, () => {
            this.loadDatas()
        })
    }
    loadDatas = () => {
        this.setState({
            spinning: true
        })
        var { page, startTime, endTime, barcode, uname, pageSize, idCard, organId, phyId } = this.state;
        var that = this;
        var obj = {
            page: page - 1,
            size: pageSize
        }
        if (startTime !== '' && startTime !== undefined) {
            obj.startTime = startTime;
        }
        if (organId !== '' && organId !== undefined) {
            obj.organizationId = organId;
        }
        else{
            if (JSON.parse(localStorage.getItem('data')).isAdmin !== 1) {
                obj.organizationId = JSON.parse(localStorage.getItem('data')).organizationId;
            }
        }
        if (idCard !== '' && idCard !== undefined) {
            obj.idCard = idCard;
        }
        if (endTime !== '' && endTime !== undefined) {
            obj.endTime = endTime;
        }
        if (phyId !== '' && phyId !== undefined) {
            obj.physicalType = phyId
        }
        if (barcode !== '' && barcode !== undefined) {
            obj.barcode = barcode
        }
        if (uname !== '' && uname !== undefined) {
            obj.username = uname;
        }
        this.props.getOrganizationGuahaoList(obj).then(msg => {
            that.setState({
                spinning: false
            })
            if (msg.data.code === 200) {
                if (msg.data.data.rows.length === 0 && msg.data.data.total !== 0) {
                    that.setState({ page: page - 1 }, () => {
                        that.loadDatas()
                    })
                }
                else {
                    that.setState({
                        dataList: msg.data.data.rows,
                        total: msg.data.data.total
                    })
                }
            }
        })
    }

    unitnameOnChange = e => {
        this.setState({
            unitname: e.target.value
        })
    }

    barcodeOnChange = e => {
        this.setState({
            barcode: e.target.value
        })
    }

    nameOnChange = e => {
        this.setState({
            uname: e.target.value
        })
    }

    datetimeChange = (date, dateString) => {
        // var choseDate =[];
        // choseDate = _.concat(choseDate,moment(dateString[0]+" 00:00:00","YYYY-MM-DD hh:mm:ss"));
        // choseDate = _.concat(choseDate,moment(dateString[1]+" 23:59:59","YYYY-MM-DD hh:mm:ss"));
        this.setState({
            startTime: dateString[0] === "" ? undefined : dateString[0] + " 00:00:00",
            endTime: dateString[1] === "" ? undefined : dateString[1] + " 23:59:59",
            choosedDates: date,
        })
    }

    idCardOnChange = (e) => {
        this.setState({
            idCard: e.target.value
        })
    }

   
  

    changePage = page => {
        var that = this;
        this.setState({ page:page,selectedRowKeys:[],selectedRowInfoKeys:[] }, () => {
            that.loadDatas()
        })
    }

    pageSizeOnChange = (current, size) => {
        var that = this;
        this.setState({ pageSize: size, selectedRowKeys: [] }, () => {
            that.loadDatas();
        })
    }

    importData=()=>{
        this.setState({
            visible:true
        })
    }

    customRequest = (option) => {
        console.log(option.file)
        this.setState({
            fileName: option.file.name,
            file: option.file
        })
    }

    importXueJianCancel=()=>{
        this.setState({
            visible:false,
            fileName:'',
            file:undefined
        })
    }

    importXueJianOk=()=>{
        var {file} = this.state
        var formdata = new FormData();
        formdata.append('file',file);
        var that = this;
        that.setState({importSpinState:true},()=>{
            that.props.readExcel(formdata).then(res=>{
                if(res.data.code===200){
                    message.success("上传成功！");
                    that.setState({
                        fileName:'',
                        file:undefined,
                        visible:false,
                        importSpinState:false
                    })
                }
            })

        })


    }
    
    render() {
        const {   organId, phyId,  dataList, barcode, uname, idCard, spinning, choosedDates,
            visible, physicalTypeList, organizationList } = this.state;
      
        return (
            <div>
                <div className="StatisticsPage-head pageHeader"><h3>挂号人员统计</h3></div>
                <div className="StatisticsPage-box has-select-table">
                    <div className="StatisticsPage-search">
                        <div className="StatisticsPage-btn">
                            <RangePicker dateFormat={dateFormat} placeholder={['开始时间', '结束时间']} className="date"
                                onChange={this.datetimeChange} value={choosedDates} />
                            <Input className="ipt" placeholder="请输入条码" value={barcode}
                                onChange={this.barcodeOnChange} />
                            <Input className="ipt" placeholder="请输入姓名" value={uname}
                                onChange={this.nameOnChange} />
                            <Input className="ipt" placeholder="请输入身份证" value={idCard}
                                onChange={this.idCardOnChange} />
                             <Select style={{ width: 300 }} allowClear={true} placeholder="请选择所属机构" value={organId} onChange={this.onOrganSelectChange} >
                                {organizationList.map(organ => {
                                    return <Option key={organ.organizationId}>{organ.organizationName}</Option>
                                })}
                            </Select>
                            <Select className="ipt" allowClear={true} placeholder="请选择所属类型" value={phyId} onChange={this.onphySelectChange} >
                                {physicalTypeList.map(phy => {
                                    return <Option key={phy.id}>{phy.physicalType}</Option>
                                })}
                            </Select>
                            <Button className="btn" type="primary" onClick={this.loadDatas}>搜索</Button>
                            <Button className="btn" type="primary" onClick={this.reset}>重置</Button>
                            <Button className="btn" type="primary" onClick={this.importData}>导入</Button>
                           
                        </div>

                    </div>
                    <Spin tip="Loading..." spinning={spinning}>
                        <div>{dataList.length > 0 ? <Table rowKey="id"
                           
                            dataSource={dataList} 
                            pagination={{
                                pageSize: this.state.pageSize, showTotal: ((total) => {
                                    return `共 ${total} 条`;
                                }),
                                onChange: this.changePage, total: this.state.total, showSizeChanger: true,
                                onShowSizeChange: this.pageSizeOnChange
                            }} bordered  >
                            <Column width={50} title="当年是否体检" key="isPhysical" align="center" render={
                                (record)=>{
                                    return  record.isPhysical===1?<span>已体检</span>:<span>未体检</span>
                                }
                            }/>
                            <Column width={50} title="是否预约" key="isAppointment" align="center" render={
                                (record)=>{
                                    return record.isAppointment===1?<span>是</span>:<span>否</span>
                                }
                            }/>
                            <Column width={50} title="条码号" dataIndex="barcode" key="barcode" align="center" />
                            <Column width={50} title="姓名" dataIndex="username" key="username" align="center" />
                            <Column width={50} title="照片" key="picture" align="center"
                                render={(record) => {
                                    return (<div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                                        <img alt="" style={{ width: 50, height: 50 }} src={record.pic} key={record.pic} />
                                    </div>)
                                }
                                } />
                            <Column width={80} title="身份证号" dataIndex="idCard" key="idCard" align="center" />
                            <Column width={90} title="体检类型" dataIndex="physicalTypeName" key="physicalTypeName" align="center" />
                            <Column width={50} title="采集点" key="collection" align="collection" render={(text, record) => (
                                <span>
                                    <span>{record.collection&&record.collection }</span>
                                </span>
                            )} />
                            <Column width={50} title="机器套餐" key="orgname" align="orgname" render={(text, record) => (
                                <span>
                                    <span>{record.orgname &&record.orgname}</span>
                                </span>
                            )} />
                            <Column width={50} title="录入方式" key="way" align="center" render={(text, record) => (
                                <span>
                                    <span>{record.way === 1 ? '设备上传' : '手动录入'}</span>
                                </span>
                            )} />

                            <Column width={80} title="采集日期" dataIndex="entryTime" key="entryTime" align="center" />
                           
                        </Table> : <Empty />}</div>
                    </Spin>                   
                </div>
                <Modal width={600} title="导入预约名单" visible={visible} okText="导入" onOk={this.importXueJianOk} onCancel={this.importXueJianCancel}>
                    <Spin tip="导入中..." spinning={this.state.importSpinState}>
                        <div className="form-group">
                            <label className="form-label">文件：</label>
                            <div className="form-ipt">
                                <Upload showUploadList={false} customRequest={this.customRequest}
                                    >
                                    <Tooltip placement="right" >
                                          <Button><Icon />上传文件</Button>

                                    </Tooltip>
                                </Upload>
                                <span>{this.state.fileName}</span>
                            </div>
                        </div>
                    </Spin>
                </Modal>

            </div>
        )
    }
}
export default connect(null, {
    getOrganizationGuahaoList,  getPhysicalTypeList, getAllorganization,readExcel
})(AppointmentList)               