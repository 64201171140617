import React, { Component } from 'react'
import { Modal, Button, Table, Divider, Input, Select, message } from 'antd'
import { connect } from 'react-redux'
import {
  stackSave,
  stackList,
  stackUpdate,
  stackDelete,
  getParentList,
} from '../../../../actions/account'
import _, { values } from 'lodash'
const { Column } = Table
const { confirm } = Modal
const { Option } = Select

class StackManage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dataList: [],
      stackId: undefined,
      checkoutOrganization: undefined,
      sampleType: undefined,
      physicalType: undefined,
      visible: false,
      projectList: [],
      masterIds: [],
      stackName: '',
      stackCode: '',
      barcodeNum: '',
    }
  }
  componentDidMount() {
    this.loadDatas()
  }
  loadDatas = () => {
    const that = this
    var obj = { page: 0, size: 999 }
    this.props.getParentList().then((res) => {
      if (res.data.code === 200) {
        that.setState({ projectList: res.data.data })
      }
    })
    this.props.stackList(obj).then((res) => {
      that.setState({ dataList: res.data.data.rows })
    })
  }

  onmasterIdsChange = (value) => {
    this.setState({ masterIds: value })
  }

  nameChange = (e, mark) => {
    if (mark === 1) {
      this.setState({ stackName: e.target.value })
    } else if (mark === 2) {
      this.setState({ stackCode: e.target.value })
    } else if (mark === 3) {
      this.setState({ barcodeNum: e.target.value })
    }
  }
  showModal = () => {
    this.setState({ visible: true })
  }
  handleOk = () => {
    var {
      stackName,
      stackCode,
      masterIds,
      barcodeNum,
      stackId,
      sampleType,
      checkoutOrganization,
      physicalType,
    } = this.state,
      that = this
    if (stackName === undefined || stackName === null || stackName === '') {
      message.error('组套名称不能为空')
      return
    }
    if (stackCode === undefined || stackCode === null || stackCode === '') {
      message.error('条码前缀不能为空')
      return
    }
    if (sampleType === undefined || sampleType === null || sampleType === '') {
      message.error('样本类型不能为空')
      return
    }
    if (
      checkoutOrganization === undefined ||
      checkoutOrganization === null ||
      checkoutOrganization === ''
    ) {
      message.error('检验机构不能为空')
      return
    }
    if (
      physicalType === undefined ||
      physicalType === null ||
      physicalType === ''
    ) {
      message.error('体检类型不能为空')
      return
    }
    if (barcodeNum === undefined || barcodeNum === null || barcodeNum === '') {
      message.error('当前条码值不能为空')
      return
    }
    if (masterIds.length === 0) {
      message.error('当前条码值不能为空')
      return
    }
    /**
               <label className="form-label">检验机构：</label>
                                    <div className="form-ipt">
             */
    var obj = {
      stackName,
      stackPre: stackCode,
      stackCurrentVal: barcodeNum,
      sampleType,
      checkoutOrganization,
      physicalType,
      projectIds: masterIds.join(','),
    }
    if (stackId === undefined) {
      this.props.stackSave(obj).then((res) => {
        if (res.data.code === 200) {
          message.success('操作成功！')
          that.handleCancel()
          that.loadDatas()
        } else {
          message.error(res.data.message)
        }
      })
    } else {
      obj.id = stackId
      this.props.stackUpdate(obj).then((res) => {
        if (res.data.code === 200) {
          message.success('操作成功！')
          that.handleCancel()
          that.loadDatas()
        } else {
          message.error(res.data.message)
        }
      })
    }
  }
  handleCancel = () => {
    this.setState({
      visible: false,
      stackName: '',
      stackCode: '',
      masterIds: [],
      barcodeNum: '',
      stackId: undefined,
    })
  }

  editAgency = (record) => {
    var projectIds = record.projectIds.split(',')
    var masterIds = []
    projectIds.forEach((projectId) => {
      masterIds = _.concat(masterIds, parseInt(projectId))
    })
    this.setState({
      visible: true,
      stackName: record.stackName,
      stackCode: record.stackPre,
      barcodeNum: record.stackCurrentVal,
      masterIds,
      stackId: record.id,
      physicalType: record.physicalType,
      sampleType: record.sampleType,
      checkoutOrganization: record.checkoutOrganization,
    })
  }
  delAgency = (id) => {
    var that = this
    confirm({
      content: '确认删除？',
      onOk() {
        that.props.stackDelete({ ids: id }).then((msg) => {
          if (msg.data.code === 200) {
            message.success('删除成功！')
            that.loadDatas()
          }
        })
      },
    })
  }
  onCheckOrganChange = (value) => {
    this.setState({ checkoutOrganization: value })
  }
  onsampleTypeChange = (value) => {
    this.setState({ sampleType: value })
  }
  onphysicalTypeChange = (value) => {
    this.setState({ physicalType: value })
  }
  render() {
    var {
      dataList,
      visible,
      stackName,
      stackCode,
      projectList,
      masterIds,
      barcodeNum,
      checkoutOrganization,
      sampleType,
      physicalType,
    } = this.state

    return (
      <div>
        <div className="StatisticsPage-head pageHeader">
          <h3>组套管理</h3>
        </div>
        <div className="StatisticsPage-box">
          <div className="StatisticsPage-search">
            <div className="StatisticsPage-btn">
              <Button className="btn" type="primary" onClick={this.showModal}>
                添加
              </Button>
              <Modal
                title="组套信息"
                visible={visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                okText="保存"
                cancelText="取消"
              >
                <div className="form-group">
                  <label className="form-label">组套名称：</label>
                  <div className="form-ipt">
                    <Input
                      placeholder="请输入组套名称"
                      value={stackName}
                      maxLength="32"
                      onChange={(e) => this.nameChange(e, 1)}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-label">条码前缀：</label>
                  <div className="form-ipt">
                    <Input
                      placeholder="请输入条码前缀"
                      value={stackCode}
                      onChange={(e) => this.nameChange(e, 2)}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-label">样本类型：</label>
                  <div className="form-ipt">
                    <Select
                      style={{ width: '100%' }}
                      allowClear={true}
                      placeholder="请选择样本类型"
                      value={sampleType}
                      onChange={this.onsampleTypeChange}
                    >
                      <Option value={'全血'}>全血</Option>
                      <Option value={'血清'}>血清</Option>
                      <Option value={'尿液'}>尿液</Option>
                      <Option value={'粪便'}>粪便</Option>
                      <Option value={'分泌物'}>分泌物</Option>
                      <Option value={'宫颈脱落细胞'}>宫颈脱落细胞</Option>
                    </Select>
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-label">检验机构：</label>
                  <div className="form-ipt">
                    <Select
                      style={{ width: '100%' }}
                      allowClear={true}
                      placeholder="请选择检验机构"
                      value={checkoutOrganization}
                      onChange={this.onCheckOrganChange}
                    >
                      <Option value={'华晟'}>华晟</Option>
                      <Option value={'兰卫'}>兰卫</Option>
                      <Option value={'艾迪康'}>艾迪康</Option>
                    </Select>
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-label">所属系统类型：</label>
                  <div className="form-ipt">
                    <Select
                      style={{ width: '100%' }}
                      allowClear={true}
                      placeholder="请选择体检类型"
                      value={physicalType}
                      onChange={this.onphysicalTypeChange}
                    >
                      <Option value={8}>公卫</Option>
                      <Option value={14}>健康体检</Option>
                      <Option value={10}>健康证体检</Option>
                    </Select>
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-label">当前条码值：</label>
                  <div className="form-ipt">
                    <Input
                      placeholder="请输入当前条码值"
                      value={barcodeNum}
                      onChange={(e) => this.nameChange(e, 3)}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-label"> 项目代码：</label>
                  <div className="form-ipt">
                    <Select
                      mode="multiple"
                      value={masterIds}
                      style={{ width: '100%' }}
                      placeholder="选择项目列表"
                      //    optionFilterProp="children"
                      onChange={this.onmasterIdsChange}
                    // filterOption={(input, option) =>
                    //     option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    // }
                    >
                      {projectList.map((project) => {
                        return (
                          <Option
                            value={project.id}
                            key={'project' + project.id}
                          >
                            {project.projectName}
                          </Option>
                        )
                      })}
                    </Select>
                  </div>
                </div>
              </Modal>
            </div>
          </div>

          <div>
            <Table rowKey="id" size="middle" dataSource={dataList} bordered>
              <Column
                title="组套名称"
                dataIndex="stackName"
                key="stackName"
                align="center"
              />
              <Column
                title="条码前缀"
                dataIndex="stackPre"
                key="stackPre"
                align="center"
              />
              <Column
                title="条码当前值"
                dataIndex="stackCurrentVal"
                key="stackCurrentVal"
                align="center"
              />
              {/* <Column
                title="项目名称"
                dataIndex="projectNames"
                key="projectNames"
                align="center"
              /> */}
              <Column
                title="样本类型"
                dataIndex="sampleType"
                key="sampleType"
                align="center"
              />
              <Column
                title="检验机构"
                dataIndex="checkoutOrganization"
                key="checkoutOrganization"
                align="center"
              />
              <Column
                title="项目列表"
                dataIndex="projectNames"
                key="projectNames"
                align="center"
              />
              <Column
                title="所属体检平台"
                dataIndex="physicalType"
                key="physicalType"
                align="center"
                render={(text, record) => (
                  <span>
                    {text === 8 && '公卫'}
                    {text === 14 && '健康体检'}
                    {text === 10 && '健康证体检'}
                  </span>
                )}
              />

              <Column
                title="操作"
                align="center"
                key="action"
                render={(text, record) => (
                  <span>
                    <span
                      style={{ cursor: 'pointer' }}
                      onClick={() => this.editAgency(record)}
                    >
                      编辑
                    </span>
                    <Divider type="vertical" />
                    <span
                      style={{ cursor: 'pointer' }}
                      onClick={() => this.delAgency(record.id)}
                    >
                      删除
                    </span>
                  </span>
                )}
              />
            </Table>
          </div>
        </div>
      </div>
    )
  }
}
export default connect(null, {
  stackSave,
  stackList,
  stackUpdate,
  stackDelete,
  getParentList,
})(StackManage)
