import React, { Component } from 'react'
import { Button, Input, Select, message, Checkbox, Upload, Icon, Drawer, DatePicker, Descriptions } from 'antd';
// import { ImageUploader } from 'antd-mobile';
import { connect } from 'react-redux'
import { appointmentPageDetail2, appointmentSave, uploadFile, appointmentRegisterSave } from '../../actions/account'
import './yuyue.css'
import _, { random } from 'lodash'
import { PlusOutlined, UploadOutlined } from '@ant-design/icons';
import moment from 'moment';
import { TrackChangesOutlined, DeleteOutlined } from '@material-ui/icons';
const { Option } = Select;
const { TextArea } = Input;
class main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            educationList: [
                {
                    value: "10",
                    text: '研究生教育'
                }, {
                    value: "20",
                    text: '大学本科教育'
                }, {
                    value: "30",
                    text: '大学专科教育'
                }, {
                    value: "40",
                    text: '中等职业教育'
                }, {
                    value: "60",
                    text: '普通高级中学教育'
                }, {
                    value: "70",
                    text: '初级中学教育'
                }, {
                    value: "80",
                    text: '小学教育'
                }, {
                    value: "90",
                    text: '其他'
                }
            ],
            maritalList: [{
                value: "1",
                text: '未婚'
            },
            {
                value: "2",
                text: '已婚'
            },
            {
                value: "3",
                text: '丧偶'
            }, {
                value: "4",
                text: '离婚'
            }, {
                value: "5",
                text: '未说明的婚姻状况'
            },
        ],
        
            marital:undefined,
            domicileType:"",
            nation: "",
            hosType: null,
            bmi: "",
            physicalSite: "请先选择日期",
            isRequresting: false,
            loading: false, loading1: false, loading2: false,
            fileList: [],
            fileList2: [],
            org: null,
            phy: null,
            selectedDate: moment().format("YYYY-MM-DD"),
            schoolName: "",
            classGrade: "",
            sex: null,
            weekName: "",
            weeks: [[], [], [], [], [], [], []],
            btnState: false,
            time: "",
            Datas: [],
            syme: null,
            username: "", idCard: "", workUnit: "", phone: "", town: "", county: "", village: "", team: "", height: "", weight: "",waist:"", occupation: "", education: "",
            pageTitle: "",
            dateList: [],
            timeList: [],
            date: "",
            detailId: "",
            erweimaOrg: undefined,
            erweimaPhy: undefined,
            birday: '--',
            isCheckedUnit: false,
            isCheckedCard: false,
            registrationData: {
                appointmentUserId: null,
                blood: null,
                diastole: "",
                shrink: "",
                exerciseFrequency: null,
                exerciseTime: null,
                duration: null,
                exerciseType: "",
                dietaryHabit: "1",
                smoke: 1,
                smokeDayAverage: null,
                startSmokeAge: null,
                endSmokeAge: null,
                drink: 1,
                drinkDayAverage: null,
                isStopDrink: null,
                stopDrinkAge: null,
                startDrinkAge: null,
                isDrunk: null,
                wineType: "",
                otherWine: "",
                drug: [],


                disease: [{ state: 1, time: "", val: "" }],
                surgeryHistory: 1,
                surgeryInfo: [],
                traumaHistory: 1,
                traumaInfo: [],
                allergy: "1",
                allergyOther: "",
                father: "1", fatherOther: "",
                mother: "1", motherOther: "",
                brother: "1", brotherOther: "",
                children: "1", childrenOther: ""
            },
            diseaseDetailVisible: false,
            diseaseDetail: { state: null, time: "", val: "" },
            shoushuDetailVisible: false,
            shoushuDetail: { time: "", val: "" },
            waishangDetailVisible: false,
            waishangDetail: { time: "", val: "" },
            DrugDetailVisible: false,
            drugDetail: {
                drugName: "",
                usage: "",
                dosage: "",
                useTime: "",
                compliance: null,
            },
        }
    }
    componentDidMount() {
        document.title = "健康证体检预约";
        console.log(this.props.location.search.substring(1))
        var params = this.props.location.search.substring(1).split("&")
        var val = []
        var query = {}
        console.log(params)
        for (var i = 0; i < params.length; i++) {
            val = params[i].split("=");
            if (val[0] === 'erweimaName') { query[val[0]] = decodeURI(val[1]) }
            else query[val[0]] = val[1]

        }
        console.log(query)
        this.setState({ org: query.erweimaOrg, phy: query.erweimaPhy }, () => {
            this.loadDatas(query.erweimaOrg, query.erweimaPhy, 1)
        })
        var locationData = query
        this.setState({
            pageTitle: locationData.erweimaName,
            erweimaOrg: query.erweimaOrg,
            erweimaPhy: query.erweimaPhy,
        })

    }
    onyyDateChange(date, dateString) {
        console.log(date, dateString);
    }
    loadDatas = (org, phy, mark) => {
        var that = this;
        var obj = { organizationId: org, typeId: Number(phy), currentDate: moment().format("YYYY-MM-DD HH:mm:ss"), selectedDate: this.state.selectedDate };
        message.loading("加载数据中....", 0)
        this.setState({ weeks: [[], [], [], [], [], [], []], dateList: [], timeList: [] }, () => {
            var { dateList, weeks } = that.state;
            that.props.appointmentPageDetail2(obj).then(msg => {
                message.destroy();
                if (msg.data.code === 200) {
                    if (!msg.data.data || msg.data.data.length === 0) {
                        message.warn('最近没有预约事务！')
                        that.setState({ btnState: true })
                        return;
                    }
                    var festival = ["01-01", "05-01", "10-01"];//法定节假日
                    var objs = msg.data.data;
                    var countDay = 0;
                    objs.forEach(obj => {
                        obj.appointmentDateDetailDTOS.forEach(item => {
                            weeks[item.week - 1].push(item)
                        })
                        var startDate = moment(obj.startTime);
                        var endDate = moment(obj.endTime);

                        var d = moment();
                        if (d.unix() > startDate.unix()) {
                            d = moment();
                        }
                        else {
                            d = startDate;
                        }
                        for (
                            d; d.unix() <= endDate.unix() && countDay < 7;
                            d = d.add(1, "days")
                        ) {
                            if (festival.indexOf(d.format("MM-DD")) === -1) {
                                //从今天开始往后的   日期范围内的    
                                var temp = d.format("YYYY-MM-DD")
                                var now = moment().format("YYYY-MM-DD")
                                var day = parseInt(d.day());
                                //  ;
                                console.log(temp)
                                if ((moment(now).isBefore(temp) || temp === now) &&
                                    ((day !== 0) ||
                                        // ((day !== 0 && weeks[day - 1].length > 0) ||
                                        (day === 0 && weeks[6].length > 0))) {
                                    if (day !== 0) dateList.push({ date: d.format("YYYY-MM-DD"), week: day, physicalSite: obj.physicalSite })
                                    else dateList.push({ date: d.format("YYYY-MM-DD"), week: 7, physicalSite: obj.physicalSite })
                                }
                                countDay++;

                            }
                        }
                    })

                    that.setState({ dateList, weeks, btnState: false }, () => {
                        if (mark === 2) {
                            var temp = "", weekName = "", physicalSite = "";
                            console.log(that.state.dateList, that.state.weeks)
                            that.state.dateList.forEach(item => {
                                if (item.date === that.state.selectedDate) {
                                    temp = item.week;
                                    if (item.week === 1) weekName = "周一";
                                    if (item.week === 2) weekName = "周二";
                                    if (item.week === 3) weekName = "周三";
                                    if (item.week === 4) weekName = "周四";
                                    if (item.week === 5) weekName = "周五";
                                    if (item.week === 6) weekName = "周六";
                                    if (item.week === 7) weekName = "周日";
                                    physicalSite = item.physicalSite;
                                    console.log('是', weekName)
                                    console.log('地点', physicalSite)
                                }
                            })
                            that.setState({ timeList: that.state.weeks[temp - 1], weekName, physicalSite })
                        }
                    })

                }
            })
        })

    }
    oneducationChange = (value) => {
        this.setState({ education: value })
    }
    ondomicileTypeChange=(value)=>{
        this.setState({domicileType:value})
    }
    onmaritalChange=(value)=>{
        this.setState({marital:value})
    }
    onDateChange = (value) => {
        console.log(value)
        this.setState({ date: value, timeList: [], detailId: "", time: "", syme: null, selectedDate: value }, () => {
            this.loadDatas(this.state.org, this.state.phy, 2)
        })
    }
    ondetailIdChange = (value) => {
        var { timeList } = this.state;
        this.setState({ detailId: value })
        timeList.forEach(item => {
            if (item.id === Number(value)) {
                this.setState({
                    syme: Number(item.residueNumber),
                    time: `${item.startTime.substring(11, 19)}-${item.endTime.substring(11, 19)}`
                })
                return;
            }
        })
    }
    submit = () => {
        var that = this;
        var { registrationData, isCheckedCard, isCheckedUnit, date, time, detailId, username, idCard, phone, birday, workUnit, bmi, hosType,
            town, county, village, team, height, weight, fileList, fileList2, residentialAddress, nation,education,domicileType
        } = this.state
        console.log(date, time)
        if (!isCheckedCard || !isCheckedUnit) {
            message.error("请勾选体检时携带材料！")
            return
        }
        if (workUnit === undefined || workUnit === "" || workUnit === null) {
            message.warn('单位未填写完整！')
            return;
        }
        if (detailId.length === 0 || date.length === 0 || time.length === 0 || username.length === 0 ||
            idCard.length === 0 || phone.length === 0) {
            message.warn('必填项未填写完整！')
            return;
        }
        var obj = {
            appointmentDetailId: Number(detailId),
            username,
            phone,
            appointmentDate: `${date} ${time}`,
            birthday: birday
        }
        if (idCard !== "") {
            obj.idCard = idCard.toUpperCase();
        }
        if (hosType) obj.hosType = hosType;
        if (education) obj.education = education;
        if(domicileType) obj.domicileType=domicileType;
        if (nation) obj.nation = nation;
        if (town.length > 0) obj.town = town;
        if (workUnit.length > 0) obj.workUnit = workUnit;
        if (county.length > 0) obj.county = county;
        if (village.length > 0) obj.village = village;
        if (team.length > 0) obj.team = team;
        if (height.length > 0) obj.height = height;
        if (weight.length > 0) obj.weight = weight;
        if (residentialAddress.length > 0) obj.residentialAddress = residentialAddress;
        obj.bmi = bmi;
        if (fileList.length === 0 || fileList2.length === 0) {
            message.error("请上传单位证件！")
            return;
        }
        if (fileList.length > 0) {
            console.log(fileList);
            var temp = [];
            fileList.forEach(item => { temp.push(item.literationURL) })
            obj.materials = temp.join(',');
        }
        if (fileList2.length > 0) {
            var temp = [];
            fileList2.forEach(item => { temp.push(item.literationURL) })
            obj.idCardImg = temp.join(',');
        }
        console.log(obj)
        message.loading('提交预约...', 0)
        this.props.appointmentSave(obj).then(msg => {
            message.destroy();
            if (msg.data.code === 200) {
                message.info('预约成功！')
                registrationData.appointmentUserId = msg.data.appointmentUserId;
                this.setState({ registrationData }, () => {
                    that.saveRegistrationData(idCard);
                })
                // that.props.history.push('/appointQueryList?idCard=' + idCard)
            }
            else {
                message.warn(msg.data.message)
            }
        })
    }
    saveRegistrationData = (idCard) => {
        message.loading('正在保存登记表...', 0)
        var { registrationData } = this.state;
        var params = JSON.parse(JSON.stringify(registrationData));
        params.surgeryInfo = params.surgeryInfo.length > 0 ? JSON.stringify(params.surgeryInfo) : "";
        params.traumaInfo = params.traumaInfo.length > 0 ? JSON.stringify(params.traumaInfo) : "";
        params.disease = params.disease.length > 0 ? JSON.stringify(params.disease) : "";
        params.drug = params.drug.length > 0 ? JSON.stringify(params.drug) : "";
        params.waist = this.state.waist;
        params.marital = this.state.marital
        console.log(params);
        // return;
        var that = this;
        this.props.appointmentRegisterSave(params).then(msg => {
            message.destroy();
            if (msg.data.code === 200) {
                message.info('登记成功！')
                that.props.history.push('/appointQueryList?idCard=' + idCard)
            }
            else {
                message.warn(msg.data.message)
            }
        })
    }
    usernameChange = (e) => {
        this.setState({
            username: e.target.value
        })
    }
    idCardChange = (e) => {
        var reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;

        if (e.target.value.length === 18) {
            if (reg.test(e.target.value) === false)
                message.warn("身份证号格式错误");
            else {
                if (parseInt(e.target.value.slice(-2, -1)) % 2 === 1) {
                    this.setState({
                        sex: 1
                    })
                }
                else {
                    this.setState({
                        sex: 2
                    })
                }
                var year = e.target.value.substring(6, 10);
                var month = e.target.value.substring(10, 12);
                var day = e.target.value.substring(12, 14);
                this.setState({ birday: year + "-" + month + "-" + day })
            }
        }
        this.setState({
            idCard: e.target.value
        })
    }
    sexOnChange = value => {
        this.setState({ sex: Number(value) })
    }
    workUnitChange = (e) => {
        this.setState({
            workUnit: e.target.value
        })
    }
    phoneChange = (e) => {
        this.setState({
            phone: e.target.value
        })
    }
    townChange = (e) => {
        this.setState({
            town: e.target.value
        })
    }
    countyChange = (e) => {
        this.setState({
            county: e.target.value
        })
    }
    villageChange = (e) => {
        this.setState({
            village: e.target.value
        })
    }
    teamChange = (e) => {
        this.setState({
            team: e.target.value
        })
    }
    residentialAddressChange = (e) => {
        this.setState({ residentialAddress: e.target.value.trim() })
    }
    heightChange = (e) => {
        this.setState({ height: e.target.value.trim() }, () => {
            this.autoCompleteBMI();
        });
    }
    weightChange = (e) => {
        this.setState({ weight: e.target.value.trim() }, () => {
            this.autoCompleteBMI();
        });
    }
    waistChange= (e) => {
        this.setState({ waist: e.target.value.trim() });
    }
    autoCompleteBMI = () => {
        var { height, weight } = this.state;
        if (height.length > 0 && weight.length > 0) {
            this.setState({
                bmi: (weight / ((height / 100) * (height / 100))).toFixed(2)
            })
        }
        else {
            this.setState({
                bmi: ""
            })
        }
    }
    schoolNameChange = (e) => {
        this.setState({
            schoolName: e.target.value
        })
    }
    classGradeChange = (e) => {
        this.setState({
            classGrade: e.target.value
        })
    }
    birdayOnChange = (e, mark) => {
        var { birday } = this.state;
        var strs = birday.split("-");
        var year = "";
        if (strs.length >= 1) {
            year = strs[0];
        }
        var month = "";
        if (strs.length >= 2) {
            month = strs[1];
        }
        var day = ""
        if (strs.length >= 3) {
            day = strs[2];
        }
        switch (mark) {
            case "y":
                year = e.target.value;
                break;
            case "m":
                month = e.target.value;
                break;
            case "d":
                day = e.target.value;
                break;
            default:
                break;
        }
        birday = year + "-" + month + "-" + day;
        this.setState({ birday });
    }
    isCheckedUnitOnChange = e => {
        this.setState({
            isCheckedUnit: e.target.checked
        })
    }
    isCheckedCardOnChange = e => {
        this.setState({
            isCheckedCard: e.target.checked
        })
    }
    onChange = (e) => {
        e.preventDefault();
        const file = e.target.files[0];
        const formData = new FormData();
        // 这里的 image 是字段，根据具体需求更改
        formData.append('image', file);
    };
    customRequestIdCard = (option) => {
        this.imagePress(option.file, 2 * 1024 * 1024, 2)
    }
    customRequestContinueIdCard = (option) => {
        var { fileList2 } = this.state;
        var that = this;

        let reader = new FileReader()
        reader.readAsDataURL(option)
        reader.onload = function () {
            var uid = fileList2.length;
            fileList2.push({ url: reader.result, thumbUrl: reader.result, name: '', uid: uid, status: 'uploading' });
            that.setState({ fileList2 }, () => {
                that.uploadFileIdCard(option, uid);
            })
        }
    }
    /**
     * 上传图片时，压缩图片
     * @param {*} file 目标文件
     * @param {*} size 压缩后目标大小
     */
    imagePress = (file, size, mark) => {
        var that = this;
        // const imagePress2 = this.imagePress;
        const isLt2M = file.size < size;
        if (isLt2M) {
            console.log('没有压缩')
            if (mark === 1) {
                that.customRequestContinueMaterials(file);
            } else {
                that.customRequestContinueIdCard(file);
            }

        }
        else {
            console.log('压缩了')
            let rate = 0.9;
            let reader = new FileReader();
            reader.readAsDataURL(file);
            let img = new Image();
            reader.onload = function (e) {
                img.src = e.target.result;
            };
            img.onload = function () {
                let canvas = document.createElement("canvas");
                let context = canvas.getContext("2d");
                // 文件大小KB
                const fileSizeKB = Math.floor(file.size / 1024);
                // console.log('file size：', fileSizeKB, 'kb');
                // 配置rate和maxSize的关系
                if (fileSizeKB * rate > 3027) {
                    rate = Math.floor((3027 / fileSizeKB) * 10) / 30;
                }
                // 缩放比例，默认0.5
                let targetW = (canvas.width = this.width * rate);
                let targetH = (canvas.height = this.height * rate);
                context.clearRect(0, 0, targetW, targetH);
                context.drawImage(img, 0, 0, targetW, targetH);
                canvas.toBlob((blob) => {
                    const newImage = new File([blob], file.name, {
                        type: file.type,
                    });
                    // console.log(newImage.size / 1024, "kb");
                    if (newImage.size < size) {
                        // 图片上传接口
                        if (mark === 1) {
                            that.customRequestContinueMaterials(newImage);
                        } else {
                            that.customRequestContinueIdCard(newImage);
                        }
                    } else {
                        that.imagePress(newImage, 1024 * 1024, mark)
                    }


                });
            };
        }
    }
    uploadFileIdCard = (file, uid) => {
        var { fileList2 } = this.state;
        var that = this;
        const formData = new FormData();
        // 这里的 image 是字段，根据具体需求更改
        formData.append('file', file);
        that.props.uploadFile(formData).then(msg => {
            if (msg.data.code === 200) {
                var file = _.find(fileList2, ['uid', uid]);
                file.status = 'success';
                file.name = '待提交...';
                file.url = msg.data.complete;
                file.literationURL = msg.data.data;
                that.setState({ fileList2 })
            }
            else {
                message.error(msg.data.message);
                var file = _.find(fileList2, ['uid', uid]);
                file.status = 'error';
                file.name = '上传失败';
                that.setState({ fileList2 })
            }
        })
    }
    handleOnRemoveIdCard = (file) => {
        var { fileList2 } = this.state;
        fileList2 = _.filter(fileList2, function (o) { return file.uid !== o.uid })
        this.setState({ fileList2 }, () => { console.log(fileList2) })
    }
    customRequestMaterials = (option) => {
        this.imagePress(option.file, 2 * 1024 * 1024, 1)

    }
    customRequestContinueMaterials = (file) => {
        //console.log(option.file) 
        var { fileList } = this.state;
        var that = this;
        let reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = function () {
            var uid = fileList.length;
            fileList.push({ url: reader.result, thumbUrl: reader.result, name: '', uid: uid, status: 'uploading' });
            that.setState({ fileList }, () => {
                that.uploadFile(file, uid);
            })
        }
    }
    uploadFile = (file, uid) => {
        var { fileList } = this.state;
        var that = this;
        const formData = new FormData();
        // 这里的 image 是字段，根据具体需求更改
        formData.append('file', file);
        that.props.uploadFile(formData).then(msg => {
            if (msg.data.code === 200) {
                var file = _.find(fileList, ['uid', uid]);
                file.status = 'success';
                file.name = '待提交...';
                file.url = msg.data.complete;
                file.literationURL = msg.data.data;
                that.setState({ fileList })
            }
            else {
                message.error(msg.data.message);
                var file = _.find(fileList, ['uid', uid]);
                file.status = 'error';
                file.name = '上传失败';
                that.setState({ fileList })
            }
        })
    }
    handleOnRemoveMaterials = (file) => {
        var { fileList } = this.state;
        fileList = _.filter(fileList, function (o) { return file.uid !== o.uid })
        this.setState({ fileList }, () => { console.log(fileList) })
    }
    onPreview = () => { return; }
    imageUpLoadOnChange = (info, mark) => {
        if (mark === 1) {
            if (info.file.status === 'uploading') {
                this.setState({ loading1: true });
            }
            else {
                this.setState({ loading1: false });
            }
        } else if (mark === 2) {
            if (info.file.status === 'uploading') {
                this.setState({ loading2: true });
            }
            else {
                this.setState({ loading2: false });
            }
        }

    }
    hosTypeChange = (e) => {
        this.setState({ hosType: e })
    }
    selectOnlyOneChange = (e, keyInRegistrationData) => {
        var { registrationData } = this.state;
        registrationData[keyInRegistrationData] = Number(e);
        switch (keyInRegistrationData) {
            case 'exerciseFrequency': if (Number(e) === 4) {
                registrationData.exerciseTime = null;
                registrationData.duration = null;
                registrationData.exerciseType = "";
            }
                break;
            case 'smoke':
                if (Number(e) === 1) {
                    registrationData.smokeDayAverage = null;
                    registrationData.startSmokeAge = null;
                    registrationData.endSmokeAge = null;
                }
                if (Number(e) === 3) {
                    registrationData.endSmokeAge = null;
                }
                break;
            case 'drink':
                if (Number(e) === 1) {
                    registrationData.drinkDayAverage = null;
                    registrationData.isStopDrink = null;
                    registrationData.stopDrinkAge = null;
                    registrationData.startDrinkAge = null;
                    registrationData.isDrunk = null;
                    registrationData.wineType = "";
                    registrationData.otherWine = "";
                }
                break;
        }
        this.setState({ registrationData })
    }
    RegisterationDataInputChange = (e, keyInRegistrationData, type = "string") => {
        var { registrationData } = this.state;
        registrationData[keyInRegistrationData] = type === "string" ? e.target.value.trim() : Number(e.target.value.trim());
        console.log(registrationData)
        this.setState({ registrationData })
    }
    onRegisterationData_CheckBoxChange(e, keyInRegistrationData, noneKeyInOptions = null, otherDesKeyInOptions = null, otherDesKey = null) {
        // keyInRegistrationData   在大json中的key, 
        // noneKeyInOptions  选项“无”对应的value ,
        // otherDesKeyInOptions 选项“其他”对应的value,
        // otherDesKey   在大json中的其他补充key, 
        console.log(e)
        var { registrationData } = this.state;
        if (noneKeyInOptions !== null) {//含有 无  选项
            if (Number(e[e.length - 1]) === Number(noneKeyInOptions)) registrationData[keyInRegistrationData] = noneKeyInOptions;
            else if (Number(e[0]) === Number(noneKeyInOptions) && e.length === 2) registrationData[keyInRegistrationData] = String(e[1]);
            else registrationData[keyInRegistrationData] = e.join(',');
        }
        else registrationData[keyInRegistrationData] = e.join(',');

        if (otherDesKeyInOptions !== null && !registrationData[keyInRegistrationData].split(',').includes(otherDesKeyInOptions)) registrationData[otherDesKey] = "";
        this.setState({ registrationData })

    }
    // 疾病史
    showDiseaseDetail = () => {
        this.setState({ diseaseDetailVisible: true })
    }
    closeDiseaseDetail = () => {
        this.setState({ diseaseDetail: { state: null, time: "", val: "" }, diseaseDetailVisible: false })
    }
    saveDiseaseDetail = () => {
        var { diseaseDetail, registrationData } = this.state;
        console.log(diseaseDetail)
        if (!diseaseDetail.state || diseaseDetail.state.length === 0 ||
            !diseaseDetail.time || diseaseDetail.time.length === 0) {
            message.warn("请填写完整！");
            return;
        }
        registrationData.disease = registrationData.disease.filter(item => item.state !== 1)
        registrationData.disease = registrationData.disease.filter(item => item.state !== diseaseDetail.state)
        registrationData.disease.push(diseaseDetail);
        this.setState({
            diseaseDetailVisible: false,
            diseaseDetail: { state: null, time: "", val: "" }, registrationData
        })
    }
    delDiseaseDetail = (state) => {
        var { registrationData } = this.state;
        registrationData.disease = registrationData.disease.filter(item => item.state !== state)
        if (registrationData.disease.length === 0) {
            registrationData.disease = [{ state: 1, time: "", val: "" }]
        }
        this.setState({ registrationData })
    }
    diseaseDetailStateChange = (e) => {
        var { diseaseDetail } = this.state;
        diseaseDetail.state = Number(e);
        diseaseDetail.val = "";
        this.setState({ diseaseDetail })
    }
    diseaseDetailValChange = (e) => {
        var { diseaseDetail } = this.state;
        diseaseDetail.val = e.target.value.trim();
        this.setState({ diseaseDetail })
    }
    diseaseDetailTimeChange = (date, dateString) => {
        console.log(date, dateString)
        var { diseaseDetail } = this.state;
        diseaseDetail.time = dateString;
        this.setState({ diseaseDetail })
    }
    // 手术史
    delShoushuDetail = (val) => {
        var { registrationData } = this.state;
        registrationData.surgeryInfo = registrationData.surgeryInfo.filter(item => item.val !== val)
        if (registrationData.surgeryInfo.length === 0) {
            registrationData.surgeryHistory = 1;
        }
        this.setState({ registrationData })
    }
    showShoushuDetail = () => {
        this.setState({ shoushuDetailVisible: true })
    }
    shoushuDetailTimeChange = (date, dateString) => {
        console.log(date, dateString)
        var { shoushuDetail } = this.state;
        shoushuDetail.time = dateString;
        this.setState({ shoushuDetail })
    }
    shoushuDetailValChange = (e) => {
        var { shoushuDetail } = this.state;
        shoushuDetail.val = e.target.value.trim();
        this.setState({ shoushuDetail })
    }
    closeshoushuDetail = () => {
        this.setState({ shoushuDetail: { time: "", val: "" }, shoushuDetailVisible: false })
    }
    saveshoushuDetail = () => {
        var { shoushuDetail, registrationData } = this.state;
        console.log(shoushuDetail)
        if (!shoushuDetail.val || shoushuDetail.val.length === 0 ||
            !shoushuDetail.time || shoushuDetail.time.length === 0) {
            message.warn("请填写完整！");
            return;
        }
        registrationData.surgeryInfo.push(shoushuDetail);
        registrationData.surgeryHistory = 2;
        this.setState({
            shoushuDetailVisible: false,
            shoushuDetail: { time: "", val: "" }, registrationData
        })
    }
    // 外伤史
    waishangDetailValChange = (e) => {
        var { waishangDetail } = this.state;
        waishangDetail.val = e.target.value.trim();
        this.setState({ waishangDetail })
    }
    waishangDetailTimeChange = (date, dateString) => {
        console.log(date, dateString)
        var { waishangDetail } = this.state;
        waishangDetail.time = dateString;
        this.setState({ waishangDetail })
    }
    showWaishangDetail = () => {
        this.setState({ waishangDetailVisible: true })
    }
    delWaishangDetail = (val) => {
        var { registrationData } = this.state;
        registrationData.traumaInfo = registrationData.traumaInfo.filter(item => item.val !== val)
        if (registrationData.traumaInfo.length === 0) {
            registrationData.traumaHistory = 1;
        }
        this.setState({ registrationData })
    }
    closeWaishangDetail = () => {
        this.setState({ waishangDetail: { time: "", val: "" }, waishangDetailVisible: false })
    }
    saveWaishangDetail = () => {
        var { waishangDetail, registrationData } = this.state;
        console.log(waishangDetail)
        if (!waishangDetail.val || waishangDetail.val.length === 0 ||
            !waishangDetail.time || waishangDetail.time.length === 0) {
            message.warn("请填写完整！");
            return;
        }
        registrationData.traumaInfo.push(waishangDetail);
        registrationData.traumaHistory = 2;
        this.setState({
            waishangDetailVisible: false,
            waishangDetail: { time: "", val: "" }, registrationData
        })
    }
    //用药情况
    drugDetailComplianceChange = (e) => {
        var { drugDetail } = this.state;
        drugDetail.compliance = Number(e);
        this.setState({ drugDetail })
    }
    drugDetailDrugNameChange = (e) => {
        var { drugDetail } = this.state;
        drugDetail.drugName = e.target.value.trim();
        this.setState({ drugDetail })
    }
    drugDetailUsageChange = (e) => {
        var { drugDetail } = this.state;
        drugDetail.usage = e.target.value.trim();
        this.setState({ drugDetail })
    }
    drugDetailDosageChange = (e) => {
        var { drugDetail } = this.state;
        drugDetail.dosage = e.target.value.trim();
        this.setState({ drugDetail })
    }
    drugDetailUseTimeChange = (e) => {
        var { drugDetail } = this.state;
        drugDetail.useTime = e.target.value.trim();
        this.setState({ drugDetail })
    }
    showDrugDetail = () => {
        this.setState({ DrugDetailVisible: true })
    }
    delDrugDetail = (drugName) => {
        var { registrationData } = this.state;
        registrationData.drug = registrationData.drug.filter(item => item.drugName !== drugName)
        this.setState({ registrationData })
    }
    closeDrugDetail = () => {
        this.setState({ drugDetail: { drugName: "", usage: "", dosage: "", useTime: "", compliance: null }, DrugDetailVisible: false })
    }
    saveDrugDetail = () => {
        var { drugDetail, registrationData } = this.state;
        console.log(drugDetail)
        if (!drugDetail.drugName || drugDetail.drugName.length === 0 ||
            !drugDetail.compliance) {
            message.warn("请填写完整！");
            return;
        }
        registrationData.drug.push(drugDetail);
        this.setState({
            DrugDetailVisible: false,
            drugDetail: { drugName: "", usage: "", dosage: "", useTime: "", compliance: null }, registrationData
        })
    }
    nationChange = (value) => { this.setState({ nation: value }) }

    render() {
        const { height, weight, waist,drugDetail, waishangDetail, shoushuDetail, diseaseDetail, registrationData, isCheckedCard, isCheckedUnit, weekName, syme, timeList, detailId, date, dateList, workUnit, loading1, loading2,
            birday, pageTitle, username, idCard, phone, fileList, fileList2 } = this.state;
        var strs = birday.split("-");
        var year = "";
        if (strs.length >= 1) {
            year = strs[0];
        }
        var month = "";
        if (strs.length >= 2) {
            month = strs[1];
        }
        var day = ""
        if (strs.length >= 3) {
            day = strs[2];
        }
        return (
            <div>
                <div className="StatisticsPage-head1 pageHeader1"><h4>{pageTitle}预约</h4></div>
                <div className="StatisticsPage-box1 has-select-table">
                    <div className="StatisticsPage-search">
                        <div className="form-group">
                            <label className="form-label1  require">日期：</label>
                            <div className="form-ipt">
                                <Select className="ipt1" allowClear={false} value={date} onChange={this.onDateChange} >
                                    {dateList.map((item, index) => {
                                        return <Option key={item.date}>{item.date}</Option>
                                    })}
                                </Select>
                                {weekName.length > 0 && <span>({weekName})</span>}
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="form-label1  require">时间点：</label>
                            <div className="form-ipt">
                                <Select className="ipt1" allowClear={false} value={detailId} onChange={this.ondetailIdChange} >
                                    {timeList && timeList.map((date, index) => {
                                        return <Option key={date.id}>{date.startTime.substring(11, 16)}~{date.endTime.substring(11, 16)}</Option>
                                    })}
                                </Select>
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="form-label1">体检地点：</label>
                            <div className="form-ipt">
                                <span className={date.length > 0 ? '' : 'tip_color'}>{this.state.physicalSite}</span>
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="form-label1">剩余名额：</label>
                            <div className="form-ipt">
                                {syme == null || syme > 0 ? <span style={{ color: 'green' }}>{syme}</span> : <span style={{ color: 'red' }}>暂无名额</span>}
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="form-label1 require">携带材料：</label>
                            <div className="form-ipt">
                                <Checkbox checked={isCheckedUnit} onChange={this.isCheckedUnitOnChange}>单位证件的复印件{this.state.erweimaOrg === "62f4a124a69c4c3f8f6a822ff51255b7" && "(归属地为浦口区)"}
                                    {this.state.erweimaOrg === "706a25384d7843a7bc5233f65d14ad51" && "(归属地江北新区)"}</Checkbox>
                                <Checkbox checked={isCheckedCard} onChange={this.isCheckedCardOnChange}>身份证原件及复印件</Checkbox>
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="form-label1 require">上传材料：</label>
                            <div className="form-ipt upload_appoint_file">
                                <Upload accept="image/png, image/jpeg" customRequest={this.customRequestMaterials}
                                    listType="picture" onRemove={this.handleOnRemoveMaterials}
                                    onPreview={this.onPreview} onChange={(info) => this.imageUpLoadOnChange(info, 1)}
                                    fileList={fileList}>
                                    {loading1 ? <Icon type='loading' /> : <Button icon={<UploadOutlined />}>上传</Button>}
                                </Upload>
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="form-label1 require">上传身份证：</label>
                            <div className="form-ipt upload_appoint_file">
                                <Upload accept="image/png, image/jpeg" customRequest={this.customRequestIdCard}
                                    listType="picture" onRemove={this.handleOnRemoveIdCard}
                                    onPreview={this.onPreview} onChange={(info) => this.imageUpLoadOnChange(info, 2)}
                                    fileList={fileList2}>
                                    <Button icon={<UploadOutlined />}>上传</Button>
                                </Upload>
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="form-label1 require">姓名：</label>
                            <div className="form-ipt">
                                <Input placeholder="姓名" value={username} onChange={e => this.usernameChange(e)} />
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="form-label1 require">身份证号：</label>
                            <div className="form-ipt">
                                <Input placeholder="身份证号" value={idCard} onChange={e => this.idCardChange(e)} />
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="form-label1 require">性别：</label>
                            <div className="form-ipt">
                                <Select style={{ width: "100%" }} value={this.state.sex} onChange={this.sexOnChange}>
                                    <Option key={1} value={1}>男</Option>
                                    <Option key={2} value={2}>女</Option>
                                </Select>
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="form-label1 require">出生日期：</label>
                            <div className="form-ipt">
                                <Input style={{ width: '25%' }} value={year} onChange={(e) => this.birdayOnChange(e, "y")} />年
                                <Input style={{ width: '25%' }} value={month} onChange={(e) => this.birdayOnChange(e, "m")} />月
                                <Input style={{ width: '25%' }} value={day} onChange={(e) => this.birdayOnChange(e, "d")} />日

                            </div>
                        </div>
                        <div className="form-group">
                            <label className="form-label1">民族：</label>
                            <div className="form-ipt">
                                <Select className="ipt1" allowClear={false} placeholder="请选择" value={this.state.nation} onChange={e => this.nationChange(e)}  >
                                    <Option key={'01'}>汉族</Option>
                                    <Option key={'08'}>壮族</Option>
                                    <Option key={'11'}>满族</Option>
                                    <Option key={'06'}>苗族</Option>
                                    <Option key={'03'}>回族</Option>
                                    <Option key={'05'}>维吾尔族</Option>
                                    <Option key={'07'}>彝族</Option>
                                    <Option key={'15'}>土家族</Option>
                                    <Option key={'04'}>藏族</Option>
                                    <Option key={'02'}>蒙古族</Option>
                                    <Option key={'09'}>布依族</Option>
                                    <Option key={'10'}>朝鲜族</Option>
                                    <Option key={'12'}>侗族</Option>
                                    <Option key={'13'}>瑶族</Option>
                                    <Option key={'14'}>白族</Option>
                                    <Option key={'16'}>哈尼族</Option>
                                    <Option key={'17'}>哈萨克族</Option>
                                    <Option key={'18'}>傣族</Option>
                                    <Option key={'19'}>黎族</Option>
                                    <Option key={'20'}>傈傈族</Option>
                                    <Option key={'21'}>佤族</Option>
                                    <Option key={'22'}>畲族</Option>
                                    <Option key={'23'}>高山族</Option>
                                    <Option key={'24'}>拉祜族</Option>
                                    <Option key={'25'}>水族</Option>
                                    <Option key={'26'}>东乡族</Option>
                                    <Option key={'27'}>纳西族</Option>
                                    <Option key={'28'}>景颇族</Option>
                                    <Option key={'29'}>柯尔克孜族</Option>
                                    <Option key={'30'}>土族</Option>
                                    <Option key={'31'}>达翰尔族</Option>
                                    <Option key={'32'}>仫佬族</Option>
                                    <Option key={'33'}>羌族</Option>
                                    <Option key={'34'}>布朗族</Option>
                                    <Option key={'35'}>撒拉族</Option>
                                    <Option key={'36'}>毛难族</Option>
                                    <Option key={'37'}>仡佬族</Option>
                                    <Option key={'38'}>锡伯族</Option>
                                    <Option key={'39'}>阿昌族</Option>
                                    <Option key={'40'}>普米族</Option>
                                    <Option key={'41'}>塔吉克族</Option>
                                    <Option key={'42'}>怒族</Option>
                                    <Option key={'43'}>乌孜别克族</Option>
                                    <Option key={'44'}>俄罗斯族</Option>
                                    <Option key={'45'}>鄂温克族</Option>
                                    <Option key={'46'}>崩龙族</Option>
                                    <Option key={'47'}>保安族</Option>
                                    <Option key={'48'}>裕固族</Option>
                                    <Option key={'49'}>京族</Option>
                                    <Option key={'50'}>塔塔尔族</Option>
                                    <Option key={'51'}>独龙族</Option>
                                    <Option key={'52'}>鄂伦春族</Option>
                                    <Option key={'53'}>赫哲族</Option>
                                    <Option key={'54'}>门巴族</Option>
                                    <Option key={'55'}>珞巴族</Option>
                                    <Option key={'56'}>基诺族</Option>
                                    <Option key={'97'}>其他</Option>
                                    <Option key={'98'}>外国血统</Option>
                                </Select>
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="form-label1">文化程度：</label>
                            <div className="form-ipt">
                                <Select className="ipt1" allowClear={false} placeholder="请选择" value={this.state.education} onChange={this.oneducationChange} >
                                    {this.state.educationList.map(edu => {
                                        return <Option key={edu.value} value={edu.value}>{edu.text}</Option>
                                    })}
                                </Select>
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="form-label1">常住类型：</label>
                            <div className="form-ipt">
                                <Select className="ipt1" allowClear={false} placeholder="请选择" value={this.state.domicileType} onChange={this.ondomicileTypeChange} >
                                    <Option key={1} value={1}>户籍</Option>
                                    <Option key={2} value={2}>非户籍</Option>
                                </Select>
                            </div>
                        </div>
                        
                        <div className="form-group">
                            <label className="form-label1">现住址：</label>
                            <div className="form-ipt">
                                <TextArea placeholder="请输入" value={this.state.residentialAddress} onChange={e => this.residentialAddressChange(e)} />

                            </div>
                        </div>
                        <div className="form-group">
                            <label className="form-label1 require">联系电话：</label>
                            <div className="form-ipt">
                                <Input placeholder="联系电话" value={phone} onChange={e => this.phoneChange(e)} />

                            </div>
                        </div>
                        <div className="form-group">
                            <label className="form-label1 require">单位名称：</label>
                            <div className="form-ipt">
                                <Input placeholder="单位" value={workUnit} onChange={e => this.workUnitChange(e)} />

                            </div>
                        </div>
                        <div className="form-group">
                            <label className="form-label1">婚姻状况：</label>
                            <div className="form-ipt">
                                <Select className="ipt1" allowClear={false} placeholder="请选择" value={this.state.marital} onChange={this.onmaritalChange} >
                                     {this.state.maritalList.map(edu => {
                                        return <Option key={edu.value} value={edu.value}>{edu.text}</Option>
                                    })}
                                </Select>
                            </div>
                        </div>
                        
                        <div className="form-group">
                            <label className="form-label1">身高：</label>
                            <div className="form-ipt">
                                <Input placeholder="身高cm" value={height} onChange={e => this.heightChange(e)} />

                            </div>
                        </div>
                        <div className="form-group">
                            <label className="form-label1">体重：</label>
                            <div className="form-ipt">
                                <Input placeholder="体重kg" value={weight} onChange={e => this.weightChange(e)} />

                            </div>
                        </div>
                        <div className="form-group">
                            <label className="form-label1">BMI：</label>
                            <div className="form-ipt">
                                <Input placeholder="自动计算" value={this.state.bmi} disabled />
                                {/* <Button type="primary" ghost onClick={this.autoCompleteBMI}> 自动计算</Button> */}
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="form-label1">腰围：</label>
                            <div className="form-ipt">
                                <Input placeholder="腰围cm" value={waist} onChange={e => this.waistChange(e)} />

                            </div>
                        </div>
                        <div className="form-group">
                            <label className="form-label1">医保类型：</label>
                            <div className="form-ipt">
                                <Select style={{ width: "100%" }} allowClear={false} placeholder="请选择" value={this.state.hosType} onChange={this.hosTypeChange} >
                                    <Option key={'01'} value={'01'}>城镇职工医疗保险</Option>
                                    <Option key={'02'} value={'02'}>城镇居民医疗保险</Option>
                                    <Option key={'03'} value={'03'}>新型农村合作医疗</Option>
                                    <Option key={'04'} value={'04'}>贫困救助</Option>
                                    <Option key={'05'} value={'05'}>商业医疗保险</Option>
                                    <Option key={'06'} value={'06'}>全公费</Option>
                                    <Option key={'07'} value={'07'}>全自费</Option>
                                </Select>
                            </div>
                        </div>
                        {/*         档案登记        */}
                        <div className="form-group">
                            <label className="form-label1">血型：</label>
                            <div className="form-ipt">
                                <Select style={{ width: "100%" }} value={registrationData.blood} onChange={e => this.selectOnlyOneChange(e, 'blood')}>
                                    <Option key={1} value={1}>A型</Option>
                                    <Option key={2} value={2}>B型</Option>
                                    <Option key={3} value={3}>O型</Option>
                                    <Option key={4} value={4}>AB型</Option>
                                    <Option key={5} value={5}>不详</Option>
                                </Select>
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="form-label1">血压：</label>
                            <div className="form-ipt">
                                <Input style={{ width: '45%' }} type="number" placeholder="收缩压" value={registrationData.shrink} onChange={(e) => this.RegisterationDataInputChange(e, "shrink")} /> /
                                <Input style={{ width: '45%' }} type="number" placeholder="舒张压" value={registrationData.diastole} onChange={(e) => this.RegisterationDataInputChange(e, "diastole")} />
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="form-label1">锻炼频率：</label>
                            <div className="form-ipt">
                                <Select style={{ width: "100%" }} value={registrationData.exerciseFrequency} onChange={e => this.selectOnlyOneChange(e, 'exerciseFrequency')}>
                                    <Option key={1} value={1}>每天</Option>
                                    <Option key={2} value={2}>每周一次以上</Option>
                                    <Option key={3} value={3}>偶尔</Option>
                                    <Option key={4} value={4}>不锻炼</Option>
                                </Select>
                            </div>
                        </div>
                        {[1, 2, 3].includes(registrationData.exerciseFrequency) &&
                            <div>
                                <div className="form-group">
                                    <label className="form-label1">锻炼时长：</label>
                                    <div className="form-ipt">
                                        <Input style={{ width: '100%' }} suffix="分钟" type="number" value={registrationData.exerciseTime} onChange={(e) => this.RegisterationDataInputChange(e, "exerciseTime", Number)} />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="form-label1">持续时间：</label>
                                    <div className="form-ipt">
                                        <Input style={{ width: '100%' }} suffix="年" type="number" value={registrationData.duration} onChange={(e) => this.RegisterationDataInputChange(e, "duration", Number)} />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="form-label1">锻炼方式：</label>
                                    <div className="form-ipt">
                                        <TextArea style={{ width: '100%' }} value={registrationData.exerciseType} onChange={(e) => this.RegisterationDataInputChange(e, "exerciseType")} />
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="form-group">
                            <label className="form-label1">饮食习惯：</label>
                            <div className="form-ipt">
                                <Checkbox.Group
                                    value={registrationData.dietaryHabit.split(',')}
                                    onChange={e => this.onRegisterationData_CheckBoxChange(e, 'dietaryHabit')}
                                >
                                    <Checkbox value={'1'}>荤素均衡</Checkbox><br />
                                    <Checkbox value={'2'}>荤食为主</Checkbox><br />
                                    <Checkbox value={'3'}>素食为主</Checkbox><br />
                                    <Checkbox value={'4'}>嗜盐</Checkbox><br />
                                    <Checkbox value={'5'}>嗜油</Checkbox><br />
                                    <Checkbox value={'6'}>嗜糖</Checkbox>
                                </Checkbox.Group>
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="form-label1">吸烟状况：</label>
                            <div className="form-ipt">
                                <Select style={{ width: "100%" }} value={registrationData.smoke} onChange={e => this.selectOnlyOneChange(e, 'smoke')}>
                                    <Option key={1} value={1}>从不吸烟</Option>
                                    <Option key={2} value={2}>已戒烟</Option>
                                    <Option key={3} value={3}>吸烟</Option>
                                </Select>
                            </div>
                        </div>
                        {[2, 3].includes(registrationData.smoke) &&
                            <div>
                                <div className="form-group">
                                    <label className="form-label1">日均量：</label>
                                    <div className="form-ipt">
                                        <Input style={{ width: '100%' }} suffix="支" type="number" value={registrationData.smokeDayAverage} onChange={(e) => this.RegisterationDataInputChange(e, "smokeDayAverage", Number)} />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="form-label1">开始吸烟年龄：</label>
                                    <div className="form-ipt">
                                        <Input style={{ width: '100%' }} suffix="岁" type="number" value={registrationData.startSmokeAge} onChange={(e) => this.RegisterationDataInputChange(e, "startSmokeAge", Number)} />
                                    </div>
                                </div>
                            </div>
                        }
                        {registrationData.smoke === 2 &&
                            <div className="form-group">
                                <label className="form-label1">戒烟年龄：</label>
                                <div className="form-ipt">
                                    <Input style={{ width: '100%' }} suffix="岁" type="number" value={registrationData.endSmokeAge} onChange={(e) => this.RegisterationDataInputChange(e, "endSmokeAge", Number)} />

                                </div>
                            </div>}
                        <div className="form-group">
                            <label className="form-label1">饮酒频率：</label>
                            <div className="form-ipt">
                                <Select style={{ width: "100%" }} value={registrationData.drink} onChange={e => this.selectOnlyOneChange(e, 'drink')}>
                                    <Option key={1} value={1}>从不</Option>
                                    <Option key={2} value={2}>偶尔</Option>
                                    <Option key={3} value={3}>经常</Option>
                                    <Option key={4} value={4}>每天</Option>
                                </Select>
                            </div>
                        </div>
                        {[2, 3, 4].includes(registrationData.drink) &&
                            <div>
                                <div className="form-group">
                                    <label className="form-label1">日均量：</label>
                                    <div className="form-ipt">
                                        <Input style={{ width: '100%' }} suffix="两" type="number" value={registrationData.drinkDayAverage} onChange={(e) => this.RegisterationDataInputChange(e, "drinkDayAverage", Number)} />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="form-label1">是否戒酒：</label>
                                    <div className="form-ipt">
                                        <Select style={{ width: "100%" }} value={registrationData.isStopDrink} onChange={e => this.selectOnlyOneChange(e, 'isStopDrink')}>
                                            <Option key={1} value={1}>未戒酒</Option>
                                            <Option key={2} value={2}>已戒酒</Option>
                                        </Select>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="form-label1">戒酒年龄：</label>
                                    <div className="form-ipt">
                                        <Input style={{ width: '100%' }} suffix="岁" type="number" value={registrationData.stopDrinkAge} onChange={(e) => this.RegisterationDataInputChange(e, "stopDrinkAge", Number)} />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="form-label1">饮酒年龄：</label>
                                    <div className="form-ipt">
                                        <Input style={{ width: '100%' }} suffix="岁" type="number" value={registrationData.startDrinkAge} onChange={(e) => this.RegisterationDataInputChange(e, "startDrinkAge", Number)} />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="form-label1">近一年是否醉酒：</label>
                                    <div className="form-ipt">
                                        <Select style={{ width: "100%" }} value={registrationData.isDrunk} onChange={e => this.selectOnlyOneChange(e, 'isDrunk')}>
                                            <Option key={1} value={1}>是</Option>
                                            <Option key={2} value={2}>否</Option>
                                        </Select>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="form-label1">酒种类：</label>
                                    <div className="form-ipt">
                                        <Checkbox.Group
                                            value={registrationData.wineType.split(',')}
                                            onChange={e => this.onRegisterationData_CheckBoxChange(e, 'wineType', null, '5', 'otherWine')}
                                        >
                                            <Checkbox value={'1'}>白酒</Checkbox><br />
                                            <Checkbox value={'2'}>啤酒</Checkbox><br />
                                            <Checkbox value={'3'}>红酒</Checkbox><br />
                                            <Checkbox value={'4'}>黄酒</Checkbox><br />
                                            <Checkbox value={'5'}>其他</Checkbox><br />
                                        </Checkbox.Group>
                                        {registrationData.wineType.split(',').includes('5') && <Input style={{ width: '100%' }} placeholder="其他酒请补充" value={registrationData.otherWine} onChange={(e) => this.RegisterationDataInputChange(e, "otherWine")} />
                                        }


                                    </div>
                                </div>

                            </div>
                        }
                        <div className="form-group">
                            <label className="form-label1">用药情况：</label>
                            <div className="form-ipt">
                                {registrationData.drug.map((item, index) =>
                                    <div className='record-detail-box' key={index}>
                                        <div className='record-detail-left' >
                                            <p>{`${item.drugName}：${['', '规律', '间断', '不服药'][item.compliance]}`}</p>
                                            <p>{`用法：${item.usage}`}</p>
                                            <p>{`用量：${item.dosage}`}</p>
                                            <p>{`使用时间：${item.useTime}`}</p>
                                        </div>
                                        <div className='record-detail-right' onClick={() => this.delDrugDetail(item.drugName)}>
                                            <DeleteOutlined style={{ color: "red" }} />
                                        </div>
                                    </div>
                                )}
                                <Button type="primary" onClick={this.showDrugDetail}>添加</Button>

                            </div>
                        </div>
                        <div className="form-group">
                            <label className="form-label1">疾病史：</label>
                            <div className="form-ipt">
                                {registrationData.disease[0].state !== 1 && registrationData.disease.map((item, index) =>
                                    <div className='record-detail-box' key={index}>
                                        <div className='record-detail-left'>
                                            <p>{`${['', '', '高血压', '糖尿病', '冠心病', '慢性阻塞性肺疾病', '恶性肿瘤', '脑卒中', '重型精神疾病', '结核病', '肝炎', '其他法定传染病', '职业病', '其他'][item.state]} ：${item.time}`}</p>
                                            {item.val.length > 0 && <p>描述：{item.val}</p>}
                                        </div>
                                        <div className='record-detail-right' onClick={() => this.delDiseaseDetail(item.state)}>
                                            <DeleteOutlined style={{ color: "red" }} />
                                        </div>
                                    </div>
                                )}
                                <Button type="primary" onClick={this.showDiseaseDetail}>添加</Button>

                            </div>
                        </div>
                        <div className="form-group">
                            <label className="form-label1">手术史：</label>
                            <div className="form-ipt">
                                {registrationData.surgeryHistory === 2 && registrationData.surgeryInfo.map((item, index) =>
                                    <div className='record-detail-box' key={index}>
                                        <div className='record-detail-left' >
                                            <p>{`${item.val}：${item.time}`}</p>
                                        </div>
                                        <div className='record-detail-right' onClick={() => this.delShoushuDetail(item.val)}>
                                            <DeleteOutlined style={{ color: "red" }} />
                                        </div>
                                    </div>
                                )}
                                <Button type="primary" onClick={this.showShoushuDetail}>添加</Button>

                            </div>
                        </div>
                        <div className="form-group">
                            <label className="form-label1">外伤史：</label>
                            <div className="form-ipt">
                                {registrationData.traumaHistory === 2 && registrationData.traumaInfo.map((item, index) =>
                                    <div className='record-detail-box' key={index}>
                                        <div className='record-detail-left' >
                                            <p>{`${item.val}：${item.time}`}</p>
                                        </div>
                                        <div className='record-detail-right' onClick={() => this.delWaishangDetail(item.val)}>
                                            <DeleteOutlined style={{ color: "red" }} />
                                        </div>
                                    </div>
                                )}
                                <Button type="primary" onClick={this.showWaishangDetail}>添加</Button>

                            </div>
                        </div>
                        <div className="form-group">
                            <label className="form-label1">过敏史：</label>
                            <div className="form-ipt">
                                {/* <Select style={{ width: "100%" }} value={registrationData.allergy} onChange={e => this.selectOnlyOneChange(e, 'allergy')}>
                                    <Option key={1} value={1}>无</Option>
                                    <Option key={2} value={2}>青霉素</Option>
                                    <Option key={3} value={3}>磺胺</Option>
                                    <Option key={4} value={4}>链霉毒</Option>
                                    <Option key={5} value={5}>其他</Option>
                                </Select> */}
                                {/* {registrationData.allergy === 5 && <Input style={{ width: '100%' }} value={registrationData.allergyOther} onChange={(e) => this.RegisterationDataInputChange(e, "allergyOther")} />
                                } */}
                                <Checkbox.Group
                                    value={registrationData.allergy.split(',')}
                                    onChange={e => this.onRegisterationData_CheckBoxChange(e, 'allergy', '1', '5', 'allergyOther')}
                                >
                                    <Checkbox key={1} value={'1'}>无</Checkbox><br />
                                    <Checkbox key={2} value={'2'}>青霉素</Checkbox><br />
                                    <Checkbox key={3} value={'3'}>磺胺</Checkbox><br />
                                    <Checkbox key={4} value={'4'}>链霉毒</Checkbox><br />
                                    <Checkbox key={5} value={'5'}>其他</Checkbox><br />
                                </Checkbox.Group>
                                {registrationData.allergy.split(',').includes('5') && <Input style={{ width: '100%' }} placeholder="其他过敏" value={registrationData.allergyOther} onChange={(e) => this.RegisterationDataInputChange(e, "allergyOther")} />
                                }
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="form-label1">父亲：</label>
                            <div className="form-ipt">
                                <Checkbox.Group
                                    value={registrationData.father.split(',')}
                                    onChange={e => this.onRegisterationData_CheckBoxChange(e, 'father', '1', '12', 'fatherOther')}
                                >
                                    <Checkbox value={'1'}>无</Checkbox><br />
                                    <Checkbox value={'2'}>高血压</Checkbox><br />
                                    <Checkbox value={'3'}>糖尿病</Checkbox><br />
                                    <Checkbox value={'4'}>冠心病</Checkbox><br />
                                    <Checkbox value={'5'}>慢性阻塞性肺疾病</Checkbox><br />
                                    <Checkbox value={'6'}>恶性肿瘤</Checkbox><br />
                                    <Checkbox value={'7'}>脑卒中</Checkbox><br />
                                    <Checkbox value={'8'}>重性精神疾病</Checkbox><br />
                                    <Checkbox value={'9'}>结核病</Checkbox><br />
                                    <Checkbox value={'10'}>肝炎</Checkbox><br />
                                    <Checkbox value={'11'}>先天畸形</Checkbox><br />
                                    <Checkbox value={'12'}>其他</Checkbox>
                                </Checkbox.Group>
                                {registrationData.father.split(',').includes('12') && <Input style={{ width: '100%' }} placeholder="其他疾病" value={registrationData.fatherOther} onChange={(e) => this.RegisterationDataInputChange(e, "fatherOther")} />
                                }
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="form-label1">母亲：</label>
                            <div className="form-ipt">
                                <Checkbox.Group
                                    value={registrationData.mother.split(',')}
                                    onChange={e => this.onRegisterationData_CheckBoxChange(e, 'mother', '1', '12', 'motherOther')}
                                >
                                    <Checkbox value={'1'}>无</Checkbox><br />
                                    <Checkbox value={'2'}>高血压</Checkbox><br />
                                    <Checkbox value={'3'}>糖尿病</Checkbox><br />
                                    <Checkbox value={'4'}>冠心病</Checkbox><br />
                                    <Checkbox value={'5'}>慢性阻塞性肺疾病</Checkbox><br />
                                    <Checkbox value={'6'}>恶性肿瘤</Checkbox><br />
                                    <Checkbox value={'7'}>脑卒中</Checkbox><br />
                                    <Checkbox value={'8'}>重性精神疾病</Checkbox><br />
                                    <Checkbox value={'9'}>结核病</Checkbox><br />
                                    <Checkbox value={'10'}>肝炎</Checkbox><br />
                                    <Checkbox value={'11'}>先天畸形</Checkbox><br />
                                    <Checkbox value={'12'}>其他</Checkbox>
                                </Checkbox.Group>
                                {registrationData.mother.split(',').includes('12') && <Input style={{ width: '100%' }} placeholder="其他疾病" value={registrationData.motherOther} onChange={(e) => this.RegisterationDataInputChange(e, "motherOther")} />
                                }
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="form-label1">兄弟姐妹：</label>
                            <div className="form-ipt">
                                <Checkbox.Group
                                    value={registrationData.brother.split(',')}
                                    onChange={e => this.onRegisterationData_CheckBoxChange(e, 'brother', '1', '12', 'brotherOther')}
                                >
                                    <Checkbox value={'1'}>无</Checkbox><br />
                                    <Checkbox value={'2'}>高血压</Checkbox><br />
                                    <Checkbox value={'3'}>糖尿病</Checkbox><br />
                                    <Checkbox value={'4'}>冠心病</Checkbox><br />
                                    <Checkbox value={'5'}>慢性阻塞性肺疾病</Checkbox><br />
                                    <Checkbox value={'6'}>恶性肿瘤</Checkbox><br />
                                    <Checkbox value={'7'}>脑卒中</Checkbox><br />
                                    <Checkbox value={'8'}>重性精神疾病</Checkbox><br />
                                    <Checkbox value={'9'}>结核病</Checkbox><br />
                                    <Checkbox value={'10'}>肝炎</Checkbox><br />
                                    <Checkbox value={'11'}>先天畸形</Checkbox><br />
                                    <Checkbox value={'12'}>其他</Checkbox>
                                </Checkbox.Group>
                                {registrationData.brother.split(',').includes('12') && <Input style={{ width: '100%' }} placeholder="其他疾病" value={registrationData.brotherOther} onChange={(e) => this.RegisterationDataInputChange(e, "brotherOther")} />
                                }
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="form-label1">子女：</label>
                            <div className="form-ipt">
                                <Checkbox.Group
                                    value={registrationData.children.split(',')}
                                    onChange={e => this.onRegisterationData_CheckBoxChange(e, 'children', '1', '12', 'childrenOther')}
                                >
                                    <Checkbox value={'1'}>无</Checkbox><br />
                                    <Checkbox value={'2'}>高血压</Checkbox><br />
                                    <Checkbox value={'3'}>糖尿病</Checkbox><br />
                                    <Checkbox value={'4'}>冠心病</Checkbox><br />
                                    <Checkbox value={'5'}>慢性阻塞性肺疾病</Checkbox><br />
                                    <Checkbox value={'6'}>恶性肿瘤</Checkbox><br />
                                    <Checkbox value={'7'}>脑卒中</Checkbox><br />
                                    <Checkbox value={'8'}>重性精神疾病</Checkbox><br />
                                    <Checkbox value={'9'}>结核病</Checkbox><br />
                                    <Checkbox value={'10'}>肝炎</Checkbox><br />
                                    <Checkbox value={'11'}>先天畸形</Checkbox><br />
                                    <Checkbox value={'12'}>其他</Checkbox>
                                </Checkbox.Group>
                                {registrationData.children.split(',').includes('12') && <Input style={{ width: '100%' }} placeholder="其他疾病" value={registrationData.childrenOther} onChange={(e) => this.RegisterationDataInputChange(e, "childrenOther")} />
                                }
                            </div>
                        </div>
                        <Button type="primary" style={{ width: "100%" }} disabled={this.state.btnState} onClick={this.submit}>确定提交</Button>
                        <div className="form-group">
                            {this.state.erweimaOrg === "62f4a124a69c4c3f8f6a822ff51255b7" &&
                                <div>地址：南京开元大酒店23楼知康办证服务点（周一至周五）<p></p>咨询电话:17368120001</div>}

                            {this.state.erweimaOrg === "320101020000" &&
                                <div>地址：南京开元大酒店23楼知康办证服务点（周一至周五）<p></p>咨询电话:17368120001</div>}


                            {this.state.erweimaOrg === "706a25384d7843a7bc5233f65d14ad51" &&
                                <div>地址：江北新区明滨路明发外滩广场F4(泰山街道体检中心）<p></p>咨询电话:025-68989587</div>}
                            <p></p>
                            备注：1、预约单位名称需要与材料的单位名称一致；<p></p>
                            2、单位证件含食品生产许可证、经营许可证、化妆品生产许可证、南京市地摊贩公示卡、食品小作坊登记证、小餐饮备案信息公示卡、生活饮用水卫生许可证、
                            涉及饮用水卫生安全产品卫生许可、消毒产品生产企业卫生许可证、公共场所卫生许可证、从事食品卫生服务相关行业的单位营业执照等复印件。

                        </div>
                    </div>
                    {/* 疾病史 */}
                    <Drawer
                        className='yuyue_drawer'
                        height="320"
                        maskClosable={false}
                        title="疾病史"
                        onClose={this.closeDiseaseDetail}
                        placement="bottom"
                        visible={this.state.diseaseDetailVisible}
                        footer={
                            <Button type="primary" style={{ width: '100%' }} onClick={this.saveDiseaseDetail} >保存</Button>
                        }
                    >
                        <div className="form-group">
                            <label className="form-label1 require">疾病：</label>
                            <div className="form-ipt">
                                <Select style={{ width: "100%" }} value={diseaseDetail.state} onChange={this.diseaseDetailStateChange}>
                                    <Option value={2}>高血压</Option>
                                    <Option value={3}>糖尿病</Option>
                                    <Option value={4}>冠心病</Option>
                                    <Option value={5}>慢性阻塞性肺疾病</Option>
                                    <Option value={6}>恶性肿瘤</Option>
                                    <Option value={7}>脑卒中</Option>
                                    <Option value={8}>重型精神疾病</Option>
                                    <Option value={9}>结核病</Option>
                                    <Option value={10}>肝炎</Option>
                                    <Option value={11}>其他法定传染病</Option>
                                    <Option value={12}>职业病</Option>
                                    <Option value={13}>其他</Option>
                                </Select>
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="form-label1 require">时间：</label>
                            <div className="form-ipt">
                                <DatePicker style={{ width: '100%' }} placeholder="选择年月" value={diseaseDetail.time.length > 0 ? moment(diseaseDetail.time, 'YYYY-MM') : null} onChange={this.diseaseDetailTimeChange} picker="month" />

                            </div>
                        </div>
                        {[6, 11, 12, 13].includes(diseaseDetail.state) &&
                            <div className="form-group">
                                <label className="form-label1">描述：</label>
                                <div className="form-ipt">
                                    <TextArea style={{ width: '100%' }} value={diseaseDetail.val} onChange={this.diseaseDetailValChange} />
                                </div>
                            </div>
                        }

                    </Drawer>
                    {/* 手术史 */}
                    <Drawer
                        className='yuyue_drawer'
                        maskClosable={false}
                        title="手术史"
                        onClose={this.closeshoushuDetail}
                        placement="bottom"
                        visible={this.state.shoushuDetailVisible}
                        footer={
                            <Button type="primary" style={{ width: '100%' }} onClick={this.saveshoushuDetail} >保存</Button>
                        }
                    >
                        <div className="form-group">
                            <label className="form-label1 require">时间：</label>
                            <div className="form-ipt">
                                <DatePicker style={{ width: '100%' }} placeholder="选择年月" value={shoushuDetail.time.length > 0 ? moment(shoushuDetail.time, 'YYYY-MM') : null} onChange={this.shoushuDetailTimeChange} picker="month" />

                            </div>
                        </div>
                        <div className="form-group">
                            <label className="form-label1 require">手术名称：</label>
                            <div className="form-ipt">
                                <TextArea style={{ width: '100%' }} value={shoushuDetail.val} onChange={this.shoushuDetailValChange} />
                            </div>
                        </div>

                    </Drawer>
                    {/* 外伤史 */}
                    <Drawer
                        className='yuyue_drawer'
                        maskClosable={false}
                        title="外伤史"
                        onClose={this.closeWaishangDetail}
                        placement="bottom"
                        visible={this.state.waishangDetailVisible}
                        footer={
                            <Button type="primary" style={{ width: '100%' }} onClick={this.saveWaishangDetail} >保存</Button>
                        }
                    >
                        <div className="form-group">
                            <label className="form-label1 require">时间：</label>
                            <div className="form-ipt">
                                <DatePicker style={{ width: '100%' }} placeholder="选择年月" value={waishangDetail.time.length > 0 ? moment(waishangDetail.time, 'YYYY-MM') : null} onChange={this.waishangDetailTimeChange} picker="month" />

                            </div>
                        </div>
                        <div className="form-group">
                            <label className="form-label1 require">外伤名称：</label>
                            <div className="form-ipt">
                                <TextArea style={{ width: '100%' }} value={waishangDetail.val} onChange={this.waishangDetailValChange} />
                            </div>
                        </div>

                    </Drawer>
                    {/* 用药情况 */}
                    <Drawer
                        height="420"
                        className='yuyue_drawer'
                        maskClosable={false}
                        title="用药情况"
                        onClose={this.closeDrugDetail}
                        placement="bottom"
                        visible={this.state.DrugDetailVisible}
                        footer={
                            <Button type="primary" style={{ width: '100%' }} onClick={this.saveDrugDetail} >保存</Button>
                        }
                    >
                        <div className="form-group">
                            <label className="form-label1 require">药物名称：</label>
                            <div className="form-ipt">
                                <Input style={{ width: '100%' }} value={drugDetail.drugName} onChange={this.drugDetailDrugNameChange} />
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="form-label1 require">服药依从性：</label>
                            <div className="form-ipt">
                                <Select style={{ width: "100%" }} value={drugDetail.compliance} onChange={this.drugDetailComplianceChange}>
                                    <Option value={1}>规律</Option>
                                    <Option value={2}>间断</Option>
                                    <Option value={3}>不服药</Option>
                                </Select>
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="form-label1 ">用法：</label>
                            <div className="form-ipt">
                                <Input style={{ width: '100%' }} value={drugDetail.usage} onChange={this.drugDetailUsageChange} />
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="form-label1 ">用量：</label>
                            <div className="form-ipt">
                                <Input style={{ width: '100%' }} value={drugDetail.dosage} onChange={this.drugDetailDosageChange} />
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="form-label1 ">使用时间：</label>
                            <div className="form-ipt">
                                <Input style={{ width: '100%' }} value={drugDetail.useTime} onChange={this.drugDetailUseTimeChange} />
                            </div>
                        </div>
                    </Drawer>
                </div>
            </div >
        )
    }
}
export default connect(null, {
    appointmentPageDetail2, appointmentSave, uploadFile, appointmentRegisterSave
})(main)