import React, { Component } from 'react'
import { Button, Input, Select, message, } from 'antd';
import { connect } from 'react-redux'
import { appointmentPageDetail2, appointmentSave, getUserGroupNumber } from '../../actions/account'
import './yuyue.css'
import { SETTINGS } from './MXB_SETTINGS'

import moment from 'moment';
// import { red } from '@material-ui/core/colors';
const { Option } = Select
class main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedDate: moment().format("YYYY-MM-DD"),

            SETTING_ORGID: null,
            SETTING_FIRST: null,
            SETTING_SECOND: null,
            SETTING_THIRD: null,
            ready_FIRST: null,
            ready_SECOND: null,
            ready_THIRD: null,
            age: undefined,
            sex: "",
            weekName: "",
            weeks: [[], [], [], [], []],
            btnState: false,
            time: "",
            Datas: [],
            syme: null,
            username: "", idCard: "", workUnit: "", phone: "", town: "", county: "", village: "", team: "", height: "", weight: "", occupation: "", education: "",
            pageTitle: "",
            occupation1: "",
            occupation2: "",
            educationList: [
                {
                    value: "10",
                    text: '研究生教育'
                }, {
                    value: "20",
                    text: '大学本科教育'
                }, {
                    value: "30",
                    text: '大学专科教育'
                }, {
                    value: "40",
                    text: '中等职业教育'
                }, {
                    value: "60",
                    text: '普通高级中学教育'
                }, {
                    value: "70",
                    text: '初级中学教育'
                }, {
                    value: "80",
                    text: '小学教育'
                }, {
                    value: "90",
                    text: '其他'
                }
            ],
            occupationList: [
                {
                    value: "100",
                    text: '党的机关、国家机关、群众团体和社会组织、企事业单位负责人',
                    children: [
                        {
                            value: "101",
                            text: '中国共产党机关负责人'
                        }, {
                            value: "102",
                            text: '国家机关负责人'
                        }, {
                            value: "103",
                            text: '民族党派和工商联负责人'
                        }, {
                            value: "104",
                            text: '人民团体和群众团体、社会组织及其他成员组织负责人'
                        }, {
                            value: "105",
                            text: '基层群众自治组织负责人'
                        }, {
                            value: "106",
                            text: '企事业单位负责人'
                        }, {
                            value: "199",
                            text: '其他党政机关、群众团体和社会组织、企事业单位负责人'
                        }
                    ]
                },
                {
                    value: "200",
                    text: '专业技术人员',
                    children: [
                        {
                            value: "201",
                            text: '科学研究人员'
                        }, {
                            value: "202",
                            text: '工程技术人员'
                        }, {
                            value: "203",
                            text: '农业技术人员'
                        }, {
                            value: "204",
                            text: '飞机和船舶技术人员'
                        }, {
                            value: "205",
                            text: '卫生专业技术人员'
                        }, {
                            value: "206",
                            text: '经济和金融专业人员'
                        }, {
                            value: "207",
                            text: '法律、社会和宗教专业人员'
                        },
                        {
                            value: "208",
                            text: '教学人员'
                        }, {
                            value: "209",
                            text: '文学艺术、体育专业人员'
                        }, {
                            value: "210",
                            text: '新闻出版、文化专业人员'
                        }, {
                            value: "299",
                            text: '其他专业技术人员'
                        },
                    ]
                },
                {
                    value: "300",
                    text: '办事人员和有关人员',
                    children: [
                        {
                            value: "301",
                            text: '办事人员'
                        }, {
                            value: "302",
                            text: '安全和消防人员'
                        }, {
                            value: "399",
                            text: '其他办事人员和有关人员'
                        }
                    ]
                },
                {
                    value: "400",
                    text: '社会生产服务和生活服务人员',
                    children: [
                        {
                            value: "401",
                            text: '批发与零售服务人员'
                        }, {
                            value: "402",
                            text: '交通运输、仓储和邮政业服务人员'
                        }, {
                            value: "403",
                            text: '住宿和餐饮服务人员'
                        }, {
                            value: "404",
                            text: '信息运输、软件和信息技术服务人员'
                        }, {
                            value: "405",
                            text: '金融服务人员'
                        }, {
                            value: "406",
                            text: '房地产服务人员'
                        }, {
                            value: "407",
                            text: '租赁和商务服务人员'
                        }, {
                            value: "408",
                            text: '技术辅助服务人员'
                        }, {
                            value: "409",
                            text: '水利、环境和公共设施管理服务人员'
                        }, {
                            value: "410",
                            text: '居民服务人员'
                        }, {
                            value: "411",
                            text: '电力、燃气及水供应服务人员'
                        }, {
                            value: "412",
                            text: '修理及制作服务人员'
                        }, {
                            value: "413",
                            text: '文化、体育及娱乐服务人员'
                        }, {
                            value: "414",
                            text: '健康服务人员'
                        }, {
                            value: "499",
                            text: '其他社会生产和生活服务人员'
                        }
                    ]
                },
                {
                    value: "500",
                    text: '农、林、牧、渔业生产及辅助人员',
                    children: [
                        {
                            value: "501",
                            text: '农业生产人员'
                        }, {
                            value: "502",
                            text: '林业生产人员'
                        }, {
                            value: "503",
                            text: '畜牧业生产人员'
                        }, {
                            value: "504",
                            text: '渔业生产人员'
                        }, {
                            value: "505",
                            text: '农林牧渔生产辅助人员'
                        }, {
                            value: "599",
                            text: '其他农、林、牧、渔、水利业生产人员'
                        },
                    ]
                },
                {
                    value: "600",
                    text: '生产制造及有关人员',
                    children: [
                        {
                            value: "601",
                            text: '农副产品加工人员'
                        }, {
                            value: "602",
                            text: '食品、饮料生产加工人员'
                        }, {
                            value: "603",
                            text: '烟草及其制品加工人员'
                        }, {
                            value: "604",
                            text: '纺织、针织、印染人员'
                        }, {
                            value: "605",
                            text: '纺织品、服装和皮革、毛皮制品加工制作人员'
                        }, {
                            value: "606",
                            text: '木材加工、家具与木制品制作人员'
                        }, {
                            value: "607",
                            text: '纸及纸制品生产加工人员'
                        }, {
                            value: "608",
                            text: '印刷和记录媒介复制人员'
                        }, {
                            value: "609",
                            text: '文教、工美、体育和娱乐用品制作人员'
                        }, {
                            value: "610",
                            text: '石油加工和炼焦、煤化工制作人员'
                        }, {
                            value: "611",
                            text: '化学原料和化学制品制造人员'
                        }, {
                            value: "612",
                            text: '医药制造人员'
                        }, {
                            value: "613",
                            text: '化学纤维制造人员'
                        }, {
                            value: "614",
                            text: '橡胶和塑料制品制造人员'
                        }, {
                            value: "615",
                            text: '非金属矿物制品制造人员'
                        }, {
                            value: "616",
                            text: '采矿人员'
                        }, {
                            value: "617",
                            text: '金属冶炼和压延加工人员'
                        }, {
                            value: "618",
                            text: '机械制造基础加工人员'
                        }, {
                            value: "619",
                            text: '金属制品制造人员'
                        }, {
                            value: "620",
                            text: '通用设备制造人员'
                        }, {
                            value: "621",
                            text: '专用设备制造人员'
                        }, {
                            value: "622",
                            text: '汽车制造人员'
                        }, {
                            value: "623",
                            text: '铁路、船舶、航空设备制造人员'
                        }, {
                            value: "624",
                            text: '电气机械和器材制造人员'
                        }, {
                            value: "625",
                            text: '计算机、通信和其他电子设备制造人员'
                        }, {
                            value: "626",
                            text: '仪器仪表制造人员'
                        }, {
                            value: "627",
                            text: '废弃资源综合利用人员'
                        }, {
                            value: "628",
                            text: '电力、热力、气体、水生产和输配人员'
                        }, {
                            value: "629",
                            text: '建筑施工人员'
                        }, {
                            value: "630",
                            text: '运输设备和通用工程机械操作人员及有关人员'
                        }, {
                            value: "631",
                            text: '生产辅助人员'
                        }, {
                            value: "699",
                            text: '其他生产制造及有关人员'
                        },
                    ]
                },
                {
                    value: "700",
                    text: '军人',
                    children: []
                },
                {
                    value: "800",
                    text: '不便分类的其他从业人员', children: []
                },
                {
                    value: "999",
                    text: '不详', children: []
                },
            ],
            occupationList1: [],
            occupationList2: [],
            dateList: [],
            timeList: [],
            date:"",
            detailId: "",
            erweimaOrg: undefined,
            erweimaPhy: undefined,
        }
    }
    componentDidMount() {
        var temp = []
        console.log(this.props.location.search.substring(1))
        var params = this.props.location.search.substring(1).split("&")
        var val = []
        var query = {}
        console.log(params)
        for (var i = 0; i < params.length; i++) {
            val = params[i].split("=");
            if (val[0] === 'erweimaName') { query[val[0]] = decodeURI(val[1]) }
            else query[val[0]] = val[1]

        }
        console.log(query)
        this.setState({ org: query.erweimaOrg, phy: query.erweimaPhy }, () => {
            this.loadDatas(query.erweimaOrg, query.erweimaPhy, 1)
        })
        this.state.occupationList.forEach(occ => {
            temp.push({ value: occ.value, text: occ.text })
        });
        var locationData = query
        this.setState({
            pageTitle: locationData.erweimaName,
            occupationList1: temp,
            erweimaOrg: query.erweimaOrg,
            erweimaPhy: query.erweimaPhy,
        })
        console.log(SETTINGS)
        //配置文件
        SETTINGS.forEach(item => {
            if (item.ORGID === query.erweimaOrg) {
                console.log(item)
                this.setState({
                    SETTING_ORGID: item.ORGID,
                    SETTING_FIRST: item.FIRST,
                    SETTING_SECOND: item.SECOND,
                    SETTING_THIRD: item.THIRD,
                })
            }
        })

    }
    onyyDateChange(date, dateString) {
        console.log(date, dateString);
    }
    loadDatas = (org, phy, mark) => {
        var that = this;
        var obj = { organizationId: org, typeId: Number(phy), currentDate: moment('2021-08-28 17:04:41').format("YYYY-MM-DD HH:mm:ss"), selectedDate: this.state.selectedDate };
        // var obj = { organizationId: org, physicalTypeId: Number(phy), currentDate: moment('2021-08-28 17:04:41').format("YYYY-MM-DD HH:mm:ss") };
        // message.loading("加载数据中....", 0)
        this.setState({ weeks: [[], [], [], [], [], [], []], dateList: [], timeList: [] }, () => {
            var { dateList, weeks } = that.state;
            message.destroy();
            console.log(obj)
            that.props.appointmentPageDetail2(obj).then(msg => {
                if (msg.data.code === 200) {
                    if (!msg.data.data || msg.data.data.length === 0) {
                        message.warn('最近没有预约事务！')
                        that.setState({ btnState: false })
                        return;
                    }


                    var festival = ["01-01", "05-01", "10-01"];//法定节假日
                    var objs = msg.data.data;
                    var countDay = 0;
                    objs.forEach(obj => {
                        obj.appointmentDateDetailDTOS.forEach(item => {
                            weeks[item.week - 1].push(item)
                        })
                        obj.startTime = moment(obj.startTime).format("YYYY-MM-DD") + " 00:00:00";
                        obj.endTime = moment(obj.endTime).format("YYYY-MM-DD") + " 23:59:59";
                        var startDate = moment(obj.startTime);
                        var endDate = moment(obj.endTime);

                        var d = moment();
                        if (d.unix() > startDate.unix()) {
                            d = moment();
                        }
                        else {
                            d = startDate;
                        }
                        // alert(startDate.getTime()+","+d.getTime()+","+endDate.getTime())
                        for (
                            d; d.unix() <= endDate.unix() && countDay < 8;
                            d = d.add(1, "days")
                        ) {
                            console.log(d.format("YYYY-MM-DD") + '-->' + countDay)
                            // console.log(moment(d).format("YYYY-MM-DD"))
                            if (festival.indexOf(d.format("MM-DD")) === -1) {
                                //从今天开始往后的   日期范围内的    
                                var temp = d.format("YYYY-MM-DD")
                                var now = moment().format("YYYY-MM-DD")
                                var day = parseInt(d.day());
                                //  ;
                                // console.log(moment(now).isBefore(temp)+'-->'+now+'  '+temp)

                                if ((moment(now).isBefore(temp) || temp === now) &&
                                    ((day !== 0) ||
                                        // ((day !== 0 && weeks[day - 1].length > 0) ||
                                        (day === 0 && weeks[6].length > 0))) {
                                    if (day !== 0) dateList.push({ date: d.format("YYYY-MM-DD"), week: day })
                                    else dateList.push({ date: d.format("YYYY-MM-DD"), week: 7 })
                                }
                                countDay++;


                            }
                        }
                    })

                    // alert(dateList.length)
                    that.setState({ dateList, weeks, btnState: false }, () => {
                        if (mark === 2) {
                            var temp = "", weekName = ""
                            console.log(that.state.dateList, that.state.weeks)
                            that.state.dateList.forEach(item => {
                                if (item.date === that.state.selectedDate) {
                                    temp = item.week;
                                    if (item.week === 1) weekName = "周一";
                                    if (item.week === 2) weekName = "周二";
                                    if (item.week === 3) weekName = "周三";
                                    if (item.week === 4) weekName = "周四";
                                    if (item.week === 5) weekName = "周五";
                                    if (item.week === 6) weekName = "周六";
                                    if (item.week === 7) weekName = "周日";
                                    console.log('是', weekName)
                                }
                            })
                            that.setState({ timeList: that.state.weeks[temp - 1], weekName })
                        }
                    })

                }
            })

        })

    }
    oneducationChange = (value) => {
        this.setState({ education: value })
    }
    onDateChange = (value) => {
        console.log(value)
        this.setState({ date: value, timeList: [], detailId: "", time: "", syme: null ,selectedDate: value }, () => {

            this.loadDatas(this.state.org, this.state.phy, 2)
        })
        // var temp = "", weekName = ""
        // console.log(this.state.dateList, this.state.weeks)
        // this.state.dateList.forEach(item => {
        //     if (item.date === value) {
        //         temp = item.week;
        //         if (item.week === 1) weekName = "周一";
        //         if (item.week === 2) weekName = "周二";
        //         if (item.week === 3) weekName = "周三";
        //         if (item.week === 4) weekName = "周四";
        //         if (item.week === 5) weekName = "周五";
        //         console.log('是', weekName)

        //     }
        // })
        // this.setState({ timeList: this.state.weeks[temp - 1], weekName })

        // , () => {
        //     Datas.forEach(item => {
        //         if (item.id !== null && item.id === Number(value)) {
        //             times = item.appointmentDateDetailDTOS;
        //             return;
        //         }
        //     })
        //     that.setState({ timeList: times })
        // }

    }
    ondetailIdChange = (value) => {
        var { timeList ,date} = this.state;
        console.log(value);
        var temp="";
        timeList.forEach(item => {
            if (item.id === Number(value)) {
                temp=`${item.startTime.substring(11, 19)}-${item.endTime.substring(11, 19)}`
                this.setState({
                    time: `${item.startTime.substring(11, 19)}-${item.endTime.substring(11, 19)}`
                })
            }
        })
        this.props.getUserGroupNumber({ detailId: value,timeStr:date+" " +temp }).then(msg => {
            if (msg.data.code === 200) {
                this.setState({
                    ready_FIRST: msg.data.data.first,
                    ready_SECOND: msg.data.data.second,
                    ready_THIRD: msg.data.data.third,
                }, () => {
                    this.setState({ detailId: value }, () => {
                        var reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;

                        if (reg.test(this.state.idCard)) {
                            this.getCount();
                        }
                        else if (this.state.idCard || this.state.idCard.length === 0) {
                            message.warn("请输入身份证号以便获取名额数量！");
                            return;
                        }
                        else {
                            message.error("身份证格式错误！");
                            message.error("无法获取相应年龄段名额！");
                            return;
                        }
                    })
                   
                })
            }
            else {
                message.error(msg.data.message);
            }
        })

    }
    onOccupation1Change = (value) => {
        var that = this;
        this.setState({ occupation1: value, occupation2: "" })
        var { occupationList } = this.state;
        occupationList.forEach(item => {
            if (item.value === value) {
                that.setState({ occupationList2: item.children })
            }
        })
    }
    onOccupation2Change = (value) => {
        this.setState({ occupation2: value })
    }
    GetAge = (strBirthday, date) => {
        var returnAge,
            strBirthdayArr = strBirthday.split("-"),
            birthYear = strBirthdayArr[0],
            birthMonth = strBirthdayArr[1],
            birthDay = strBirthdayArr[2],
            d = moment(date, "YYYY-MM-DD"),
            nowYear = d.format("YYYY"),
            nowMonth = d.format("MM"),
            nowDay = d.format("DD");
        if (nowYear === birthYear) {
            returnAge = 0;//同年 则为0周岁
        }
        else {
            var ageDiff = nowYear - birthYear; //年之差
            if (ageDiff > 0) {
                if (nowMonth === birthMonth) {
                    var dayDiff = nowDay - birthDay;//日之差
                    if (dayDiff < 0) {
                        returnAge = ageDiff - 1;
                    } else {
                        returnAge = ageDiff;
                    }
                } else {
                    var monthDiff = nowMonth - birthMonth;//月之差
                    if (monthDiff < 0) {
                        returnAge = ageDiff - 1;
                    }
                    else {
                        returnAge = ageDiff;
                    }
                }
            } else {
                returnAge = -1;//返回-1 表示出生日期输入错误 晚于今天
            }
        }
        return returnAge;//返回周岁年龄
    }
    submit = () => {
        var that = this;
        var {   date, time, detailId, username, idCard, phone, workUnit, town, county, village, team,       } = this.state
        // console.log(date, time)
        if (idCard.length !== 18) {
            message.warn("身份证格式错误")
            return;
        }
        if (detailId.length === 0 || date.length === 0 || time.length === 0 || username.length === 0 || idCard.length === 0 || phone.length === 0) {
            message.warn('必填项未填写完整！')
            return;
        }
        var obj = {
            appointmentDetailId: Number(detailId),
            username,
            idCard,
            phone,
            appointmentDate: `${date} ${time}`
        }
        if (workUnit.length > 0) obj.workUnit = workUnit;
        if (town.length > 0) obj.town = town;
        if (county.length > 0) obj.county = county;
        if (village.length > 0) obj.village = village;
        if (team.length > 0) obj.team = team;
        console.log(obj)
        this.props.appointmentSave(obj).then(msg => {
            if (msg.data.code === 200) {
                message.info('预约成功！')
                that.props.history.push('/appointQueryList?idCard=' + idCard)
                // that.loadDatas(erweimaOrg,erweimaPhy)
                // that.setState({
                //     weekName:"",
                //     detailId: "",
                //     date: "",
                //     time: "",
                //     syme: null,
                //     username: "",
                //     phone: "",
                //     idCard: "",
                //     workUnit: "",
                //     county: "",
                //     team: "",
                //     village: "",
                //     town: "",
                //     height: "",
                //     weight: "",
                //     education: "",
                //     occupation: "",
                //     occupation1: "",
                //     occupation2: "",
                //     sex: ""
                // })
            }
            else {
                message.warn(msg.data.message)
            }
        })
    }
    usernameChange = (e) => {
        this.setState({
            username: e.target.value.trim()
        })
    }
    idCardChange = (e) => {
        var reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;       
        var idCard = e.target.value.trim()
        var that=this;
        this.setState({ age: undefined, sex: '', syme:null}, () => {
            var age = this.getAgeByIdCard(idCard)
            that.setState({
                idCard: idCard,
                age: age
            },()=>{
                  if (idCard.length === 18) {
                if (reg.test(idCard) === false)
                    message.warn("身份证号格式错误");
                else {
                    if (parseInt(idCard.slice(-2, -1)) % 2 === 1) {
                        that.setState({
                            sex: "男"
                        })
                    }
                    else {
                        that.setState({
                            sex: "女"
                        })
                    }

                }
                if (that.state.detailId && that.state.detailId.length > 0) {
                    that.getCount();
                }
            }
            })
          
        })


    }
    getCount = () => {
        var { age, sex, ready_FIRST, ready_SECOND, ready_THIRD, SETTING_FIRST, SETTING_SECOND, SETTING_THIRD } = this.state;
        console.log(age)
        if(!SETTING_FIRST || !SETTING_SECOND || !SETTING_THIRD){
            message.warn("该机构未设置配置文件！");
            this.setState({
                syme: 0,
            })
            return;
        }
        if (age >= SETTING_FIRST.MIN_AGE && age <= SETTING_FIRST.MAX_AGE) {
            this.setState({
                syme: sex === "男" ? Number(SETTING_FIRST.MAN_COUNT - ready_FIRST.manNumber) : Number(SETTING_FIRST.WOMAN_COUNT - ready_FIRST.woManNumber),
            })
        }
        else if (age >= SETTING_SECOND.MIN_AGE && age <= SETTING_SECOND.MAX_AGE) {
            this.setState({
                syme: sex === "男" ? Number(SETTING_SECOND.MAN_COUNT - ready_SECOND.manNumber) : Number(SETTING_SECOND.WOMAN_COUNT - ready_SECOND.woManNumber),
            })
        }
        else if (age >= SETTING_THIRD.MIN_AGE && age <= SETTING_THIRD.MAX_AGE) {
            this.setState({
                syme: sex === "男" ? Number(SETTING_THIRD.MAN_COUNT - ready_THIRD.manNumber) : Number(SETTING_THIRD.WOMAN_COUNT - ready_THIRD.woManNumber),
            })
        }
        else {
            this.setState({
                syme: 0,
            })
        }
    }
    getAgeByIdCard = (UUserCard) => {
        //获取年龄
        var myDate = new Date();
        var month = myDate.getMonth() + 1;
        var day = myDate.getDate();
        var age = myDate.getFullYear() - UUserCard.substring(6, 10) - 1;
        if (UUserCard.substring(10, 12) < month || (UUserCard.substring(10, 12) === month && UUserCard.substring(12, 14) <= day)) {
            age++;
        }
console.log(age)
        return age;
    }
    workUnitChange = (e) => {
        this.setState({
            workUnit: e.target.value
        })
    }
    phoneChange = (e) => {
        this.setState({
            phone: e.target.value
        })
    }
    townChange = (e) => {
        this.setState({
            town: e.target.value
        })
    }
    countyChange = (e) => {
        this.setState({
            county: e.target.value
        })
    }
    villageChange = (e) => {
        this.setState({
            village: e.target.value
        })
    }
    teamChange = (e) => {
        this.setState({
            team: e.target.value
        })
    }
    heightChange = (e) => {
        this.setState({
            height: e.target.value
        })
    }
    weightChange = (e) => {
        this.setState({
            weight: e.target.value
        })
    }

    isLastCheckChange = value => {
        this.setState({
            btnState: value
        })
    }
    render() {
        const { weekName, syme, timeList, detailId, date, dateList, 
            pageTitle, username, idCard, workUnit, phone, town, county, village, team,
        } = this.state;

        return (
            <div>
                <div className="StatisticsPage-head1 pageHeader1"><h3>{pageTitle}预约</h3></div>
                <div className="StatisticsPage-box1 has-select-table">
                    <div className="StatisticsPage-search">
                        <div className="form-group">
                            <label className="form-label1 require">姓名：</label>
                            <div className="form-ipt">
                                <Input placeholder="姓名" value={username} onChange={e => this.usernameChange(e)} />
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="form-label1 require">身份证号：</label>
                            <div className="form-ipt">
                                <Input placeholder="身份证号" value={idCard} onChange={e => this.idCardChange(e)} />
                            </div>
                        </div>
                        {this.state.sex.length > 0 &&
                            <div className="form-group">
                                <label className="form-label1">性别/年龄：</label>
                                <div className="form-ipt">
                                    {`${this.state.sex}   /    ${this.state.age} `}
                                </div>
                            </div>}
                        <div className="form-group">
                            <label className="form-label1  require">日期：</label>
                            <div className="form-ipt">
                                <Select className="ipt1" allowClear={true} value={date} onChange={this.onDateChange} >
                                    {dateList.map((item, index) => {
                                        return <Option key={item.date}>{item.date}</Option>
                                    })}
                                </Select>
                                {weekName.length > 0 && <span>({weekName})</span>}
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="form-label1  require">时间点：</label>
                            <div className="form-ipt">
                                <Select className="ipt1" value={detailId} onChange={this.ondetailIdChange} >
                                    {timeList.map((date, index) => {
                                        return <Option key={date.id}>{date.startTime.substring(11, 16)}~{date.endTime.substring(11, 16)}</Option>
                                    })}
                                </Select>
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="form-label1">剩余名额：</label>
                            <div className="form-ipt">
                                {syme == null || syme > 0 ? <span style={{ color: 'green' }}>{syme}</span> : <span style={{ color: 'red' }}>暂无名额</span>}
                            </div>
                        </div>


                        <div className="form-group">
                            <label className="form-label1">所在单位：</label>
                            <div className="form-ipt">
                                <Input placeholder="所在单位" value={workUnit} onChange={e => this.workUnitChange(e)} />

                            </div>
                        </div>
                        <div className="form-group">
                            <label className="form-label1 require">联系电话：</label>
                            <div className="form-ipt">
                                <Input placeholder="联系电话" value={phone} onChange={e => this.phoneChange(e)} />

                            </div>
                        </div>
                        <div className="form-group">
                            <label className="form-label1">现住址：</label>
                            <div className="form-ipt">
                                <Input placeholder="街道" value={town} onChange={e => this.townChange(e)} style={{ marginBottom: "5px" }} />
                                <Input placeholder="社区" value={county} onChange={e => this.countyChange(e)} style={{ marginBottom: "5px" }} />
                                <Input placeholder="村" value={village} onChange={e => this.villageChange(e)} style={{ marginBottom: "5px" }} />
                                <Input placeholder="门牌" value={team} onChange={e => this.teamChange(e)} style={{ marginBottom: "5px" }} />
                            </div>
                        </div>
                        <Button type="primary" style={{ width: "100%" }} disabled={this.state.btnState || syme <= 0} onClick={this.submit}>确定提交</Button>

                    </div>
                </div>
            </div>
        )
    }
}
export default connect(null, {
    appointmentPageDetail2, appointmentSave, getUserGroupNumber
})(main)