import React, { Component } from 'react'
import {  Table } from 'antd';
import { connect } from 'react-redux'
import {organizationStatistics } from '../../../actions/account'


const { Column } = Table;
class OrganizationStatistics extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataList:[]
          
        }
    }

    componentDidMount() {
     this.loadDatas()
    }

    loadDatas=()=>{
        var that = this;
        this.props.organizationStatistics().then(res=>{
            if(res.data.code ===200){
                that.setState({
                    dataList:[res.data.data]
                })
            }
        })
    }
  
    render() {
        const {  dataList } = this.state;
      
        return (
            <div>
                <div className="StatisticsPage-head pageHeader"><h3>挂号人员统计</h3></div>
                <div className="StatisticsPage-box has-select-table">
                    
                    <Table dataSource={dataList}  size="middle"  childrenColumnName="childOrganization" bordered>
                    <Column title="机构名称" dataIndex="organizationName" key="organizationName" align="center" />
                    <Column title="本机构体检人数" dataIndex="physicalNumber" key="physicalNumber" align="center" />
                    <Column title="子机构体检人数" dataIndex="childPhysicalNumber" key="childPhysicalNumber" align="center" />
                    </Table>                
                </div>
              

            </div>
        )
    }
}
export default connect(null, {
    organizationStatistics
})(OrganizationStatistics)               