import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  Modal,
  Button,
  Table,
  Input,
  message,
  Divider,
  Tabs,
  Select,
  DatePicker,
} from 'antd'
import './DCM.css'
import moment from 'moment'

import {
  pissResult,
  pissResultimportExcel,
  pissResultremove,
  pissResultupdate,
} from '../../../actions/account'
const dateFormat = 'YYYY-MM-DDHHmmss'
const { confirm } = Modal
const { RangePicker } = DatePicker
const { TabPane } = Tabs
const { Column } = Table
const { TextArea } = Input
const { Option } = Select
class pissResultPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      applyNo: '',

      size: 10,
      dataList: [],
      page: 1,
      total: 0,
      title: '',
      subTitle: '',
      currId: undefined,
      type: '',
      startTime: undefined,
      endTime: undefined,
      choosedDates: [],
      selectedRowKeys: [],
      barcode: '',
      currentId: undefined,
      visible: false,
    }
  }

  componentDidMount() {
    this.loadDatas()
  }

  loadDatas = () => {
    var { page, size, applyNo, startTime, endTime } = this.state
    var that = this
    var obj = {
      page: page - 1,
      size: size,
      barcode: applyNo,
    }
    if (startTime !== undefined) {
      obj.startTime = startTime
    }
    if (endTime !== undefined) {
      obj.endTime = endTime
    }
    this.props.pissResult(obj).then((res) => {
      if (res.data.code === 200) {
        that.setState({
          dataList: res.data.data.rows,
          total: res.data.data.total,
        })
      }
    })
  }

  keyWordOnChange = (e) => {
    this.setState({ applyNo: e.target.value })
  }
  typeChange = (e) => {
    console.log(e)
    this.setState({ type: e })
  }

  changePage = (page) => {
    var that = this
    this.setState({ page }, () => {
      that.loadDatas()
    })
  }
  pageSizeOnChange = (current, size) => {
    var that = this
    this.setState({ pageSize: size }, () => {
      that.loadDatas()
    })
  }
  datetimeChange = (date, dateString) => {
    this.setState({
      startTime: dateString[0] === '' ? undefined : `${dateString[0]} 00:00:00`,
      endTime: dateString[1] === '' ? undefined : `${dateString[1]} 23:59:59`,
      choosedDates: date,
    })
  }
  onSelectChange = (selectedRowKeys) => {
    // var { dataList } = this.state;
    this.setState({ selectedRowKeys })
  }
  delItem = (id) => {
    var that = this
    confirm({
      content: '确认删除？',
      onOk() {
        that.props.pissResultremove({ id: id }).then((msg) => {
          if (msg.data.code === 200) {
            message.success('删除成功！')
            that.loadDatas()
          }
        })
      },
    })
  }
  exportBmodel = () => {
    var { startTime, endTime, idCard } = this.state
    console.log(startTime, endTime)
    if (!startTime || !endTime) {
      message.warn('请选择起始时间!')
      return
    }
    var obj = { startTime, endTime }
    if (!!!idCard) {
      obj.idCard = idCard
    }
    this.props.pissResultimportExcel(obj).then((res) => {
      const fileName = 'export' + moment().format('YYYYMMDDHHmmss')
      if ('download' in document.createElement('a')) {
        // 非IE下载
        const url = window.URL.createObjectURL(
          new Blob(
            [res.data],
            // 设置该文件的mime类型，这里对应的mime类型对应为.xlsx格式
            { type: 'application/vnd.ms-excel' }
          )
        )
        const link = document.createElement('a')
        link.href = url
        // 从header中获取服务端命名的文件名
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
      } else {
        // IE10+下载
        navigator.msSaveBlob(res.data, fileName)
      }
    })
  }
  serchBtn = () => {
    this.setState({ page: 1 }, () => {
      this.loadDatas()
    })
  }
  barcodeOnChnage = (e) => {
    this.setState({ barcode: e.target.value })
  }
  handleBindOk = () => {
    var { barcode, currentId } = this.state
    var that = this
    var obj = { barcode: barcode }
    obj.id = currentId
    this.props.pissResultupdate(obj).then((res) => {
      if (res.data.code === 200) {
        message.success('修改成功！')
        that.setState({
          visible: false,
          barcode: '',
          currentId: undefined,
        })
        that.loadDatas()
      }
    })
  }
  handleBindCancel = () => {
    this.setState({
      visible: false,
      barcode: '',
      currentId: undefined,
    })
  }
  editItem = (record) => {
    this.setState({
      visible: true,
      barcode: record.barcode,
      currentId: record.id,
    })
  }
  render() {
    const {
      dataList,
      applyNo,
      choosedDates,
      selectedRowKeys,
      barcode,
      visible,
    } = this.state
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    }
    return (
      <div>
        <div className="StatisticsPage-head pageHeader">
          <h3>中旗心电图数据列表</h3>
        </div>
        <div className="StatisticsPage-box">
          <div className="StatisticsPage-search">
            <div className="StatisticsPage-btn">
              <RangePicker
                dateFormat="YYYY-MM-DD"
                placeholder={['开始时间', '结束时间']}
                onChange={this.datetimeChange}
                value={choosedDates}
                className="date"
              />
              <Input
                className="ipt"
                placeholder="请输入条码号"
                value={applyNo}
                style={{ width: 100 }}
                onChange={this.keyWordOnChange}
              />

              <Button className="btn" type="primary" onClick={this.serchBtn}>
                搜索
              </Button>
              <Button
                className="btn"
                type="primary"
                onClick={this.exportBmodel}
              >
                导出
              </Button>
            </div>
          </div>
          <Modal
            width="500px"
            title="修改条码号"
            visible={visible}
            onOk={this.handleBindOk}
            onCancel={this.handleBindCancel}
            okText="保存"
            cancelText="取消"
          >
            <div className="form-group">
              <label className="form-label">条码号：</label>
              <div className="form-ipt" style={{ marginTop: '5px' }}>
                <Input value={barcode} onChange={this.barcodeOnChnage}></Input>
              </div>
            </div>
          </Modal>
          <div>
            <Table
              size="middle"
              dataSource={dataList}
              bordered
              rowKey="id"
              pagination={{
                pageSize: this.state.size,
                showTotal: (total) => {
                  return `共 ${total} 条`
                },
                onChange: this.changePage,
                total: this.state.total,
                showSizeChanger: true,
                onShowSizeChange: this.pageSizeOnChange,
              }}
              rowSelection={rowSelection}
            >
              <Column
                title="条码号"
                dataIndex="barcode"
                key="barcode"
                align="center"
              />
              <Column
                title="姓名"
                dataIndex="username"
                key="username"
                align="center"
              />
              <Column
                title="身份证号"
                dataIndex="idCard"
                key="idCard"
                align="center"
              />
              <Column title="样本编号" dataIndex="no" key="no" align="center" />
              <Column
                title="录入时间"
                dataIndex="entryDate"
                key="entryDate"
                align="center"
              />
              <Column
                title="创建时间"
                dataIndex="createdAt"
                key="createdAt"
                align="center"
              />
              <Column
                title="操作"
                align="center"
                key="action"
                render={(text, record) => (
                  <span>
                    <span
                      style={{ cursor: 'pointer' }}
                      onClick={() => this.editItem(record)}
                    >
                      修改
                    </span>
                    <Divider type="vertical" />
                    <span
                      style={{ cursor: 'pointer' }}
                      onClick={() => this.delItem(record.id)}
                    >
                      删除
                    </span>
                  </span>
                )}
              />
            </Table>
          </div>
        </div>
      </div>
    )
  }
}
export default connect(null, {
  pissResult,
  pissResultimportExcel,
  pissResultremove,
  pissResultupdate,
})(pissResultPage)
