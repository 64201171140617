
export const SETTINGS = [
    {
        ORGID: "1a97797659154a7ca25b681815bc8fac",
        FIRST: { MAN_COUNT: 8, WOMAN_COUNT: 8, MIN_AGE: 18, MAX_AGE: 34 },//18~34
        SECOND: { MAN_COUNT: 11, WOMAN_COUNT: 11, MIN_AGE: 35, MAX_AGE: 59 },//35~59
        THIRD: { MAN_COUNT: 5, WOMAN_COUNT: 5, MIN_AGE: 60, MAX_AGE: 150 },//>=60              
    },
    {
        ORGID: "521c44b33fd14a009faf3098f7511e3f",
        FIRST: { MAN_COUNT: 15, WOMAN_COUNT: 15, MIN_AGE: 18, MAX_AGE: 34 },//18~34
        SECOND: { MAN_COUNT: 21, WOMAN_COUNT: 21, MIN_AGE: 35, MAX_AGE: 59 },//35~59
        THIRD: { MAN_COUNT: 10, WOMAN_COUNT: 10, MIN_AGE: 60, MAX_AGE: 150 },//>=60              
    },
    {
        ORGID: "464f79c6db2c40e2ab6c18b49663262c",
        FIRST: { MAN_COUNT: 15, WOMAN_COUNT: 15, MIN_AGE: 18, MAX_AGE: 34 },//18~34
        SECOND: { MAN_COUNT: 21, WOMAN_COUNT: 21, MIN_AGE: 35, MAX_AGE: 59 },//35~59
        THIRD: { MAN_COUNT: 10, WOMAN_COUNT: 10, MIN_AGE: 60, MAX_AGE: 150 },//>=60              
    },
    {
        ORGID: "e8e28688e37d4ea39b107aa4b25c459e",
        FIRST: { MAN_COUNT: 15, WOMAN_COUNT: 15, MIN_AGE: 18, MAX_AGE: 34 },//18~34
        SECOND: { MAN_COUNT: 21, WOMAN_COUNT: 21, MIN_AGE: 35, MAX_AGE: 59 },//35~59
        THIRD: { MAN_COUNT: 10, WOMAN_COUNT: 10, MIN_AGE: 60, MAX_AGE: 150 },//>=60              
    }
    
    
]