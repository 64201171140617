import React, { Component } from 'react'
import './WupinPage.css'
import { Modal, Button, Input, Table, Empty, message, Spin,DatePicker,Checkbox } from 'antd';
import { connect } from 'react-redux'
import _ from 'lodash'
import JsBarcode from 'jsbarcode';

import moment from 'moment';
import { exportItemRegistrationList, getItemRegistrationList, itemRegistrationDelete,
    getPhysicalTypeList, getAllorganization, guahaoDel,itemsExport } from '../../../actions/account'

const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';
const { Column } = Table;
// const { Option } = Select;
const { confirm } = Modal;
class StatisticsPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            phyId: '',selectedRowInfoKeys:[],
            physicalTypeList: [],
            guahaoList: [],
            organizationList: [],
            page: 1,
            total: 0,
            dataList: [],
            organList: [],
            unitname: '',
            barcode: '',
            itemsName: '',
            startTime: undefined,
            endTime: undefined,
            choosedDates: [],//时间默认值
            idcard: '',
            pageSize: 10,
            selectedRowKeys: [],location:'',
            organId: undefined,
            spinning: false,printRecordList:[],cbValue:false
        }
    }

    componentDidMount() {
        this.loadDatas()
    }
    exportExcel = () => {
        var {  startTime, endTime, barcode,itemsName,unitname,location } = this.state;
        // var that = this;
        var obj = {}
        if(unitname!==undefined&&unitname!==''&&unitname!==null){
            obj.unit= unitname;
        }
        if(location!==undefined&&location!==''&&location!==null){
            obj.locations= location;
        }
        if (startTime!==undefined) {
           obj.startTime=startTime
        }
        if(endTime!==undefined){
            obj.endTime=endTime
        }
        if (barcode && barcode.length > 0) {
            obj.barcode = barcode
        }
        if (itemsName && itemsName.length > 0) {
            obj.itemsName = itemsName
        }
        console.log(obj)
        this.props.itemsExport(obj).then(res => {

            const fileName = "export" + moment().format("YYYYMMDDHHmmss");
            if ('download' in document.createElement('a')) { // 非IE下载
                const url = window.URL.createObjectURL(new Blob([res.data],
                    // 设置该文件的mime类型，这里对应的mime类型对应为.xlsx格式                                                   
                    { type: 'application/vnd.ms-excel' }));
                const link = document.createElement('a');
                link.href = url;
                // 从header中获取服务端命名的文件名
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
            }
            else {
                // IE10+下载
                navigator.msSaveBlob(res.data, fileName)
            }

        })
    }
    reset = () => {
        this.setState({
            barcode: undefined,
            itemsName: undefined,
            choosedDates: [],
            startTime: undefined,
            endTime: undefined,
        }, () => {
            this.loadDatas()
        })
    }
    loadDatas = () => {
        this.setState({
            spinning: true
        })
        var {startTime,endTime, page,barcode, itemsName, pageSize,unitname,location} = this.state;
        var that = this;
        var obj = {
            page: page - 1,
            size: pageSize
        }    
        if(unitname!==undefined&&unitname!==''&&unitname!==null){
            obj.unit= unitname;
        }
        if(location!==undefined&&location!==''&&location!==null){
            obj.c= location;
        }
        if (startTime !== '' && startTime !== undefined) {
            obj.startTime = startTime;
        }   
        if (endTime !== '' && endTime !== undefined) {
            obj.endTime = endTime;
        }
        if (barcode !== '' && barcode !== undefined) {
            obj.barcode = barcode
        }
        if (itemsName !== '' && itemsName !== undefined) {
            obj.itemsName = itemsName;
        }
        this.props.getItemRegistrationList(obj).then(msg => {
            that.setState({
                spinning: false
            })
            if (msg.data.code === 200) {
                if (msg.data.data.rows.length === 0 && msg.data.data.total !== 0) {
                    that.setState({ page: page - 1 }, () => {
                        that.loadDatas()
                    })
                }
                else {
                    that.setState({
                        dataList: msg.data.data.rows,
                        total: msg.data.data.total
                    })
                }
            }
        })
    }


    barcodeOnChange = e => {
        this.setState({
            barcode: e.target.value
        })
    }

    nameOnChange = e => {
        this.setState({
            itemsName: e.target.value
        })
    }

    

    delItem = (id) => {
        var { selectedRowKeys } = this.state;
        if (selectedRowKeys.length === 0) {
            message.error("请选择删除的记录")
            return;
        }
        var that = this;
        confirm({
            content: "确认删除？",
            onOk() {

                var That = that;
                that.props.itemRegistrationDelete({ ids: selectedRowKeys.join(",") }).then(msg => {
                    if (msg.data.code === 200) {
                        message.success("删除成功！");
                        That.loadDatas();
                    }

                })
            }
        });

    }

    editItem = () => {
        var { selectedRowKeys, dataList } = this.state;
        if (selectedRowKeys.length === 0) {
            message.error("请选择编辑记录")
            return;
        }
        if (selectedRowKeys.length > 1) {
            message.error("选择记录过多，请选择单条记录")
            return;
        }
        var curRecord = _.find(dataList, ['id', selectedRowKeys[0]]);
        this.props.history.push({
            pathname: "/admin/medicalRecord", state: {
                idCard: curRecord.idCard
            }
        });
    }

    printItem = () => {
        var { selectedRowKeys, dataList } = this.state;
        if (selectedRowKeys.length === 0) {
            message.error("请选择打印记录")
            return;
        }
        var curRecord = _.find(dataList, ['recordId', selectedRowKeys[0]]);
        if (curRecord === undefined) {
            return;
        }
        window.open("/print/CheckInfo?username=" + curRecord.username + "&sex=" + curRecord.sex +
            "&birthDate=" + curRecord.birthDate + "&barcode=" + curRecord.barcode + "&infoCreatedAt="
            + curRecord.infoCreatedAt + "&phone=" + curRecord.phone + "&workUnit=" + curRecord.workUnit, "_blank");
    }

    changePage = page => {
        var that = this;
        this.setState({ page }, () => {
            that.loadDatas()
        })
    }

    pageSizeOnChange = (current, size) => {
        var that = this;
        this.setState({ pageSize: size, selectedRowKeys: [] }, () => {
            that.loadDatas();
        })
    }

    // 选中改变
    onSelectChange = selectedRowKeys => {
        console.log(selectedRowKeys)
        this.setState({ selectedRowKeys,selectedRowInfoKeys:selectedRowKeys });
    };

    datetimeChange = (date, dateString) => {
        // var choseDate =[];
        // choseDate = _.concat(choseDate,moment(dateString[0]+" 00:00:00","YYYY-MM-DD hh:mm:ss"));
        // choseDate = _.concat(choseDate,moment(dateString[1]+" 23:59:59","YYYY-MM-DD hh:mm:ss"));
        this.setState({
            startTime: dateString[0] === "" ? undefined : dateString[0] + " 00:00:00",
            endTime: dateString[1] === "" ? undefined : dateString[1] + " 23:59:59",
            choosedDates: date,
        })
    }
    barcodePrint = () => {
        var { selectedRowInfoKeys, dataList } = this.state;
        if (selectedRowInfoKeys.length === 0) {
            message.error("请选择记录")
            return;
        }
        var that = this;
        var barcodeTEMP = "";
        var tempList = [];
        selectedRowInfoKeys.forEach((item, index) => {
            tempList.push(_.find(dataList, ['id', item]))
            that.setState({ printRecordList: tempList }, () => {
                barcodeTEMP = _.find(dataList, ['id', item]).barcode
                JsBarcode(this["BARCODE1_" + barcodeTEMP], barcodeTEMP, {
                    text: barcodeTEMP,
            format: "CODE128",
            displayValue: true,
            width: 1,
            height: 50,
            fontSize: 10,
            margin: 0,
            textAlign: "left",
            textMargin: 0
                });
                if(this.state.cbValue){
                    JsBarcode(this["BARCODE2_" + barcodeTEMP], barcodeTEMP, {
                        text: barcodeTEMP,
                        format: "CODE128",
                        displayValue: true,
                        width: 1,
                        height: 50,
                        fontSize: 10,
                        margin: 0,
                        textAlign: "left",
                        textMargin: 0
                    });
                    
                }
            })
        })
        setTimeout(() => { that.preview(); }, 1000)

}
 /**
    * 打印条码
    */
  preview = (barcode = null) => {
    // JsBarcode(this.barcode, barcode, {
    //     text: barcode,
    //     format: "CODE39",
    //     displayValue: true,
    //     width: 1,
    //     height: 50,
    //     fontSize: 10,
    //     margin: 0,
    //     textAlign: "left",
    //     textMargin: 0
    // });
    // JsBarcode(this.barcode1, barcode, {
    //     text: barcode,
    //     format: "CODE39",
    //     displayValue: true,
    //     width: 1,
    //     height: 50,
    //     fontSize: 10,
    //     margin: 0,
    //     textAlign: "left",
    //     textMargin: 0
    // });
    const printDiv = document.getElementById('printDiv');
    const iframe = document.createElement('IFRAME');
    let doc = null;
    iframe.setAttribute('style', 'position:absolute;width:0px;height:0px;left:0px;top:0px;');
    document.body.appendChild(iframe);
    doc = iframe.contentWindow.document;
    // 打印时去掉页眉页脚
    doc.write('<style media="print">@page {size: auto;  margin: 0mm; }</style>');
    doc.write(printDiv.innerHTML);
    doc.close();
    // 获取iframe的焦点，从iframe开始打印
    iframe.contentWindow.focus();
    iframe.contentWindow.print();
    if (navigator.userAgent.indexOf("MSIE") > 0) {
        //打印完删除iframe
        document.body.removeChild(iframe);
    }

}
cbonChange=e=>{
    this.setState({
        cbValue:e.target.checked
    })
}

unitnameOnChange=(e)=>{
    this.setState({
        unitname:e.target.value
    })
}

locationOnChange=e=>{
    this.setState({
        location:e.target.value
    })
}
    render() {
        const { dataList, barcode, itemsName,spinning,choosedDates,selectedRowKeys,cbValue,unitname} = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        return (
            <div>
                <div className="StatisticsPage-head pageHeader"><h3>物品环境信息统计</h3></div>
                <div className="StatisticsPage-box has-select-table">
                <div id="printDiv" style={{ display: 'none' }} >
                    {this.state.printRecordList.map((record,index) => <div key={index} style={{ 'display': 'block', 'overflow': 'hidden' }}>
                            <div style={{ 'height':'110px' }}>
                                <p style={{ 'fontSize': 15,  padding: 0, margin: 0 }}>{(record.itemsName.length>8?record.itemsName.substring(0,10)+"...":record.itemsName) } </p>
                                {/* <p style={{ 'fontSize': 10,  padding: 0, margin: 0 }}>  {record.idCard}</p> */}
                                <div style={{ 'fontSize': 0 }}>
                                    <svg
                                        ref={(ref) => {
                                            this["BARCODE1_" + record.barcode] = ref;
                                        }}
                                    />
                                </div>
                                <p style={{ 'fontSize': 15,  padding: 0, margin: 0 }}>  {record.unit !== null && record.unit+"   "}{record.locations !== null && record.locations}</p>
                                
                            </div>
                            {cbValue&&<div style={{ 'height':'110px'  }}>
                            <p style={{ 'fontSize': 15,  padding: 0, margin: 0 }}>{(record.itemsName.length>8?record.itemsName.substring(0,10)+"...":record.itemsName) } </p>
                            
                                {/* <p style={{ 'fontSize': 10,  padding: 0, margin: 0 }}>  {record.idCard}</p> */}
                                <div style={{ 'fontSize': 0 }}>
                                    <svg
                                        ref={(ref) => {
                                            this["BARCODE2_" + record.barcode] = ref;
                                        }}
                                    />
                                </div>
                                <p style={{ 'fontSize': 10,  padding: 0, margin: 0 }}>  {record.unit !== null && record.unit+"   "}{record.locations !== null && record.locations}</p>
                                
                            </div> }
                        </div>)}



                    </div>

                    <div className="StatisticsPage-search">
                        <div className="StatisticsPage-btn">
                        <RangePicker dateFormat={dateFormat} placeholder={['开始时间', '结束时间']} className="date"
                                onChange={this.datetimeChange} value={choosedDates} />
                            <Input className="ipt" placeholder="请输入条码" value={barcode}
                                onChange={this.barcodeOnChange} />
                            <Input className="ipt" placeholder="物品名称" value={itemsName}
                                onChange={this.nameOnChange} />
                             <Input className="ipt" placeholder="采样单位" value={unitname}
                                onChange={this.unitnameOnChange} />
                             {/* <Input className="ipt" placeholder="采样地点" value={location}
                                onChange={this.locationOnChange} /> */}
                            <Button className="btn" type="primary" onClick={this.loadDatas}>搜索</Button>
                            <Button className="btn" type="primary" onClick={this.reset}>重置</Button>
                            <Button className="btn" type="primary" onClick={this.exportExcel}>导出</Button>
                            <Button className="btn" type="primary" onClick={this.barcodePrint}>打印条码</Button>
                            <Button className="btn" type="primary" onClick={this.delItem}>删除</Button>
                            <Checkbox onChange={this.cbonChange}>打印两张条码</Checkbox> 
                            {/*
                            <Button className="btn" type="primary" onClick={this.delItem}>删除</Button> */}
                        </div>

                    </div>
                    <Spin tip="Loading..." spinning={spinning}>
                        <div>{dataList.length > 0 ? <Table rowKey="id"
                            onRow={(record, index) => {
                                return {
                                    onClick: () => {
                                        let temp = this.state.selectedRowKeys
                                        let tempinfo = this.state.selectedRowInfoKeys;
                                        let index = temp.indexOf(record.id);
                                        let index1 = tempinfo.indexOf(record.id);
                                        if (index === -1) { temp.push(record.id) }
                                        else { temp.splice(index, 1) }
                                        this.setState({ selectedRowKeys: temp });
                                        if (index1 === -1) { tempinfo.push(record.id) }
                                        else { tempinfo.splice(index1, 1) }
                                        this.setState({ selectedRowInfoKeys: tempinfo })
                                    }
                                }
                            }}
                            dataSource={dataList}
                             rowSelection={rowSelection}
                            pagination={{
                                pageSize: this.state.pageSize, showTotal: ((total) => {
                                    return `共 ${total} 条`;
                                }),
                                onChange: this.changePage, pageSizeOptions: ['10', '20', '50', '100', '500'], total: this.state.total, showSizeChanger: true,
                                onShowSizeChange: this.pageSizeOnChange
                            }} bordered  >
                            <Column width={50} title="条码号" dataIndex="barcode" key="barcode" align="center" 
                               sorter={(a, b) => { return a.barcode > b.barcode ? 1 : -1 }} />
                            <Column width={50} title="名称" dataIndex="itemsName" key="itemsName" align="center" 
                               sorter={(a, b) => { return a.itemsName > b.itemsName ? 1 : -1 }} />
                            <Column width={80} title="采样单位" dataIndex="unit" key="unit" align="center" />
                         
                            <Column width={90} title="采样地点" dataIndex="locations" key="locations" align="center" />
                            <Column width={90} title="物品编号" dataIndex="checkNumber" key="checkNumber" align="center" />
                            <Column width={90} title="联系人" dataIndex="contactName" key="contactName" align="center" />
                            <Column width={90} title="联系方式" dataIndex="contactPhone" key="contactPhone" align="center" />
                            <Column width={90} title="创建时间" dataIndex="createdAt" key="createdAt" align="center" />
                            <Column width={50} title="图片" key="pic" align="center" render={(text, record) => (
                                <span>
                                    <span>{record.pic && record.pic.length>0 ? '展示图片待完善' : '暂无'}</span>
                                </span>
                            )} />
                        </Table> : <Empty />}</div>
                    </Spin>
                </div>

            </div>
        )
    }
}
export default connect(null, {
    getItemRegistrationList, getPhysicalTypeList, getAllorganization, exportItemRegistrationList,
    guahaoDel,itemsExport,itemRegistrationDelete
})(StatisticsPage)               