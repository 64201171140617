import React, { Component } from 'react'
import {  Table, DatePicker, Select, Modal, Input,Button } from 'antd';



import { connect } from 'react-redux'
import { loglist,businessType,logmodules } from '../../../actions/account';
const { Option } = Select;
const { RangePicker } = DatePicker;
const { TextArea } = Input;
const dateFormat = 'YYYY-MM-DD hh:mm:ss';
const { Column } = Table;
class SystemRecord extends Component {
    constructor(props) {
        super(props);
        this.state = {
          businessTypes:[],
          modules:[],
          choosedDates: [],//时间默认值
          startTime: undefined,
          endTime: undefined,
          curBusinessType:undefined,
          curModule:undefined,
          page:1,total:0,visible:false,requestBody:'',responseBody:''
        }
    }
    componentDidMount() {
        var that = this;
        this.props.businessType().then(res=>{
            if(res.data.code===200){
                that.setState({
                    businessTypes:res.data.data
                })
            }
        })
        this.props.logmodules().then(res=>{
            that.setState({
                modules:res.data.data
            })
        })
        this.loadLogs();       
    }

    loadLogs=()=>{
        var {page,curBusinessType,curModule,startTime,endTime} =this.state;
        var obj={};
        obj.page=page-1;
        if(curBusinessType!==undefined){
            obj.businessType= curBusinessType
        }
        if(curModule!==undefined){
            obj.optModule=curModule
        }
        if(startTime!==undefined){
            obj.startTime = startTime
        }
        if(endTime!==undefined){
            obj.endTime= endTime
        }
        var that= this;
        this.props.loglist(obj).then(res=>{
            if(res.data.code===200){
                that.setState({
                    total:res.data.data.total,
                    dataList:res.data.data.rows
                })

            }
        })
    }
 
    businessTypeOnChange=e=>{
        this.setState({
            curBusinessType:e
        })
    }

    moduleOnChange=e=>{
        this.setState({
            curModule:e
        })
    }

    
    changePage = page => {
        var that = this;
        this.setState({ page }, () => {
            that.loadLogs()
        })
    }
    recordOnClick=record=>{
        this.setState({
            visible:true,
            requestBody:record.requestParam.replace(/&quot;/g,"\""),
            responseBody:record.returnResult.replace(/&quot;/g,"\"")
        })

    }

    visibleOnCancel=()=>{
        this.setState({
            visible:false
        })
    }

    render() {
        const { dataList, choosedDates,businessTypes,modules,visible,requestBody,responseBody} = this.state;
      
        return (
            <div>
                <div className="StatisticsPage-head pageHeader"><h3>系统日志</h3></div>
                <div className="StatisticsPage-box">
                <RangePicker dateFormat={dateFormat} className="date"
                    placeholder="请选择日期" onChange={this.datetimeChange} value={choosedDates} />
                <Select showSearch style={{width:300}} placeholder="选择业务类型" allowClear={true} onChange={this.businessTypeOnChange} >
                    {businessTypes.map(businessType=>{
                        return <Option key={"business"+businessType} value={businessType}>{businessType}</Option>
                    })}
                 </Select>
                 <Select style={{width:300}} placeholder="选择操作模块" allowClear={true} onChange={this.moduleOnChange}>
                    {modules.map(module=>{
                        return <Option key={"module"+businessType} value={module}>{module}</Option>
                    })}
                 </Select>
                 <Button className="btn" type="primary" style={{ margin: '0 10px' }} onClick={this.loadLogs}>查找</Button>

                </div>
                <div className="StatisticsPage-box">
                     <div>
                        <Table rowKey='id'   size="middle" dataSource={dataList} pagination={{
                            pageSize: 10, showTotal: ((total) => {
                                return `共 ${total} 条`;
                            }), onChange: this.changePage,  total: this.state.total
                        }} bordered >
                            {/* <Column title="编号" dataIndex="id" key="id" align="center" /> */}
                            <Column  width={100} title="URL" dataIndex="url" key="url" align="center" />
                            <Column  width={50} title="IP地址" dataIndex="ip" key="ip" align="center" />
                            <Column width={50} title="耗时" dataIndex="consumingTime" key="consumingTime" align="center" />
                            <Column width={50} title="操作人" dataIndex="optName" key="optName" align="center" />
                            <Column width={50} title="业务类型" dataIndex="businessType" key="businessType" align="center" />
                            <Column width={50}  title="操作模块" dataIndex="optModule" key="optModule" align="center" />
                            <Column width={50} title="操作时间" dataIndex="createdAt" key="createdAt" align="center" />
                            <Column width={20} title="操作" align="center" key="action"
                                render={(text, record) => (
                                    <span>
                                        <span style={{ cursor: 'pointer' }} onClick={()=>this.recordOnClick(record)}>查看详情</span>
                                       
                                    </span>
                                )} />
                        </Table>
                    </div>
                </div>
                <Modal visible={visible} width={1200} title="请求详情" footer={null} onCancel={this.visibleOnCancel}>
                    <div className="form-group">
                        <label className="form-label">请求体：</label>
                        <div className="form-ipt">
                           <TextArea rows={8} style={{width:800}} value={requestBody} ></TextArea>
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="form-label">响应体：</label>
                        <div className="form-ipt">
                           <TextArea rows={4} style={{width:800}} value={responseBody} ></TextArea>
                        </div>
                    </div>
                </Modal>
                
            </div >
        )
    }
}
export default connect(null, { loglist,businessType,logmodules })(SystemRecord)