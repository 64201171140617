import React, { Component } from 'react'
import './RegistrationPage.css'
import { Modal, Button, Input, Table, Empty, message, Spin, DatePicker } from 'antd';
import { connect } from 'react-redux'
import _ from 'lodash'
import { scaleList ,exportNcovUser } from '../../../actions/account'
import moment from 'moment';

const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD hh:mm:ss';
const { Column } = Table;
const { confirm } = Modal;
class StatisticsPage extends Component {
    constructor(props) {
        super(props);
        this.state = {

            curRecord: undefined,
            resultVisble: false,
            phone: '',
            physicalTypeList: [],
            guahaoList: [],
            organizationList: [],
            page: 1,
            total: 0,
            dataList: [],
            organList: [],
            unitname: '',
            barcode: '',
            uname: '',
            startTime: undefined,
            endTime: undefined,
            choosedDates: [],//时间默认值
            idcard: '',
            pageSize: 10,
            selectedRowKeys: [],
            selectedRowInfoKeys:[],
            workUnit: '',
            spinning: false
        }
    }

    componentDidMount() {
       this.loadDatas();

    }

    onOrganSelectChange = value => {
        this.setState({
            workUnit: value
        })
    }
    onphySelectChange = value => {
        this.setState({
            phone: value
        })
    }
   
    reset = () => {
        this.setState({
            choosedDates: [],
            startTime: undefined,
            endTime: undefined,
            barcode: undefined,
            uname: undefined,
            idCard: undefined,
            workUnit: undefined,
            phone: undefined,
        }, () => {
            this.loadDatas()
        })
    }
    exportExcel = () => {
        var {  startTime, endTime} = this.state;
        // var that = this;
        var obj = {}
        if (startTime!==undefined) {
           obj.startTime=startTime
        }
        if(endTime!==undefined){
            obj.endTime=endTime
        }    
        console.log(obj)
        this.props.exportNcovUser(obj).then(res => {
            const fileName = "export" + moment().format("YYYYMMDDHHmmss");
            if ('download' in document.createElement('a')) { // 非IE下载
                const url = window.URL.createObjectURL(new Blob([res.data],
                    // 设置该文件的mime类型，这里对应的mime类型对应为.xlsx格式                                                   
                    { type: 'application/vnd.ms-excel' }));
                const link = document.createElement('a');
                link.href = url;
                // 从header中获取服务端命名的文件名
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
            }
            else {
                // IE10+下载
                navigator.msSaveBlob(res.data, fileName)
            }

        })
    }
    loadDatas = () => {
        this.setState({
            spinning: true
        })
        var { page, startTime, endTime, barcode, uname, pageSize, idCard, workUnit, phone } = this.state;
        var that = this;
        var obj = {
            page: page - 1,
            size: pageSize
        }
        if (startTime !== '' && startTime !== undefined) {
            obj.startTime = startTime;
        }
        if (workUnit !== '' && workUnit !== undefined) {
            obj.workUnit = workUnit;
        }
        if (idCard !== '' && idCard !== undefined) {
            obj.idCard = idCard;
        }
        if (endTime !== '' && endTime !== undefined) {
            obj.endTime = endTime;
        }
        if (phone !== '' && phone !== undefined) {
            obj.phone = phone
        }
        if (barcode !== '' && barcode !== undefined) {
            obj.barcode = barcode
        }
        if (uname !== '' && uname !== undefined) {
            obj.username = uname;
        }
        this.props.scaleList(obj).then(msg => {
            that.setState({
                spinning: false
            })
            if (msg.data.code === 200) {
                if (msg.data.data.rows.length === 0 && msg.data.data.total !== 0) {
                    that.setState({ page: page - 1 }, () => {
                        that.loadDatas()
                    })
                }
                else {
                    that.setState({
                        dataList: msg.data.data.rows,
                        total: msg.data.data.total
                    })
                }
            }
        })
    }

    unitnameOnChange = e => {
        this.setState({
            unitname: e.target.value
        })
    }

    barcodeOnChange = e => {
        this.setState({
            barcode: e.target.value
        })
    }

    nameOnChange = e => {
        this.setState({
            uname: e.target.value
        })
    }

    datetimeChange = (date, dateString) => {
        // var choseDate =[];
        // choseDate = _.concat(choseDate,moment(dateString[0]+" 00:00:00","YYYY-MM-DD hh:mm:ss"));
        // choseDate = _.concat(choseDate,moment(dateString[1]+" 23:59:59","YYYY-MM-DD hh:mm:ss"));
        this.setState({
            startTime: dateString[0] === "" ? undefined : dateString[0] + " 00:00:00",
            endTime: dateString[1] === "" ? undefined : dateString[1] + " 23:59:59",
            choosedDates: date,
        })
    }

    idCardOnChange = (e) => {
        this.setState({
            idCard: e.target.value
        })
    }
    workUnitOnChange= (e) => {
        this.setState({
            workUnit: e.target.value
        })
    }
    phoneOnChange= (e) => {
        this.setState({
            phone: e.target.value
        })
    }
    delItem = (id) => {
        var { selectedRowInfoKeys } = this.state;
        if (selectedRowInfoKeys.length === 0) {
            message.error("请选择删除的记录")
            return;
        }
        var that = this;
        confirm({
            content: "确认删除？",
            onOk() {

                var That = that;
                that.props.guahaoDel({ barcodes: selectedRowInfoKeys.join(",") }).then(msg => {
                    if (msg.data.code === 200) {
                        message.success("删除成功！");
                        That.setState({
                            selectedRowInfoKeys:[]
                        })
                        That.loadDatas();
                    }

                })
            }
        });

    }

    editItem = () => {
        var { selectedRowKeys, dataList } = this.state;
        if (selectedRowKeys.length === 0) {
            message.error("请选择编辑记录")
            return;
        }
        if (selectedRowKeys.length > 1) {
            message.error("选择记录过多，请选择单条记录")
            return;
        }
        var curRecord = _.find(dataList, ['id', selectedRowKeys[0]]);
        this.props.history.push({
            pathname: "/admin/medicalRecord", state: {
                idCard: curRecord.idCard
            }
        });
    }

  

    changePage = page => {
        var that = this;
        this.setState({ page:page,selectedRowKeys:[],selectedRowInfoKeys:[] }, () => {
            that.loadDatas()
        })
    }

    pageSizeOnChange = (current, size) => {
        var that = this;
        this.setState({ pageSize: size, selectedRowKeys: [] }, () => {
            that.loadDatas();
        })
    }
// infoId
    // 选中改变
    onSelectChange = selectedRowKeys => {
       var {dataList} = this.state;
        this.setState({ selectedRowKeys });
        var barcodes=[];
        selectedRowKeys.forEach(selectedRowKey => {
           barcodes = _.concat(barcodes,_.find(dataList,["id",selectedRowKey]).barcode) ;
        });
        this.setState({selectedRowInfoKeys:barcodes})
    };
    
    
  
    render() {
        const {   workUnit, phone, selectedRowKeys, dataList, barcode, uname, idCard, spinning, choosedDates } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        return (
            <div>
                <div className="StatisticsPage-head pageHeader"><h3>大规模采集信息统计</h3></div>
                <div className="StatisticsPage-box has-select-table">
                    <div className="StatisticsPage-search">
                        <div className="StatisticsPage-btn">
                            <RangePicker dateFormat={dateFormat} placeholder={['开始时间', '结束时间']} className="date"
                                onChange={this.datetimeChange} value={choosedDates} />
                            <Input className="ipt" placeholder="请输入条码" value={barcode}
                                onChange={this.barcodeOnChange} />
                            <Input className="ipt" placeholder="请输入姓名" value={uname}
                                onChange={this.nameOnChange} />
                            <Input className="ipt" placeholder="请输入身份证" value={idCard}
                                onChange={this.idCardOnChange} />
                           <Input className="ipt" placeholder="请输入工作单位" value={workUnit}
                                onChange={this.workUnitOnChange} />
                            <Input className="ipt" placeholder="请输入手机号" value={phone}
                                onChange={this.phoneOnChange} />
                            <Button className="btn" type="primary" onClick={this.loadDatas}>搜索</Button>
                            <Button className="btn" type="primary" onClick={this.reset}>重置</Button>
                            <Button className="btn" type="primary" onClick={this.exportExcel}>导出</Button>

                           
                        </div>

                    </div>
                    <Spin tip="Loading..." spinning={spinning}>
                        <div>{dataList.length > 0 ? <Table rowKey="id"
                            onRow={(record, index) => {
                                return {
                                    onClick: () => {
                                        let temp = this.state.selectedRowKeys
                                        let tempinfo = this.state.selectedRowInfoKeys;
                                        let index = temp.indexOf(record.id);
                                        let index1 = tempinfo.indexOf(record.barcode);
                                        if (index === -1) { temp.push(record.id) }
                                        else { temp.splice(index, 1) }
                                        this.setState({ selectedRowKeys: temp });
                                        if(index1 === -1){tempinfo.push(record.barcode)}
                                        else {tempinfo.splice(index1,1)}
                                        this.setState({selectedRowInfoKeys:tempinfo})
                                    }
                                }
                            }}
                           
                            dataSource={dataList} rowSelection={rowSelection}
                            pagination={{
                                pageSize: this.state.pageSize, showTotal: ((total) => {
                                    return `共 ${total} 条`;
                                }),
                                onChange: this.changePage, total: this.state.total, showSizeChanger: true,
                                onShowSizeChange: this.pageSizeOnChange
                            }} bordered  >
                            <Column width={50} title="条码号" dataIndex="barcode" key="barcode" align="center" />
                            <Column width={50} title="姓名" dataIndex="username" key="username" align="center" />
                            {/* <Column width={50} title="照片" key="picture" align="center"
                                render={(record) => {
                                    return (<div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                                        <img alt="" style={{ width: 50, height: 50 }} src={record.pic} key={record.pic} />
                                    </div>)
                                }
                                } /> */}
                            <Column width={80} title="身份证号" dataIndex="idCard" key="idCard" align="center" />
                            {/* <Column width={90} title="体检类型" dataIndex="physicalTypeName" key="physicalTypeName" align="center" /> */}
                            <Column width={50} title="采集点" key="collection" align="collection" render={(text, record) => (
                                <span>
                                    <span>{record.collection&&record.collection }</span>
                                </span>
                            )} />
                            {/* <Column width={50} title="" key="orgname" align="orgname" render={(text, record) => (
                                <span>
                                    <span>{record.orgname &&record.orgname}</span>
                                </span>
                            )} /> */}
                            {/* <Column width={50} title="录入方式" key="way" align="center" render={(text, record) => (
                                <span>
                                    <span>{record.way === 1 ? '设备上传' : '手动录入'}</span>
                                </span>
                            )} /> */}

                            <Column width={80} title="采集日期" dataIndex="createdAt" key="createdAt" align="center" />
                           
                        </Table> : <Empty />}</div>
                    </Spin>
                   
                </div>

            </div>
        )
    }
}
export default connect(null, {scaleList,exportNcovUser})(StatisticsPage)               