import React, { Component } from 'react'
import { Modal, Button, Table, Input, message, Select, Spin } from 'antd';
import moment from 'moment';
import {doctorAll, getreagentNoPageList,reagentUpdate,EnvironmentUpdate, EnvironmentRemove, getPhysicalTypeList, EnvironmentSave, getEnvironmentList, savePhyProjectList } from '../../../actions/account'

import _ from 'lodash'
import { connect } from 'react-redux'
const { Option } = Select;
const { Column } = Table;
const { confirm } = Modal;

class MachineManage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inspectionName:undefined,
            checkName:undefined,
            issuance:undefined,
            validity: moment(),
            spinning: false,
            temperature: '',
            humidity: '',
            rank: '',
            reagentId: [],
            selectedRowKeys: [],
            phyList: [],
            doctors:[],
            alreadyDataList: [],
            newPhysicalTypeName: '',
            size: 10,
            dataList: [],
            curRecord: undefined,
            visible: false,
            page: 1,
            total: 0,
            isRequesting: false,
            bindVisble: false,
            bindphyVisble: false,
            orgList: [],
            organizationId: '',
            physicalType: '',
            reagentList: [],
        };
    }

    componentDidMount() {
        var that=this;
        this.loadDatas();
        this.props.getreagentNoPageList().then(msg => {
            if (msg.data.code === 200) {
                that.setState({
                    reagentList: msg.data.data
                })
            }
        })
        this.props.doctorAll().then(res=>{
            that.setState({doctors:res.data.data})
        })
    }

    loadDatas = () => {
        var that = this;
        var { page, size } = this.state;
        var obj = { page: page - 1, size: size };
        this.setState({ spinning: true })
        this.props.getEnvironmentList(obj).then(msg => {
            console.log(msg)
            if (msg.data.code === 200) {
                if (msg.data.data.rows.length === 0 && msg.data.data.total !== 0) {
                    that.setState({ page: page - 1 }, () => {
                        that.loadDatas()
                    })
                }
                else {
                    that.setState({
                        dataList: msg.data.data.rows,
                        total: msg.data.data.total,
                        spinning: false
                    })
                }
            }
        })
    }
   
    showModal = () => {
        this.setState({
            curRecord: undefined,
            visible: true
        })
    }

    handleCancel = () => {
        this.setState({ bindVisble:false,visible: false, curRecord:undefined,temperature: '', humidity: '' ,reagentId: '' ,inspectionName: '' ,checkName: '' ,issuance: '' ,});

    }
    handleBindCancel = () => {
        this.setState({ bindVisble: false, temperature: '', curRecord: undefined, humidity: '', validity: moment() });

    }
    temperatureChange = e => {
        this.setState({
            temperature: e.target.value
        })
    }
    humidityChange = e => {
        this.setState({
            humidity: e.target.value
        })
    }
    inspectionNameChange= e => {
        this.setState({
            inspectionName: e
        })
    }
    checkNameChange= e => {
        this.setState({
            checkName: e
        })
    }
    issuanceChange= e => {
        this.setState({
            issuance: e
        })
    }
    handleOk = () => {
        var { temperature, humidity, isRequesting, reagentId,inspectionName, checkName,issuance} = this.state;
        if (isRequesting) {
            return
        }
        if(temperature.length===0 || humidity.length===0 ||reagentId.length===0 ||inspectionName.length===0 ||checkName.length===0 ||issuance.length===0){
            message.warn('请填写完整！')
            return;
        }
        reagentId= _.filter(reagentId,function(o){
            return o!=='' ||o!==null;
        })
        var that = this;
        this.setState({ isRequesting: true }, () => {
            that.props.EnvironmentSave({  temperature, humidity,  reagentId:reagentId.join(','),inspectionName, checkName,issuance }).then(msg => {
                console.log(msg)
                if (msg.data.code === 200) {
                    message.success('添加成功')
                    that.handleCancel();
                    that.loadDatas();
                }
                else {
                    message.error(msg.data.message)
                }
                that.setState({ isRequesting: false })
            })
        })
    }
    editItem = (record) => {
        this.setState({
            curRecord: record,
            reagentId: record.reagentId.toString(),
            checkName: record.checkName,
            issuance: record.issuance,
            temperature: record.temperature,
            humidity: record.humidity,
            inspectionName: record.inspectionName,
            bindVisble: true,
        })
    }

    reagentOnChange = (value) => {
        this.setState({ reagentId: value })
    }
    phyOnChange = (value) => {
        this.setState({ physicalType: value })
    }

    handleBindphyOk = () => {
        var { isRequesting } = this.state;
        if (isRequesting) {
            return
        }
        var that = this;
        this.setState({ isRequesting: false }, () => {
            that.props.savePhyProjectList({
            }).then(msg => {
                if (msg.data.code === 200) {
                    message.success("绑定成功！");
                    that.loadDatas()
                    that.handleBindphyCancel()
                }
                else {
                    message.error(msg.data.message)
                }
                that.setState({ isRequesting: false })
            })
        })

    }
    

    UpdateBindphyCancel = () => {
        this.setState({
            updatephyVisble: false,
            curRecord: undefined,
            physicalType: '',
            organizationId: '',
        })
    }
    handleBindphyCancel = () => {
        this.setState({
            bindphyVisble: false,
            physicalType: '',
            reagentId: ''
        })
    }
    changePage = page => {
        var that = this;
        this.setState({ page }, () => {
            that.loadDatas()
        })
    }
    // 选中改变
    onSelectChange = selectedRowKeys => {
        console.log(selectedRowKeys)
        this.setState({ selectedRowKeys });
    };
    onChange = (date, dateString) => {
        console.log(date, dateString, dateString.split('-'), dateString.split('-').join(''));
        this.setState({
            validity: dateString.split('-').join('')
        })
    }
    delItem = (recorrd) => {
        var { selectedRowKeys } = this.state;
        if (selectedRowKeys.length === 0 && recorrd === 'all') {
            message.error("请选择删除的记录")
            return;
        }
        if (recorrd !== 'all') {
            this.setState({
                selectedRowKeys: [recorrd.id]
            })
        }
        var that = this;
        confirm({
            content: "确认删除？",
            onOk() {
                var That = that;
                that.props.EnvironmentRemove({ ids: selectedRowKeys.join(",") }).then(msg => {
                    if (msg.data.code === 200) {
                        message.success("删除成功！");
                        That.setState({ selectedRowKeys: [] })
                        That.loadDatas();
                    }

                })
            }
        });

    }
    handleBindOk = () => {
        var { temperature, humidity, isRequesting, reagentId,inspectionName, checkName,issuance,curRecord} = this.state;
        if (isRequesting) {
            return
        }
        if(temperature.length===0 || humidity.length===0 ||reagentId.length===0 ||!inspectionName ||!checkName||!issuance.length){
            message.warn('请填写完整！')
            return;
        }
        reagentId= _.filter(reagentId,function(o){
            return o!=='' ||o!==null;
        })
       
        var that = this;
        this.setState({ isRequesting: true }, () => {
            that.props.EnvironmentUpdate({id:curRecord.id,  temperature, humidity,  reagentId:  reagentId.join(','),inspectionName, checkName,issuance }).then(msg => {
                console.log(msg)
                if (msg.data.code === 200) {
                    message.success('修改成功')
                    that.handleCancel();
                    that.loadDatas();
                }
                else {
                    message.error(msg.data.message)
                }
                that.setState({ isRequesting: false })
            })
        })

    }
    getReagentName=(name)=>{
        if(name===null){
            return ""
        }
        var names=name.split(',');
        names.forEach((nameItem,index) => {
            if(nameItem.length>4){
                names[index]=nameItem.substring(0,4)+"..."
            }
        });
        return names.join(',')
    }
    render() {

        const {doctors, reagentId, selectedRowKeys, reagentList, spinning,  inspectionName, checkName, issuance, dataList, temperature,  humidity, bindVisble } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        return (
            <div>
                <div className="StatisticsPage-head pageHeader"><h3>环境管理</h3></div>
                <div className="StatisticsPage-box">
                    <div className="StatisticsPage-search">
                        <div className="StatisticsPage-btn">

                            <Button className="btn" type="primary" onClick={this.showModal}>添加环境</Button>
                            <Button className="btn" type="primary" onClick={() => this.delItem('all')}>删除</Button>
                            <Modal title="添加环境" visible={this.state.visible} onOk={this.handleOk} onCancel={this.handleCancel} okText="保存" cancelText="取消">
                                <div className="form-group">
                                    <label className="form-label">试剂：</label>
                                    <div className="form-ipt">
                                        <Select  mode="multiple" style={{ width: '90%' }} value={reagentId} onChange={this.reagentOnChange}>
                                            {reagentList.map(item => {
                                                return (<Option key={item.id} value={item.id}>{item.reagentName}</Option>)
                                            })}
                                        </Select>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="form-label">温度：</label>
                                    <div className="form-ipt">
                                        <Input value={temperature} placeholder="请输入温度" onChange={e => this.temperatureChange(e)} />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="form-label">湿度：</label>
                                    <div className="form-ipt">
                                        <Input value={humidity} placeholder="请输入湿度" onChange={e => this.humidityChange(e)} />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="form-label">检验者：</label>
                                    <div className="form-ipt">
                                       
                                         <Select style={{ width: '90%' }} value={inspectionName} onChange={this.inspectionNameChange} >
                                            {doctors.map(doctor=>{
                                                return <Option key={"doctor"+doctor.id} value={doctor.id}>{doctor.name}</Option>
                                            })}
                                            
                                        </Select>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="form-label">校验者：</label>
                                    <div className="form-ipt">

                                    <Select style={{ width: '90%' }} value={checkName} onChange={this.checkNameChange} >
                                            {doctors.map(doctor=>{
                                                return <Option key={"doctor2"+doctor.id} value={doctor.id}>{doctor.name}</Option>
                                            })}
                                            
                                        </Select>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="form-label">签发者：</label>
                                    <div className="form-ipt">
                                    <Select style={{ width: '90%' }} value={issuance} onChange={this.issuanceChange} >
                                            {doctors.map(doctor=>{
                                                return <Option key={"issuance"+doctor.id} value={doctor.id}>{doctor.name}</Option>
                                            })}
                                            
                                        </Select>
                                        </div>
                                </div>
                            </Modal>
                        </div>
                    </div>
                    <div>
                        <Spin tip="Loading..." spinning={spinning}>

                            <Table size="middle" dataSource={dataList} bordered rowKey="id"
                                rowSelection={rowSelection}
                                pagination={{
                                    pageSize: this.state.size, showTotal: ((total) => {
                                        return `共 ${total} 条`;
                                    }),
                                    onChange: this.changePage, total: this.state.total, showSizeChanger: false
                                }}
                                onRow={(record, index) => {
                                    return {
                                        onClick: () => {
                                            let temp = this.state.selectedRowKeys
                                            let index = temp.indexOf(record.id)
                                            if (index === -1) { temp.push(record.id) }
                                            else { temp.splice(index, 1) }
                                            this.setState({ selectedRowKeys: temp });
                                        }
                                    }
                                }}
                            >
                                <Column title="试剂名称" key="reagentName" align="center" render={(text, record) => (
                                        <span>
                                            <span >{this.getReagentName(record.reagentName) }</span>
                                        </span>
                                    )}  />
                                <Column title="温度" dataIndex="temperature" key="temperature" align="center" />
                                <Column title="湿度" dataIndex="humidity" key="humidity" align="center" />
                                <Column title="检验者" dataIndex="inspectionNameStr" key="inspectionNameStr" align="center" />
                                <Column title="校核者" dataIndex="checkNameStr" key="checkNameStr" align="center" />
                                <Column title="签发者" dataIndex="issuanceStr" key="issuanceStr" align="center" />
                                {/* <Column title="操作" align="center" key="action"
                                    render={(text, record) => (
                                        <span>
                                            <span style={{ cursor: 'pointer' }} onClick={() => this.delItem(record)} >删除</span>
                                        </span>
                                    )} /> */}
                            </Table>
                        </Spin>
                        <Modal title="修改" visible={bindVisble} onOk={this.handleBindOk} onCancel={this.handleBindCancel} okText="保存" cancelText="取消">
                        <div className="form-group">
                                    <label className="form-label">试剂：</label>
                                    <div className="form-ipt">
                                        <Select  mode="multiple" style={{ width: '90%' }} value={reagentId} onChange={this.reagentOnChange}>
                                            {reagentList.map(item => {
                                                return (<Option key={item.id}>{item.reagentName}</Option>)
                                            })}
                                        </Select>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="form-label">温度：</label>
                                    <div className="form-ipt">
                                        <Input value={temperature} placeholder="请输入试剂名称" onChange={e => this.temperatureChange(e)} />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="form-label">湿度：</label>
                                    <div className="form-ipt">
                                        <Input value={humidity} placeholder="请输入批号" onChange={e => this.humidityChange(e)} />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="form-label">检验者：</label>
                                    <div className="form-ipt">
                                        <Input value={inspectionName} placeholder="请输入检验者" onChange={e => this.inspectionNameChange(e)} />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="form-label">校验者：</label>
                                    <div className="form-ipt">
                                        <Input value={checkName} placeholder="请输入校验者" onChange={e => this.checkNameChange(e)} />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="form-label">签发：</label>
                                    <div className="form-ipt">
                                        <Input value={issuance} placeholder="请输入签发" onChange={e => this.issuanceChange(e)} />
                                    </div>
                                </div>
                        </Modal>
                    </div>
                </div>
            </div>
        )
    }
}
export default connect(null, {doctorAll,EnvironmentUpdate, getreagentNoPageList,reagentUpdate, EnvironmentRemove, getPhysicalTypeList, EnvironmentSave, getEnvironmentList, savePhyProjectList })(MachineManage)