import React, { Component } from 'react'
import {connect} from 'react-redux'
// import example_logo from '../../assets/example.png'
// import {organizationHealthImage} from '../../actions/account'
import util from '../../actions/util';

class Process extends Component {

    constructor(props){
        super(props);
        let search = this.props.history.location.search;
        var recordId = util.urlParam("id", search);
        this.state={
            recordId:recordId,
            example_logo:undefined
        }
    }

    componentDidMount(){
    //    var {recordId} = this.state;
    //    var that = this
    //    this.props.organizationHealthImage({recordId:recordId}).then(res=>{
    //         if(res.data.code === 200){
    //             that.setState({
    //                 example_logo:res.data.data
    //             })
    //         }
    //    })

    }

    

    render() {
        // var {}= this.state;
       return (
        <div >
              <div className="StatisticsPage-head1 pageHeader1"><h3>检验编号</h3></div>

         
        </div>   
        )
    }
}
export default connect(null,{})(Process)