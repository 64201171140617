import React, { Component } from 'react'
import { Modal, Button, Table, Input, message, Select, Spin } from 'antd'
import '../organizationBS/index.css'
import {
  projectRemove,
  getPhysicalTypeList,
  projectSave,
  getParentList,
  savePhyProjectList,
} from '../../../../actions/account'

// import _ from 'lodash'
import { connect } from 'react-redux'
const { Option } = Select
const { Column } = Table
const { confirm } = Modal

class MachineManage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      spinning: false,
      projectName: '',
      projectCode: '',
      rank: '',
      projectId: '',
      selectedRowKeys: [],
      phyList: [],

      alreadyDataList: [],
      newPhysicalTypeName: '',
      size: 10,
      dataList: [],
      curRecord: undefined,
      visible: false,
      page: 1,
      total: 0,
      isRequesting: false,
      bindVisble: false,
      bindphyVisble: false,
      orgList: [],
      organizationId: '',
      physicalType: '',
    }
  }

  componentDidMount() {
    this.loadDatas()
  }

  loadDatas = () => {
    var that = this
    this.setState({ spinning: true })
    this.props.getParentList().then((msg) => {
      console.log(msg)
      if (msg.data.code === 200) {
        that.setState({
          dataList: msg.data.data,
          spinning: false,
        })
      }
    })
    this.props.getPhysicalTypeList().then((msg) => {
      console.log(msg)
      if (msg.data.code === 200) {
        that.setState({
          phyList: msg.data.data,
        })
      }
    })
  }

  showModal = () => {
    this.setState({
      curRecord: undefined,
      visible: true,
    })
  }

  handleCancel = () => {
    this.setState({
      visible: false,
      projectName: '',
      projectCode: '',
      rank: '',
      parentID: '',
    })
  }
  handleBindCancel = () => {
    this.setState({
      bindVisble: false,
      newPhysicalTypeName: '',
      curRecord: undefined,
    })
  }
  projectNameChange = (e) => {
    this.setState({
      projectName: e.target.value,
    })
  }
  projectCodeChange = (e) => {
    this.setState({
      projectCode: e.target.value,
    })
  }
  rankChange = (e) => {
    this.setState({
      rank: e.target.value,
    })
  }

  handleOk = () => {
    var { projectName, projectCode, isRequesting, rank } = this.state
    if (isRequesting) {
      return
    }
    var that = this
    this.setState({ isRequesting: true }, () => {
      that.props
        .projectSave({
          projectName: projectName,
          projectCode: projectCode,
          rank: Number(rank),
        })
        .then((msg) => {
          console.log(msg)
          if (msg.data.code === 200) {
            message.success('添加成功')
            that.handleCancel()
            that.loadDatas()
          } else {
            message.error(msg.data.message)
          }
          that.setState({ isRequesting: false })
        })
    })
  }
  editItem = (record) => {
    console.log(record)
    this.setState({
      curRecord: record,
      newPhysicalTypeName: record.physicalType,
      bindVisble: true,
    })
  }
  editAlreadyRecord = (record) => {
    console.log(record)
    this.setState({
      curRecord: record,
      organizationId: record.organizationId,
      physicalType: record.physicalType,
      updatephyVisble: true,
    })
  }
  projectOnChange = (value) => {
    this.setState({ projectId: value })
  }
  phyOnChange = (value) => {
    this.setState({ physicalType: value })
  }

  bandphy = () => {
    var that = this
    that.setState({
      bindphyVisble: true,
    })
  }
  handleBindphyOk = () => {
    var { isRequesting, projectId, physicalType } = this.state
    if (isRequesting) {
      return
    }
    var that = this
    this.setState({ isRequesting: false }, () => {
      that.props
        .savePhyProjectList({
          typeId: Number(physicalType),
          projectId: Number(projectId),
        })
        .then((msg) => {
          if (msg.data.code === 200) {
            message.success('绑定成功！')
            that.loadDatas()
            that.handleBindphyCancel()
          } else {
            message.error(msg.data.message)
          }
          that.setState({ isRequesting: false })
        })
    })
  }
  UpdateBindphyOk = () => {
    // var { isRequesting, organizationId, physicalType, curRecord } = this.state;
    // if (isRequesting) {
    //     return
    // }
    // var that = this;
    // this.setState({ isRequesting: false }, () => {
    //     that.props.getPhysicalTypeList({
    //         id: curRecord.id,
    //         physicalType: Number(physicalType),
    //         organizationId: organizationId.toString()
    //     }).then(msg => {
    //         if (msg.data.code === 200) {
    //             message.success("修改成功！");
    //             that.loadDatas2();
    //             that.UpdateBindphyCancel()
    //         }
    //         else {
    //             message.error(msg.data.message)
    //         }
    //         that.setState({ isRequesting: false })
    //     })
    // })
  }

  UpdateBindphyCancel = () => {
    this.setState({
      updatephyVisble: false,
      curRecord: undefined,
      physicalType: '',
      organizationId: '',
    })
  }
  handleBindphyCancel = () => {
    this.setState({
      bindphyVisble: false,
      physicalType: '',
      projectId: '',
    })
  }
  changePage = (page) => {
    var that = this
    this.setState({ page }, () => {
      that.loadDatas2()
    })
  }
  // 选中改变
  onSelectChange = (selectedRowKeys) => {
    console.log(selectedRowKeys)
    this.setState({ selectedRowKeys })
  }
  delItem = (recorrd) => {
    var { selectedRowKeys } = this.state
    if (selectedRowKeys.length === 0 && recorrd === 'all') {
      message.error('请选择删除的记录')
      return
    }
    let arr = recorrd !== 'all' ? [recorrd.id] : selectedRowKeys
    var that = this
    confirm({
      content: '确认删除？',
      onOk() {
        var That = that
        that.props
          .projectRemove({ ids: arr.join(',') })
          .then((msg) => {
            if (msg.data.code === 200) {
              message.success('删除成功！')
              That.setState({ selectedRowKeys: [] })
              That.loadDatas()
            }
          })
      },
    })
  }
  render() {
    const {
      projectId,
      selectedRowKeys,
      phyList,
      spinning,
      updatephyVisble,
      physicalType,
      orgList,
      organizationId,
      dataList,
      projectName,
      rank,
      projectCode,
      bindVisble,
      bindphyVisble,
      newPhysicalTypeName,
    } = this.state
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    }
    return (
      <div>
        <div className="StatisticsPage-head pageHeader">
          <h3>组套项目管理</h3>
        </div>
        <div className="StatisticsPage-box">
          <div className="StatisticsPage-search">
            <div className="StatisticsPage-btn">
              <Button className="btn" type="primary" onClick={this.showModal}>
                添加组套项目
              </Button>
              <Button
                className="btn"
                type="primary"
                onClick={() => this.delItem('all')}
              >
                删除
              </Button>

              {/* <Button className="btn" type="primary" onClick={this.bandphy}>绑定体检类型</Button> */}

              <Modal
                title="添加项目"
                visible={this.state.visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                okText="保存"
                cancelText="取消"
              >
                <div className="form-group">
                  <label className="form-label">项目名称：</label>
                  <div className="form-ipt">
                    <Input
                      value={projectName}
                      placeholder="请输入项目名称"
                      onChange={(e) => this.projectNameChange(e)}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-label">项目代码：</label>
                  <div className="form-ipt">
                    <Input
                      value={projectCode}
                      placeholder="请输入项目代码"
                      onChange={(e) => this.projectCodeChange(e)}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-label">权重：</label>
                  <div className="form-ipt">
                    <Input
                      value={rank}
                      placeholder="请输入权重"
                      onChange={(e) => this.rankChange(e)}
                    />
                  </div>
                </div>
              </Modal>
              <Modal
                title="机构-体检类型"
                visible={bindphyVisble}
                onOk={this.handleBindphyOk}
                onCancel={this.handleBindphyCancel}
                okText="保存"
                cancelText="取消"
              >
                <div className="form-group">
                  <label className="form-label">大项：</label>
                  <div className="form-ipt" style={{ marginTop: '5px' }}>
                    <Select
                      style={{ width: '90%' }}
                      value={projectId}
                      onChange={this.projectOnChange}
                    >
                      {dataList.map((item) => {
                        return <Option key={item.id}>{item.projectName}</Option>
                      })}
                    </Select>
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-label">体检类型：</label>
                  <div className="form-ipt" style={{ marginTop: '5px' }}>
                    <Select
                      style={{ width: '60%' }}
                      value={physicalType}
                      onChange={this.phyOnChange}
                    >
                      {phyList.map((item) => {
                        return (
                          <Option key={item.id}>{item.physicalType}</Option>
                        )
                      })}
                    </Select>
                  </div>
                </div>
              </Modal>
            </div>
          </div>
          <div>
            <Spin tip="Loading..." spinning={spinning}>
              <Table
                size="middle"
                dataSource={dataList}
                bordered
                rowKey="id"
                pagination={false}
                rowSelection={rowSelection}
                onRow={(record, index) => {
                  return {
                    onClick: () => {
                      let temp = this.state.selectedRowKeys
                      let index = temp.indexOf(record.id)
                      if (index === -1) {
                        temp.push(record.id)
                      } else {
                        temp.splice(index, 1)
                      }
                      this.setState({ selectedRowKeys: temp })
                    },
                  }
                }}
              >
                <Column
                  title="大项名称"
                  dataIndex="projectName"
                  key="projectName"
                  align="center"
                />
                <Column
                  title="项目代码"
                  dataIndex="projectCode"
                  key="projectCode"
                  align="center"
                />
                <Column
                  title="权重"
                  dataIndex="rank"
                  key="rank"
                  align="center"
                />
                <Column
                  title="操作"
                  align="center"
                  key="action"
                  render={(text, record) => (
                    <span>
                      {/* <span style={{ cursor: 'pointer' }} onClick={() => this.editItem(record)}>修改</span> */}
                      {/* <Divider type="vertical" /> */}
                      <span
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.delItem(record)}
                      >
                        删除
                      </span>
                    </span>
                  )}
                />
              </Table>
            </Spin>
            <Modal
              title="修改类型名称"
              visible={bindVisble}
              onOk={this.handleBindOk}
              onCancel={this.handleBindCancel}
              okText="保存"
              cancelText="取消"
            >
              <div className="form-group">
                <label className="form-label">类型名称：</label>
                <div className="form-ipt">
                  <Input
                    value={newPhysicalTypeName}
                    placeholder="请输入类型名称"
                    onChange={(e) => this.newphysicalTypeChange(e)}
                  />
                </div>
              </div>
            </Modal>
            <Modal
              title="机构-体检类型-修改"
              visible={updatephyVisble}
              onOk={this.UpdateBindphyOk}
              onCancel={this.UpdateBindphyCancel}
              okText="保存"
              cancelText="取消"
            >
              <div className="form-group">
                <label className="form-label">机构：</label>
                <div className="form-ipt" style={{ marginTop: '5px' }}>
                  <Select
                    style={{ width: '60%' }}
                    value={organizationId}
                    onChange={this.organizationOnChange}
                  >
                    {orgList.map((item) => {
                      return (
                        <Option key={item.organizationId}>
                          {item.organizationName}
                        </Option>
                      )
                    })}
                  </Select>
                </div>
              </div>
              <div className="form-group">
                <label className="form-label">体检类型：</label>
                <div className="form-ipt" style={{ marginTop: '5px' }}>
                  <Select
                    style={{ width: '60%' }}
                    value={physicalType.toString()}
                    onChange={this.phyOnChange}
                  >
                    {dataList.map((item) => {
                      return <Option key={item.id}>{item.physicalType}</Option>
                    })}
                  </Select>
                </div>
              </div>
            </Modal>
          </div>
        </div>
      </div>
    )
  }
}
export default connect(null, {
  projectRemove,
  getPhysicalTypeList,
  projectSave,
  getParentList,
  savePhyProjectList,
})(MachineManage)
