
import React, { Component } from 'react'
import {connect} from 'react-redux'
import { Button } from 'antd';
import './PrintCheckInfo.css'

class picBrowse extends Component {
    canvasRef = React.createRef()
    constructor(props){
        super(props);
        this.state={
             // 放大倍数
    scale: 4,
    // 组件宽
    width: "400",
    // 组件高
    height: "400"
        }
       
    }

    componentDidMount(){
      this.initPainter()
    }

  /**
   * 初始化画笔和事件监听
   */
  initPainter = () => {
    let canvas = this.canvasRef.current;
    this.canvasContext = canvas.getContext('2d');
    this.canvasWidth = canvas.width;
    this.canvasHeight = canvas.height;
    this.targetImage = document.getElementById('targetImage');
  }

    
  handleMouseMoveEvent = (e) => {
    let offsetX = e.nativeEvent.offsetX;
    let offsetY = e.nativeEvent.offsetY;
    let startX = offsetX - 50;
    let startY = offsetY - 50;
    this.canvasContext.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
    this.canvasContext.drawImage(this.targetImage, startX, startY, 100, 100, offsetX , offsetY, 400, 400);
  }
    render() {

    
       return (
        <div className="StatisticsPage-box">
      
          <div className="StatisticsPage-search">
            <div className="StatisticsPage-btn picBtn">
            <Button onClick={()=>this.lightOnClick(1)}>重置</Button>
              <Button onClick={()=>this.lightOnClick(2)}>增亮</Button>
              <Button onClick={()=>this.lightOnClick(3)}>减亮</Button>
            </div>
          </div>
          <div className="StatisticsPage-content">
          <img id={'targetImage'} onMouseMove={this.handleMouseMoveEvent}
           src="http://qiniu.yeekcloud.com/2022-05-28/dr/d134a819c181414a8fc8bbb0390528e1.jpg" />
          <canvas ref={this.canvasRef} className="canvas" width={400} height={400}/>

          </div>
        </div>
     
          
      
        )
    }
}
export default connect(null,{})(picBrowse)