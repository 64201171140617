import React, { Component } from 'react'
import '../medicalRecord/RegistrationPage.css'
import { Tabs, Button, Input, Table, Empty, Spin, DatePicker, Select, message, Modal ,notification,Progress } from 'antd';
import { connect } from 'react-redux'
import _ from 'lodash'
import moment from 'moment';

import {getPhysicalRecord,updateInspectionResult,
    batchSetItemResult, getItemRegistrationList, detectionMachineList, getOrganizationGuahaoList, getPhysicalTypeList, getAllorganization, generateReport, exportNcov,
    setMachine, setReport, getEnvironmentList, setReportEnvItem, setMachineItem,generateItemPdf
} from '../../../actions/account'

const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD hh:mm:ss';
const dateFormatWP = 'YYYY-MM-DD';
const { Column } = Table;
const { TabPane } = Tabs;
const { Option } = Select;

class ReportPrint extends Component {
    constructor(props) {
        super(props);
        this.state = {
            settingPercent:0,
            contents:[],
            dateTimeVisble: false,
            PLstartTime: undefined,
            PLendTime: undefined,
            PLchoosedDates: [],
            envirState: 1,
            curRecord: undefined,
            phyId: '',
            physicalTypeList: [],
            guahaoList: [],
            organizationList: [],
            page: 1,
            total: 0,
            dataList: [],
            organList: [],
            unitname: '',
            barcode: '',
            uname: '',
            startTime: undefined,
            endTime: undefined,
            choosedDates: [],//时间默认值
            choosedDatesWP: [],
            idcard: '',
            pageSize: 10,
            selectedRowKeys: [],
            selectedRowInfoKeys: [],
            selectedRowKeysWP: [],
            selectedRowInfoKeysWP: [],
            organId: undefined,
            spinning: false,
            environments: [],
            visible: false,
            environmentId: undefined,
            extractDetections: [], startNum: '', endNum: '', workUnit: '',collection:'',
            checkDetections: [], isPrintReport: undefined, isSetEnv: undefined,
            extractDetectionId: undefined, checkDetectionId: undefined,
            dataListWP: [],
            startTimeWP: undefined,
            endTimeWP: undefined,
            pageWP:1, pageSizeWP: 10, totalWP: 0,
            itemsNameWP: "", barcodeWP: "",
        }
    }

    componentDidMount() {
        var that = this;
        that.props.detectionMachineList({ machineType: 1 }).then(res => {
            if (res.data.code === 200) {
                that.setState({ extractDetections: res.data.data })
            }
        })
        that.props.detectionMachineList({ machineType: 2 }).then(res => {
            if (res.data.code === 200) {
                that.setState({ checkDetections: res.data.data })
            }
        })
        that.props.getEnvironmentList({page:0,size:999}).then(res => {
            that.setState({
                environments: res.data.data.rows
            })
        })
        
        this.props.getPhysicalTypeList().then(msg => {
            if (msg.data.code === 200) {
                that.setState({
                    physicalTypeList: msg.data.data
                })
            }
        })
        this.props.getAllorganization().then(msg => {
            if (msg.data.code === 200) {
                that.setState({
                    organizationList: msg.data.data
                })
            }
        })
        if (JSON.parse(localStorage.getItem('data')).isAdmin !== 1) {
            that.setState({
                organId: JSON.parse(localStorage.getItem('data')).organizationId
            }, () => {
                this.loadDatas();
                this.loadDatasWP();

            })
        }
        else {
            this.loadDatasWP();
            this.loadDatas();

        }
    }
    loadDatasWP = () => {
        this.setState({
            spinning: true
        })
        var { startTimeWP, endTimeWP, pageWP, barcodeWP, itemsNameWP, pageSizeWP, unitname} = this.state;
        var that = this;
        var obj = {
            page: pageWP - 1,
            size: pageSizeWP
        }
        if(unitname!==undefined&&unitname!==''&&unitname!==null){
            obj.unit= unitname;
        }
        if (startTimeWP !== '' && startTimeWP !== undefined) {
            obj.startTime = startTimeWP;
        }
        if (endTimeWP !== '' && endTimeWP !== undefined) {
            obj.endTime = endTimeWP;
        }
        if (barcodeWP !== '' && barcodeWP !== undefined) {
            obj.barcode = barcodeWP
        }
        if (itemsNameWP !== '' && itemsNameWP !== undefined) {
            obj.itemsName = itemsNameWP;
        }
        this.props.getItemRegistrationList(obj).then(msg => {
            that.setState({
                spinning: false
            })
            if (msg.data.code === 200) {
                if (msg.data.data.rows.length === 0 && msg.data.data.total !== 0) {
                    that.setState({ page: pageWP - 1 }, () => {
                        that.loadDatasWP()
                    })
                }
                else {
                    that.setState({
                        dataListWP: msg.data.data.rows,
                        totalWP: msg.data.data.total
                    })
                }
            }
        })
    }
    onOrganSelectChange = value => {
        this.setState({
            organId: value
        })
    }
    onphySelectChange = value => {
        this.setState({
            phyId: value
        })
    }

    isSetEnvSelectChange = value => {
        this.setState({ isSetEnv: value })
    }

    isPrintReportSelectChange = value => {
        this.setState({ isPrintReport: value })
    }

    reset = () => {
        this.setState({
            choosedDates: [],
            startTime: undefined,
            endTime: undefined,
            barcode: undefined,
            uname: undefined,
            idCard: undefined,
            organId: undefined,
            phyId: undefined,
            collection:undefined,
            workUnit:undefined,
        }, () => {
            this.loadDatas()
        })
    }
    resetWP = () => {
        this.setState({
            choosedDatesWP: [],
            startTimeWP: undefined,
            endTimeWP: undefined,
            itemsNameWP: "",
            barcodeWP: "",
        }, () => {
            this.loadDatasWP()
        })
    }
    loadDatas = () => {

        var { collection,page, startTime, endTime, barcode, uname, pageSize, idCard, organId, phyId, isSetEnv, isPrintReport, workUnit, startNum, endNum } = this.state;
        var that = this;
        var obj = {
            page: page - 1,
            size: pageSize
        }
        if (workUnit !== '' && workUnit !== undefined) {
            obj.workUnit = workUnit;
        }
        if (collection !== '' && collection !== undefined) {
            obj.collection = collection;
        }
        if (startNum !== '' && startNum !== undefined) {
            obj.startNumber = startNum;
        } if (endNum !== '' && endNum !== undefined) {
            obj.endNumber = endNum;
        } if (startTime !== '' && startTime !== undefined) {
            obj.startTime = startTime;
        }
        if (organId !== '' && organId !== undefined) {
            obj.organizationId = organId;
        }
        if (idCard !== '' && idCard !== undefined) {
            obj.idCard = idCard;
        }
        if (endTime !== '' && endTime !== undefined) {
            obj.endTime = endTime;
        }
        if (phyId !== '' && phyId !== undefined) {
            obj.physicalType = phyId
        }
        if (barcode !== '' && barcode !== undefined) {
            obj.barcode = barcode
        }
        if (uname !== '' && uname !== undefined) {
            obj.username = uname;
        }
        if (isSetEnv !== undefined) {
            obj.isSetEnv = isSetEnv
        }
        if (isPrintReport !== undefined) {
            obj.isPrintReport = isPrintReport
        }
        this.setState({
            spinning: true
        }, () => {
            that.props.getOrganizationGuahaoList(obj).then(msg => {
                that.setState({
                    spinning: false
                })
                if (msg.data.code === 200) {
                    if (msg.data.data.rows.length === 0 && msg.data.data.total !== 0) {
                        that.setState({ page: page - 1 }, () => {
                            that.loadDatas()
                        })
                    }
                    else {
                        that.setState({
                            dataList: msg.data.data.rows,
                            total: msg.data.data.total
                        })
                    }
                }
            })

        })
    }

    unitnameOnChange = e => {
        this.setState({
            unitname: e.target.value
        })
    }

    barcodeOnChange = e => {
        this.setState({
            barcode: e.target.value
        })
    }
    barcodeOnChangeWP = e => {
        this.setState({
            barcodeWP: e.target.value
        })
    }
    nameOnChange = e => {
        this.setState({
            uname: e.target.value
        })
    }
    itemsNameWPOnChange = e => {
        this.setState({
            itemsNameWP: e.target.value
        })
    }
    
    PLdatetimeChange = (date, dateString) => {
        // var choseDate =[];
        // choseDate = _.concat(choseDate,moment(dateString[0]+" 00:00:00","YYYY-MM-DD hh:mm:ss"));
        // choseDate = _.concat(choseDate,moment(dateString[1]+" 23:59:59","YYYY-MM-DD hh:mm:ss"));
        this.setState({
            PLstartTime: dateString[0] === "" ? undefined : dateString[0] + " 00:00:00",
            PLendTime: dateString[1] === "" ? undefined : dateString[1] + " 23:59:59",
            PLchoosedDates: date,
        })
    }


    datetimeChange = (date, dateString) => {
        this.setState({
            startTime: dateString[0] === "" ? undefined : dateString[0] + " 00:00:00",
            endTime: dateString[1] === "" ? undefined : dateString[1] + " 23:59:59",
            choosedDates: date,
        })
    }
    datetimeChangeWP = (date, dateString) => {
        this.setState({
            startTimeWP: dateString[0] === "" ? undefined : dateString[0] + " 00:00:00",
            endTimeWP: dateString[1] === "" ? undefined : dateString[1] + " 23:59:59",
            choosedDatesWP: date,
        })
    }
    idCardOnChange = (e) => {
        this.setState({
            idCard: e.target.value
        })
    }


    changePage = page => {
        var that = this;
        this.setState({ page: page, selectedRowKeys: [], selectedRowInfoKeys: [] }, () => {
            that.loadDatas()
        })
    }
    changePageWP = page => {
        var that = this;
        this.setState({ pageWP: page, selectedRowKeysWP: [], selectedRowInfoKeysWP: [] }, () => {
            that.loadDatasWP()
        })
    }
    pageSizeOnChange = (current, size) => {
        var that = this;
        this.setState({ pageSize: size, selectedRowKeys: [] }, () => {
            that.loadDatas();
        })
    }
    pageSizeOnChangeWP = (current, size) => {
        var that = this;
        this.setState({ pageSizeWP: size, selectedRowKeysWP: [] }, () => {
            that.loadDatasWP();
        })
    }
    // infoId
    // 选中改变
    onSelectChange = selectedRowKeys => {
        var { dataList } = this.state;
        this.setState({ selectedRowKeys });
        var barcodes = [];
        selectedRowKeys.forEach(selectedRowKey => {
            barcodes = _.concat(barcodes, _.find(dataList, ["id", selectedRowKey]).barcode);
        });
        this.setState({ selectedRowInfoKeys: barcodes })
    };
    onSelectChangeWP = selectedRowKeysWP => {
        var { dataListWP } = this.state;
        this.setState({ selectedRowKeysWP });
        var barcodes = [];
        console.log(selectedRowKeysWP)
        selectedRowKeysWP.forEach(str => {
            barcodes = _.concat(barcodes, _.find(dataListWP, ["id", str]).barcode);
        });
        console.log(barcodes)
        this.setState({ selectedRowInfoKeysWP: barcodes })
    };
    reportPrinting = (mark) => {
        var { selectedRowInfoKeys,selectedRowInfoKeysWP } = this.state;
        var that = this;
        this.setState({ spinning: true }, () => {
            var formdata = new FormData();
            if(mark===1){
                formdata.append('barcodes', selectedRowInfoKeys)
                that.props.generateReport(formdata).then(res => {
                    if (res.data.code === 200) {

                        window.open(res.data.data.complete, "_blank");
                    }
                    else {
                        message.error(res.data.message)
                    }
                    that.setState({
                        spinning: false
                    })
                })
            }
            else{
                formdata.append('barcodes', selectedRowInfoKeysWP)                
                that.props.generateItemPdf(formdata).then(res => {
                    if (res.data.code === 200) {
                        // var winHandler = window.open('_blank')
                        // winHandler.location.href = res.data.data.complete
                        window.open(res.data.data.complete, "_blank");
                    }
                    else {
                        message.error(res.data.message)
                    }
                    that.setState({
                        spinning: false
                    })
                })
            }
        });

    }

    setEnvironment = (mark) => {
        this.setState({ envirState: mark, visible: true })
    }

    selectOnChange = value => {
        this.setState({
            environmentId: value
        })
    }

    extractDetectionOnChange = value => {
        this.setState({ extractDetectionId: value })
    }

    checkDetectionOnChange = value => {
        this.setState({ checkDetectionId: value })
    }

    handleBindOk = () => {
        var { envirState, selectedRowKeys, environmentId, extractDetectionId, checkDetectionId, selectedRowKeysWP } = this.state
        var that = this;
        if (environmentId === undefined || extractDetectionId === undefined || checkDetectionId === undefined) {
            message.error("参数不全")
            return;
        }
        var formdata = new FormData();
        formdata.append("envId", environmentId);
        if (envirState === 1) {
            if (selectedRowKeys.length === 0) {
                message.warn("请选择至少一条记录！")
                return;
            }
            formdata.append("ids", selectedRowKeys);
            that.props.setReport(formdata).then(res => {
                if (res.data.code === 200) {
                    //设置仪器
                    var formdata2 = new FormData();
                    formdata2.append("ids", selectedRowKeys);
                    formdata2.append("detectionMachine", extractDetectionId)
                    formdata2.append("measurementMachine", checkDetectionId)
                    that.props.setMachine(formdata2).then(msg => {
                        if (msg.data.code === 200) {
                            that.setState({ visible: false, environmentId: undefined, extractDetectionId: undefined, checkDetectionId: undefined })
                        }
                        else {
                            message.error(res.data.message)
                        }
                    })
                }
                else {
                    message.error(res.data.message)
                }
            })
        }
        if (envirState === 2) {
            if (selectedRowKeysWP.length === 0) {
                message.warn("请选择至少一条记录！")
                return;
            }
            formdata.append("ids", selectedRowKeysWP);
            that.props.setReportEnvItem(formdata).then(res => {
                if (res.data.code === 200) {
                    //设置仪器
                    var formdata3 = new FormData();
                    formdata3.append("ids", selectedRowKeysWP);
                    formdata3.append("detectionMachine", extractDetectionId)
                    formdata3.append("measurementMachine", checkDetectionId)
                    that.props.setMachineItem(formdata3).then(msg => {
                        if (msg.data.code === 200) {
                            that.setState({ visible: false, environmentId: undefined, extractDetectionId: undefined, checkDetectionId: undefined })
                        } else {
                            message.error(res.data.message)
                        }
                    })
                } else {
                    message.error(res.data.message)
                }
            })
        }

    }
    handleBindCancel = () => {
        this.setState({ visible: false, environmentId: undefined, extractDetectionId: undefined, checkDetectionId: undefined, envirState: 1 })

    }
    exportExcel = () => {
        var { startTime, endTime } = this.state;
        // var that = this;
        var formdata = new FormData();

        if (startTime !== undefined) {
            formdata.append("startTime", startTime);

        }
        if (endTime !== undefined) {
            formdata.append("endTime", endTime);
        }
        if (!startTime || !endTime) {
            message.error("请选择日期")
            return;
        }

        this.props.exportNcov(formdata).then(res => {
            console.log(res)
            const fileName = "export" + moment().format("YYYYMMDDHHmmss");
            if ('download' in document.createElement('a')) { // 非IE下载
                const url = window.URL.createObjectURL(new Blob([res.data],
                    // 设置该文件的mime类型，这里对应的mime类型对应为.xlsx格式                                                   
                    { type: 'application/vnd.ms-excel' }));
                const link = document.createElement('a');
                link.href = url;
                // 从header中获取服务端命名的文件名
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
            }
            else {
                // IE10+下载
                navigator.msSaveBlob(res.data, fileName)
            }

        })
    }

    startNumOnChange = e => {
        this.setState({ startNum: e.target.value })
    }
    collectionOnChange= e => {
        this.setState({ collection: e.target.value })
    }
    endNumOnChange = e => {
        this.setState({ endNum: e.target.value })
    }

    workUnitOnChange = e => {
        this.setState({ workUnit: e.target.value })
    }
    onenvirStateChange = e => {
        console.log(e.target.value);
        this.setState({ envirState: e.target.value });
    };
    getReagentName=(name)=>{
        if(!name)return name;
        var names=name.split(',');
        // console.log(name)
        names.forEach((nameItem,index) => {
            if(nameItem.length>4){
                names[index]=nameItem.substring(0,4)+"..."
                // console.log(nameItem)
            }
        });
        // console.log(names.join(',')) 
        return names.join(',')
    }
    unitnameOnChange=e=>{
        this.setState({
            unitname:e.target.value
        })
    }
    batchSetOnClick = () => {
        console.log(123)
        this.setState({
            dateTimeVisble: true,
            PLstartTime: undefined,
            PLendTime: undefined,
            PLchoosedDates: [],
            loading:false,
        })
    }
    onDateCancel = () => {
        this.setState({
            dateTimeVisble: false,
            PLstartTime: undefined,
            PLendTime: undefined,
            PLchoosedDates: [],
            loading:false,
            settingPercent:0,
        })
    }
    goSearchWPDetail=(datas,i)=>{
        // datas.for
        var that= this;
        this.setState({settingPercent:Number((i+1)*100/datas.length).toFixed(1)})
        if (i >= datas.length) {
            message.destroy();
            message.success("设置成功！")
            that.onDateCancel();
            that.loadDatasWP();
            return;
        }
        var data = datas[i];
        this.props.getPhysicalRecord( {barcode:data.barcode}).then(res => {
            if (res.data.code === 200) {
                if (res.data.data === null) {
                    // that.state.contents.push(data.barcode+data.itemsName+'设置失败！：查无检验项');
                    notification.error({
                        duration: 0,
                        message: '查无检验项',
                        description:data.barcode+"设置失败"
                      });
                    i++;
                    that.goSearchWPDetail(datas,i);
                }
                else{
                    that.saveResult(datas,i,res.data.data);
                    // notification.error({
                    //     duration: 0,
                    //     message: '查无检验项',
                    //     description:data.barcode+"设置失败"
                    //   });
                }
                
            }
        })
    }
    saveResult=(datas,i,result)=>{
        result.inspectionResult.details[0].slaveProject.forEach(item=>{item.result=item.refVal;})
        var that=this;
        that.props.updateInspectionResult(result).then(res => {
            if (res.data.code === 200) {
                i++;
                that.goSearchWPDetail(datas,i);
            }
            else{
                notification.error({
                    duration: 0,
                    message: res.data.message,
                    description:result.barcode+"设置失败"
                  });
                i++;
                that.goSearchWPDetail(datas,i);
            }
        })
    }
    onDateOK=()=>{
        var { PLstartTime, PLendTime,} = this.state;
        var that = this;
            var formdata = new FormData();
            formdata.append("startTime", PLstartTime);
            formdata.append("endTime", PLendTime);
            message.loading('正在操作，请稍等...',0)
            var obj = {
                page:0,
                size: 99999,
                startTime: PLstartTime,
                endTime: PLendTime,
            }
            this.props.getItemRegistrationList(obj).then(msg => {
                if (msg.data.code === 200) {
                    if (msg.data.data.rows.length>0) {
                        that.goSearchWPDetail(msg.data.data.rows,0);
                    }
                    else {
                       message.destroy();
                       message.info('查无数据！');
                    }
                }
            })

            // that.props.batchSetItemResult(formdata).then(res => {
            //     console.log(res)
            //     if (res.data.code === 200) {
            //         message.success("批量设置成功！");
            //         that.onDateCancel();
            //     }
            //     else{
            //         that.setState({loading:false,})
            //         message.error(res.data.message)
            //     }
            // })
    }
    render() {
        const {PLchoosedDates, dataListWP, selectedRowKeysWP,  extractDetections, checkDetections, environments, environmentId, organId,  visible, extractDetectionId,
            selectedRowKeys, dataList, barcode, uname, idCard, spinning, choosedDates, unitname, checkDetectionId,
            organizationList, isPrintReport, isSetEnv, startNum, endNum, workUnit ,collection} = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        const rowSelectionWP = {
            selectedRowKeysWP,
            onChange: this.onSelectChangeWP,
        };
        return (
            <div>
                <div className="StatisticsPage-head pageHeader"><h3>报告打印</h3></div>
                <Modal width="800px" title="设置报告环境" visible={visible} onOk={this.handleBindOk} onCancel={this.handleBindCancel} okText="保存" cancelText="取消">
                    <div className="form-group">
                        <label className="form-label">选择环境：</label>
                        <div className="form-ipt">
                            <Select showSearch style={{ width: '100%' }}  value={environmentId} onChange={this.selectOnChange} 
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                              >
                                {environments.map(environment => {
                                    return <Option key={environment.id} value={environment.id}>{this.getReagentName(environment.reagentName) + "-" + environment.temperature + "-" + environment.humidity +
                                    "-" + environment.inspectionNameStr+ "-" + environment.checkNameStr+ "-" + environment.issuanceStr}</Option>
                                })}
                            </Select>
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="form-label">选择提取仪器：</label>
                        <div className="form-ipt">
                            <Select style={{ width: '100%' }} value={extractDetectionId} onChange={this.extractDetectionOnChange}>
                                {extractDetections.map(environment => {
                                    return <Option key={environment.id} value={environment.id}>{environment.detectionMachine + "-" + environment.machineCode}</Option>
                                })}

                            </Select>
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="form-label">选择检测仪器：</label>
                        <div className="form-ipt">
                            <Select style={{ width: '100%' }} value={checkDetectionId} onChange={this.checkDetectionOnChange}>
                                {checkDetections.map(environment => {
                                    return <Option key={environment.id} value={environment.id}>{environment.detectionMachine + "-" + environment.machineCode}</Option>
                                })}

                            </Select>
                        </div>
                    </div>


                </Modal>
                <Modal title="批量设置" visible={this.state.dateTimeVisble}
                    onOk={this.onDateOK}
                    onCancel={this.onDateCancel}>
                         <Spin spinning={this.state.loading} tip="批量设置中，请耐心等待...">
                         <div className="form-group">
                            <label className="form-label">选择日期：</label>
                            <div className="form-ipt">
                            <RangePicker dateFormat={dateFormat} placeholder={['开始时间', '结束时间']} className="date"
                             onChange={this.PLdatetimeChange} value={PLchoosedDates} />
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="form-label">操作进度条：</label>
                            <div className="form-ipt">
                                <Progress percent={this.state.settingPercent} status="active" />
                            </div>
                        </div>
                   </Spin>
                  
                </Modal>
                <div className="StatisticsPage-box has-select-table">
                    <Tabs defaultActiveKey="1">
                        <TabPane tab="人员体检" key="1">
                            <div className="StatisticsPage-search">
                                <div className="StatisticsPage-btn">
                                    <RangePicker dateFormat={dateFormat} placeholder={['开始时间', '结束时间']} className="date"
                                        onChange={this.datetimeChange} value={choosedDates} />
                                    <Input className="ipt_search" placeholder="请输入条码" value={barcode}
                                        onChange={this.barcodeOnChange} />
                                    <Input className="ipt_search" placeholder="请输入姓名" value={uname}
                                        onChange={this.nameOnChange} />
                                    <Input className="ipt_search" placeholder="请输入身份证" value={idCard}
                                        onChange={this.idCardOnChange} />
                                    <Input className="ipt_search" placeholder="请输入单位" value={workUnit}
                                        onChange={this.workUnitOnChange} />
                                     <Input className="ipt" placeholder="请输入采集点" value={collection}
                                onChange={this.collectionOnChange} />
                                    <Input className="ipt_search" placeholder="起始检测编号" value={startNum}
                                        onChange={this.startNumOnChange} />
                                    <Input className="ipt_search" placeholder="终止检测编号" value={endNum}
                                        onChange={this.endNumOnChange} />
                                    {JSON.parse(localStorage.getItem('data')).isAdmin === 1 && <Select className="ipt_search" allowClear={true} placeholder="请选择所属机构" value={organId} onChange={this.onOrganSelectChange} >
                                        {organizationList.map(organ => {
                                            return <Option key={organ.organizationId}>{organ.organizationName}</Option>
                                        })}
                                    </Select>}

                                    <Select className="ipt_search" allowClear={true} placeholder="是否设置环境" value={isSetEnv} onChange={this.isSetEnvSelectChange} >
                                        <Option value={0}>未设置</Option>
                                        <Option value={1}>已设置</Option>
                                    </Select>
                                    <Select className="ipt_search" allowClear={true} placeholder="是否打印" value={isPrintReport} onChange={this.isPrintReportSelectChange} >
                                        <Option value={0}>未打印</Option>
                                        <Option value={1}>已打印</Option>
                                    </Select>
                                    <Button className="btn" type="primary" onClick={this.loadDatas}>搜索</Button>
                                    <Button className="btn" type="primary" onClick={this.reset}>重置</Button>
                                    <Button className="btn" type="primary" onClick={e => this.setEnvironment(1)}>设置报告环境</Button>
                                    <Button className="btn" type="primary" onClick={(e)=>this.reportPrinting(1)}>打印报告</Button>
                                    <Button className="btn" type="primary" onClick={this.exportExcel}>导出检验结果</Button>

                                </div>

                            </div>
                            <Spin tip="Loading..." spinning={spinning}>
                                <div>{dataList.length > 0 ? <Table rowKey="id"
                                    onRow={(record, index) => {
                                        return {
                                            onClick: () => {
                                                let temp = this.state.selectedRowKeys
                                                let tempinfo = this.state.selectedRowInfoKeys;
                                                let index = temp.indexOf(record.id);
                                                let index1 = tempinfo.indexOf(record.barcode);
                                                if (index === -1) { temp.push(record.id) }
                                                else { temp.splice(index, 1) }
                                                this.setState({ selectedRowKeys: temp });
                                                if (index1 === -1) { tempinfo.push(record.barcode) }
                                                else { tempinfo.splice(index1, 1) }
                                                this.setState({ selectedRowInfoKeys: tempinfo })
                                            }
                                        }
                                    }}
                                    dataSource={dataList} rowSelection={rowSelection}
                                    pagination={{
                                        pageSize: this.state.pageSize, showTotal: ((total) => {
                                            return `共 ${total} 条`;
                                        }),
                                        onChange: this.changePage, pageSizeOptions: ['10', '20', '50', '100', '500'], total: this.state.total, showSizeChanger: true,
                                        onShowSizeChange: this.pageSizeOnChange
                                    }} bordered  >
                                    <Column width={50} title="条码号" dataIndex="barcode" key="barcode" align="center" sorter={
                                        (a, b) => { return a.barcode > b.barcode ? 1 : -1 }} />
                                    <Column width={50} title="姓名" dataIndex="username" key="username" align="center" />
                                    <Column width={80} title="身份证号" dataIndex="idCard" key="idCard" align="center" />
                                    <Column width={50} title="照片" key="picture" align="center"
                                        render={(record) => {
                                            return (<div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                                                <img alt="" style={{ width: 50, height: 50 }} src={record.pic} key={record.pic} />
                                            </div>)
                                        }
                                        } />
                                    <Column width={90} title="体检类型" dataIndex="physicalTypeName" key="physicalTypeName" align="center" />
                                    {/* <Column width={50} title="机器套餐" key="collection" align="collection" render={(text, record) => (
                                <span>
                                    <span>{record.collection && record.collection}</span>
                                </span>
                            )} />
                        */}
                                    <Column width={50} title="单位" key="workUnit" align="workUnit" render={(text, record) => (
                                        <span>
                                            <span>{record.workUnit && record.workUnit}</span>
                                        </span>
                                    )} sorter={(a, b) => { return a.workUnit > b.workUnit ? 1 : -1 }} />
                                    <Column width={50} title="采集点" key="collection" align="collection" 
                            sorter={(a, b) => { return a.collection > b.collection ? 1 : -1 }}  render={(text, record) => (
                                <span>
                                    <span>{record.collection && record.collection}</span>
                                </span>
                            )} />
                                    <Column width={100} title="检验编号" dataIndex="checkSampleNumber" key="checkSampleNumber" align="center"
                                        sorter={
                                            (a, b) => { return a.checkSampleNumber > b.checkSampleNumber ? 1 : -1 }} />
                                    <Column width={80} title="是否设置环境" dataIndex="isPrintReport" key="envId" align="envId" render={(text, record) => (
                                        <span>
                                            <span>{record.envId === null ? '未设置' : '已设置'}</span>
                                        </span>
                                    )} />
                                    <Column width={80} title="是否打印" dataIndex="isPrintReport" key="isPrintReport" align="center" render={(text, record) => (
                                        <span>
                                            <span>{record.isPrintReport === 1 ? '已打印' : '未打印'}</span>
                                        </span>
                                    )} />

                                    <Column width={80} title="采集日期" dataIndex="entryTime" key="entryTime" align="center"
                                        sorter={(a, b) => { return a.entryTime > b.entryTime ? 1 : -1 }} />

                                </Table> : <Empty />}</div>
                            </Spin>

                        </TabPane>
                        <TabPane tab="物品" key="2">
                            <div className="StatisticsPage-search">
                                <div className="StatisticsPage-btn">
                                    <RangePicker dateFormat={dateFormatWP} placeholder={['开始时间', '结束时间']} className="date"
                                        onChange={this.datetimeChangeWP} value={this.state.choosedDatesWP} />
                                    <Input className="ipt_search" placeholder="请输入条码" value={this.state.barcodeWP}
                                        onChange={this.barcodeOnChangeWP} />
                                    <Input className="ipt_search" placeholder="物品名称" value={this.state.itemsNameWP}
                                        onChange={this.itemsNameWPOnChange} />
                                    <Input className="ipt" placeholder="采样单位" value={unitname}
                                        onChange={this.unitnameOnChange} />
                                    <Button className="btn" type="primary" onClick={this.loadDatasWP}>搜索</Button>
                                    <Button className="btn" type="primary" onClick={this.resetWP}>重置</Button>
                                    <Button className="btn" type="primary" onClick={e => this.setEnvironment(2)}>设置报告环境</Button>
                                    <Button className="btn" type="primary" onClick={e => this.reportPrinting(2)}>打印报告</Button>
                                    <Button className="btn" type="primary" onClick={()=>this.batchSetOnClick()} >批量设置结果</Button>

                                </div>

                            </div>
                            <Spin tip="Loading..." spinning={spinning}>
                                <div>{dataListWP.length > 0 ? <Table rowKey="id"
                                    onRow={(record, index) => {
                                        return {
                                            onClick: () => {
                                                let temp = this.state.selectedRowKeysWP
                                                let tempinfo = this.state.selectedRowInfoKeysWP;
                                                let index = temp.indexOf(record.id);
                                                let index1 = tempinfo.indexOf(record.id);
                                                if (index === -1) { temp.push(record.id) }
                                                else { temp.splice(index, 1) }
                                                this.setState({ selectedRowKeysWP: temp });
                                                if (index1 === -1) { tempinfo.push(record.id) }
                                                else { tempinfo.splice(index1, 1) }
                                                this.setState({ selectedRowInfoKeysWP: tempinfo })
                                            }
                                        }
                                    }}
                                    dataSource={dataListWP}
                                    rowSelection={rowSelectionWP}
                                    pagination={{
                                        pageSize: this.state.pageSizeWP, showTotal: ((totalWP) => {
                                            return `共 ${totalWP} 条`;
                                        }),
                                        onChange: this.changePageWP, pageSizeOptions: ['10', '20', '50', '100', '500', '1000'], total: this.state.totalWP, showSizeChanger: true,
                                        onShowSizeChange: this.pageSizeOnChangeWP
                                    }} bordered  >
                                    <Column width={50} title="条码号" dataIndex="barcode" key="barcode" align="center" 
                                      sorter={(a, b) => { return a.barcode > b.barcode ? 1 : -1 }}/>
                                    <Column width={50} title="名称" dataIndex="itemsName" key="itemsName" align="center" 
                                      sorter={(a, b) => { return a.itemsName > b.itemsName ? 1 : -1 }}/>
                                    <Column width={80} title="采样单位" dataIndex="unit" key="unit" align="center"
                                      sorter={(a, b) => { return a.unit > b.unit ? 1 : -1 }} />
                                    <Column width={90} title="采样地点" dataIndex="locations" key="locations" align="center" 
                                      sorter={(a, b) => { return a.locations > b.locations ? 1 : -1 }}/>
                                    <Column width={90} title="联系人" dataIndex="contactName" key="contactName" align="center" />
                                    <Column width={90} title="联系方式" dataIndex="contactPhone" key="contactPhone" align="center" />
                                    <Column width={90} title="创建时间" dataIndex="createdAt" key="createdAt" align="center" />
                                    <Column width={50} title="图片" key="pic" align="center" render={(text, record) => (
                                        <span>
                                            <span>{record.pic && record.pic.length > 0 ? '展示图片待完善' : '暂无'}</span>
                                        </span>
                                    )} />
                                </Table> : <Empty />}</div>
                            </Spin>

                        </TabPane>
                    </Tabs>
                   
           
                </div>

            </div>
        )
    }
}
export default connect(null, {
    detectionMachineList, setMachine, getItemRegistrationList,generateItemPdf,batchSetItemResult,getPhysicalRecord,updateInspectionResult,
    getOrganizationGuahaoList, getPhysicalTypeList, getAllorganization, generateReport, setReport, getEnvironmentList, exportNcov, setReportEnvItem, setMachineItem
})(ReportPrint)