import React, { Component } from 'react'
import { connect } from 'react-redux'


class InsideDepartments extends Component {
   
    render() {
        return (
            <div>
                <div className="StatisticsPage-head pageHeader"><h3>门诊预约部门</h3></div>
          
            </div>
        )
    }
}
export default connect(null, {  })(InsideDepartments) 