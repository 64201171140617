export const PREFIX_FANDIAN =
  process.env.NODE_ENV === 'development'
    ? 'http://sample.api.yeekcloud.com/v1' //10.36.249.11
    : 'http://sample.api.yeekcloud.com/v1'
export const PREFIX_FANDIAN2 =
  process.env.NODE_ENV === 'development'
    ? 'http://sample.api.yeekcloud.com/v1'
    : 'http://sample.api.yeekcloud.com/api'
export const PREFIX_FANDIAN3 =
  process.env.NODE_ENV === 'development'
    ? 'http://10.36.249.13:8084'
    : 'http://sample.api.yeekcloud.com'
export const PREFIX_GONGWEI = 'http://gongwei_api.yeekcloud.com/api'
export const PREFIX_UPLOAD_PATH =
  process.env.NODE_ENV === 'development'
    ? 'http://10.36.249.13:8084/v1'
    : 'http://upload.cert_api.yeekcloud.com/v1'
//路径****************************************** ****

// 门诊预约
export const appointmentSave_Path =
  PREFIX_FANDIAN2 + '/registration/appointmentUser/save' //预约
export const appointmentRegister_Save_Path =
  PREFIX_FANDIAN2 + '/health/appointmentRegister/save' //预约居民健康档案登记表
export const appointmentRegister_Detail_Path =
  PREFIX_FANDIAN2 + '/health/appointmentRegister/detail' //居民健康档案登记表详情
// export const appointmentSave_Path= PREFIX_FANDIAN+"/health/appointmentUser/save";//预约
export const appointmentPageDetail_Path =
  PREFIX_FANDIAN2 + '/registration/appointmentPageDetail'
export const appointmentPageDetail_Path2 =
  PREFIX_FANDIAN2 + '/registration/appointmentDateList'
export const appointmentList_LIST_Path =
  PREFIX_FANDIAN + '/health/appointmentDate/appointmentList'
export const appointmentDateDetail_Remove_Path =
  PREFIX_FANDIAN + '/health/appointmentDateDetail/batchRemove'
export const appointmentDate_Remove_Path =
  PREFIX_FANDIAN + '/health/appointmentDate/batchRemove'
export const AppointmentDate_LIST_Path =
  PREFIX_FANDIAN + '/health/appointmentDate/list' //体检类型预约时间段
export const AppointmentDate_UPDATE_Path =
  PREFIX_FANDIAN + '/health/appointmentDate/update' //修改体检类型预约时间段
export const AppointmentDate_ADD_Path =
  PREFIX_FANDIAN + '/health/appointmentDate/save' //新增体检类型预约时间段

export const AppointmentDateDetail_LIST_Path =
  PREFIX_FANDIAN + '/health/appointmentDateDetail/list' //体检类型预约时间点
export const AppointmentDateDetail_UPDATE_Path =
  PREFIX_FANDIAN + '/health/appointmentDateDetail/update' //修改体检类型预约时间点
export const AppointmentDateDetail_ADD_Path =
  PREFIX_FANDIAN + '/health/appointmentDateDetail/save' //新增体检类型预约时间点

//批量设置采集方式
export const batchSetSampleWay_Path =
  PREFIX_FANDIAN + '/health/physicalRecord/batchSetSampleWay'
//人群
export const setPersonsCategory_Path =
  PREFIX_FANDIAN + '/health/physicalRecord/batchUpdateCrowd'
//导出华晟Excel
export const exportHsExcel_Path =
  PREFIX_FANDIAN + '/health/physicalRecord/exportHsExcel'
//导出大规模采集数据
export const exportNcovUser_Path =
  PREFIX_FANDIAN + '/health/wxNcovUser/exportNcovUser'
//导出核酸检测信息结果
export const exportNcov_Path =
  PREFIX_FANDIAN + '/health/registerInfo/exportNcov'
// 导入体检名单
export const readExcel_Path = PREFIX_FANDIAN + '/health/physicalList/readExcel'

//设置报告环境
export const setReport_env_Path =
  PREFIX_FANDIAN + '/health/physicalRecord/setReport'
//分配小程序账号
export const childOrganization_list_Path =
  PREFIX_FANDIAN + '/health/childOrganization/list'
export const childOrganization_add_Path =
  PREFIX_FANDIAN + '/health/childOrganization/save'
export const childOrganization_remove_Path =
  PREFIX_FANDIAN + '/health/childOrganization/batchRemove'
export const childOrganization_update_Path =
  PREFIX_FANDIAN + '/health/childOrganization/update'
// 项目管理
export const project_save_Path = PREFIX_FANDIAN + '/health/project/save'
export const project_remove_Path =
  PREFIX_FANDIAN + '/health/project/batchRemove'
export const project_slaveList_Path =
  PREFIX_FANDIAN + '/health/project/slaveList'
export const project_parentList_Path =
  PREFIX_FANDIAN + '/health/project/parentList'
//检验结果
export const physicalRecord_Path =
  PREFIX_FANDIAN + '/health/physicalRecord/inspectionResult'
export const updateInspectionResult_Path =
  PREFIX_FANDIAN + '/health/physicalRecord/updateInspectionResult'

export const batchSetResult_PATH =
  PREFIX_FANDIAN + '/health/physicalRecord/batchSetReport'
//体检类型-项目绑定
export const organizationTypeProject_save_Path =
  PREFIX_FANDIAN + '/health/organizationTypeProject/save'
export const organizationTypeProject_remove_Path =
  PREFIX_FANDIAN + '/health/organizationTypeProject/batchRemove'
export const organizationTypeProject_update_Path =
  PREFIX_FANDIAN + '/health/organizationTypeProject/update'
export const organizationTypeProject_list_Path =
  PREFIX_FANDIAN + '/health/organizationTypeProject/list'
//试剂
export const reagent_save_Path = PREFIX_FANDIAN + '/health/reagent/save'
export const reagent_remove_Path =
  PREFIX_FANDIAN + '/health/reagent/batchRemove'
export const reagent_update_Path = PREFIX_FANDIAN + '/health/reagent/update'
export const reagent_list_Path = PREFIX_FANDIAN + '/health/reagent/list'
export const reagent_list_noPage_Path =
  PREFIX_FANDIAN + '/health/reagent/list/no/page'
//环境
export const environment_save_Path = PREFIX_FANDIAN + '/health/environment/save'
export const environment_remove_Path =
  PREFIX_FANDIAN + '/health/environment/batchRemove'
export const environment_update_Path =
  PREFIX_FANDIAN + '/health/environment/update'
export const environment_list_Path = PREFIX_FANDIAN + '/health/environment/list'
export const environment_list_noPage_Path =
  PREFIX_FANDIAN + '/health/environment/list/no/page'
//报告打印
export const generateReport_Path =
  PREFIX_FANDIAN + '/health/physicalRecord/generateReport'

export const uploadFile_Path = PREFIX_FANDIAN2 + '/file/uploadFile'
export const inportBackUP_Path = PREFIX_FANDIAN + '/health/machine/importBackup'

export const itemRegistration_List_Path =
  PREFIX_FANDIAN + '/health/itemRegistration/list'
export const itemRegistration_export_Path =
  PREFIX_FANDIAN + '/health/itemRegistration/export'
//导出基本信息
export const import_registerInfo_PATH =
  PREFIX_FANDIAN + '/health/registerInfo/import' //登录
export const organization_not_slect_Path =
  PREFIX_FANDIAN + '/health/organizationPhysicalType/organization/select'

export const LOGIN_PATH = PREFIX_FANDIAN + '/system/user/login' //登录
export const Organization_Logout_PATH = PREFIX_FANDIAN + '/system/user/logout' //退出登录
export const Organization_Delete_PATH =
  PREFIX_FANDIAN + '/health/organization/remove'
export const BindMachine_remove_PATH =
  PREFIX_FANDIAN + '/health/organizationBindMachine/remove' //解除绑定

export const GuahaoList_PATH = PREFIX_FANDIAN + '/health/registerInfo/list'
export const GuahaoAdd_PATH = PREFIX_FANDIAN2 + '/registration/save'
export const Guahao_Organization_PATH =
  PREFIX_FANDIAN + '/health/registerInfo/organization'
export const Guahao_getDetail_PATH =
  PREFIX_FANDIAN + '/health/registerInfo/getDetail'
export const Guahao_remove_Path =
  PREFIX_FANDIAN + '/health/physicalRecord/batchRemove' //删除挂号
export const physicalRecord_remove_Path =
  PREFIX_FANDIAN + '/health/physicalRecord/remove' //删除挂号
export const allBindList_PATH =
  PREFIX_FANDIAN + '/health/organizationBindMachine/allBindList'
export const Guahao_update_Path = PREFIX_FANDIAN + '/health/registerInfo/update'
export const MachineList_PATH = PREFIX_FANDIAN + '/health/machine/list' //机器分页
export const organizationSelect_PATH =
  PREFIX_FANDIAN + '/health/organization/select' //机构不分页
export const UsableMachine_PATH =
  PREFIX_FANDIAN + '/health/organizationBindMachine/usableMachine'
export const MachineAdd_PATH = PREFIX_FANDIAN + '/health/machine/save'
export const OrganizationBindMachine_PATH =
  PREFIX_FANDIAN + '/health/organizationBindMachine/save'
export const alreadyMachineList_PATH =
  PREFIX_FANDIAN + '/health/organizationBindMachine/alreadyMachine'
export const OrganizationAdd_PATH = PREFIX_FANDIAN + '/health/organization/save'
export const OrganizationList_PATH =
  PREFIX_FANDIAN + '/health/organization/list'
export const BindPhysicalTypeUpdate_PATH =
  PREFIX_FANDIAN + '/health/organizationBindMachine/update'
//体检类型
export const physicalTypeList_PATH =
  PREFIX_FANDIAN + '/health/physicalType/list'
export const physicalSave_PATH = PREFIX_FANDIAN + '/health/physicalType/save'
export const physicalUpdate_PATH =
  PREFIX_FANDIAN + '/health/physicalType/update'
export const physicalDel_PATH =
  PREFIX_FANDIAN + '/health/physicalType/batchRemove'
//
export const Barcode_PATH = PREFIX_FANDIAN + '/health/organization/getBarcode'
//机构-体检类型
export const organizationPhysicalType_select_PATH =
  PREFIX_FANDIAN + '/health/organizationPhysicalType/select'
export const organizationPhysicalType_PATH =
  PREFIX_FANDIAN + '/health/organizationPhysicalType/list'
export const organizationPhysicalTypeSave_PATH =
  PREFIX_FANDIAN + '/health/organizationPhysicalType/save'
export const organizationPhysicalTypeRemove_PATH =
  PREFIX_FANDIAN + '/health/organizationPhysicalType/remove'
export const organizationPhysicalTypeUpdate_PATH =
  PREFIX_FANDIAN + '/health/organizationPhysicalType/update'

export const Old_System_Data_PATH =
  'http://akangchina.com:8989/akangchina/uquery/getPersonDetail'
// 医生管理
export const doctorAll_PATH = PREFIX_FANDIAN + '/health/doctor/all'
export const doctorSave_PATH = PREFIX_FANDIAN + '/health/doctor/save'
export const doctorUpdate_PATH = PREFIX_FANDIAN + '/health/doctor/update'
export const doctorList_PATH = PREFIX_FANDIAN + '/health/doctor/list'
export const doctorDelete_PATH = PREFIX_FANDIAN + '/health/doctor/batchRemove'
export const imageUpload_PATH = PREFIX_FANDIAN2 + '/file/uploadFile'
export const detectionMachine_Save_PATH =
  PREFIX_FANDIAN + '/health/ncovDetectionMachine/save'
export const detectionMachine_Update_PATH =
  PREFIX_FANDIAN + '/health/ncovDetectionMachine/update'
export const detectionMachine_List_PATH =
  PREFIX_FANDIAN + '/health/ncovDetectionMachine/list'
export const detectionMachine_Delete_PATH =
  PREFIX_FANDIAN + '/health/ncovDetectionMachine/batchRemove'

export const setMachine_PATH =
  PREFIX_FANDIAN + '/health/physicalRecord/setMachine'

export const scale_List_PATH = PREFIX_FANDIAN + '/health/wxNcovUser/list'

export const items_export_PATH =
  PREFIX_FANDIAN + '/health/itemRegistration/exportItem'

export const updateSampleNumber_PATH =
  PREFIX_FANDIAN + '/health/physicalRecord/updateSampleNumber'
export const resetPwd_PATH = PREFIX_FANDIAN + '/health/organization/resetPwd' //重置密码
export const modules_PATH = PREFIX_FANDIAN + '/health/logs/modules' //操作模块
export const businessTypes_PATH = PREFIX_FANDIAN + '/health/logs/businessType' //业务类型
export const logList_PATH = PREFIX_FANDIAN + '/health/logs/list' //日志列表
export const barcodeRuleSet_PATH =
  PREFIX_FANDIAN + '/health/organizationPhysicalType/update' //条码规则设置
export const getBarcodeRule_PATH =
  PREFIX_FANDIAN + '/health/organizationPhysicalType/detail'

// /api/registration/getAppointmentInfo
export const AppointmentInfo_PATH =
  PREFIX_FANDIAN2 + '/registration/getAppointmentInfo'
export const AppointmentList_PATH =
  PREFIX_FANDIAN + '/health/appointmentUser/appointmentList'
export const AppointmentExport_Path =
  PREFIX_FANDIAN + '/health/appointmentUser/exportAppointment'

// ""
export const appointmentCancel_PATH =
  PREFIX_FANDIAN2 + '/registration/cancelAppointment'

export const org_PhycicalTypes_PATH =
  PREFIX_FANDIAN + '/health/organization/getOrganizationPhysicalType' //机构绑定体检类型

export const valid_PhysicalRecord_PATH =
  PREFIX_FANDIAN + '/health/physicalRecord/validData'
export const userGroupNumber_PATH =
  PREFIX_FANDIAN2 + '/registration/userGroupNumber'
//
export const organization_Statistics_PATH =
  PREFIX_FANDIAN + '/health/organization/organizationList'
export const transferPhysicalData_PATH =
  PREFIX_FANDIAN + '/health/organization/transferPhysicalData'
export const excelGeneratePdf_PATH =
  PREFIX_FANDIAN + '/health/physicalRecord/excelGeneratePdf'
export const itemRegistration_PATH =
  PREFIX_FANDIAN + '/health/itemRegistration/save'
export const updateItemCheckNum_PATH =
  PREFIX_FANDIAN + '/health/itemRegistration/updateCheckNumber'
export const getItemInfo_PATH =
  PREFIX_FANDIAN + '/health/itemRegistration/getItemInfo'
export const setReportEnv_item_Path =
  PREFIX_FANDIAN + '/health/itemRegistration/setReportEnv'
export const setMachine_item_Path =
  PREFIX_FANDIAN + '/health/itemRegistration/setMachine'
export const generateItemPdf_Path =
  PREFIX_FANDIAN + '/health/itemRegistration/generateItemPdf'
export const dcmInfo_LIST_Path = PREFIX_FANDIAN + '/health/dcmInfo/list'
export const uploadDCM_Path = PREFIX_FANDIAN + '/health/dcmInfo/uploadDCM'
export const updateDCM_Path = PREFIX_FANDIAN + '/health/dcmInfo/update'
export const batchSetItemResult_Path =
  PREFIX_FANDIAN + '/health/physicalRecord/batchSetItemResult' //批量设置物品报告结果
export const export_AppointGroup_PATH =
  PREFIX_FANDIAN + '/health/appointmentUser/group' //预约统计
export const export_parseExcel_PATH = PREFIX_FANDIAN2 + '/registration/parse' //备份文件转Excel

export const impot_appointmentUser_PATH =
  PREFIX_FANDIAN + '/health/appointmentUser/importAppointmentUser'
export const itemRegistration_delete_PATH =
  PREFIX_FANDIAN + '/health/itemRegistration/batchRemove'
export const two_cancer_login_PATH =
  'http://jiangpuliangai.natapp1.cc/mchis-controller/service/sysuser/login'

export const two_cancer_checklist_PATH =
  'http://jiangpuliangai.natapp1.cc/mchis-controller/service/cancerbase/queryMainGridForRecord'

export const wx_sendCode_PATH = PREFIX_FANDIAN2 + '/wx/official/sendCode'
export const getToken_cancer_PATH = PREFIX_GONGWEI + '/cancer/dock/getToken'
export const getRecord_TWOCANCER_PATH =
  'http://jiangpuliangai.natapp1.cc/mchis-controller/service/cancerbase/queryLatestByCardNo'
export const twoCancer_IsHave_PATH = PREFIX_FANDIAN2 + '/zhending/isHave'
export const wx_official_pdf_PATH = PREFIX_FANDIAN2 + '/wx/official/getPdf'

// export const get_root_area_PATH =
//   'http://gongwei_api.yeekcloud.com/v1/health/districts/getRoot'
// export const get_child_area_PATH =
//   'http://gongwei_api.yeekcloud.com/v1/health/districts/getChild'

//短链接
export const shortLink_save_PATH = PREFIX_FANDIAN + '/health/shortUrl/save' //新增短链接
export const shortLink_list_PATH = PREFIX_FANDIAN + '/health/shortUrl/list'
// export const dcm_doctor_list_PATH=PREFIX_FANDIAN + "/health/dcmInfo/dcmDoctor";
//胸片词条
export const batch_update_doctor_PATH =
  PREFIX_FANDIAN + '/health/dcmInfo/batchUpdateDoctor'
export const dcm_suggest_save_PATH = PREFIX_FANDIAN + '/health/dcmSuggest/save'
export const dcm_suggest_list_PATH = PREFIX_FANDIAN + '/health/dcmSuggest/list'
export const dcm_suggest_update_PATH =
  PREFIX_FANDIAN + '/health/dcmSuggest/update'
export const dcm_suggest_del_PATH = PREFIX_FANDIAN + '/health/dcmSuggest/remove'

// 胸片医生
export const dcm_doctor_save_PATH = PREFIX_FANDIAN + '/health/dcmDoctor/save'
export const dcm_doctor_update_PATH =
  PREFIX_FANDIAN + '/health/dcmDoctor/update'
export const dcm_doctor_list_PATH = PREFIX_FANDIAN + '/health/dcmDoctor/list'
export const dcm_doctor_del_PATH = PREFIX_FANDIAN + '/health/dcmDoctor/remove'
export const dcm_upload_file_PATH = PREFIX_FANDIAN2 + '/qiniu/uploadDcmFile'
export const generate_dcm_report_PATH =
  PREFIX_FANDIAN + '/health/dcmInfo/generateDcmReport'
export const dcm_result_import_PATH =
  PREFIX_FANDIAN + '/health/dcmInfo/insertDcmInfo'
// 流调码
// /api/health/healthDeclare/entry
export const healthDeclare_Entry_PATH =
  PREFIX_FANDIAN2 + '/health/healthDeclare/entry'
export const healthDeclare_Detail_PATH =
  PREFIX_FANDIAN2 + '/health/healthDeclare/detail'
export const healthDeclare_Delete_PATH =
  PREFIX_FANDIAN2 + '/health/healthDeclare/remove'

export const fubaosuo_getdata_PATH =
  PREFIX_FANDIAN2 + '/registration/thirdInterface'
export const dcm_delete_PATH = PREFIX_FANDIAN + '/health/dcmInfo/batchRemove'
export const dcm_export_PATH = PREFIX_FANDIAN + '/health/dcmInfo/exportDcmInfo'
export const dcm_update_PATH = PREFIX_FANDIAN + '/health/dcmInfo/update'
export const reportList_PATH = PREFIX_FANDIAN2 + '/wx/official/list'
export const reportDownLoad_PATH = PREFIX_FANDIAN2 + '/wx/official/preview'

//组套
export const stack_save_PATH = PREFIX_FANDIAN + '/health/stack/save'
export const stack_list_PATH = PREFIX_FANDIAN + '/health/stack/list'
export const stack_update_PATH = PREFIX_FANDIAN + '/health/stack/update'
export const stack_del_PATH = PREFIX_FANDIAN + '/health/stack/batchRemove'
//人员组套信息
export const userBindStack_PATH = PREFIX_FANDIAN + '/health/userBindStack/list'
export const UserStackDel_PATH =
  PREFIX_FANDIAN + '/health/userBindStack/batchRemove'
export const userStackExport_PATH =
  PREFIX_FANDIAN + '/health/userBindStack/export'
export const userImportStack_PATH =
  PREFIX_FANDIAN + '/health/userBindStack/importStack'
// B超
export const bmodelInfo_PATH = PREFIX_FANDIAN + '/health/bmodelInfo/list'
export const exportBmodel_PATH =
  PREFIX_FANDIAN + '/health/bmodelInfo/exportBmodel'
// 心电图
export const ecgInfo_PATH = PREFIX_FANDIAN + '/health/ecgInfo/list'
export const pissResult_PATH = PREFIX_FANDIAN + '/health/pissResult/list'
export const exportEcg_PATH = PREFIX_FANDIAN + '/health/ecgInfo/exportEcg'
export const deleteEcg_PATH = PREFIX_FANDIAN + '/health/ecgInfo/batchRemove'
export const deleteB_PATH = PREFIX_FANDIAN + '/health/bmodelInfo/batchRemove'
export const dcmSuggestimportExcel_PATH =
  PREFIX_FANDIAN + '/health/dcmSuggest/importExcel'
export const pissResultimportExcel_PATH =
  PREFIX_FANDIAN + '/health/pissResult/exportExcel'
export const pissResultremove_PATH =
  PREFIX_FANDIAN + '/health/pissResult/remove'
export const pissResultupdate_PATH =
  PREFIX_FANDIAN + '/health/pissResult/update'
export const sendApplySampleInfo_PATH =
  PREFIX_FANDIAN + '/health/userBindStack/sendApplySampleInfo'
export const jointGongWei_PATH =
  PREFIX_FANDIAN + '/health/userBindStack/jointGongWei'
export const cancelApplyForm_PATH =
  PREFIX_FANDIAN + '/health/userBindStack/cancelApplyForm'
export const appointmentUser_PATH =
  PREFIX_FANDIAN + '/health/appointmentUser/orderDetail'
// 理邦心电图
export const libangEcgList_PATH = PREFIX_FANDIAN + '/health/libangEcg/list'
export const libangEcgsave_PATH = PREFIX_FANDIAN + '/health/libangEcg/save'
export const libangEcgupdate_PATH = PREFIX_FANDIAN + '/health/libangEcg/update'
export const libangEcgremove_PATH = PREFIX_FANDIAN + '/health/libangEcg/remove'
export const libangEcgexportEcg_PATH = PREFIX_FANDIAN + '/health/libangEcg/exportEcg'
export const forPay_PATH = PREFIX_FANDIAN + '/wxpay/forPay'
export const sampleInfoinsert_PATH = PREFIX_FANDIAN + '/hs/sampleInfo/insert'
export const settingReportPath_PATH = PREFIX_FANDIAN2 + '/registration/settingReportPath'

// =套餐
export const mealList_PATH =
  PREFIX_FANDIAN + '/health/meal/list'
export const mealSave_PATH =
  PREFIX_FANDIAN + '/health/meal/save'
export const mealRemove_PATH =
  PREFIX_FANDIAN + '/health/meal/remove'

export const newUploadFilePath_PATH = PREFIX_FANDIAN2 + '/file/newUploadFile'
export const jscode2session_PATH = PREFIX_FANDIAN2 + '/registration/jscode2session'
// =====
export const generateSign_PATH = PREFIX_FANDIAN + '/wxpay/generateSign'
// 套餐分类
export const mealClassifyList_PATH =
  PREFIX_FANDIAN + '/health/mealClassify/list'
export const mealClassifySave_PATH =
  PREFIX_FANDIAN + '/health/mealClassify/save'
export const mealClassifyRemove_PATH =
  PREFIX_FANDIAN + '/health/mealClassify/remove'
// 周期
export const appointmentParamList_PATH =
  PREFIX_FANDIAN + '/health/appointmentParam/list'
export const appointmentParamSave_PATH =
  PREFIX_FANDIAN + '/health/appointmentParam/save'

export const appointmentParamRangeList_PATH =
  PREFIX_FANDIAN + '/health/appointmentParamRange/list'
export const appointmentParamRangeSave_PATH =
  PREFIX_FANDIAN + '/health/appointmentParamRange/save'
export const appointmentParamRangeRemove_PATH =
  PREFIX_FANDIAN + '/health/appointmentParamRange/remove'
export const appointmentParamRangeUpdate_PATH =
  PREFIX_FANDIAN + '/health/appointmentParamRange/update'