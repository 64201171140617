import React, { Component } from 'react'
import { Button, Modal, message, Alert, Image, Spin } from 'antd'
import { connect } from 'react-redux'
import QRCode from 'qrcode.react'
import liulanqi from '../../assets/lliulanqi.png'
import {
  getAppointmentInfoList,
  appointmentCancel,
  appointmentUser, forPay
} from '../../actions/account'
import cookie from 'react-cookies'
import './query.css'

const { confirm } = Modal

class QueryList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      appoints: [],
      idCard: undefined,
      orderIdArr: [],
      khd: '',
      loading: false,
      xsPay: [],
      type: '2',
      description: `请截图保存至手机，检查时现场核验。
      如有取消预约，预约时费用退款已原路返回`
    };

  }
  componentWillUnmount() {
    this.socket.close();
  }
  componentDidMount() {
    document.title = '预约查询结果'
    // ===

    // =========
    var params = this.props.location.search.substring(1).split('&')
    var val = []
    for (var i = 0; i < params.length; i++) {
      val = params[i].split('=')

      if (val[0] === 'idCard') {
        this.setState(
          {
            idCard: val[1],
            loading: true
          },
          () => {
            setTimeout(() => {
              this.loadDatas()
            }, 2000)
          }
        )
        // /==========
        this.socket = new WebSocket(`ws://121.89.193.72:8084/ws/appointment/${val[1]}`); // WebSocket 服务器地址

        this.socket.onopen = () => {
          console.log('WebSocket connected');
        };

        this.socket.onmessage = (event) => {
          console.log(event)
          this.loadDatas()
        };

        this.socket.onclose = () => {
          console.log('WebSocket disconnected');
        };
      }
      if (val[0] === 'type') {
        this.setState(
          {
            type: val[1]

          })
      }
    }
    var ua = window.navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == 'micromessenger') {
      this.setState({
        khd: 'wx',
      })
    } else {
      this.setState({
        khd: 'qt',
      })
    }

  }

  loadDatas = () => {
    var { idCard } = this.state
    var obj = { idCard: idCard }
    if (idCard === undefined) {
      cookie.remove('idCard')
      this.props.history.push('/appointQuery')
    }
    var that = this
    let inFifteenMinutes = new Date(new Date().getTime() + 24 * 3600 * 7000) //一天
    cookie.save('idCard', idCard, { expires: inFifteenMinutes })
    that.props.getAppointmentInfoList(obj).then((res) => {
      if (res.data.code === 200) {
        var appoints = res.data.data
        that.setState({
          appoints: appoints,
          loading: false,
          xsPay: []
        })
        // 获取支付路径
        if (appoints.length > 0) {
          this.getAppoint(appoints, 0)
        }
      }

    })
  }
  getAppoint = (arr, ind) => {
    var { xsPay } = this.state
    console.log(xsPay)
    if (arr[ind].orderStatus == '1') {
      let formData = new FormData()
      formData.append('orderId', arr[ind].orderId)
      this.props.forPay(formData).then(res1 => {
        ind++
        if (res1.data.code == '200') {
          xsPay.push(res1.data.message)
        } else {
          xsPay.push('')
        }
        this.setState({
          xsPay: xsPay
        })
        if (ind < arr.length) {
          this.getAppoint(arr, ind)
        }
      })
    } else {
      xsPay.push('')
      this.setState({
        xsPay: xsPay
      })
      ind++
      if (ind < arr.length) {
        this.getAppoint(arr, ind)
      }
    }

  }
  // appoint = (itm) => {
  //   const formData = new FormData()
  //   formData.append('orderId', itm.orderId)
  //   this.props.forPay(formData).then(res => {
  //     if (res.data.code == '200') {
  //       window.open(res.data.message, '_blank')
  //     } else {
  //       message.error(res.data.message)
  //     }
  //   })
  // }
  appointCancel = (id) => {
    var that = this
    confirm({
      content: '确认取消预约？',
      onOk() {
        that.props.appointmentCancel({ id: id }).then((res) => {
          if (res.data.code === 200) {
            message.success('取消预约成功！')
            that.loadDatas()
          }
        })
      },
    })
  }

  queryagain = () => {
    // localStorage.removeItem("idCard")
    cookie.remove('idCard')
    this.props.history.push('/appointQuery')
  }

  render() {
    const { appoints, khd, xsPay, type, description } = this.state
    return (
      <div>
        <div className="StatisticsPage-head1 pageHeader1">
          <h3>预约查询凭证</h3>
        </div>
        <Spin spinning={this.state.loading}>
          <div className="StatisticsPage-box1 has-select-table">
            {appoints.length > 0 && (
              <Alert
                message="温馨提示"
                description={description}
                type="success"
                showIcon
                style={{ whiteSpace: 'pre-line' }}
              />
            )}
            {appoints.length === 0 ? (
              <div> <div>暂无预约，请重新扫码预约</div>
                <div>如有取消预约，预约时费用退款已原路返回</div></div>

            ) : (
              appoints.map((item, index) => (
                <div>
                  {item.orderStatus == '1' && khd == 'wx' ? (
                    <div id="container" >
                      <div style={{ position: 'relative', paddingTop: '100px', color: '#333', fontSize: '22px', textAlign: 'center' }}>
                        <p>当前预约未支付</p>
                        <i style={{ position: 'absolute', top: '10px', right: '10px', fontSize: '100px' }}></i>
                        <p style={{ fontWeight: '600' }}>请点击屏幕右上角 [ ··· ] </p>
                        <p>在 <img src={liulanqi} alt="" srcset="" style={{ width: '25px' }} /> 浏览器打开支付</p></div>
                    </div>

                  ) : (
                    <div className="form-group-single appoint_info" key={index}>
                      <div className="form-group">
                        <label className="form-label1">姓名：</label>
                        <div className="form-ipt">{item.username}</div>
                      </div>
                      <div className="form-group">
                        <label className="form-label1">身份证号：</label>
                        <div className="form-ipt">{item.idCard}</div>
                      </div>
                      {!!item.mealName && (
                        <div className="form-group">
                          <label className="form-label1">套餐名称：</label>
                          <div className="form-ipt">{item.mealName}</div>
                        </div>
                      )}
                      {item.physicalTypeName !== '学生体检' && (
                        <div className="form-group">
                          <label className="form-label1">单位：</label>
                          <div className="form-ipt">{item.workUnit}</div>
                        </div>
                      )}

                      <div className="form-group">
                        <label className="form-label1">预约时间：</label>
                        <div className="form-ipt">
                          {/* {item.appointmentDate} */}
                          {item.physicalTypeName === '公卫'
                            ? '等候工作人员联系'
                            : item.appointmentDate}
                        </div>
                      </div>
                      {item.physicalTypeName !== '学生体检' &&
                        item.physicalTypeName !== '大学生体检' && (
                          <div className="form-group">
                            <label className="form-label1">体检类型：</label>
                            <div className="form-ipt">{item.physicalTypeName}</div>
                          </div>
                        )}
                      {!!item.orderStatus && (
                        <div className="form-group">
                          <label className="form-label1">支付状态：</label>
                          <div className="form-ipt">
                            {item.orderStatus == '0'
                              ? '无需支付'
                              : item.orderStatus == '1'
                                ? '待付款'
                                : item.orderStatus == '2'
                                  ? '待使用'
                                  : item.orderStatus == '3'
                                    ? '已完成'
                                    : ''}
                          </div>
                        </div>
                      )}
                      {item.orderStatus == '2' && (
                        <div className="form-group">
                          <label className="form-label1">订单条码：</label>
                          <div>
                            <QRCode value={item.orderId} />
                          </div>
                        </div>
                      )}
                      {item.orderStatus == '1' && (
                        <div className="form-group">
                          <label className="form-label1">支付按钮：</label>
                          {xsPay[index] != '' ? (
                            <a href={xsPay[index]} style={{ marginLeft: '30%', display: 'block', width: '40%', height: "40px", lineHeight: '40px', textAlign: 'center', background: '#2979ff', color: '#fff' }}>请点击支付</a>
                          ) : (< span > 暂无支付链接</span>)

                          }

                        </div>
                      )}
                      {item.physicalTypeName === '学生体检' &&
                        item.organizationName === '江浦街道社区卫生服务中心' && (
                          <div className="form-group">
                            <label className="form-label1">培训地址：</label>
                            <div className="form-ipt">
                              江浦街道社区卫生服务中心三楼儿童保健科
                            </div>
                          </div>
                        )}
                      {item.organizationName === '江浦街道社区卫生服务中心' &&
                        item.physicalTypeName === '两癌筛查' && (
                          <div className="form-group">
                            <label className="form-label1">体检地址：</label>
                            <div className="form-ipt">
                              江浦街道珠泉西路17-2号（消防大队旁）
                            </div>
                          </div>
                        )}
                      {/* {item.organizationName==="南京知康门诊部" &&
                               <div className="form-group">
                               <label className="form-label1">地址：</label>
                               <div className="form-ipt">
                              江浦街道文德东路35号鼎业国际大厦2201室南京浦口知康门诊部
                               </div>
                           </div>} */}

                      {item.organizationName === '江浦街道社区卫生服务中心' &&
                        item.physicalTypeName === '两癌筛查' && (
                          <div className="form-group">
                            <label className="form-label1">备注：</label>
                            <div className="form-ipt">
                              请携带身份证、医保卡参加体检
                            </div>
                          </div>
                        )}
                      {item.materials && (
                        <div className="form-group">
                          <label className="form-label1">单位材料：</label>
                          <div className="form-ipt">
                            <Image
                              width={80}
                              src={'http://minio.yeekcloud.com/' + item.materials}
                            />
                          </div>
                        </div>
                      )}
                      {item.idCardImg && (
                        <div className="form-group">
                          <label className="form-label1">身份证：</label>
                          <div className="form-ipt">
                            <Image
                              width={80}
                              src={'http://minio.yeekcloud.com/' + item.idCardImg}
                            />
                          </div>
                        </div>
                      )}
                      {item.orderStatus == '3' || type != '1' ? (
                        <span></span>
                      ) : (
                        <Button
                          className="cancelAppo"
                          type="primary"
                          size="small"
                          onClick={() => this.appointCancel(item.id)}
                        >
                          取消预约
                        </Button>
                      )
                      }

                    </div>
                  )}
                </div>


              ))
            )}

            {/* <p></p>
            <p></p>
            <p></p>
            <p></p>
            <div className="form-group-single appoint_info">
              <Button
                className="cancelAppo"
                type="primary"
                size="small"
                onClick={() => this.queryagain()}
              >
                重新查询
              </Button>
            </div> */}
          </div>
        </Spin >
      </div >
    )
  }
}
export default connect(null, {
  getAppointmentInfoList,
  appointmentCancel,
  appointmentUser, forPay
})(QueryList)
