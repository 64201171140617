import React, { Component } from 'react'
import MD5 from 'crypto-js/md5'
import { Modal, Button, Table, Input, message, Spin, Divider, Select } from 'antd';
import '../medicalRecord/RegistrationPage.css'
import {
    updateChildOrganization, addChildOrganization, getChildOrganizationList, getUsableMachine,
    getPhysicalTypeList, removeChildOrganization
} from '../../../actions/account'

// import _ from 'lodash'
import { connect } from 'react-redux'
const { Column } = Table;
const { confirm } = Modal;
const { Option } = Select;

class MachineManage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedRowKeys: [],
            spinning: false,
            isAdmin: 0,
            account: '', password: '',
            size: 10,
            dataList: [],
            curRecord: null,
            visible: false,
            page: 1,
            total: 0,
            isRequesting: false,
            bindVisble: false,
            useMachineList: [],
            machineId: '',
            physicalType: '',
            barcode: '0000',
            physicalTypeList: [],
            accountType: "Web"
        };
    }

    componentDidMount() {
        this.loadDatas();
    }

    loadDatas = () => {
        this.setState({ spinning: true })

        var { page, size } = this.state;
        var obj = { page: page - 1, size: size };
        var that = this;
        that.props.getChildOrganizationList(obj).then(msg => {
            console.log(msg)
            if (msg.data.code === 200) {
                if (msg.data.data.rows.length === 0 && msg.data.data.total !== 0) {
                    that.setState({ page: page - 1 }, () => {
                        that.loadDatas()
                    })
                }
                else {
                    that.setState({
                        dataList: msg.data.data.rows,
                        total: msg.data.data.total,
                        spinning: false
                    })
                }
            }
        })

    }

    showModal = () => {
        this.setState({
            curRecord: undefined,
            visible: true
        })
    }

    handleCancel = () => {
        this.setState({ visible: false, account: '', password: '' });

    }

    accountChange = e => {
        this.setState({
            account: e.target.value
        })
    }
    passwordChange = e => {
        this.setState({
            password: e.target.value
        })
    }

    barcodeChange = e => {
        this.setState({
            barcode: e.target.value
        })
    }
    handleOk = () => {
        var { account, password, isRequesting, accountType } = this.state;
        if (isRequesting) {
            return
        }
        const regEmail = /^(?=.*\d)(?=.*?[a-z])(?=.*?[A-Z])(?=.*[~!@#$%^&*])[\da-zA-Z~!@#$%^&*]{6,18}$/;
        if (!regEmail.test(password)) {
            message.error("密码必须由数字字母、大小写及特殊字符组成");
            return;
        }
        var that = this;
        var data = localStorage.getItem("data");
        var oobj = JSON.parse(data);
        var obj = {
            account: account,
            password: MD5(password).toString(),
            organizationName: oobj.organizationName,
            accountType: accountType
        }
        this.setState({ isRequesting: true }, () => {
            that.props.addChildOrganization(obj).then(msg => {
                console.log(msg)
                if (msg.data.code === 200) {
                    that.setState({ visible: false, account: '', password: '' });
                    that.loadDatas();
                }
                else {
                    message.error(msg.data.message)
                }
                that.setState({ isRequesting: false })
            })
        })
    }
    editItem = (record) => {
        this.setState({
            curRecord: record,
            account: record.account,
            bindVisble: true,
        })
    }

    organizationOnChange = (value) => {
        this.setState({ machineId: value })
    }


    delItem = (recorrd) => {
        var { selectedRowKeys } = this.state;
        if (selectedRowKeys.length === 0 && recorrd === 'all') {
            message.error("请选择删除的记录")
            return;
        }
        if (recorrd !== 'all') {
            selectedRowKeys = [recorrd.id]
        }
        console.log(selectedRowKeys)
        var that = this;
        confirm({
            content: "确认删除？",
            onOk() {
                that.props.removeChildOrganization({ ids: selectedRowKeys.join(",") }).then(msg => {
                    if (msg.data.code === 200) {
                        message.success("删除成功！");
                        that.loadDatas();
                    }
                })
            }
        });

    }
    handleBindOk = () => {
        var { isRequesting, account, curRecord, password } = this.state;
        if (isRequesting) {
            return
        }
        const regEmail = /^(?=.*\d)(?=.*?[a-z])(?=.*?[A-Z])(?=.*[~!@#$%^&*])[\da-zA-Z~!@#$%^&*]{6,18}$/;
        if (!regEmail.test(password)) {
            message.error("密码必须由数字字母、大小写及特殊字符组成");
            return;
        }
        var obj = {
            id: Number(curRecord.id),
        }
        if (account !== curRecord.account) { obj.account = account }
        var data = localStorage.getItem("data");
        var oobj = JSON.parse(data);
        obj.organizationName = oobj.organizationName;
        if (password.length > 0) { obj.password = MD5(password) }
        console.log(obj)
        var that = this;
        this.setState({ isRequesting: false }, () => {
            that.props.updateChildOrganization(obj).then(msg => {
                if (msg.data.code === 200) {
                    message.success("修改成功！");
                    that.handleBindCancel()
                    that.loadDatas();
                }
                else {
                    message.error(msg.data.message)
                }
                that.setState({ isRequesting: false })
            })
        })

    }

    handleBindCancel = () => {
        this.setState({
            bindVisble: false,
            account: undefined,
            password: undefined,
            curRecord: undefined
        })
    }
    onphysicalTypeChange = e => {
        console.log('radio checked', e.target.value);
        // setValue(e.target.value);
        this.setState({
            physicalType: e.target.value
        })
    };
    onisAdminChange = e => {
        this.setState({
            isAdmin: e.target.value
        })
    };
    changePage = page => {
        var that = this;
        this.setState({ page }, () => {
            that.loadDatas()
        })
    }
    // 选中改变
    onSelectChange = selectedRowKeys => {
        console.log(selectedRowKeys)
        this.setState({ selectedRowKeys });
    };

    accountTypeChange = value => {
        this.setState({
            accountType: value
        })
    }
    render() {
        const { selectedRowKeys, spinning, password, dataList, account, bindVisble, accountType } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        return (
            <div>
                <div className="StatisticsPage-head pageHeader"><h3>账号管理</h3></div>
                <div className="StatisticsPage-box">
                    <div className="StatisticsPage-search">
                        <div className="StatisticsPage-btn">
                            <Button className="btn" type="primary" onClick={this.showModal}>添加</Button>
                            <Button className="btn" type="primary" onClick={() => this.delItem('all')}>删除</Button>
                            <Modal title="添加账号" visible={this.state.visible} onOk={this.handleOk} onCancel={this.handleCancel} okText="保存" cancelText="取消">
                                <div className="form-group">
                                    <label className="form-label">账号：</label>
                                    <div className="form-ipt">
                                        <Input value={account} placeholder="请输入账号" onChange={e => this.accountChange(e)} />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="form-label">密码：</label>
                                    <div className="form-ipt">
                                        <Input value={password} placeholder="请输入密码" onChange={e => this.passwordChange(e)} />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="form-label">账号类型：</label>
                                    <div className="form-ipt">
                                        <Select style={{ width: "100%" }} placeholder="请选择账号类型" value={accountType} onChange={this.accountTypeChange} >
                                            <Option key="Xcx" value="Xcx">小程序</Option>
                                            <Option key="Web" value="Web">Web网站</Option>
                                            <Option key="APP" value="APP">APP</Option>

                                        </Select>
                                    </div>
                                </div>

                            </Modal>
                        </div>
                    </div>
                    <div>
                        <Spin tip="Loading..." spinning={spinning}>
                            <Table size="middle" dataSource={dataList} bordered rowKey="id" pagination={{
                                pageSize: this.state.size, showTotal: ((total) => {
                                    return `共 ${total} 条`;
                                }),
                                onChange: this.changePage, total: this.state.total, showSizeChanger: false
                            }}
                                rowSelection={rowSelection}
                                onRow={(record, index) => {
                                    return {
                                        onClick: () => {
                                            let temp = this.state.selectedRowKeys
                                            let index = temp.indexOf(record.id)
                                            if (index === -1) { temp.push(record.id) }
                                            else { temp.splice(index, 1) }
                                            this.setState({ selectedRowKeys: temp });
                                        }
                                    }
                                }}>
                                <Column title="账号" dataIndex="account" key="account" align="center" />
                                <Column title="机构名称" dataIndex="organizationName" key="organizationName" align="center" />
                                <Column title="操作" align="center" key="action"
                                    render={(text, record) => (
                                        <span>
                                            <span style={{ cursor: 'pointer' }} onClick={() => this.editItem(record)}>修改</span>
                                            <Divider type="vertical" />
                                            <span style={{ cursor: 'pointer' }} onClick={() => this.delItem(record)} >删除</span>
                                        </span>
                                    )} />
                            </Table>
                        </Spin>
                        <Modal title="修改" visible={bindVisble} onOk={this.handleBindOk} onCancel={this.handleBindCancel} okText="保存" cancelText="取消">
                            <div className="form-group">
                                <label className="form-label">账号：</label>
                                <div className="form-ipt">
                                    <Input value={account} placeholder="请输入账号" onChange={e => this.accountChange(e)} />
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="form-label">密码：</label>
                                <div className="form-ipt">
                                    <Input value={password} placeholder="请输入密码" onChange={e => this.passwordChange(e)} />
                                </div>
                            </div>
                            {/* <div className="form-group">
                                    <label className="form-label">子机构名称：</label>
                                    <div className="form-ipt">
                                        <Input value={organizationName} placeholder="子机构名称" onChange={e => this.organizationNameChange(e)} />
                                    </div>
                                </div> */}
                        </Modal>
                    </div>
                </div>
            </div>
        )
    }
}
export default connect(null, {
    updateChildOrganization, addChildOrganization, getChildOrganizationList, getUsableMachine,
    getPhysicalTypeList, removeChildOrganization
})(MachineManage)