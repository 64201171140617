import React, { Component } from 'react'
import { Button } from 'antd';
import { connect } from 'react-redux'
import { mealList } from '../../actions/account'
import './query.css'
import cookie from 'react-cookies';




class studentsNew_yuyue extends Component {
    constructor(props) {
        super(props);
        this.state = {

            erweimaName: '',
            erweimaOrg: '',
            erweimaOrgName: '',
            erweimaPhy: '',
            khd: '',
        }

    }
    componentDidMount() {
        console.log(this.props.location.state)
        this.setState({

            erweimaName: this.props.location.state.erweimaName,
            erweimaOrg: this.props.location.state.erweimaOrg,
            erweimaOrgName: this.props.location.state.erweimaOrgName,
            erweimaPhy: this.props.location.state.erweimaPhy,
        })
    }
    goback = () => {
        this.props.history.go(-1)
    }


    render() {
        const {

            detail

        } = this.state
        return (
            <div className="appointquery-bg">
                <div className="StatisticsPage-head1 pageHeader1">
                    <h3>体检预约</h3>
                </div>
                <div>

                </div>


            </div >
        )
    }
}
export default connect(null, {
    mealList
})(studentsNew_yuyue)