import React, { Component } from 'react';
// const zhanspdf = require('../../../assets/20240222-231047-1.pdf');
class WebSocketComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      messages: [],
    };
    this.socket = new WebSocket('ws://121.89.193.72:8084/ws/appointment/320911198610236686'); // WebSocket 服务器地址

    this.socket.onopen = () => {
      console.log('WebSocket connected');
    };

    this.socket.onmessage = (event) => {
      console.log(event)
      const message = JSON.parse(event.data);
      this.setState((prevState) => ({
        messages: [...prevState.messages, message],
      }));
    };

    this.socket.onclose = () => {
      console.log('WebSocket disconnected');
    };
  }
  componentDidMount() {

  }
  componentWillUnmount() {
    this.socket.close();
  }

  render() {
    return (
      <div>
        <h1>WebSocket Messages:</h1>
        <ul>
          {this.state.messages.map((message, index) => (
            <li key={index}>{message}</li>
          ))}
        </ul>
        {/* ========== */}
        {/* <embed id="pdfEmbed" src={zhanspdf} type="application/pdf" width='100%' height='800' zoom='90%'></embed> */}
      </div>
    );
  }
}

export default WebSocketComponent;