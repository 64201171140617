import React, { Component } from 'react'
import { connect } from 'react-redux'
// import headerlogo from '../../../assets/login-img/img_logo.png'
import {
  Button,
  message,
  Input,
  Select,
  Switch,
  Radio,
  Modal,
  Image,
} from 'antd'
import './pic_Read.css'
import {
  getDcmInfolIST,
  updateDCM,
  dcmSuggestList,
  dcmDelete,
} from '../../../actions/account'
import { PlusOutlined } from '@ant-design/icons'
// import { suggest } from './DATA'
var el = undefined
const { TextArea } = Input
const { Option } = Select
const { confirm } = Modal
class pic_Read extends Component {
  canvasRef = React.createRef()
  canvasRef2 = React.createRef()
  constructor(props) {
    super(props)
    this.arr = []
    this.state = {
      isRoam: false,
      picSize: '800',
      defaultPicSize: '800',
      line1Length: null,
      line2Length: null,
      isLast: false,
      suggestList: [],
      el: undefined,
      mouseDownFlag: false,
      mouseDownClientX: undefined,
      mouseDownClientY: undefined,
      imgWidth: 0,
      imgheight: 0,
      imgleft: 0,
      imgtop: 0,
      cutState: false,
      page: 1,
      size: 10,
      total: 0,
      dcmList: [],
      picUrl: '',
      barcode: '',
      zdjy: null,
      zdjyContent: '胸部未见明显器质性病变。',
      jcsj: null,
      showJCSJ: true,
      sfzc: undefined,
      currentIndex: 0,
      currentRecord: null,
      brightness: 100,
      contrast: 1,
      isMagnifier: false,
      isCounting: false,
      searchObj: {},
      suggestSel: '',
      suggest: '',
      jianyiList: [],
      mouseX: 0,
      mouseY: 0,
      leftDistance: 0,
      topDistance: 0
    }
  }

  componentDidMount() {
    let canvas = this.canvasRef.current
    this.canvasContext = canvas.getContext('2d')
    this.canvasWidth = canvas.width
    this.canvasHeight = canvas.height
    this.targetImage = document.getElementById('targetImage')
    // /=====
    document.addEventListener('mousemove', this.handleMouseMove);
    this.getElementPosition();
    // /========

    var record = JSON.parse(this.props.location.state.record)
    var that = this
    this.setState(
      {
        currentRecord: record,
        picUrl: record.filePath,
        barcode: record.barcode,
        page: this.props.location.state.page,
        searchObj: this.props.location.state.searchObj,
        currentIndex: this.props.location.state.index,
      },
      () => {
        that.getDcmInfolIST()
        el = document.getElementById('targetImage')
        console.log(el.height, el.width)
        this.setState({
          imgWidth: el.naturalWidth,
          imgheight: el.naturalHeight,
          // imgleft: el.offsetLeft,
          // imgtop: el.offsetTop,
        })
        el.style.height = this.state.picSize + 'px'
        el.style.width = this.state.picSize + 'px'
        this.setState({
          el,
        })

        this.props
          .dcmSuggestList({ page: 0, size: 10000, type: '1' })
          .then((res) => {
            if (res.data.code === 200) {
              that.setState({ suggestList: res.data.data.rows })
            }
          })
        this.props
          .dcmSuggestList({ page: 0, size: 10000, type: '2' })
          .then((res) => {
            if (res.data.code === 200) {
              that.setState({ jianyiList: res.data.data.rows })
            }
          })
      }
    )
  }
  getElementPosition = () => {
    const element = document.getElementById('canvas2');
    const rect = element.getBoundingClientRect();
    console.log(rect.left)
    console.log(rect.top)
    this.setState({
      leftDistance: rect.left,
      topDistance: rect.top
    });
  }
  handleMouseMove = (e) => {


    if (this.state.isMagnifier) {
      let left = ((e.clientX - this.state.leftDistance) * this.state.imgWidth) / 800 - 200
      let top = ((e.clientY - this.state.topDistance) * this.state.imgheight) / 800 - 200
      // right top
      if (e.clientX - 400 > 600 || e.clientY < this.state.topDistance) {
        document.getElementById('canvas').style.display = 'none'
        return
      }
      if (e.clientX < this.state.leftDistance) {
        document.getElementById('canvas').style.display = 'none'
        return
      }
      document.getElementById('canvas').style.display = 'block'
      this.canvasContext.clearRect(0, 0, this.canvasWidth, this.canvasHeight)
      // if(isMagnifier){
      this.canvasContext.drawImage(
        this.targetImage,
        left,//图像的内容
        top,//
        400,
        400,
        0,//中间指针的位置
        0,//中间指针的位置
        400,//宽
        400//高
      )
      document.getElementById('canvas').setAttribute('style', `left:${e.clientX - 400}px;top:${e.clientY - 400}px`)
    }
  };
  getDcmInfolIST = () => {
    var { page, size, dcmList, currentIndex, searchObj } = this.state
    console.log(searchObj)
    var that = this
    var obj = { page: page - 1, size: size }
    if (searchObj.username) obj.username = searchObj.username
    if (searchObj.barcode) obj.barcode = searchObj.barcode
    if (searchObj.startTime) obj.startTime = searchObj.startTime
    if (searchObj.endTime) obj.endTime = searchObj.endTime
    if (searchObj.isError !== undefined) obj.isError = searchObj.isError
    if (searchObj.isRead !== undefined) obj.isRead = searchObj.isRead
    if (searchObj.isauditStatus !== undefined)
      obj.auditStatus = searchObj.isauditStatus

    message.loading('加载中...', 0)
    this.props.getDcmInfolIST(obj).then((msg) => {
      console.log(msg.data.data.rows)
      message.destroy()
      if (msg.data.code === 200) {
        if (msg.data.data.rows.length === 0 && msg.data.data.total !== 0) {
          message.info('这是最后一张')
          that.setState({
            page: page - 1,
            isLast: true,
            currentIndex: dcmList.length - 1,
            currentRecord: dcmList[dcmList.length - 1],
          })
        } else {
          // '胸廓对称，两肺野清晰，两肺内未见明显异常密度影，心影大小、形态在正常范围，两膈面光滑，两侧肋膈角锐利。'
          console.log(msg.data.data.rows[currentIndex])
          msg.data.data.rows[currentIndex].diagnoseResult =
            msg.data.data.rows[currentIndex].diagnoseResult == null
              ? '胸部未见明显器质性病变。'
              : msg.data.data.rows[currentIndex].diagnoseResult
          that.setState(
            {
              isLast: false,
              dcmList: msg.data.data.rows,
              total: msg.data.data.total,
              currentRecord: msg.data.data.rows[currentIndex],
              picUrl: msg.data.data.rows[currentIndex].filePath,
              barcode: msg.data.data.rows[currentIndex].barcode,
              sfzc:
                msg.data.data.rows[currentIndex].diagnoseResult === null
                  ? undefined
                  : msg.data.data.rows[currentIndex].diagnoseResult ===
                    '胸部未见明显器质性病变。'
                    ? 1
                    : 2,
              zdjy: msg.data.data.rows[currentIndex].diagnoseResult,
              suggestSel: msg.data.data.rows[currentIndex].suggestSel,
              suggest: msg.data.data.rows[currentIndex].suggest,
              zdjyContent: //this.moveSubstringToFront(
                msg.data.data.rows[currentIndex].diagnoseResult,
              //  '主动脉粥样硬化。'
             // ),
              showJCSJ: true,
              // jcsj:
              //   msg.data.data.rows[currentIndex].diagnoseResult ===
              //   '胸部未见明显器质性病变。'
              //     ? '胸廓对称，两肺野清晰，两肺内未见明显异常密度影，心影大小、形态在正常范围，两膈面光滑，两侧肋膈角锐利。'
              //     : msg.data.data.rows[currentIndex].examinationInfo,
              jcsj: !!msg.data.data.rows[currentIndex].examinationInfo
                ? msg.data.data.rows[currentIndex].examinationInfo
                : msg.data.data.rows[currentIndex].diagnoseResult ===
                  '胸部未见明显器质性病变。'
                  ? '胸廓对称，两肺野清晰，两肺内未见明显异常密度影，心影大小、形态在正常范围，两膈面光滑，两侧肋膈角锐利。'
                  : msg.data.data.rows[currentIndex].examinationInfo,
            },
            () => {
              var el = document.getElementById('targetImage')
              console.log(el.height, el.width)
              this.setState({
                imgWidth: el.naturalWidth,
                imgheight: el.naturalHeight,
                // imgleft: el.offsetLeft,
                // imgtop: el.offsetTop,
              })
              el.style.height = this.state.defaultPicSize + 'px'
              el.style.width = this.state.defaultPicSize + 'px'
              this.setState({
                el,
                picSize: this.state.defaultPicSize,
              })
            }
          )
        }
      }
    })
  }
  resetImg = () => {
    el.style.height = this.state.defaultPicSize + 'px'
    el.style.width = this.state.defaultPicSize + 'px'
    el.style.left = 0 + 'px'
    el.style.top = 0 + 'px'
    this.setState({ picSize: this.state.defaultPicSize })
    this.clearLine()
  }
  // onwheel = (e) => {
  //   var {el}=this.state;
  //   let delta = (e.deltaY > 0 ? 1 : -1)
  //   if (delta > 0) {//放大
  //     // 向上滚
  //     let oWidth = el.offsetWidth;//取得图片的实际宽度
  //     let oHeight = el.offsetHeight; //取得图片的实际高度
  //     if (el.offsetWidth < 1000) {
  //       el.style.width = (oWidth + 50) + "px"
  //       el.style.height = (oHeight + 50 / oWidth * oHeight) + "px"
  //     }

  //   } else if (delta < 0) {//缩小
  //     //向下滚
  //     let oWidth = el.offsetWidth; //取得图片的实际宽度
  //     let oHeight = el.offsetHeight; //取得图片的实际高度
  //     if (el.offsetWidth - 50 > this.state.imgWidth || el.offsetHeight - 50 > this.state.imgheight) {//判断如果图片缩小到原图大小就停止缩小(100和75分别为原图的宽高)
  //       el.style.width = oWidth - 50 + "px"
  //       el.style.height = oHeight - 50 / oWidth * oHeight + "px"
  //     }
  //   }
  //   this.setState({el})
  // }
  // onMouseDown = (e) => {
  //   var { clientX, clientY } = e;
  //   e.preventDefault();
  //   e.stopPropagation();
  //   this.setState({ mouseDownFlag: true, mouseDownClientX: clientX, mouseDownClientY: clientY })
  // }
  // onMouseMove = (e) => {
  //   e.preventDefault();
  //   e.stopPropagation();
  //   var { clientX, clientY } = e;
  //   var { mouseDownFlag, mouseDownClientX, mouseDownClientY } = this.state;
  //   if (mouseDownFlag) {
  //     const diffX = clientX - mouseDownClientX;
  //     const diffY = clientY - mouseDownClientY;
  //     const offsetLeft = el.offsetLeft;
  //     const offsetTop = el.offsetTop;
  //     const offsetX = diffX / 15 + offsetLeft;
  //     const offsetY = diffY / 15 + offsetTop;
  //     el.style.left = offsetX + "px"
  //     el.style.top = offsetY + "px"
  //   }
  // }
  // onMouseUp = (e) => {
  //   e.preventDefault();
  //   e.stopPropagation();
  //   this.setState({ mouseDownFlag: false, mouseDownClientX: undefined, mouseDownClientY: undefined })

  // }
  cutImg = (e) => {
    this.setState({ cutState: !this.state.cutState })
  }
  jcsjChange = (e) => {
    this.setState({
      // jcsj: e.target.value.trim(),
      jcsj: e.target.value,
    })
  }
  suggestChange = (value, option) => {
    console.log(option)
    var { jcsj, zdjy, zdjyContent } = this.state
    if (zdjyContent !== undefined && zdjyContent !== null) {
      zdjyContent = zdjyContent + option.props.children
    } else {
      zdjyContent = option.props.children
    }
    if (jcsj !== undefined && jcsj !== null) {
      jcsj = jcsj + '\n' + option.props.data_desc
    } else {
      jcsj = option.props.data_desc
    }
   // zdjyContent = this.moveSubstringToFront(zdjyContent, '主动脉粥样硬化。')
    this.setState({ zdjy: option.props.children, zdjyContent })
    if (this.state.showJCSJ) {
      this.setState({ jcsj })
    }
  }
  jyselChange = (e) => {
    this.setState({ suggestSel: e, suggest: e })
  }
  jyContentChange = (e) => {
    this.setState({ suggest: e.target.value })
  }
  showJCSJonChange = (checked) => {
    this.setState({ showJCSJ: checked })
    if (!checked) {
      this.setState({ jcsj: null })
    }
  }
  zdjyContentChange = (e) => {
    this.setState({ zdjyContent: e.target.value })
  }
  moveSubstringToFront(str, substring) {
    // 检查字符串中是否包含指定的子字符串
    if (str.includes(substring)) {
      // 将指定的子字符串从原字符串中移除
      str = str.replace(substring, '')
      // 将指定的子字符串放在最前面
      str = substring + str
    }
    return str
  }
  onStateChange = (e) => {
    if (e.target.value === 1) {
      this.setState({
        zdjy: '胸部未见明显器质性病变。',
        showJCSJ: true,
        zdjyContent: '胸部未见明显器质性病变。',
      })
      if (this.state.showJCSJ) {
        this.setState({
          jcsj: '胸廓对称，两肺野清晰，两肺内未见明显异常密度影，心影大小、形态在正常范围，两膈面光滑，两侧肋膈角锐利。',
        })
      } else {
        this.setState({ jcsj: null })
      }
    } else {
      this.setState({
        zdjy: null,
        showJCSJ: true,
        jcsj: null,
        zdjyContent: null,
      })
    }
    this.setState({ sfzc: e.target.value })
  }
  upImage = () => {
    var { currentIndex, dcmList, page, size } = this.state
    if (currentIndex === 0) {
      if (page === 1) {
        message.info('这是第一张')
      } else {
        this.setState(
          {
            page: page - 1,
            currentIndex: size - 1,
            sfzc: undefined,
            zdjy: null,
            showJCSJ: true,
            jcsj: null,
            suggestSel: '',
            suggest: '',
          },
          () => {
            this.getDcmInfolIST()
          }
        )
      }
    } else {
      dcmList[currentIndex - 1].diagnoseResult =
        dcmList[currentIndex - 1].diagnoseResult == null
          ? '胸部未见明显器质性病变。'
          : dcmList[currentIndex - 1].diagnoseResult
      this.setState(
        {
          currentIndex: currentIndex - 1,
          currentRecord: dcmList[currentIndex - 1],
          barcode: dcmList[currentIndex - 1].barcode,
          picUrl: dcmList[currentIndex - 1].filePath,
          sfzc:
            dcmList[currentIndex - 1].diagnoseResult === null
              ? undefined
              : dcmList[currentIndex - 1].diagnoseResult ===
                '胸部未见明显器质性病变。'
                ? 1
                : 2,
          zdjy: dcmList[currentIndex - 1].diagnoseResult,
          suggestSel: dcmList[currentIndex - 1].suggestSel,
          suggest: dcmList[currentIndex - 1].suggest,
          showJCSJ: true,
          // jcsj:
          //   dcmList[currentIndex - 1].diagnoseResult ===
          //   '胸部未见明显器质性病变。'
          //     ? '胸廓对称，两肺野清晰，两肺内未见明显异常密度影，心影大小、形态在正常范围，两膈面光滑，两侧肋膈角锐利。'
          //     : dcmList[currentIndex - 1].examinationInfo,
          jcsj: !!dcmList[currentIndex - 1].examinationInfo
            ? dcmList[currentIndex - 1].examinationInfo
            : dcmList[currentIndex - 1].diagnoseResult ===
              '胸部未见明显器质性病变。'
              ? '胸廓对称，两肺野清晰，两肺内未见明显异常密度影，心影大小、形态在正常范围，两膈面光滑，两侧肋膈角锐利。'
              : dcmList[currentIndex - 1].examinationInfo,
        },
        () => {
          var el = document.getElementById('targetImage')
          el.style.cursor = 'default'
          console.log(el.naturalWidth, el.naturalWidth)
          this.setState({
            imgWidth: el.naturalWidth,
            imgheight: el.naturalHeight,
            // imgleft: el.offsetLeft,
            // imgtop: el.offsetTop,
          })
          el.style.height = this.state.defaultPicSize + 'px'
          el.style.width = this.state.defaultPicSize + 'px'
          this.setState({
            el,
            picSize: this.state.defaultPicSize,
          })
        }
      )
    }
    this.clearLine()
  }
  downImage = (e) => {
    console.log('下一章')
    this.clearLine()

    var { currentIndex, dcmList, page, isLast } = this.state
    if (currentIndex + 1 === dcmList.length) {
      if (isLast) {
        message.info('这是最后一张')
      } else {
        this.setState(
          {
            page: page + 1,
            currentIndex: 0,
            sfzc: undefined,
            zdjy: null,
            showJCSJ: true,
            jcsj: null,
            suggestSel: '',
            suggest: '',
          },
          () => {
            this.getDcmInfolIST()
          }
        )
      }
    } else {
      dcmList[currentIndex + 1].diagnoseResult =
        dcmList[currentIndex + 1].diagnoseResult == null
          ? '胸部未见明显器质性病变。'
          : dcmList[currentIndex + 1].diagnoseResult
      this.setState(
        {
          currentIndex: currentIndex + 1,
          currentRecord: dcmList[currentIndex + 1],
          barcode: dcmList[currentIndex + 1].barcode,
          picUrl: dcmList[currentIndex + 1].filePath,
          sfzc:
            dcmList[currentIndex + 1].diagnoseResult === null
              ? undefined
              : dcmList[currentIndex + 1].diagnoseResult ===
                '胸部未见明显器质性病变。'
                ? 1
                : 2,
          zdjyContent: //this.moveSubstringToFront(
            dcmList[currentIndex + 1].diagnoseResult,
        //    '主动脉粥样硬化。'
       //   ),
          zdjy: dcmList[currentIndex + 1].diagnoseResult,
          suggestSel: dcmList[currentIndex + 1].suggestSel,
          suggest: dcmList[currentIndex + 1].suggest,
          showJCSJ: true,
          // jcsj:
          //   dcmList[currentIndex + 1].diagnoseResult ===
          //   '胸部未见明显器质性病变。'
          //     ? '胸廓对称，两肺野清晰，两肺内未见明显异常密度影，心影大小、形态在正常范围，两膈面光滑，两侧肋膈角锐利。'
          //     : dcmList[currentIndex + 1].examinationInfo,
          jcsj: !!dcmList[currentIndex + 1].examinationInfo
            ? dcmList[currentIndex + 1].examinationInfo
            : dcmList[currentIndex + 1].diagnoseResult ===
              '胸部未见明显器质性病变。'
              ? '胸廓对称，两肺野清晰，两肺内未见明显异常密度影，心影大小、形态在正常范围，两膈面光滑，两侧肋膈角锐利。'
              : dcmList[currentIndex + 1].examinationInfo,
        },
        () => {
          var el = document.getElementById('targetImage')
          el.style.cursor = 'default'
          console.log(el.naturalWidth, el.naturalWidth)
          this.setState({
            imgWidth: el.naturalWidth,
            imgheight: el.naturalHeight,
            // imgleft: el.offsetLeft,
            // imgtop: el.offsetTop,
          })
          el.style.height = this.state.defaultPicSize + 'px'
          el.style.width = this.state.defaultPicSize + 'px'
          this.setState({
            el,
            picSize: this.state.defaultPicSize,
          })
        }
      )
    }
  }
  save = () => {
    var that = this
    var {
      currentRecord,
      zdjy,
      jcsj,
      sfzc,
      currentIndex,
      dcmList,
      zdjyContent,
      suggestSel,
      suggest,
    } = this.state
    if (sfzc === undefined) {
      message.error('请选择是否正常！')
      return
    }
    if (sfzc === '')
      if (!zdjy || zdjy.length === 0) {
        message.error('请选择诊断建议！')
        return
      }
    // confirm({
    //   title: '确定提交吗？',
    //   onOk() {
    jcsj = jcsj.trim()

    var obj = {
      id: currentRecord.id,
      diagnoseResult: zdjyContent,
      examinationInfo: jcsj,
      isError: sfzc === 1 ? 1 : 0,
      suggestSel: suggestSel,
    }
    if (suggest) {
      obj.suggest = suggest.trim()
    }
    that.props.updateDCM(obj).then((msg) => {
      if (msg.data.code === 200) {
        message.success('提交成功！')
        dcmList[currentIndex].diagnoseResult = zdjyContent
        dcmList[currentIndex].examinationInfo = jcsj
        dcmList[currentIndex].suggest = suggest
        dcmList[currentIndex].suggestSel = suggestSel
        that.setState({ dcmList }, () => {
          that.downImage()
        })
        this.clearLine()
      } else {
        message.error(msg.data.message)
      }
      that.setState({ isRequesting: false })
    })
    //   },
    //   onCancel() {
    //   },
    // });
  }
  goBack = () => {
    var { searchObj } = this.state
    this.props.history.push({ pathname: '/admin/DCM', state: { searchObj } })
  }
  lightOnClick = (mark) => {
    var { brightness, picUrl, contrast, isMagnifier, isCounting, isRoam } =
      this.state
    el = document.getElementById('targetImage')

    if (mark === 1) {
      this.resetImg()
      brightness = 100
      contrast = 1
    } else if (mark === 2) {
      brightness = brightness + 5
    } else if (mark === 3) {
      brightness = brightness - 5
    } else if (mark === 4) {
      window.open('/browseDCM?url=' + picUrl, '_blank')
    } else if (mark === 5) {
      contrast = contrast + 0.1
    } else if (mark === 6) {
      contrast = contrast - 0.1
    } else if (mark === 7) {
      //开启放大镜
      this.setState({ isMagnifier: !isMagnifier, isRoam: false }, () => {
        console.log(isMagnifier)
        if (isMagnifier) {
          document.getElementById('canvas').style.display = 'none'
        } else {
          el.style.cursor = 'default'
          this.resetImg()
          document.getElementById('canvas2').style.zIndex = '9998'
        }

      })
      return
    } else if (mark === 8) {
      el.style.cursor = 'default'
      this.setState({ isCounting: !isCounting }, () => {
        if (this.state.isCounting)
          document.getElementById('canvas2').style.zIndex = '9998'
      })
      return
    } else if (mark === 9) {
      // 放大
      el.style.height = this.state.picSize * 1.5 + 'px'
      el.style.width = this.state.picSize * 1.5 + 'px'
      // el.style.transform="translate(-50%,-50%)"
      // el.style.left="50%"
      // el.style.top="50%"
      this.setState(
        { isMagnifier: false, picSize: this.state.picSize * 1.5 },
        () => {
          this.clearLine()
        }
      )
    } else if (mark === 10) {
      // 缩小
      el = document.getElementById('targetImage')
      el.style.height = this.state.picSize / 1.5 + 'px'
      el.style.width = this.state.picSize / 1.5 + 'px'
      // el.style.transform="translate(-50%,-50%)"
      // el.style.left="50%"
      // el.style.top="50%"
      this.setState(
        { isMagnifier: false, picSize: this.state.picSize / 1.5 },
        () => {
          this.clearLine()
        }
      )
    } else if (mark === 11) {
      isRoam = !isRoam
      if (isRoam === true)
        document.getElementById('canvas2').style.zIndex = '-1'
      if (isRoam === true) el.style.cursor = 'move'
      else {
        el.style.cursor = 'default'
      }
      this.setState({ isRoam, isMagnifier: false, isCounting: false }, () => {
        this.clearLine()
      })
    }
    if (brightness > 300 || brightness < 50) {
      return
    }

    this.setState({ brightness, contrast })
  }
  onMouseOut = (e) => {
    // document.getElementById('canvas').setAttribute('style', 'display:none')
  }
  clearLine = () => {
    let canvas2D = this.canvasRef2.current
    let ctx = canvas2D.getContext('2d')
    this.arr = []
    this.setState({ line1Length: null, line2Length: null })
    ctx.clearRect(0, 0, 800, 800)
  }
  onMouseEvent = (e) => {
    var { isCounting, isMagnifier } = this.state
    let offsetX = e.nativeEvent.offsetX
    let offsetY = e.nativeEvent.offsetY
    let startX = offsetX
    let startY = offsetY
    // console.log(startX, '33333')
    // console.log(startY, '44444')
    if (e.type === 'mousedown') {
      let canvas2D = this.canvasRef2.current
      let ctx = canvas2D.getContext('2d')
      if (this.arr.length === 4) {
        // this.clearLine();
        message.error('最多绘制两条线')
        return
      }
      if (!isCounting) return
      // return
      //点
      ctx.strokeStyle = 'red'
      ctx.fillStyle = 'red'
      ctx.lineJoin = 'round'
      ctx.lineWidth = 1
      ctx.beginPath()
      ctx.arc(offsetX, offsetY, 2, 0, 2 * Math.PI)
      ctx.stroke()
      ctx.fill()
      ctx.closePath()
      this.arr.push({ x: offsetX, y: offsetY })
      //线
      if (this.arr.length % 2 == 0) {
        ctx.strokeStyle = 'red'
        ctx.lineJoin = 'round'
        ctx.lineWidth = 5
        ctx.beginPath()
        ctx.moveTo(
          this.arr[this.arr.length - 2].x,
          this.arr[this.arr.length - 2].y
        )
        ctx.lineTo(
          this.arr[this.arr.length - 1].x,
          this.arr[this.arr.length - 1].y
        )
        ctx.stroke()
        ctx.closePath()

        var x, y
        if (this.arr.length == 2) {
          x = this.arr[0].x - this.arr[1].x
          y = this.arr[0].y - this.arr[1].y
          this.setState({ line1Length: Math.sqrt(x * x + y * y) })
        }
        if (this.arr.length == 4) {
          x = this.arr[2].x - this.arr[3].x
          y = this.arr[2].y - this.arr[3].y
          this.setState({ line2Length: Math.sqrt(x * x + y * y) })
        }
        console.log(this.arr)
      }
    }
    if (!isMagnifier) {
      return
    }

    // console.log(e.type)
    // document.getElementById('canvas').style.display = 'block'
    // this.canvasContext.clearRect(0, 0, this.canvasWidth, this.canvasHeight)
    // // if(isMagnifier){
    // this.canvasContext.drawImage(
    //   this.targetImage,
    //   (startX * this.state.imgWidth) / 800 - 200,//图像的内容
    //   (startY * this.state.imgheight) / 800 - 200,//
    //   400,
    //   400,
    //   0,//中间指针的位置
    //   0,//中间指针的位置
    //   400,//宽
    //   400//高
    // )



    // var canvas2D2 = this.canvasRef.current
    // let ct2 = canvas2D2.getContext('2d')
    // ct2.strokeStyle = 'red'
    // ct2.lineJoin = 'round'
    // ct2.lineWidth = 1
    // ct2.beginPath()
    // // ct2.arc(200,200, 1, 0, 2 * Math.PI)(圆)
    // var x = 200,
    //   y = 200
    // ct2.moveTo(x, y)
    // ct2.lineTo(x, 180)
    // ct2.moveTo(x, y)
    // ct2.lineTo(x, 220)
    // ct2.moveTo(x, y)
    // ct2.lineTo(180, y)
    // ct2.moveTo(x, y)
    // ct2.lineTo(220, y)
    // ct2.closePath()
    // ct2.stroke()
    // ct2.arc(offsetX + 400, offsetY - 100, 5, 0, 2 * Math.PI)
    // document.getElementById('canvas').setAttribute('style', `left:${this.state.mouseX - 200}px;top:${this.state.mouseY - 400}px`)

    // }
  }
  bigerImage = (x, y) => {

    var that = this
    var imageX = x * 3,
      imageY = y * 3,
      sx = imageX - 150,
      sy = imageY - 150

    var dx = x - 150,
      dy = y - 150

    that.canvasContext.save()

    that.canvasContext.strokeStyle = '#9eddf1'
    that.canvasContext.lineWidth = 3

    that.canvasContext.beginPath()
    that.canvasContext.arc(x, y, 150, 0, Math.PI * 2)

    that.canvasContext.shadowColor = '#6ed25b'
    that.canvasContext.shadowBlur = 10

    that.canvasContext.stroke()
    that.canvasContext.clip()

    that.canvasContext.drawImage(
      that.canvas2,
      sx,
      sy,
      2 * 150,
      2 * 150,
      dx,
      dy,
      2 * 150,
      2 * 150
    )

    that.canvasContext.restore()
  }

  delete = () => {
    var { currentRecord, currentIndex, dcmList, page, isLast } = this.state

    var that = this
    confirm({
      content: '确认删除？',
      onOk() {
        that.props.dcmDelete({ ids: currentRecord.id }).then((res) => {
          if (res.data.code === 200) {
            if (currentIndex + 1 === dcmList.length) {
              if (isLast) {
                window.history.back()
              } else {
                that.setState(
                  {
                    page: page + 1,
                    currentIndex: 0,
                    sfzc: undefined,
                    zdjy: null,
                    showJCSJ: true,
                    jcsj: null,
                  },
                  () => {
                    that.getDcmInfolIST()
                  }
                )
              }
            } else {
              that.setState({
                currentIndex: currentIndex + 1,
                currentRecord: dcmList[currentIndex + 1],
                barcode: dcmList[currentIndex + 1].barcode,
                picUrl: dcmList[currentIndex + 1].filePath,
                sfzc:
                  dcmList[currentIndex + 1].diagnoseResult === null
                    ? undefined
                    : dcmList[currentIndex + 1].diagnoseResult ===
                      '胸部未见明显器质性病变。'
                      ? 1
                      : 2,
                zdjy: dcmList[currentIndex + 1].diagnoseResult,
                showJCSJ: true,
                jcsj: dcmList[currentIndex + 1].examinationInfo,
              })
            }
          }
        })
      },
    })
  }
  handleDrag = (e) => {
    if (!this.state.isRoam) return
    const o = document.getElementById('targetImage')
    const s = o.style
    const p = 'onmousemove'
    //在jsx中需要用e.persist()此方法会从池中移除合成事件，允许用户代码保留对事件的引用,否则clientX会是null
    let x = e.clientX - o.offsetLeft
    let y = e.clientY - o.offsetTop
    document[p] = function (e) {
      s.left = e.clientX - x + 'px'
      s.top = e.clientY - y + 'px'
    }
    this.setState({
      event: document[p],
    })
    document.onmouseup = function () {
      document[p] = null
    }
  }

  handleStopDrag = (e) => {
    e.preventDefault()
  }
  render() {
    const {
      currentRecord,
      brightness,
      contrast,
      line1Length,
      line2Length,
      isCounting,
      isMagnifier,
      isRoam,
    } = this.state
    return (
      <div className="pic_read_page">
        <div className="StatisticsPage-head pageHeader">
          {' '}
          <span
            onClick={this.goBack}
            style={{
              paddingRight: '20px',
              color: '#278B7B',
              cursor: 'pointer',
            }}
          >
            {'〈  返回列表'}
          </span>
        </div>
        <div className="StatisticsPage-box_read">
          <div className="StatisticsPage-btn picBtn">
            <Button size="small" onClick={() => this.lightOnClick(1)}>
              重置
            </Button>
            <Button size="small" onClick={() => this.lightOnClick(11)}>
              {isRoam ? '关闭漫游' : '开启漫游'}
            </Button>
            <Button size="small" onClick={() => this.lightOnClick(9)}>
              放大
            </Button>
            <Button size="small" onClick={() => this.lightOnClick(10)}>
              缩小
            </Button>
            <Button size="small" onClick={() => this.lightOnClick(2)}>
              增亮
            </Button>
            <Button size="small" onClick={() => this.lightOnClick(3)}>
              减亮
            </Button>
            <Button size="small" onClick={() => this.lightOnClick(5)}>
              对比度+1
            </Button>
            <Button size="small" onClick={() => this.lightOnClick(6)}>
              对比度-1{' '}
            </Button>
            <Button size="small" onClick={() => this.lightOnClick(7)}>
              {isMagnifier ? '关闭放大镜' : '开启放大镜'}{' '}
            </Button>
            <Button size="small" onClick={() => this.lightOnClick(8)}>
              {isCounting ? '关闭心胸比' : '开启心胸比'}{' '}
            </Button>

            <span style={{ color: 'red' }}>
              心胸比：
              {line1Length && line2Length
                ? (line1Length / line2Length).toFixed(2)
                : 0}
            </span>
            <Button valsizeue="small" onClick={() => this.clearLine()}>
              重新绘线
            </Button>
            <div style={{ float: 'right' }}>
              {currentRecord &&
                currentRecord.isRepetition &&
                currentRecord.isRepetition === 1 ? (
                <span style={{ color: 'red' }}>当前条码存在多条数据</span>
              ) : (
                <span></span>
              )}
              <Button size="small" danger onClick={() => this.delete()}>
                删除
              </Button>
            </div>
          </div>
          <div className="StatisticsPage-content-read">
            <div className="pic_box">
              <img
                mode="aspectFill"
                onDragStart={this.handleStopDrag}
                onMouseDown={(e) => {
                  e.persist()
                  this.handleDrag(e)
                }}
                id="targetImage"
                style={{
                  filter:
                    ' brightness(' +
                    brightness +
                    '%) contrast(' +
                    contrast +
                    ')',
                }}
                src={this.state.picUrl}
              />
              <canvas
                ref={this.canvasRef2}
                id="canvas2"
                className="canvas2"
                width={800}
                height={800}
                onMouseDown={this.onMouseEvent}
                onMouseMove={this.onMouseEvent}
                onMouseUp={this.onMouseEvent}
                onMouseOut={this.onMouseOut}
              ></canvas>
            </div>
            {/* 线段canvas */}
            <canvas
              ref={this.canvasRef}
              id="canvas"
              className="canvas"
              width={400}
              height={400}
            ></canvas>
            <div className="right_box">
              {/* <button className="margin5" onClick={this.resetImg}>初始位置大小</button> */}
              {/* <button className="margin5" onClick={this.cutImg}>{this.state.cutState ? '取消裁剪' : '裁剪图片'}</button> */}
              {/* {this.state.cutState && <div className="uploadCrop margin5"></div>} */}
              <div>
                <span style={{ padding: '0 5px' }}>
                  条码号：{this.state.barcode}
                </span>
                <span style={{ padding: '0 5px' }}>
                  姓名：{currentRecord && currentRecord.username}
                </span>
                <span style={{ padding: '0 5px' }}>
                  {currentRecord &&
                    currentRecord.sex &&
                    currentRecord.sex === 1 &&
                    '（男）'}
                </span>
                <span style={{ padding: '0 5px' }}>
                  {currentRecord &&
                    currentRecord.sex &&
                    currentRecord.sex === 2 &&
                    '（女）'}
                </span>
                <span style={{ padding: '0 5px' }}>
                  {currentRecord && currentRecord.age}
                </span>
                <div className="form-group">
                  <label className="form-label">胸片是否正常：</label>
                  <div className="form-ipt">
                    <Radio.Group
                      onChange={this.onStateChange}
                      value={this.state.sfzc}
                    >
                      <Radio value={1}>正常</Radio>
                      <Radio value={2}>异常</Radio>
                    </Radio.Group>
                  </div>
                </div>
                {/* {this.state.sfzc !== 1 && */}
                <div>
                  <div className="form-group">
                    <label className="form-label">诊断结论：</label>
                    <div className="form-ipt">
                      <Select disabled={this.state.sfzc===1?true:false}
                        showSearch
                        style={{ width: '100%' }}
                        placeholder="选择建议"
                        value={this.state.zdjy}
                        optionFilterProp="label"
                        onChange={this.suggestChange}
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {this.state.suggestList.map((item, index) => (
                          <Option
                            value={item.title}
                            key={index}
                            data_desc={item.subTitle}
                          >
                            {item.title}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="form-label">结论内容：</label>
                    <div className="form-ipt">
                      <TextArea
                        rows={6}
                        placeholder="请输入诊断结论"
                        value={this.state.zdjyContent}
                        allowClear={true}
                        onChange={this.zdjyContentChange}
                      />
                    </div>
                  </div>

                  <div className="form-group">
                    <label className="form-label">检查所见：</label>
                    <div className="form-ipt">
                      <Switch
                        checkedChildren="开启"
                        className="show_jcsj"
                        unCheckedChildren="关闭"
                        checked={this.state.showJCSJ}
                        onChange={this.showJCSJonChange}
                      />
                      {this.state.showJCSJ && (
                        <TextArea
                          rows={6}
                          placeholder="请输入检查所见"
                          value={this.state.jcsj}
                          allowClear={true}
                          onChange={this.jcsjChange}
                        />
                      )}
                    </div>
                  </div>
                  {/* ========== */}
                  <div className="form-group">
                    <label className="form-label">诊断建议：</label>
                    <div className="form-ipt">
                      <Select
                        showSearch
                        style={{ width: '100%' }}
                        placeholder="选择建议"
                        value={this.state.suggestSel}
                        optionFilterProp="label"
                        onChange={this.jyselChange}
                      >
                        {this.state.jianyiList.map((item, index) => (
                          <Option value={item.title} key={index}>
                            {item.title}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="form-label">建议内容：</label>
                    <div className="form-ipt">
                      <TextArea
                        rows={6}
                        placeholder="请输入建议内容"
                        value={this.state.suggest}
                        allowClear={true}
                        onChange={this.jyContentChange}
                      />
                    </div>
                  </div>
                  {/* ============ */}
                </div>
                {/* } */}
                <div className="form-group">
                  <label className="form-label"></label>
                  <div className="form-ipt">
                    <Button
                      className="option_btn"
                      type="default"
                      onClick={this.upImage}
                    >
                      上一张
                    </Button>
                    <Button
                      className="option_btn"
                      type="default"
                      onClick={this.downImage}
                    >
                      下一张
                    </Button>
                    <Button
                      className="option_btn"
                      type="primary"
                      onClick={this.save}
                    >
                      提交
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default connect(null, {
  getDcmInfolIST,
  updateDCM,
  dcmSuggestList,
  dcmDelete,
})(pic_Read)
