import React, { Component } from 'react'
import { Modal, Button, Table, Upload, message, Input, Divider } from 'antd'
import './DCM.css'

import {
  dcmDoctorSave,
  dcmDoctorList,
  dcmDoctorUpdate,
  dcmDoctorDelete,
  dcmUploadFile,
} from '../../../actions/account'

import { connect } from 'react-redux'
// import _ from 'lodash';

const { Column } = Table

const { confirm } = Modal

class DCMSuggest extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dataList: [],
      visible: false,
      loading: false,
      imageUrlAll: undefined,
      imageUrl: undefined,
      doctorname: '',
      currentId: undefined,
    }
  }

  componentDidMount() {
    this.loadDatas()
  }
  loadDatas = () => {
    var that = this
    var obj = { page: 0, size: 1000 }
    that.props.dcmDoctorList(obj).then((res) => {
      if (res.data.code === 200) {
        that.setState({
          dataList: res.data.data.rows,
        })
      }
    })
  }
  customRequest = (option) => {
    const formData = new FormData()
    const that = this
    formData.append('file', option.file)

    that.props.dcmUploadFile(formData).then(function (res) {
      if (res.data.code === 200) {
        that.setState({
          imageUrlAll: res.data.fullPath,
          imageUrl: res.data.message,
        })
      } else {
        message.error(res.data.message)
      }
      that.setState({
        loading: false,
      })
    })
  }

  showModal = () => {
    this.setState({ visible: true })
  }
  nameOnChnage = (e) => {
    this.setState({ doctorname: e.target.value })
  }

  handleChange = (info) => {
    if (info.file.status === 'uploading') {
      this.setState({ loading: true })
    }
  }
  handleBindOk = () => {
    var { imageUrl, doctorname, currentId } = this.state
    var that = this
    var obj = { doctorName: doctorname }
    if (imageUrl !== undefined) obj.sign = imageUrl
    if (currentId === undefined) {
      this.props.dcmDoctorSave(obj).then((res) => {
        if (res.data.code === 200) {
          message.success('新增成功！')
          that.setState({ visible: false, imageUrl: undefined, doctorname: '' })
          that.loadDatas()
        }
      })
    } else {
      obj.id = currentId
      this.props.dcmDoctorUpdate(obj).then((res) => {
        if (res.data.code === 200) {
          message.success('新增成功！')
          that.setState({
            visible: false,
            imageUrl: undefined,
            doctorname: '',
            currentId: undefined,
          })
          that.loadDatas()
        }
      })
    }
  }
  handleBindCancel = () => {
    this.setState({
      visible: false,
      imageUrl: undefined,
      doctorname: '',
      currentId: undefined,
    })
  }
  editItem = (record) => {
    this.setState({
      visible: true,
      doctorname: record.doctorName,
      imageUrlAll: record.sign !== null ? record.sign : undefined,
      currentId: record.id,
    })
  }

  onRelieve = (id) => {
    var that = this
    confirm({
      content: '确认删除？',
      onOk() {
        that.props.dcmDoctorDelete({ id: id }).then((res) => {
          if (res.data.code === 200) {
            message.success('删除成功！')
            that.loadDatas()
          }
        })
      },
    })
  }
  render() {
    const { dataList, visible, doctorname, imageUrlAll } = this.state

    return (
      <div>
        <div className="StatisticsPage-head pageHeader">
          <h3>医师管理</h3>
        </div>
        <div className="StatisticsPage-box">
          <div className="StatisticsPage-search">
            <div className="StatisticsPage-btn">
              <Button className="btn" type="primary" onClick={this.showModal}>
                添加医师
              </Button>

              <Modal
                width="500px"
                title="医师信息"
                visible={visible}
                onOk={this.handleBindOk}
                onCancel={this.handleBindCancel}
                okText="保存"
                cancelText="取消"
              >
                <div className="form-group">
                  <label className="form-label">医师姓名：</label>
                  <div className="form-ipt" style={{ marginTop: '5px' }}>
                    <Input
                      value={doctorname}
                      onChange={this.nameOnChnage}
                    ></Input>
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-label">电子签名：</label>
                  <div className="form-ipt" style={{ marginTop: '5px' }}>
                    {imageUrlAll && (
                      <img
                        style={{ width: '120px', height: '150px' }}
                        alt=""
                        src={imageUrlAll}
                      />
                    )}
                    <Upload
                      accept="image/png, image/jpeg"
                      showUploadList={false}
                      customRequest={this.customRequest}
                      onChange={this.handleChange}
                    >
                      <Button type="primary" loading={this.state.loading}>
                        上传照片
                      </Button>
                    </Upload>
                  </div>
                </div>
              </Modal>
            </div>
          </div>
          <div>
            <Table
              size="middle"
              dataSource={dataList}
              bordered
              rowKey="id"
              pagination={false}
            >
              {/* <Column title="编号ID" dataIndex="id" key="id" align="center" /> */}
              <Column
                title="姓名"
                dataIndex="doctorName"
                key="doctorName"
                align="center"
              />
              <Column
                width={150}
                title="电子签名"
                key="sign"
                align="center"
                render={(record) => {
                  return (
                    <div
                      style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}
                    >
                      <img
                        alt=""
                        style={{ width: 100, height: 100 }}
                        src={record.sign}
                        key={record.sign}
                      />
                    </div>
                  )
                }}
              />
              <Column
                title="操作"
                align="center"
                key="action"
                render={(text, record) => (
                  <span>
                    <span
                      style={{ cursor: 'pointer' }}
                      onClick={() => this.editItem(record)}
                    >
                      修改
                    </span>
                    <Divider type="vertical" />
                    <span
                      style={{ cursor: 'pointer' }}
                      onClick={() => this.onRelieve(record.id)}
                    >
                      删除
                    </span>
                  </span>
                )}
              />
            </Table>
          </div>
        </div>
      </div>
    )
  }
}
export default connect(null, {
  dcmDoctorSave,
  dcmDoctorList,
  dcmDoctorUpdate,
  dcmDoctorDelete,
  dcmUploadFile,
})(DCMSuggest)
