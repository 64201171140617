import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  Modal,
  Button,
  Table,
  Input,
  message,
  Divider,
  Tabs,
  Select,
  DatePicker,
} from 'antd'
import './DCM.css'
import moment from 'moment'
import { bmodelInfo, exportBmodel, deleteBchao } from '../../../actions/account'
const { confirm } = Modal
const dateFormat = 'YYYYMMDD'
const { TabPane } = Tabs
const { Column } = Table
const { TextArea } = Input
const { RangePicker } = DatePicker
const { Option } = Select
class BmodelPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      barcode: '',

      size: 10,
      dataList: [],
      page: 1,
      total: 0,
      title: '',
      subTitle: '',
      currId: undefined,
      type: '',
      startTime: undefined,
      endTime: undefined,
      choosedDates: [],
      selectedRowKeys: [],
    }
  }

  componentDidMount() {
    this.loadDatas()
  }

  loadDatas = () => {
    var { page, size, barcode, startTime, endTime } = this.state

    var that = this
    var obj = {
      page: page - 1,
      size: size,
    }
    if (barcode !== '') {
      obj.barcode = barcode
    }
    if (startTime !== undefined) {
      obj.startTime = moment(startTime, 'YYYY-MM-DD HH:mm:ss').format(
        'YYYYMMDD'
      )
    }
    if (endTime !== undefined) {
      obj.endTime = moment(endTime, 'YYYY-MM-DD HH:mm:ss').format('YYYYMMDD')
    }
    this.props.bmodelInfo(obj).then((res) => {
      if (res.data.code === 200) {
        that.setState({
          dataList: res.data.data.rows,
          total: res.data.data.total,
        })
      }
    })
  }

  keyWordOnChange = (e) => {
    this.setState({ barcode: e.target.value })
  }
  typeChange = (e) => {
    console.log(e)
    this.setState({ type: e })
  }
  exportBmodel = () => {
    var { startTime, endTime } = this.state

    if (!!!startTime || !!!endTime) {
      message.error('请先选择时间')
      return
    }
    var obj = {}
    console.log(startTime)
    if (startTime !== undefined) {
      obj.startTime = moment(startTime, 'YYYY-MM-DD HH:mm:ss').format(
        'YYYY-MM-DD'
      )
    }
    if (endTime !== undefined) {
      obj.endTime = moment(endTime, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD')
    }
    var that = this
    that.props.exportBmodel(obj).then((res) => {
      const fileName = 'export' + moment().format('YYYYMMDDHHmmss')
      if ('download' in document.createElement('a')) {
        // 非IE下载
        const url = window.URL.createObjectURL(
          new Blob(
            [res.data],
            // 设置该文件的mime类型，这里对应的mime类型对应为.xlsx格式
            { type: 'application/vnd.ms-excel' }
          )
        )
        const link = document.createElement('a')
        link.href = url
        // 从header中获取服务端命名的文件名
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
      } else {
        // IE10+下载
        navigator.msSaveBlob(res.data, fileName)
      }
    })
  }
  changePage = (page) => {
    var that = this
    this.setState({ page }, () => {
      that.loadDatas()
    })
  }
  pageSizeOnChange = (current, size) => {
    var that = this
    this.setState({ size: size })
  }
  datetimeChange = (date, dateString) => {
    this.setState({
      startTime: dateString[0] === '' ? undefined : dateString[0] + ' 00:00:00',
      endTime: dateString[1] === '' ? undefined : dateString[1] + ' 23:59:59',
      choosedDates: date,
    })
  }
  onSelectChange = (selectedRowKeys) => {
    // var { dataList } = this.state;
    this.setState({ selectedRowKeys })
  }
  delItem = (id) => {
    var { dataList } = this.state
    var that = this
    // if (selectedRowInfoKeys.length === 0) {
    //     message.error("请选择删除的记录")
    //     return;
    // }
    // var that = this;
    // var barcode = ( _.find(dataList, ['id', id])).barcode;
    // console.log(barcode)
    confirm({
      content: '确认删除？',
      onOk() {
        that.props.deleteBchao({ ids: id }).then((msg) => {
          if (msg.data.code === 200) {
            message.success('删除成功！')
            that.loadDatas()
          }
        })
      },
    })
  }
  serchBtn = () => {
    this.setState({ page: 1 }, () => {
      this.loadDatas()
    })
  }
  render() {
    const { dataList, barcode, choosedDates, selectedRowKeys } = this.state
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    }
    return (
      <div>
        <div className="StatisticsPage-head pageHeader">
          <h3>B超列表</h3>
        </div>
        <div className="StatisticsPage-box">
          <div className="StatisticsPage-search">
            <div className="StatisticsPage-btn">
              <RangePicker
                dateFormat={dateFormat}
                placeholder={['开始时间', '结束时间']}
                className="date"
                onChange={this.datetimeChange}
                value={choosedDates}
              />
              <Input
                className="ipt"
                placeholder="请输入条码号"
                value={barcode}
                style={{ width: 100 }}
                onChange={this.keyWordOnChange}
              />

              <Button className="btn" type="primary" onClick={this.serchBtn}>
                搜索
              </Button>
              <Button
                className="btn"
                type="primary"
                onClick={this.exportBmodel}
              >
                导出
              </Button>
            </div>
          </div>
          <div>
            <Table
              size="middle"
              dataSource={dataList}
              bordered
              rowKey="id"
              pagination={{
                pageSize: this.state.size,
                showTotal: (total) => {
                  return `共 ${total} 条`
                },
                onChange: this.changePage,
                total: this.state.total,
                showSizeChanger: true,
                onShowSizeChange: this.pageSizeOnChange,
              }}
              rowSelection={rowSelection}
            >
              <Column
                title="条码号"
                dataIndex="barcode"
                key="barcode"
                align="center"
              />
              <Column
                title="检查部位"
                dataIndex="checkPoint"
                key="checkPoint"
                align="center"
              />
              <Column
                title="检查所见"
                dataIndex="examinationFinding"
                key="examinationFinding"
                align="center"
              />
              <Column
                title="检查结论"
                dataIndex="examinationConclusion"
                key="examinationConclusion"
                align="center"
              />
              <Column
                title="临床建议"
                dataIndex="clinicalRecommendations"
                key="clinicalRecommendations"
                align="center"
              />
              <Column
                title="检查日期"
                dataIndex="examinationDate"
                key="examinationDate"
                align="center"
              />
              <Column
                title="操作"
                align="center"
                key="action"
                render={(text, record) => (
                  <span>
                    <span
                      style={{ cursor: 'pointer' }}
                      onClick={() => this.delItem(record.id)}
                    >
                      删除
                    </span>
                  </span>
                )}
              />
              {/* <Column
                title="报告路径"
                dataIndex="filePath"
                key="filePath"
                align="center"
                render={(record) => {
                  return (
                    <div
                      style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}
                    >
                      <img
                        alt=""
                        style={{ width: 50, height: 50 }}
                        src={record.pic}
                        key={record.pic}
                      />
                    </div>
                  )
                }}
              /> */}
            </Table>
          </div>
        </div>
      </div>
    )
  }
}
export default connect(null, {
  bmodelInfo,
  exportBmodel,
  deleteBchao,
})(BmodelPage)
