import React, { Component } from 'react'
import {
  Modal,
  Button,
  Input,
  Select,
  message,
  Radio,
  DatePicker,
  Checkbox,
  InputNumber,
  Alert,
} from 'antd'
import { connect } from 'react-redux'
import {
  appointmentPageDetail2,
  appointmentSave,
  getTwoCancerToken,
  getTwoCancerIsHave,
  getAppointmentInfoList,
} from '../../actions/account'
import './yuyue.css'

import Community from './Community'

import _ from 'lodash'
import moment from 'moment'
// import { red } from '@material-ui/core/colors';
const { Option } = Select
const { TextArea } = Input
class main extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hasTwoCancerRecord: false,
      // twoCancerToken: "A25ED7EC1C574D23BF9E531C5376F5A4",
      idCardError: '请输入身份证号',
      orgName: '',
      twoToken: '',
      isSaving: false,
      org: null,
      phy: null,
      selectedDate: moment().format('YYYY-MM-DD'),
      sex: '',
      weekName: '',
      weeks: [[], [], [], [], []],
      btnState: false,
      time: '',
      Datas: [],
      syme: null,
      username: '',
      idCard: '',
      workUnit: '无',
      phone: '',
      town: undefined,
      county: undefined,
      height: '',
      weight: '',
      occupation: '',
      education: '',
      pageTitle: '',
      communityList: [],

      dateList: [],
      timeList: [],
      date: '',
      detailId: '',
      erweimaOrg: undefined,
      erweimaPhy: undefined,

      lowinsuranceNo: '',
      poor: '',
      freeCheck: '',
      cardType: '01',
      domicileMode: '1',
      residentialAddress: '',
      domicileType: '1',
      domicileAddr: '',
      prov: undefined,
      city: undefined,
      postCode: '',
      workTel: '',
      tctNo: '',
      hpvNo: '',
      bmi: '',
      lmpStr: '',
      menophaniaAge: null,
      menstrual: '',
      cyc: null,
      isMenopause: '1',
      menopauseAge: null,
      primiparityAge: null,
      isProduction: '0',
      isLactation: '0',
      lactationTime: null,
      larcControl: '1',
      larcCondom: '',
      larcAcyeterion: '',
      larcAcyeYear: null,
      larcOther: '',
      larcOtherCont: '',
      larciud: '',
      larciudYear: null,
      gravidityTimes: null,
      deliverTimes: null,
      optName: '',
      hospitalName: '',
      sampleDate: moment().format('YYYY-MM-DD'),
    }
  }
  componentDidMount() {
    document.title = '预约'
    var temp = [],
      that = this
    console.log(this.props.location.search.substring(1))
    var params = this.props.location.search.substring(1).split('&')
    var val = []
    var query = {}
    console.log(params)
    for (var i = 0; i < params.length; i++) {
      val = params[i].split('=')
      if (val[0] === 'erweimaName' || val[0] === 'erweimaOrgName') {
        query[val[0]] = decodeURI(val[1])
      } else query[val[0]] = val[1]
    }
    console.log(query)
    this.setState(
      {
        org: query.erweimaOrg,
        phy: query.erweimaPhy,
        orgName: query.erweimaOrgName.replace('永林', '永宁'),
      },
      () => {
        this.loadDatas(query.erweimaOrg, query.erweimaPhy, 1)
      }
    )

    var locationData = query
    this.setState({
      pageTitle: locationData.erweimaName.replace('永林', '永宁'),
      occupationList1: temp,
      erweimaOrg: query.erweimaOrg.replace('永林', '永宁'),
      erweimaPhy: query.erweimaPhy.replace('永林', '永宁'),
    })

    //查找对应社区列表
    var commobj = _.find(Community, ['code', query.erweimaOrgName])
    if (commobj !== undefined) {
      this.setState({ communityList: commobj.list })
    }
    // this.props.getRootAreas().then(res => {
    //     if (res.data.code === 200) {
    //         that.setState({ provinces: res.data.data })
    //     }
    // })
  }

  onyyDateChange(date, dateString) {
    console.log(date, dateString)
  }
  loadDatas = (org, phy, mark) => {
    var that = this
    var obj = {
      organizationId: org,
      typeId: Number(phy),
      currentDate: moment('2021-08-28 17:04:41').format('YYYY-MM-DD HH:mm:ss'),
      selectedDate: this.state.selectedDate,
    }
    // var obj = { organizationId: org, physicalTypeId: Number(phy), currentDate: moment('2021-08-28 17:04:41').format("YYYY-MM-DD HH:mm:ss") };
    // message.loading("加载数据中....", 0)
    this.setState(
      { weeks: [[], [], [], [], [], [], []], dateList: [], timeList: [] },
      () => {
        var { dateList, weeks } = that.state
        message.destroy()
        console.log(obj)
        that.props.appointmentPageDetail2(obj).then((msg) => {
          if (msg.data.code === 200) {
            if (!msg.data.data || msg.data.data.length === 0) {
              message.warn('最近没有预约事务！')
              that.setState({ btnState: false })
              return
            }

            var festival = ['01-01', '05-01', '10-01'] //法定节假日
            var objs = msg.data.data
            var countDay = 0
            objs.forEach((obj) => {
              obj.appointmentDateDetailDTOS.forEach((item) => {
                weeks[item.week - 1].push(item)
              })
              obj.startTime =
                moment(obj.startTime).format('YYYY-MM-DD') + ' 00:00:00'
              obj.endTime =
                moment(obj.endTime).format('YYYY-MM-DD') + ' 23:59:59'
              var startDate = moment(obj.startTime)
              var endDate = moment(obj.endTime)

              var d = moment()
              if (d.unix() > startDate.unix()) {
                d = moment()
              } else {
                d = startDate
              }
              // alert(startDate.getTime()+","+d.getTime()+","+endDate.getTime())
              for (
                d;
                d.unix() <= endDate.unix() && countDay < 8;
                d = d.add(1, 'days')
              ) {
                console.log(d.format('YYYY-MM-DD') + '-->' + countDay)
                // console.log(moment(d).format("YYYY-MM-DD"))
                if (festival.indexOf(d.format('MM-DD')) === -1) {
                  //从今天开始往后的   日期范围内的
                  var temp = d.format('YYYY-MM-DD')
                  var now = moment().format('YYYY-MM-DD')
                  var day = parseInt(d.day())
                  //  ;
                  // console.log(moment(now).isBefore(temp)+'-->'+now+'  '+temp)

                  if (moment(now).isBefore(temp) || temp === now) {
                    if (day !== 0)
                      dateList.push({
                        date: d.format('YYYY-MM-DD'),
                        week: day,
                        address: obj.physicalSite,
                      })
                    else
                      dateList.push({
                        date: d.format('YYYY-MM-DD'),
                        week: 7,
                        address: obj.physicalSite,
                      })
                  }
                  countDay++
                }
              }
            })

            // alert(dateList.length)
            that.setState({ dateList, weeks, btnState: false }, () => {
              if (mark === 2) {
                var temp = '',
                  weekName = ''
                console.log(that.state.dateList, that.state.weeks)
                that.state.dateList.forEach((item) => {
                  if (item.date === that.state.selectedDate) {
                    temp = item.week
                    if (item.week === 1) weekName = '周一'
                    if (item.week === 2) weekName = '周二'
                    if (item.week === 3) weekName = '周三'
                    if (item.week === 4) weekName = '周四'
                    if (item.week === 5) weekName = '周五'
                    if (item.week === 6) weekName = '周六'
                    if (item.week === 7) weekName = '周日'
                    console.log('是', weekName)
                  }
                })
                that.setState({
                  timeList: that.state.weeks[temp - 1],
                  weekName,
                })
              }
            })
          }
        })
      }
    )
  }

  onDateChange = (value) => {
    console.log(value)
    this.setState(
      {
        date: value,
        timeList: [],
        detailId: '',
        time: '',
        syme: null,
        selectedDate: value,
      },
      () => {
        this.loadDatas(this.state.org, this.state.phy, 2)
      }
    )
  }
  ondetailIdChange = (value) => {
    var { timeList } = this.state
    this.setState({ detailId: value })
    timeList.forEach((item) => {
      if (item.id === Number(value)) {
        this.setState({
          syme: Number(item.residueNumber),
          time: `${item.startTime.substring(11, 19)}-${item.endTime.substring(
            11,
            19
          )}`,
        })
        return
      }
    })
  }

  GetAge = (strBirthday, date) => {
    var returnAge,
      strBirthdayArr = strBirthday.split('-'),
      birthYear = strBirthdayArr[0],
      birthMonth = strBirthdayArr[1],
      birthDay = strBirthdayArr[2],
      d = moment(date, 'YYYY-MM-DD'),
      nowYear = d.format('YYYY'),
      nowMonth = d.format('MM'),
      nowDay = d.format('DD')
    if (nowYear === birthYear) {
      returnAge = 0 //同年 则为0周岁
    } else {
      var ageDiff = nowYear - birthYear //年之差
      if (ageDiff > 0) {
        if (nowMonth === birthMonth) {
          /**      var dayDiff = nowDay - birthDay;//日之差
                    if (dayDiff < 0) {
                        returnAge = ageDiff - 1;
                    } else {
                        returnAge = ageDiff;
                    }*/
        } else {
          var monthDiff = nowMonth - birthMonth //月之差
          if (returnAge === 65) {
            monthDiff = monthDiff - 1
          }
          if (monthDiff < 0) {
            returnAge = ageDiff - 1
          } else {
            returnAge = ageDiff
          }
        }
      } else {
        returnAge = -1 //返回-1 表示出生日期输入错误 晚于今天
      }
    }
    return returnAge //返回周岁年龄
  }
  submit = () => {
    var that = this
    var {
      date,
      time,
      detailId,
      username,
      idCard,
      phone,
      workUnit,
      isSaving,
      city,
      county,
      town,
      syme,
      height,
      weight,
      occupation,
      education,
    } = this.state
    // console.log(date, time)
    if (phone.length !== 11) {
      message.error('请正确填写手机号！')
      return
    }
    if (isSaving) return
    this.setState({ isSaving: true }, () => {
      if (syme <= 0) {
        message.warn('无剩余名额')
        that.setState({ isSaving: false })
        return
      }

      if (detailId.length === 0 || date.length === 0 || time.length === 0) {
        message.warn('请选择预约时间及地点！')
        that.setState({ isSaving: false })
        return
      }
      if (username.length === 0) {
        message.warn('姓名不得为空！')
        that.setState({ isSaving: false })
        return
      }
      if (idCard.length === 0) {
        message.warn('身份证号不得为空！')
        that.setState({ isSaving: false })
        return
      }
      if (phone.length === 0) {
        message.warn('联系电话不得为空！')
        that.setState({ isSaving: false })
        return
      }

      idCard = idCard.trim()

      var obj = {
        appointmentDetailId: Number(detailId),
        username,
        idCard: idCard.toUpperCase(),
        phone,
        appointmentDate: `${date} ${time}`,
      }
      if (workUnit.length > 0) obj.workUnit = workUnit

      // if (weight.length > 0) obj.weight = weight;
      // if (education.length > 0) obj.education = education;
      // if (occupation.length > 0) obj.occupation = occupation;
      var keys = [
        'lowinsuranceNo',
        'poor',
        'freeCheck',
        'cardType',
        'domicileMode',
        'residentialAddress',
        'domicileType',
        'domicileAddr',
        'prov',
        'city',
        'county',
        'town',
        'postCode',
        'workTel',
        'tctNo',
        'hpvNo',
        'bmi',
        'lmpStr',
        'menophaniaAge',
        'menstrual',
        'cyc',
        'isMenopause',
        'menopauseAge',
        'primiparityAge',
        'isProduction',
        'isLactation',
        'lactationTime',
        'larcControl',
        'larcCondom',
        'larcAcyeterion',
        'larcAcyeYear',
        'larcOther',
        'larcOtherCont',
        'larciud',
        'larciudYear',
        'gravidityTimes',
        'deliverTimes',
        'optName',
        'hospitalName',
        'sampleDate',
      ]
      for (var i in keys) {
        obj[keys[i]] = this.state[keys[i]]
      }
      console.log(obj)
      that.props.appointmentSave(obj).then((msg) => {
        if (msg.data.code === 200) {
          message.info('预约成功！')
          that.props.history.push('/appointQueryList?idCard=' + idCard)
        } else {
          message.warn(msg.data.message)
        }
        that.setState({ isSaving: false })
      })
    })
  }
  usernameChange = (e) => {
    this.setState({
      username: e.target.value,
    })
  }

  idCardChange = (e) => {
    var { date } = this.state
    if (date === undefined || date === '' || date === null) {
      message.warn('请先选择预约日期！')
      return
    }
    var reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/
    var idCardError = null
    var hasTwoCancerRecord = false
    if (e.target.value.length === 18) {
      if (reg.test(e.target.value) === false) {
        message.warn('身份证号格式错误')
        idCardError = '身份证号格式错误'
      }
    } else {
      idCardError = '身份证号格式错误'
    }

    this.setState({
      idCard: e.target.value,
      idCardError,
    })
  }
  workUnitChange = (e) => {
    this.setState({
      workUnit: e.target.value,
    })
  }
  phoneChange = (e) => {
    this.setState({
      phone: e.target.value,
    })
  }
  phoneBlur = () => {
    var { phone } = this.state
    let reg = /^1[3|4|5|7|8][0-9]\d{8}$/
    if (!reg.test(phone)) {
      message.error('联系电话格式有误！')
    }
  }

  teamChange = (e) => {
    this.setState({
      team: e.target.value,
    })
  }
  heightChange = (e) => {
    this.setState({ height: e.target.value }, () => {
      this.autoCompleteBMI()
    })
  }
  weightChange = (e) => {
    this.setState({ weight: e.target.value }, () => {
      this.autoCompleteBMI()
    })
  }
  autoCompleteBMI = () => {
    var { height, weight } = this.state
    if (height.length > 0 && weight.length > 0) {
      this.setState({
        bmi: (weight / ((height / 100) * (height / 100))).toFixed(2),
      })
    } else {
      this.setState({
        bmi: '',
      })
    }
  }
  isLastCheckChange = (value) => {
    this.setState({
      btnState: value,
    })
  }
  lowinsuranceNoChange = (e) => {
    this.setState({ lowinsuranceNo: e.target.value.trim() })
  }
  poorOnChange = (e) => {
    this.setState({ poor: e.target.value })
  }
  freeCheckOnChange = (value) => {
    this.setState({ freeCheck: value })
  }
  onCardTypeChange = (value) => {
    this.setState({ cardType: value })
  }

  idcardHosChange = (e) => {
    this.setState({ idcardHos: e.target.value.trim() })
  }
  domicileModeChange = (value) => {
    this.setState({ domicileMode: value })
  }
  residentialAddressChange = (e) => {
    this.setState({ residentialAddress: e.target.value.trim() })
  }
  domicileTypeChange = (value) => {
    this.setState({ domicileType: value })
  }
  domicileAddrChange = (e) => {
    this.setState({ domicileAddr: e.target.value.trim() })
  }

  postCodeChange = (e) => {
    this.setState({ postCode: e.target.value.trim() })
  }
  workTelChange = (e) => {
    this.setState({ workTel: e.target.value.trim() })
  }
  tctNoChange = (e) => {
    this.setState({ tctNo: e.target.value.trim() })
  }
  hpvNoChange = (e) => {
    this.setState({ hpvNo: e.target.value.trim() })
  }
  bmiChange = (e) => {
    this.setState({ bmi: e.target.value.trim() })
  }
  menophaniaAgeChange = (e) => {
    this.setState({ menophaniaAge: e })
  }
  isMenopauseChange = (value) => {
    this.setState({
      isMenopause: value,
      menopauseAge: '',
      lmpStr: '',
      menstrual: '',
      cyc: null,
    })
  }
  menopauseAgeChange = (e) => {
    this.setState({ menopauseAge: e })
  }
  lmpStrOnChange = (value, str) => {
    console.log(value, str)
    this.setState({ lmpStr: str })
  }
  menstrualChange = (value) => {
    this.setState({ menstrual: value })
  }
  cycChange = (e) => {
    this.setState({ cyc: e })
  }
  isProductionOnChange = (e) => {
    this.setState({ isProduction: e.target.value, primiparityAge: '' })
  }
  primiparityAgeChange = (e) => {
    this.setState({ primiparityAge: e })
  }
  isLactationOnChange = (e) => {
    this.setState({ isLactation: e.target.value, lactationTime: '' })
  }
  lactationTimeChange = (e) => {
    this.setState({ lactationTime: e })
  }
  larcControlChange = (value) => {
    this.setState({
      larcControl: value,
      larcCondom: '',
      larcAcyeterion: '',
      larcAcyeYear: '',
      larcOther: '',
      larcOtherCont: '',
      larciud: '',
      larciudYear: '',
    })
  }
  larcCondomChange = (e) => {
    this.setState({ larcCondom: e.target.checked ? 1 : 0 })
  }
  larcAcyeterionChange = (e) => {
    this.setState({
      larcAcyeterion: e.target.checked ? 1 : 0,
      larcAcyeYear: '',
    })
  }
  larcOtherChange = (e) => {
    this.setState({ larcOther: e.target.checked ? 1 : 0, larcOtherCont: '' })
  }
  larcAcyeYearChange = (e) => {
    this.setState({ larcAcyeYear: e })
  }
  larcOtherContChange = (e) => {
    this.setState({ larcOtherCont: e.target.value.trim() })
  }
  larciudChange = (e) => {
    this.setState({ larciud: e.target.checked ? 1 : 0, larciudYear: '' })
  }
  larciudYearChange = (e) => {
    this.setState({ larciudYear: e })
  }
  gravidityTimesChange = (e) => {
    this.setState({ gravidityTimes: e })
  }
  deliverTimesChange = (e) => {
    this.setState({ deliverTimes: e })
  }
  render() {
    const {
      weekName,
      syme,
      timeList,
      detailId,
      date,
      dateList,
      erweimaOrg,
      pageTitle,
      username,
      idCard,
      workUnit,
      phone,
      town,
      county,
      village,
      height,
      weight,
      btnState,
    } = this.state

    return (
      <div>
        <div className="StatisticsPage-head1 pageHeader1">
          <h3>{pageTitle}预约</h3>
        </div>
        <div className="StatisticsPage-box1 has-select-table">
          <div className="appoint_tips">
            <p>王欣：中医专长医师/金陵一针第四代传人，坐诊时间：周三下午</p>
            <p>
              张中元：执业中医师/针灸推拿学硕士研究生/南京中医药大学针灸推拿学硕士研究生，坐诊时间：周四下午
            </p>
            <p>
              常佑任：高年资主治中医师/台湾中医师/中医世家传人，坐诊时间：周五下午
            </p>

            <p>
              王昕：主治中医师/美容主诊医师/国家二级心理咨询师/执业中药师，坐诊时间：周日上午
            </p>

            {/* {erweimaOrg==="53d1f91340244c5f98e0d846b7288569"&& <p>
                        预约时间段为10:00-11:00的学生享受一次免费颊针体验服务，请凭预约记录至校医务室领取免费体验券一张，使用期限仅限当天预约时间段内。
                        </p>}
                        {erweimaOrg==="53d1f91340244c5f98e0d846b7288569"&&  <p>
                        <span>
                        注：一人有且仅有一次免费体验机会。</span>
                        </p>}  */}
          </div>
          <div className="StatisticsPage-search two_cancer_appoint">
            <div className="form-group">
              <label className="form-label1  require">预约日期：</label>
              <div className="form-ipt">
                <Select
                  className={
                    date && date.length > 0 ? 'ipt1' : ' ipt1 require_input'
                  }
                  allowClear={false}
                  value={date}
                  onChange={this.onDateChange}
                >
                  {dateList.map((item, index) => {
                    return <Option key={item.date}>{item.date}</Option>
                  })}
                </Select>
                {weekName.length > 0 && <span>({weekName})</span>}
              </div>
            </div>
            <div className="form-group">
              <label className="form-label1  require">时间点：</label>
              <div className="form-ipt">
                <Select
                  className={
                    detailId && detailId.length > 0
                      ? 'ipt1'
                      : ' ipt1 require_input'
                  }
                  allowClear={false}
                  value={detailId}
                  onChange={this.ondetailIdChange}
                >
                  {timeList.map((date, index) => {
                    return (
                      <Option key={date.id}>
                        {date.startTime.substring(11, 16)}~
                        {date.endTime.substring(11, 16)}
                      </Option>
                    )
                  })}
                </Select>
              </div>
            </div>{' '}
            <div className="form-group">
              <label className="form-label1  require">地址：</label>
              <div className="form-ipt">
                {date &&
                  _.find(dateList, ['date', date]) &&
                  _.find(dateList, ['date', date]).address}
              </div>
            </div>
            <div className="form-group">
              <label className="form-label1">剩余名额：</label>
              <div className="form-ipt">
                {syme == null || syme > 0 ? (
                  <span style={{ color: 'green' }}>{syme}</span>
                ) : (
                  <span style={{ color: 'red' }}>暂无名额</span>
                )}
              </div>
            </div>
            <div className="form-group">
              <label className="form-label1 require">姓名：</label>
              <div className="form-ipt">
                <Input
                  placeholder="姓名"
                  className={
                    username && username.length > 0 ? '' : 'require_input'
                  }
                  value={username}
                  onChange={(e) => this.usernameChange(e)}
                />
              </div>
            </div>
            <div className="form-group">
              <label className="form-label1">证件类型：</label>
              <div className="form-ipt">
                <Select
                  className="ipt1"
                  allowClear={false}
                  placeholder="请选择"
                  value={this.state.cardType}
                  onChange={this.onCardTypeChange}
                >
                  <Option key={'01'}>居民身份证</Option>
                  <Option key={'02'}>居民户口簿</Option>
                  <Option key={'03'}>护照</Option>
                  <Option key={'04'}>军官证</Option>
                  <Option key={'05'}>驾驶证</Option>
                  <Option key={'06'}>港澳居民来往内地通行证</Option>
                  <Option key={'07'}>台湾居民来往内地通行证</Option>
                  <Option key={'99'}>其他法定有效证件</Option>
                </Select>
              </div>
            </div>
            <div className="form-group">
              <label className="form-label1 require">身份证号：</label>
              <div className="form-ipt">
                <Input
                  placeholder="身份证号"
                  className={
                    idCard && idCard.length > 0
                      ? 'query_seconds_Input'
                      : 'require_input query_seconds_Input'
                  }
                  value={idCard}
                  onChange={(e) => this.idCardChange(e)}
                />
              </div>
            </div>
            {/* <div className="form-group">
                            <label className="form-label1  require">上一年度是否参加两癌体检：</label>
                            <div className="form-ipt">
                                <Select className='ipt1' value={btnState} onChange={this.isLastCheckChange} disabled>
                                    <Option value={false}>否</Option>
                                    <Option value={true}>是</Option>
                                </Select>
                            </div>
                        </div> */}
            {this.state.sex && this.state.sex.length !== 0 && (
              <div className="form-group">
                <label className="form-label1">性别：</label>
                <div className="form-ipt">
                  <Input
                    placeholder="身份证号自动识别"
                    value={this.state.sex}
                    disabled
                  />
                </div>
              </div>
            )}
            <div className="form-group">
              <label className="form-label1 require">联系电话：</label>
              <div className="form-ipt">
                <Input
                  placeholder="联系电话"
                  className={phone && phone.length > 0 ? '' : 'require_input'}
                  value={phone}
                  onChange={(e) => this.phoneChange(e)}
                  onBlur={this.phoneBlur}
                />
              </div>
            </div>
            {erweimaOrg === 'b6c16ab4d6504982a9efc5cc09ccd58e' && (
              <div className="form-group">
                <label className="form-label1  require">所在院系：</label>
                <div className="form-ipt">
                  <Input
                    className={workUnit.length > 0 ? '' : 'require_input'}
                    placeholder="所在院系"
                    value={workUnit}
                    onChange={(e) => this.workUnitChange(e)}
                  />
                </div>
              </div>
            )}
            <div></div>
            <Button
              type="primary"
              style={{ width: '100%' }}
              disabled={
                this.state.idCardError ||
                syme <= 0 ||
                this.state.hasTwoCancerRecord
              }
              onClick={this.submit}
            >
              确定提交
            </Button>
            {this.state.idCardError && (
              <Alert message={this.state.idCardError} type="error" />
            )}
          </div>
        </div>
      </div>
    )
  }
}
export default connect(null, {
  appointmentPageDetail2,
  appointmentSave,
  getTwoCancerToken,
  getTwoCancerIsHave,
  getAppointmentInfoList,
})(main)
