import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  Modal,
  Button,
  Table,
  Input,
  message,
  Divider,
  Tabs,
  Select,
  Spin,
  Upload,
  Tooltip,
  Icon, Radio
} from 'antd'
import './DCM.css'

import {
  dcmSuggestSave,
  dcmSuggestUpdate,
  dcmSuggestList,
  dcmSuggestDel,
  dcmSuggestimportExcel,
} from '../../../actions/account'
const { confirm } = Modal

const { TabPane } = Tabs
const { Column } = Table
const { TextArea } = Input
const { Option } = Select
class DCMSuggest extends Component {
  constructor(props) {
    super(props)
    this.state = {
      keyWord: '',
      visible: false,
      size: 10,
      dataList: [],
      page: 1,
      total: 0,
      title: '',
      subTitle: '',
      currId: undefined,
      type: '',
      visiblexpct: false,
      importSpinState: false,
      fileName: '',
      file: undefined,
      suggestType: '',
      formType: 1
    }
  }

  componentDidMount() {
    this.loadDatas()
  }

  loadDatas = () => {
    var { page, size, keyWord, type, suggestType } = this.state
    var that = this
    var obj = {
      page: page - 1,
      size: size,
      type: type,
    }
    if (keyWord !== '') {
      obj.title = keyWord
    }
    if (!!suggestType) {
      obj.suggestType = suggestType
    }
    this.props.dcmSuggestList(obj).then((res) => {
      if (res.data.code === 200) {
        that.setState({
          dataList: res.data.data.rows,
          total: res.data.data.total,
        })
      }
    })
  }

  keyWordOnChange = (e) => {
    this.setState({ keyWord: e.target.value })
  }
  typeChange = (e) => {
    this.setState({ type: e })
  }
  suggestTypeChange = (e) => {
    this.setState({ suggestType: e })
  }
  onformTypeChange = (e) => {
    this.setState({ formType: e.target.value })
  }

  showModal = () => {
    this.setState({ visible: true })
  }
  handleCancel = () => {
    this.setState({
      visible: false,
      title: '',
      subTitle: '',
      currId: undefined,
    })
  }
  handleOk = () => {
    var { currId, title, subTitle, formType } = this.state
    var that = this
    var obj = { title, subTitle }
    obj.type = formType
    if (currId === undefined) {
      this.props.dcmSuggestSave(obj).then((res) => {
        if (res.data.code === 200) {
          message.success('新增成功！')
          that.loadDatas()
          that.setState({ visible: false, title: '', subTitle: '' })
        } else {
          message.error(res.data.message)
        }
      })
    } else {
      obj.id = currId
      this.props.dcmSuggestUpdate(obj).then((res) => {
        if (res.data.code === 200) {
          message.success('新增成功！')
          that.loadDatas()
          that.setState({
            visible: false,
            title: '',
            subTitle: '',
            currId: undefined,
          })
        } else {
          message.error(res.data.message)
        }
      })
    }
  }

  changePage = (page) => {
    var that = this
    this.setState({ page }, () => {
      that.loadDatas()
    })
  }
  pageSizeOnChange = (current, size) => {
    var that = this
    this.setState({ size: size })
  }
  edit = (record) => {
    this.setState({
      title: record.title,
      subTitle: record.subTitle,
      currId: record.id,
      visible: true,
      formType: record.type
    })
  }

  delete = (record) => {
    var that = this
    confirm({
      content: '确认删除？',
      onOk() {
        that.props.dcmSuggestDel({ id: record.id }).then((res) => {
          if (res.data.code === 200) {
            message.success('删除成功！')
            that.loadDatas()
          }
        })
      },
    })
  }
  titleOnChange = (e) => {
    this.setState({ title: e.target.value })
  }
  subTitleOnChange = (e) => {
    this.setState({ subTitle: e.target.value })
  }
  clearsubTitle = () => {
    this.setState({ subTitle: '', title: '' })
  }
  // 批量导入
  exportModal = () => {
    this.setState({
      visiblexpct: true,
    })
  }
  importxpctOk = () => {
    var { file } = this.state
    var formdata = new FormData()
    formdata.append('file', file)
    var that = this
    that.setState({ importSpinState: true }, () => {
      that.props.dcmSuggestimportExcel(formdata).then((res) => {
        if (res.data.code === 200) {
          message.success('上传成功！')
          that.loadDatas()
          that.setState({
            fileName: '',
            file: undefined,
            visiblexpct: false,
            importSpinState: false,
          })
        }
      })
    })
  }
  customRequest = (option) => {
    this.setState({
      fileName: option.file.name,
      file: option.file,
    })
  }
  imporxpctCancel = () => {
    this.setState({
      visiblexpct: false,
      fileName: '',
      file: undefined,
      importSpinState: undefined,
    })
  }
  render() {
    const { dataList, keyWord, title, subTitle, type, visiblexpct, suggestType } = this.state

    return (
      <div>
        <div className="StatisticsPage-head pageHeader">
          <h3>胸片管理</h3>
        </div>
        <div className="StatisticsPage-box">
          <div className="StatisticsPage-search">
            <div className="StatisticsPage-btn">
              <Input
                className="ipt"
                placeholder="请输入关键字"
                value={keyWord}
                style={{ width: 100 }}
                onChange={this.keyWordOnChange}
              />
              <Select
                showSearch
                placeholder="选择类型"
                value={type}
                onChange={this.typeChange}
                style={{ width: '200px' }}
              >
                <Option value="1">结论</Option>
                <Option value="2">建议</Option>
              </Select>
              <Select
                showSearch
                placeholder="选择类别"
                value={suggestType}
                onChange={this.suggestTypeChange}
                style={{ width: '200px' }}
              >
                <Option value="1">胸片</Option>
                <Option value="2">心电图</Option>
              </Select>
              <Button className="btn" type="primary" onClick={this.loadDatas}>
                搜索
              </Button>
              <Button className="btn" type="primary" onClick={this.showModal}>
                新增
              </Button>
              <Button className="btn" type="primary" onClick={this.exportModal}>
                批量导入
              </Button>
            </div>
          </div>
          <div>
            <Table
              size="middle"
              dataSource={dataList}
              bordered
              rowKey="id"
              pagination={{
                pageSize: this.state.size,
                showTotal: (total) => {
                  return `共 ${total} 条`
                },
                onChange: this.changePage,
                total: this.state.total,
                showSizeChanger: true,
                onShowSizeChange: this.pageSizeOnChange,
              }}
            >
              <Column
                title="类型"
                dataIndex="type"
                key="type"
                align="center"
                width="80px"
                render={(text, record, index) => (
                  <span>
                    <span>{record.type == '1' ? '胸片' : record.type == '2' ? '心电图' : ''}</span>
                  </span>
                )}
              />
              <Column
                title="诊断结论"
                dataIndex="title"
                key="title"
                align="center"
              />
              <Column
                title="诊断内容"
                dataIndex="subTitle"
                key="subTitle"
                align="center"
              />

              <Column
                title="操作"
                align="center"
                key="action"
                render={(text, record, index) => (
                  <span>
                    <span
                      style={{ cursor: 'pointer' }}
                      onClick={(e) => this.edit(record)}
                    >
                      编辑
                    </span>
                    <Divider type="vertical" />
                    <span
                      style={{ cursor: 'pointer' }}
                      onClick={(e) => this.delete(record)}
                    >
                      删除
                    </span>
                  </span>
                )}
              />
            </Table>
          </div>
        </div>
        <Modal
          title="词条新增/编辑"
          maskClosable={false}
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          okText="保存"
          cancelText="取消"
        >
          <Tabs defaultActiveKey="1" onChange={() => this.clearsubTitle()}>
            <TabPane tab="诊断结论" key="1">
              <div className="form-group">
                <label className="form-label">诊断结论：</label>
                <div className="form-ipt">
                  <TextArea
                    rows={5}
                    value={title}
                    placeholder="请输入诊断结论"
                    onChange={this.titleOnChange}
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="form-label">检查所见：</label>
                <div className="form-ipt">
                  <TextArea
                    rows={10}
                    value={subTitle}
                    placeholder="请输入检查所见"
                    onChange={this.subTitleOnChange}
                  />
                </div>
              </div>
            </TabPane>
            <TabPane tab="诊断建议" key="2">
              {' '}
              <div className="form-group">
                <label className="form-label">诊断建议：</label>
                <div className="form-ipt">
                  <TextArea
                    rows={10}
                    value={title}
                    placeholder="请输入诊断建议"
                    onChange={this.titleOnChange}
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="form-label">诊断建议：</label>
                <div className="form-ipt">
                  <Radio.Group
                    onChange={this.onformTypeChange}
                    value={this.state.formType}
                  >
                    <Radio value={1}>胸片</Radio>
                    <Radio value={2}>心电图</Radio>
                  </Radio.Group>
                </div>
              </div>
            </TabPane>
          </Tabs>
        </Modal>
        {/*  */}
        <Modal
          width={600}
          title="胸片词条导入"
          visible={visiblexpct}
          okText="导入"
          onOk={this.importxpctOk}
          onCancel={this.imporxpctCancel}
        >
          <Spin tip="导入中..." spinning={this.state.importSpinState}>
            <div className="form-group">
              <label className="form-label">文件：</label>
              <div className="form-ipt">
                <Upload
                  showUploadList={false}
                  customRequest={this.customRequest}
                >
                  <Tooltip placement="right">
                    <Button>
                      <Icon />
                      上传文件
                    </Button>
                  </Tooltip>
                </Upload>
                <span>{this.state.fileName}</span>
              </div>
            </div>
          </Spin>
        </Modal>
      </div>
    )
  }
}
export default connect(null, {
  dcmSuggestSave,
  dcmSuggestUpdate,
  dcmSuggestList,
  dcmSuggestDel,
  dcmSuggestimportExcel,
})(DCMSuggest)
