import React, { Component } from 'react'
import { connect } from 'react-redux'
import './appointmentRegister.css'
import moment from 'moment'
import util from '../../actions/util'
import { Descriptions, Checkbox } from 'antd'
import _ from 'lodash'

import { appointmentRegisterDetail } from '../../actions/account'
import { message } from 'antd'
const CheckboxGroup = Checkbox.Group

class appointmentRegister extends Component {
  constructor(props) {
    super(props)
    let search = this.props.history.location.search
    var idCard = util.urlParam('idCard', search)
    this.state = {
      idCard: idCard,
      curRecord: {
        id: null,
        appointmentUserId: null,
        organizationId: null,
        idCard: null,
        blood: null,
        disease: null,
        surgeryHistory: null,
        surgeryInfo: null,
        traumaHistory: null,
        traumaInfo: null,
        allergy: null,
        allergyOther: null,
        father: null,
        fatherOther: null,
        mother: null,
        motherOther: null,
        brother: null,
        brotherOther: null,
        children: null,
        childrenOther: null,
        diastole: null,
        shrink: null,
        exerciseFrequency: null,
        exerciseTime: null,
        duration: null,
        exerciseType: null,
        dietaryHabit: null,
        smoke: null,
        smokeDayAverage: null,
        startSmokeAge: null,
        endSmokeAge: null,
        drink: null,
        drinkDayAverage: null,
        isStopDrink: null,
        stopDrinkAge: null,
        startDrinkAge: null,
        isDrunk: null,
        wineType: null,
        otherWine: null,
        drug: null,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        marital: null,
        username: null,
        phone: null,
        domicileType: null,
        workUnit: null,
        residentialAddress: null,
        education: null,
        hosType: null,
        height: null,
        weight: null,
      },
    }
  }
  componentDidMount() {
    message.loading({ content: '查询中...', duration: 0 })
    // 获取当前页面的URL
    const url = window.location.href
    console.log(url)
    var curRecord = url.split('=')[1] // 通过等于号分隔并获取第二部分（索引为1）

    this.props.appointmentRegisterDetail({ idCard: curRecord }).then((res) => {
      message.destroy()
      if (res.data.code === 200) {
        this.setState({ curRecord: res.data.data }, () => {
          setTimeout(() => {
            window.print()
          }, 1000)
        })
      }
    })
  }
  getDiseaseChecked = (str, key = null) => {
    if (!str) {
      if (key) return null
      else return []
    }
    var arr = JSON.parse(str.replaceAll('&quot;', '"'))
    if (key) {
      var checked = null
      arr.forEach((item) => {
        if (Number(item.state) === Number(key)) checked = item
      })
      return checked
    } else {
      var checked = []
      arr.forEach((item) => {
        checked.push(item.state)
      })
      return checked
    }
  }
  getEdu = (education) => {
    var educationList = [
      { value: '10', text: '研究生教育' },
      { value: '20', text: '大学本科教育' },
      { value: '30', text: '大学专科教育' },
      { value: '40', text: '中等职业教育' },
      { value: '60', text: '普通高级中学教育' },
      { value: '70', text: '初级中学教育' },
      { value: '80', text: '小学教育' },
      { value: '90', text: '其他' },
    ]
    var text = _.find(educationList, ['value', education])
      ? _.find(educationList, ['value', education]).text
      : ''
    return text
  }
  getHosType = (hosType) => {
    var list = [
      { value: '01', text: '城镇职工医疗保险' },
      { value: '02', text: '城镇居民医疗保险' },
      { value: '03', text: '新型农村合作医疗' },
      { value: '04', text: '贫困救助' },
      { value: '05', text: '商业医疗保险' },
      { value: '06', text: '全公费' },
      { value: '07', text: '全自费' },
    ]
    var text = _.find(list, ['value', hosType])
      ? _.find(list, ['value', hosType]).text
      : ''
    return text
  }
  getShouShuList = (str) => {
    if (!str || str.length === 0) return []
    while (str.includes('&quot;')) {
      str = str.replace('&quot;', '"')
    }
    console.log(JSON.parse(str))
    return JSON.parse(str)
  }
  getWaishangList = (str) => {
    if (!str || str.length === 0) return []
    while (str.includes('&quot;')) {
      str = str.replace('&quot;', '"')
    }
    console.log(JSON.parse(str))
    return JSON.parse(str)
  }
  getDrugList = (drugStr) => {
    if (!drugStr || drugStr.length === 0) return []
    while (drugStr.includes('&quot;')) {
      drugStr = drugStr.replace('&quot;', '"')
    }
    console.log(JSON.parse(drugStr))
    return JSON.parse(drugStr)
  }
  render() {
    const { curRecord } = this.state
    return (
      <div className="appointmentRegister_page">
        <h3 className="register_page_title">居民健康档案登记表</h3>
        <div className="register_page_content">
          <Descriptions bordered column={6}>
            <Descriptions.Item label="姓名" span={1}>
              {curRecord.username}
            </Descriptions.Item>
            <Descriptions.Item label="身份证号码" span={3}>
              {curRecord.idCard}
            </Descriptions.Item>
            <Descriptions.Item label="联系电话" span={2}>
              {curRecord.phone}
            </Descriptions.Item>

            <Descriptions.Item label="婚姻状况" span={1}>
              {curRecord.marital
                ? ['', '未婚', '已婚', '丧偶', '离婚', '未说明的婚姻状况'][
                    curRecord.marital
                  ]
                : ''}
            </Descriptions.Item>
            <Descriptions.Item label="常住类型" span={2}>
              {curRecord.domicileType
                ? ['', '户籍', '非户籍'][curRecord.domicileType]
                : ''}
            </Descriptions.Item>
            <Descriptions.Item label="学历" span={3}>
              {curRecord.education ? this.getEdu(curRecord.education) : ''}
            </Descriptions.Item>

            <Descriptions.Item label="现住址" span={6}>
              {curRecord.residentialAddress}
            </Descriptions.Item>
            <Descriptions.Item label="工作单位" span={6}>
              {curRecord.workUnit}
            </Descriptions.Item>

            <Descriptions.Item label="血型" span={2}>
              {curRecord.marital
                ? ['', 'A型', 'B型', 'O型', 'AB型', '不详'][curRecord.blood]
                : ''}
            </Descriptions.Item>
            <Descriptions.Item label="医保类型" span={4}>
              {curRecord.hosType ? this.getHosType(curRecord.hosType) : ''}
            </Descriptions.Item>

            <Descriptions.Item label="身高cm" span={2}>
              {curRecord.height}
            </Descriptions.Item>
            <Descriptions.Item label="体重kg" span={2}>
              {curRecord.weight}
            </Descriptions.Item>
            <Descriptions.Item label="血压" span={2}>
              {curRecord.shrink}/{curRecord.diastole}
            </Descriptions.Item>

            <Descriptions.Item
              label="运动情况"
              span={6}
              className="inner_des_box"
            >
              <Descriptions bordered column={6}>
                <Descriptions.Item label="频率" span={2}>
                  {curRecord.exerciseFrequency
                    ? ['', '每天', '每周一次以上', '偶尔', '不锻炼'][
                        curRecord.exerciseFrequency
                      ]
                    : ''}
                </Descriptions.Item>
                <Descriptions.Item label="时长(分钟)" span={2}>
                  {curRecord.exerciseTime}
                </Descriptions.Item>
                <Descriptions.Item label="持续(年)" span={2}>
                  {curRecord.duration}
                </Descriptions.Item>
                <Descriptions.Item label="锻炼方式" span={6}>
                  {curRecord.exerciseType}
                </Descriptions.Item>
              </Descriptions>
            </Descriptions.Item>
            <Descriptions.Item
              label="吸烟情况"
              span={6}
              className="inner_des_box"
            >
              <Descriptions bordered column={2}>
                <Descriptions.Item label="当前状况" span={1}>
                  {curRecord.smoke
                    ? ['', '从不吸烟', '已戒烟', '吸烟'][curRecord.smoke]
                    : ''}
                </Descriptions.Item>
                <Descriptions.Item label="日均量(支)" span={1}>
                  {curRecord.smokeDayAverage}
                </Descriptions.Item>
                <Descriptions.Item label="开始吸烟年龄(岁)" span={1}>
                  {curRecord.startSmokeAge}
                </Descriptions.Item>
                <Descriptions.Item label="戒烟年龄(岁)" span={1}>
                  {curRecord.endSmokeAge}
                </Descriptions.Item>
              </Descriptions>
            </Descriptions.Item>
            <Descriptions.Item
              label="饮酒情况"
              span={6}
              className="inner_des_box"
            >
              <Descriptions bordered column={3}>
                <Descriptions.Item label="频率" span={1}>
                  {curRecord.smoke
                    ? ['', '从不', '偶尔', '经常', '每天'][curRecord.drink]
                    : ''}
                </Descriptions.Item>
                <Descriptions.Item label="日均量(两)" span={1}>
                  {curRecord.drinkDayAverage}
                </Descriptions.Item>
                <Descriptions.Item label="饮酒年龄(岁)" span={1}>
                  {curRecord.startDrinkAge}
                </Descriptions.Item>
                <Descriptions.Item label="是否戒酒" span={1}>
                  {curRecord.isStopDrink
                    ? ['', '未戒酒', '已戒酒'][curRecord.isStopDrink]
                    : ''}
                </Descriptions.Item>
                <Descriptions.Item label="戒酒年龄(岁)" span={1}>
                  {curRecord.stopDrinkAge}
                </Descriptions.Item>
                <Descriptions.Item label="近一年是否醉酒" span={1}>
                  {curRecord.isDrunk ? ['', '是', '否'][curRecord.isDrunk] : ''}
                </Descriptions.Item>
                <Descriptions.Item label="酒种类" span={3}>
                  <CheckboxGroup
                    className="des_checkgroup"
                    value={
                      curRecord.wineType ? curRecord.wineType.split(',') : []
                    }
                  >
                    {['白酒', '啤酒', '红酒', '黄酒', '其他'].map(
                      (item, index) => (
                        <Checkbox
                          value={index + 1 + ''}
                          key={index + 1}
                          className="examination_checkbox"
                        >
                          {item}
                        </Checkbox>
                      )
                    )}
                    <span className="checkbox_dis_span">
                      {curRecord.otherWine}
                    </span>
                  </CheckboxGroup>
                </Descriptions.Item>
              </Descriptions>
            </Descriptions.Item>
            <Descriptions.Item label="药物过敏史" span={6}>
              <CheckboxGroup
                className="des_checkgroup"
                value={curRecord.allergy ? curRecord.allergy.split(',') : []}
              >
                {['无', '青霉素', '磺胺', '链霉素', '其他'].map(
                  (item, index) => (
                    <Checkbox
                      value={index + 1 + ''}
                      key={index + 1}
                      className="examination_checkbox"
                    >
                      {item}
                    </Checkbox>
                  )
                )}
                <span className="checkbox_dis_span">
                  {curRecord.allergyOther}
                </span>
              </CheckboxGroup>
            </Descriptions.Item>
            <Descriptions.Item label="疾病史" span={6}>
              <CheckboxGroup
                className="des_checkgroup check_box_wrap"
                value={this.getDiseaseChecked(curRecord.disease)}
              >
                {[
                  '无',
                  '高血压',
                  '糖尿病',
                  '冠心病',
                  '慢性阻塞性肺疾病',
                  '恶性肿瘤',
                  '脑卒中',
                  '重型精神疾病',
                  '结核病',
                ].map((item, index) => (
                  <Checkbox
                    value={index + 1}
                    key={index + 1}
                    className=" examination_checkbox"
                  >
                    {item}
                    {index !== 0 &&
                      this.getDiseaseChecked(curRecord.disease, index + 1) && (
                        <span className="checkbox_dis_span">
                          {this.getDiseaseChecked(curRecord.disease, index + 1)
                            .time +
                            ' ' +
                            this.getDiseaseChecked(curRecord.disease, index + 1)
                              .val}
                        </span>
                      )}
                  </Checkbox>
                ))}
              </CheckboxGroup>
            </Descriptions.Item>
            <Descriptions.Item label="手术史" span={6} className="custom_line">
              <div className="custom_line_box">
                {curRecord.surgeryHistory === 1 && (
                  <p>
                    <span>无</span>
                  </p>
                )}
                {curRecord.surgeryHistory === 2 &&
                  this.getShouShuList(curRecord.surgeryInfo).map(
                    (item, index) => (
                      <p key={index}>
                        <span>{`手术名称：${item.val}`}</span>
                        <span>{`时间：${item.time}`}</span>
                      </p>
                    )
                  )}
              </div>
            </Descriptions.Item>
            <Descriptions.Item label="外伤史" span={6} className="custom_line">
              <div className="custom_line_box">
                {curRecord.traumaHistory === 1 && (
                  <p>
                    <span>无</span>
                  </p>
                )}
                {curRecord.traumaHistory === 2 &&
                  this.getWaishangList(curRecord.traumaInfo).map(
                    (item, index) => (
                      <p key={index}>
                        <span>{`外伤名称：${item.val}`}</span>
                        <span>{`时间：${item.time}`}</span>
                      </p>
                    )
                  )}
              </div>
            </Descriptions.Item>
            <Descriptions.Item label="用药史" span={6} className="custom_line">
              <div className="custom_line_box">
                {this.getDrugList(curRecord.drug).length === 0 && (
                  <p>
                    <span>无</span>
                  </p>
                )}
                {this.getDrugList(curRecord.drug).length > 0 &&
                  this.getDrugList(curRecord.drug).map((item, index) => (
                    <p key={index}>
                      <span>{`药品名称：${item.drugName}`}</span>
                      <span>{`服药依从性：${
                        ['', '规律', '间断', '不服药'][item.compliance]
                      }`}</span>
                      <span>{`用法：${item.usage}`}</span>
                      <span>{`用量：${item.dosage}`}</span>
                      <span>{`使用时间：${item.useTime}`}</span>
                    </p>
                  ))}
              </div>
            </Descriptions.Item>

            <Descriptions.Item
              label="家族史"
              span={6}
              className="inner_des_box"
            >
              <Descriptions bordered column={6}>
                <Descriptions.Item label="父亲" span={6}>
                  {curRecord.father
                    ? curRecord.father
                        .split(',')
                        .map((item) =>
                          Number(item) !== 12
                            ? [
                                '',
                                '无',
                                '高血压;',
                                '糖尿病;',
                                '冠心病;',
                                '慢性阻塞性肺疾病;',
                                '恶性肿瘤;',
                                '脑卒中;',
                                '重性精神疾病;',
                                '结核病;',
                                '肝炎;',
                                '先天畸形;',
                              ][item]
                            : `其他(${curRecord.fatherOther})`
                        )
                    : ''}
                  {/* <CheckboxGroup className='des_checkgroup check_box_wrap' value={curRecord.father ? curRecord.brother.split(',') : []}>
                                        {['无', '高血压', '糖尿病', '冠心病', '慢性阻塞性肺疾病',
                                        '恶性肿瘤', '脑卒中', '重性精神疾病', '结核病', '肝炎', '先天畸形', '其他'].map((item, index) =>
                                            <Checkbox value={(index + 1) + ""} key={index + 1} className="examination_checkbox">{item}</Checkbox>
                                        )}
                                        <span className='checkbox_dis_span'>{curRecord.fatherOther}</span>
                                    </CheckboxGroup> */}
                </Descriptions.Item>
                <Descriptions.Item label="母亲" span={6}>
                  {curRecord.mother
                    ? curRecord.mother
                        .split(',')
                        .map((item) =>
                          Number(item) !== 12
                            ? [
                                '',
                                '无',
                                '高血压;',
                                '糖尿病;',
                                '冠心病;',
                                '慢性阻塞性肺疾病;',
                                '恶性肿瘤;',
                                '脑卒中;',
                                '重性精神疾病;',
                                '结核病;',
                                '肝炎;',
                                '先天畸形;',
                              ][item]
                            : `其他(${curRecord.motherOther});`
                        )
                    : ''}
                </Descriptions.Item>
                <Descriptions.Item label="兄弟姐妹" span={6}>
                  {curRecord.brother
                    ? curRecord.brother
                        .split(',')
                        .map((item) =>
                          Number(item) !== 12
                            ? [
                                '',
                                '无',
                                '高血压;',
                                '糖尿病;',
                                '冠心病;',
                                '慢性阻塞性肺疾病;',
                                '恶性肿瘤;',
                                '脑卒中;',
                                '重性精神疾病;',
                                '结核病;',
                                '肝炎;',
                                '先天畸形;',
                              ][item]
                            : `其他(${curRecord.brotherOther})`
                        )
                    : ''}
                </Descriptions.Item>
                <Descriptions.Item label="子女" span={6}>
                  {curRecord.children
                    ? curRecord.children
                        .split(',')
                        .map((item) =>
                          Number(item) !== 12
                            ? [
                                '',
                                '无',
                                '高血压;',
                                '糖尿病;',
                                '冠心病;',
                                '慢性阻塞性肺疾病;',
                                '恶性肿瘤;',
                                '脑卒中;',
                                '重性精神疾病;',
                                '结核病;',
                                '肝炎;',
                                '先天畸形;',
                              ][item]
                            : `其他(${curRecord.childrenOther})`
                        )
                    : ''}
                </Descriptions.Item>
              </Descriptions>
            </Descriptions.Item>
          </Descriptions>
        </div>
      </div>
    )
  }
}
export default connect(null, { appointmentRegisterDetail })(appointmentRegister)
