import React, { Component } from 'react'
import { Modal, Button, Table, message, Spin, Divider, Input, Radio } from 'antd'

import {
    mealClassifyRemove, mealClassifySave, mealClassifyList
} from '../../../actions/account'
import _ from 'lodash'
// import _ from 'lodash'
import { connect } from 'react-redux'
import '../medicalRecord/RegistrationPage.css'
const { Column } = Table
const { confirm } = Modal

class mealClassify extends Component {
    constructor(props) {
        super(props)
        this.state = {
            spinning: false,
            classifyName: '',
            status: null,
            id: '',
            bindphyVisble: false,
            // 
            dataList2: [],
            page: 1,
            total: 0,
            size: 10,
            name: '',
            curend: {}
        }
    }

    componentDidMount() {
        this.loadDatas()
        var that = this

    }
    onsexChange = (e) => {
        this.setState({
            status: e.target.value,
        })
    }
    loadDatas = () => {
        var { page, size, name } = this.state
        var obj = { page: page - 1, size: size }
        if (!!name) {
            obj.classifyName = name
        }
        var that = this
        this.setState({ spinning: true })
        this.props.mealClassifyList(obj).then((msg) => {
            console.log(msg)
            if (msg.data.code === 200) {
                that.setState({
                    dataList2: msg.data.data.rows,
                    total: msg.data.data.total,
                    spinning: false,
                })
            }
        })
    }

    editItem = (itm) => {
        var that = this

        that.setState({
            curend: JSON.parse(JSON.stringify(itm)),
            classifyName: itm.classifyName,
            status: itm.status,
            bindphyVisble: true,
            id: itm.id,

        })

    }

    bandphy = () => {
        var that = this
        that.setState({
            status: null,
            classifyName: '',
            id: '',
            bindphyVisble: true,
            curend: {}
        })
    }
    handleBindphyOk = () => {
        var { id, classifyName, status, curend } = this.state

        var that = this
        if (!classifyName) {
            message.error('请输入分类名称')
            return
        }
        if (status == null) {
            message.error('请选择状态')
            return
        }
        let boj = {
            classifyName: classifyName,
            status: status,

            id: id,

        }
        if (!!id) {
            if (curend.classifyName == classifyName) {
                delete boj.classifyName
            }
            if (curend.status == status) {
                delete boj.status
            }
        }
        that.props
            .mealClassifySave(boj)
            .then((msg) => {
                if (msg.data.code === 200) {
                    message.success('操作成功！')
                    that.loadDatas()
                    that.handleBindphyCancel()
                } else {
                    message.error(msg.data.message)
                }

            })

    }


    handleBindphyCancel = () => {
        this.setState({
            status: null,
            classifyName: '',
            mealPrice: '',
            id: '',
            bindphyVisble: false
        })
    }


    delItem = (recorrd) => {
        var that = this
        confirm({
            content: '确认删除？',
            onOk() {
                var That = that
                that.props
                    .mealClassifyRemove({ id: recorrd.id })
                    .then((msg) => {
                        if (msg.data.code === 200) {
                            message.success('删除成功！')

                            That.loadDatas()
                        }
                    })
            },
        })
    }
    changePage = (page) => {
        var that = this
        this.setState({ page }, () => {
            that.loadDatas()
        })
    }
    mealNameChange = (e, type) => {
        this.setState({
            [type]: e.target.value,
        })
    }
    priceChange = (e, type) => {

        this.setState({
            [type]: e,
        })
    }
    nameOnChange = (e) => {

        this.setState({
            name: e.target.value,
        })
    }
    render() {
        const {
            dataList2,

            selectedRowKeys,
            classifyName, status,
            spinning,
            bindphyVisble,
            name
        } = this.state
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        }

        return (
            <div>
                <div className="StatisticsPage-head pageHeader">
                    <h3>套餐分类列表</h3>
                </div>

                <div className="StatisticsPage-box">
                    <div className="StatisticsPage-search">
                        <div className="StatisticsPage-btn">
                            <Input
                                className="ipt"
                                placeholder="请输入分类名称"
                                value={name}
                                onChange={this.nameOnChange}
                            />

                            <Button className="btn" type="primary" onClick={this.loadDatas}>
                                搜索
                            </Button>
                            <Button className="btn" type="primary" onClick={this.bandphy}>
                                添加套餐分类
                            </Button>

                            <Modal
                                title="套餐分类"
                                visible={bindphyVisble}
                                onOk={this.handleBindphyOk}
                                onCancel={this.handleBindphyCancel}
                                okText="保存"
                                cancelText="取消"
                            >
                                <div className="form-group">
                                    <label className="form-label">分类名称：</label>
                                    <div className="form-ipt" style={{ marginTop: '5px' }}>
                                        <Input
                                            placeholder="分类名称"
                                            value={classifyName}
                                            onChange={(e) => this.mealNameChange(e, 'classifyName')}
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="form-label">状态：</label>
                                    <div className="form-ipt" style={{ marginTop: '5px' }}>
                                        <Radio.Group
                                            onChange={this.onsexChange}
                                            value={status}
                                        >
                                            <Radio value={0}>关闭</Radio>
                                            <Radio value={1}>开启</Radio>
                                        </Radio.Group>
                                    </div>
                                </div>

                            </Modal>
                        </div>
                    </div>
                    <div>
                        <Spin tip="Loading..." spinning={spinning}>
                            <Table
                                size="middle"
                                dataSource={dataList2}
                                bordered
                                rowKey="id"
                                pagination={{
                                    pageSize: this.state.size,
                                    showTotal: (total) => {
                                        return `共 ${total} 条`
                                    },
                                    onChange: this.changePage,
                                    total: this.state.total,
                                    showSizeChanger: false,
                                }}
                                rowSelection={rowSelection}

                            >
                                <Column
                                    title="分类名称"
                                    dataIndex="classifyName"
                                    key="classifyName"
                                    align="center"
                                />
                                <Column
                                    title="状态"
                                    dataIndex="status"
                                    key="status"
                                    align="center"
                                    render={(text, record) => (
                                        <span>
                                            {record.status == 0 ? '关闭' : '开启'}
                                        </span>
                                    )}
                                />


                                <Column
                                    title="操作"
                                    align="center"
                                    key="action"
                                    render={(text, record) => (
                                        <span>
                                            <span
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => this.editItem(record)}
                                            >
                                                修改
                                            </span>
                                            <Divider type="vertical" />
                                            <span
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => this.delItem(record)}
                                            >
                                                删除
                                            </span>
                                        </span>
                                    )}
                                />
                            </Table>
                        </Spin>

                    </div>
                </div>
            </div>
        )
    }
}
export default connect(null, {
    mealClassifyRemove, mealClassifySave, mealClassifyList
})(mealClassify)
